import { useMutation } from '@apollo/client';
import React, { FC } from 'react';
import { ScrollView, TextStyle, View } from 'react-native';

import { priceFormatter } from 'mango-utils-client';
import DatePicker from '../../../../components/DatePicker';
import { MundoButton, MundoText } from '../../../../components/elements';
import MundoTable from '../../../../components/elements/MundoTable';
import { config } from '../../../../utilities/config';
import { useStyle } from '../../../../utilities/hooks/styles';
import { openDocs } from '../../../../utilities/openDocs';
import { useExports } from '../../hooks/exports/useExports';
import { usePreview } from '../../hooks/exports/usePreview';
import messages from './messages';
import { NAV_EXPORT } from './queries';

const NavisionExportList: FC = () => {
	const styles = useStyle();

	const {
		preview,
		onChangeFrom,
		onChangeTo,
		fromDate,
		toDate,
	} = usePreview();

	const {
		exports,
		loading,
		loadMore,
		loadMoreActive,
		refetch,
	} = useExports();

	const [navExport] = useMutation(NAV_EXPORT, {
		onCompleted: () => {
			refetch();
		},
	});

	const boldText: TextStyle = { fontWeight: '600' };

	const exportButtonHandler = async () => {
		await navExport({
			variables: {
				from: fromDate,
				to: toDate,
			},
		});
	};

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/vehicleCleaning/export')
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View style={styles.containerFullPercentageWidth}>
						<View
							style={
								styles.containerFullWidthHorizontalCenterAligned
							}
						>
							<View style={styles.paddedView}>
								<MundoText message={messages.from} />
							</View>
							<View style={styles.paddedView}>
								<DatePicker
									cyId={'exports.from.date'}
									value={new Date(fromDate)
										.toISOString()
										.substr(0, 10)}
									onChange={(date) => {
										if (date <= toDate) {
											onChangeFrom(date);
										}
									}}
								/>
							</View>
							<View style={styles.paddedView}>
								<MundoText message={messages.to} />
							</View>
							<View style={styles.paddedView}>
								<DatePicker
									cyId={'exports.to.date'}
									value={new Date(toDate)
										.toISOString()
										.substr(0, 10)}
									onChange={(date) => {
										if (date >= fromDate) {
											onChangeTo(date);
										}
									}}
								/>
							</View>
							<MundoButton
								title={messages.export}
								onPress={() => exportButtonHandler()}
							/>
						</View>
						<View>
							<View
								style={[
									styles.topMargin20,
									styles.bottomMargin10,
								]}
							>
								<MundoText
									message={messages.preview}
									styles={boldText}
								/>
							</View>
							<MundoTable
								entrys={[
									{
										identifier: 'group',
										header: messages.group,
										size: 10,
									},
									{
										identifier: 'net',
										header: messages.net,
										size: 15,
									},
									{
										identifier: 'tax',
										header: messages.tax,
										size: 15,
									},
									{
										identifier: 'gross',
										header: messages.gross,
										size: 15,
									},
								]}
								rows={preview.map((ex, i) => {
									return {
										key: `${i}`,
										values: [
											{
												identifier: 'group',
												content: (
													<MundoText>
														{ex.title}
													</MundoText>
												),
											},
											{
												identifier: 'net',
												content: (
													<MundoText>
														{priceFormatter(
															ex.net / 1000,
														) + ' €'}
													</MundoText>
												),
											},
											{
												identifier: 'tax',
												content: (
													<MundoText>
														{priceFormatter(
															ex.tax / 1000,
														) + ' €'}
													</MundoText>
												),
											},
											{
												identifier: 'gross',
												content: (
													<MundoText>
														{priceFormatter(
															ex.gross / 1000,
														) + ' €'}
													</MundoText>
												),
											},
										],
									};
								})}
							/>
						</View>
						<View>
							<View
								style={[
									styles.topMargin20,
									styles.bottomMargin10,
								]}
							>
								<MundoText
									message={messages.exports}
									styles={{ fontWeight: '600' }}
								/>
							</View>
							<MundoTable
								entrys={[
									{
										identifier: 'date',
										header: messages.date,
										size: 10,
									},
									{
										identifier: 'group',
										header: messages.group,
										size: 10,
									},
									{
										identifier: 'net',
										header: messages.net,
										size: 15,
									},
									{
										identifier: 'tax',
										header: messages.tax,
										size: 15,
									},
									{
										identifier: 'gross',
										header: messages.gross,
										size: 15,
									},
									{ identifier: 'dl', size: 10 },
								]}
								rows={exports.map((ex) => {
									return {
										key: `${ex.date}`,
										values: [
											{
												identifier: 'date',
												content: (
													<MundoText>
														{new Date(ex.date)
															.toISOString()
															.substr(0, 10)}
													</MundoText>
												),
											},
											{
												identifier: 'group',
												content: (
													<MundoText>
														{ex.title}
													</MundoText>
												),
											},
											{
												identifier: 'net',
												content: (
													<MundoText>
														{priceFormatter(
															ex.net / 1000,
														) + ' €'}
													</MundoText>
												),
											},
											{
												identifier: 'tax',
												content: (
													<MundoText>
														{priceFormatter(
															ex.tax / 1000,
														) + ' €'}
													</MundoText>
												),
											},
											{
												identifier: 'gross',
												content: (
													<MundoText>
														{priceFormatter(
															ex.gross / 1000,
														) + ' €'}
													</MundoText>
												),
											},
											{
												identifier: 'dl',
												content: (
													<MundoButton
														styles={{ width: 40 }}
														subtype="small"
														icon={'download'}
														onPress={() => {
															// TODO: find generic react native solution
															window.open(
																`${config.API_URL}/export/${ex.date}`,
															);
														}}
													/>
												),
											},
										],
										subRows: ex.subExport.map(
											(subEx, i) => {
												return {
													key: `${i}`,
													values: [
														{
															identifier: 'date',
															content: (
																<MundoText>
																	{new Date(
																		subEx.date,
																	)
																		.toISOString()
																		.substr(
																			0,
																			10,
																		)}
																</MundoText>
															),
														},
														{
															identifier: 'group',
															content: (
																<MundoText>
																	{
																		subEx.title
																	}
																</MundoText>
															),
														},
														{
															identifier: 'net',
															content: (
																<MundoText>
																	{priceFormatter(
																		subEx.net /
																			1000,
																	) + ' €'}
																</MundoText>
															),
														},
														{
															identifier: 'tax',
															content: (
																<MundoText>
																	{priceFormatter(
																		subEx.tax /
																			1000,
																	) + ' €'}
																</MundoText>
															),
														},
														{
															identifier: 'gross',
															content: (
																<MundoText>
																	{priceFormatter(
																		subEx.gross /
																			1000,
																	) + ' €'}
																</MundoText>
															),
														},
													],
												};
											},
										),
									};
								})}
								loadMore={loadMore}
								loading={loading}
								loadMoreActive={loadMoreActive}
							/>
						</View>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default NavisionExportList;
