import { createContext } from 'react';

export const SidebarContext = createContext({
	isExpanded: false,
	onChangeExpanded: (expand: boolean) => {
		/* expand here */
		console.log(expand);
	},
	currentPipeline: '',
	onChangeCurrentPipeline: (next: string) => {
		console.log(next);
	},
});
