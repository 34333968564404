import {
	EItemSource,
	EUnit,
	MItem,
	MTranslation,
	priceFormatter,
} from 'mango-utils-client';
import React, { FC, useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Picker, Text, View } from 'react-native';
import MediaQuery from 'react-responsive';

import unitMessages from '../../../../messages';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle } from '../../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import { getTranslation } from '../../../../utilities/translations';
import MundoInput from '../../../elements/MundoInput';
import MundoObjectTypeahead from '../../../elements/MundoObjectTypeahead';
import MundoPicker from '../../../elements/MundoPicker';
import messages from '../../messages';
import { IItemEditProps } from '../../props';
import { GET_ARTICLES } from '../queries';

export const CustomArticle: FC<IItemEditProps> = ({
	value: item,
	onChange,
}) => {
	const styles = useStyle();
	const intl = useIntl();
	const { language } = useContext(LanguageContext);

	const [controlled, onChangeControlled] = useState('free');

	const update = useCallback(
		(next: Partial<MItem>) => {
			if (!onChange) {
				return;
			}
			onChange(new MItem({ ...item, ...next }));
		},
		[item, onChange],
	);

	const { title, unit, short, amount, discount, price, tax } = item;

	return (
		<View style={{ flexDirection: 'column' }}>
			{!!short && !!title && (
				<View style={[styles.leftMargin10, { maxWidth: 550 }]}>
					<Text numberOfLines={2} ellipsizeMode="tail">
						{short + ' ' + getTranslation(title, language)}
					</Text>
				</View>
			)}
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'flex-end',
					justifyContent: 'center',
					marginBottom: 3,
				}}
			>
				<View style={styles.elevated}>
					<MundoPicker
						style={styles.leftMargin10}
						value={controlled}
						onChange={onChangeControlled}
						values={[
							{ label: messages.controlled, value: 'controlled' },
							{ label: messages.free, value: 'free' },
						]}
					/>
					{controlled === 'controlled' ? (
						<View style={styles.leftMargin10}>
							<MundoObjectTypeahead
								cyId={`article.input`}
								value={item}
								onChange={(newArticle) => {
									if (newArticle) {
										update({
											...newArticle,
											title: newArticle.title as MTranslation[],
										});
									}
								}}
								QUERY={GET_ARTICLES}
								multilanguage
							/>
						</View>
					) : (
						<View style={styles.leftMargin10}>
							<MundoInput
								dataSet={{ cy: 'invoice.itemTitle.input' }}
								placeholder={messages.placeholderTitle}
								value={`${getTranslation(title, language)}`}
								onChangeText={(text: string) =>
									update({
										title: [
											new MTranslation({
												message: text,
												lang: language,
											}),
										],
									})
								}
							/>
						</View>
					)}
				</View>

				<MediaQuery minWidth={0} maxWidth={1019}>
					<View>
						<View style={styles.leftMargin10}>
							<MundoInput
								dataSet={{ cy: 'invoice.itemPrice.input' }}
								placeholder={messages.placeholderPrice}
								value={`${
									price > 0
										? priceFormatter(price / 1000)
										: ''
								}`}
								onChangeText={(text: string) => {
									// convert price from readable string to milli Euro (format for saving in database)
									update({
										price: +text.replace(/,|\./g, '') * 10,
									});
								}}
								length={6}
								unit={messages.currency}
							/>
						</View>
						<View style={styles.leftMargin10}>
							<MundoInput
								dataSet={{ cy: 'invoice.itemdiscount.input' }}
								placeholder={messages.placeholderDiscount}
								value={`${
									discount > 0
										? priceFormatter(discount / 100)
										: ''
								}`}
								onChangeText={(text: string) => {
									update({
										discount: +text.replace(/,|\./g, ''),
									});
								}}
								length={6}
								unit={messages.percent}
							/>
						</View>
					</View>
					<View>
						<View style={styles.leftMargin10}>
							<Picker
								style={styles.picker}
								selectedValue={tax}
								onValueChange={(itemValue) =>
									update({ tax: +itemValue })
								}
							>
								<Picker.Item
									label={'16%' /* 19 corona*/}
									value={16 /* 19 corona*/}
								/>
								<Picker.Item
									label={'5%' /* 7 corona*/}
									value={5 /* 7 corona*/}
								/>
							</Picker>
						</View>

						<View style={styles.leftMargin10}>
							<MundoInput
								dataSet={{ cy: 'invoice.itemAmount.input' }}
								placeholder={messages.placeholderAmount}
								value={`${amount > 0 ? amount : ''}`}
								onChangeText={(text: string) => {
									if (!isNaN(+text)) {
										update({ amount: +text });
									}
								}}
								length={5}
								unit={
									item.source !== EItemSource.CUSTOM
										? unit
										: ''
								}
							/>
						</View>
					</View>
					<View>
						{item.source === EItemSource.CUSTOM && (
							<MundoPicker
								style={styles.leftMargin10}
								value={unit}
								onChange={(v: EUnit) => {
									update({ unit: v });
								}}
								values={Object.keys(EUnit).map((key) => {
									return {
										value: key,
										// @ts-ignore
										label: unitMessages[key],
									};
								})}
							/>
						)}
					</View>
				</MediaQuery>
				<MediaQuery minWidth={1020}>
					<View>
						<View style={styles.leftMargin10}>
							<MundoInput
								dataSet={{ cy: 'invoice.itemPrice.input' }}
								placeholder={messages.placeholderPrice}
								value={`${
									price > 0
										? priceFormatter(price / 1000)
										: ''
								}`}
								onChangeText={(text: string) => {
									update({
										price: +text.replace(/,|\./g, '') * 10,
									});
								}}
								length={6}
								unit={messages.currency}
							/>
						</View>
						<View style={styles.leftMargin10}>
							<MundoInput
								dataSet={{ cy: 'invoice.itemdiscount.input' }}
								placeholder={messages.placeholderDiscount}
								value={`${
									discount > 0
										? priceFormatter(discount / 100)
										: ''
								}`}
								onChangeText={(text: string) => {
									update({
										discount: +text.replace(/,|\./g, ''),
									});
								}}
								length={6}
								unit={messages.percent}
							/>
						</View>
					</View>
					<View>
						<View style={styles.leftMargin10}>
							<Picker
								style={styles.picker}
								selectedValue={tax}
								onValueChange={(itemValue) =>
									update({ tax: +itemValue })
								}
							>
								<Picker.Item
									label={
										`16% ${capitalizeFirstWord(
											intl.formatMessage(messages.tax),
										)}` /* 19 corona*/
									}
									value={16 /* 19 corona*/}
								/>
								<Picker.Item
									label={
										`5% ${capitalizeFirstWord(
											intl.formatMessage(messages.tax),
										)}` /* 7 corona*/
									}
									value={5 /* 7 corona*/}
								/>
							</Picker>
						</View>

						<View style={styles.leftMargin10}>
							<MundoInput
								dataSet={{ cy: 'invoice.itemAmount.input' }}
								placeholder={messages.placeholderAmount}
								value={`${amount > 0 ? amount : ''}`}
								onChangeText={(text: string) => {
									if (!isNaN(+text)) {
										update({ amount: +text });
									}
								}}
								length={5}
								unit={
									item.source !== EItemSource.CUSTOM
										? unit
										: ''
								}
							/>
						</View>
					</View>
					<View>
						{item.source === EItemSource.CUSTOM && (
							<MundoPicker
								style={styles.leftMargin10}
								value={unit}
								onChange={(v: EUnit) => {
									update({ unit: v });
								}}
								placeholder={messages.unit}
								values={Object.keys(EUnit).map((key) => {
									return {
										value: key,
										// @ts-ignore
										label: unitMessages[key],
									};
								})}
							/>
						)}
					</View>
				</MediaQuery>
			</View>
		</View>
	);
};
