import { gql } from '@apollo/client';

export const GET_TRAILERS = gql`
	query getTrailers(
		$trailer: TTrailerInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		tTrailers(
			trailer: $trailer
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			_id
			id
			title
			type
			companyID
			chambers
			trailerType
			registrationDate
			huTest
			spTest
			uvvTest
			atpTest
			outerBoilerTest
			innerBoilerTest
			hardnessTest
		}
	}
`;
