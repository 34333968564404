export enum ETypes {
	EDIT = 'EDIT',
	EDIT_SILENT = 'EDIT_SILENT',
	ADD = 'ADD',
	ADD_SILENT = 'ADD_SILENT',
	REMOVE = 'REMOVE',
	REMOVE_SILENT = 'REMOVE_SILENT',
	RESET_TO = 'RESET_TO',
	NOTIFY = 'NOTIFY',
	SWAP = 'SWAP',
	SWAP_LEFT = 'SWAP_LEFT',
	SWAP_RIGHT = 'SWAP_RIGHT',
}

export const arrayReducer = <t>(
	items: t[],
	action: { type: ETypes; location?: number; item?: t | t[] | number },
) => {
	switch (action.type) {
		case ETypes.EDIT:
			const loc =
				!action.location && action.location !== 0
					? -1
					: action.location;
			const next = action.item as t;
			if (loc > -1) {
				items.splice(loc, 1, next);
			}
			return [...items];
		case ETypes.ADD:
			if (Array.isArray(action.item)) {
				return [...items, ...action.item];
			} else {
				return [...items, action.item];
			}
		case ETypes.REMOVE:
			if (action.location || action.location === 0) {
				return items.filter((item, index) => index !== action.location);
			} else {
				return items.filter((item) => item !== action.item);
			}
		case ETypes.RESET_TO:
			return action.item as any[];
		case ETypes.SWAP:
			const locA = action.location as number;
			const locB = action.item as number;
			if (!isNaN(locA) && !isNaN(locB)) {
				[items[locA], items[locB]] = [items[locB], items[locA]];
			}
			return [...items];
		case ETypes.SWAP_LEFT:
			if (action.location) {
				const neighbour = action.location - 1;
				items[action.location] = items.splice(
					neighbour,
					1,
					items[action.location],
				)[0];
			}
			return [...items];
		case ETypes.SWAP_RIGHT:
			if (action.location || action.location === 0) {
				const neighbour = action.location + 1;
				if (neighbour < items.length) {
					items[action.location] = items.splice(
						neighbour,
						1,
						items[action.location],
					)[0];
				}
			}
			return [...items];
		case ETypes.EDIT_SILENT:
			const locs =
				!action.location && action.location !== 0
					? -1
					: action.location;
			if (locs > -1) {
				items.splice(locs, 1, action.item as t);
			}
			return items;
		case ETypes.ADD_SILENT:
			if (Array.isArray(action.item)) {
				items.push(...action.item);
				return items;
			} else {
				items.push(action.item as t);
				return items;
			}
		case ETypes.REMOVE_SILENT:
			if (action.location || action.location === 0) {
				items.splice(action.location, 1);
				return items;
			} else {
				items.splice(items.indexOf(action.item as t));
				return items;
			}
		case ETypes.NOTIFY:
			return [...items];
		default:
			throw new Error();
	}
};
