import { defineMessages } from 'react-intl';

export const scope = 'mango.component.table';

export default defineMessages({
	noData: {
		id: `${scope}.noData`,
		defaultMessage: 'no data',
	},
	loadMore: {
		id: `${scope}.loadMore`,
		defaultMessage: 'load more',
	},
});
