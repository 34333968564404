import React, { FC } from 'react';
import { Text, View } from 'react-native';

import { MPackage, MState, MTranslation } from 'mango-utils-client';
import { GET_PACKAGES } from '../../../container/Package/List/queries';
import { useStyle } from '../../../utilities/hooks/styles';
import DatePicker from '../../DatePicker';
import { MundoButton } from '../../elements';
import MundoInput from '../../elements/MundoInput';
import MundoObjectTypeahead from '../../elements/MundoObjectTypeahead';
import MundoText from '../../elements/MundoText';
import messages from '../messages';
import { IStateEditProps } from './props';
import { EPluign, getExtension } from '../../../Plugins';

const StateEdit: FC<IStateEditProps> = ({ state, onChange }) => {
	const styles = useStyle();

	return (
		<View style={styles.containerFullWidth}>
			<View style={styles.textBlock}>
				<MundoText message={messages.module} />
				<Text>{state.title}</Text>
			</View>
			<View style={styles.textBlock}>
				<MundoText message={messages.state} />
				<Text>{state.subState}</Text>
			</View>
			<View style={styles.textBlock}>
				<MundoText message={messages.date} />
				<DatePicker
					style={styles.datePicker}
					defaultValue={state.date}
					onChange={(date) => onChange({ ...state, date })}
				/>
			</View>
			<View style={styles.textBlock}>
				<MundoText message={messages.tags} />
				<View style={styles.wideGroupLeftAligned}>
					{state.tags.map((tag, index) => (
						<View key={index} style={styles.wideGroupLeftAligned}>
							<MundoInput
								placeholder={messages.tags}
								value={tag}
								onChangeText={(text: string) => {
									const newState = { ...state };
									newState.tags.splice(index, 1, text);
									onChange(newState);
								}}
							/>
							<MundoButton
								onPress={() => {
									const newState = { ...state };
									newState.tags.splice(index, 1);
									onChange(newState);
								}}
								icon={'minus'}
							/>
						</View>
					))}
					<MundoButton
						onPress={() => {
							const newState = { ...state };
							newState.tags.push('');
							onChange(newState);
						}}
						icon={'plus'}
					/>
				</View>
			</View>
			<View style={styles.textBlock}>
				<MundoText message={messages.user} />
				<Text>{state.user.title}</Text>
			</View>
			<View style={styles.textBlock}>
				<MundoText message={messages.bookedPackages} />
				<View style={styles.wideGroup}>
					{state.packages.map((pack, index) => (
						<View
							key={pack._id}
							style={[styles.elevated, styles.wideGroup]}
						>
							<MundoObjectTypeahead
								value={pack}
								onChange={(newPackage) => {
									if (newPackage) {
										const newPackages = state.packages;
										newPackages.splice(
											index,
											1,
											new MPackage({
												...newPackage,
												title: newPackage.title as MTranslation[],
											}),
										);
										onChange({
											...state,
											packages: newPackages,
										});
									}
								}}
								key={index}
								QUERY={GET_PACKAGES}
								multilanguage
							/>
							<MundoButton
								onPress={() => {
									const newState = { ...state };
									newState.packages.splice(index, 1);
									onChange(newState);
								}}
								icon={'minus'}
							/>
						</View>
					))}
					<MundoButton
						onPress={() => {
							const newState = { ...state };
							newState.packages.push(new MPackage());
							onChange(newState);
						}}
						icon={'plus'}
					/>
				</View>
				{Object.keys(EPluign).map((plugin) => {
					const Extension = getExtension(
						'StateEdit',
						plugin as EPluign,
					);

					if (Extension) {
						return (
							<Extension
								key={plugin}
								{...{
									state,
									onChange: (newState: MState) =>
										onChange(newState),
								}}
							/>
						);
					}
				})}
			</View>
		</View>
	);
};

export default StateEdit;
