import { defineMessages } from 'react-intl';

export const scope = 'mango.container.credit';

export default defineMessages({
	dueDate: {
		id: `${scope}.dueDate`,
		defaultMessage: 'due date',
	},
	issueDate: {
		id: `${scope}.issueDate`,
		defaultMessage: 'issue date',
	},
	netSum: {
		id: `${scope}.netSum`,
		defaultMessage: 'net sum',
	},
	taxSum: {
		id: `${scope}.taxSum`,
		defaultMessage: 'tax sum',
	},
	grossSum: {
		id: `${scope}.grossSum`,
		defaultMessage: 'gross sum',
	},
	paymentTime: {
		id: `${scope}.paymentTime`,
		defaultMessage: 'payment deadline (days)',
	},
	title: {
		id: `${scope}.invoice`,
		defaultMessage: 'invoice',
	},
	now: {
		id: `${scope}.now`,
		defaultMessage: 'now',
	},
	customerAllegiance: {
		id: `${scope}.customerAllegiance`,
		defaultMessage: 'customer base country',
	},
	customerError: {
		id: `${scope}.customerError`,
		defaultMessage: 'the customer is not set or is invalid',
	},
	customerName: {
		id: `${scope}.customerName`,
		defaultMessage: 'customerName',
	},
});
