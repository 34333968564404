// import { useQuery } from '@apollo/client';
import React, { FC, useMemo, useState } from 'react';
import lightTheme from '../../../../theme/light';
import { IThemeConstants } from '../../../constants';
// import { destructData } from '../../../dataFunctions';
import stylesFunction from '../../../styles';
import StyleContext from '../context';
// import { GET_CURRENT_THEME } from './queries';

interface IStyleProviderProps {
	theme?: IThemeConstants;
	children: React.ReactNode;
}

export const StyleProvider: FC<IStyleProviderProps> = (props) => {
	const [theme, onChangeTheme] = useState(lightTheme);

	const styles = useMemo(() => {
		return stylesFunction(theme);
	}, [theme]);
	// const { refetch } = useQuery(GET_CURRENT_THEME, {
	// 	onCompleted: (data) => {
	// 		onChangeTheme(destructData(data));
	// 	},
	// });
	return (
		<StyleContext.Provider
			value={{
				theme,
				setTheme: onChangeTheme,
				styles,
				refreshTheme: () => {
					console.log('asdf');
				},
			}}
		>
			{props.children}
		</StyleContext.Provider>
	);
};
