import { defineMessages } from 'react-intl';

export const scope = 'mango.HostEdit';
export default defineMessages({
	bank: {
		id: `${scope}.bank`,
		defaultMessage: 'bank',
	},
	bankTitle: {
		id: `${scope}.bankTitle`,
		defaultMessage: 'title',
	},
	swift: {
		id: `${scope}.swift`,
		defaultMessage: 'swift',
	},
	iban: {
		id: `${scope}.iban`,
		defaultMessage: 'iban',
	},
	court: {
		id: `${scope}.court`,
		defaultMessage: 'court',
	},
	director: {
		id: `${scope}.director`,
		defaultMessage: 'director',
	},
	header: {
		id: `${scope}.header`,
		defaultMessage: 'contractor data',
	},
	commissioning: {
		id: `${scope}.commissioning`,
		defaultMessage: 'commission',
	},
	commissioningError: {
		id: `${scope}.commissioningError`,
		defaultMessage: 'the comission is limited to 250 characters',
	},
	legalInformation: {
		id: `${scope}.legalInformation`,
		defaultMessage: 'legal information',
	},
	legalInformationError: {
		id: `${scope}.legalInformationError`,
		defaultMessage: 'the legal information is limited to 3000 characters',
	},
});
