import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MModuleSignSettings, MPipeline } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory, useParams } from '../../../utilities/routing';
import OrderListSettings from '../../Order/List/Settings';
import messages from './messages';
import { GET_SIGN_SETTINGS, SAVE_SIGN_SETTINGS } from './queries';

export const SignSettings: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { pipelineId } = useParams<{ pipelineId: string }>();
	const { settings } = useContext(SettingsContext);
	const [pipeline, onChangePipeline] = useState<MPipeline>();

	useEffect(() => {
		onChangePipeline(settings.pipelines.find((p) => p._id === pipelineId));
	}, [settings, pipelineId]);

	const [signSettings, onChangeSettings] = useState<MModuleSignSettings>();

	const { loading } = useQuery(GET_SIGN_SETTINGS, {
		skip: !pipelineId,
		variables: {
			pipelineId,
		},
		onCompleted: (data) => {
			if (data.moduleSignSettings) {
				onChangeSettings(data.moduleSignSettings);
			} else {
				onChangeSettings(new MModuleSignSettings());
			}
		},
	});

	const update = (input: Partial<MModuleSignSettings>) => {
		const next = Object.assign({}, signSettings, input);

		onChangeSettings(next);
	};
	// save signSettings
	const [saveSettings] = useMutation(SAVE_SIGN_SETTINGS);
	const saveButtonHandler = async () => {
		await saveSettings({
			variables: {
				signSettings: {
					...signSettings,
					pipelineId,
				},
			},
		});

		history.push('/signSettings');
	};

	// LOADING
	if (!signSettings || loading || !pipeline) {
		return <LoadingIndicator />;
	}

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => openDocs('#/content/module/sign')}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderListSettings
						update={update}
						dashboardFields={signSettings.dashboardFields}
						noSave
					/>
				</View>
			</ScrollView>
			{/* TODO: add input fields according to signSettings model */}
			<FinishButtonGroup
				saveFunction={saveButtonHandler}
				cancelFunction={history.goBack}
			/>
		</View>
	);
};
