import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { TextInput, View } from 'react-native';

import { alert } from '../../../../utilities/alert';
import { config } from '../../../../utilities/config';
import { useStyle } from '../../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import MundoButton from '../../../../components/elements/MundoButton';
import messages from './messages';

const SERVER_BASE_URL = config.API_URL;

const Forgot: FC = () => {
	const styles = useStyle();
	const [mail, onChangeMail] = React.useState('');
	const intl = useIntl();

	const sendMail = async () => {
		const postOptions = {
			headers: { mail },
			method: 'POST',
		};
		const fetchResult = await fetch(
			`${SERVER_BASE_URL}/resetMail`,
			postOptions,
		);
		if (fetchResult.status === 201) {
			alert(
				capitalizeFirstWord(intl.formatMessage(messages.mailSended)),
				capitalizeFirstWord(
					intl.formatMessage(messages.resetLinkSended),
				),
			);
		} else if (fetchResult.status === 404) {
			alert(
				capitalizeFirstWord(intl.formatMessage(messages.mailNotFound)),
				capitalizeFirstWord(intl.formatMessage(messages.plsCheckMail)),
			);
		} else {
			alert(
				capitalizeFirstWord(intl.formatMessage(messages.wentWrong)),
				capitalizeFirstWord(intl.formatMessage(messages.cantLogin)),
				'error',
			);
		}
	};
	return (
		<View
			style={[
				styles.verticalPadding10,
				styles.buttonGroupVertical,
				styles.fullWidth,
				styles.alignCenterHorizontally,
			]}
		>
			<TextInput
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.mail),
				)}
				style={[styles.input, styles.fullWidth]}
				onChangeText={(text) => onChangeMail(text)}
				value={mail}
			/>
			<MundoButton
				onPress={() => sendMail()}
				disabled={!mail}
				title={messages.sendMail}
				styles={styles.fullWidth}
			/>
		</View>
	);
};

export default Forgot;
