import { useLazyQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MTDriver } from 'mango-utils-client';
import {
	MundoButton,
	MundoCheckBox,
	MundoText,
} from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { useStyle } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { CSS_COLOR_NAMES } from '../../utilities/randomColor';
import { SFilterSectionContext } from '../FilterSection/FilterSection';
import messages from './messages';
import { IDriverFilterSectionProps } from './props';
import { GET_DRIVERS } from './queries';

export const DriverFilterSection: FC<IDriverFilterSectionProps> = ({
	dispatchSelectedDriverAction,
	selectedDrivers,
	driverColors,
	dispatchDriverColorAction,
	selectedCustomers,
}) => {
	const styles = useStyle();

	const {
		global,
		onChangeGlobal,
		globalFiltered,
		onChangeGlobalFiltered,
		useGlobal,
	} = useContext(SFilterSectionContext);

	const [useAllDrivers, onChangeUseAll] = useState<boolean>(false);

	const [getAllDrivers] = useLazyQuery(GET_DRIVERS, {
		variables: {
			any: {
				companyID: `${selectedCustomers.map((c) => c._id).join('|')}`,
			},
			count: 500,
		},
		onCompleted: (data) => {
			if (data) {
				Object.keys(data).forEach((key) => {
					if (key.length) {
						const Drivers = data[key];
						const colors = Drivers.map(
							() =>
								CSS_COLOR_NAMES[
									Math.floor(
										Math.random() * CSS_COLOR_NAMES.length,
									)
								],
						);
						dispatchSelectedDriverAction({
							type: ETypes.RESET_TO,
							item: Drivers,
						});
						dispatchDriverColorAction({
							type: ETypes.RESET_TO,
							item: colors,
						});
						onChangeUseAll(true);
					}
				});
			}
		},
	});

	return (
		<View style={[{ justifyContent: 'space-between' }]}>
			<View style={[styles.elevated, { maxHeight: 1000 }]}>
				<View style={[styles.elevated, styles.horizontalLayout]}>
					<View style={styles.elevated}>
						<MundoText message={messages.driver} />
						<MundoObjectTypeahead
							QUERY={GET_DRIVERS}
							onChange={(driver) => {
								if (
									driver &&
									!selectedDrivers.find(
										(sC) => driver._id === sC._id,
									)
								) {
									dispatchSelectedDriverAction({
										type: ETypes.ADD,
										item: new MTDriver({
											...driver,
											title: driver.title as string,
										}),
									});
									dispatchDriverColorAction({
										type: ETypes.ADD,
										item:
											CSS_COLOR_NAMES[
												Math.floor(
													Math.random() *
														CSS_COLOR_NAMES.length,
												)
											],
									});
								}
							}}
							additionalFilters={{
								companyID: `${selectedCustomers
									.map((c) => c._id)
									.join('|')}`,
							}}
						/>
					</View>
					<MundoCheckBox
						checked={useAllDrivers}
						onCheckedChanged={(c) => {
							if (c) {
								getAllDrivers();
							} else {
								dispatchSelectedDriverAction({
									type: ETypes.RESET_TO,
									item: [],
								});
								dispatchDriverColorAction({
									type: ETypes.RESET_TO,
									item: [],
								});
								onChangeUseAll(false);
							}
						}}
						title={messages.useAllDrivers}
					/>
				</View>
				<ScrollView>
					{selectedDrivers.map((Driver, index) => {
						return (
							<View
								style={
									styles.containerFullWidthHorizontalCenterAligned
								}
								key={Driver._id}
							>
								<MundoText message={Driver.title} />
								<MundoButton
									onPress={() =>
										dispatchDriverColorAction({
											type: ETypes.EDIT,
											item:
												CSS_COLOR_NAMES[
													Math.floor(
														Math.random() *
															CSS_COLOR_NAMES.length,
													)
												],
											location: index,
										})
									}
									styles={{
										backgroundColor: driverColors[index],
									}}
								/>
								<MundoButton
									onPress={() => {
										dispatchSelectedDriverAction({
											type: ETypes.REMOVE,
											location: index,
										});
										dispatchDriverColorAction({
											type: ETypes.REMOVE,
											location: index,
										});
									}}
									icon={'minus'}
								/>
							</View>
						);
					})}
				</ScrollView>
			</View>
			{useGlobal && (
				<View>
					<View
						style={styles.containerFullWidthHorizontalCenterAligned}
					>
						<MundoCheckBox
							checked={global}
							onCheckedChanged={onChangeGlobal}
							title={messages.global}
						/>
						<MundoButton
							onPress={() => console.log('')}
							styles={{ backgroundColor: 'green' }}
						/>
					</View>
					<MundoCheckBox
						checked={globalFiltered}
						onCheckedChanged={onChangeGlobalFiltered}
						title={messages.filterGlobal}
					/>
				</View>
			)}
		</View>
	);
};
