import { defineMessages } from 'react-intl';

export const scope = 'mango.container.SignEdit';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'sign settings',
	},
});
