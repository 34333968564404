import { MModuleDescription } from 'mango-utils-client';
import { MessageDescriptor } from 'react-intl';

export const transformModuleTitle = (
	module: MModuleDescription,
	isDashboard = false,
): MessageDescriptor => {
	return module.title
		? {
				id: `${module.title}${isDashboard ? 'Dashboard' : ''}`,
				defaultMessage: `${module.title}${
					isDashboard ? ' Dashboard' : ''
				}`,
		  }
		: {
				id: `module.${module.identifier
					.split('::')[0]
					.split('.')
					.reverse()
					.join('.')}${isDashboard ? 'Dashboard' : ''}`,
				defaultMessage: `${module.identifier}${
					isDashboard ? ' Dashboard' : ''
				}`,
		  };
};

export const transformModuleIcon = (module: MModuleDescription): string => {
	return module.identifier.split('.')[0];
};
