import { useQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { Image, ImageSourcePropType, ScrollView, View } from 'react-native';

import icons from '../../assets';
// import ChangeLog from '../../components/ChangeLog';
import MundoButton from '../../components/elements/MundoButton';
import MundoCard from '../../components/elements/MundoCard';
import MundoText from '../../components/elements/MundoText';
import Forgot from './components/Forgot';
import Login from './components/Login';
import Register from './components/Register';
import { destructData } from '../../utilities/dataFunctions';
import { useStyle, useTheme } from '../../utilities/hooks/styles';
import { Status } from './components/Status';

import messages from './messages';
import { GET_LOGO } from './queries';
import homepageStyles from './styles';
import { LanguageContext } from '../../utilities/contexts/Language';
import { useHistory } from '../../utilities/routing';

const DEFAULT_LOGO_HEIGHT = 64;

export const HomePage: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { theme } = useTheme();
	const [mode, onChangeMode] = useState(0);
	const [logo, onChangeLogo] = useState<string>();
	const { language } = useContext(LanguageContext);
	const [logoWidth, setLogoWidth] = useState(DEFAULT_LOGO_HEIGHT);
	const [logoHeight, setLogoHeight] = useState(DEFAULT_LOGO_HEIGHT);

	useQuery(GET_LOGO, {
		onCompleted: (data) => {
			const nextLogo = destructData<string>(data);
			onChangeLogo(nextLogo);
			Image.getSize(
				nextLogo,
				(width, height) => {
					setLogoHeight(DEFAULT_LOGO_HEIGHT);
					setLogoWidth((width * DEFAULT_LOGO_HEIGHT) / height);
				},
				() => {
					// log error
				},
			);
		},
	});

	return (
		<View
			{...{ dataSet: { cy: 'container' } }}
			style={homepageStyles(theme).homeContainer}
		>
			<Image
				source={(logo || icons.logoSmall) as ImageSourcePropType}
				style={[
					homepageStyles(theme).logo,
					{
						width: logoWidth,
						height: logoHeight,
					},
				]}
				resizeMode="contain"
			/>
			<ScrollView>
				<MundoCard alignment="center">
					{mode === 0 && (
						<View style={[styles.centeredContent]}>
							<View
								style={[
									styles.verticalPadding10,
									styles.alignCenterHorizontally,
								]}
							>
								<MundoText
									message={messages.welcomeText}
									bold
									styles={styles.h1}
								/>
							</View>
						</View>
					)}
					{mode === 1 && <Login />}
					{mode === 2 && <Register />}
					{mode === 3 && <Forgot />}
					<View style={styles.thinSeparator} />
					<View
						{...{ dataSet: { cy: 'buttons' } }}
						style={[
							styles.buttonGroupVertical,
							styles.verticalPadding10,
							styles.fullWidth,
							styles.alignCenterHorizontally,
						]}
					>
						{[
							messages.login,
							messages.register,
							messages.forgot,
						].map((button, index) => {
							if (index + 1 !== mode) {
								return (
									<MundoButton
										dataSet={{ cy: `button${index}` }}
										key={'button' + index}
										onPress={() => onChangeMode(index + 1)}
										title={button}
										styles={styles.fullWidth}
									/>
								);
							}
						})}
					</View>
					<View style={styles.thinSeparator} />
					<Status />
					<View style={styles.thinSeparator} />
					<MundoButton
						icon={language}
						onPress={() => history.push('/language')}
						coloredIcon
					/>
					{/* <View style={styles.thinSeparator} />
					<ChangeLog /> */}
				</MundoCard>
			</ScrollView>
		</View>
	);
};
