import React, { FC } from 'react';

import LoadingIndicator from '../components/LoadingIndicator';

export const Loader: FC<{
	isLoading: boolean;
	pastDelay: boolean;
	timedOut: boolean;
	error?: Error | undefined;
	retry: () => any;
}> = (props) => {
	if (props.error) {
		console.warn('404 could not find the document you are looking for');
		return null;
	} else {
		return <LoadingIndicator />;
	}
};
