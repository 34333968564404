import gql from 'graphql-tag';

export const SAVE_PACKAGE = gql`
	mutation savePackage($package: PackageInput!) {
		savePackage(package: $package) {
			id
		}
	}
`;

export const GET_PACKAGE = gql`
	query getPackage($id: String!) {
		package(id: $id) {
			_id
			id
			title {
				lang
				message
			}
			tags
			description {
				lang
				message
			}
			price
			companyId
			calculationFunctionName
			triggerFunctionName
			calculationArticleBundle {
				articles {
					_id
					id
					title {
						lang
						message
					}
					description {
						lang
						message
					}
					price
					tax
					unit
					subArticles {
						_id
						id
						title {
							lang
							message
						}
					}
					tags
					essentials
					mandatory
				}
				useAmount
				useUnderflowPrice
				lowerAmount
				amount
				upperAmount
				underflowPrice
				overflowPrice
			}
			includedArticles {
				articles {
					_id
					id
					title {
						lang
						message
					}
					description {
						lang
						message
					}
					price
					tax
					unit
					subArticles {
						_id
						id
						title {
							lang
							message
						}
					}
					tags
					essentials
					mandatory
				}
				useAmount
				useUnderflowPrice
				lowerAmount
				amount
				upperAmount
				underflowPrice
				overflowPrice
			}
		}
	}
`;

export const DELETE_PACKAGE = gql`
	mutation deletePackage($id: String!) {
		deletePackage(id: $id) {
			id
		}
	}
`;

export const GET_CUSTOMERS = gql`
	query getCustomers($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count) {
			_id
			id
			title
		}
	}
`;

export const GET_CUSTOMER = gql`
	query getCustomer($id: String!) {
		customer(id: $id) {
			_id
			id
			title
		}
	}
`;
