import React, { FC, useContext } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { LanguageContext } from '../../../utilities/contexts/Language';

import { useStyle } from '../../../utilities/hooks/styles';
import { getTranslation } from '../../../utilities/translations';
import MundoText from '../../elements/MundoText';
import { IBundledArticleViewProps } from '../props';
import messages from './messages';

const BundledArticleView: FC<IBundledArticleViewProps> = ({
	changeEditMode,
	bundle,
}) => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);
	const {
		articles,
		useAmount,
		useUnderflowPrice,
		useOverflowPrice,
		lowerAmount,
		amount,
		upperAmount,
		underflowPrice,
		overflowPrice,
	} = bundle;
	return (
		<>
			<TouchableOpacity
				{...{
					dataSet: {
						cy: `bundled.${bundle.articles.map(
							(a) => `${getTranslation(a.title, language)}`,
						)}.edit`,
					},
				}}
				onPress={() => changeEditMode(true)}
			>
				<View style={styles.wideGroupTable}>
					<View style={styles.wideGroupTableColumn15NoGrow}>
						{articles.length ? (
							articles.map((article) => (
								<MundoText key={article._id}>
									{getTranslation(article.title, language)}
								</MundoText>
							))
						) : (
							<MundoText message={messages.empty} />
						)}
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText
							message={useAmount ? messages.yes : messages.no}
						/>
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText
							message={
								useUnderflowPrice ? messages.yes : messages.no
							}
						/>
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText
							message={
								useOverflowPrice ? messages.yes : messages.no
							}
						/>
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText>{lowerAmount || ' '}</MundoText>
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText>{amount || ' '}</MundoText>
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText>
							{upperAmount === 2147483647 ? ' ' : upperAmount}
						</MundoText>
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText>{underflowPrice || ' '}</MundoText>
					</View>
					<View style={styles.wideGroupTableColumn10NoGrow}>
						<MundoText>{overflowPrice || ' '}</MundoText>
					</View>
				</View>
			</TouchableOpacity>
		</>
	);
};

export default BundledArticleView;
