import React, { FC, useContext, useState } from 'react';
import { View } from 'react-native';

import { MBundledArticle } from 'mango-utils-client';
import BundledArticleEdit from '../../../components/BundledArticle/Edit';
import { MundoButton, MundoText } from '../../../components/elements';
import { useStyle } from '../../../utilities/hooks/styles';
import { PackageEditContext } from '../Edit';
import messages from './messages';

const CalculationBundle: FC = () => {
	const { update, pack } = useContext(PackageEditContext);
	const styles = useStyle();
	const [editCalcBundle, toggleEditCalcBundle] = useState(false);

	return (
		<View>
			<MundoText
				message={messages.calculationBundle}
				styles={styles.topMargin20}
			/>
			{!editCalcBundle ? (
				<MundoButton
					styles={[styles.topMargin10, { alignSelf: 'flex-start' }]}
					title={messages.editCalculationBundle}
					onPress={() => toggleEditCalcBundle(true)}
				/>
			) : (
				<BundledArticleEdit
					bundle={
						pack.calculationArticleBundle
							? pack.calculationArticleBundle
							: new MBundledArticle()
					}
					changeEditMode={toggleEditCalcBundle}
					onChange={(bundle) => {
						update({ calculationArticleBundle: bundle });
					}}
				/>
			)}
		</View>
	);
};

export default CalculationBundle;
