export function remove(key: string) {
	return new Promise<string>((resolve, reject) => {
		try {
			// @ts-ignore // web specific stuff
			const res: string = localStorage.removeItem(key);
			resolve(res);
		} catch (e) {
			reject('error while deleting key: ' + key);
		}
	});
}
