import React, { FC } from 'react';
import { View } from 'react-native';

import { EUnit } from 'mango-utils-client';
import MundoPicker from '../../../../components/elements/MundoPicker';
import unitMessages from '../../../../messages';
import messages from '../messages';
import { IArticleEditFieldProps } from '../props';

export const UnitField: FC<IArticleEditFieldProps> = ({ value, update }) => {
	return (
		<View style={{ width: 174 }}>
			<MundoPicker
				dataSet={{ cy: 'article.unit.picker' }}
				tabelarLayout
				label={messages.unit}
				value={value.unit}
				onChange={(v: EUnit) => {
					update({
						unit: v,
					});
				}}
				values={Object.keys(EUnit).map((key) => {
					// @ts-ignore
					return { value: key, label: unitMessages[key + '_FULL'] };
				})}
			/>
		</View>
	);
};
