import React, { createContext, FC, useContext } from 'react';
import { ScrollView, View } from 'react-native';
import { useHistory } from 'react-router';
import { EPluign, getExtension } from '../../../Plugins';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { MundoText, MundoButton } from '../../elements';
import messages from '../messages';
import { IDataMenuProps } from '../props';
import sidebarStyles from '../styles';

export const DataManagementContext = createContext({
	detail: false,
	navigate: (path: string) => console.error('function not defined', path),
});

const DataMenu: FC<IDataMenuProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { isAdmin } = useContext(AuthContext);
	const history = useHistory();

	const navigate = (path: string) => {
		history.push(path);
		if (props.closeSidebar) {
			props.closeSidebar();
		}
	};
	return (
		<ScrollView>
			<View style={sidebarStyles(theme).titleContainer}>
				<MundoText
					isButtonText
					styles={styles.headerSecondaryText}
					message={messages.dataManagement}
				/>
			</View>
			<View style={sidebarStyles(theme).sidebarFiller}>
				<MundoButton
					dataSet={{ cy: 'sidebar.back' }}
					onPress={() => props.onChangeShowDataMenu(false)}
					title={props.detail ? messages.back : undefined}
					icon={'left'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<MundoButton
					onPress={() => navigate(`/orders`)}
					title={props.detail ? messages.orders : undefined}
					icon={'menu'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<MundoButton
					dataSet={{ cy: 'sidebar.customers' }}
					onPress={() => navigate('/customers')}
					title={props.detail ? messages.customers : undefined}
					icon={'cardCustomer'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<DataManagementContext.Provider
					value={{
						navigate,
						detail: props.detail,
					}}
				>
					{Object.keys(EPluign).map((plugin) => {
						const Extension = getExtension(
							'DataManagement',
							plugin as EPluign,
						);

						if (Extension) {
							return <Extension key={plugin} />;
						}
					})}
				</DataManagementContext.Provider>
				{isAdmin && (
					<MundoButton
						dataSet={{ cy: 'sidebar.proposals.button' }}
						onPress={() => navigate('/proposals')}
						title={props.detail ? messages.proposals : undefined}
						icon={'invoices'}
						styles={[
							sidebarStyles(theme).navButton,
							props.detail && sidebarStyles(theme).exNavButton,
						]}
					/>
				)}
				<MundoButton
					dataSet={{ cy: 'sidebar.groupInvoices.button' }}
					onPress={() => navigate('/groupInvoices')}
					title={props.detail ? messages.groupInvoices : undefined}
					icon={'invoices'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<MundoButton
					dataSet={{ cy: 'sidebar.credits.button' }}
					onPress={() => navigate('/credits')}
					title={props.detail ? messages.credits : undefined}
					icon={'invoices'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				{isAdmin && (
					<MundoButton
						dataSet={{ cy: 'sidebar.datev.button' }}
						onPress={() => navigate('/datevexport')}
						title={props.detail ? messages.datevExport : undefined}
						icon={'export'}
						styles={[
							sidebarStyles(theme).navButton,
							props.detail && sidebarStyles(theme).exNavButton,
						]}
					/>
				)}
				{isAdmin && (
					<MundoButton
						dataSet={{ cy: 'sidebar.archiv.button' }}
						onPress={() => navigate('/archiv')}
						title={props.detail ? messages.archiv : undefined}
						icon={'save2'}
						styles={[
							sidebarStyles(theme).navButton,
							props.detail && sidebarStyles(theme).exNavButton,
						]}
					/>
				)}
				{isAdmin && (
					<MundoButton
						dataSet={{ cy: 'sidebar.settings.button' }}
						onPress={() => navigate(`/settings`)}
						title={props.detail ? messages.settings : undefined}
						icon={'settings'}
						styles={[
							sidebarStyles(theme).navButton,
							props.detail && sidebarStyles(theme).exNavButton,
						]}
					/>
				)}
			</View>
		</ScrollView>
	);
};

export default DataMenu;
