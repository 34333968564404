import {
	ApolloClient,
	ApolloLink,
	concat,
	HttpLink,
	InMemoryCache,
	Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { alert } from '../alert';
import { removeTokens } from '../auth';
import { config } from '../config';
import { AuthContext } from '../hooks/auth';
import { load } from '../localStorage';
import messages from './messages';

export const useClient = () => {
	const { checkLogin } = useContext(AuthContext);
	const intl = useIntl();

	const client = useMemo(() => {
		console.log('Opening Apollo Client');
		const errorMiddleware = onError(
			({ graphQLErrors, networkError, operation, forward }) => {
				if (graphQLErrors && graphQLErrors.length > 0) {
					const message: any = graphQLErrors[0].message;
					if (message.statusCode === 401) {
						return new Observable((observer) => {
							checkLogin()
								.then((success) => {
									if (success) {
										// Restart
										operation.setContext({
											headers: {
												...operation.getContext()
													.headers,
												authorization: `Bearer ${success}`,
											},
										});
										forward(operation).subscribe(observer);
									} else {
										removeTokens();
									}
								})
								.catch(() => {
									removeTokens();
								});
						});
					} else {
						alert('GraphQL Error', message, 'error');
					}
				} else if (networkError) {
					const title = `${networkError.message}`;
					alert(
						title,
						intl.formatMessage(
							title === 'Failed to fetch'
								? messages.connection
								: messages.unnokwn,
						),
						'error',
					);
				}
			},
		);
		const authMiddleware = new ApolloLink((operation, forward) => {
			return new Observable((observer) => {
				load('access_token')
					.then((token) => {
						operation.setContext({
							headers: {
								authorization: token ? `Bearer ${token}` : '',
							},
						});
						// Continue
						forward(operation).subscribe(observer);
					})
					.catch((e) => console.error(e));
			});
		});
		const httpMiddleware = new HttpLink({
			uri: config.API_URL + '/graphql',
		});

		const next = new ApolloClient({
			cache: new InMemoryCache({
				addTypename: false,
			}),
			link: concat(
				concat(authMiddleware, errorMiddleware),
				httpMiddleware,
			),
		});

		next.defaultOptions = {
			watchQuery: {
				fetchPolicy: 'network-only',
			},
			query: {
				fetchPolicy: 'network-only',
			},
		};

		return next;
	}, [checkLogin, intl]);

	return client;
};
