import React, { FC, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import MundoIcon from '../MundoIcon';
import MundoText from '../MundoText';
import { IMundoButtonProps } from './props';
import mundoButtonStyles from './styles';

const MundoButton: FC<IMundoButtonProps> = (props) => {
	const ref = useRef(null);
	const { theme } = useTheme();
	const styles = useStyle();
	const isHovered = useHover(ref);

	const [hasBeenClicked, onChangedClicked] = useState<boolean>(false);

	let iconSize = 24;
	if (props.subtype === 'transparent') {
		iconSize = 16;
	} else if (props.subtype === 'checkbox') {
		iconSize = 15;
	} else if (props.subtype === 'small') {
		iconSize = 14;
	}

	const styleArray = [];
	if (props.subtype === 'checkbox') {
		styleArray.push(mundoButtonStyles(theme).buttonCheckbox);
	} else if (props.subtype === 'small') {
		styleArray.push(mundoButtonStyles(theme).buttonSmall);
	} else {
		styleArray.push(mundoButtonStyles(theme).button);
	}

	if (props.subtype === 'transparent') {
		styleArray.push(styles.transparentButton);
	}

	return (
		<>
			<TouchableOpacity
				{...{ dataSet: props.dataSet }}
				ref={ref}
				style={[
					styleArray,
					props.styles,
					props.disabled || hasBeenClicked ? styles.disabled : null,
					isHovered && props.stylesHovered,
				]}
				onPress={() => {
					if (props.disabled || hasBeenClicked) {
						if (props.onPressDisabled) {
							props.onPressDisabled();
						}
					} else {
						if (props.disableOnClick) {
							onChangedClicked(true);
						}
						props.onPress();
					}
				}}
			>
				<View
					style={
						props.vertical
							? mundoButtonStyles(theme).vertical
							: mundoButtonStyles(theme).horizontal
					}
				>
					{props.icon && (
						<View
							style={
								props.title
									? props.vertical
										? mundoButtonStyles(theme)
												.buttonIconContainerVertical
										: mundoButtonStyles(theme)
												.buttonIconContainer
									: {}
							}
						>
							<MundoIcon
								icon={props.icon}
								fontSize={iconSize}
								styles={
									!props.coloredIcon && {
										tintColor:
											props.subtype === 'small'
												? 'white'
												: 'black',
									}
								}
							/>
						</View>
					)}
					{!!props.title && (
						<View>
							<MundoText
								isButtonText
								styles={props.fontStyles}
								message={props.title}
							/>
						</View>
					)}
				</View>
				{props.children}
			</TouchableOpacity>
			{props.disabled && !!props.reason && (
				<MundoText styles={styles.warning} message={props.reason} />
			)}
		</>
	);
};

export default MundoButton;
