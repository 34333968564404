import React from 'react';
import { FC } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { MundoText } from '../../../../components/elements';
import { useStyle, useTheme } from '../../../../utilities/hooks/styles';
import { orderOverviewStyles } from '../styles';
import { IStateButtonsProps } from './props';

export const StateButtons: FC<IStateButtonsProps> = ({
	buttons,
	onChangeCurrentIndex,
	currentIndex,
}) => {
	const { theme } = useTheme();
	const styles = useStyle();
	return (
		<View style={styles.spacedContainerHorizontal}>
			<ScrollView horizontal>
				{buttons.map((button, buttonIndex) => (
					<View
						key={`Order${button.title}_${buttonIndex}`}
						style={styles.paddedView}
					>
						<TouchableOpacity
							style={[
								orderOverviewStyles(theme).stateInactive,
								button.active && // there is a ready state on order for this module
									orderOverviewStyles(theme).stateActive,
								button.targetIndex !== -1 && // there is a done state on order for this module
									orderOverviewStyles(theme).stateDone,
								currentIndex === buttonIndex && // you currently view this one
									orderOverviewStyles(theme).stateSelected,
							]}
							onPress={() => {
								if (button.targetIndex > -1) {
									onChangeCurrentIndex(buttonIndex);
								}
							}}
						>
							<MundoText
								message={{
									id: `module.${button.title
										.split('::')[0]
										.split('.')
										.reverse()
										.join('.')}`,
								}}
							/>
						</TouchableOpacity>
					</View>
				))}
			</ScrollView>
		</View>
	);
};
