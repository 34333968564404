import gql from 'graphql-tag';

export const INVOICE_IN = gql`
	query moduleInvoiceIn($id: String!) {
		moduleInvoiceIn(id: $id) {
			id
			orderId
			items {
				id
				_id
				title {
					lang
					message
				}
				short
				description {
					lang
					message
				}
				price
				tax
				unit
				subArticles {
					id
					_id
				}
				tags
				essentials
				mandatory
				articleType
				articleId
				source
				amount
				checked
				highlighted
				data
				comment
			}
			packages {
				_id
				id
				title {
					lang
					message
				}
				description {
					lang
					message
				}
				price
				includedArticles {
					articles {
						id
						_id
						title {
							lang
							message
						}
						description {
							lang
							message
						}
					}
					useAmount
					useUnderflowPrice
					useOverflowPrice
					lowerAmount
					amount
					upperAmount
					underflowPrice
					overflowPrice
				}
				calculationFunctionName
				triggerFunctionName
				calculationArticleBundle {
					articles {
						id
						_id
						title {
							lang
							message
						}
						price
						unit
						description {
							lang
							message
						}
					}
					useAmount
					useUnderflowPrice
					useOverflowPrice
					lowerAmount
					amount
					upperAmount
					underflowPrice
					overflowPrice
				}
			}
			tags
			customer {
				_id
				id
				title
				firstName
				lastName
				isPerson
				tags
				address {
					street
					city
					country
					zipCode
					tags
				}
				billingAddress {
					street
					city
					country
					zipCode
					tags
				}
				ustID
				allegiance
				taxNr
				externalId
				groupInvoiceTime
				defaultPaymentTime
				mail
				fax
				phone
				discounts
				defaultPaymentMethod {
					_id
					id
					title
				}
				enablePaymentMethodByTime
				paymentMethodByTime {
					paymentMethod {
						_id
						id
						title
					}
					fromh
					toh
					fromm
					tom
					day
				}
			}
			previous {
				_id
				positions {
					id
					_id
					title {
						lang
						message
					}
					short
					description {
						lang
						message
					}
					price
					tax
					unit
					subArticles {
						id
						_id
					}
					tags
					essentials
					mandatory
					articleType
					articleId
					source
					amount
					checked
					highlighted
					data
					comment
				}
				paymentMethod {
					id
					surcharge
				}
			}
		}
	}
`;

export const INVOICE_SETTINGS = gql`
	query getInvoiceSettings($pipelineId: String!) {
		moduleInvoiceSettings(pipelineId: $pipelineId) {
			id
			_id
			title
			useProposal
			dashboardFields
			useProposal
			pipelineId
			packages {
				title {
					lang
					message
				}
				description {
					lang
					message
				}
				price
				id
				_id
				includedArticles {
					articles {
						id
						_id
						title {
							lang
							message
						}
						description {
							lang
							message
						}
					}
					useAmount
					useUnderflowPrice
					useOverflowPrice
					lowerAmount
					amount
					upperAmount
					underflowPrice
					overflowPrice
				}
				tags
				calculationArticleBundle {
					articles {
						id
						_id
						title {
							lang
							message
						}
						price
						unit
						description {
							lang
							message
						}
					}
					useAmount
					useUnderflowPrice
					useOverflowPrice
					lowerAmount
					amount
					upperAmount
					underflowPrice
					overflowPrice
				}
				calculationFunctionName
				triggerFunctionName
			}
			paymentMethods {
				id
				_id
				title
				surcharge
				paymentTime
				description
				cashback
				cashbackAmount
				cashbackTime
				tags
				customer {
					id
					_id
					title
					externalId
					address {
						street
						city
						country
						zipCode
						tags
					}
					billingAddress {
						street
						city
						country
						zipCode
						tags
					}
					taxNr
					tags
					ustID
					allegiance
				}
			}
		}
	}
`;

export const INVOICE_OUT = gql`
	mutation moduleInvoiceOut(
		$state: StateInput!
		$orderId: String
		$invoice: InvoiceInput!
		$groupInvoice: GroupInvoiceInput
		$pipelineId: String!
	) {
		moduleInvoiceOut(
			invoice: {
				output: {
					state: $state
					orderId: $orderId
					invoice: $invoice
					groupInvoice: $groupInvoice
					pipelineId: $pipelineId
				}
			}
		)
	}
`;

export const SENSE_GROUP_INVOICE = gql`
	query($date: Float!, $customer: CustomerInput!) {
		senseGroupInvoice(date: $date, customer: $customer) {
			_id
			id
			paymentMethod {
				title
			}
			startDate
			endDate
			dueDate
		}
	}
`;

export const SAVE_INVOICE_SETTINGS = gql`
	mutation saveInvoiceSettings(
		$invoiceSettings: MModuleInvoiceSettingsInput!
	) {
		saveInvoiceSettings(invoiceSettings: $invoiceSettings) {
			_id
		}
	}
`;
