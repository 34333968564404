import { defineMessages } from 'react-intl';

export const scope = 'mango.components.ParentEdit';

export default defineMessages({
	error: {
		id: `${scope}.error`,
		defaultMessage: 'ERROR: no edit found!',
	},
});
