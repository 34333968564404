export function save(key: string, value: string) {
	return new Promise((resolve, reject) => {
		try {
			// web specific stuff
			/* eslint-disable no-undef */
			// @ts-ignore
			resolve(localStorage.setItem(key, value)).then((anything) => {
				console.log(anything);
			});
			/* eslint-enable */
		} catch (e) {
			reject(e);
		}
	});
}
