import { defineMessages } from 'react-intl';

export const scope = 'mango.components.CountryPicker';

export default defineMessages({
	country: {
		id: `${scope}.country`,
		defaultMessage: 'country',
	},
	Afghanistan: {
		id: `${scope}.Afghanistan`,
		defaultMessage: `Afghanistan`,
	},
	AlandIslands: {
		id: `${scope}.AlandIslands`,
		defaultMessage: `AlandIslands`,
	},
	Albania: {
		id: `${scope}.Albania`,
		defaultMessage: `Albania`,
	},
	Algeria: {
		id: `${scope}.Algeria`,
		defaultMessage: `Algeria`,
	},
	AmericanSamoa: {
		id: `${scope}.AmericanSamoa`,
		defaultMessage: `AmericanSamoa`,
	},
	Andorra: {
		id: `${scope}.Andorra`,
		defaultMessage: `Andorra`,
	},
	Angola: {
		id: `${scope}.Angola`,
		defaultMessage: `Angola`,
	},
	Anguilla: {
		id: `${scope}.Anguilla`,
		defaultMessage: `Anguilla`,
	},
	Antarctica: {
		id: `${scope}.Antarctica`,
		defaultMessage: `Antarctica`,
	},
	AntiguaAndBarbuda: {
		id: `${scope}.AntiguaAndBarbuda`,
		defaultMessage: `AntiguaAndBarbuda`,
	},
	Argentina: {
		id: `${scope}.Argentina`,
		defaultMessage: `Argentina`,
	},
	Armenia: {
		id: `${scope}.Armenia`,
		defaultMessage: `Armenia`,
	},
	Aruba: {
		id: `${scope}.Aruba`,
		defaultMessage: `Aruba`,
	},
	Australia: {
		id: `${scope}.Australia`,
		defaultMessage: `Australia`,
	},
	Austria: {
		id: `${scope}.Austria`,
		defaultMessage: `Austria`,
	},
	Azerbaijan: {
		id: `${scope}.Azerbaijan`,
		defaultMessage: `Azerbaijan`,
	},
	Bahamas: {
		id: `${scope}.Bahamas`,
		defaultMessage: `Bahamas`,
	},
	Bahrain: {
		id: `${scope}.Bahrain`,
		defaultMessage: `Bahrain`,
	},
	Bangladesh: {
		id: `${scope}.Bangladesh`,
		defaultMessage: `Bangladesh`,
	},
	Barbados: {
		id: `${scope}.Barbados`,
		defaultMessage: `Barbados`,
	},
	Belarus: {
		id: `${scope}.Belarus`,
		defaultMessage: `Belarus`,
	},
	Belgium: {
		id: `${scope}.Belgium`,
		defaultMessage: `Belgium`,
	},
	Belize: {
		id: `${scope}.Belize`,
		defaultMessage: `Belize`,
	},
	Benin: {
		id: `${scope}.Benin`,
		defaultMessage: `Benin`,
	},
	Bermuda: {
		id: `${scope}.Bermuda`,
		defaultMessage: `Bermuda`,
	},
	Bhutan: {
		id: `${scope}.Bhutan`,
		defaultMessage: `Bhutan`,
	},
	Bolivia: {
		id: `${scope}.Bolivia`,
		defaultMessage: `Bolivia`,
	},
	BosniaAndHerzegovina: {
		id: `${scope}.BosniaAndHerzegovina`,
		defaultMessage: `BosniaAndHerzegovina`,
	},
	Botswana: {
		id: `${scope}.Botswana`,
		defaultMessage: `Botswana`,
	},
	BouvetIsland: {
		id: `${scope}.BouvetIsland`,
		defaultMessage: `BouvetIsland`,
	},
	Brazil: {
		id: `${scope}.Brazil`,
		defaultMessage: `Brazil`,
	},
	BritishIndianOceanTerritory: {
		id: `${scope}.BritishIndianOceanTerritory`,
		defaultMessage: `BritishIndianOceanTerritory`,
	},
	BruneiDarussalam: {
		id: `${scope}.BruneiDarussalam`,
		defaultMessage: `BruneiDarussalam`,
	},
	Bulgaria: {
		id: `${scope}.Bulgaria`,
		defaultMessage: `Bulgaria`,
	},
	BurkinaFaso: {
		id: `${scope}.BurkinaFaso`,
		defaultMessage: `BurkinaFaso`,
	},
	Burundi: {
		id: `${scope}.Burundi`,
		defaultMessage: `Burundi`,
	},
	Cambodia: {
		id: `${scope}.Cambodia`,
		defaultMessage: `Cambodia`,
	},
	Cameroon: {
		id: `${scope}.Cameroon`,
		defaultMessage: `Cameroon`,
	},
	Canada: {
		id: `${scope}.Canada`,
		defaultMessage: `Canada`,
	},
	CapeVerde: {
		id: `${scope}.CapeVerde`,
		defaultMessage: `CapeVerde`,
	},
	CaymanIslands: {
		id: `${scope}.CaymanIslands`,
		defaultMessage: `CaymanIslands`,
	},
	CentralAfricanRepublic: {
		id: `${scope}.CentralAfricanRepublic`,
		defaultMessage: `CentralAfricanRepublic`,
	},
	Chad: {
		id: `${scope}.Chad`,
		defaultMessage: `Chad`,
	},
	Chile: {
		id: `${scope}.Chile`,
		defaultMessage: `Chile`,
	},
	China: {
		id: `${scope}.China`,
		defaultMessage: `China`,
	},
	ChristmasIsland: {
		id: `${scope}.ChristmasIsland`,
		defaultMessage: `ChristmasIsland`,
	},
	CocosKeelingIslands: {
		id: `${scope}.CocosKeelingIslands`,
		defaultMessage: `CocosKeelingIslands`,
	},
	Colombia: {
		id: `${scope}.Colombia`,
		defaultMessage: `Colombia`,
	},
	Comoros: {
		id: `${scope}.Comoros`,
		defaultMessage: `Comoros`,
	},
	Congo: {
		id: `${scope}.Congo`,
		defaultMessage: `Congo`,
	},
	CongoDemocraticRepublic: {
		id: `${scope}.CongoDemocraticRepublic`,
		defaultMessage: `CongoDemocraticRepublic`,
	},
	CookIslands: {
		id: `${scope}.CookIslands`,
		defaultMessage: `CookIslands`,
	},
	CostaRica: {
		id: `${scope}.CostaRica`,
		defaultMessage: `CostaRica`,
	},
	CoteDIvoire: {
		id: `${scope}.CoteDIvoire`,
		defaultMessage: `CoteDIvoire`,
	},
	Croatia: {
		id: `${scope}.Croatia`,
		defaultMessage: `Croatia`,
	},
	Cuba: {
		id: `${scope}.Cuba`,
		defaultMessage: `Cuba`,
	},
	Cyprus: {
		id: `${scope}.Cyprus`,
		defaultMessage: `Cyprus`,
	},
	CzechRepublic: {
		id: `${scope}.CzechRepublic`,
		defaultMessage: `CzechRepublic`,
	},
	Denmark: {
		id: `${scope}.Denmark`,
		defaultMessage: `Denmark`,
	},
	Djibouti: {
		id: `${scope}.Djibouti`,
		defaultMessage: `Djibouti`,
	},
	Dominica: {
		id: `${scope}.Dominica`,
		defaultMessage: `Dominica`,
	},
	DominicanRepublic: {
		id: `${scope}.DominicanRepublic`,
		defaultMessage: `DominicanRepublic`,
	},
	Ecuador: {
		id: `${scope}.Ecuador`,
		defaultMessage: `Ecuador`,
	},
	Egypt: {
		id: `${scope}.Egypt`,
		defaultMessage: `Egypt`,
	},
	ElSalvador: {
		id: `${scope}.ElSalvador`,
		defaultMessage: `ElSalvador`,
	},
	EquatorialGuinea: {
		id: `${scope}.EquatorialGuinea`,
		defaultMessage: `EquatorialGuinea`,
	},
	Eritrea: {
		id: `${scope}.Eritrea`,
		defaultMessage: `Eritrea`,
	},
	Estonia: {
		id: `${scope}.Estonia`,
		defaultMessage: `Estonia`,
	},
	Ethiopia: {
		id: `${scope}.Ethiopia`,
		defaultMessage: `Ethiopia`,
	},
	FalklandIslands: {
		id: `${scope}.FalklandIslands`,
		defaultMessage: `FalklandIslands`,
	},
	FaroeIslands: {
		id: `${scope}.FaroeIslands`,
		defaultMessage: `FaroeIslands`,
	},
	Fiji: {
		id: `${scope}.Fiji`,
		defaultMessage: `Fiji`,
	},
	Finland: {
		id: `${scope}.Finland`,
		defaultMessage: `Finland`,
	},
	France: {
		id: `${scope}.France`,
		defaultMessage: `France`,
	},
	FrenchGuiana: {
		id: `${scope}.FrenchGuiana`,
		defaultMessage: `FrenchGuiana`,
	},
	FrenchPolynesia: {
		id: `${scope}.FrenchPolynesia`,
		defaultMessage: `FrenchPolynesia`,
	},
	FrenchSouthernTerritories: {
		id: `${scope}.FrenchSouthernTerritories`,
		defaultMessage: `FrenchSouthernTerritories`,
	},
	Gabon: {
		id: `${scope}.Gabon`,
		defaultMessage: `Gabon`,
	},
	Gambia: {
		id: `${scope}.Gambia`,
		defaultMessage: `Gambia`,
	},
	Georgia: {
		id: `${scope}.Georgia`,
		defaultMessage: `Georgia`,
	},
	Germany: {
		id: `${scope}.Germany`,
		defaultMessage: `Germany`,
	},
	Ghana: {
		id: `${scope}.Ghana`,
		defaultMessage: `Ghana`,
	},
	Gibraltar: {
		id: `${scope}.Gibraltar`,
		defaultMessage: `Gibraltar`,
	},
	Greece: {
		id: `${scope}.Greece`,
		defaultMessage: `Greece`,
	},
	Greenland: {
		id: `${scope}.Greenland`,
		defaultMessage: `Greenland`,
	},
	Grenada: {
		id: `${scope}.Grenada`,
		defaultMessage: `Grenada`,
	},
	Guadeloupe: {
		id: `${scope}.Guadeloupe`,
		defaultMessage: `Guadeloupe`,
	},
	Guam: {
		id: `${scope}.Guam`,
		defaultMessage: `Guam`,
	},
	Guatemala: {
		id: `${scope}.Guatemala`,
		defaultMessage: `Guatemala`,
	},
	Guernsey: {
		id: `${scope}.Guernsey`,
		defaultMessage: `Guernsey`,
	},
	Guinea: {
		id: `${scope}.Guinea`,
		defaultMessage: `Guinea`,
	},
	GuineaBissau: {
		id: `${scope}.GuineaBissau`,
		defaultMessage: `GuineaBissau`,
	},
	Guyana: {
		id: `${scope}.Guyana`,
		defaultMessage: `Guyana`,
	},
	Haiti: {
		id: `${scope}.Haiti`,
		defaultMessage: `Haiti`,
	},
	HeardIslandMcdonaldIslands: {
		id: `${scope}.HeardIslandMcdonaldIslands`,
		defaultMessage: `HeardIslandMcdonaldIslands`,
	},
	HolySeeVaticanCityState: {
		id: `${scope}.HolySeeVaticanCityState`,
		defaultMessage: `HolySeeVaticanCityState`,
	},
	Honduras: {
		id: `${scope}.Honduras`,
		defaultMessage: `Honduras`,
	},
	HongKong: {
		id: `${scope}.HongKong`,
		defaultMessage: `HongKong`,
	},
	Hungary: {
		id: `${scope}.Hungary`,
		defaultMessage: `Hungary`,
	},
	Iceland: {
		id: `${scope}.Iceland`,
		defaultMessage: `Iceland`,
	},
	India: {
		id: `${scope}.India`,
		defaultMessage: `India`,
	},
	Indonesia: {
		id: `${scope}.Indonesia`,
		defaultMessage: `Indonesia`,
	},
	Iran: {
		id: `${scope}.Iran`,
		defaultMessage: `Iran`,
	},
	Iraq: {
		id: `${scope}.Iraq`,
		defaultMessage: `Iraq`,
	},
	Ireland: {
		id: `${scope}.Ireland`,
		defaultMessage: `Ireland`,
	},
	IsleOfMan: {
		id: `${scope}.IsleOfMan`,
		defaultMessage: `IsleOfMan`,
	},
	Israel: {
		id: `${scope}.Israel`,
		defaultMessage: `Israel`,
	},
	Italy: {
		id: `${scope}.Italy`,
		defaultMessage: `Italy`,
	},
	Jamaica: {
		id: `${scope}.Jamaica`,
		defaultMessage: `Jamaica`,
	},
	Japan: {
		id: `${scope}.Japan`,
		defaultMessage: `Japan`,
	},
	Jersey: {
		id: `${scope}.Jersey`,
		defaultMessage: `Jersey`,
	},
	Jordan: {
		id: `${scope}.Jordan`,
		defaultMessage: `Jordan`,
	},
	Kazakhstan: {
		id: `${scope}.Kazakhstan`,
		defaultMessage: `Kazakhstan`,
	},
	Kenya: {
		id: `${scope}.Kenya`,
		defaultMessage: `Kenya`,
	},
	Kiribati: {
		id: `${scope}.Kiribati`,
		defaultMessage: `Kiribati`,
	},
	Korea: {
		id: `${scope}.Korea`,
		defaultMessage: `Korea`,
	},
	Kuwait: {
		id: `${scope}.Kuwait`,
		defaultMessage: `Kuwait`,
	},
	Kyrgyzstan: {
		id: `${scope}.Kyrgyzstan`,
		defaultMessage: `Kyrgyzstan`,
	},
	LaoPeoplesDemocraticRepublic: {
		id: `${scope}.LaoPeoplesDemocraticRepublic`,
		defaultMessage: `LaoPeoplesDemocraticRepublic`,
	},
	Latvia: {
		id: `${scope}.Latvia`,
		defaultMessage: `Latvia`,
	},
	Lebanon: {
		id: `${scope}.Lebanon`,
		defaultMessage: `Lebanon`,
	},
	Lesotho: {
		id: `${scope}.Lesotho`,
		defaultMessage: `Lesotho`,
	},
	Liberia: {
		id: `${scope}.Liberia`,
		defaultMessage: `Liberia`,
	},
	LibyanArabJamahiriya: {
		id: `${scope}.LibyanArabJamahiriya`,
		defaultMessage: `LibyanArabJamahiriya`,
	},
	Liechtenstein: {
		id: `${scope}.Liechtenstein`,
		defaultMessage: `Liechtenstein`,
	},
	Lithuania: {
		id: `${scope}.Lithuania`,
		defaultMessage: `Lithuania`,
	},
	Luxembourg: {
		id: `${scope}.Luxembourg`,
		defaultMessage: `Luxembourg`,
	},
	Macao: {
		id: `${scope}.Macao`,
		defaultMessage: `Macao`,
	},
	Macedonia: {
		id: `${scope}.Macedonia`,
		defaultMessage: `Macedonia`,
	},
	Madagascar: {
		id: `${scope}.Madagascar`,
		defaultMessage: `Madagascar`,
	},
	Malawi: {
		id: `${scope}.Malawi`,
		defaultMessage: `Malawi`,
	},
	Malaysia: {
		id: `${scope}.Malaysia`,
		defaultMessage: `Malaysia`,
	},
	Maldives: {
		id: `${scope}.Maldives`,
		defaultMessage: `Maldives`,
	},
	Mali: {
		id: `${scope}.Mali`,
		defaultMessage: `Mali`,
	},
	Malta: {
		id: `${scope}.Malta`,
		defaultMessage: `Malta`,
	},
	MarshallIslands: {
		id: `${scope}.MarshallIslands`,
		defaultMessage: `MarshallIslands`,
	},
	Martinique: {
		id: `${scope}.Martinique`,
		defaultMessage: `Martinique`,
	},
	Mauritania: {
		id: `${scope}.Mauritania`,
		defaultMessage: `Mauritania`,
	},
	Mauritius: {
		id: `${scope}.Mauritius`,
		defaultMessage: `Mauritius`,
	},
	Mayotte: {
		id: `${scope}.Mayotte`,
		defaultMessage: `Mayotte`,
	},
	Mexico: {
		id: `${scope}.Mexico`,
		defaultMessage: `Mexico`,
	},
	Micronesia: {
		id: `${scope}.Micronesia`,
		defaultMessage: `Micronesia`,
	},
	Moldova: {
		id: `${scope}.Moldova`,
		defaultMessage: `Moldova`,
	},
	Monaco: {
		id: `${scope}.Monaco`,
		defaultMessage: `Monaco`,
	},
	Mongolia: {
		id: `${scope}.Mongolia`,
		defaultMessage: `Mongolia`,
	},
	Montenegro: {
		id: `${scope}.Montenegro`,
		defaultMessage: `Montenegro`,
	},
	Montserrat: {
		id: `${scope}.Montserrat`,
		defaultMessage: `Montserrat`,
	},
	Morocco: {
		id: `${scope}.Morocco`,
		defaultMessage: `Morocco`,
	},
	Mozambique: {
		id: `${scope}.Mozambique`,
		defaultMessage: `Mozambique`,
	},
	Myanmar: {
		id: `${scope}.Myanmar`,
		defaultMessage: `Myanmar`,
	},
	Namibia: {
		id: `${scope}.Namibia`,
		defaultMessage: `Namibia`,
	},
	Nauru: {
		id: `${scope}.Nauru`,
		defaultMessage: `Nauru`,
	},
	Nepal: {
		id: `${scope}.Nepal`,
		defaultMessage: `Nepal`,
	},
	Netherlands: {
		id: `${scope}.Netherlands`,
		defaultMessage: `Netherlands`,
	},
	NetherlandsAntilles: {
		id: `${scope}.NetherlandsAntilles`,
		defaultMessage: `NetherlandsAntilles`,
	},
	NewCaledonia: {
		id: `${scope}.NewCaledonia`,
		defaultMessage: `NewCaledonia`,
	},
	NewZealand: {
		id: `${scope}.NewZealand`,
		defaultMessage: `NewZealand`,
	},
	Nicaragua: {
		id: `${scope}.Nicaragua`,
		defaultMessage: `Nicaragua`,
	},
	Niger: {
		id: `${scope}.Niger`,
		defaultMessage: `Niger`,
	},
	Nigeria: {
		id: `${scope}.Nigeria`,
		defaultMessage: `Nigeria`,
	},
	Niue: {
		id: `${scope}.Niue`,
		defaultMessage: `Niue`,
	},
	NorfolkIsland: {
		id: `${scope}.NorfolkIsland`,
		defaultMessage: `NorfolkIsland`,
	},
	NorthernMarianaIslands: {
		id: `${scope}.NorthernMarianaIslands`,
		defaultMessage: `NorthernMarianaIslands`,
	},
	Norway: {
		id: `${scope}.Norway`,
		defaultMessage: `Norway`,
	},
	Oman: {
		id: `${scope}.Oman`,
		defaultMessage: `Oman`,
	},
	Pakistan: {
		id: `${scope}.Pakistan`,
		defaultMessage: `Pakistan`,
	},
	Palau: {
		id: `${scope}.Pakistan`,
		defaultMessage: `Pakistan`,
	},
	PalestinianTerritory: {
		id: `${scope}.PalestinianTerritory`,
		defaultMessage: `PalestinianTerritory`,
	},
	Panama: {
		id: `${scope}.Panama`,
		defaultMessage: `Panama`,
	},
	PapuaNewGuinea: {
		id: `${scope}.PapuaNewGuinea`,
		defaultMessage: `PapuaNewGuinea`,
	},
	Paraguay: {
		id: `${scope}.Paraguay`,
		defaultMessage: `Paraguay`,
	},
	Peru: {
		id: `${scope}.Peru`,
		defaultMessage: `Peru`,
	},
	Philippines: {
		id: `${scope}.Philippines`,
		defaultMessage: `Philippines`,
	},
	Pitcairn: {
		id: `${scope}.Pitcairn`,
		defaultMessage: `Pitcairn`,
	},
	Poland: {
		id: `${scope}.Poland`,
		defaultMessage: `Poland`,
	},
	Portugal: {
		id: `${scope}.Portugal`,
		defaultMessage: `Portugal`,
	},
	PuertoRico: {
		id: `${scope}.PuertoRico`,
		defaultMessage: `PuertoRico`,
	},
	Qatar: {
		id: `${scope}.Qatar`,
		defaultMessage: `Qatar`,
	},
	Reunion: {
		id: `${scope}.Reunion`,
		defaultMessage: `Reunion`,
	},
	Romania: {
		id: `${scope}.Romania`,
		defaultMessage: `Romania`,
	},
	RussianFederation: {
		id: `${scope}.RussianFederation`,
		defaultMessage: `RussianFederation`,
	},
	Rwanda: {
		id: `${scope}.Rwanda`,
		defaultMessage: `Rwanda`,
	},
	SaintBarthelemy: {
		id: `${scope}.SaintBarthelemy`,
		defaultMessage: `SaintBarthelemy`,
	},
	SaintHelena: {
		id: `${scope}.SaintHelena`,
		defaultMessage: `SaintHelena`,
	},
	SaintKittsAndNevis: {
		id: `${scope}.SaintKittsAndNevis`,
		defaultMessage: `SaintKittsAndNevis`,
	},
	SaintLucia: {
		id: `${scope}.SaintLucia`,
		defaultMessage: `SaintLucia`,
	},
	SaintMartin: {
		id: `${scope}.SaintMartin`,
		defaultMessage: `SaintMartin`,
	},
	SaintPierreAndMiquelon: {
		id: `${scope}.SaintPierreAndMiquelon`,
		defaultMessage: `SaintPierreAndMiquelon`,
	},
	SaintVincentAndGrenadines: {
		id: `${scope}.SaintVincentAndGrenadines`,
		defaultMessage: `SaintVincentAndGrenadines`,
	},
	Samoa: {
		id: `${scope}.Samoa`,
		defaultMessage: `Samoa`,
	},
	SanMarino: {
		id: `${scope}.SanMarino`,
		defaultMessage: `SanMarino`,
	},
	SaoTomeAndPrincipe: {
		id: `${scope}.SaoTomeAndPrincipe`,
		defaultMessage: `SaoTomeAndPrincipe`,
	},
	SaudiArabia: {
		id: `${scope}.SaudiArabia`,
		defaultMessage: `SaudiArabia`,
	},
	Senegal: {
		id: `${scope}.Senegal`,
		defaultMessage: `Senegal`,
	},
	Serbia: {
		id: `${scope}.Serbia`,
		defaultMessage: `Serbia`,
	},
	Seychelles: {
		id: `${scope}.Seychelles`,
		defaultMessage: `Seychelles`,
	},
	SierraLeone: {
		id: `${scope}.SierraLeone`,
		defaultMessage: `SierraLeone`,
	},
	Singapore: {
		id: `${scope}.Singapore`,
		defaultMessage: `Singapore`,
	},
	Slovakia: {
		id: `${scope}.Slovakia`,
		defaultMessage: `Slovakia`,
	},
	Slovenia: {
		id: `${scope}.Slovenia`,
		defaultMessage: `Slovenia`,
	},
	SolomonIslands: {
		id: `${scope}.SolomonIslands`,
		defaultMessage: `SolomonIslands`,
	},
	Somalia: {
		id: `${scope}.Somalia`,
		defaultMessage: `Somalia`,
	},
	SouthAfrica: {
		id: `${scope}.SouthAfrica`,
		defaultMessage: `SouthAfrica`,
	},
	SouthGeorgiaAndSandwichIsl: {
		id: `${scope}.SouthGeorgiaAndSandwichIsl`,
		defaultMessage: `SouthGeorgiaAndSandwichIsl`,
	},
	Spain: {
		id: `${scope}.Spain`,
		defaultMessage: `Spain`,
	},
	SriLanka: {
		id: `${scope}.SriLanka`,
		defaultMessage: `SriLanka`,
	},
	Sudan: {
		id: `${scope}.Sudan`,
		defaultMessage: `Sudan`,
	},
	Suriname: {
		id: `${scope}.Suriname`,
		defaultMessage: `Suriname`,
	},
	SvalbardAndJanMayen: {
		id: `${scope}.SvalbardAndJanMayen`,
		defaultMessage: `SvalbardAndJanMayen`,
	},
	Swaziland: {
		id: `${scope}.Swaziland`,
		defaultMessage: `Swaziland`,
	},
	Sweden: {
		id: `${scope}.Sweden`,
		defaultMessage: `Sweden`,
	},
	Switzerland: {
		id: `${scope}.Switzerland`,
		defaultMessage: `Switzerland`,
	},
	SyrianArabRepublic: {
		id: `${scope}.SyrianArabRepublic`,
		defaultMessage: `SyrianArabRepublic`,
	},
	Taiwan: {
		id: `${scope}.Taiwan`,
		defaultMessage: `Taiwan`,
	},
	Tajikistan: {
		id: `${scope}.Tajikistan`,
		defaultMessage: `Tajikistan`,
	},
	Tanzania: {
		id: `${scope}.Tanzania`,
		defaultMessage: `Tanzania`,
	},
	Thailand: {
		id: `${scope}.Thailand`,
		defaultMessage: `Thailand`,
	},
	TimorLeste: {
		id: `${scope}.TimorLeste`,
		defaultMessage: `TimorLeste`,
	},
	Togo: {
		id: `${scope}.Togo`,
		defaultMessage: `Togo`,
	},
	Tokelau: {
		id: `${scope}.Tokelau`,
		defaultMessage: `Tokelau`,
	},
	Tonga: {
		id: `${scope}.Tonga`,
		defaultMessage: `Tonga`,
	},
	TrinidadAndTobago: {
		id: `${scope}.TrinidadAndTobago`,
		defaultMessage: `TrinidadAndTobago`,
	},
	Tunisia: {
		id: `${scope}.Tunisia`,
		defaultMessage: `Tunisia`,
	},
	Turkey: {
		id: `${scope}.Turkey`,
		defaultMessage: `Turkey`,
	},
	Turkmenistan: {
		id: `${scope}.Turkmenistan`,
		defaultMessage: `Turkmenistan`,
	},
	TurksAndCaicosIslands: {
		id: `${scope}.TurksAndCaicosIslands`,
		defaultMessage: `TurksAndCaicosIslands`,
	},
	Tuvalu: {
		id: `${scope}.Tuvalu`,
		defaultMessage: `Tuvalu`,
	},
	Uganda: {
		id: `${scope}.Uganda`,
		defaultMessage: `Uganda`,
	},
	Ukraine: {
		id: `${scope}.Ukraine`,
		defaultMessage: `Ukraine`,
	},
	UnitedArabEmirates: {
		id: `${scope}.UnitedArabEmirates`,
		defaultMessage: `UnitedArabEmirates`,
	},
	UnitedKingdom: {
		id: `${scope}.UnitedKingdom`,
		defaultMessage: `UnitedKingdom`,
	},
	UnitedStates: {
		id: `${scope}.UnitedStates`,
		defaultMessage: `UnitedStates`,
	},
	UnitedStatesOutlyingIslands: {
		id: `${scope}.UnitedStatesOutlyingIslands`,
		defaultMessage: `UnitedStatesOutlyingIslands`,
	},
	Uruguay: {
		id: `${scope}.Uruguay`,
		defaultMessage: `Uruguay`,
	},
	Uzbekistan: {
		id: `${scope}.Uzbekistan`,
		defaultMessage: `Uzbekistan`,
	},
	Vanuatu: {
		id: `${scope}.Vanuatu`,
		defaultMessage: `Vanuatu`,
	},
	Venezuela: {
		id: `${scope}.Venezuela`,
		defaultMessage: `Venezuela`,
	},
	VietNam: {
		id: `${scope}.VietNam`,
		defaultMessage: `VietNam`,
	},
	VirginIslandsBritish: {
		id: `${scope}.VirginIslandsBritish`,
		defaultMessage: `VirginIslandsBritish`,
	},
	VirginIslandsUS: {
		id: `${scope}.VirginIslandsUS`,
		defaultMessage: `VirginIslandsUS`,
	},
	WallisAndFutuna: {
		id: `${scope}.WallisAndFutuna`,
		defaultMessage: `WallisAndFutuna`,
	},
	WesternSahara: {
		id: `${scope}.WesternSahara`,
		defaultMessage: `WesternSahara`,
	},
	Yemen: {
		id: `${scope}.Yemen`,
		defaultMessage: `Yemen`,
	},
	Zambia: {
		id: `${scope}.Zambia`,
		defaultMessage: `Zambia`,
	},
	Zimbabw: {
		id: `${scope}.Zimbabw`,
		defaultMessage: `Zimbabw`,
	},
	selectCountry: {
		id: `${scope}.selectCountry`,
		defaultMessage: '-Select Country-',
	},
});
