import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';

import {
	EInvoice,
	EItemSource,
	MCustomer,
	millisecondsToDays,
	MInvoice,
	MItem,
	MPaymentMethod,
	priceFormatter,
	roundToCents,
} from 'mango-utils-client';
import DatePicker from '../../../components/DatePicker';
import { MundoButton, MundoCheckBox } from '../../../components/elements';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import MundoText from '../../../components/elements/MundoText';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import EditItem from '../../../components/Item/Edit';
import { useArray } from '../../../utilities/hooks/array';
import { timeoutForDebounce } from '../../../utilities/hooks/debounce';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { useHistory } from '../../../utilities/routing';
import CustomerCard from '../../Customer/card';
import messages from './messages';
import {
	CREDIT_OUT,
	CREDIT_PDFGEN,
	GET_CUSTOMER,
	GET_CUSTOMERS,
} from './queries';
/**
 * invoice module.
 * gets previouse state (module.plugin/done)
 * works with items
 * checks for packages based on booked and/or price levels.
 */
export const Credit: FC = () => {
	const history = useHistory();
	const styles = useStyle();
	// displayedItems
	const [items, dispatchItemAction] = useArray<MItem>();
	const [customer, onChangeCustomer] = useState<MCustomer>();
	// sum
	const [netSum, onChangeNetSum] = useState(0);
	const [taxSum, onChangeTaxSum] = useState(0);
	// discount
	// issue & due date states
	const [issueDate, onIssueDateChange] = React.useState(Date.now());
	const [dueDate, onDueDateChange] = React.useState(Date.now());
	const [tax, onChangeTax] = useState<boolean>(true);
	/**
	 * customer load query
	 */
	const [loadCustomer] = useLazyQuery(GET_CUSTOMER, {
		onCompleted: (data) => {
			if (data && data.customer) {
				onChangeCustomer(data.customer);
			}
		},
	});
	/**
	 * update customer
	 * @param c next customer _id
	 */
	const updateCustomer = useCallback(
		(c?: { _id: string }) => {
			if (c) {
				loadCustomer({
					variables: { id: c._id },
				});
			} else {
				onChangeCustomer(c);
			}
		},
		[loadCustomer],
	);

	const checkCustomer = () => {
		if (!customer) {
			return messages.customerError;
		}
		return;
	};
	/**
	 * effect to handle sum changes
	 */
	useEffect(() => {
		if (items.length) {
			let ts = 0;
			let ns = 0;

			items
				.filter((item: MItem) => item.checked)
				.forEach((item) => {
					const priceWithFee = Math.round(item.price) / 1000;
					const priceWIthDiscount =
						priceWithFee * (1 - item.discount / 10000);
					const endprice = roundToCents(
						priceWIthDiscount * item.amount,
					);

					ns += endprice * 1000;
					// * corona tax
					let taxVal =
						(item.tax === 19 ? 16 : item.tax === 7 ? 5 : item.tax) /
						100;
					if (taxVal === 0) {
						taxVal = 0.16;
					}
					ts += endprice * 1000 * taxVal;
				});
			onChangeNetSum(ns);
			onChangeTaxSum(tax ? ts : 0);
		}
	}, [items, tax]);
	// save invoice
	const [outQuery] = useMutation(CREDIT_OUT);
	const [pdfMutation] = useMutation(CREDIT_PDFGEN);
	const save = async () => {
		if (!customer) {
			return;
		}
		await timeoutForDebounce();
		const invoice = new MInvoice({
			orderId: '',
			paymentMethod: new MPaymentMethod(),
			issueDate,
			dueDate,
			positions: items
				.filter((item) => item.checked)
				.map(
					(item) =>
						new MItem({
							...item,
							price: Math.round(item.price),
							discount: Math.round(item.discount),
						}),
				),
			customer,
			netSum: Math.round(netSum),
			taxSum: Math.round(taxSum),
			grossSum: Math.round(netSum + taxSum),
			billingAddress: customer.billingAddress,
			type: EInvoice.CREDIT,
		});
		outQuery({ variables: { invoice } })
			.then((result) => {
				const id = result.data?.saveInvoice._id;
				if (id) {
					pdfMutation({ variables: { invoiceId: id } })
						.then(() => {
							history.push(
								`pdf/credit/${result.data?.saveInvoice._id}`,
							);
						})
						.catch((e) => console.error(e));
				}
			})
			.catch((e) => console.error(e));
	};
	/**
	 * render
	 */
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/module/invoice?id=rechnungserstellung',
							)
						}
					/>
				</View>
			</View>
			<ScrollView
				contentContainerStyle={[
					styles.horizontalScrollViewContainerNoPadding,
					styles.elevated,
				]}
			>
				<View style={[styles.spacedContainer, styles.elevated]}>
					{/* PAYMENT METHODS */}
					<View
						style={[
							styles.formContainer,
							styles.bottomMargin20,
							styles.elevated,
						]}
					>
						{/* INVOICE STATE */}
						<View style={[styles.leftMargin20, styles.elevated]}>
							<View style={styles.elevated}>
								<MundoObjectTypeahead
									cyId={'equipmentManager.customer.input'}
									value={customer}
									placeholder={messages.customerName}
									onChange={updateCustomer}
									QUERY={GET_CUSTOMERS}
									customValidity={checkCustomer}
								/>
							</View>
							{customer && (
								<View
									style={styles.wideGroupLeftAlignedNoPadding}
								>
									<CustomerCard customer={customer} />
								</View>
							)}
						</View>
						<View style={[styles.leftMargin20]}>
							<MundoText message={messages.customerAllegiance} />
							{customer && (
								<MundoText
									message={
										customer.billingAddress
											? customer.billingAddress.country
											: customer.address.country
									}
									styles={[styles.bottomMargin10]}
								/>
							)}
							<MundoText
								message={messages.issueDate}
								styles={[
									styles.topMargin10,
									styles.bottomMargin10,
								]}
							/>
							<View>
								<DatePicker
									style={styles.formUnitStandardWidth}
									value={new Date(issueDate)
										.toISOString()
										.substr(0, 10)}
									onChange={(date) => {
										onIssueDateChange(date);
									}}
								/>
							</View>
							<MundoText
								message={messages.dueDate}
								styles={[
									styles.topMargin10,
									styles.bottomMargin10,
								]}
							/>
							<DatePicker
								value={new Date(dueDate)
									.toISOString()
									.substr(0, 10)}
								onChange={(date) => {
									onDueDateChange(date);
								}}
							/>
							<MundoText
								message={messages.paymentTime}
								styles={[styles.topMargin10]}
							/>
							<MundoText
								message={
									issueDate >= dueDate
										? messages.now
										: undefined
								}
							>
								{issueDate < dueDate &&
									millisecondsToDays(dueDate - issueDate)}
							</MundoText>
							<MundoText
								message={messages.netSum}
								styles={[styles.topMargin10]}
							/>
							<MundoText>{`${priceFormatter(
								netSum / 1000,
							)} €`}</MundoText>
							<View style={styles.horizontalLayout}>
								<View>
									<MundoText
										message={messages.taxSum}
										styles={[styles.topMargin10]}
									/>
									<MundoText>
										{`${priceFormatter(taxSum / 1000)} €`}{' '}
									</MundoText>
								</View>
								<View
									style={[
										styles.leftMargin20,
										styles.topMargin10,
									]}
								>
									<MundoCheckBox
										checked={tax}
										onCheckedChanged={onChangeTax}
									/>
								</View>
							</View>
							<MundoText
								message={messages.grossSum}
								styles={[styles.topMargin10]}
							/>
							<MundoText>{`${priceFormatter(
								(taxSum + netSum) / 1000,
							)} €`}</MundoText>
						</View>
					</View>
					{/* ITEMS */}
					{items.map((item: MItem, index: number) => (
						<View
							style={{ zIndex: 10 + items.length - index }}
							key={index}
						>
							<EditItem
								value={item}
								onChange={(next) =>
									dispatchItemAction({
										type: ETypes.EDIT,
										item: new MItem(next),
										location: index,
									})
								}
							>
								<View style={styles.wideGroupTableColumn8}>
									<Text>
										{' '}
										={' '}
										{priceFormatter(
											((item.amount * item.price) /
												1000) *
												(1 -
													(item.discount || 0) /
														10000),
										)}{' '}
										€
									</Text>
								</View>
							</EditItem>
						</View>
					))}
					<MundoButton
						dataSet={{ cy: 'invoice.addItem.button' }}
						icon={'plus'}
						onPress={() => {
							dispatchItemAction({
								type: ETypes.ADD,
								item: new MItem({
									source: EItemSource.CUSTOM,
									tax: 16, // 19 corona,
								}),
							});
						}}
					/>
				</View>
			</ScrollView>
			{/* FINISHBUTTONS */}
			<FinishButtonGroup
				cyId={'invoice'}
				saveFunction={save}
				cancelFunction={history.goBack}
			/>
		</View>
	);
};
