import React, { FC, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MBundledArticle } from 'mango-utils-client';
import BundledArticle from '../../../components/BundledArticle';
import { MundoButton } from '../../../components/elements';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { ESortMode } from '../../../utilities/enums/ESortMode';
import { useStyle } from '../../../utilities/hooks/styles';
import { getTranslation } from '../../../utilities/translations';
import { PackageEditContext } from '../Edit';
import BundledArticleListHeader from './BundledArticleListHeader';

const BundledArticleList: FC = () => {
	const styles = useStyle();
	const { update, pack } = useContext(PackageEditContext);
	const { language } = useContext(LanguageContext);

	const [sortMode, onChangeSortMode] = useState(ESortMode.NOSORT);

	const deleteButtonHandler = (index: number) => {
		pack.includedArticles.splice(index, 1);
		update(pack);
	};

	const onChangeSort = (column: string, mode: ESortMode) => {
		if (column === 'article') {
			switch (mode) {
				case ESortMode.ASC:
					pack.includedArticles.sort(
						(a: MBundledArticle, b: MBundledArticle) => {
							const first = getTranslation(
								a.articles[0].title,
								language,
							).toLocaleLowerCase();
							const second = getTranslation(
								b.articles[0].title,
								language,
							).toLocaleLowerCase();
							if (first && second) {
								if (first < second) {
									return -1;
								}
								if (first > second) {
									return 1;
								}
							}
							return 0;
						},
					);
					onChangeSortMode(ESortMode.ASC);
					return update(pack);
				case ESortMode.DESC:
					pack.includedArticles.sort(
						(a: MBundledArticle, b: MBundledArticle) => {
							const first = getTranslation(
								a.articles[0].title,
								language,
							).toLocaleLowerCase();
							const second = getTranslation(
								b.articles[0].title,
								language,
							).toLocaleLowerCase();
							if (first && second) {
								if (first > second) {
									return -1;
								}
								if (first < second) {
									return 1;
								}
							}
							return 0;
						},
					);
					onChangeSortMode(ESortMode.DESC);
					return update(pack);
				case ESortMode.NOSORT:
					onChangeSortMode(ESortMode.NOSORT);
					return update(pack);
				default:
			}
		}
	};

	return (
		<ScrollView horizontal contentContainerStyle={{ width: '100%' }}>
			<View>
				<BundledArticleListHeader
					onChangeSort={onChangeSort}
					currentSort={sortMode}
				/>
				{pack.includedArticles.map((bundle, index) => (
					<View
						key={`BundledList${bundle.articles}${index}`}
						style={styles.tableRow}
					>
						<BundledArticle
							bundle={bundle}
							onChange={(bundel) => {
								pack.includedArticles.splice(index, 1, bundel);
								update(pack);
							}}
						/>
						<View style={styles.wideGroupTableColumn10NoGrow}>
							<MundoButton
								dataSet={{ cy: 'article.remove.button' }}
								onPress={() => deleteButtonHandler(index)}
								icon={'minus'}
							/>
						</View>
					</View>
				))}
			</View>
		</ScrollView>
	);
};

export default BundledArticleList;
