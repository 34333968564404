import gql from 'graphql-tag';

export const GET_GROUPS = gql`
	query getGroups {
		groups {
			title
			_id
			id
			inheritance
		}
	}
`;

export const GET_CUSTOMERS = gql`
	query($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;
