import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.driverFilterSection';

export default defineMessages({
	driver: {
		id: `${scope}.driver`,
		defaultMessage: 'driver',
	},
	global: {
		id: `${scope}.global`,
		defaultMessage: 'Total Profit',
	},
	filterGlobal: {
		id: `${scope}.filterGlobal`,
		defaultMessage: 'Filter Total Profit by Drivers',
	},
	useAllDrivers: {
		id: `${scope}.useAllDrivers`,
		defaultMessage: 'use all',
	},
});
