import gql from 'graphql-tag';

export const GET_ORDERS_BY_LANES = gql`
	query getOrdersByLanes($laneIds: [Float!]!) {
		getOrdersByLanes(laneIds: $laneIds) {
			laneId
			orders {
				id
				_id
				currentState
				subState
				customer {
					title
				}
			}
		}
	}
`;

export const GET_LANES = gql`
	query getTRecordSettings($pipelineId: String!) {
		tRecordSettings(pipelineId: $pipelineId) {
			lanes {
				_id
				id
				tags
				classifications
				trailerType
			}
			dashboardFields
		}
	}
`;

export const ASSIGN_TO_LANE = gql`
	mutation assignOrderToLane(
		$orderId: String!
		$laneId: Float!
		$pipelineId: String!
	) {
		assignOrderToLane(
			orderId: $orderId
			laneId: $laneId
			pipelineId: $pipelineId
		)
	}
`;
