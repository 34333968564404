import gql from 'graphql-tag';

export const GET_USER = gql`
	query getUser($id: String!) {
		user(id: $id) {
			_id
			id
			title
			classification
			mail
			telephone
			tags
			groups {
				_id
				id
				title
			}
			customer {
				_id
			}
			signature
		}
	}
`;

export const GET_GROUPS = gql`
	query getGroups {
		groups {
			title
			id
			_id
		}
	}
`;

export const SAVE_USER = gql`
	mutation saveUser($user: UserInput!) {
		updateUser(user: $user) {
			id
		}
	}
`;

export const DELETE_USER = gql`
	mutation deleteUser($id: String!) {
		deleteUser(id: $id) {
			id
		}
	}
`;

export const GET_CUSTOMER = gql`
	query getCustomer($id: String!) {
		customer(id: $id) {
			_id
			id
			title
		}
	}
`;

export const GET_CUSTOMERS = gql`
	query getCompanies($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;
