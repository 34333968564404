import { defineMessages } from 'react-intl';

export const scope = 'mango.orderOverview';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'order',
	},
	save: {
		id: `${scope}.save`,
		defaultMessage: 'save',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit',
	},
	cancel: {
		id: `${scope}.cancel`,
		defaultMessage: 'cancel',
	},
	resetToHere: {
		id: `${scope}.resetToHere`,
		defaultMessage: 'reset to this state',
	},
	resetDescr: {
		id: `${scope}.resetDescr`,
		defaultMessage:
			'if you do this all further order data will be lost permanently',
	},
	cancellate: {
		id: `${scope}.cancellate`,
		defaultMessage: 'cancel order',
	},
	cancellateDescr: {
		id: `${scope}.cancellateDescr`,
		defaultMessage:
			'if you do this the order will become invisible for most views',
	},
	exposeToTerminal: {
		id: `${scope}.exposeToTerminal`,
		defaultMessage: 'expose order to terminal',
	},
	exposeToTerminalQuestion: {
		id: `${scope}.exposeToTerminalQuestion`,
		defaultMessage: 'expose to terminal?',
	},
	exposeToTerminalInformation: {
		id: `${scope}.exposeToTerminalInformation`,
		defaultMessage: 'the order will be visible to all terminal users',
	},
});
