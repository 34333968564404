import React, { FC } from 'react';
import { ScrollView, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { MundoButton, MundoText } from '..';
import { useStyle } from '../../../utilities/hooks/styles';
import LoadingIndicator from '../../LoadingIndicator';
import MundoTableHeader from '../MundoTableHeader';
import { MundoTableRow } from './components/MundoTableRow';
import { makeColumnStyle } from './functions/makeColumnStyle';
import messages from './messages';
import { IMundoTableProps } from './props';

export const MundoTable: FC<IMundoTableProps> = ({
	entrys,
	onChangeSort,
	sortMode,
	orderBy,
	rows,
	loadMore,
	noLoadMore,
	loading,
	loadMoreActive,
	filter,
	customHeaders,
}) => {
	const styles = useStyle();
	const isTablet = useMediaQuery({ maxDeviceWidth: 1024 });
	const getColumnWidth = (numChars: number) => numChars * 10;

	const headerStyle = [
		styles.wideGroupTableColumnHeader,
		{
			paddingTop: 3,
			paddingRight: 10,
			flexGrow: 1,
		},
	];
	let baseStyle = styles.horizontalScrollViewContainer;
	if (rows.length === 0) {
		baseStyle = styles.horizontalScrollViewContainerFlexed;
	}
	return (
		<View style={styles.fullSize}>
			{filter}
			<ScrollView horizontal contentContainerStyle={baseStyle}>
				<View
					style={[
						styles.wideGroupTable,
						styles.wideGroupTableColumnHeader,
					]}
				>
					{entrys.map((entry) => (
						<View
							key={entry.identifier}
							style={
								isTablet
									? [
											makeColumnStyle(entry.size || 10),
											styles.wideGroupTableColumnHeader,
									  ]
									: [
											headerStyle,
											{
												width: getColumnWidth(
													entry.size || 10,
												),
												flexGrow: 0,
											},
									  ]
							}
						>
							{entry.header && (
								<MundoTableHeader
									message={entry.header}
									onChangeSort={onChangeSort}
									identifier={entry.identifier}
									activeSorted={orderBy}
									sortType={sortMode}
								/>
							)}
						</View>
					))}
					{customHeaders}
				</View>
				{rows.length ? (
					rows.map((row) => (
						<MundoTableRow
							key={row.key}
							entrys={entrys}
							row={row}
						/>
					))
				) : (
					<MundoText message={messages.noData} />
				)}
				{loading && <LoadingIndicator />}
				{!loading && loadMoreActive && loadMore && !noLoadMore && (
					<MundoButton
						dataSet={{ cy: 'loadMore' }}
						title={messages.loadMore}
						onPress={loadMore}
						styles={[
							styles.formButtonStandardWidth,
							styles.topMargin10,
							{ alignSelf: 'center' },
						]}
					/>
				)}
			</ScrollView>
		</View>
	);
};
