import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useStyle, useTheme } from '../../../../utilities/hooks/styles';
import { ConditionalOnPressWrap } from '../functions/conditionalOnPressWrap';
import { makeColumnStyle } from '../functions/makeColumnStyle';
import { IMundoTableRowProps } from '../props';

export const MundoTableRow: FC<IMundoTableRowProps> = ({ row, entrys }) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const isTablet = useMediaQuery({ maxDeviceWidth: 1024 });
	const getColumnWidth = (numChars: number) => numChars * 10;
	const [expand, onChangeExpand] = useState<boolean>(false);

	const cellStyle = [
		styles.generalCellStyle,
		styles.extendedRowHeight,
		{
			paddingLeft: 10,
			paddingRight: 10,
			flexGrow: 1,
		},
	];

	return (
		<View
			style={
				expand && {
					borderBottomWidth: 2,
					borderBottomColor: theme.accentMainColor,
					borderTopWidth: 2,
					borderTopColor: theme.accentMainColor,
				}
			}
		>
			<ConditionalOnPressWrap
				onPress={
					row.onPress || row.subRows
						? () => {
								if (row.onPress) {
									row.onPress();
								} else {
									onChangeExpand(!expand);
								}
						  }
						: undefined
				}
				dataSet={row.dataSet}
			>
				<View style={styles.wideGroupTable}>
					{entrys.map((entry) => {
						const v = row.values.find(
							(value) => value.identifier === entry.identifier,
						);
						return (
							<View
								key={entry.identifier}
								style={
									isTablet
										? [
												makeColumnStyle(
													entry.size || 10,
												),
												styles.extendedRowHeight,
										  ]
										: [
												cellStyle,
												{
													width: getColumnWidth(
														entry.size || 10,
													),
													flexGrow: 0,
												},
										  ]
								}
							>
								{v?.content}
							</View>
						);
					})}
					{row.customFields}
				</View>
			</ConditionalOnPressWrap>
			{expand &&
				row.subRows &&
				row.subRows.map((sr) => (
					<MundoTableRow key={sr.key} entrys={entrys} row={sr} />
				))}
		</View>
	);
};
