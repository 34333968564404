import React, { FC, useContext, useEffect, useState } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';

import { getTranslation, priceFormatter } from 'mango-utils-client';
import icons from '../../../assets';
import MundoTable from '../../../components/elements/MundoTable';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { useArticles } from '../../../utilities/hooks/article';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import ArticleFilter from '../Filter';
import ArticleListHeader from './components/ArticleListHeader';
import messages from './messages';

const ArticleList: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { language } = useContext(LanguageContext);

	const { orderBy, mode, changeSort } = useSort();

	const {
		articles,
		loading,
		loadMore /*, onChangeFilter*/,
		loadMoreActive,
		onChangeFilter,
	} = useArticles({ skip: false, disabled: false, orderBy });

	const [selectedType, onChangeSelectedType] = useState('article');
	const [filterArticle, onChangeFilterArticle] = useState();

	const createArticleButtonHandler = () => {
		history.push(`/edit/${selectedType}/new`);
	};

	useEffect(() => {
		onChangeFilter(filterArticle);
	}, [onChangeFilter, filterArticle]);

	return (
		<View style={styles.containerFullResolution}>
			<ArticleListHeader
				onChange={onChangeSelectedType}
				onPress={createArticleButtonHandler}
			/>
			<ScrollView>
				<View style={[styles.spacedContainer]}>
					<MundoTable
						filter={
							<View style={styles.bottomMargin20}>
								<ArticleFilter
									onChangeFilterArticle={
										onChangeFilterArticle
									}
									filterArticle={filterArticle}
								/>
							</View>
						}
						entrys={[
							{ identifier: 'id', header: messages.id, size: 10 },
							{
								identifier: 'short',
								header: messages.short,
								size: 10,
							},
							{
								identifier: 'title',
								header: messages.title,
								size: 60,
							},
							{
								identifier: 'price',
								header: messages.price,
								size: 8,
							},
							{ identifier: 'arrow', size: 4 },
						]}
						rows={articles.map((article) => {
							return {
								key: article._id,
								onPress: () =>
									history.push(
										`/edit/${article.articleType}/${article._id}`,
									),
								values: [
									{
										identifier: 'id',
										content: <Text>{article.id}</Text>,
									},
									{
										identifier: 'short',
										content: <Text>{article.short}</Text>,
									},
									{
										identifier: 'title',
										content: (
											<Text>
												{getTranslation(
													article.title,
													language,
												)}
											</Text>
										),
									},
									{
										identifier: 'price',
										content: (
											<Text>
												{priceFormatter(
													article.price / 1000,
												)}
											</Text>
										),
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								dataSet: {
									cy: `Articles.${getTranslation(
										article.title,
										language,
									)}`,
								},
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default ArticleList;
