import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.DriverFilter';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID',
	},
	companyName: {
		id: `${scope}.companyName`,
		defaultMessage: 'company name',
	},
	firstName: {
		id: `${scope}.firstName`,
		defaultMessage: 'first name',
	},
	lastName: {
		id: `${scope}.lastName`,
		defaultMessage: 'last name',
	},
});
