import gql from 'graphql-tag';

export const NAV_EXPORT = gql`
	mutation navExport($from: Float, $to: Float) {
		navExport(from: $from, to: $to)
	}
`;

export const INVOICE = gql`
	query($id: String!) {
		invoiceByOrderID(id: $id) {
			tags
		}
	}
`;
