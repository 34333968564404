import React, { FC } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { useStyle } from '../../utilities/hooks/styles';

const LoadingIndicator: FC = () => {
	const styles = useStyle();
	return (
		<View
			{...{ dataSet: { cy: 'loadingIndicator' } }}
			style={styles.loading}
		>
			<ActivityIndicator size={'large'} />
		</View>
	);
};

export default LoadingIndicator;
