import { defineMessages } from 'react-intl';

export const scope = 'mango.components.vehicleCleaning.TableHeaderExtension';

export default defineMessages({
	driver: {
		id: `${scope}.driver`,
		defaultMessage: 'driver',
	},
	trailer: {
		id: `${scope}.trailer`,
		defaultMessage: 'trailer',
	},
	truck: {
		id: `${scope}.truck`,
		defaultMessage: 'truck',
	},
	malformed: {
		id: `${scope}.malformed`,
		defaultMessage: 'malformed key',
	},
});
