import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../components/elements/MundoInput';
import {
	getTranslation,
	updateTranslation,
} from '../../../../utilities/translations';
import messages from '../messages';
import { IArticleEditTranslateableFieldProps } from '../props';

export const DescriptionField: FC<IArticleEditTranslateableFieldProps> = ({
	value,
	update,
	lang,
}) => {
	let description = getTranslation(value.description, lang);
	if (!description) {
		description = '';
	}
	return (
		<View>
			<MundoInput
				dataSet={{ cy: 'article.description.input' }}
				label={messages.description}
				onChangeText={(text) =>
					update({
						description: updateTranslation(
							text,
							lang,
							value.description,
						),
					})
				}
				value={description}
			/>
		</View>
	);
};
