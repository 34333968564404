import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../utilities/constants';

const sidebarStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		logoImgDefault: {
			width: 40,
			height: 40,
		},
		logoImg: {
			width: 170,
			height: 40,
		},
		logoContainer: {
			margin: 10,
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
		},
		pipelineContainer: {
			marginTop: 5,
			marginBottom: 0,
			paddingBottom: 8,
		},
		container: {
			flex: 1,
			alignSelf: 'stretch',
			display: 'flex',
			flexDirection: 'row',
		},
		containerMobile: {
			flex: 1,
			alignSelf: 'stretch',
			display: 'flex',
			flexDirection: 'column',
		},
		navigationHeader: {
			display: 'flex',
			flexDirection: 'row',
			backgroundColor: theme.backgroundSecondaryColor,
		},
		sidebar: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			backgroundColor: theme.backgroundMainColor,
			zIndex: 9000,
			paddingBottom: 20,
			paddingTop: 10,
			paddingHorizontal: 10,
		},
		sidebarDetail: {
			minWidth: 250,
			paddingHorizontal: 20,
			paddingBottom: 20,
			alignItems: 'flex-start',
		},
		sidebarDetailTablet: {
			minWidth: 250,
			paddingHorizontal: 20,
			paddingBottom: 20,
			alignItems: 'flex-start',
			position: 'absolute',
			bottom: 0,
			top: 0,
		},
		sidebarMobile: {
			position: 'absolute',
			zIndex: 0,
		},
		sidebarFiller: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
		},
		statusbarIOS: {
			height: 30,
			backgroundColor: theme.accentMainColor,
		},
		navButton: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			backgroundColor: 'transparent',
		},
		exNavButton: {
			justifyContent: 'flex-start',
			backgroundColor: 'transparent',
		},
		contentPane: {
			flex: 1,
			backgroundColor: '#fafafa',
		},
		headerContainer: {
			flexDirection: 'row',
			width: '100%',
			paddingBottom: 30,
			justifyContent: 'space-between',
		},
		sidebarButton: {
			backgroundColor: 'transparent',
			borderWidth: 0,
			borderColor: theme.accentMainColor,
			height: 40,
			width: 40,
			marginTop: 10,
		},
		titleContainer: {
			padding: 10,
			borderStyle: 'solid',
			borderBottomWidth: StyleSheet.hairlineWidth,
			borderColor: theme.borderTertiaryColor,
		},
		closer: {
			width: '100%',
			height: '100%',
			position: 'absolute',
		},
		pipelineName: {
			marginTop: 10,
			borderColor: theme.borderSecondaryColor,
			borderWidth: 1,
		},
		pipelineNameBackground: {
			marginTop: 10,
			backgroundColor: theme.accentSecondaryColor,
			borderColor: theme.borderSecondaryColor,
			borderWidth: 1,
		},
	});

export default sidebarStyles;
