import React, { FC, useCallback, useEffect, useState } from 'react';
import { Picker, View } from 'react-native';

import { ECountries, MAddress, MCustomer } from 'mango-utils-client';
import { MundoButton } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import MundoText from '../../../components/elements/MundoText';
import useDebounce from '../../../utilities/hooks/debounce';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';
import { ICustomerFilterProps } from './props';

const CustomerFilter: FC<ICustomerFilterProps> = ({ onChange }) => {
	const styles = useStyle();

	const [expanded, onChangeExpaded] = React.useState(false);

	const [customer, onChangeCustomer] = useState<MCustomer>(new MCustomer());
	const [last, onChangeLast] = useState<MCustomer>();
	const update = (next: Partial<MCustomer>) => {
		if (next.address) {
			const address = { ...customer.address, ...next.address };
			return onChangeCustomer(new MCustomer({ ...customer, address }));
		}
		return onChangeCustomer(new MCustomer({ ...customer, ...next }));
	};

	const loadCB = useCallback((next) => onChange(next), [onChange]);

	const debouncedSearchTerm = useDebounce(customer);

	useEffect(() => {
		if (debouncedSearchTerm && debouncedSearchTerm !== last) {
			onChangeLast(debouncedSearchTerm);
			loadCB(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm, loadCB, last]);

	return (
		<View>
			<View style={[styles.filterGroup, styles.elevated, styles.wrap]}>
				<View style={styles.formUnitStandardWidth}>
					<MundoInput
						label={messages.id}
						onChangeText={(id) => update({ id: +id })}
						value={`${customer.id}`}
						length={5}
						type={`numeric`}
					/>
				</View>
				<View style={styles.formUnitStandardWidth}>
					<MundoInput
						dataSet={{ cy: 'company.filter.input' }}
						label={messages.companyName}
						onChangeText={(title) => update({ title })}
						value={customer.title}
					/>
				</View>
				<View style={styles.formUnitStandardWidth}>
					<MundoInput
						label={messages.externalId}
						onChangeText={(externalId) => update({ externalId })}
						value={customer.externalId}
					/>
				</View>
				<View style={{ marginTop: 24 }}>
					<MundoButton
						onPress={() => onChangeExpaded(!expanded)}
						title={expanded ? messages.less : messages.more}
					/>
				</View>
			</View>
			{expanded && (
				<View style={[styles.filterGroup, styles.wrap]}>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.firstName}
							onChangeText={(firstName) => update({ firstName })}
							value={customer.firstName}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.lastName}
							onChangeText={(lastName) => update({ lastName })}
							value={customer.lastName}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.phoneNumber}
							onChangeText={(phone) => update({ phone })}
							value={customer.phone}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.email}
							onChangeText={(mail) => update({ mail })}
							value={customer.mail}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.street}
							onChangeText={(street) =>
								update({ address: { street } as MAddress })
							}
							value={customer.address.street}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.zipCode}
							onChangeText={(zipCode) =>
								update({ address: { zipCode } as MAddress })
							}
							value={customer.address.zipCode}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.city}
							onChangeText={(city) =>
								update({ address: { city } as MAddress })
							}
							value={customer.address.city}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoText
							message={messages.country}
							styles={styles.pickerLabelSpacing}
						/>
						<Picker
							style={styles.picker}
							onValueChange={(country) =>
								update({ address: { country } as MAddress })
							}
						>
							<Picker.Item
								label={'-Select Country-'}
								value={''}
							/>
							{Object.keys(ECountries).map((c) => (
								<Picker.Item
									key={`countryPickerItem${c}`}
									label={c}
									value={c}
								/>
							))}
						</Picker>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoInput
							label={messages.ustIdNumber}
							onChangeText={(ustID) => update({ ustID })}
							value={customer.ustID}
						/>
					</View>
					<View style={styles.formUnitStandardWidth}>
						<MundoText
							message={messages.region}
							styles={styles.pickerLabelSpacing}
						/>
						<Picker style={styles.picker}>
							<Picker.Item label={'-Select Region-'} value={''} />
							{['D', 'EU', 'G'].map((c) => (
								<Picker.Item
									key={`regionPickerItem${c}`}
									label={c}
									value={c}
								/>
							))}
						</Picker>
					</View>
				</View>
			)}
		</View>
	);
};

export default CustomerFilter;
