import { gql } from '@apollo/client';

export const PDF_SENSE = gql`
	query pdfs($id: String!) {
		pdfs(orderId: $id)
	}
`;

export const PDF_SEND = gql`
	mutation sendPDF(
		$id: String!
		$pdfs: [String!]!
		$email: String!
		$subject: String!
		$text: String!
	) {
		sendpdfs(
			orderId: $id
			pdfs: $pdfs
			email: $email
			subject: $subject
			body: $text
		)
	}
`;

export const GET_ORDER = gql`
	query getOrder($id: String!) {
		order(id: $id) {
			id
			customer {
				id
				title
				address {
					street
					city
					country
					zipCode
					tags
				}
				mail
				taxNr
				ustID
				allegiance
				disabled
			}
		}
	}
`;
