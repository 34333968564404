import React, { FC, ReactNode } from 'react';
import { View } from 'react-native';

import { ETOrderListSettings, MOrder } from 'mango-utils-client';
import { MundoText } from '../../../../components/elements';
import messages from './messages';

interface IProps {
	order: MOrder;
	column: string;
}

export const TableContentExtension: FC<IProps> = ({ order, column }) => {
	switch (column) {
		case ETOrderListSettings.DRIVER:
			return (
				<MundoText>
					{
						order.tags
							.find((tag) => tag.split('::')[0] === 'driver')
							?.split('::')[1]
					}
				</MundoText>
			);
		case ETOrderListSettings.TRAILER:
			return (
				<MundoText>
					{
						order.tags
							.find((tag) => tag.split('::')[0] === 'trailer')
							?.split('::')[1]
					}
				</MundoText>
			);
		case ETOrderListSettings.TRUCK:
			return (
				<MundoText>
					{
						order.tags
							.find((tag) => tag.split('::')[0] === 'truck')
							?.split('::')[1]
					}
				</MundoText>
			);
		default:
			return <MundoText message={messages.malformed}>{column}</MundoText>;
	}
};

export function pickTableContentExtension(
	order: MOrder,
	field: string,
): { identifier: string; content: ReactNode } {
	switch (field) {
		case ETOrderListSettings.DRIVER:
			return {
				identifier: 'driver',
				content: <TableContentExtension order={order} column={field} />,
			};
		case ETOrderListSettings.TRAILER:
			return {
				identifier: 'trailer',
				content: <TableContentExtension order={order} column={field} />,
			};
		case ETOrderListSettings.TRUCK:
			return {
				identifier: 'truck',
				content: <TableContentExtension order={order} column={field} />,
			};
		default:
			return { identifier: 'malformed', content: <View /> };
	}
}
