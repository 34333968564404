import gql from 'graphql-tag';

export const GET_GROUPINVOICES = gql`
	query getGroupInvoices(
		$groupInvoice: GroupInvoiceInput!
		$start: Int!
		$count: Int!
		$from: Float!
		$to: Float!
		$orderBy: String
	) {
		groupInvoices(
			groupInvoice: $groupInvoice
			start: $start
			count: $count
			from: $from
			to: $to
			orderBy: $orderBy
		) {
			id
			_id
			invoices {
				_id
			}
			startDate
			endDate
			dueDate
			grossSum
			taxSum
			netSum
			discountSum
			paymentMethod {
				title
			}
			customer {
				title
			}
			billingAddress {
				street
				city
				country
				zipCode
			}
		}
	}
`;
