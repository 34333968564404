import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import { MTTruck } from 'mango-utils-client';
import { MundoText } from '../../../../../components/elements';
import MundoInput from '../../../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../../../components/elements/MundoObjectTypeahead';
import useDebounce from '../../../../../utilities/hooks/debounce';
import { useStyle } from '../../../../../utilities/hooks/styles';
import messages from './messages';
import { ITruckFilterProps } from './props';
import { GET_COMPANIES } from './queries';

const TruckFilter: FC<ITruckFilterProps> = ({ onChange }) => {
	const styles = useStyle();

	const [company, onChangeCompany] = useState({ _id: '', title: '' });

	const [truck, onChangeTruck] = useState<MTTruck>(new MTTruck());
	const [last, onChangeLast] = useState<MTTruck>();

	const update = (next: Partial<MTTruck>) => {
		return onChangeTruck(new MTTruck({ ...truck, ...next }));
	};

	const loadCB = useCallback((next) => onChange(next), [onChange]);

	const debouncedSearchTerm = useDebounce(truck);

	useEffect(() => {
		if (debouncedSearchTerm && debouncedSearchTerm !== last) {
			onChangeLast(debouncedSearchTerm);
			loadCB(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm, loadCB, last]);

	return (
		<View style={[styles.filterGroup]}>
			<View style={[styles.formUnitStandardWidth, styles.elevated]}>
				<MundoText
					message={messages.companyName}
					styles={styles.pickerLabelSpacing}
				/>
				<MundoObjectTypeahead
					value={company}
					placeholder={messages.companyName}
					onChange={(selectedCompany) => {
						if (selectedCompany) {
							update({ companyID: selectedCompany._id });
							onChangeCompany({
								...selectedCompany,
								title: selectedCompany.title as string,
							});
						} else if (truck.companyID) {
							update({ companyID: '' });
							onChangeCompany({ _id: '', title: '' });
						}
					}}
					QUERY={GET_COMPANIES}
				/>
			</View>
			<View style={styles.formUnitStandardWidth}>
				<MundoInput
					label={messages.id}
					onChangeText={(id) => update({ id: +id })}
					value={`${truck.id}`}
					length={5}
					type={`numeric`}
				/>
			</View>
			<View style={styles.formUnitStandardWidth}>
				<MundoInput
					dataSet={{ cy: 'truck.filter.license.input' }}
					label={messages.licensePlate}
					onChangeText={(title) => update({ title })}
					value={truck.title}
				/>
			</View>
		</View>
	);
};

export default TruckFilter;
