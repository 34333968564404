import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MundoText } from '../../components/elements';
import ArticleEdit from '../../container/Article/Edit';
import CustomerEdit from '../Customer/Edit';
import GroupEdit from '../Group/Edit';
import InvoiceSettings from '../Invoice/Settings';
import OrderListSettings from '../Order/List/Settings';
import PackageEdit from '../Package/Edit';
import PaymentMethodEdit from '../PaymentMethod/Edit';
import ProfileEdit from '../ProfileEdit';
import SignSettings from '../Sign/Settings';
import UserEdit from '../User/Edit';
import messages from './messages';

// plugin imports
import { getContainer, getExtension } from '../../Plugins';

// TODO: substitute these imports with getImports
import {
	tExtractor,
	tQueries,
} from '../../Plugins/VehicleCleaning/extensions/Article/queries';
import { ETOrderListSettings } from '../../Plugins/VehicleCleaning/extensions/enums';
import additionalSelectTitle from '../../Plugins/VehicleCleaning/extensions/TableHeader/functions/selectTitle';
import MailEdit from '../Mail/Edit';
import RecordSettings from '../Record/Settings';
import ReceiptSettings from '../Receipt/Settings';

const ParentEdit: FC = () => {
	const { title, id } = useParams<{ title: string; id: string }>();

	let titleWithCapitalizedPlugin = title || '';
	if (titleWithCapitalizedPlugin.split('.').length) {
		titleWithCapitalizedPlugin = titleWithCapitalizedPlugin
			.split('.')
			.map((identifier, index) =>
				index
					? identifier.charAt(0).toUpperCase() + identifier.slice(1)
					: identifier,
			)
			.join('.');
	}
	const upperCaseAll = (input: string) => {
		return input
			.split('.')
			.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
			.join('.');
	};

	const selectedEdit = useMemo(() => {
		switch (titleWithCapitalizedPlugin) {
			case 'profile':
				return <ProfileEdit />;
			case 'user':
				return <UserEdit />;
			case 'group':
				return <GroupEdit />;
			case 'article':
				return <ArticleEdit />;
			case 'mail':
				return <MailEdit />;
			case 'package':
				return <PackageEdit />;
			case 'paymentMethod':
				return <PaymentMethodEdit />;
			case 'invoice':
				return <InvoiceSettings />;
			case 'sign':
				return <SignSettings />;
			case 'customer':
				return <CustomerEdit />;
			case 'record':
				return <RecordSettings />;
			case 'receipt':
				return <ReceiptSettings />;
			case 'article.VehicleCleaning':
				const ArticleExtension = getExtension('Article');
				return ArticleExtension ? (
					<ArticleEdit
						id={id}
						query={tQueries}
						extractor={tExtractor}
					>
						<ArticleExtension />
					</ArticleEdit>
				) : (
					<MundoText message={messages.error} />
				);
			case 'orders':
				// TODO need to be changed, if lazy imports are implemented here
				return (
					<OrderListSettings
						additionalTableConfig={Object.keys(ETOrderListSettings)}
						additionalSelectTitle={additionalSelectTitle}
					/>
				);
			default:
				const split = upperCaseAll(titleWithCapitalizedPlugin).split(
					'.',
				);
				const EditView = getContainer(`${split[0]}/Edit`);
				return <EditView />;
		}
	}, [titleWithCapitalizedPlugin, id]);

	return selectedEdit;
};

export default ParentEdit;
