import React, { FC, useContext } from 'react';
import { ScrollView, Text, View } from 'react-native';

import { MundoButton, MundoText } from '../../../components/elements';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { useHistory } from '../../../utilities/routing';
import { IPipelineProps } from '../props';
import settingStyles, {
	pipelineEditStyles,
	pipelineViewStyles,
} from '../styles';
import messages from './messages';
import { PipelineContext } from './Pipeline';

export const PipelineView: FC<IPipelineProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { settings, onChangeSettings } = useContext(SettingsContext);
	const { setEditMode } = React.useContext(PipelineContext);

	const history = useHistory();

	// Pipeline Settings

	const deleteModulePressHandler = () => {
		if (settings) {
			const newList = settings.pipelines;
			newList.splice(props.index, 1);
			onChangeSettings({ ...settings, pipelines: newList });
		}
	};

	const openSettings = (moduleTitle: string) => {
		history.push(
			`/edit/${props.pipeline._id}/${moduleTitle}/${
				props.pipeline.id || 'new'
			}`,
		);
	};

	return (
		<ScrollView horizontal>
			<View
				style={pipelineEditStyles(theme).pipelineEditContainer}
				key={props.pipeline.id}
			>
				<View style={pipelineViewStyles(theme).item}>
					{props.pipeline.title ? (
						<View style={pipelineViewStyles(theme).pipelineTitle}>
							<Text
								{...{
									dataSet: {
										cy: `pipeline.${props.pipeline.title}`,
									},
								}}
							>
								{props.pipeline.title}
							</Text>
						</View>
					) : null}
					{props.pipeline.modules.map((module, index) => (
						<View
							style={[
								pipelineViewStyles(theme)
									.pipelineListModuleDescription,
								styles.card,
								styles.horizontalLayout,
							]}
							key={module.identifier + index}
						>
							<MundoText
								dataSet={{
									cy: `${props.pipeline.title}.${module.identifier}`,
								}}
								message={transformModuleTitle(module)}
							/>
							<MundoButton
								dataSet={{
									cy: `${props.pipeline.title}.${module.identifier}.settings`,
								}}
								styles={styles.transparentButton}
								onPress={() => openSettings(module.identifier)}
								icon={'settings'}
							/>
						</View>
					))}
				</View>
				<View
					style={[
						styles.horizontalLayout,
						styles.leftMargin30,
						pipelineViewStyles(theme).buttons,
					]}
				>
					<MundoButton
						dataSet={{
							cy: `pipeline.${props.pipeline.title}.edit`,
						}}
						key={`Edit${props.pipeline.id}`}
						title={messages.edit}
						styles={[
							settingStyles(theme).button,
							styles.neutralButton,
						]}
						onPress={() => setEditMode(true)}
					/>
					<MundoButton
						dataSet={{
							cy: `pipeline.${props.pipeline.title}.delete`,
						}}
						key={`Del${props.pipeline.id}`}
						title={messages.delete}
						styles={[
							settingStyles(theme).button,
							styles.deleteButton,
						]}
						onPress={() => deleteModulePressHandler()}
					/>
				</View>
			</View>
		</ScrollView>
	);
};
