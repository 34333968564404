import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useParams } from 'react-router-dom';

import {
	EUserClassification,
	MCustomer,
	MGroup,
	MUser,
} from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import MundoPicker from '../../../components/elements/MundoPicker';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';
import {
	DELETE_USER,
	GET_CUSTOMER,
	GET_CUSTOMERS,
	GET_GROUPS,
	GET_USER,
	SAVE_USER,
} from './queries';

const UserEdit: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const [user, onChangeUser] = useState(new MUser());

	useQuery(GET_USER, {
		variables: { id },
		skip: id === 'new',
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			onChangeUser(new MUser(data.user));
			if (data.user.customer._id) {
				loadCustomer({ variables: { id: data.user.customer._id } });
			}
		},
	});

	const [loadCustomer] = useLazyQuery<
		{ customer: MCustomer },
		{ id?: string }
	>(GET_CUSTOMER, {
		onCompleted: (queryData) => {
			if (queryData && queryData.customer) {
				if (!customer) {
					changeCustomer(queryData.customer);
				}
			}
		},
	});

	const [customer, changeCustomer] = useState<MCustomer>();

	const [saveUser] = useMutation(SAVE_USER);
	const [deleteUser] = useMutation(DELETE_USER);

	const update = (input: Partial<MUser>) => {
		const next = Object.assign({}, user, input);
		onChangeUser(next);
	};

	const saveButtonHandler = async () => {
		await saveUser({ variables: { user } });
		history.goBack();
	};

	const deleteButtonHandler = async () => {
		await deleteUser({ variables: { id: user._id } });
		history.goBack();
	};

	const checkEmailInput = (input: any) => {
		const regex = new RegExp(
			// eslint-disable-next-line
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^$/,
		);

		if (!input.match(regex)) {
			return messages.mailError;
		}
		return;
	};

	const checkPhoneInput = (input: any) => {
		const regex = new RegExp(/^[0-9]*$/);

		if (!input.match(regex)) {
			return messages.phoneError;
		}
		return;
	};

	const selectLabel = (key: string) => {
		switch (key) {
			case EUserClassification.DEFAULT:
				return messages.defaultClass;
			case EUserClassification.ADMIN:
				return messages.adminClass;
			case EUserClassification.MEMBER:
				return messages.memberClass;
			case EUserClassification.TERMINAL:
				return messages.terminalClass;
			default:
				return messages.defaultClass;
		}
	};

	return (
		<View
			{...{ dataSet: { cy: 'UserEdit' } }}
			style={styles.containerFullResolution}
		>
			<View style={styles.headerView}>
				<MundoText
					message={messages.header}
					styles={styles.headerText}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View
						style={[
							styles.containerFullWidthHorizontal,
							styles.elevated,
						]}
					>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
								styles.elevated,
							]}
						>
							<MundoText
								message={messages.companyName}
								styles={[styles.pickerLabelSpacing]}
							/>

							<MundoObjectTypeahead
								cyId={'user.companyName.input'}
								value={customer}
								placeholder={messages.companyName}
								onChange={(next) =>
									next &&
									update({
										customer: new MCustomer({
											...next,
											title: next.title as string,
										}),
									})
								}
								QUERY={GET_CUSTOMERS}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'user.title.input' }}
								label={messages.title}
								onChangeText={(title) => update({ title })}
								value={user.title}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'user.mail.input' }}
								label={messages.mail}
								onChangeText={(mail) => update({ mail })}
								value={user.mail}
								customCheckInvalidity={checkEmailInput}
							/>
						</View>
					</View>
					<View style={styles.containerFullWidthHorizontal}>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'user.phone.input' }}
								label={messages.telephone}
								onChangeText={(telephone) =>
									update({ telephone })
								}
								value={user.telephone}
								customCheckInvalidity={checkPhoneInput}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'user.tags.input' }}
								label={messages.tags}
								onChangeText={(tags) =>
									update({ tags: tags.split(',') })
								}
								value={user.tags ? user.tags.join(',') : ''}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoPicker
								label={messages.classification}
								dataSet={{ cy: 'user.class.picker' }}
								onChange={(
									classification: EUserClassification,
								) => update({ classification })}
								value={user.classification}
								values={Object.keys(EUserClassification).map(
									(key) => {
										return {
											label: selectLabel(key),
											value: key,
										};
									},
								)}
							/>
						</View>
					</View>
					<View
						style={styles.containerFullPercentageWidthLeftPadding}
					>
						{user.classification === EUserClassification.MEMBER && (
							<>
								<View
									style={[
										styles.containerFullPercentageWidth,
										styles.elevated,
									]}
								>
									<MundoText message={messages.groups} />
									<View
										style={[
											styles.containerFullWidthHorizontalNoPadding,
											styles.wrap,
										]}
									>
										{user.groups.map((group, index) => (
											<View
												key={group._id}
												style={[
													styles.formUnitStandardWidth,
													styles.formUnitHeightVertCenterHalfHeight,
													styles.elevated,
													{
														zIndex:
															20 +
															user.groups.length -
															index,
													},
												]}
											>
												<MundoObjectTypeahead
													cyId={'user.group.input'}
													value={group}
													onChange={(newG) => {
														if (newG) {
															const newGroups =
																user.groups;
															newGroups.splice(
																index,
																1,
																new MGroup({
																	...newG,
																	title: newG.title as string,
																}),
															);
															update({
																groups: newGroups,
															});
														}
													}}
													key={
														index +
														group.id +
														group.title
													}
													QUERY={GET_GROUPS}
												/>
											</View>
										))}
									</View>
								</View>
								<MundoButton
									dataSet={{ cy: 'user.addGroup.button' }}
									onPress={() =>
										update({
											groups: [
												...user.groups,
												new MGroup(),
											],
										})
									}
									title={messages.addGroup}
									styles={[
										styles.formButtonStandardWidth,
										styles.topMargin20,
									]}
								/>
							</>
						)}
					</View>
				</View>
				<FinishButtonGroup
					cyId={'user'}
					cancelFunction={history.goBack}
					saveFunction={saveButtonHandler}
					deleteFunction={deleteButtonHandler}
				/>
			</ScrollView>
		</View>
	);
};

export default UserEdit;
