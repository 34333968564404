import React, { FC } from 'react';
import { View } from 'react-native';

import { useTheme } from '../../../utilities/hooks/styles';
import {
	transformModuleIcon,
	transformModuleTitle,
} from '../../../utilities/moduleTransformers';
import { useHistory } from '../../../utilities/routing';
import { MundoButton } from '../../elements';
import { IModuleProps } from '../props';
import sidebarStyles from '../styles';

export const Module: FC<IModuleProps> = ({
	module,
	closeSidebar,
	detail,
	pipeline,
}) => {
	const { theme } = useTheme();
	const history = useHistory();
	const navigate = (path: string) => {
		history.push(path);
		if (closeSidebar) {
			closeSidebar();
		}
	};
	return (
		<View>
			<MundoButton
				dataSet={{ cy: `button${module.identifier}` }}
				onPress={() =>
					navigate(`/dash/${pipeline._id}/${module.identifier}`)
				}
				title={detail ? transformModuleTitle(module) : undefined}
				icon={transformModuleIcon(module)}
				styles={[
					sidebarStyles(theme).navButton,
					detail && sidebarStyles(theme).exNavButton,
				]}
			/>
		</View>
	);
};
