import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../utilities/constants';

export const signStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		signaturePad: {
			borderColor: theme.backgroundTertiaryColor,
			borderStyle: 'solid',
			borderWidth: 1,
			width: 400, // keep in mind this is ineffective
			height: 200, // keep in mind this is ineffective
			margin: 0,
		},
	});
