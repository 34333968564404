import { SData } from 'mango-utils-client';

export const constructComperatorSet = (d1: SData[], d2: SData[]): SData[][] => {
	const stack = [d1];
	stack.push(
		d1.map((v, i) => {
			const current = v.y;
			const prev = d2[i].y;
			const diff = prev - current;
			const boi: SData = { x: v.x, y: d2[i].y };
			if (!prev) {
				boi.label = `--,-%`;
			} else if (diff > 0) {
				boi.label = `-${Math.round((diff / d2[i].y) * 1000) / 10}%`;
				boi.y = diff;
			} else {
				boi.label = `+${
					(Math.round((diff / d2[i].y) * 1000) / 10) * -1
				}%`;
				boi.y = 0;
			}
			return boi;
		}),
	);
	return stack;
};
