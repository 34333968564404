import { MessageDescriptor } from 'react-intl';
import messages from './messages';

export function convertDateToMonth(date: number | Date): MessageDescriptor {
	const parseDate = new Date(date);
	const month = parseDate.getMonth();
	const months = [
		messages.january,
		messages.february,
		messages.march,
		messages.april,
		messages.may,
		messages.june,
		messages.juli,
		messages.august,
		messages.september,
		messages.oktober,
		messages.november,
		messages.december,
	];

	return months[month];
}
