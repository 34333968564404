import { useLazyQuery } from '@apollo/client';
import { MArticle } from 'mango-utils-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_ARTICLES } from './queries';

interface IUseArticlesProps {
	skip?: boolean;
	QUERY?: any;
	disabled?: boolean;
	orderBy?: string | undefined;
}

export const useArticles = (props: IUseArticlesProps) => {
	const [articles, onChangeArticles] = useState<MArticle[]>([]);
	const [filter, onChangeFilter] = useState<MArticle>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			article: { ...filter, disabled: props.disabled },
			start: articles.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, articles, props.disabled, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(props.QUERY || GET_ARTICLES, {
		onCompleted: (data) => {
			if (data) {
				if (!data.articles.length || data.articles.length % 20 !== 0) {
					setLoadMoreActive(false);
				}
				if (reset) {
					onChangeArticles(data.articles);
					onChangeReset(false);
				} else {
					onChangeArticles([...articles, ...data.articles]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				article: { ...filter, disabled: props.disabled },
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.disabled, props.orderBy]);

	return { articles, loading, loadMore, onChangeFilter, loadMoreActive };
};
