import { defineMessages } from 'react-intl';

export const scope = 'mango.components.items';

export default defineMessages({
	price: {
		id: `${scope}.price`,
		defaultMessage: 'price',
	},
	placeholderPrice: {
		id: `${scope}.placeholderPrice`,
		defaultMessage: 'price',
	},
	amount: {
		id: `${scope}.amount`,
		defaultMessage: 'amount',
	},
	placeholderAmount: {
		id: `${scope}.placeholderAmount`,
		defaultMessage: 'amount',
	},
	placeholderDiscount: {
		id: `${scope}.placeholderDiscount`,
		defaultMessage: 'discount',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	placeholderTitle: {
		id: `${scope}.placeholderTitle`,
		defaultMessage: 'title',
	},
	placeholderUnit: {
		id: `${scope}.placeholderUnit`,
		defaultMessage: 'unit',
	},
	currency: {
		id: `${scope}.currency`,
		defaultMessage: '€',
	},
	percent: {
		id: `${scope}.percent`,
		defaultMessage: '%',
	},
	unit: {
		id: `${scope}.unit`,
		defaultMessage: 'unit',
	},
	tax: {
		id: `${scope}.tax`,
		defaultMessage: 'tax',
	},
	controlled: {
		id: `${scope}.controlled`,
		defaultMessage: 'controlled',
	},
	free: {
		id: `${scope}.free`,
		defaultMessage: 'freeform',
	},
});
