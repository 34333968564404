import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../../../components/elements/MundoInput';
import { useStyle } from '../../../../../../utilities/hooks/styles';
import messages from '../messages';
import { IDriverFilterFieldProps } from '../props';

export const DriverIdFilter: FC<IDriverFilterFieldProps> = ({
	driver,
	update,
}) => {
	const styles = useStyle();

	return (
		<View style={[styles.formUnitStandardWidth]}>
			<MundoInput
				label={messages.id}
				onChangeText={(id) => update({ id: +id })}
				value={`${driver.id}`}
				type={`numeric`}
				length={5}
			/>
		</View>
	);
};
