import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.PreproductList';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	chemicalDescription: {
		id: `${scope}.chemicalDescription`,
		defaultMessage: 'chemical description',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit',
	},
	preproducts: {
		id: `${scope}.preproducts`,
		defaultMessage: 'preproducts',
	},
});
