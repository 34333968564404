import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useParams } from '../../utilities/routing';

import { EUserClassification, MPipeline } from 'mango-utils-client';
import { MundoText } from '../../components/elements';
import { AuthContext } from '../../utilities/hooks/auth';
import { SettingsContext } from '../../utilities/hooks/settings';
import { useStyle } from '../../utilities/hooks/styles';
import TerminalDashboard from '../DashboardTerminal';
import InvoiceDashboard from '../Invoice/Dashboard';
import SignDashboard from '../Sign/Dashboard';
import messages from './messages';
import { ReceiptDashboard } from '../Receipt/Dashboard';
import { RecordDashboard } from '../Record/Dashboard';

import EquipmentManagerDashboard from '../../Plugins/VehicleCleaning/container/EquipmentManager/Dashboard';
import MetaDataDashboard from '../../Plugins/VehicleCleaning/container/MetaData/Dashboard';
import TReceiptDashboard from '../../Plugins/VehicleCleaning/container/Receipt/Dashboard';
import TRecordDashboard from '../../Plugins/VehicleCleaning/container/Record/Dashboard';
import { DefaultDashboard } from './DefaultDashboard';

export const Dashboard: FC = () => {
	const styles = useStyle();

	const { user } = useContext(AuthContext);
	const { title, pipelineId } = useParams<{
		title: string;
		pipelineId: string;
	}>();
	const { settings } = useContext(SettingsContext);
	const [pipeline, onChangePipeline] = useState<MPipeline>(new MPipeline());

	useEffect(() => {
		if (pipelineId) {
			const next = settings.pipelines.find(
				(p) => p._id === pipelineId,
			) as MPipeline;
			onChangePipeline(next);
		} else {
			console.error('jo where the pipelineId @');
		}
	}, [settings, pipelineId]);

	if (user.classification === EUserClassification.DEFAULT) {
		return (
			<View style={styles.headerView}>
				<MundoText message={messages.notUnlocked} />
			</View>
		);
	}
	if (user.classification === EUserClassification.TERMINAL && !title) {
		return <TerminalDashboard />;
	}

	switch (title) {
		case 'terminal':
			return <TerminalDashboard />;
		case 'sign':
			return <SignDashboard pipeline={pipeline} />;
		case 'equipmentManager.vehicleCleaning':
			return <EquipmentManagerDashboard pipeline={pipeline} />;
		case 'metaData.vehicleCleaning':
			return <MetaDataDashboard pipeline={pipeline} />;
		case 'receipt.vehicleCleaning':
			return <TReceiptDashboard pipeline={pipeline} />;
		case 'record.vehicleCleaning':
			return <TRecordDashboard pipeline={pipeline} />;
		case 'invoice':
			return <InvoiceDashboard pipeline={pipeline} />;
		case 'receipt':
			return <ReceiptDashboard pipeline={pipeline} />;
		case 'record':
			return <RecordDashboard pipeline={pipeline} />;
		case 'default':
			return <DefaultDashboard pipeline={pipeline} />;
		default:
			return <DefaultDashboard pipeline={pipeline} />;
	}
};
