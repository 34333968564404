import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../../../utilities/constants';

export const receiptSettingsStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		buttons: {
			display: 'flex',
			marginLeft: 10,
			flexDirection: 'row',
		},
		list: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		heightLimiter: {
			height: '50%',
		},
		primary: {
			color: theme.backgroundMainColor,
		},
	});
