import { defineMessages } from 'react-intl';

export const scope = 'mango.module.invoice.groupInvoice';

export default defineMessages({
	groupInvoiceId: {
		id: `${scope}.groupInvoiceId`,
		defaultMessage: 'group invoice id',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'group invoice',
	},
	dueDate: {
		id: `${scope}.dueDate`,
		defaultMessage: 'due date',
	},
	startDate: {
		id: `${scope}.startDate`,
		defaultMessage: 'start date',
	},
	endDate: {
		id: `${scope}.endDate`,
		defaultMessage: 'end date',
	},
	noOnChange: {
		id: `${scope}.noOnChange`,
		defaultMessage: 'internal error: no on change function provided',
	},
});
