import { defineMessages } from 'react-intl';

export const scope = 'mango.components.ImageSelector';

export default defineMessages({
	error: {
		id: `${scope}.error`,
		defaultMessage: 'image picker error:',
	},
	upload: {
		id: `${scope}.upload`,
		defaultMessage: 'upload image',
	},
});
