import { defineMessages } from 'react-intl';

export const scope = 'mango.components.language';

export default defineMessages({
	title: {
		id: `${scope}.languageSelect`,
		defaultMessage: 'Select a Language',
	},
});
