import { useMutation, useQuery } from '@apollo/client';
import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { ScrollView, View } from 'react-native';
import MediaQuery from 'react-responsive';
import { useParams } from 'react-router-dom';

import { ELanguages, MArticle, MTranslation } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import MundoPicker from '../../../components/elements/MundoPicker';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { alert } from '../../../utilities/alert/index.web';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { useArticles } from '../../../utilities/hooks/article';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory } from '../../../utilities/routing';
import ArticleFilter from '../Filter';
import {
	DataCheckboxes,
	DataUnitField,
	DescriptionField,
	PriceField,
	ShortCodeField,
	TaxField,
	TitleField,
	UnitField,
} from './components';
import SubarticleEntry from './components/SubarticleEntry';
import SubarticleSelectEntry from './components/SubarticleSelectEntry';
import messages from './messages';
import { IArticleEditProps } from './props';
import { queries } from './queries';
import { articleEditStyles } from './styles';
import { destructData } from '../../../utilities/dataFunctions';

export const ArticleEditContext = createContext({
	article: new MArticle(),
	update: (input: Partial<MArticle>) => {
		alert('error', input._id || '', 'error');
	},
});

const ArticleEdit: FC<IArticleEditProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const { language: appLanguage } = useContext(LanguageContext);
	const [language, onChangeLanguage] = useState<ELanguages>(appLanguage);

	const [article, onChangeArticle] = useState<MArticle>(
		new MArticle({
			title: [
				new MTranslation({
					lang: language,
					message: props.initialTitle || '',
				}),
			],
		}),
	);
	const [filterArticle, onChangeFilterArticle] = useState();

	const articleQueries = { ...queries, ...props.query };

	const {
		articles: subArticles,
		loadMore,
		loading,
		loadMoreActive,
		onChangeFilter,
	} = useArticles({ skip: false, QUERY: articleQueries.subArticles });
	useQuery(articleQueries.article, {
		skip: id === 'new',
		variables: { id },
		onCompleted: (data) => {
			if (data) {
				const articleData = new MArticle(destructData(data));
				onChangeArticle(articleData);
			}
		},
	});

	useEffect(() => {
		onChangeFilter(filterArticle);
	}, [onChangeFilter, filterArticle]);

	const [saveArticle] = useMutation(articleQueries.save);
	const [deleteArticle] = useMutation(
		article.articleType === 'article'
			? articleQueries.deleteArticle
			: articleQueries.deleteTArticle,
	);

	const removeArticlePressHandler = (currentArticle: MArticle) => {
		const index = article.subArticles.indexOf(currentArticle);
		article.subArticles.splice(index, 1);

		update({ subArticles: article.subArticles });
	};

	const update = useCallback(
		(input: Partial<MArticle>) => {
			const next = Object.assign({}, article, input);
			onChangeArticle(next);
		},
		[article],
	);

	/**
	 * check if art is on article
	 * @param articleToCheck article to check for
	 */
	const isArticleASubArticle = (articleToCheck: MArticle) =>
		-1 <
		article.subArticles.findIndex(
			(element) => element._id === articleToCheck._id,
		);
	/**
	 * save function
	 */
	const saveButtonHandler = async () => {
		await saveArticle({ variables: { article } });
		history.goBack();
	};

	const deleteButtonHandler = async () => {
		await deleteArticle({ variables: { id: article._id } });

		history.goBack();
	};

	return (
		<ArticleEditContext.Provider value={{ article, update }}>
			<View style={styles.containerFullResolution}>
				<View style={styles.headerView}>
					<View style={styles.headerTitleContainer}>
						<MundoText
							styles={styles.headerText}
							message={messages.header}
						/>
						<MundoButton
							icon={'question'}
							subtype="transparent"
							onPress={() =>
								openDocs('#/content/settings?id=artikel')
							}
						/>
					</View>
					<MundoPicker
						style={[styles.formUnitHalfWidth]}
						onChange={onChangeLanguage}
						values={Object.keys(ELanguages).map((lang) => {
							return { label: lang, value: lang };
						})}
						value={language}
					/>
				</View>
				<ScrollView>
					<View style={styles.spacedContainerHorizontal}>
						<View style={articleEditStyles(theme).formFull}>
							<MediaQuery minWidth={0} maxWidth={1019}>
								<View style={[styles.formLeftHorizontal]}>
									<View
										style={[
											styles.formUnitHeightVertCenter,
										]}
									>
										<TitleField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<ShortCodeField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DescriptionField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
								</View>
								<View style={styles.formLeftHorizontal}>
									<View
										style={[
											styles.formUnitHeightVertCenter,
										]}
									>
										<PriceField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,

											styles.leftMargin26,
										]}
									>
										<TaxField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<UnitField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DataUnitField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
								</View>
								<View style={[styles.formUnitHeightVertCenter]}>
									<DataCheckboxes
										value={article}
										update={update}
									/>
								</View>
							</MediaQuery>
							<MediaQuery minWidth={1020} maxWidth={1120}>
								<View style={styles.formLeftHorizontal}>
									<View
										style={[
											styles.formUnitHeightVertCenter,
										]}
									>
										<TitleField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<ShortCodeField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DescriptionField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<PriceField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,

											styles.leftMargin26,
										]}
									>
										<TaxField
											value={article}
											update={update}
										/>
									</View>
								</View>
								<View style={styles.formLeftHorizontal}>
									<View
										style={[
											styles.formUnitHeightVertCenter,
										]}
									>
										<UnitField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DataUnitField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DataCheckboxes
											value={article}
											update={update}
										/>
									</View>
								</View>
							</MediaQuery>
							<MediaQuery minWidth={1121}>
								<View style={styles.formLeftHorizontal}>
									<View
										style={[
											styles.formUnitHeightVertCenter,
										]}
									>
										<TitleField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<ShortCodeField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DescriptionField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<PriceField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<TaxField
											value={article}
											update={update}
										/>
									</View>
								</View>
								<View style={styles.formLeftHorizontal}>
									<View
										style={[
											styles.formUnitHeightVertCenter,
										]}
									>
										<UnitField
											value={article}
											update={update}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DataUnitField
											value={article}
											update={update}
											lang={language}
										/>
									</View>
									<View
										style={[
											styles.formUnitHeightVertCenter,
											styles.leftMargin20,
										]}
									>
										<DataCheckboxes
											value={article}
											update={update}
										/>
									</View>
								</View>
							</MediaQuery>
							<View
								style={[
									styles.formUnitHeightVertCenter,
									{ height: 140 },
								]}
							>
								{props.children}
							</View>
						</View>
					</View>
					<View style={styles.formContainer}>
						<View
							style={[
								articleEditStyles(theme).selector,
								styles.spacedContainer,
							]}
						>
							<MundoText message={messages.subArticles} />
							<View
								style={[
									styles.topMargin10,
									styles.bottomMargin10,
								]}
							>
								<ArticleFilter
									onChangeFilterArticle={
										onChangeFilterArticle
									}
									filterArticle={filterArticle}
								/>
							</View>
							<ScrollView>
								{subArticles &&
									subArticles.map((subArticle, index) => (
										<SubarticleEntry
											key={`ArticlesEditSelector${index}`}
											article={article}
											subArticle={subArticle}
											update={update}
											isArticleASubArticle={
												isArticleASubArticle
											}
										/>
									))}
								{loadMoreActive && !loading && (
									<MundoButton
										title={messages.loadMore}
										onPress={loadMore}
										styles={[
											styles.formButtonStandardWidth,
											styles.topMargin10,
											{ alignSelf: 'center' },
										]}
									/>
								)}
								{loading && <LoadingIndicator />}
							</ScrollView>
						</View>
						<View
							style={[
								articleEditStyles(theme).selectorRight,
								styles.spacedContainer,
							]}
						>
							<MundoText
								message={messages.selected}
								styles={styles.bottomMargin10}
							/>
							<ScrollView>
								{article.subArticles.map((subArticle) => (
									<SubarticleSelectEntry
										key={`ArticlesEditList${subArticle.id}`}
										subArticle={subArticle}
										remove={removeArticlePressHandler}
									/>
								))}
							</ScrollView>
						</View>
					</View>
					<FinishButtonGroup
						cyId={'article'}
						cancelFunction={history.goBack}
						saveFunction={saveButtonHandler}
						deleteFunction={deleteButtonHandler}
					/>
				</ScrollView>
			</View>
		</ArticleEditContext.Provider>
	);
};

export default ArticleEdit;
