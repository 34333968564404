import React, { FC, memo } from 'react';
import { View } from 'react-native';
import MundoInput from '../elements/MundoInput';

import { useStyle } from '../../utilities/hooks/styles';
import MundoText from '../elements/MundoText';
import messages from './messages';
import { IHostEditProps } from './props';

export const HostEdit: FC<IHostEditProps> = memo((props) => {
	const styles = useStyle();
	const {
		director,
		bank,
		court,
		onChange,
		commissioning,
		legalInformation,
	} = props;

	return (
		<View style={styles.containerFullWidthHorizontalNoPadding}>
			<View style={[styles.containerHalfWidth]}>
				<MundoText message={messages.header} />
				<MundoInput
					label={messages.director}
					value={director}
					onChangeText={(next) => onChange({ director: next })}
				/>
				<MundoInput
					label={messages.court}
					value={court}
					onChangeText={(next) => onChange({ court: next })}
				/>
			</View>
			<View style={[styles.containerHalfWidth]}>
				<MundoText message={messages.bank} />
				<MundoInput
					label={messages.bankTitle}
					value={bank.title}
					onChangeText={(next) =>
						onChange({ bank: { ...bank, title: next } })
					}
				/>
				<MundoInput
					label={messages.iban}
					value={bank.iban}
					onChangeText={(next) =>
						onChange({ bank: { ...bank, iban: next } })
					}
				/>
				<MundoInput
					label={messages.swift}
					value={bank.swift}
					onChangeText={(next) =>
						onChange({ bank: { ...bank, swift: next } })
					}
				/>
			</View>
			<View style={[styles.containerHalfWidth]}>
				<MundoInput
					label={messages.commissioning}
					value={commissioning}
					onChangeText={(next) => onChange({ commissioning: next })}
					multiline={4}
					customCheckInvalidity={(text) => {
						if (text.length > 250) {
							return messages.commissioningError;
						} else {
							return;
						}
					}}
				/>
				<MundoInput
					label={messages.legalInformation}
					value={legalInformation}
					onChangeText={(next) =>
						onChange({ legalInformation: next })
					}
					multiline={8}
					customCheckInvalidity={(text) => {
						if (text.length > 3000) {
							return messages.legalInformationError;
						} else {
							return;
						}
					}}
				/>
			</View>
		</View>
	);
});
