import React, { FC } from 'react';
import { ScrollView, View } from 'react-native';
// import { useQuery } from '@apollo/client';

// import  GraphQL query from file
import { MModuleDescription } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import { useStyle } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { IDashboardProps } from '../../Dashboard/props';
import OrderList from '../../Order/List';
import { useHistory, useParams } from '../../../utilities/routing';
import messages from './messages';

export const ReceiptDashboard: FC<IDashboardProps> = (props) => {
	const styles = useStyle();
	const { pipelineId } = useParams<{ pipelineId: string }>();
	const history = useHistory();

	// fetch and return typed data by using a GraphQL query
	// const [receiptSettings, onChangeReceiptSettings] = useState(
	// 	new MModuleReceiptSettings(),
	// );
	// load settings
	// const { loading: loadingSettings } = useQuery(GET_TRECEIPT_SETTINGS, {
	// 	variables: {
	// 		pipelineId,
	// 	},
	// 	onCompleted: (response: { tReceiptSettings: MModuleReceiptSettings }) => {
	// 		if (response && response.tReceiptSettings) {
	// 			const receiptSettings = response.tReceiptSettings;

	// 			onChangeReceiptSettings(receiptSettings);
	// 		}
	// 	},
	// });

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					{props.pipeline && (
						<MundoText
							message={transformModuleTitle(
								props.pipeline.modules.find(
									(m) => m.identifier === 'receipt',
								) || new MModuleDescription(),
								true,
							)}
							styles={styles.headerText}
						/>
					)}
				</View>
				<MundoButton
					dataSet={{ cy: 'create' }}
					title={messages.createNewOrder}
					icon={'plus'}
					onPress={() =>
						history.push(`/module/${pipelineId}/receipt/new`)
					}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList
						state={'receipt'}
						pipeline={pipelineId}
						noFilter
						noHeader
					/>
				</View>
			</ScrollView>
		</View>
	);
};
