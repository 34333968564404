import { defineMessages } from 'react-intl';

export const scope = 'mango.components.mail.edit';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'Administrating Mail Configuration',
	},
	mail: {
		id: `${scope}.mail`,
		defaultMessage: 'mail',
	},
	mailDisplay: {
		id: `${scope}.mailDisplay`,
		defaultMessage: 'display name',
	},
	mailUsername: {
		id: `${scope}.mailUsername`,
		defaultMessage: 'username',
	},
	mailPassword: {
		id: `${scope}.mailPassword`,
		defaultMessage: 'password',
	},
	showPassword: {
		id: `${scope}.showPassword`,
		defaultMessage: 'show password',
	},
	mailHost: {
		id: `${scope}.mailHost`,
		defaultMessage: 'mail server',
	},
	mailPort: {
		id: `${scope}.mailPort`,
		defaultMessage: 'mail server port',
	},
	validationMail: {
		id: `${scope}.validationMail`,
		defaultMessage: 'validation mail',
	},
	hint: {
		id: `${scope}.hint`,
		defaultMessage:
			'Uppon pressing save the server will try to send a confirmation mail to the validation mail address. It will contain a link to be pressed to confirm the configuration was successfull!',
	},
});
