import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

export const orderOverviewStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		stateActive: {
			backgroundColor: theme.warningColor,
		},
		stateSelected: {
			backgroundColor: theme.accentSecondaryColor,
		},
		stateDone: {
			backgroundColor: theme.successColor,
		},
		stateInactive: {
			backgroundColor: theme.backgroundSecondaryColor,
			borderRadius: 4,
			padding: 10,
		},
	});
