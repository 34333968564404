import gql from 'graphql-tag';

export const GET_SIGN_SETTINGS = gql`
	query moduleSignSettings($pipelineId: String!) {
		moduleSignSettings(pipelineId: $pipelineId) {
			id
			_id
			title
			dashboardFields
		}
	}
`;

export const SAVE_SIGN_SETTINGS = gql`
	mutation saveSignSettings($signSettings: MModuleSignSettingsInput!) {
		saveSignSettings(signSettings: $signSettings) {
			_id
		}
	}
`;
