import React, { FC, memo } from 'react';
import { View } from 'react-native';

import { MundoButton, MundoText } from '../../../../components/elements';
import { useStyle } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { ISelectedPaymentEntry } from '../props';

const SelectedPaymentEntry: FC<ISelectedPaymentEntry> = memo((props) => {
	const { index, payMeth, dispatchPaymentAction } = props;
	const styles = useStyle();

	return (
		<View style={styles.horizontalLayout}>
			<MundoText message={payMeth.title} />
			<MundoButton
				icon={'up'}
				onPress={() =>
					dispatchPaymentAction({
						type: ETypes.SWAP_LEFT,
						location: index,
					})
				}
			/>
			<MundoButton
				icon={'down'}
				onPress={() =>
					dispatchPaymentAction({
						type: ETypes.SWAP_RIGHT,
						location: index,
					})
				}
			/>
			<MundoButton
				icon={'minus'}
				onPress={() =>
					dispatchPaymentAction({
						type: ETypes.REMOVE,
						location: index,
					})
				}
			/>
		</View>
	);
});

export default SelectedPaymentEntry;
