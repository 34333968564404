import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

import {
	getTranslation,
	MBundledArticle,
	MCustomer,
	MItem,
	MModuleReceiptSettings,
	MPackage,
	MState,
} from 'mango-utils-client';
import { useIntl } from 'react-intl';
import { MundoButton } from '../../../components/elements';
import MundoText from '../../../components/elements/MundoText';
import Item from '../../../components/Item';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { alert } from '../../../utilities/alert';
import { useArray } from '../../../utilities/hooks/array';
import { useStyle } from '../../../utilities/hooks/styles';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { useHistory, useParams } from '../../../utilities/routing';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import messages from './messages';
import { RECEIPT_OUT, RECEIPT_SETTINGS } from './queries';
import CustomerPicker from '../../Customer/Picker';
import { destructData } from '../../../utilities/dataFunctions';
import MundoPicker from '../../../components/elements/MundoPicker';
import { LanguageContext } from '../../../utilities/contexts/Language';
import PackageOverview from '../../../components/PackageOverview';

const ReceiptModule: FC = () => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);
	const intl = useIntl();
	const { pipelineId } = useParams<{
		pipelineId: string;
	}>();
	const [customer, onChangeCustomer] = useState<MCustomer>();
	const history = useHistory();
	const [items, dispatchItemAction] = useArray<MItem>();
	const [packages, dispatchPackageAction] = useArray<MPackage>();
	const [
		selectedPackages,
		dispatchSelectedPackageAction,
	] = useArray<MPackage>();
	// load articles from server when loadArticles is called
	const [receiptOut] = useMutation(RECEIPT_OUT);
	const { loading: loadingSet, called: calledSet } = useQuery(
		RECEIPT_SETTINGS,
		{
			variables: {
				pipelineId,
			},
			skip: !pipelineId, // for querying sequence
			onCompleted: (data) => {
				if (data) {
					const set = destructData<MModuleReceiptSettings>(data);
					dispatchItemAction({
						type: ETypes.RESET_TO,
						item: set.articles.map((art) => new MItem(art)),
					});
					console.log(set.packages);
					dispatchPackageAction({
						type: ETypes.RESET_TO,
						item: set.packages.map((p) => new MPackage(p)),
					});
				}
			},
		},
	);
	/**
	 * function to remove package
	 * @param pack pack to remove
	 */
	const removePackageHandler = (pack: MPackage) => {
		workWithItems(pack.includedArticles, true);
		selectedPackages.splice(selectedPackages.indexOf(pack), 1);
		const arts = selectedPackages.reduce(
			(prev, p) => [...prev, ...p.includedArticles],
			[] as MBundledArticle[],
		);
		workWithItems(pack.includedArticles, true);
		workWithItems(arts);
		dispatchSelectedPackageAction({
			type: ETypes.REMOVE,
			item: pack,
		});
	};
	/**
	 * check articles of chosen packages
	 */
	const workWithItems = useCallback(
		(bundles: MBundledArticle[], revert = false) => {
			items.forEach((item, index) => {
				const bookedItem = bundles.find(
					(bundle) =>
						bundle.articles.length &&
						bundle.articles[0]._id === item._id,
				);
				if (bookedItem) {
					item.amount = revert ? 1 : new MItem(bookedItem).amount;
					item.checked = !revert;
				}
				dispatchItemAction({
					type: ETypes.EDIT,
					location: index,
					item: new MItem(item),
				});
			});
			dispatchItemAction({
				type: ETypes.RESET_TO,
				item: items,
			});
		},
		[items, dispatchItemAction],
	);
	// LOADING
	if (!calledSet || loadingSet) {
		return <LoadingIndicator />;
	}
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<MundoText
					styles={styles.headerText}
					message={messages.receipt}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View style={styles.elevated}>
						<CustomerPicker
							customer={customer}
							onChange={(c) => onChangeCustomer(c)}
						/>
					</View>
					{packages && packages.length && (
						<MundoPicker
							label={messages.selectPackage}
							dataSet={{
								cy: 'receipt.vehicleCleaning.packages.picker',
							}}
							style={styles.pickerMaxWidth}
							onChange={(value) => {
								if (value && !isNaN(value)) {
									const packtoAdd = packages[value];
									if (
										!selectedPackages.find(
											(pack) => pack.id === packtoAdd.id,
										) &&
										packtoAdd
									) {
										dispatchSelectedPackageAction({
											type: ETypes.ADD,
											item: packtoAdd,
										});
										workWithItems(
											packtoAdd.includedArticles,
										);
									}
								}
							}}
							placeholder={messages.selectPackage}
							values={
								packages &&
								packages.map((pack, index) => {
									return {
										value: index,
										label: getTranslation(
											pack.title,
											language,
										),
									};
								})
							}
						/>
					)}
					{selectedPackages &&
						selectedPackages.map((pack) => (
							<View key={pack._id}>
								<PackageOverview
									key={`selectedpack${pack.id}`}
									pack={pack}
									canRemove={true}
									removeFunction={() =>
										removePackageHandler(pack)
									}
								/>
								<View style={styles.thinSeparator} />
							</View>
						))}
					{items.map((item, index) => (
						<Item
							key={item._id}
							value={item}
							onChange={(i) => {
								dispatchItemAction({
									type: ETypes.EDIT,
									item: new MItem(i),
									location: index,
								});
							}}
						/>
					))}
					<MundoButton
						onPress={async () => {
							try {
								receiptOut({
									variables: {
										state: new MState({
											items: items.filter(
												(item) => item.checked,
											),
											company: customer,
										}),
										pipelineId,
									},
								}).then(() => {
									history.push(
										'/dash/' + pipelineId + '/default',
									);
								});
							} catch (e) {
								alert(
									capitalizeFirstWord(
										intl.formatMessage(
											messages.orderCreateError,
										),
									),
									e,
									'error',
								);
							}
						}}
						title={messages.save}
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default ReceiptModule;
