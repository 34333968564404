import { MCustomer } from 'mango-utils-client';

export function getGroupInvoiceEndDate(
	customer: MCustomer,
	startDate?: number,
): number {
	const date = new Date(startDate || Date.now());
	const groupInvoiceTime = customer.groupInvoiceTime || 14;
	const daysToMsFactor = 1000 * 60 * 60 * 24;
	let endDate = new Date(date.getTime() + groupInvoiceTime * daysToMsFactor);
	const lastDayInMonth = new Date(
		date.getFullYear(),
		date.getMonth() + 1,
		0,
		date.getHours(),
		date.getMinutes(),
		date.getSeconds(),
	);
	if (endDate.getTime() > lastDayInMonth.getTime()) {
		endDate = lastDayInMonth;
	}

	return endDate.getTime();
}
