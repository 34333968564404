import { defineMessages } from 'react-intl';

export const pdfScope = 'pdf.';
export const scope = 'module.';

export default defineMessages({
	'vehicleCleaning.equipmentManager': {
		id: `${scope}vehicleCleaning.equipmentManager`,
		defaultMessage: 'equipment manager',
	},
	'vehicleCleaning.metaData': {
		id: `${scope}vehicleCleaning.metaData`,
		defaultMessage: 'metadata',
	},
	'vehicleCleaning.receipt': {
		id: `${scope}vehicleCleaning.receipt`,
		defaultMessage: 'receipt',
	},
	'vehicleCleaning.record': {
		id: `${scope}vehicleCleaning.record`,
		defaultMessage: 'record',
	},
	// Dasboards
	'vehicleCleaning.equipmentManagerDashboard': {
		id: `${scope}vehicleCleaning.equipmentManagerDashboard`,
		defaultMessage: 'Equipment Manager Dashboard',
	},
	'vehicleCleaning.metaDataDashboard': {
		id: `${scope}vehicleCleaning.metaDataDashboard`,
		defaultMessage: 'Metadata Dashboard',
	},
	'vehicleCleaning.receiptDashboard': {
		id: `${scope}vehicleCleaning.receiptDashboard`,
		defaultMessage: 'Receipt Dashboard',
	},
	'vehicleCleaning.recordDashboard': {
		id: `${scope}vehicleCleaning.recordDashboard`,
		defaultMessage: 'Record Dashboard',
	},
	'vehicleCleaning.deliveryNote': {
		id: `${pdfScope}vehicleCleaning.deliveryNote`,
		defaultMessage: 'delivery note',
	},
	'vehicleCleaning.ecd': {
		id: `${pdfScope}vehicleCleaning.ecd`,
		defaultMessage: 'ecd',
	},
	'vehicleCleaning.order': {
		id: `${pdfScope}vehicleCleaning.order`,
		defaultMessage: 'order',
	},
	'vehicleCleaning.qcd': {
		id: `${pdfScope}vehicleCleaning.qcd`,
		defaultMessage: 'qcd',
	},
	'vehicleCleaning.tankEntrance': {
		id: `${pdfScope}vehicleCleaning.tankEntrance`,
		defaultMessage: 'tank entrance',
	},
});
