import { defineMessages } from 'react-intl';

export const scope = 'mango.container.ProfileEdit';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'profile edit',
	},
	mail: {
		id: `${scope}.mail`,
		defaultMessage: 'e-mail address',
	},
	telephone: {
		id: `${scope}.telephone`,
		defaultMessage: 'telephone',
	},
	tags: {
		id: `${scope}.tags`,
		defaultMessage: 'tags',
	},
	clear: {
		id: `${scope}.clear`,
		defaultMessage: 'clear',
	},
	mailError: {
		id: `${scope}.mailError`,
		defaultMessage: 'the e-mail address is in the wrong format!',
	},
	phoneError: {
		id: `${scope}.phoneError`,
		defaultMessage: 'the phone number is in the wrong format!',
	},
});
