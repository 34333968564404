import { defineMessages } from 'react-intl';

export const scope = 'mango.customer.list';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit',
	},
	customers: {
		id: `${scope}.customers`,
		defaultMessage: 'customers',
	},
	missingValue: {
		id: `${scope}.missingValue`,
		defaultMessage: 'missing Value',
	},
});
