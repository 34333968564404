import { createContext } from 'react';

import { MUser } from 'mango-utils-client';
import { alert } from '../../../alert';

export const AuthContext = createContext({
	isLoggedIn: false,
	user: new MUser(),
	logOut: () => alert('onchangeLogin', 'is undefined', 'warning'),
	isAdmin: false,
	isHost: false,
	level: 0,
	checkLogin: () =>
		new Promise<string>(() =>
			console.log('the authcontext is not initialized properly'),
		),
	firstLoadComplete: false,
});
