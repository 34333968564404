import { useLazyQuery } from '@apollo/client';
import { MExport } from 'mango-utils-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_EXPORTS } from './queries';

export const useExports = () => {
	const [exports, updateLocalExports] = useState<MExport[]>([] as MExport[]);
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);

	const variables = useMemo(() => {
		return {
			count: 5,
			start: exports.length,
		};
	}, [exports]);

	const refetch = () => {
		lazyQuery({ variables });
	};

	const [lazyQuery, { loading }] = useLazyQuery(GET_EXPORTS, {
		onCompleted: (data) => {
			const extractedData = data.nExports;
			if (data) {
				if (!extractedData.length || extractedData.length % 5 !== 0) {
					setLoadMoreActive(false);
				}
				if (reset) {
					updateLocalExports(extractedData);
					onChangeReset(false);
				} else {
					updateLocalExports([...exports, ...extractedData]);
				}
				onChangeReset(false);
			}
		},
		variables,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				start: 0,
				count: 5,
			},
		});
	}, [lazyQuery]);

	return { exports, loading, loadMore, loadMoreActive, refetch };
};
