import React, { FC, ReactNode, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { PopUpContext } from '../../utilities/contexts/PopUp';
import { useTheme } from '../../utilities/hooks/styles';
import PopUpContainerStyles from './styles';

const PopUpContainer: FC = ({ children }) => {
	const { theme } = useTheme();

	const [content, onChangeContent] = useState<ReactNode>();
	const [open, onChangeOpen] = useState<boolean>(false);
	return (
		<PopUpContext.Provider
			value={{
				onChangePopUp: onChangeContent,
				open: () => onChangeOpen(true),
				close: () => onChangeOpen(false),
			}}
		>
			{open && (
				<View style={PopUpContainerStyles(theme).floater}>
					<TouchableOpacity
						style={PopUpContainerStyles(theme).closer}
						onPress={() => onChangeOpen(false)}
					/>
					<View style={PopUpContainerStyles(theme).content}>
						{content}
					</View>
				</View>
			)}
			{children}
		</PopUpContext.Provider>
	);
};

export default PopUpContainer;
