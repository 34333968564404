import { MUser } from 'mango-utils-client';
import React, { FC } from 'react';

import useAuth, { AuthContext } from '..';
import LoadingIndicator from '../../../../components/LoadingIndicator';

export const AuthProvider: FC = (props) => {
	const {
		user,
		isLoggedIn,
		loading,
		isAdmin,
		isHost,
		level,
		checkLogin,
		logOut,
		firstLoadComplete,
	} = useAuth();

	return (
		<AuthContext.Provider
			value={{
				user: user || new MUser(),
				isLoggedIn,
				isAdmin,
				isHost,
				level,
				checkLogin,
				logOut,
				firstLoadComplete,
			}}
		>
			{loading ? <LoadingIndicator /> : props.children}
		</AuthContext.Provider>
	);
};
