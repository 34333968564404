import { defineMessages } from 'react-intl';

export const scope = 'mango.components.UserEdit';

export default defineMessages({
	cancel: {
		id: `${scope}.cancel`,
		defaultMessage: 'cancel',
	},
	delete: {
		id: `${scope}.delete`,
		defaultMessage: 'delete',
	},
	save: {
		id: `${scope}.save`,
		defaultMessage: 'save',
	},
	saveAndContinue: {
		id: `${scope}.saveAndContinue`,
		defaultMessage: 'save and continue',
	},
	confirmDelete: {
		id: `${scope}.confirmDelete`,
		defaultMessage: 'are you sure you want to delete this?',
	},
});
