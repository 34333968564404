import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MOrder } from 'mango-utils-client';
import { GET_ORDERS } from './queries';

interface IUseOrdersProps {
	skip?: boolean;
	QUERY?: any; // figure out le type
	orderBy?: string | undefined;
	initialFilter?: Partial<MOrder>;
	poll?: number;
}

export const useOrders = (props: IUseOrdersProps) => {
	const [orders, updateLocalOrders] = useState<MOrder[]>([] as MOrder[]);
	const [filter, onChangeFilter] = useState<MOrder>(
		(props?.initialFilter as MOrder) || undefined,
	);
	const [fromDate, onChangeFrom] = useState<number>();
	const [toDate, onChangeTo] = useState<number>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			order: { ...filter },
			start: orders.length,
			count: 20,
			from: fromDate,
			to: toDate,
			orderBy: props.orderBy,
		};
	}, [filter, orders, fromDate, toDate, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_ORDERS, {
		onCompleted: (data) => {
			if (data) {
				if (!data.orders.length || data.orders.length % 20 !== 0) {
					setLoadMoreActive(false);
				}
				if (reset || orders.length <= 20) {
					updateLocalOrders(data.orders);
					onChangeReset(false);
				} else {
					updateLocalOrders([...orders, ...data.orders]);
				}
			}
		},
		notifyOnNetworkStatusChange: true,
		variables,
		...props,
		pollInterval: props.poll && orders.length < 20 ? props.poll : 0,
	});
	/**
	 * polling
	 */
	// useEffect(() => {
	// 	if (props.poll) {
	// 		setInterval(() => {
	// 			setLoadMoreActive(true);
	// 			onChangeReset(true);
	// 			lazyQuery({variables: {
	// 				...variables,
	// 				from: 0,
	// 				count: variables.start,
	// 			}});
	// 		}, props.poll);
	// 	}
	// }, [props, variables, lazyQuery]);
	/**
	 * load more
	 */
	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);
	/**
	 * reset
	 */
	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				order: { ...filter },
				count: 20,
				start: 0,
				from: fromDate,
				to: toDate,
				orderBy: props.orderBy,
			},
		});
	}, [lazyQuery, filter, fromDate, toDate, props.orderBy]);

	return {
		orders,
		loading,
		loadMore,
		onChangeFilter,
		onChangeFrom,
		onChangeTo,
		loadMoreActive,
	};
};
