import { defineMessages } from 'react-intl';

export const scope = 'mango.container.ArticleFilter';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	short: {
		id: `${scope}.short`,
		defaultMessage: 'short',
	},
});
