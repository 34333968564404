import messages_cs from './cs.json';
import messages_de from './de.json';
import messages_en from './en.json';
import messages_es from './es.json';
import messages_fr from './fr.json';
import messages_hu from './hu.json';
import messages_it from './it.json';
import messages_lv from './lv.json';
import messages_pl from './pl.json';
import messages_ro from './ro.json';
import messages_ru from './ru.json';
import messages_sl from './sl.json';

const translations = {
	de: messages_de,
	en: messages_en,
	fr: messages_fr,
	it: messages_it,
	lv: messages_lv,
	pl: messages_pl,
	ro: messages_ro,
	ru: messages_ru,
	sl: messages_sl,
	es: messages_es,
	cs: messages_cs,
	hu: messages_hu,
};

export default translations;
