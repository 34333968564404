import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';

import { MItem } from 'mango-utils-client';
import unitMessages from '../../messages';
import { confirm } from '../../utilities/alert';
import { useStyle } from '../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../utilities/stringFunctions';
import { MundoText } from '../elements';
import FinishButtonGroup from '../FinishButtonGroup';
import messages from './messages';
import { IRecordPagesOverviewProps } from './props';

const RecordPagesOverview: FC<IRecordPagesOverviewProps> = (props) => {
	const styles = useStyle();
	const intl = useIntl();
	const { save, saveAndContinue } = props;
	const pages: Array<{ title: string; items: MItem[] }> = [];

	props.pages.forEach((page) => {
		pages.push({
			title: page.title,
			items: page.items.filter((item) => item.checked),
		});
	});

	const saveCB = useCallback(
		(cb: () => void) => {
			const missingPages = props.pages.map((page) => {
				return {
					items: page.items.filter(
						(item) => item.highlighted && !item.checked,
					),
					title: page.title,
				};
			});

			if (missingPages.find((page) => page.items.length)) {
				let missingString = '';
				missingPages.forEach((page) => {
					if (page.items.length) {
						missingString += page.title + ': "';
						missingString +=
							page.items.map((item) => item.short).join(', ') +
							'" ';
					}
				});
				confirm(
					capitalizeFirstWord(
						intl.formatMessage(messages.missingBookedItems),
					),
					missingString,
					() => {
						cb();
					},
					'info',
				);
			} else {
				cb();
			}
		},
		[intl, props],
	);
	return (
		<View style={styles.fullSize}>
			{pages.map((page) => {
				if (page.items.length > 0) {
					return (
						<View key={`pageoverviewpage${page.title}`}>
							<Text>{page.title}</Text>
							<View style={styles.thinSeparator} />
							{page.items.map((item) => (
								<View
									key={`inpage:${page.title}:item:${item.id}`}
								>
									<View style={[styles.tableRow]}>
										<View
											style={styles.wideGroupTableColumn}
										>
											<Text>{`${item.short} ${item.title}`}</Text>
										</View>
										<View
											style={styles.wideGroupTableColumn}
										>
											<Text>{`${item.amount} `}</Text>
											<MundoText
												message={
													unitMessages[item.unit]
												}
											/>
										</View>
										<View
											style={styles.wideGroupTableColumn}
										>
											<Text>{`${item.data} ${item.dataUnit}`}</Text>
										</View>
										<View
											style={styles.wideGroupTableColumn}
										>
											<Text>{`${item.data} ${item.dataUnit}`}</Text>
										</View>
									</View>
									<View style={styles.thinSeparator} />
								</View>
							))}
						</View>
					);
				}
				return null;
			})}
			<FinishButtonGroup
				cyId={'record'}
				saveFunction={() => saveCB(save)}
				saveAndContinueFunction={() => saveCB(saveAndContinue)}
			/>
		</View>
	);
};

export default RecordPagesOverview;
