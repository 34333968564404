import { gql } from '@apollo/client';

export const GET_STATUS = gql`
	query v {
		version
	}
`;

export const GET_LOGO = gql`
	query y {
		logo
	}
`;
