import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Image, ScrollView, View } from 'react-native';
import MediaQuery from 'react-responsive';

import { MCustomer, MPipeline, MSettings } from 'mango-utils-client';
import MundoButton from '../../components/elements/MundoButton';
import MundoObjectTypeahead from '../../components/elements/MundoObjectTypeahead';
import MundoPadButton from '../../components/elements/MundoPadButton';
import MundoText from '../../components/elements/MundoText';
import ImageSelector from '../../components/ImageSelector';
import { SettingsContext } from '../../utilities/hooks/settings';
import { useStyle, useTheme } from '../../utilities/hooks/styles';
import { openDocs } from '../../utilities/openDocs';
import { useHistory } from '../../utilities/routing';
import { ArchivConfig, PermissionGrid, Pipeline } from './components/';
import { CountryConfig } from './components/CountryConfig';
import { InteractionConfig } from './components/InteractionConfig';
import messages from './messages';
import { GET_CUSTOMERS } from './queries';
import { settingStyles } from './styles';
import { EPluign, getExtension } from '../../Plugins';

const SettingsScreen: FC = () => {
	const styles = useStyle();
	const { theme } = useTheme();
	// get Global Settings Context
	const { settings, onChangeSettings } = useContext(SettingsContext);
	// Layout Settings
	const [logoURL, onChangeLogoURL] = useState(settings.logoURL);
	// Host
	const [host, onChangeHost] = useState<MCustomer>(settings.host);
	const [logoWidth, setLogoWidth] = useState(40);
	const [logoHeight, setLogoHeight] = useState(40);

	const history = useHistory();

	const saveSettingPressHandler = () => {
		const newSettings: MSettings = new MSettings({
			...settings,
			logoURL,
			host,
		});
		onChangeSettings(newSettings);
	};

	useEffect(() => {
		onChangeHost(settings.host);
	}, [settings.host]);

	useEffect(() => {
		Image.getSize(
			settings.logoURL,
			(width, height) => {
				setLogoWidth(200);
				setLogoHeight((height * 200) / width);
			},
			() => {
				// log error
			},
		);
	}, [settings.logoURL]);

	const B1 = useMemo(() => {
		return () => (
			<MundoPadButton
				icon="box"
				cyId={'settings.articles.button'}
				onPress={() => history.push('/articles')}
				title={messages.articles}
			/>
		);
	}, []);

	const B2 = useMemo(() => {
		return () => (
			<MundoPadButton
				icon="users"
				cyId={'settings.users.button'}
				onPress={() => history.push('/users')}
				title={messages.users}
			/>
		);
	}, []);

	const B3 = useMemo(() => {
		return () => (
			<MundoPadButton
				icon="groups"
				cyId={'settings.groups.button'}
				onPress={() => history.push('/groups')}
				title={messages.groups}
			/>
		);
	}, []);

	const B4 = useMemo(() => {
		return () => (
			<MundoPadButton
				icon="boxes"
				cyId={'settings.packages.button'}
				title={messages.packages}
				onPress={() => history.push('/packages')}
			/>
		);
	}, []);

	const B5 = useMemo(() => {
		return () => (
			<MundoPadButton
				icon="card"
				cyId={'settings.payment.button'}
				title={messages.paymentMethods}
				onPress={() => history.push('/paymentMethods')}
			/>
		);
	}, []);

	const B6 = useMemo(() => {
		return () => (
			<MundoPadButton
				icon="contract"
				cyId={'settings.orders.button'}
				title={messages.orders}
				onPress={() => history.push('/edit/orders')}
			/>
		);
	}, []);

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/settings?id=settings')
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={[styles.spacedContainer, styles.elevated]}>
					<MundoText
						styles={styles.headerSecondaryText}
						message={messages.generalSettings}
					/>

					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							styles.topMargin20,
						]}
					>
						<Image
							source={{ uri: logoURL }}
							style={
								logoURL !== ''
									? [
											settingStyles(theme).logoImgBorder,
											{
												width: logoWidth,
												height: logoHeight,
											},
									  ]
									: settingStyles(theme).previewImg
							}
						/>
						<View
							style={[
								styles.settingsContainerSmall,
								styles.leftMargin30,
							]}
						>
							<MundoText
								message={messages.changePhoto}
								styles={styles.bottomMargin10}
							/>
							<ImageSelector
								img={logoURL}
								style={styles.bottomMargin20}
								onChangeImg={(d) => onChangeLogoURL(d)}
							/>
							<View
								style={[
									styles.bottomMargin10,
									styles.containerFullWidthHorizontalNoPadding,
									styles.elevated,
								]}
							>
								<View>
									<MundoText message={messages.host} />
									<MundoObjectTypeahead
										cyId={'settings.host'}
										QUERY={GET_CUSTOMERS}
										onChange={(value) =>
											value
												? onChangeHost(
														value as MCustomer,
												  )
												: undefined
										}
										value={host}
									/>
								</View>
								<View style={{ zIndex: -1 }}>
									<MundoButton
										dataSet={{ cy: 'settings.save.button' }}
										styles={[
											{
												height: 40,
												marginTop: 21,
											},
										]}
										onPress={() =>
											saveSettingPressHandler()
										}
										title={messages.save}
									/>
								</View>
							</View>
							<View
								style={[
									styles.bottomMargin10,
									styles.containerFullWidthHorizontalNoPadding,
								]}
							>
								<View>
									<MundoText message={messages.hostMail} />
									<MundoText
										styles={styles.input}
										message={settings.mail}
									/>
								</View>
								<View style={{ zIndex: -1 }}>
									<MundoButton
										dataSet={{
											cy: 'settings.mailedit.button',
										}}
										styles={{ height: 40, marginTop: 21 }}
										onPress={() =>
											history.push('/edit/mail')
										}
										title={messages.edit}
									/>
								</View>
							</View>
						</View>
						<MediaQuery minWidth={1121}>
							<View
								style={[
									styles.containerFullWidthNoPadding,
									{ zIndex: -1 },
								]}
							>
								<View
									style={
										styles.containerFullWidthHorizontalNoPadding
									}
								>
									<B1 />
									<B2 />
									<B3 />
								</View>
								<View
									style={
										styles.containerFullWidthHorizontalNoPadding
									}
								>
									<B4 />
									<B5 />
									<B6 />
								</View>
							</View>
						</MediaQuery>
						<MediaQuery minWidth={1020} maxWidth={1120}>
							<View
								style={[
									styles.containerFullWidthNoPadding,
									{ zIndex: -1 },
								]}
							>
								<View
									style={
										styles.containerFullWidthHorizontalNoPadding
									}
								>
									<B1 />
									<B2 />
								</View>
								<View
									style={
										styles.containerFullWidthHorizontalNoPadding
									}
								>
									<B3 />
									<B4 />
								</View>
								<View
									style={
										styles.containerFullWidthHorizontalNoPadding
									}
								>
									<B5 />
									<B6 />
								</View>
							</View>
						</MediaQuery>
					</View>
					<MediaQuery minWidth={0} maxWidth={1019}>
						<View
							style={[
								styles.containerFullWidthNoPadding,
								{ zIndex: -1 },
							]}
						>
							<View
								style={
									styles.containerFullWidthHorizontalNoPadding
								}
							>
								<B1 />
								<B2 />
								<B3 />
							</View>
							<View
								style={
									styles.containerFullWidthHorizontalNoPadding
								}
							>
								<B4 />
								<B5 />
								<B6 />
							</View>
						</View>
					</MediaQuery>
				</View>

				<View style={styles.spacedContainer}>
					{/* Pipelines */}
					<View style={settingStyles(theme).pipelineListContainer}>
						<View style={styles.headerTitleContainer}>
							<MundoText
								styles={styles.headerSecondaryText}
								message={messages.pipelines}
							/>
							<MundoButton
								icon={'question'}
								subtype="transparent"
								onPress={() =>
									openDocs('#/content/settings?id=pipelines')
								}
							/>
							<View style={styles.alignRightInHorizontalFlex}>
								<MundoButton
									dataSet={{
										cy: 'settings.createPipeline.button',
									}}
									styles={settingStyles(theme).addButton}
									icon={'plus'}
									onPress={() =>
										onChangeSettings({
											...settings,
											pipelines: [
												...settings.pipelines,
												new MPipeline(),
											],
										})
									}
								/>
							</View>
						</View>

						{settings.pipelines.map((pipeline, index) => (
							<View key={`${pipeline.title}${pipeline._id}`}>
								<Pipeline pipeline={pipeline} index={index} />
							</View>
						))}
					</View>
				</View>
				<View style={styles.spacedContainer}>
					<PermissionGrid />
				</View>
				<View style={styles.spacedContainer}>
					<ArchivConfig />
				</View>
				<View style={styles.spacedContainer}>
					<InteractionConfig />
				</View>
				<View style={styles.spacedContainer}>
					<CountryConfig />
				</View>
				{Object.keys(EPluign).map((plugin) => {
					const Extension = getExtension(
						'Settings',
						plugin as EPluign,
					);

					if (Extension) {
						return <Extension key={plugin} />;
					}
				})}
			</ScrollView>
		</View>
	);
};

export default SettingsScreen;
