import { defineMessages } from 'react-intl';

export const scope = 'mango.module.receipt';

export default defineMessages({
	save: {
		id: `${scope}.save`,
		defaultMessage: 'save',
	},
	record: {
		id: `${scope}.record`,
		defaultMessage: 'record',
	},
	selectItems: {
		id: `${scope}.selectItems`,
		defaultMessage:
			'select items to record them. (booked items should be highlighted)',
	},
});
