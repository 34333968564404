import { defineMessages } from 'react-intl';

export const scope = 'mango.container.Home';

export default defineMessages({
	login: {
		id: `${scope}.auth.login`,
		defaultMessage: 'login',
	},
	register: {
		id: `${scope}.auth.register`,
		defaultMessage: 'register',
	},
	forgot: {
		id: `${scope}.auth.forgot`,
		defaultMessage: 'forgot password',
	},
	welcomeText: {
		id: `${scope}.welcome.text`,
		defaultMessage: 'welcome to mango',
	},
	de: {
		id: `de`,
		defaultMessage: 'de',
	},
	en: {
		id: `en`,
		defaultMessage: 'en',
	},
	server: {
		id: `${scope}.server`,
		defaultMessage: 'server version',
	},
});
