import { defineMessages } from 'react-intl';

export const scope = 'mango.components.ArticleEdit';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'edit article',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	short: {
		id: `${scope}.short`,
		defaultMessage: 'short',
	},
	description: {
		id: `${scope}.description`,
		defaultMessage: 'description',
	},
	price: {
		id: `${scope}.price`,
		defaultMessage: 'price',
	},
	tax: {
		id: `${scope}.tax`,
		defaultMessage: 'tax',
	},
	unit: {
		id: `${scope}.unit`,
		defaultMessage: 'unit',
	},
	subArticles: {
		id: `${scope}.subArticles`,
		defaultMessage: 'sub-articles',
	},
	essantials: {
		id: `${scope}.essentials`,
		defaultMessage: 'essentials',
	},
	mandatory: {
		id: `${scope}.mandatory`,
		defaultMessage: 'mandatory',
	},
	type: {
		id: `${scope}.type`,
		defaultMessage: 'type',
	},
	purpose: {
		id: `${scope}.purpose`,
		defaultMessage: 'purpose',
	},
	cost: {
		id: `${scope}.cost`,
		defaultMessage: 'cost',
	},
	selected: {
		id: `${scope}.selected`,
		defaultMessage: 'selected subarticles',
	},
	loadMore: {
		id: `${scope}.loadMore`,
		defaultMessage: 'load more',
	},
	unitError: {
		id: `${scope}.unitError`,
		defaultMessage: 'the unit is in the wrong format!',
	},
	amount: {
		id: `${scope}.amount`,
		defaultMessage: 'amount',
	},
	data: {
		id: `${scope}.data`,
		defaultMessage: 'data',
	},
	comment: {
		id: `${scope}.comment`,
		defaultMessage: 'comment',
	},
	intervals: {
		id: `${scope}.intervals`,
		defaultMessage: 'intervals',
	},
	dataUnit: {
		id: `${scope}.dataUnit`,
		defaultMessage: 'data unit',
	},
});
