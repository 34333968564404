import React, { FC, memo, useContext } from 'react';
import { View } from 'react-native';

import { getTranslation } from 'mango-utils-client';
import { MundoText, MundoButton } from '../../../../components/elements';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle } from '../../../../utilities/hooks/styles';
import { IPageArticle } from './props';
import { ETypes } from '../../../../utilities/reducer/array.reducer';

enum ESwapDirection {
	UP,
	DOWN,
}

export const PageArticle: FC<IPageArticle> = memo((props) => {
	const { pages, index, siteIndex, article, dispatchPageAction } = props;

	const styles = useStyle();
	const { language } = useContext(LanguageContext);

	const swap = (
		position: number,
		pIndex: number,
		direction: ESwapDirection,
	) => {
		const p = pages[pIndex];
		const neighbour =
			direction === ESwapDirection.DOWN ? position + 1 : position - 1;
		if (neighbour >= 0 && neighbour < p.articles.length) {
			// Swap
			p.articles[position] = p.articles.splice(
				neighbour,
				1,
				p.articles[position],
			)[0];
		}
		// Apply
		dispatchPageAction({
			type: ETypes.EDIT,
			location: siteIndex,
			item: p,
		});
	};

	const remove = (position: number, pIndex: number) => {
		if (position >= 0) {
			const p = pages[pIndex];
			p.articles.splice(position, 1);
			dispatchPageAction({
				type: ETypes.EDIT,
				location: siteIndex,
				item: p,
			});
		}
	};

	return (
		<View
			style={[
				styles.horizontalLayout,
				styles.fullWidth,
				{
					zIndex: pages[siteIndex].articles.length - index + 100,
				},
			]}
		>
			<View style={[styles.padding, { width: 40 }]}>
				<MundoText message={article.short} maxLength={4} />
			</View>
			<View style={{ flex: 1, flexWrap: 'wrap' }}>
				<MundoText message={getTranslation(article.title, language)} />
			</View>
			<View style={[styles.horizontalLayout, { zIndex: -1 }]}>
				<MundoButton
					icon={'up'}
					onPress={() => {
						swap(index, siteIndex, ESwapDirection.UP);
					}}
				/>
				<MundoButton
					icon={'down'}
					onPress={() => {
						swap(index, siteIndex, ESwapDirection.DOWN);
					}}
				/>
				<MundoButton
					icon={'minus'}
					onPress={() => {
						remove(index, siteIndex);
					}}
				/>
			</View>
		</View>
	);
});
