import { Notifier } from '@airbrake/browser';
import { Component, ReactChildren } from 'react';

export class ErrorBoundary extends Component<unknown, { hasError: boolean }> {
	public airbrake: Notifier;

	constructor(props: { children: ReactChildren }) {
		super(props);
		this.state = { hasError: false };
		this.airbrake = new Notifier({
			projectId: 299962,
			projectKey: 'c040c6fa1c79f39928b9ad906b644775',
			performanceStats: true,
		});
	}

	public componentDidCatch(error: any, info: any) {
		// Display fallback UI
		this.setState({ hasError: true });
		// Send error to Airbrake
		this.airbrake
			.notify({
				error,
				params: { info },
			})
			.catch((e) => console.error(e));
	}

	public render() {
		// if (this.state.hasError) {
		// 	// You can render any custom fallback UI
		// 	return <h1>Something went wrong.</h1>;
		// }
		return this.props.children;
	}
}
