import React, { FC, useContext } from 'react';
import { View } from 'react-native';

import {
	getTranslation,
	MTranslation,
	priceFormatter,
} from 'mango-utils-client';
import { MundoText } from '../../../../components/elements';
import MundoTable from '../../../../components/elements/MundoTable';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle } from '../../../../utilities/hooks/styles';
import messages from '../../container/Profit/messages';
import { IDataTableProps } from './props';

export const DataTable: FC<IDataTableProps> = ({
	formatTick,
	data,
	selectedEntrys,
}) => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);

	return (
		<View style={styles.spacedContainer}>
			<MundoTable
				entrys={[
					{ identifier: 'date', header: messages.date, size: 25 },
					{
						identifier: 'netSumYLabel',
						header: messages.netSumYLabel,
						size: 25,
					},
					...data.byRef
						.map((customerEntrys) => {
							const c = selectedEntrys.find(
								(entry) => entry._id === customerEntrys.ref._id,
							);
							if (c) {
								let header: string | MTranslation[] =
									(c as any).title || c.id;
								if (Array.isArray(header)) {
									header = getTranslation(header, language);
								}
								return [
									{
										identifier: customerEntrys.ref._id,
										header,
									},
									{
										identifier:
											customerEntrys.ref._id + 'share',
										header: messages.share,
									},
								];
							}
							return [];
						})
						.reduce((p, c) => [...p, ...c], []),
					{ identifier: 'arrow', size: 4 },
				]}
				rows={data.general.map((entry, index) => {
					const addition = data.byRef
						.map((customerEntrys) => {
							return [
								{
									identifier: customerEntrys.ref._id,
									content: (
										<MundoText>
											{priceFormatter(
												customerEntrys.data[index].y,
											)}{' '}
											€
										</MundoText>
									),
								},
								{
									identifier:
										customerEntrys.ref._id + 'share',
									content: (
										<MundoText>
											{Math.round(
												(customerEntrys.data[index].y /
													entry.y) *
													1000,
											) / 10 || 0}{' '}
											%
										</MundoText>
									),
								},
							];
						})
						.reduce((p, c) => [...p, ...c], []);
					return {
						key: `${entry.x}`,
						values: [
							{
								identifier: 'date',
								content: (
									<MundoText>
										{formatTick(entry)}{' '}
										{formatTick(entry, true)}
									</MundoText>
								),
							},
							{
								identifier: 'netSumYLabel',
								content: (
									<MundoText>
										{priceFormatter(entry.y)} €
									</MundoText>
								),
							},
							...addition,
						],
					};
				})}
				// onChangeSort={changeSort}
				// orderBy={orderBy}
				// sortMode={mode}
				// loadMore={loadMore}
				// loading={loading}
				// loadMoreActive={loadMoreActive}
			/>
		</View>
	);
};
