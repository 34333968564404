import { useLazyQuery } from '@apollo/client';
import { MExport } from 'mango-utils-client';
import { useEffect, useState } from 'react';
import { EXPORT_PREVIEW } from './queries';

export const usePreview = () => {
	const [preview, updateLocalPreview] = useState<MExport[]>([] as MExport[]);
	const [fromDate, onChangeFrom] = useState<number>(Date.now() - 2628000000);
	const [toDate, onChangeTo] = useState<number>(Date.now());

	const [lazyQuery, { loading }] = useLazyQuery(EXPORT_PREVIEW, {
		onCompleted: (data) => {
			if (data) {
				const response = data.navExportPreview;
				updateLocalPreview(response);
			}
		},
	});

	useEffect(() => {
		lazyQuery({
			variables: {
				from: fromDate,
				to: toDate,
			},
		});
	}, [lazyQuery, fromDate, toDate]);

	return { onChangeFrom, onChangeTo, loading, fromDate, toDate, preview };
};
