import React, { FC, useContext, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MPipeline } from 'mango-utils-client';
import { SidebarContext } from '../../../utilities/contexts/Sidebar';
import { AuthContext } from '../../../utilities/hooks/auth';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory } from '../../../utilities/routing';
import MundoButton from '../../elements/MundoButton';
import messages from '../messages';
import { IContentProps } from '../props';
import sidebarStyles from '../styles';
import { Pipeline } from './Pipeline';
import DataMenu from './DataMenu';
import { LanguageContext } from '../../../utilities/contexts/Language';

export const Content: FC<IContentProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { settings } = useContext(SettingsContext);
	const { logOut } = useContext(AuthContext);
	const { language } = useContext(LanguageContext);
	const [showDataMenu, onChangeShowDataMenu] = useState<boolean>(false);
	const {
		currentPipeline,
		isExpanded,
		onChangeExpanded,
		onChangeCurrentPipeline,
	} = useContext(SidebarContext);
	const history = useHistory();

	useEffect(() => {
		if (!isExpanded && showDataMenu) {
			onChangeShowDataMenu(isExpanded);
		}
	}, [isExpanded, showDataMenu]);
	const navigate = (path: string) => {
		history.push(path);
		if (props.closeSidebar) {
			props.closeSidebar();
		}
	};
	if (showDataMenu) {
		return (
			<DataMenu {...props} onChangeShowDataMenu={onChangeShowDataMenu} />
		);
	}
	return (
		<View
			style={{
				flex: 1,
				display: 'flex',
				justifyContent: 'space-between',
				width: '100%',
			}}
		>
			<MundoButton
				dataSet={{ cy: 'home.button' }}
				onPress={() => {
					navigate(`/`);
					onChangeCurrentPipeline('');
				}}
				title={props.detail ? messages.home : undefined}
				icon={'home'}
				styles={[
					sidebarStyles(theme).navButton,
					props.detail && sidebarStyles(theme).exNavButton,
				]}
			/>
			<View style={styles.thinSeparator} />
			<View style={{ flex: 1 }}>
				<ScrollView>
					{currentPipeline ? (
						<Pipeline
							detail={props.detail}
							closeSidebar={props.closeSidebar}
							pipeline={
								settings.pipelines.find(
									(pipe) => pipe._id === currentPipeline,
								) || new MPipeline()
							}
						/>
					) : (
						settings.pipelines.map((pipeline: MPipeline) => (
							<Pipeline
								key={pipeline._id}
								detail={props.detail}
								closeSidebar={props.closeSidebar}
								pipeline={pipeline}
							/>
						))
					)}
				</ScrollView>
			</View>
			<View style={styles.thinSeparator} />
			<View>
				<MundoButton
					dataSet={{ cy: 'sidebar.showData' }}
					onPress={() => {
						onChangeShowDataMenu(true);
						onChangeExpanded(true);
					}}
					title={props.detail ? messages.dataManagement : undefined}
					icon={'more'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<MundoButton
					onPress={() => navigate(`/orders`)}
					title={props.detail ? messages.orders : undefined}
					icon={'menu'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<MundoButton
					onPress={() => openDocs()}
					title={props.detail ? messages.documentation : undefined}
					icon={'question'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<MundoButton
					onPress={() => navigate(`/profile`)}
					title={props.detail ? messages.profile : undefined}
					icon={'profile'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
				<MundoButton
					onPress={() => navigate('/language')}
					title={props.detail ? messages.language : undefined}
					icon={language}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
					coloredIcon
				/>
				<MundoButton
					dataSet={{ cy: 'logout' }}
					onPress={() => logOut()}
					title={props.detail ? messages.logOut : undefined}
					icon={'out'}
					styles={[
						sidebarStyles(theme).navButton,
						props.detail && sidebarStyles(theme).exNavButton,
					]}
				/>
			</View>
		</View>
	);
};
