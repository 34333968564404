import { createContext, ReactNode } from 'react';

import { alert } from '../../alert';

export const PopUpContext = createContext({
	onChangePopUp: (newPopUp?: ReactNode) =>
		alert(
			'onChangePopup is undefined',
			JSON.stringify(newPopUp),
			'warning',
		),
	open: () => alert('open is undefined', '', 'warning'),
	close: () => alert('close is undefined', '', 'warning'),
});
