import { defineMessages } from 'react-intl';

export const scope = 'mango.components.BundledArticleView';

export default defineMessages({
	article: {
		id: `${scope}.article`,
		defaultMessage: 'article',
	},
	amount: {
		id: `${scope}.amount`,
		defaultMessage: 'amount',
	},
	lowerAmount: {
		id: `${scope}.lowerAmount`,
		defaultMessage: 'lower amount',
	},
	useFixedAmount: {
		id: `${scope}.useFixedAmount`,
		defaultMessage: 'use fixed amount',
	},
	usedAmount: {
		id: `${scope}.usedAmount`,
		defaultMessage: 'used amount',
	},
	upperAmount: {
		id: `${scope}.upperAmount`,
		defaultMessage: 'upper amount',
	},
	underflowPrice: {
		id: `${scope}.underflowPrice`,
		defaultMessage: 'underflow price',
	},
	overflowPrice: {
		id: `${scope}.overflowPrice`,
		defaultMessage: 'overflow price',
	},
	usedUnderflowPrice: {
		id: `${scope}.usedUnderflowPrice`,
		defaultMessage: 'used underflow price',
	},
	usedOverflowPrice: {
		id: `${scope}.usedOverflowPrice`,
		defaultMessage: 'used overflow price',
	},
	close: {
		id: `${scope}.close`,
		defaultMessage: 'close',
	},
	empty: {
		id: `${scope}.empty`,
		defaultMessage: 'empty',
	},
	yes: {
		id: `${scope}.yes`,
		defaultMessage: 'yes',
	},
	no: {
		id: `${scope}.no`,
		defaultMessage: 'no',
	},
});
