import { useCallback, useEffect, useState } from 'react';
import { ESortMode } from '../../enums/ESortMode';
import useDebounce from '../debounce';

export const useSort = () => {
	const [sort, onChangeSort] = useState({
		mode: ESortMode.NOSORT,
		column: '',
		orderBy: '',
	});
	const [temp, onChangeTemp] = useState({
		mode: ESortMode.NOSORT,
		column: '',
		orderBy: '',
	});

	const [last, onChangeLast] = useState<ESortMode>();

	const debouncedSortMode = useDebounce<any>(temp, 150);

	const loadCB = useCallback((next) => onChangeSort(next), [onChangeSort]);

	useEffect(() => {
		if (debouncedSortMode && debouncedSortMode !== last) {
			onChangeLast(debouncedSortMode);
			loadCB(debouncedSortMode);
		}
	}, [debouncedSortMode, loadCB, last]);

	const changeSort = (column: string) => {
		if (column === temp.column) {
			switch (temp.mode) {
				case ESortMode.ASC:
					onChangeTemp({
						mode: ESortMode.DESC,
						column,
						orderBy: `-${column}`,
					});
					break;
				case ESortMode.DESC:
					onChangeTemp({
						mode: ESortMode.NOSORT,
						column,
						orderBy: '',
					});
					break;
				case ESortMode.NOSORT:
					onChangeTemp({
						mode: ESortMode.ASC,
						column,
						orderBy: column,
					});
					break;
			}
		} else {
			onChangeTemp({ mode: ESortMode.ASC, column, orderBy: column });
		}
	};

	return { changeSort, ...sort };
};
