import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const checkboxStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		check: {
			width: 24,
			height: 24,
			borderRadius: 5,
			padding: 0,
			marginTop: 5,
			marginHorizontal: 0,
			marginBottom: 0,
			alignItems: 'center',
			alignContent: 'center',
			backgroundColor: 'transparent',
			borderColor: theme.backgroundTertiaryColor,
		},
		checkboxLabel: {
			marginLeft: 4,
			marginTop: 8,
		},
	});

export default checkboxStyles;
