import { ETOrderListSettings } from '../../enums';
import messages from '../messages';

export default function selectTitle(field: string) {
	switch (field) {
		case ETOrderListSettings.DRIVER:
			return messages.driver;
		case ETOrderListSettings.TRAILER:
			return messages.trailer;
		case ETOrderListSettings.TRUCK:
			return messages.truck;
		default:
			return { id: field, defaultMessage: field };
	}
}
