import React, { FC, useState } from 'react';
import { View } from 'react-native';

import { MPackage } from 'mango-utils-client';
import { MundoButton } from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { useStyle } from '../../../../utilities/hooks/styles';
import messages from '../messages';
import { IPackageSelector } from '../props';
import { GET_PACKAGES } from '../queries';

const PackageSelector: FC<IPackageSelector> = (props) => {
	const styles = useStyle();
	const [next, onChangeNext] = useState<MPackage>();

	return (
		<View style={styles.horizontalLayout}>
			<MundoObjectTypeahead
				cyId={'package.input'}
				value={next}
				onChange={(newPackageMethod) => {
					if (newPackageMethod) {
						onChangeNext(newPackageMethod as MPackage);
					}
				}}
				QUERY={GET_PACKAGES}
				multilanguage
			/>
			<MundoButton
				dataSet={{ cy: 'addPackage' }}
				title={messages.addPackage}
				onPress={() => {
					if (next) {
						props.onNewPack(next);
					}
				}}
				disabled={!next}
			/>
		</View>
	);
};

export default PackageSelector;
