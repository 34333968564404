import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.EquipmentManagerDashboard';

export default defineMessages({
	error: {
		id: `${scope}.error`,
		defaultMessage: 'ERROR: no matching definition',
	},
	companies: {
		id: `${scope}.companies`,
		defaultMessage: 'companies',
	},
	truck: {
		id: `${scope}.truck`,
		defaultMessage: 'trucks',
	},
	trailer: {
		id: `${scope}.trailer`,
		defaultMessage: 'trailers',
	},
	driver: {
		id: `${scope}.driver`,
		defaultMessage: 'drivers',
	},
	description: {
		id: `${scope}.description`,
		defaultMessage:
			'you can create orders in this module by clicking the "+" button in the top right corner, or view and manage the equipment data by clicking on one of the tabs',
	},
	createNewOrder: {
		id: `${scope}.createNewOrder`,
		defaultMessage: 'create new order',
	},
});
