import {
	dateFormatter,
	EInvoice,
	MInvoice,
	priceFormatter,
} from 'mango-utils-client';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { ScrollView, View } from 'react-native';

import { MundoButton, MundoText } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useInvoices } from '../../../utilities/hooks/invoice';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';

export const Credits: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { isAdmin } = useContext(AuthContext);

	const { orderBy, mode, changeSort } = useSort();
	const {
		invoices,
		loading,
		loadMore,
		loadMoreActive,
		onChangeFilter,
	} = useInvoices(
		useMemo(() => {
			return { orderBy };
		}, [orderBy]),
	);

	useEffect(() => {
		onChangeFilter(new MInvoice({ type: EInvoice.CREDIT }));
	}, [onChangeFilter]);

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.header}
					/>

					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => console.log('TODO')}
					/>
				</View>
				{isAdmin && (
					<MundoButton
						dataSet={{ cy: 'create' }}
						title={messages.create}
						icon={'plus'}
						onPress={() => history.push(`/credit`)}
					/>
				)}
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoTable
						entrys={[
							{ header: messages.id, size: 10, identifier: 'id' },
							{
								header: messages.customer,
								size: 25,
								identifier: 'customer',
							},
							{
								header: messages.netSum,
								size: 15,
								identifier: 'netSum',
							},
							{
								header: messages.grossSum,
								size: 15,
								identifier: 'grossSum',
							},
							{
								header: messages.taxSum,
								size: 20,
								identifier: 'taxSum',
							},
							{
								header: messages.issueDate,
								size: 25,
								identifier: 'issueDate',
							},
						]}
						rows={invoices.map((invoice) => {
							return {
								onPress: () =>
									history.push(`pdf/credit/${invoice._id}`),
								key: invoice._id,
								values: [
									{
										identifier: 'id',
										content: (
											<MundoText>{invoice.id}</MundoText>
										),
									},
									{
										identifier: 'customer',
										content: (
											<MundoText>
												{invoice.customer.title}
											</MundoText>
										),
									},
									{
										identifier: 'netSum',
										content: (
											<MundoText>
												{priceFormatter(
													invoice.netSum / 1000,
												)}
											</MundoText>
										),
									},
									{
										identifier: 'grossSum',
										content: (
											<MundoText>
												{priceFormatter(
													invoice.grossSum / 1000,
												)}
											</MundoText>
										),
									},
									{
										identifier: 'taxSum',
										content: (
											<MundoText>
												{priceFormatter(
													invoice.taxSum / 1000,
												)}
											</MundoText>
										),
									},
									{
										identifier: 'issueDate',
										content: (
											<MundoText>
												{dateFormatter(
													new Date(invoice.issueDate),
												)}
											</MundoText>
										),
									},
								],
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};
