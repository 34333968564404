import { ELanguages } from 'mango-utils-client';
import React, { FC, useContext } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHistory } from '../../utilities/routing';
import { MundoIcon, MundoText } from '../../components/elements';
import { useStyle } from '../../utilities/hooks/styles';
import messages from './messages';
import languageMessages from '../../messages';
import { LanguageContext } from '../../utilities/contexts/Language';

export const Language: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { language, onChangeLanguage } = useContext(LanguageContext);
	return (
		<View style={styles.fullSize}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
				</View>
			</View>
			<View style={styles.spacedContainer}>
				{Object.values(ELanguages)
					.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
					.map((lang) => (
						<TouchableOpacity
							key={lang}
							style={[
								styles.horizontalLayout,
								styles.paddedView,
								language === lang && styles.highlight,
							]}
							onPress={() => {
								onChangeLanguage(lang);
								history.push('/');
							}}
						>
							<MundoIcon icon={lang} width={40} />
							<MundoText
								message={languageMessages[lang]}
								styles={styles.paddedView}
							/>
						</TouchableOpacity>
					))}
			</View>
		</View>
	);
};
