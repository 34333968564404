import arrow from './icons/arrow.svg';
import order from './icons/order.png';
import receipt from './icons/receipt.svg';
import record from './icons/record.svg';
import invoice from './icons/invoice.svg';
import invoices from './icons/invoices.svg';
import edit from './icons/require.svg';
import plus from './icons/plus.svg';
import check from './icons/check.svg';
import left from './icons/left.svg';
import right from './icons/right.svg';
import minus from './icons/minus.svg';
import menu from './icons/menu.svg';
import out from './icons/out.svg';
import home from './icons/home.svg';
import settings from './icons/settings.svg';
import send from './icons/send.svg';
import metaData from './icons/metaData.svg';
import equipmentManager from './icons/equipmentManager.svg';
import profile from './icons/profile.svg';
import logoSmall from './icons/logo.svg';
import sign from './icons/sign.svg';
import up from './icons/up.svg';
import down from './icons/down.svg';
import question from './icons/question.svg';
import e from './icons/export.svg';
import card from './icons/card.svg';
import box from './icons/box.svg';
import boxes from './icons/boxes.svg';
import users from './icons/user.svg';
import groups from './icons/users.svg';
import contract from './icons/contract.svg';
import close from './icons/close.svg';
import cardCustomer from './icons/cardCustomer.svg';
import cardDriver from './icons/cardDriver.svg';
import cardTrailer from './icons/cardTrailer.svg';
import cardTruck from './icons/cardTruck.svg';
import cardCreditCard from './icons/cardCreditCard.svg';
import tableSortDown from './icons/tablesort_down.svg';
import tableSortUp from './icons/tablesort_up.svg';
import upload from './icons/upload.svg';
import more from './icons/more.svg';
import download from './icons/download.svg';
// import save from './icons/save.svg';
import save2 from './icons/save2.svg';
import analytics from './icons/analytics.svg';
import products from './icons/products.svg';
import de from './flags/de.svg';
import gb from './flags/gb.svg';
import ru from './flags/ru.svg';
import pl from './flags/pl.svg';
import ro from './flags/ro.svg';
import sk from './flags/sk.svg';
import fr from './flags/fr.svg';
import nl from './flags/nl.svg';
import it from './flags/it.svg';
import es from './flags/es.svg';
import bg from './flags/bg.svg';
import lv from './flags/lv.svg';
import si from './flags/si.svg';
import cz from './flags/cz.svg';
import hu from './flags/hu.svg';

const icons = {
	arrow,
	order,
	receipt,
	record,
	invoice,
	invoices,
	edit,
	plus,
	check,
	left,
	right,
	minus,
	menu,
	out,
	home,
	settings,
	send,
	metaData,
	equipmentManager,
	profile,
	logoSmall,
	sign,
	up,
	down,
	question,
	export: e,
	card,
	box,
	boxes,
	users,
	groups,
	contract,
	close,
	cardCustomer,
	cardDriver,
	cardTrailer,
	cardTruck,
	cardCreditCard,
	tableSortDown,
	tableSortUp,
	upload,
	more,
	download,
	save2,
	analytics,
	products,
	// country flags
	de,
	gb,
	ru,
	pl,
	ro,
	sk,
	fr,
	nl,
	it,
	es,
	bg,
	lv,
	si,
	cz,
	hu,
	// additional flags for languages
	en: gb,
	sl: si,
	cs: cz,
};

export default icons;
