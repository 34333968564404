import React, { FC, memo, useContext } from 'react';
import { Text, View } from 'react-native';
import { LanguageContext } from '../../utilities/contexts/Language';

import { useStyle } from '../../utilities/hooks/styles';
import { getTranslation } from '../../utilities/translations';
import { MundoButton } from '../elements';
import { IPackageOverviewProps } from './props';

const PackageOverview: FC<IPackageOverviewProps> = memo((props) => {
	// const { id } = useParams();
	// const intl = useIntl();
	const styles = useStyle();
	const { language } = useContext(LanguageContext);
	const { pack, removeFunction, canRemove } = props;
	return (
		<View>
			<View style={styles.containerFullWidthHorizontal}>
				<View
					key={`selectedPackage${pack.id}`}
					style={{ maxWidth: '99%' }}
				>
					<Text style={[styles.boldText, styles.bottomMargin20]}>
						{getTranslation(pack.title, language)}
					</Text>
					{pack.includedArticles &&
						pack.includedArticles.map(
							(bundleArt) =>
								bundleArt.articles.length && (
									<View key={bundleArt.articles[0]._id}>
										<Text>
											{getTranslation(
												bundleArt.articles[0].title,
												language,
											)}
										</Text>
									</View>
								),
						)}
				</View>

				{canRemove && removeFunction && (
					<MundoButton
						styles={[
							styles.formButtonHeight,
							{ position: 'absolute', right: 0 },
						]}
						icon={'minus'}
						onPress={() => {
							removeFunction();
						}}
					/>
				)}
			</View>
		</View>
	);
});

export default PackageOverview;
