import React, { createContext, FC, useEffect, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { MCustomer, MTDriver, MTTrailer, MTTruck } from 'mango-utils-client';
import { MundoIcon } from '../../../../components/elements';
import { useArray } from '../../../../utilities/hooks/array';
import { useStyle } from '../../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import CustomerFilterSection from '../../components/CustomerFilterSection';
import DriverFilterSection from '../DriverFilterSection';
import TrailerFilterSection from '../TrailerFilterSection';
import { TruckFilterSection } from '../TruckFilterSection/TruckFilterSection';
import { ISFilterSectionProps } from './props';

export const SFilterSectionContext = createContext({
	useGlobal: true,
	global: false,
	onChangeGlobal: (b: boolean) => console.log('onChangeGlobal undefined', b),
	globalFiltered: false,
	onChangeGlobalFiltered: (b: boolean) =>
		console.log('onChangeGlobalFiltered undefined', b),
});

const tabs = ['customer', 'trailer', 'truck', 'driver'];
export const FilterSection: FC<ISFilterSectionProps> = ({
	global,
	onChangeGlobal,
	globalFiltered,
	onChangeGlobalFiltered,
	selectedTab,
	onChangeSelectedTab,
	onChangeEntityColors,
	onChangeSelectedEntities,
}) => {
	const styles = useStyle();

	const [
		selectedCustomers,
		dispatchSelectedCustomerAction,
	] = useArray<MCustomer>();
	const [customerColors, dispatchCustomerColorAction] = useArray<string>();

	const [
		selectedTrailers,
		dispatchSelectedTrailerAction,
	] = useArray<MTTrailer>();
	const [trailerColors, dispatchTrailerColorAction] = useArray<string>();

	const [selectedTrucks, dispatchSelectedTruckAction] = useArray<MTTruck>();
	const [truckColors, dispatchTruckColorAction] = useArray<string>();

	const [
		selectedDrivers,
		dispatchSelectedDriverAction,
	] = useArray<MTDriver>();
	const [driverColors, dispatchDriverColorAction] = useArray<string>();
	/**
	 * effect to aplly selection to entities in parent
	 */
	useEffect(() => {
		switch (selectedTab) {
			case tabs[0]:
				onChangeEntityColors(customerColors);
				onChangeSelectedEntities(selectedCustomers);
				return;
			case tabs[1]:
				if (selectedTrailers) {
					onChangeEntityColors(trailerColors);
					onChangeSelectedEntities(selectedTrailers);
					return;
				}
				break;
			case tabs[2]:
				if (selectedTrucks) {
					onChangeEntityColors(truckColors);
					onChangeSelectedEntities(selectedTrucks);
					return;
				}
				break;
			case tabs[3]:
				if (selectedDrivers) {
					onChangeEntityColors(driverColors);
					onChangeSelectedEntities(selectedDrivers);
					return;
				}
				break;
		}
		onChangeEntityColors(customerColors);
		onChangeSelectedEntities(selectedCustomers);
	}, [
		onChangeEntityColors,
		onChangeSelectedEntities,
		selectedTab,
		selectedCustomers,
		customerColors,
		selectedDrivers,
		driverColors,
		selectedTrailers,
		trailerColors,
		selectedTrucks,
		truckColors,
	]);
	/**
	 * filter which filter section to display
	 */
	const filterSection = useMemo(() => {
		switch (selectedTab) {
			case tabs[0]:
				return (
					<CustomerFilterSection
						selectedCustomers={selectedCustomers}
						dispatchSelectedCustomerAction={
							dispatchSelectedCustomerAction
						}
						customerColors={customerColors}
						dispatchCustomerColorAction={
							dispatchCustomerColorAction
						}
					/>
				);
			case tabs[1]:
				return (
					<TrailerFilterSection
						selectedTrailers={selectedTrailers}
						dispatchSelectedTrailerAction={
							dispatchSelectedTrailerAction
						}
						trailerColors={trailerColors}
						dispatchTrailerColorAction={dispatchTrailerColorAction}
						selectedCustomers={selectedCustomers}
					/>
				);
			case tabs[2]:
				return (
					<TruckFilterSection
						selectedTrucks={selectedTrucks}
						dispatchSelectedTruckAction={
							dispatchSelectedTruckAction
						}
						truckColors={truckColors}
						dispatchTruckColorAction={dispatchTruckColorAction}
						selectedCustomers={selectedCustomers}
					/>
				);
			case tabs[3]:
				return (
					<DriverFilterSection
						selectedDrivers={selectedDrivers}
						dispatchSelectedDriverAction={
							dispatchSelectedDriverAction
						}
						driverColors={driverColors}
						dispatchDriverColorAction={dispatchDriverColorAction}
						selectedCustomers={selectedCustomers}
					/>
				);
			default:
				return <View />;
		}
	}, [
		dispatchCustomerColorAction,
		dispatchDriverColorAction,
		dispatchSelectedCustomerAction,
		dispatchSelectedDriverAction,
		dispatchSelectedTrailerAction,
		dispatchSelectedTruckAction,
		dispatchTrailerColorAction,
		dispatchTruckColorAction,
		selectedTab,
		selectedCustomers,
		customerColors,
		selectedDrivers,
		driverColors,
		selectedTrailers,
		trailerColors,
		selectedTrucks,
		truckColors,
	]);

	return (
		<View style={styles.sideMenu}>
			<SFilterSectionContext.Provider
				value={{
					global: !!global,
					onChangeGlobal:
						onChangeGlobal ||
						((b: boolean) => console.log('no global', b)),
					globalFiltered: !!globalFiltered,
					onChangeGlobalFiltered:
						onChangeGlobalFiltered ||
						((b: boolean) => console.log('no global filter', b)),
					useGlobal: !!onChangeGlobal,
				}}
			>
				<View style={styles.containerFullWidthHorizontalNoPadding}>
					{tabs.map((tab) => {
						return (
							<TouchableOpacity
								key={tab}
								activeOpacity={0.8}
								style={[
									styles.tabLite,
									tab === selectedTab && styles.selectedtab,
								]}
								onPress={() => onChangeSelectedTab(tab)}
							>
								<MundoIcon
									icon={'card' + capitalizeFirstWord(tab)}
								/>
							</TouchableOpacity>
						);
					})}
				</View>
				{filterSection}
			</SFilterSectionContext.Provider>
		</View>
	);
};
