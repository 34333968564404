import React, { FC, useRef } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';

import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import MundoIcon from '../MundoIcon';
import MundoText from '../MundoText';
import { IMundoButtonProps } from './props';
import mundoButtonStyles from './styles';

const MundoPadButton: FC<IMundoButtonProps> = (props) => {
	const ref = useRef(null);
	const styles = useStyle();
	const { theme } = useTheme();
	const isHovered = useHover(ref);
	return (
		<TouchableOpacity
			{...{ dataSet: { cy: props.cyId } }}
			ref={ref}
			style={[
				mundoButtonStyles(theme).button,
				props.styles,
				props.disabled ? styles.disabled : null,
				props.subtype === 'transparent'
					? styles.transparentButton
					: null,
				isHovered && props.stylesHovered,
			]}
			onPress={props.disabled ? undefined : props.onPress}
		>
			<View style={mundoButtonStyles(theme).buttonContainer}>
				{props.icon && (
					<View
						style={
							props.title
								? mundoButtonStyles(theme).buttonIconContainer
								: {}
						}
					>
						<MundoIcon
							icon={props.icon}
							fontSize={40}
							styles={{ tintColor: theme.textMainColor }}
						/>
					</View>
				)}
				{props.short && (
					<MundoText
						styles={styles.headerSecondaryText}
						message={props.short}
					/>
				)}
				{props.title && (
					<MundoText
						styles={props.fontStyles}
						message={props.title}
					/>
				)}
			</View>
			{props.children}
		</TouchableOpacity>
	);
};

export default MundoPadButton;
