import { useLazyQuery, useMutation } from '@apollo/client';
import { ELanguages, escapeRegex, MTranslation } from 'mango-utils-client';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
	NativeSyntheticEvent,
	Text,
	TextInput,
	TextInputKeyPressEventData,
	TouchableOpacity,
	View,
} from 'react-native';

import { MundoText } from '..';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { PopUpContext } from '../../../utilities/contexts/PopUp';
import useDebounce from '../../../utilities/hooks/debounce';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import { getTranslation } from '../../../utilities/translations';
import MundoButton from '../MundoButton';
import { IMundoObjectTypeaheadProps } from './props';
import { PLACEHOLDER } from './queries';
import MundoObjectTypeaheadStyles from './styles';

const DROPDOWN_LIMIT = 10;
/**
 * Object Typeahead for serverside rendering
 * ! This needs to be contained in a elevated View... thanks react-native-web !
 * @param props onChange,
 * value,
 * createComponent,
 * placeholder,
 * QUERY,
 * additionalFilters,
 */
const MundoObjectTypeahead: FC<IMundoObjectTypeaheadProps> = ({
	cyId,
	onChange,
	value,
	createComponent,
	placeholder,
	QUERY,
	QUICKADDQUERY,
	additionalFilters,
	customValidity,
	multilanguage,
}) => {
	const intl = useIntl();
	const styles = useStyle();
	const { theme } = useTheme();

	const { onChangePopUp, open: openPop, close: closePop } = useContext(
		PopUpContext,
	);

	const [open, onChangeOpen] = useState(false);
	const [currentText, onChangeCurrentText] = useState('');
	const [currentIndex, onChangeCurrentIndex] = useState(0);
	const my = { createComponent };
	const { language } = useContext(LanguageContext);

	const openPopUp = () => {
		if (my.createComponent) {
			const customer = additionalFilters
				? additionalFilters.company ||
				  additionalFilters.customer ||
				  additionalFilters.companyID ||
				  additionalFilters.companyId
				: '';
			onChangePopUp(
				<my.createComponent
					id={'new'}
					callback={(res: { title: string; _id: string }) => {
						onChange(res);
						closePop();
					}}
					initialTitle={currentText}
					initialCustomer={customer}
					stay
				/>,
			);
			openPop();
		}
	};

	const getValidity = useCallback(
		(input: any) => {
			if (customValidity) {
				return customValidity(input);
			}
			return undefined;
		},
		[customValidity],
	);

	/**
	 * Typeahead DATA based on provided Query
	 */
	const [loadData] = useLazyQuery<
		{ data: any },
		{
			any: { title: string | MTranslation[] };
			count: number;
			start: number;
		}
	>(QUERY, {
		onCompleted: (data) => {
			if (data) {
				Object.keys(data).forEach((key) => {
					if (key.length) {
						// * check if key is array (because arrays got length)
						// @ts-ignore // * use key for data
						onChangenValues(data[key]);
					}
				});
			}
		},
	});

	const loadCB = useCallback(
		(title: string) => {
			let next: string | MTranslation[] = escapeRegex(title);
			if (multilanguage) {
				next = [new MTranslation({ message: escapeRegex(title) })];
			}
			loadData({
				variables: {
					any: { title: next, ...additionalFilters },
					count: DROPDOWN_LIMIT,
					start: 0,
				},
			});
		},
		[loadData, additionalFilters, multilanguage],
	);

	const [values, onChangenValues] = useState<
		Array<{ title: string | MTranslation[]; _id: string }>
	>([]);

	const currentValues = useMemo(() => {
		return values.filter((v) => {
			if (typeof v.title === 'string') {
				return (v.title as string).includes(currentText);
			} else {
				return (v.title as MTranslation[]).find((translation) =>
					translation.message.includes(currentText),
				);
			}
		});
	}, [values, currentText]);

	const debouncedSearchTerm = useDebounce<string>(currentText);

	useEffect(() => {
		if ((debouncedSearchTerm || debouncedSearchTerm === '') && open) {
			loadCB(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm, loadCB, open]);

	useEffect(() => {
		if (value && value.title) {
			if (typeof value.title === 'string') {
				onChangeCurrentText(value.title);
			} else {
				const translation = getTranslation(value.title, language);
				onChangeCurrentText(translation ? translation : '');
			}
		}
	}, [value, language]);

	const [addData] = useMutation(QUICKADDQUERY || PLACEHOLDER, {
		onCompleted: (data) => {
			if (data) {
				Object.keys(data).forEach((key) => {
					// @ts-ignore // * use key for data
					onChange(data[key]);
				});
			}
		},
	});
	const quickAdd = async () => {
		await addData({ variables: { title: currentText } });
	};

	return (
		<View style={MundoObjectTypeaheadStyles(theme).typeAheadGroup}>
			<View>
				<TextInput
					{...{ dataSet: { cy: cyId } }}
					style={styles.input}
					value={currentText}
					placeholder={
						placeholder &&
						capitalizeFirstWord(intl.formatMessage(placeholder))
					}
					// handling text
					onChangeText={(text) => {
						onChangeCurrentText(text);
						const newValue = values.find((v) => v.title === text);
						onChangeCurrentIndex(0);
						if (newValue) {
							onChange(newValue);
						} else {
							onChange();
						}
					}}
					onKeyPress={(
						event: NativeSyntheticEvent<TextInputKeyPressEventData>,
					) => {
						const key = event.nativeEvent.key;
						if (key === 'Enter') {
							event.preventDefault();
							if (
								currentValues.length > 0 &&
								currentValues.length > currentIndex
							) {
								const next = currentValues[currentIndex];
								if (typeof next.title === 'string') {
									onChangeCurrentText(next.title as string);
								} else {
									onChangeCurrentText(
										getTranslation(
											next.title as MTranslation[],
											language,
										),
									);
								}
								onChange(next);
							}
							onChangeCurrentIndex(0);
						} else if (key === 'ArrowDown') {
							event.preventDefault();
							if (currentValues.length > currentIndex + 1) {
								onChangeCurrentIndex(currentIndex + 1);
							}
						} else if (key === 'ArrowUp') {
							event.preventDefault();
							if (0 < currentIndex) {
								onChangeCurrentIndex(currentIndex - 1);
							}
						}
					}}
					// Handling of opening and closing typeahead
					onFocus={() => onChangeOpen(true)}
					onBlur={() => setTimeout(() => onChangeOpen(false), 150)}
				/>
				{getValidity(value) && (
					<MundoText
						styles={styles.warning}
						message={getValidity(value)}
					/>
				)}
			</View>
			{open && (
				<View
					style={MundoObjectTypeaheadStyles(theme).floatingContentBox}
				>
					{values.slice(0, DROPDOWN_LIMIT).map((v, index) => {
						return (
							<TouchableOpacity
								{...{
									dataSet: {
										cy: `${cyId}.${
											typeof v.title === 'string'
												? v.title
												: getTranslation(
														v.title,
														ELanguages.en,
												  )
										}.suggestion`,
									},
								}}
								style={[
									MundoObjectTypeaheadStyles(theme).option,
									currentIndex === index
										? MundoObjectTypeaheadStyles(theme)
												.optionActive
										: {},
								]}
								key={v._id}
								onPress={() => {
									onChange(v);
									if (typeof v.title === 'string') {
										onChangeCurrentText(v.title);
									} else {
										const translation = getTranslation(
											v.title,
											language,
										);
										onChangeCurrentText(
											translation ? translation : '',
										);
									}
								}}
							>
								<Text
									style={
										currentIndex === index &&
										MundoObjectTypeaheadStyles(theme)
											.highlight
									}
								>
									{(multilanguage
										? getTranslation(
												v.title as MTranslation[],
												language,
										  )
										: v.title) || v._id}
								</Text>
							</TouchableOpacity>
						);
					})}
				</View>
			)}
			{(createComponent || (QUICKADDQUERY && currentText)) &&
				(!value || currentText.length === 0) && (
					<MundoButton
						styles={styles.inputStandardHeight}
						onPress={QUICKADDQUERY ? quickAdd : openPopUp}
						icon={'plus'}
					/>
				)}
		</View>
	);
};

export default MundoObjectTypeahead;
