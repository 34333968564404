import { defineMessages } from 'react-intl';

export const scope = 'mango.container.groupInvoice.list';

export default defineMessages({
	from: {
		id: `${scope}.from`,
		defaultMessage: 'from',
	},
	to: {
		id: `${scope}.to`,
		defaultMessage: 'to',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
});
