import { useLazyQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import {
	Alpha,
	dateFormatter,
	day,
	EUnit,
	MArticle,
	SData,
	SStat,
	week,
} from 'mango-utils-client';
import { MundoText } from '../../../../components/elements';
import unitMessages from '../../../../messages';
import { destructData } from '../../../../utilities/dataFunctions';
import { useArray } from '../../../../utilities/hooks/array';
import { useStyle } from '../../../../utilities/hooks/styles';
import ArticleFilterSection from '../../components/ArticleFilterSection';
import { Chart } from '../../components/Chart/Chart';
import { DataTable } from '../../components/DataTable/DataTable';
import { FilterSection } from '../../components/FilterSection/FilterSection';
import TimeConfigurator from '../../components/TimeConfigurator';
import { formatTimeTick } from '../../utilities/times';
import { IExportableStatistic } from '../Dashboard/props';
import messages from './messages';
import { GET_ARTICLE_STATS } from './queries';

export const Articles: FC<IExportableStatistic> = () => {
	const styles = useStyle();
	const intl = useIntl();
	const [fromTo, onChangeFromTo] = useState<string>(
		`${Date.now() - week}/${Date.now()}`,
	);
	// const [ compare, onChangeCompare ] = useState<boolean>(false);

	const [
		selectedArticles,
		dispatchSelectedArticleAction,
	] = useArray<MArticle>();
	const [articleColors, dispatchArticleColorAction] = useArray<string>();
	const [
		articleSecondaryColors,
		dispatchArticleSecondaryColorAction,
	] = useArray<string>();

	const [selectedTab, onChangeSelectedTab] = useState<string>('customer'); // selected tab (for grouping)
	const [selectedEntities, onChangeSelectedEntities] = useState<Alpha[]>([]);
	const [entityColors, onChangeEntityColors] = useState<string[]>([]);

	const [interval, onChangeInterval] = useState<number>(day);
	const [entrys, onChangeEntrys] = useState<number>(10);
	const [startDate, onChangeStartDate] = useState(Date.now());

	const [data, onChange] = useState<SStat>(new SStat());
	const [query] = useLazyQuery(GET_ARTICLE_STATS, {
		onCompleted: (response) => {
			onChange(destructData(response));
		},
	});
	useEffect(() => {
		query({
			variables: {
				from: +fromTo.split('/')[0],
				to: +fromTo.split('/')[1],
				interval,
				entrys,
				selectedArticles: selectedArticles.map((c) => c._id).join(','),
			},
		});
	}, [interval, fromTo, selectedArticles, entrys, query]);

	const updateFromTo = useCallback((fromto: string) => {
		onChangeFromTo(fromto);
	}, []);

	const getTick = useCallback(
		(tick: SData | number, asDate = false) => {
			const formattedTimeTick = formatTimeTick(
				tick,
				startDate,
				entrys,
				interval,
				asDate,
			);
			if (typeof formattedTimeTick === 'object') {
				return intl.formatMessage(formattedTimeTick);
			} else {
				return formattedTimeTick;
			}
		},
		[intl, interval, entrys, startDate],
	);

	return (
		<>
			<View style={[styles.spacedContainer]}>
				<View style={[styles.horizontalLayout]}>
					<View style={styles.sideMenu}>
						<View style={{ flex: 1, zIndex: 10 }}>
							<ArticleFilterSection
								selectedArticles={selectedArticles}
								dispatchSelectedArticleAction={
									dispatchSelectedArticleAction
								}
								articleColors={articleColors}
								dispatchArticleColorAction={
									dispatchArticleColorAction
								}
								articleSecondaryColors={articleSecondaryColors}
								dispatchArticleSecondaryColorAction={
									dispatchArticleSecondaryColorAction
								}
							/>
						</View>
						<View style={{ flex: 1 }}>
							<FilterSection
								selectedEntities={selectedEntities}
								onChangeSelectedEntities={
									onChangeSelectedEntities
								}
								selectedTab={selectedTab}
								onChangeSelectedTab={onChangeSelectedTab}
								entityColors={entityColors}
								onChangeEntityColors={onChangeEntityColors}
								// global={global}
								// onChangeGlobal={onChangeGlobal}
								// globalFiltered={globalFiltered}
								// onChangeGlobalFiltered={onChangeGlobalFiltered}
							/>
						</View>
					</View>
					<View style={{ flex: 1, justifyContent: 'center' }}>
						<View style={styles.headerView}>
							<MundoText
								styles={styles.headerSecondaryText}
								message={messages.header}
							>
								{' ' + dateFormatter(new Date())}
							</MundoText>
						</View>
						<Chart
							entityColors={articleColors}
							compare={false}
							global={false}
							data={data}
							pastData={new SStat()}
							entrys={entrys}
							yLabel={
								selectedArticles.length &&
								selectedArticles[0] &&
								!selectedArticles.some(
									(art) =>
										art.unit !== selectedArticles[0].unit,
								) &&
								selectedArticles[0].unit !== EUnit.NONE
									? unitMessages[selectedArticles[0].unit]
									: messages.noMatchingUnitType
							}
							formatTick={getTick}
							selectedEntities={selectedArticles}
						/>
					</View>
					<TimeConfigurator
						updateFromTo={updateFromTo}
						fromTo={fromTo}
						onChangeInterval={onChangeInterval}
						interval={interval}
						onChangeEntrys={onChangeEntrys}
						entrys={entrys}
						startDate={startDate}
						onChangeStartDate={onChangeStartDate}
					/>
				</View>
			</View>
			<DataTable
				selectedEntrys={selectedArticles}
				data={data}
				formatTick={getTick}
			/>
		</>
	);
};
