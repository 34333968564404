import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../utilities/constants';

const orderCardStyles = (theme: IThemeConstants, highlighted?: boolean) =>
	StyleSheet.create({
		container: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: 0,
			margin: 0,
			flex: 1,
			borderColor: highlighted ? theme.accentQuaternaryColor : undefined,
			borderWidth: highlighted ? 3 : undefined,
			borderRadius: 5,
		},
		orderData: {
			maxWidth: '82%',
			width: '82%',
			padding: 10,
		},
		orderButton: {
			backgroundColor: 'transparent',
			borderWidth: 0,
			padding: 10,
			margin: 0,
			height: '100%',
		},
		orderButtonContainer: {
			borderLeftWidth: 3,
			borderLeftColor: '#fff',
			minHeight: 160,
			maxHeight: 320,
			alignItems: 'center',
			justifyContent: 'center',
		},
	});

export default orderCardStyles;
