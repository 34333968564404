import React, { FC, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

// import  GraphQL query from file
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	ESubState,
	MModuleDescription,
	MOrder,
	MTLane,
	MTRecordSettings,
} from 'mango-utils-client';
import { useIntl } from 'react-intl';
import { MundoButton } from '../../../../../components/elements';
import MundoBasicTableHeader from '../../../../../components/elements/MundoBasicTableHeader';
import MundoPicker from '../../../../../components/elements/MundoPicker';
import MundoText from '../../../../../components/elements/MundoText';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import { IDashboardProps } from '../../../../../container/Dashboard/props';
import OrderList from '../../../../../container/Order/List';
import { alert, confirm } from '../../../../../utilities/alert';
import { useArray } from '../../../../../utilities/hooks/array';
import { useStyle } from '../../../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../../../utilities/moduleTransformers';
import { openDocs } from '../../../../../utilities/openDocs';
import { ETypes } from '../../../../../utilities/reducer/array.reducer';
import { useHistory, useParams } from '../../../../../utilities/routing';
import { capitalizeFirstWord } from '../../../../../utilities/stringFunctions';
import { pickTableContentExtension } from '../../../extensions/TableContent';
import { pickTableHeaderExtension } from '../../../extensions/TableHeader';
import messages from './messages';
import { ILanesPicker } from './props';
import { ASSIGN_TO_LANE, GET_LANES, GET_ORDERS_BY_LANES } from './queries';

const TRecordDashboard: FC<IDashboardProps> = (props) => {
	const styles = useStyle();
	const history = useHistory();
	const intl = useIntl();
	const { pipelineId } = useParams<{ pipelineId: string }>();

	// Pages get used for initial population and background values
	const [lanes, dispatchLaneAction] = useArray<MTLane>();
	// load settings
	const [tRecordSettings, onChangeRecordSettings] = useState(
		new MTRecordSettings(),
	);
	const [ordersByLane, dispatchOrdersByLaneAction] = useArray<{
		laneId: number;
		orders: MOrder[];
	}>();
	const [getOrdersByLanes] = useLazyQuery(GET_ORDERS_BY_LANES, {
		onCompleted: (response) => {
			if (response && response.getOrdersByLanes) {
				dispatchOrdersByLaneAction({
					type: ETypes.RESET_TO,
					item: response.getOrdersByLanes.map(
						(ol: { laneId: number; orders: MOrder[] }) => {
							return {
								laneId: ol.laneId,
								orders: ol.orders.map((o) => new MOrder(o)),
							};
						},
					),
				});
			}
		},
	});
	/**
	 * effect to init orders on lanes loading
	 */
	useEffect(() => {
		getOrdersByLanes({
			variables: { laneIds: lanes.map((lane) => lane.id) },
		});
	}, [getOrdersByLanes, lanes]);

	// load setting with lanes
	const { loading: loadingSettings } = useQuery(GET_LANES, {
		variables: {
			pipelineId,
		},
		onCompleted: (response: { tRecordSettings: MTRecordSettings }) => {
			if (response && response.tRecordSettings) {
				const recordSettings = new MTRecordSettings(
					response.tRecordSettings,
				);
				// dispatch action to lane to refresh
				dispatchLaneAction({
					type: ETypes.RESET_TO,
					item: recordSettings.lanes,
				});
				onChangeRecordSettings(recordSettings);
			}
		},
	});

	// loading display
	if (loadingSettings) {
		return <LoadingIndicator />;
	}
	const LanesPickerHeader = (
		<View style={styles.wideGroupTableColumn25}>
			<MundoBasicTableHeader message={messages.lane} />
		</View>
	);
	const LanesPicker: FC<ILanesPicker> = (lpProps) => {
		// TODO: make use of states in orders
		const [assignOrderToLane] = useMutation(ASSIGN_TO_LANE);
		// assign order to lane and refresh lane component
		const assignOrder = async (order: MOrder, laneId: number) => {
			await assignOrderToLane({
				variables: { orderId: order._id, laneId, pipelineId },
			});
			const lane = ordersByLane.find(
				(laneOrderConsturct) => laneOrderConsturct.laneId === laneId,
			);
			if (lane && !lane.orders.find((o: MOrder) => o._id === order._id)) {
				ordersByLane.forEach(
					(laneOrderConsturct: {
						laneId: number;
						orders: MOrder[];
					}) => {
						const oIndex = laneOrderConsturct.orders.findIndex(
							(o: MOrder) => o._id === order._id,
						);
						if (oIndex >= 0) {
							laneOrderConsturct.orders.splice(oIndex, 1);
						}
					},
				);
				lane.orders.push(order);
				dispatchOrdersByLaneAction({ type: ETypes.NOTIFY });
			}
		};
		const selectedLane = ordersByLane.find((laneOrderConsturct) =>
			laneOrderConsturct.orders.find(
				(o: MOrder) => o._id === lpProps.order._id,
			),
		);
		return (
			<View style={styles.wideGroupTableColumn25}>
				<MundoPicker
					dataSet={{
						cy: `lanePicker.${lpProps.order.customer.title}.${lpProps.index}`,
					}}
					value={selectedLane && selectedLane.laneId}
					onChange={(id: number) => {
						assignOrder(lpProps.order, +id).catch((e) =>
							alert(
								capitalizeFirstWord(
									intl.formatMessage(messages.error),
								),
								e,
								'error',
							),
						);
					}}
					placeholder={messages.selectLane}
					values={
						lanes &&
						lanes.map((lane: MTLane, index) => {
							return {
								label: `${capitalizeFirstWord(
									intl.formatMessage(messages.lane),
								)} ${index + 1}`,
								value: lane.id,
							};
						})
					}
				/>
			</View>
		);
	};

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					{props.pipeline && (
						<MundoText
							message={transformModuleTitle(
								props.pipeline.modules.find(
									(m) =>
										m.identifier ===
										'record.vehicleCleaning',
								) || new MModuleDescription(),
								true,
							)}
							styles={styles.headerText}
						/>
					)}
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/record?id=dashboard',
							)
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList
						tableConfig={tRecordSettings.dashboardFields}
						fields={LanesPicker}
						headers={LanesPickerHeader}
						additionalTableContent={pickTableContentExtension}
						additionalTableHeader={pickTableHeaderExtension}
						state={'record.vehicleCleaning'}
						pipeline={pipelineId}
						noFilter
						noHeader
					/>
				</View>
			</ScrollView>
			<View style={styles.wideGroup}>
				<ScrollView horizontal>
					{lanes &&
						lanes.map((lane: MTLane, index) => {
							const laneOrders = ordersByLane.find(
								(item) => item.laneId === lane.id,
							);
							return (
								<View
									key={lane._id}
									style={[
										styles.card,
										{
											minHeight: 120,
											justifyContent: 'flex-start',
										},
									]}
								>
									<MundoText message={messages.lane} bold>
										: {index + 1}
									</MundoText>
									{!!lane.classifications.length && (
										<View>
											<MundoText
												message={
													messages.classifications
												}
												styles={styles.topMargin20}
											>
												:
											</MundoText>
											{lane.classifications.map(
												(classification) => (
													<View key={classification}>
														<MundoText>
															{classification}
														</MundoText>
													</View>
												),
											)}
										</View>
									)}
									{!!lane.trailerType.length && (
										<MundoText
											message={messages.trailerTypes}
											styles={styles.topMargin10}
										>
											: {lane.trailerType.join()}
										</MundoText>
									)}
									{!!lane.tags.length && (
										<MundoText
											message={messages.tags}
											styles={styles.topMargin10}
										>
											: {lane.tags.join()}
										</MundoText>
									)}
									{laneOrders &&
										laneOrders.orders.map(
											(order: MOrder) => (
												<View
													key={order._id}
													style={styles.topMargin10}
												>
													<MundoText
														message={messages.id}
													>
														: {order.id}
													</MundoText>
													<MundoButton
														dataSet={{
															cy: `useOrderFromLane.${order.customer.title}`,
														}}
														onPress={async () => {
															if (
																order.subState ===
																ESubState.ONGOING
															) {
																await new Promise<void>(
																	(resolve) =>
																		confirm(
																			capitalizeFirstWord(
																				intl.formatMessage(
																					messages.occupied,
																				),
																			),
																			capitalizeFirstWord(
																				intl.formatMessage(
																					messages.confirm,
																				),
																			),
																			resolve,
																			'question',
																		),
																);
															}
															history.push(
																`/module/${
																	pipelineId
																		? pipelineId +
																		  '/'
																		: ''
																}${
																	order.currentState
																}/${order._id}`,
															);
														}}
														title={messages.use}
													/>
												</View>
											),
										)}
								</View>
							);
						})}
				</ScrollView>
			</View>
		</View>
	);
};

export default TRecordDashboard;
