import React, { FC } from 'react';
import { View } from 'react-native';

import { useTheme } from '../../../utilities/hooks/styles';
import { IMundoCard } from './props';
import mundoCardStyles from './styles';

const MundoCard: FC<IMundoCard> = ({
	style,
	alignment,
	children,
	...passThrough
}) => {
	const { theme } = useTheme();
	let alignmentStyle = {};
	switch (alignment) {
		case 'left': {
			alignmentStyle = { justifyContent: 'flex-start' };
			break;
		}
		case 'center': {
			alignmentStyle = { alignItems: 'center' };
			break;
		}
		case 'right': {
			alignmentStyle = { justifyContent: 'flex-end' };
			break;
		}
		default: {
			alignmentStyle = { justifyContent: 'center' };
			break;
		}
	}

	return (
		<View
			style={[mundoCardStyles(theme).card, style, alignmentStyle]}
			{...passThrough}
		>
			{children}
		</View>
	);
};

export default MundoCard;
