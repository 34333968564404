import { defineMessages } from 'react-intl';

export const scope = 'mango.container.PackageFilter';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	customerPlaceholder: {
		id: `${scope}.customerPlaceholder`,
		defaultMessage: 'customer name',
	},
});
