import { useMutation, useQuery } from '@apollo/client';
import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { ScrollView, View } from 'react-native';

import {
	ERegion,
	MAddress,
	MCustomer,
	MPaymentMethod,
	MPaymentMethodWithTime,
} from 'mango-utils-client';
import AddressEdit from '../../../components/AddressEdit';
import { MundoButton, MundoCheckBox } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import MundoPicker from '../../../components/elements/MundoPicker';
import MundoText from '../../../components/elements/MundoText';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import HostEdit from '../../../components/HostEdit';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { EPluign, getExtension } from '../../../Plugins';
import { destructData } from '../../../utilities/dataFunctions';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useId } from '../../../utilities/hooks/id';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory, useParams } from '../../../utilities/routing';
import messages from './messages';
import { ICustomerEditProps } from './props';
import {
	DELETE_CUSTOMER,
	GET_CUSTOMER,
	GET_PAYMENTMETHOD,
	GET_PAYMENTMETHODS,
	ID_IS_TAKEN,
	SAVE_CUSTOMER,
} from './queries';
import { PaymentMethodByTime } from './components/PaymentMethodByTime';

export const CustomerEditContext = createContext({
	customer: new MCustomer(),
	id: '',
});

const CustomerEdit: FC<ICustomerEditProps> = ({
	id: propID,
	initialTitle,
	stay,
	callback,
	localValues,
	noDelete,
}) => {
	const styles = useStyle();
	const history = useHistory();
	const { id: urlId } = useParams<{ id: string }>();
	const id = propID || (localValues ? localValues._id : '') || urlId;
	const { isAdmin } = useContext(AuthContext);

	const [online, onChangeOnline] = useState<boolean>(
		!!(localValues && localValues._id),
	);
	const [customer, onChangeCustomer] = useState(
		new MCustomer(localValues || { title: initialTitle || '' }),
	);
	const [expandedHostData, onChangeExpandedHostData] = useState(false);
	const {
		currentID,
		idIsValid,
		unverified,
		checkIDInput,
		updateID,
		onChangeCurrentID,
	} = useId(customer, ID_IS_TAKEN);

	const { loading } = useQuery(GET_CUSTOMER, {
		skip: !id || id === 'new' || localValues,
		variables: { id },
		onCompleted: (queryData) => {
			if (queryData) {
				onChangeCustomer(new MCustomer(queryData.customer));
				onChangeCurrentID(queryData.customer.id);
			}
		},
	});

	const [saveCustomer, { loading: saving }] = useMutation(SAVE_CUSTOMER, {
		onCompleted: (data) => {
			customer._id = destructData<{ _id: string }>(data)._id;
			finalNavigation(customer);
		},
	});
	const [deleteCustomer] = useMutation(DELETE_CUSTOMER);

	const checkTaxInput = (input: string) => {
		const regex = new RegExp(/^([0-9])*$/);
		if (input.match(regex)) {
			return;
		}

		return messages.taxError;
	};

	const update = useCallback(
		(input: Partial<MCustomer>) => {
			if (customer.isPerson || input.isPerson) {
				const first = input.firstName || customer.firstName;
				const last = input.lastName || customer.lastName;
				if (first || last) {
					input.title = `${first} ${last}`;
				}
			}
			onChangeCustomer({ ...customer, ...input });
		},
		[customer],
	);

	const saveButtonHandler = useCallback(async () => {
		if (!localValues || (localValues && online)) {
			await saveCustomer({
				variables: { customer: { ...customer, id: currentID } },
			});
		} else if (localValues && !online) {
			finalNavigation(customer);
		}
	}, [customer, currentID, saveCustomer]);

	const deleteButtonHandler = async () => {
		await deleteCustomer({ variables: { id } });
		finalNavigation();
	};

	const cancelButtonHandler = async () => {
		finalNavigation();
	};

	const finalNavigation = (resultCompany?: MCustomer) => {
		if (!stay) {
			history.goBack();
		} else if (callback) {
			callback(resultCompany);
		}
	};

	const { data: paymentMethodData } = useQuery(GET_PAYMENTMETHOD, {
		skip:
			!customer.defaultPaymentMethod ||
			!customer.defaultPaymentMethod._id,
		variables: { id: customer.defaultPaymentMethod?._id || '' },
	});

	const transcribeAddress = () => {
		if (customer.address) {
			update({ billingAddress: new MAddress(customer.address) });
		}
	};
	useEffect(() => {
		if (localValues && localValues.id) {
			onChangeCurrentID(localValues.id);
		}
	}, [localValues]);

	if (loading || saving) {
		return <LoadingIndicator />;
	}

	return (
		<CustomerEditContext.Provider value={{ id, customer }}>
			<View style={styles.containerFullResolution}>
				<View style={styles.headerView}>
					<MundoText
						message={messages.header}
						styles={styles.headerText}
					/>
				</View>
				<ScrollView>
					<View style={styles.spacedContainer}>
						<View
							style={styles.containerFullWidthHorizontalNoPadding}
						>
							<View
								style={[
									styles.containerHalfWidth,
									{ zIndex: 10 },
								]}
							>
								<MundoInput
									label={messages.id}
									onChangeText={(customId) =>
										updateID(+customId)
									}
									value={`${currentID}`}
									type={`numeric`}
									length={5}
									customCheckInvalidity={checkIDInput}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.name` }}
									label={messages.title}
									onChangeText={(title) => update({ title })}
									value={customer.title}
									disabled={customer.isPerson}
								/>
								<MundoCheckBox
									checked={customer.isPerson}
									onCheckedChanged={(c) => {
										update({ isPerson: c });
									}}
									title={messages.isPerson}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.firstname` }}
									label={messages.firstName}
									onChangeText={(firstName) =>
										update({ firstName })
									}
									value={customer.firstName}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.lastname` }}
									label={messages.lastName}
									onChangeText={(lastName) =>
										update({ lastName })
									}
									value={customer.lastName}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.phone` }}
									label={messages.phone}
									onChangeText={(phone) => update({ phone })}
									value={customer.phone}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.mail` }}
									label={messages.mail}
									onChangeText={(mail) => update({ mail })}
									value={customer.mail}
								/>
							</View>
							<View style={styles.containerHalfWidth}>
								<MundoInput
									dataSet={{ cy: `company.input.tax` }}
									label={messages.taxNumber}
									onChangeText={(taxNr) => update({ taxNr })}
									value={customer.taxNr}
									customCheckInvalidity={checkTaxInput}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.ust` }}
									label={messages.ustIdNumber}
									onChangeText={(ustID) => update({ ustID })}
									value={customer.ustID}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.externalId` }}
									label={messages.externalId}
									onChangeText={(externalId) =>
										update({ externalId })
									}
									value={customer.externalId}
								/>
								<MundoInput
									dataSet={{ cy: `company.input.fax` }}
									label={messages.fax}
									onChangeText={(fax) => update({ fax })}
									value={customer.fax}
								/>
							</View>
							<View style={styles.containerHalfWidth}>
								<MundoInput
									dataSet={{
										cy: `company.input.defaultPaymentTime`,
									}}
									label={messages.defaultPaymentTime}
									unit={messages.days}
									onChangeText={(defaultPaymentTime) => {
										if (!isNaN(+defaultPaymentTime)) {
											update({
												defaultPaymentTime: +defaultPaymentTime,
											});
										}
									}}
									length={5}
									value={`${customer.defaultPaymentTime}`}
								/>
								<MundoInput
									dataSet={{
										cy: `company.input.groupInvoiceTime`,
									}}
									label={messages.groupInvoiceTime}
									onChangeText={(groupInvoiceTime) => {
										if (!isNaN(+groupInvoiceTime)) {
											update({
												groupInvoiceTime: +groupInvoiceTime,
											});
										}
									}}
									length={5}
									value={`${customer.groupInvoiceTime}`}
									unit={messages.days}
									hint={messages.groupInvoiceTimeHint}
								/>
								<View style={{ zIndex: 10 }}>
									<MundoText
										message={messages.defaultPaymentMethod}
									/>
									<MundoObjectTypeahead
										value={
											paymentMethodData &&
											destructData(paymentMethodData)
										}
										onChange={(value) => {
											if (value) {
												update({
													defaultPaymentMethod: value as MPaymentMethod,
												});
											} else {
												update({
													defaultPaymentMethod: new MPaymentMethod(),
												});
											}
										}}
										QUERY={GET_PAYMENTMETHODS}
									/>
								</View>
								<MundoText message={messages.discounts} />
								<View
									style={[
										styles.buttonGroupLeft,
										styles.flexContainerAutoWrap,
										{ minWidth: 250 },
									]}
								>
									{customer.discounts.map((dc, index) => (
										<MundoInput
											key={index}
											value={`${dc / 100}`}
											onChangeText={(text) => {
												customer.discounts.splice(
													index,
													1,
													+text * 100,
												);
												update({});
											}}
											length={5}
											unit={'%'}
										/>
									))}
									<MundoButton
										onPress={() =>
											update({
												discounts: [
													...customer.discounts,
													0,
												],
											})
										}
										icon={'plus'}
									/>
									<MundoButton
										onPress={() => {
											customer.discounts.pop();
											update({});
										}}
										icon={'minus'}
									/>
								</View>
								<View>
									<MundoPicker
										label={messages.allegiance}
										dataSet={{ cy: 'allegiance' }}
										onChange={(allegiance) =>
											update({ allegiance })
										}
										value={customer.allegiance}
										placeholder={messages.selectAllegiance}
										values={Object.keys(ERegion).map(
											(c) => {
												return { label: c, value: c };
											},
										)}
									/>
								</View>
							</View>
						</View>
						<View
							style={[
								styles.thinSeparator,
								styles.topMargin10,
								styles.bottomMargin10,
							]}
						/>
						<View
							style={styles.containerFullWidthHorizontalNoPadding}
						>
							<AddressEdit
								cyId={'company.address'}
								label={messages.address}
								address={customer.address}
								setAddress={(address) => update({ address })}
							/>
							<View style={[styles.centeredContent]}>
								<MundoButton
									icon={'right'}
									onPress={transcribeAddress}
								/>
							</View>
							<AddressEdit
								cyId={'company.billing address'}
								label={messages.billingAddress}
								address={customer.billingAddress}
								setAddress={(billingAddress) =>
									update({ billingAddress })
								}
							/>
						</View>
						{isAdmin && (
							<>
								<View
									style={[
										styles.thinSeparator,
										styles.topMargin10,
										styles.bottomMargin10,
									]}
								/>
								{expandedHostData ? (
									<View
										style={
											styles.containerFullWidthHorizontalNoPadding
										}
									>
										<HostEdit
											commissioning={
												customer.commissioning
											}
											legalInformation={
												customer.legalInformation
											}
											court={customer.court}
											director={customer.director}
											bank={customer.bank}
											onChange={update}
										/>
									</View>
								) : (
									<MundoButton
										title={messages.hostData}
										onPress={() =>
											onChangeExpandedHostData(true)
										}
										styles={[
											styles.formButtonStandardWidth,
											styles.topMargin10,
											{ alignSelf: 'center' },
										]}
									/>
								)}
							</>
						)}
						{localValues &&
							(localValues._id ? (
								<MundoCheckBox
									title={messages.online}
									onCheckedChanged={onChangeOnline}
									checked={online}
								/>
							) : (
								<MundoText message={messages.noOnline} />
							))}
						<View
							style={[
								styles.thinSeparator,
								styles.topMargin10,
								styles.bottomMargin10,
							]}
						/>
						<MundoCheckBox
							title={messages.enableTimedPaymentMethods}
							checked={customer.enablePaymentMethodByTime}
							onCheckedChanged={(c) =>
								update({ enablePaymentMethodByTime: c })
							}
						/>
						{customer.enablePaymentMethodByTime && (
							<View style={styles.elevated}>
								{customer.paymentMethodByTime.map(
									(paymentMethodByTime, index) => {
										return (
											<View
												key={index}
												style={{ zIndex: 100 - index }}
											>
												<View
													style={[
														styles.thinSeparator,
														styles.topMargin10,
														styles.bottomMargin10,
													]}
												/>
												<PaymentMethodByTime
													paymentMethodByTime={
														paymentMethodByTime
													}
													onChangePaymentMethodByTime={(
														next,
													) => {
														const clone =
															customer.paymentMethodByTime;
														clone[index] = next;
														update({
															paymentMethodByTime: clone,
														});
													}}
												/>
											</View>
										);
									},
								)}
								<View style={styles.horizontalLayout}>
									<MundoButton
										icon={'minus'}
										title={messages.removeEntry}
										onPress={() => {
											update({
												paymentMethodByTime: customer.paymentMethodByTime.slice(
													undefined,
													customer.paymentMethodByTime
														.length - 1,
												),
											});
										}}
									/>
									<MundoButton
										icon={'plus'}
										title={messages.addEntry}
										onPress={() => {
											update({
												paymentMethodByTime: [
													...customer.paymentMethodByTime,
													new MPaymentMethodWithTime(),
												],
											});
										}}
									/>
								</View>
							</View>
						)}
						<View
							style={[
								styles.thinSeparator,
								styles.topMargin10,
								styles.bottomMargin10,
							]}
						/>
						<FinishButtonGroup
							cyId={'company'}
							saveFunction={saveButtonHandler}
							saveDisabled={!idIsValid || unverified}
							deleteFunction={
								!noDelete && id !== 'new'
									? deleteButtonHandler
									: undefined
							}
							cancelFunction={cancelButtonHandler}
						/>
						{Object.keys(EPluign).map((plugin) => {
							const Extension = getExtension(
								'Customer',
								plugin as EPluign,
							);

							return <Extension key={plugin} />;
						})}
					</View>
				</ScrollView>
			</View>
		</CustomerEditContext.Provider>
	);
};

export default CustomerEdit;
