import { useLazyQuery } from '@apollo/client';
import { MArchiv } from 'mango-utils-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ESortMode } from '../../enums/ESortMode';
import { GET_ARCHIV } from './queries';

interface IUseArchivsProps {
	skip?: boolean;
	QUERY?: any;
	sortMode?: ESortMode | undefined;
}

export const useArchivs = (props: IUseArchivsProps) => {
	const [archivs, updateLocalArchivs] = useState<MArchiv[]>([] as MArchiv[]);
	const [fromDate, onChangeFrom] = useState<number>(Date.now() - 31540000000); // from a year ago
	const [toDate, onChangeTo] = useState<number>(Date.now()); // to now
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const [orderBy, onChangeOrderBy] = useState<string>('');

	const variables = useMemo(() => {
		return {
			start: archivs.length,
			count: 20,
			from: fromDate,
			to: toDate,
			orderBy,
			sortMode: props.sortMode,
		};
	}, [archivs, fromDate, toDate, orderBy, props.sortMode]);

	const [lazyQuery, { loading, refetch }] = useLazyQuery(GET_ARCHIV, {
		onCompleted: (data) => {
			if (data) {
				if (
					!data.getArchiv.length ||
					data.getArchiv.length % 20 !== 0
				) {
					setLoadMoreActive(false);
				}
				if (reset) {
					updateLocalArchivs(data.getArchiv);
					onChangeReset(false);
				} else {
					updateLocalArchivs([...archivs, data.getArchiv]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				count: 20,
				start: 0,
				from: fromDate,
				to: toDate,
				orderBy,
				sortMode: props.sortMode,
			},
		});
	}, [lazyQuery, fromDate, toDate, orderBy, props.sortMode]);

	return {
		archivs,
		loading,
		loadMore,
		onChangeFrom,
		onChangeTo,
		loadMoreActive,
		fromDate,
		toDate,
		onChangeOrderBy,
		refetch,
	};
};
