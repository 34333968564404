import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useParams } from 'react-router-dom';

import { MCustomer, MPaymentMethod, priceFormatter } from 'mango-utils-client';
import { useIntl } from 'react-intl';
import { MundoCheckBox } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import MundoPicker from '../../../components/elements/MundoPicker';
import MundoText from '../../../components/elements/MundoText';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import { alert } from '../../../utilities/alert';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import CustomerCard from '../../Customer/card';
import messages from './messages';
import {
	CUSTOMERS,
	DELETE_PAYMENTMETHOD,
	GET_CUSTOMER,
	GET_PAYMENTMETHOD,
	SAVE_PAYMENTMETHOD,
} from './queries';
import { IPaymentMethodEditProps } from './props';
import { destructData } from '../../../utilities/dataFunctions';

const PaymentMethodEdit: FC<IPaymentMethodEditProps> = ({
	id: propID,
	stay,
	callback,
	noDelete,
}) => {
	const styles = useStyle();
	const history = useHistory();
	const { id: urlId } = useParams<{ id: string }>();
	const intl = useIntl();
	const id = propID || urlId;
	const [customer, onChangeCustomer] = useState<MCustomer>();
	const [loadCompany] = useLazyQuery(GET_CUSTOMER, {
		onCompleted: (data) => {
			if (data && data.customer) {
				onChangeCustomer(data.customer);
			}
		},
	});
	const [paymentMethod, onChangePaymentMethod] = useState(
		new MPaymentMethod(),
	);

	useQuery(GET_PAYMENTMETHOD, {
		variables: { id },
		skip: !id || id === 'new',
		onCompleted: (data) => {
			if (data) {
				onChangePaymentMethod(data.paymentMethod);
				const c = data.paymentMethod.customer;
				if (c) {
					onChangeCustomer(c);
				}
			}
		},
	});

	const [savePaymentMethod] = useMutation(SAVE_PAYMENTMETHOD);
	const [deletePaymentMethod] = useMutation(DELETE_PAYMENTMETHOD);

	const update = (input: Partial<any>) => {
		const next = Object.assign({}, paymentMethod, input);
		onChangePaymentMethod(next);
	};
	const finalNavigation = (resultPaymentMethod?: MPaymentMethod) => {
		if (!stay) {
			history.goBack();
		} else if (callback) {
			callback(resultPaymentMethod || undefined);
		}
	};

	const saveButtonHandler = async () => {
		const result = await savePaymentMethod({
			variables: { paymentMethod },
		});
		const pm = destructData<MPaymentMethod>(result.data);
		finalNavigation({ ...paymentMethod, _id: pm._id });
	};

	const deleteButtonHandler = async () => {
		await deletePaymentMethod({ variables: { id } });
		finalNavigation(paymentMethod);
	};

	const cancelButtonHandler = async () => {
		finalNavigation();
	};

	return (
		<View
			{...{ dataSet: { cy: 'PaymentEdit' } }}
			style={styles.containerFullResolution}
		>
			<View style={styles.headerView}>
				<MundoText
					message={messages.header}
					styles={styles.headerText}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View style={styles.containerFullWidthHorizontalNoPadding}>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'payment.title.input' }}
								label={messages.title}
								onChangeText={(inputTitle) =>
									update({ title: inputTitle })
								}
								value={paymentMethod.title}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'payment.surcharge.input' }}
								label={messages.surcharge}
								unit={'%'}
								onChangeText={(surcharge) => {
									const next = +surcharge.replace(
										/,|\./g,
										'',
									);
									update({ surcharge: next });
								}}
								length={5}
								value={
									paymentMethod.surcharge &&
									paymentMethod.surcharge > 0
										? `${priceFormatter(
												paymentMethod.surcharge / 100,
										  )}`
										: ''
								}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'payment.time.input' }}
								label={messages.paymentTime}
								unit={messages.days}
								onChangeText={(paymentTime) =>
									!isNaN(+paymentTime)
										? update({ paymentTime: +paymentTime })
										: alert(
												capitalizeFirstWord(
													intl.formatMessage(
														messages.numericError,
													),
												),
												capitalizeFirstWord(
													intl.formatMessage(
														messages.numericErrorDescr,
													),
												),
												'warning',
										  )
								}
								value={`${paymentMethod.paymentTime}`}
								length={5}
							/>
						</View>
					</View>
					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							styles.topMargin30,
						]}
					>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'payment.description.input' }}
								label={messages.description}
								onChangeText={(description) =>
									update({ description })
								}
								value={paymentMethod.description}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoInput
								dataSet={{ cy: 'payment.tags.input' }}
								label={messages.tags}
								onChangeText={(tags) =>
									update({ tags: tags.split(',') })
								}
								value={
									paymentMethod.tags
										? paymentMethod.tags.join(',')
										: ''
								}
							/>
						</View>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoPicker
								label={messages.color}
								onChange={(color) => update({ color })}
								value={paymentMethod.color}
								values={[
									{ label: messages.noColor, value: '' },
									{ label: messages.red, value: '#ff0d49' },
								]}
							/>
						</View>
					</View>
					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							styles.topMargin30,
						]}
					>
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoText message={messages.cashbackActive} />
							<MundoCheckBox
								cyId={'payment.cashback.check'}
								onCheckedChanged={(input) => {
									update({ cashback: input });
								}}
								checked={!!paymentMethod.cashback}
							/>
						</View>
						{paymentMethod.cashback && (
							<>
								<View
									style={[
										styles.formUnitHeightVertCenterHalfHeight,
										styles.formUnitStandardWidth,
									]}
								>
									<MundoInput
										dataSet={{
											cy: 'payment.cashbackAmount.input',
										}}
										label={messages.cashbackAmount}
										unit={'%'}
										onChangeText={(cashbackAmount) => {
											const next = +cashbackAmount.replace(
												/,|\./g,
												'',
											);
											update({ cashbackAmount: next });
										}}
										value={`${
											paymentMethod.cashbackAmount &&
											paymentMethod.cashbackAmount > 0
												? priceFormatter(
														paymentMethod.cashbackAmount /
															100,
												  )
												: ''
										}`}
										length={5}
									/>
								</View>
								<View
									style={[
										styles.formUnitHeightVertCenterHalfHeight,
										styles.formUnitStandardWidth,
									]}
								>
									<MundoInput
										dataSet={{
											cy: 'payment.cashbackTime.input',
										}}
										unit={messages.days}
										label={messages.cashbackTime}
										onChangeText={(cashbackTime) =>
											!isNaN(+cashbackTime)
												? update({
														cashbackTime: +cashbackTime,
												  })
												: alert(
														capitalizeFirstWord(
															intl.formatMessage(
																messages.numericError,
															),
														),
														capitalizeFirstWord(
															intl.formatMessage(
																messages.numericErrorDescr,
															),
														),
														'warning',
												  )
										}
										length={5}
										value={`${paymentMethod.cashbackTime}`}
									/>
								</View>
							</>
						)}
						<View
							style={[
								styles.formUnitHeightVertCenterHalfHeight,
								styles.formUnitStandardWidth,
							]}
						>
							<MundoText message={messages.customer} />
							<MundoObjectTypeahead
								cyId={'payment.customer'}
								onChange={(c) => {
									update({ customer: c });
									if (c) {
										loadCompany({
											variables: { id: c._id },
										});
									} else {
										onChangeCustomer(undefined);
									}
								}}
								value={paymentMethod.customer}
								QUERY={CUSTOMERS}
							/>
						</View>
						{customer && <CustomerCard customer={customer} />}
					</View>
				</View>

				<FinishButtonGroup
					cyId={'payment'}
					saveFunction={saveButtonHandler}
					deleteFunction={!noDelete ? deleteButtonHandler : undefined}
					cancelFunction={cancelButtonHandler}
				/>
			</ScrollView>
		</View>
	);
};

export default PaymentMethodEdit;
