import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useMutation, useQuery } from '@apollo/client';

import {
	MItem,
	MModuleRecordIn,
	MModuleRecordSettings,
	MState,
} from 'mango-utils-client';
import MundoText from '../../../components/elements/MundoText';
import Item from '../../../components/Item';
import Pages from '../../../components/RecordPages';
import { useArray } from '../../../utilities/hooks/array';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';
import { RECORD_IN, RECORD_OUT, RECORD_SETTINGS } from './queries';
import { useParams, useHistory } from '../../../utilities/routing';
import { destructData } from '../../../utilities/dataFunctions';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { handlePages } from './functions/handlePages';

const RecordModule: FC = () => {
	const styles = useStyle();
	const [pages, dispatchPageAction] = useArray<{
		title: string;
		items: MItem[];
	}>();
	const { id, pipelineId } = useParams<{ id: string; pipelineId: string }>();
	const [loading, onChangeLoading] = useState<boolean>(true);
	const history = useHistory();

	const {
		called: setCalled,
		loading: setLoading,
		data: setData,
		error: setError,
	} = useQuery(RECORD_SETTINGS, {
		variables: { pipelineId },
	});

	const {
		called: inCalled,
		loading: inLoading,
		data: inData,
		error: inError,
	} = useQuery(RECORD_IN, {
		variables: { id },
	});
	const [recordOut] = useMutation(RECORD_OUT);

	useEffect(() => {
		if (inError || setError) {
			console.log('an error got detected');
		} else if (setCalled && !setLoading && inCalled && !inLoading) {
			onChangeLoading(false);
			const settings = destructData<MModuleRecordSettings>(setData);
			const input = destructData<MModuleRecordIn>(inData);
			dispatchPageAction({
				type: ETypes.RESET_TO,
				item: handlePages(
					settings.pages,
					settings.recommendedArticles,
					input.items,
				),
			});
		}
	}, [
		setCalled,
		setLoading,
		setData,
		inCalled,
		inLoading,
		inData,
		setError,
		inError,
		dispatchPageAction,
	]);

	const save = (next?: boolean) => {
		const items: MItem[] = pages.reduce<MItem[]>(
			(a, b) => [...a, ...b.items],
			[],
		);
		recordOut({
			variables: {
				state: new MState({
					items,
				}),
				orderId: id,
				pipelineId,
			},
		}).then((result) => {
			if (next) {
				history.push(`/module/${pipelineId}/${result.data.recordOut}`);
			} else {
				history.push('/dash/' + pipelineId + '/default');
			}
		});
	};
	if (loading) {
		return <LoadingIndicator />;
	}
	return (
		<View style={styles.fullSize}>
			<MundoText message={messages.record} />
			<MundoText message={messages.selectItems} />
			<Pages
				itemComponent={Item}
				pages={pages}
				dispatchAction={dispatchPageAction}
				save={() => save()}
				saveAndContinue={() => save(true)}
			/>
		</View>
	);
};

export default RecordModule;
