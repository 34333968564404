import { config } from '../config';
import { load } from '../localStorage';
import { refreshToken } from './refreshToken';

export const checkToken = async () => {
	try {
		const accessToken = await load('access_token');
		const postOptions = {
			headers: {
				Authorization: 'Bearer ' + accessToken,
			},
			method: 'GET',
		};
		if (accessToken) {
			try {
				const fetchResult = await fetch(
					`${config.API_URL}/check`,
					postOptions,
				);
				if (fetchResult.status !== 200) {
					const rt = await refreshToken();
					return { accessToken, refreshToken: rt, success: rt };
				}
				return { accessToken, refreshToken: false, success: true };
			} catch (e) {
				return { accessToken, refreshToken: false, success: false };
			}
		} else {
			return { accessToken, refreshToken: false, success: false };
		}
	} catch (e) {
		return { accessToken: '', refreshToken: false, success: false };
	}
};
