import { useLazyQuery } from '@apollo/client';
import React, { FC, useCallback, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MTTankEntrance, MTTrailer, range } from 'mango-utils-client';
import { MundoCheckBox, MundoText } from '../../../../components/elements';
import MundoInput from '../../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import FinishButtonGroup from '../../../../components/FinishButtonGroup';
import { IPopUpContentProps } from '../../../../components/PopupContainer/props';
import { useArray } from '../../../../utilities/hooks/array';
import { useStyle } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { useHistory } from '../../../../utilities/routing';
import messages from './messages';
import { GET_TRAILER, GET_TRAILERS } from './queries';
import { TankEntranceViewer } from './Viewer/TankEntranceViewer';

const TankEntrance: FC<IPopUpContentProps> = ({ initialOrderId }) => {
	const styles = useStyle();
	const history = useHistory();

	const [tankEntrance, onChangeTankEntrance] = useState<MTTankEntrance>();
	const [display, onChangeDisplay] = useState<boolean>(false);

	const [supportedChambers] = useState(6);
	const initBoolArray = range(supportedChambers).map(() => false);

	const [orderId, onChangeOrderId] = useState<number>(initialOrderId || 0);
	const [observer, onChangeObserver] = useState<string>('');
	const [worker, onChangeWorker] = useState<string>('');
	const [safety, onChangeSafety] = useState<string>('');
	const [task, onChangeTask] = useState<string>('');
	const [materials, onChangeMaterials] = useState<string>('');
	const [equipment, onChangeEquipment] = useState<string>('');

	const [o2, onChangeO2] = useState<number>(0);
	const [exp, onChangeExp] = useState<number>(0);
	const [dmg, onChangeDmg] = useState<number>(0);
	const [agw, onChangeAGW] = useState<number>(0);

	const [entry, dispatchEntry] = useArray<boolean>(
		new Array<boolean>(...initBoolArray),
	);
	const [preCleaned, dispatchPreCleaned] = useArray<boolean>(
		new Array<boolean>(...initBoolArray),
	);
	const [airOn, dispatchAirOn] = useArray<boolean>(
		new Array<boolean>(...initBoolArray),
	);
	const [suc, dispatchSuc] = useArray<boolean>(
		new Array<boolean>(...initBoolArray),
	);

	const [safetyEQ, onChangeSafetyEQ] = useState<boolean>(false);
	const [safetyRope, onChangeSafetyRope] = useState<boolean>(false);
	const [safetyGloves, onChangeSafetyGloves] = useState<boolean>(false);
	const [safetyGoggles, onChangeSafetyGoggles] = useState<boolean>(false);
	const [pna, onChangePNA] = useState<boolean>(false);
	const [multiDevice, onChangeMultiDevice] = useState<boolean>(false);
	const [breath, onChangeBreath] = useState<boolean>(false);
	const [ignition, onChangeIgnition] = useState<boolean>(false);
	const [earth, onChangeEarth] = useState<boolean>(false);

	const [clothing, onChangeClothing] = useState<string>('');
	const [shoes, onChangeShoes] = useState<string>('');

	const [trailer, changeTrailer] = useState<MTTrailer>(new MTTrailer());
	const [loadTrailer] = useLazyQuery(GET_TRAILER, {
		onCompleted: (data) => {
			if (data && data.tTrailer) {
				changeTrailer(data.tTrailer);
			}
		},
	});
	/**
	 * update trailer
	 * @param t next trailer _id
	 */
	const updateTrailer = useCallback(
		(t?: { _id: string }) => {
			if (t) {
				loadTrailer({
					variables: { id: t._id },
				});
			}
		},
		[loadTrailer],
	);

	const createTankEntranceButtonHandler = async () => {
		const newTankEntrance: MTTankEntrance = new MTTankEntrance({
			orderId,
			trailerType: trailer.trailerType,
			trailer: trailer.title,
			observer,
			worker,
			safety,
			task,
			materials,
			equipment,
			entry,
			perCleaned: preCleaned,
			airOnChecked: airOn,
			sucChecked: suc,
			o2,
			exp,
			dmg,
			agw,
			safetyEQ,
			safetyRope,
			pna,
			clothing,
			shoes,
			breath,
			safetyGloves,
			safetyGoggles,
			multiDevice,
			ignition,
			earth,
		});
		onChangeTankEntrance(newTankEntrance);
		onChangeDisplay(true);
	};
	if (display && tankEntrance) {
		return <TankEntranceViewer tankentrance={tankEntrance} />;
	}
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<MundoText
					message={messages.header}
					styles={styles.headerText}
				/>
			</View>
			<ScrollView>
				<View style={[styles.spacedContainer]}>
					<MundoText
						message={messages.generalInfo}
						styles={styles.boldText}
					/>
					<View
						style={[styles.containerFullWidthHorizontalNoPadding]}
					>
						<View style={[styles.topMargin20]}>
							<MundoText message={messages.orderId} />
							<MundoInput
								onChangeText={(id) => onChangeOrderId(+id)}
								value={`${orderId}`}
								length={20}
							/>
							<MundoText message={messages.license} />
							<View style={[styles.elevated, { zIndex: 1000 }]}>
								<MundoObjectTypeahead
									QUERY={GET_TRAILERS}
									value={trailer}
									onChange={updateTrailer}
								/>
							</View>
							<MundoText message={messages.observer} />
							<MundoInput
								onChangeText={(obsv) => onChangeObserver(obsv)}
								value={observer}
								length={20}
							/>
						</View>
						<View
							style={[
								styles.topMargin20,
								styles.leftMargin20,
								{ zIndex: -1 },
							]}
						>
							<MundoText message={messages.worker} />
							<MundoInput
								onChangeText={(wrker) => onChangeWorker(wrker)}
								value={worker}
							/>
							<View>
								<MundoText message={messages.safety} />
								<MundoInput
									onChangeText={(safty) =>
										onChangeSafety(safty)
									}
									value={safety}
								/>
							</View>
							<MundoText message={messages.task} />
							<MundoInput
								onChangeText={(tsk) => onChangeTask(tsk)}
								value={task}
							/>
						</View>
						<View style={[styles.topMargin20, styles.leftMargin20]}>
							<MundoText message={messages.materials} />
							<MundoInput
								onChangeText={(mats) => onChangeMaterials(mats)}
								value={materials}
							/>
							<MundoText message={messages.equipment} />
							<MundoInput
								onChangeText={(eq) => onChangeEquipment(eq)}
								value={equipment}
							/>
						</View>
					</View>
					<View style={styles.topMargin20}>
						<MundoText
							styles={styles.boldText}
							message={messages.workPrep}
						/>
					</View>
					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							styles.topMargin20,
							{ zIndex: -1 },
						]}
					>
						<View>
							<MundoCheckBox
								title={messages.ignition}
								checked={ignition}
								onCheckedChanged={() =>
									onChangeIgnition(!ignition)
								}
							/>
						</View>
						<View style={styles.leftMargin20}>
							<MundoCheckBox
								title={messages.grounded}
								checked={earth}
								onCheckedChanged={() => onChangeEarth(!earth)}
							/>
						</View>
					</View>
					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							styles.topMargin30,
							{ zIndex: -1, paddingLeft: 150 },
						]}
					>
						{range(supportedChambers).map((num) => (
							<View
								key={`${num}`}
								style={{ width: 60, alignItems: 'center' }}
							>
								<MundoText message={`${num + 1}`} />
							</View>
						))}
					</View>
					<View
						style={[
							styles.containerFullPercentageWidth,
							{ zIndex: -1 },
						]}
					>
						<View
							style={[
								styles.containerFullWidthHorizontalNoPadding,
								{ alignItems: 'center', height: 50 },
							]}
						>
							<View style={{ width: 150 }}>
								<MundoText message={messages.entry} />
							</View>
							<View
								style={[
									{
										flexDirection: 'row',
									},
								]}
							>
								{range(supportedChambers).map((num) => (
									<View
										key={`${num}`}
										style={[
											{
												width: 60,
												alignItems: 'center',
											},
										]}
									>
										<MundoCheckBox
											checked={entry[num]}
											onCheckedChanged={() =>
												dispatchEntry({
													type: ETypes.EDIT,
													location: num,
													item: !entry[num],
												})
											}
										/>
									</View>
								))}
							</View>
						</View>
						<View
							style={[
								styles.containerFullWidthHorizontalNoPadding,
								{ alignItems: 'center', height: 50 },
							]}
						>
							<View style={{ width: 150 }}>
								<MundoText message={messages.preCleaned} />
							</View>
							{range(supportedChambers).map((num) => (
								<View
									key={`${num}`}
									style={[
										{
											width: 60,
											alignItems: 'center',
										},
									]}
								>
									<MundoCheckBox
										checked={preCleaned[num]}
										onCheckedChanged={() =>
											dispatchPreCleaned({
												type: ETypes.EDIT,
												location: num,
												item: !preCleaned[num],
											})
										}
									/>
								</View>
							))}
						</View>
						<View
							style={[
								styles.containerFullWidthHorizontalNoPadding,
								{ alignItems: 'center', height: 50 },
							]}
						>
							<View style={{ width: 150 }}>
								<MundoText message={messages.airOn} />
							</View>
							{range(supportedChambers).map((num) => (
								<View
									key={`${num}`}
									style={[
										{
											width: 60,
											alignItems: 'center',
										},
									]}
								>
									<MundoCheckBox
										checked={airOn[num]}
										onCheckedChanged={() =>
											dispatchAirOn({
												type: ETypes.EDIT,
												location: num,
												item: !airOn[num],
											})
										}
									/>
								</View>
							))}
						</View>
						<View
							style={[
								styles.containerFullWidthHorizontalNoPadding,
								{ alignItems: 'center', height: 50 },
							]}
						>
							<View style={{ width: 150 }}>
								<MundoText message={messages.suction} />
							</View>
							{range(supportedChambers).map((num) => (
								<View
									key={`${num}`}
									style={[
										{
											width: 60,
											alignItems: 'center',
										},
									]}
								>
									<MundoCheckBox
										checked={suc[num]}
										onCheckedChanged={() =>
											dispatchSuc({
												type: ETypes.EDIT,
												location: num,
												item: !suc[num],
											})
										}
									/>
								</View>
							))}
						</View>
					</View>
					<View style={styles.topMargin30}>
						<MundoText
							styles={styles.boldText}
							message={messages.freeExhib}
						/>
					</View>
					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							{ zIndex: -1 },
						]}
					>
						<View style={[styles.topMargin20, { zIndex: -1 }]}>
							<View>
								<MundoText message={messages.o2} />
								<MundoInput
									onChangeText={(oxy) => onChangeO2(+oxy)}
									value={`${o2}`}
									unit={'Vol.-%'}
									largeUnit
								/>
							</View>
							<View style={styles.topMargin10}>
								<MundoText message={messages.exp} />
								<MundoInput
									onChangeText={(ex) => onChangeExp(+ex)}
									value={`${exp}`}
									unit={'% UEG'}
									largeUnit
								/>
							</View>
						</View>
						<View
							style={[
								styles.topMargin20,
								styles.leftMargin20,
								{ zIndex: -1 },
							]}
						>
							<View>
								<MundoText message={messages.dmg} />
								<MundoInput
									onChangeText={(pol) => onChangeDmg(+pol)}
									value={`${dmg}`}
									unit={'ppm / mg/m³'}
									largeUnit
								/>
							</View>
							<View style={styles.topMargin10}>
								<MundoText message={messages.agw} />
								<MundoInput
									onChangeText={(a) => onChangeAGW(+a)}
									value={`${agw}`}
									unit={'ppm / mg/m³'}
									largeUnit
								/>
							</View>
						</View>
					</View>
					<View style={styles.topMargin30}>
						<MundoText
							styles={styles.boldText}
							message={messages.personalProtect}
						/>
					</View>
					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							{ zIndex: -1 },
						]}
					>
						<View
							style={[
								styles.containerHalfWidthNoPadding,
								{ zIndex: -1 },
							]}
						>
							<View style={styles.topMargin20}>
								<MundoCheckBox
									title={messages.safetyEQ}
									checked={safetyEQ}
									onCheckedChanged={() =>
										onChangeSafetyEQ(!safetyEQ)
									}
								/>
							</View>
							<View style={styles.topMargin20}>
								<MundoCheckBox
									title={messages.safetyRope}
									checked={safetyRope}
									onCheckedChanged={() =>
										onChangeSafetyRope(!safetyRope)
									}
								/>
							</View>
							<View style={styles.topMargin20}>
								<MundoCheckBox
									title={messages.pna}
									checked={pna}
									onCheckedChanged={() => onChangePNA(!pna)}
								/>
							</View>
							<View style={styles.topMargin20}>
								<MundoInput
									label={messages.clothing}
									value={clothing}
									onChangeText={(cloth) =>
										onChangeClothing(cloth)
									}
								/>
							</View>
							<View>
								<MundoInput
									label={messages.shoes}
									value={shoes}
									onChangeText={(shoe) => onChangeShoes(shoe)}
								/>
							</View>
						</View>
						<View
							style={[
								styles.containerHalfWidth,
								styles.leftMargin20,
							]}
						>
							<View>
								<MundoCheckBox
									title={messages.breathProtect}
									checked={breath}
									onCheckedChanged={() =>
										onChangeBreath(!breath)
									}
								/>
							</View>
							<View style={styles.topMargin20}>
								<MundoCheckBox
									title={messages.safetyGloves}
									checked={safetyGloves}
									onCheckedChanged={() =>
										onChangeSafetyGloves(!safetyGloves)
									}
								/>
							</View>
							<View style={styles.topMargin20}>
								<MundoCheckBox
									title={messages.safetyGoggles}
									checked={safetyGoggles}
									onCheckedChanged={() =>
										onChangeSafetyGoggles(!safetyGoggles)
									}
								/>
							</View>
							<View style={styles.topMargin20}>
								<MundoCheckBox
									title={messages.multiDevice}
									checked={multiDevice}
									onCheckedChanged={() =>
										onChangeMultiDevice(!multiDevice)
									}
								/>
							</View>
						</View>
					</View>
				</View>
				<FinishButtonGroup
					saveFunction={createTankEntranceButtonHandler}
					cancelFunction={history.goBack}
				/>
			</ScrollView>
		</View>
	);
};

export default TankEntrance;
