import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { alert } from '../../alert';
import { load, save } from '../../localStorage';

import { MSettings } from 'mango-utils-client';
import { GET_SETTINGS, SAVE_SETTINGS } from './queries';

const SETTINGS_KEY = 'settings';

/**
 * useSettings Hook
 */
export default function useSettings() {
	const [localSettings, onChangeLocalSettings] = useState(new MSettings());
	const [loading, updateLoading] = useState(true);
	/**
	 * query to update. called on active changes
	 */
	const [updateSettings] = useMutation(SAVE_SETTINGS, {
		onCompleted(response) {
			if (response) {
				onChangeLocalSettings(new MSettings(response.saveSettings));
				updateLoading(false);
			}
		},
	});
	/**
	 * query to load from server (called on start of hook)
	 */
	const { refetch } = useQuery<{ settings: MSettings }>(GET_SETTINGS, {
		// pollInterval: 60000,
		onError() {
			loadFromLocalStorage();
		},
		onCompleted(data) {
			if (data && data.settings) {
				if (
					JSON.stringify(data.settings) !==
					JSON.stringify(localSettings)
				) {
					onChangeLocalSettings(
						new MSettings(
							JSON.parse(JSON.stringify(data.settings)),
						),
					);
				}
				updateLoading(false);
			} else {
				// somehow a complete 404 isn't triggering a error so this fallback for no response has to be used
				loadFromLocalStorage();
			}
		},
	});
	/**
	 * load settings from local storage
	 */
	const loadFromLocalStorage = () => {
		load(SETTINGS_KEY)
			.then((localResponse: string) => {
				onChangeLocalSettings(new MSettings(JSON.parse(localResponse)));
				updateLoading(false);
				console.log('used Local Settings');
			})
			.catch((execption) => {
				// Dieser Fall tritt ein wenn die App noch nie auf dem Gerät geöffnet wurde und keine DB Verbindung existiert
				// ! DIESER FALL SOLLTE KEIN CATCH WERFEN
				console.error(execption);
			});
	};
	/**
	 * function to call save with catch
	 */
	const saveToLocal = useCallback((settings: MSettings) => {
		save(SETTINGS_KEY, JSON.stringify(settings)).catch((e) =>
			alert('failed to save settings locally', `${e}`, 'error'),
		);
	}, []);
	/**
	 * Effect to handle local change of Settings (after creation, load or update)
	 */
	useEffect(() => {
		// check if settings are non-trivial // * stringcompare works
		if (
			localSettings &&
			JSON.stringify(localSettings) !== JSON.stringify(new MSettings())
		) {
			saveToLocal(localSettings);
		}
	}, [localSettings, saveToLocal]);
	/**
	 * function to post changes to this hook
	 * @param settings settings to create / update
	 */
	const onChangeSettings = (settings: MSettings) => {
		updateLoading(true);
		onChangeLocalSettings(settings);
		updateSettings({ variables: { settings } }).catch((e) => {
			console.error(e);
			alert(
				'failed to save settings in db',
				'A local save will be generated, but overwritten if the server has settings saved',
				'error',
			);
		});
	};
	// [MSettings, (settings: MSettings) => void, boolean]
	return {
		settings: localSettings,
		onChangeSettings,
		loading,
		refetch,
	};
}

export { SettingsContext } from './context/settingsContext';
export { SettingsProvider } from './provider/settingsProvider';
