import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { useQuery } from '@apollo/client';
import { MModuleDescription, MModuleInvoiceSettings } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import LoadingIndicator from '../../../components/LoadingIndicator';
import OrderList from '../../../container/Order/List';
import { pickTableContentExtension } from '../../../Plugins/VehicleCleaning/extensions/TableContent';
import { pickTableHeaderExtension } from '../../../Plugins/VehicleCleaning/extensions/TableHeader';
import { useStyle } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { openDocs } from '../../../utilities/openDocs';
import { useParams } from '../../../utilities/routing';
import { IDashboardProps } from '../../Dashboard/props';
import { GET_INVOICE_SETTINGS } from './queries';

const InvoiceDashboard: FC<IDashboardProps> = (props) => {
	const styles = useStyle();
	const { pipelineId } = useParams<{ pipelineId: string }>();
	const [invoiceSettings, onChangeInvoiceSettings] = useState(
		new MModuleInvoiceSettings(),
	);
	// load settings
	const { loading: loadingSettings } = useQuery(GET_INVOICE_SETTINGS, {
		variables: {
			pipelineId,
		},
		onCompleted: (response: {
			moduleInvoiceSettings: MModuleInvoiceSettings;
		}) => {
			const settings = response.moduleInvoiceSettings;

			onChangeInvoiceSettings(settings);
		},
	});

	if (loadingSettings || !props.pipeline) {
		return <LoadingIndicator />;
	}

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={transformModuleTitle(
							props.pipeline.modules.find(
								(m) => m.identifier === 'invoice',
							) || new MModuleDescription(),
							true,
						)}
						styles={styles.headerText}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/module/invoice?id=rechnung')
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList
						state={'invoice'}
						pipeline={pipelineId}
						noFilter
						noHeader
						tableConfig={invoiceSettings.dashboardFields}
						additionalTableContent={pickTableContentExtension}
						additionalTableHeader={pickTableHeaderExtension}
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default InvoiceDashboard;
