import gql from 'graphql-tag';

export const tExtractor = (data: any) => data.tArticle;

export const GET_TARTICLE = gql`
	query getTArticle($id: String!) {
		tArticle(id: $id) {
			id
			_id
			title {
				message
				lang
			}
			short
			description {
				message
				lang
			}
			price
			tax
			unit
			dataUnit {
				message
				lang
			}
			subArticles {
				id
				_id
				title {
					message
					lang
				}
				short
			}
			tags
			essentials
			mandatory
			type
			purpose
			cost
			billing
			ecd
			deliveryNote
			extra
		}
	}
`;

export const SAVE_TARTICLE = gql`
	mutation saveTArticle($article: TArticleInput!) {
		saveTArticle(article: $article) {
			id
			_id
		}
	}
`;

export const tQueries = {
	article: GET_TARTICLE,
	save: SAVE_TARTICLE,
};
