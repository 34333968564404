import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MundoButton, MundoText } from '../../../../components/elements';
import { useStyle } from '../../../../utilities/hooks/styles';
import { downloadTextAsFile } from '../../utilities/downloadTextAsFile';
import Articles from '../Articles';
import Profit from '../Profit';
import messages from './messages';

export const StatisticsDashboard: FC = () => {
	const styles = useStyle();

	const [mode, onChangeMode] = useState('profit');

	const [exportText, onChangeExport] = useState<string>();

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.header}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => console.log('TODO')}
					/>
				</View>
			</View>
			<ScrollView>
				<View
					style={[
						styles.spacedContainer,
						styles.horizontalLayout,
						{ justifyContent: 'space-between' },
					]}
				>
					<View style={styles.headerTitleContainer}>
						<MundoButton
							onPress={() => onChangeMode('profit')}
							title={messages.profit}
						/>
						<MundoButton
							onPress={() => onChangeMode('articles')}
							title={messages.articles}
						/>
					</View>
					<View>
						<MundoButton
							onPress={() => console.log('asdf')}
							title={messages.pdf}
							disabled
						/>
						<MundoButton
							onPress={() => {
								let text = 'no export selected';
								if (exportText) {
									text = exportText;
								}
								downloadTextAsFile(text, mode + Date.now());
							}}
							title={messages.csv}
							disabled={!exportText || mode !== 'profit'}
						/>
					</View>
				</View>
				{mode === 'profit' && (
					<Profit onChangeExport={onChangeExport} />
				)}
				{mode === 'articles' && (
					<Articles onChangeExport={onChangeExport} />
				)}
			</ScrollView>
		</View>
	);
};
