import React, { FC, memo } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';
import { useHistory } from 'react-router-dom';

import icons from '../../../assets';
import { MundoButton, MundoText } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { useUsers } from '../../../utilities/hooks/user';
import { openDocs } from '../../../utilities/openDocs';
import messages from './messages';

const UserList: FC = memo(() => {
	const styles = useStyle();
	const history = useHistory();
	const { orderBy, mode, changeSort } = useSort();
	const {
		users,
		loading,
		loadMore /*, onChangeFilter*/,
		loadMoreActive,
	} = useUsers({ skip: false, orderBy });

	return (
		<View
			{...{ dataSet: { cy: 'UserList' } }}
			style={styles.containerFullResolution}
		>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/settings?id=benutzer')
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoTable
						entrys={[
							{ identifier: 'id', header: messages.id, size: 6 },
							{
								identifier: 'title',
								header: messages.columnTitle,
								size: 25,
							},
							{ identifier: 'arrow', size: 4 },
						]}
						rows={users.map((user) => {
							return {
								dataSet: { cy: user.title },
								key: user._id,
								values: [
									{
										identifier: 'id',
										content: <Text>{user.id}</Text>,
									},
									{
										identifier: 'title',
										content: <Text>{user.title}</Text>,
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								onPress: () =>
									history.push(`edit/user/${user._id}`),
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
});

export default UserList;
