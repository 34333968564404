import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../utilities/constants';

const orderCardListStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		container: {
			display: 'flex',
			flexDirection: 'column',
			borderStyle: 'solid',
			borderRadius: 5,
			borderWidth: 1,
			marginRight: 10,
			borderColor: theme.backgroundSecondaryColor,
		},
		laneButton: {
			backgroundColor: 'transparent',
			borderWidth: 0,
			padding: 0,
			margin: 0,
		},
		headerTitle: {
			color: theme.textMainColor,
			fontSize: 15,
			fontWeight: '500',
		},
	});

export default orderCardListStyles;
