import { useQuery } from '@apollo/client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import {
	getTranslation,
	MModuleReceiptSettings,
	MArticle,
	MTranslation,
} from 'mango-utils-client';
import { ReceiptSettingsContext } from '../ReceiptSettings';
import { MundoText, MundoButton } from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { GET_RECEIPT_SETTINGS_ARTICLES } from '../queries';
import { receiptSettingsStyles } from '../../../../Plugins/VehicleCleaning/container/Receipt/Edit/styles';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useArray } from '../../../../utilities/hooks/array';
import { useStyle, useTheme } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import messages from '../messages';
import { GET_ARTICLES } from '../queries';
import { alert } from '../../../../utilities/alert';
import { destructData } from '../../../../utilities/dataFunctions';

export const ReceiptSettingsArticleList: FC = () => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { language } = useContext(LanguageContext);
	const intl = useIntl();
	const { onChangeReceiptSettings, pipeline } = useContext(
		ReceiptSettingsContext,
	);

	const [articles, dispatchArticleAction] = useArray<MArticle>();
	const [nextArticle, onChangeNextArticle] = useState(new MArticle());
	// get settings
	const { loading } = useQuery(GET_RECEIPT_SETTINGS_ARTICLES, {
		variables: {
			pipelineId: pipeline._id,
		},
		onCompleted: (data) => {
			if (data) {
				dispatchArticleAction({
					type: ETypes.RESET_TO,
					item: destructData<MModuleReceiptSettings>(
						data,
					).articles.map(
						(article: MArticle) => new MArticle(article),
					),
				});
			}
		},
	});

	useEffect(() => {
		onChangeReceiptSettings({ articles });
	}, [articles, onChangeReceiptSettings]);

	return (
		<View style={styles.fullSize}>
			<MundoText message={messages.articles} />
			{loading ? (
				<LoadingIndicator />
			) : (
				articles.map((article, index) => (
					<>
						<View
							key={`${article._id}`}
							style={[
								{
									zIndex: articles.length - index,
								},
								receiptSettingsStyles(theme).list,
								styles.horizontalLayout,
								styles.fullWidth,
								styles.elevated,
							]}
						>
							<View style={styles.padding}>
								<MundoText message={article.short} />
							</View>
							<MundoText
								styles={{ flex: 1 }}
								message={getTranslation(
									article.title,
									language,
								)}
							/>
							<View style={styles.horizontalLayout}>
								<MundoButton
									icon={'up'}
									disabled={index === 0}
									onPress={() =>
										dispatchArticleAction({
											type: ETypes.SWAP_LEFT,
											location: index,
										})
									}
								/>
								<MundoButton
									icon={'down'}
									disabled={index === articles.length - 1}
									onPress={() =>
										dispatchArticleAction({
											type: ETypes.SWAP_RIGHT,
											location: index,
										})
									}
								/>
								<MundoButton
									icon={'minus'}
									onPress={() =>
										dispatchArticleAction({
											type: ETypes.REMOVE,
											location: index,
										})
									}
								/>
							</View>
						</View>
						<View style={styles.thinSeparator} />
					</>
				))
			)}
			<View style={[receiptSettingsStyles(theme).list, styles.elevated]}>
				<MundoObjectTypeahead
					cyId={`article.input`}
					value={nextArticle}
					onChange={(newArticle) => {
						if (newArticle) {
							onChangeNextArticle({
								...newArticle,
								title: newArticle.title as MTranslation[],
							} as MArticle);
						}
					}}
					QUERY={GET_ARTICLES}
					multilanguage
				/>
				<MundoButton
					dataSet={{ cy: 'receipt.article.button' }}
					onPress={() => {
						if (
							nextArticle._id &&
							!articles.find((a) => nextArticle._id === a._id)
						) {
							dispatchArticleAction({
								type: ETypes.ADD,
								item: nextArticle,
							});
						} else {
							alert(
								capitalizeFirstWord(
									intl.formatMessage(messages.denied),
								),
								capitalizeFirstWord(
									intl.formatMessage(messages.duplicate),
								),
								'error',
							);
						}
					}}
					disabled={!nextArticle._id}
					title={messages.addArticle}
				/>
			</View>
		</View>
	);
};
