import {
	BrowserRouter as Router,
	Link,
	Redirect,
	Route,
	Switch,
	useHistory,
	useParams,
	withRouter,
} from 'react-router-dom';

export default {
	Link,
	Route,
	Redirect,
	Router,
	Switch,
	withRouter,
	useHistory,
	useParams,
};
export {
	Link,
	Route,
	Redirect,
	Router,
	Switch,
	withRouter,
	useHistory,
	useParams,
};
