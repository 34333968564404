import { IChange, MItem } from 'mango-utils-client';
import { ETypes } from '../../../../utilities/reducer/array.reducer';

export function handleChangeConsumption(
	change: IChange,
	log = true,
	dispatchHistoryAction: React.Dispatch<{
		type: ETypes;
		location?: number | undefined;
		item?: IChange | IChange[] | undefined;
	}>,
	dispatchItemAction: React.Dispatch<{
		type: ETypes;
		location?: number | undefined;
		item?: MItem | MItem[] | undefined;
	}>,
	items: MItem[],
) {
	if (log) {
		// log to history (so this will be reverseable)
		dispatchHistoryAction({
			type: ETypes.ADD,
			item: {
				title: change.title,
				description: change.description,
				current: change.current.map((c) => new MItem(c)),
				changed: change.changed.map((c) => new MItem(c)),
			},
		});
	}

	const next = items.map((previousItem) => {
		const current = change.current.find(
			(item) =>
				item.articleId === previousItem.articleId &&
				item.title === previousItem.title &&
				item.price === previousItem.price,
		);
		const changed = change.changed.find(
			(item) =>
				item.articleId === previousItem.articleId &&
				item.title === previousItem.title &&
				item.price === previousItem.price,
		);
		previousItem.amount +=
			(changed ? changed.amount : 0) - (current ? current.amount : 0);
		if (previousItem.amount > 0) {
			return previousItem;
		} else {
			return undefined;
		}
	});

	change.changed
		.filter(
			(changed) =>
				!items.find(
					(item) =>
						item.articleId === changed.articleId &&
						item.title === changed.title &&
						item.price === changed.price,
				),
		)
		.forEach((changed) =>
			next.unshift({
				...changed,
				amount: changed.amount || 1,
				checked: true,
			}),
		);

	dispatchItemAction({
		type: ETypes.RESET_TO,
		item: next.filter((n) => n) as MItem[],
	});
}
