import React, { FC, useContext } from 'react';
import { Image, ImageSourcePropType, ScrollView, View } from 'react-native';
import { useHistory } from 'react-router-dom';

import icons from '../../../assets';
import { MundoButton } from '../../../components/elements';
import MundoText from '../../../components/elements/MundoText';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import messages from './messages';

const UserProfile: FC = () => {
	const styles = useStyle();
	const history = useHistory();

	const { user } = useContext(AuthContext);

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.profile}
						styles={styles.headerText}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => openDocs('#/content/profile?id=profil')}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View>
						<MundoText message={messages.image} />
						<Image
							source={icons.profile as ImageSourcePropType}
							style={{ width: 64, height: 64 }}
						/>
					</View>
					<MundoText message={messages.title}>
						: {user.title}
					</MundoText>
					<MundoText message={messages.mail}>: {user.mail}</MundoText>
					<MundoText message={messages.telephone}>
						: {user.telephone}
					</MundoText>
					<MundoText message={messages.classification}>
						: {user.classification}
					</MundoText>
					<MundoText message={messages.groups}>{`: ${user.groups
						.map((group) => group.title)
						.join(', ')}`}</MundoText>
					<View
						style={[
							styles.containerFullWidthHorizontalNoPadding,
							styles.topMargin20,
						]}
					>
						<MundoButton
							onPress={() =>
								history.push(`/edit/profile/${user._id}`)
							}
							title={messages.edit}
						/>
						<MundoButton
							onPress={() =>
								history.push(`/passwordChange/${user._id}`)
							}
							title={messages.changePassword}
						/>
					</View>
				</View>
			</ScrollView>
		</View>
	);
};

export default UserProfile;
