import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const mundoButtonStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		button: {
			borderStyle: 'solid',
			borderWidth: 1,
			borderColor: theme.accentMainColor,
			backgroundColor: theme.accentMainColor,
			borderRadius: 4,
			padding: 5,
			margin: 5,
			alignItems: 'center',
			minHeight: 40,
			justifyContent: 'center',
			zIndex: 2,
		},
		buttonCheckbox: {
			borderStyle: 'solid',
			borderWidth: 1,
			borderColor: theme.accentMainColor,
			backgroundColor: theme.accentMainColor,
			borderRadius: 4,
			padding: 0,
			margin: 0,
			alignItems: 'center',
			minHeight: 20,
			justifyContent: 'center',
			zIndex: 2,
		},
		buttonSmall: {
			borderStyle: 'solid',
			borderWidth: 1,
			borderColor: theme.accentMainColor,
			backgroundColor: theme.accentMainColor,
			borderRadius: 4,
			padding: 2,
			alignItems: 'center',
			minHeight: 24,
			justifyContent: 'center',
			zIndex: 2,
		},
		horizontal: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		vertical: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		buttonIconContainer: {
			paddingRight: 10,
		},
		buttonIconContainerVertical: {
			paddingRight: 0,
		},
	});

export default mundoButtonStyles;
