import { gql } from '@apollo/client/core';

export const CREDIT_OUT = gql`
	mutation CREDIT_OUT($invoice: InvoiceInput!) {
		saveInvoice(invoice: $invoice) {
			_id
		}
	}
`;

export const CREDIT_PDFGEN = gql`
	mutation cpdf($invoiceId: String!) {
		createCredit(invoiceId: $invoiceId)
	}
`;

export const GET_CUSTOMERS = gql`
	query($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;

export const GET_CUSTOMER = gql`
	query($id: String!) {
		customer(id: $id) {
			id
			_id
			title
			externalId
			address {
				street
				city
				country
				zipCode
				tags
			}
			billingAddress {
				street
				city
				country
				zipCode
				tags
			}
			taxNr
			tags
			ustID
			allegiance
			defaultPaymentTime
			defaultPaymentMethod {
				_id
				id
				title
			}
			disabled
			discounts
		}
	}
`;
