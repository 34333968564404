import { gql } from '@apollo/client';

export const GET_INVOICE_STATS = gql`
	query(
		$from: Float!
		$to: Float!
		$interval: Float!
		$entrys: Float!
		$selectedEntities: String!
		$globalFilter: Boolean!
		$entityType: String!
		$past: Boolean
	) {
		invoiceStatistics(
			from: $from
			to: $to
			interval: $interval
			entrys: $entrys
			selectedEntities: $selectedEntities
			globalFilter: $globalFilter
			past: $past
			entityType: $entityType
		) {
			general {
				x
				y
				interval
			}
			byRef {
				ref {
					_id
					id
				}
				data {
					x
					y
					interval
				}
			}
		}
	}
`;

export const GET_INVOICE_STATS2 = gql`
	query(
		$from: Float!
		$to: Float!
		$interval: Float!
		$entrys: Float!
		$selectedEntities: String!
		$globalFilter: Boolean!
		$entityType: String!
		$past: Boolean
	) {
		invoiceStatisticsPast(
			from: $from
			to: $to
			interval: $interval
			entrys: $entrys
			selectedEntities: $selectedEntities
			globalFilter: $globalFilter
			past: $past
			entityType: $entityType
		) {
			general {
				x
				y
				interval
			}
			byRef {
				ref {
					_id
					id
				}
				data {
					x
					y
					interval
				}
			}
		}
	}
`;
