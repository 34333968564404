import gql from 'graphql-tag';

export const GET_PAYMENTMETHOD = gql`
	query getPaymentMethod($id: String!) {
		paymentMethod(id: $id) {
			_id
			id
			title
			surcharge
			paymentTime
			description
			cashback
			cashbackAmount
			cashbackTime
			tags
			color
			customer {
				id
				title
				_id
				address {
					street
					city
					country
					zipCode
					tags
				}
				billingAddress {
					street
					city
					country
					zipCode
					tags
				}
			}
		}
	}
`;

export const SAVE_PAYMENTMETHOD = gql`
	mutation savePaymentMethod($paymentMethod: PaymentMethodInput!) {
		savePaymentMethod(paymentMethod: $paymentMethod) {
			_id
			id
		}
	}
`;

export const DELETE_PAYMENTMETHOD = gql`
	mutation deletePaymentMethod($id: String!) {
		deletePaymentMethod(id: $id) {
			id
		}
	}
`;

export const CUSTOMERS = gql`
	query($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;

export const GET_CUSTOMER = gql`
	query getCustomer($id: String!) {
		customer(id: $id) {
			id
			_id
			title
			externalId
			address {
				street
				city
				country
				zipCode
				tags
			}
			billingAddress {
				street
				city
				country
				zipCode
				tags
			}
			taxNr
			tags
			ustID
			allegiance
			disabled
		}
	}
`;
