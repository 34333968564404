import { gql } from '@apollo/client';

export const GET_ARTICLE_STATS = gql`
	query(
		$from: Float!
		$to: Float!
		$interval: Float!
		$entrys: Float!
		$selectedArticles: String!
		$past: Boolean
	) {
		articleStatistics(
			from: $from
			to: $to
			interval: $interval
			entrys: $entrys
			selectedArticles: $selectedArticles
			past: $past
		) {
			general {
				x
				y
				interval
			}
			byRef {
				ref {
					_id
					id
				}
				data {
					x
					y
					interval
				}
			}
		}
	}
`;
