import React, { FC, memo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Text } from 'react-native';

import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import { IMundoText } from './props';
import mundoTextStyles from './styles';

const MundoText: FC<IMundoText> = memo(
	({
		message,
		bold,
		styles,
		isButtonText,
		children,
		dataSet,
		maxLength,
		...passThrough
	}) => {
		const { theme } = useTheme();
		const globalStyles = useStyle();
		const intl = useIntl();

		const text = message
			? typeof message === 'string'
				? (message as string)
				: capitalizeFirstWord(
						intl.formatMessage(message as MessageDescriptor),
				  )
			: '';

		return (
			<Text
				{...{ dataSet }}
				style={[
					bold
						? mundoTextStyles(theme).boldText
						: mundoTextStyles(theme).text,
					isButtonText && globalStyles.buttonDefaultTextColor,
					styles,
				]}
				{...passThrough}
			>
				{text && isButtonText
					? text
							.substring(0, maxLength ? maxLength : text.length)
							.toUpperCase()
					: text.substring(0, maxLength ? maxLength : text.length)}
				{children}
			</Text>
		);
	},
);

export default MundoText;
