import { defineMessages } from 'react-intl';

export const scope = 'mango.container.GroupInvoiceList';

export default defineMessages({
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	length: {
		id: `${scope}.length`,
		defaultMessage: 'length',
	},
	startDate: {
		id: `${scope}.startDate`,
		defaultMessage: 'start date',
	},
	endDate: {
		id: `${scope}.endDate`,
		defaultMessage: 'end date',
	},
	dueDate: {
		id: `${scope}.dueDate`,
		defaultMessage: 'due date',
	},
	grossSum: {
		id: `${scope}.grossSum`,
		defaultMessage: 'gross sum',
	},
	taxSum: {
		id: `${scope}.taxSum`,
		defaultMessage: 'tax sum',
	},
	netSum: {
		id: `${scope}.netSum`,
		defaultMessage: 'net sum',
	},
	payment: {
		id: `${scope}.payment`,
		defaultMessage: 'payment method',
	},
	address: {
		id: `${scope}.address`,
		defaultMessage: 'address',
	},
	pdf: {
		id: `${scope}.pdf`,
		defaultMessage: 'PDF',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'group invoices',
	},
});
