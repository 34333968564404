import { defineMessages } from 'react-intl';

export const scope = 'mango.components.Register';
export const errorScope = 'error';

export default defineMessages({
	user: {
		id: `${scope}.user.username`,
		defaultMessage: 'set your username',
	},
	company: {
		id: `${scope}.user.company`,
		defaultMessage: 'company',
	},
	password: {
		id: `${scope}.user.password`,
		defaultMessage: 'password',
	},
	passwordConfirm: {
		id: `${scope}.user.password.confirm`,
		defaultMessage: 'confirm password',
	},
	passwordMissmatch: {
		id: `${scope}.user.password.missmatch`,
		defaultMessage: "the passwords don't match",
	},
	mail: {
		id: `${scope}.user.mail`,
		defaultMessage: 'e-mail address',
	},
	mailFormat: {
		id: `${scope}.user.mail.format`,
		defaultMessage: 'the e-mail format is incorrect',
	},
	register: {
		id: `${scope}.auth.register`,
		defaultMessage: 'register',
	},
	incompleteCred: {
		id: `${errorScope}.incompleteCred`,
		defaultMessage: 'your credentials are incomplete',
	},
	plsFill: {
		id: `${errorScope}.plsFill`,
		defaultMessage:
			'please fill in the username, password, and mail fields',
	},
	credTaken: {
		id: `${errorScope}.credTaken`,
		defaultMessage: 'your username/e-mail is already taken',
	},
	loginEnabled: {
		id: `${errorScope}.loginEnabled`,
		defaultMessage: 'you can log in now',
	},
	error: {
		id: `${errorScope}.error`,
		defaultMessage: 'error',
	},
});
