import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
	query($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;
