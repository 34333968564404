import React, { FC, memo } from 'react';
import { View } from 'react-native';

import { MAddress } from 'mango-utils-client';
import useSettings from '../../utilities/hooks/settings';
import { useStyle } from '../../utilities/hooks/styles';
import CountryPicker from '../CountryPicker';
import MundoInput from '../elements/MundoInput';
import MundoText from '../elements/MundoText';
import messages from './messages';
import { IAddressEdit } from './props';

const AddressEdit: FC<IAddressEdit> = memo((props) => {
	const styles = useStyle();
	const { address, setAddress, label } = props;
	const { settings } = useSettings();

	const update = (input: Partial<MAddress>) => {
		const currentAddress = new MAddress(address);
		Object.assign(currentAddress, input);
		setAddress(currentAddress);
	};

	const checkCityInput = (input: any) => {
		const regex = new RegExp(/^[a-zA-ZäÄüÜöÖß ]*$/);

		if (!input.match(regex)) {
			return messages.cityError;
		}
		return;
	};

	return (
		<View style={[styles.containerHalfWidth, props.styles]}>
			<MundoText message={label} />
			<MundoInput
				dataSet={{ cy: `${props.cyId}.street` }}
				style={props.inputStyles}
				label={messages.street}
				onChangeText={(street) => update({ street })}
				value={address.street}
			/>
			<MundoInput
				dataSet={{ cy: `${props.cyId}.city` }}
				style={props.inputStyles}
				label={messages.city}
				onChangeText={(city) => update({ city })}
				value={address.city}
				customCheckInvalidity={checkCityInput}
			/>
			<MundoInput
				dataSet={{ cy: `${props.cyId}.zip` }}
				style={props.inputStyles}
				label={messages.zipCode}
				onChangeText={(zipCode) => update({ zipCode })}
				value={address.zipCode}
			/>
			<CountryPicker
				cyId={props.cyId}
				value={address.country}
				onChange={(country) => update({ country })}
				countries={
					settings.countries.length ? settings.countries : undefined
				}
			/>
		</View>
	);
});

export default AddressEdit;
