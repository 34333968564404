import React, { FC, memo, useContext } from 'react';
import { Text, View } from 'react-native';

import { IChange, priceFormatter } from 'mango-utils-client';
import { useIntl } from 'react-intl';
import { MundoButton, MundoText } from '../../../../components/elements';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle, useTheme } from '../../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import { getTranslation } from '../../../../utilities/translations';
import messages from '../messages';
import invoiceStyles from '../styles';

interface IPossiblePackageProps {
	pack: IChange;
	cyId?: string;
	consumeChange: (change: IChange, log?: any) => void;
}

export const PossiblePackage: FC<IPossiblePackageProps> = memo(
	({ pack, cyId, consumeChange }) => {
		const intl = useIntl();
		const styles = useStyle();
		const { theme } = useTheme();
		const { language } = useContext(LanguageContext);
		return (
			<View style={invoiceStyles(theme).packageCard}>
				<Text
					{...{ dataSet: { cy: `${cyId}.header` } }}
					style={{ textAlign: 'center', fontWeight: 'bold' }}
				>
					{getTranslation(pack.title, language)}
				</Text>
				<View style={[styles.fullSize, styles.topMargin10]}>
					<View style={styles.wideGroupTable}>
						<View
							style={[
								styles.wideGroupTableColumn30,
								styles.wideGroupTableColumnHeader,
							]}
						/>
						<View
							style={[
								styles.wideGroupTableColumn15NoGrow,
								styles.wideGroupTableColumnHeader,
							]}
						>
							<MundoText
								styles={{ fontWeight: 'bold' }}
								dataSet={{ cy: `${cyId}.amount` }}
							>
								{`${intl.formatMessage(messages.amount)}`}
							</MundoText>
						</View>
						<View
							style={[
								styles.wideGroupTableColumn15NoGrow,
								styles.wideGroupTableColumnHeader,
							]}
						>
							<MundoText
								styles={{ fontWeight: 'bold' }}
								dataSet={{ cy: `${cyId}.price` }}
							>
								{`${intl.formatMessage(messages.price)}`}
							</MundoText>
						</View>
						<View
							style={[
								styles.wideGroupTableColumn15,
								styles.wideGroupTableColumnHeader,
							]}
						>
							<MundoText
								styles={{ fontWeight: 'bold' }}
								dataSet={{ cy: `${cyId}.total` }}
							>
								{`${intl.formatMessage(messages.total)}`}
							</MundoText>
						</View>
					</View>
					<View style={styles.thinSeparator} />
					{pack.current &&
						pack.current.map((i) => (
							<View
								style={[styles.wideGroupTable]}
								key={i.articleId}
							>
								<View style={styles.wideGroupTableColumn30}>
									<Text>{`${getTranslation(
										i.title,
										language,
									)}`}</Text>
								</View>
								<View
									style={styles.wideGroupTableColumn15NoGrow}
								>
									<Text>{`${i.amount}`}</Text>
								</View>
								<View
									style={styles.wideGroupTableColumn15NoGrow}
								>
									<MundoText
										dataSet={{
											cy: `${cyId}.current.${getTranslation(
												i.title,
												language,
											)}.price`,
										}}
									>
										{`${priceFormatter(i.price / 1000)}€`}
									</MundoText>
								</View>
								<View style={styles.wideGroupTableColumn15}>
									<MundoText
										dataSet={{
											cy: `${cyId}.current.${getTranslation(
												i.title,
												language,
											)}.total`,
										}}
									>
										{`${priceFormatter(
											(i.price / 1000) * i.amount,
										)} €`}
									</MundoText>
								</View>
							</View>
						))}
					<View style={[styles.fullSize, styles.wideGroupTable]}>
						<View
							style={[
								styles.wideGroupTableColumn2,
								styles.rightMargin10,
							]}
						/>
						<View style={[styles.leftMargin10, { flex: 1 }]}>
							{pack.changed && (
								<>
									<View style={styles.wideGroupTable}>
										<View
											style={[
												styles.wideGroupTableColumn30,
												styles.wideGroupTableColumnHeader,
											]}
										/>
										<View
											style={[
												styles.wideGroupTableColumn15NoGrow,
												styles.wideGroupTableColumnHeader,
											]}
										>
											<MundoText
												styles={{ fontWeight: 'bold' }}
											>
												{`${capitalizeFirstWord(
													intl.formatMessage(
														messages.amount,
													),
												)}`}
											</MundoText>
										</View>
										<View
											style={[
												styles.wideGroupTableColumn15NoGrow,
												styles.wideGroupTableColumnHeader,
											]}
										>
											<MundoText
												styles={{ fontWeight: 'bold' }}
											>
												{`${capitalizeFirstWord(
													intl.formatMessage(
														messages.price,
													),
												)}`}
											</MundoText>
										</View>
										<View
											style={[
												styles.wideGroupTableColumn15,
												styles.wideGroupTableColumnHeader,
											]}
										>
											<MundoText
												styles={{ fontWeight: 'bold' }}
											>
												{`${capitalizeFirstWord(
													intl.formatMessage(
														messages.total,
													),
												)}`}
											</MundoText>
										</View>
									</View>
									<View style={styles.thinSeparator} />
								</>
							)}
							{pack.changed &&
								pack.changed.map((i, index) => (
									<View
										style={[styles.wideGroupTable]}
										key={index}
									>
										<View
											style={
												styles.wideGroupTableColumn30
											}
										>
											<Text>{`${getTranslation(
												i.title,
												language,
											)}`}</Text>
										</View>
										<View
											style={
												styles.wideGroupTableColumn15NoGrow
											}
										>
											<Text>{`${i.amount}`}</Text>
										</View>
										<View
											style={
												styles.wideGroupTableColumn15NoGrow
											}
										>
											<MundoText
												dataSet={{
													cy: `${cyId}.changed.${getTranslation(
														i.title,
														language,
													)}.price`,
												}}
											>
												{`${priceFormatter(
													i.price / 1000,
												)}€`}
											</MundoText>
										</View>
										<View
											style={
												styles.wideGroupTableColumn15
											}
										>
											<MundoText
												dataSet={{
													cy: `${cyId}.changed.${getTranslation(
														i.title,
														language,
													)}.total`,
												}}
											>
												{`${priceFormatter(
													(i.price / 1000) * i.amount,
												)} €`}
											</MundoText>
										</View>
									</View>
								))}
						</View>
						<View style={styles.wideGroupTableColumnNoFlex}>
							<MundoButton
								title={messages.apply}
								onPress={() => consumeChange(pack)}
							/>
						</View>
					</View>
				</View>
			</View>
		);
	},
);
