import gql from 'graphql-tag';

export const GET_PAYMENTMETHODS = gql`
	query getPaymentMethods(
		$paymentMethod: PaymentMethodInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		paymentMethods(
			paymentMethod: $paymentMethod
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			_id
			id
			title
		}
	}
`;
