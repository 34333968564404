import React from 'react';
import lightTheme from '../../../../theme/light';
import { IThemeConstants } from '../../../constants';
import styles from '../../../styles';

const StyleContext = React.createContext({
	theme: lightTheme,
	setTheme: (theme: IThemeConstants) => {
		// This will be passed from GQL
		console.log(theme);
	},
	styles: styles(lightTheme),
	refreshTheme: () => {
		console.log('refresh Theme undefined');
	},
});

export default StyleContext;
