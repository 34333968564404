import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ScrollView, View } from 'react-native';

import {
	ESubState,
	EUserClassification,
	MItem,
	MModuleDescription,
	MOrder,
	MPipeline,
	MState,
} from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import EditItem from '../../../components/Item/Edit';
import ItemView from '../../../components/Item/View';
import LoadingIndicator from '../../../components/LoadingIndicator';
import StateEdit from '../../../components/State/Edit';
import StateView from '../../../components/State/View';
import { confirm } from '../../../utilities/alert';
import { destructData } from '../../../utilities/dataFunctions';
import { useArray } from '../../../utilities/hooks/array';
import { AuthContext } from '../../../utilities/hooks/auth';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { useHistory, useParams } from '../../../utilities/routing';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import messages from './messages';
import {
	CANCEL_ORDER,
	EXPOSE_ORDER_TO_TERMINAL,
	GET_ORDER,
	GET_STATES,
	GET_TSTATES,
	RESET_ORDER_TO,
	SAVE_STATES,
	SAVE_TSTATES,
} from './queries';
import { StateButtons } from './components/StateButtons';

const OrderOverview: FC = () => {
	const styles = useStyle();
	const { id } = useParams<{ id: string }>();
	const { settings } = useContext(SettingsContext);
	const { isAdmin } = useContext(AuthContext);
	const history = useHistory();
	const intl = useIntl();

	const [pipeline, onChangePipeline] = useState<MPipeline>();
	const [order, onChangeOrder] = useState<MOrder>();
	const [states, onChangeStates] = useState([] as MState[]);
	const [editMode /*, onChangeEditMode*/] = useState(false);
	const [buttons, dispatchButtonAction] = useArray<{
		title: string;
		targetIndex: number;
		active: boolean;
	}>();
	const [currentIndex, onChangeCurrentIndex] = useState(0);

	useEffect(() => {
		const foundPipeline = settings.pipelines.find(
			(pipe) => pipe._id === order?.pipeline,
		);
		if (order?.pipeline && foundPipeline) {
			onChangePipeline(foundPipeline);
		} else if (order) {
			console.log(
				'this order does not connect to a living pipeline. pipeline: "',
				order.pipeline,
				'" contact Mundo-IT',
			);
		}
	}, [settings, order]);
	/**
	 * filter which override queries to use
	 * TODO fix this mess. this should be injected from plugin folder
	 * @param filterMode filter to work with
	 */
	const composeQueries = (filterMode: string) => {
		switch (filterMode) {
			case 'vehicleCleaning':
				return {
					saveQuery: SAVE_TSTATES,
					loadQuery: GET_TSTATES,
				};
			default:
				return null;
		}
	};
	// extensions // TODO: dehardcode
	const ext = composeQueries('vehicleCleaning');

	const { loading: LoadingStates, refetch: refetchState } = useQuery(
		ext ? ext.loadQuery : GET_STATES,
		{
			variables: { id },
			onCompleted: (data) => {
				onChangeStates(destructData<MState[]>(data));
			},
			notifyOnNetworkStatusChange: true,
		},
	);
	const { loading: LoadingOrder } = useQuery(GET_ORDER, {
		variables: { id },
		onCompleted: (data) => {
			onChangeOrder(destructData<MOrder>(data));
		},
	});
	const [saveStates] = useMutation(ext ? ext.saveQuery : SAVE_STATES, {
		onCompleted: () => history.goBack(),
	});
	const [resetOrderTo] = useMutation(RESET_ORDER_TO, {
		onCompleted: () => refetchState(),
	});
	const [cancelOrder] = useMutation(CANCEL_ORDER);
	const [exposeOrderToTerminal] = useMutation(EXPOSE_ORDER_TO_TERMINAL);

	const findStateIndex = useCallback(
		(module: MModuleDescription, mode: string) => {
			const title = module.identifier;
			const index = states.findIndex((s, i) => {
				const primary =
					s.subState === mode && s.title.split('::')[0] === title;

				let secondary = true;
				const resI = module.tags.indexOf('reusable');
				if (resI > -1 && pipeline) {
					const prev = findStateIndex(
						pipeline.modules.find(
							(mod) => mod.identifier === module.tags[resI + 1],
						) as MModuleDescription,
						ESubState.DONE,
					);
					secondary = -1 < prev && prev < i;
				}
				return primary && secondary;
			});
			return index;
		},
		[states, pipeline],
	);

	useEffect(() => {
		if (pipeline) {
			const nextButtons = pipeline.modules.map((module) => {
				return {
					title: module.identifier,
					targetIndex: findStateIndex(module, ESubState.DONE),
					active: -1 < findStateIndex(module, ESubState.READY),
				};
			});
			dispatchButtonAction({
				type: ETypes.RESET_TO,
				item: nextButtons,
			});
			const index = [...nextButtons]
				.reverse()
				.findIndex((b) => b.targetIndex !== -1);
			const bindex = index > -1 ? nextButtons.length - (index + 1) : 0;
			onChangeCurrentIndex(bindex);
		}
	}, [pipeline, states, findStateIndex, dispatchButtonAction]);

	const updateState = (newState: MState, stateIndex: number) => {
		const next = [...states];
		next.splice(stateIndex, 1, newState);
		onChangeStates(next);
	};

	const updateItem = (
		newItem: MItem,
		stateIndex: number,
		itemIndex: number,
	) => {
		const next = [...states];
		next[stateIndex].items.splice(itemIndex, 1, newItem);
		onChangeStates(next);
	};

	const loading = !buttons[currentIndex];
	// loading handling
	if (loading) {
		return <LoadingIndicator />;
	}
	// parse button index to vars
	const curStateIndex = buttons[currentIndex].targetIndex;
	const state = states[curStateIndex];
	// reloading handling
	if (!state || !order || LoadingOrder || LoadingStates) {
		return <LoadingIndicator />;
	}

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoText
						styles={styles.headerText}
						message={` ${order.id}`}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/orders?id=Übersicht')
						}
					/>
				</View>
			</View>
			<ScrollView>
				<StateButtons
					buttons={buttons}
					currentIndex={currentIndex}
					onChangeCurrentIndex={onChangeCurrentIndex}
				/>
				<View style={styles.spacedContainer}>
					<View style={styles.containerFullPercentageWidth}>
						{buttons.length > currentIndex &&
							buttons[currentIndex].targetIndex > -1 && (
								<View>
									{editMode ? (
										<StateEdit
											state={state}
											index={curStateIndex}
											onChange={(newState) =>
												updateState(
													newState,
													curStateIndex,
												)
											}
										/>
									) : (
										<StateView
											state={state}
											index={curStateIndex}
										/>
									)}
								</View>
							)}
					</View>
					<View
						style={[
							styles.thinSeparator,
							styles.topMargin10,
							styles.bottomMargin10,
						]}
					/>
					<View style={styles.buttonGroup}>
						<View style={styles.buttonGroupLeft}>
							{!!state.tags.length &&
								state.tags.map((tag) => {
									return (
										tag.split(':')[0] === 'pdf' && (
											<MundoButton
												title={tag.split(':')[1]}
												onPress={() =>
													history.push(
														`/pdf/${
															tag.split(':')[1]
														}/${id}`,
													)
												}
												disableOnClick
											/>
										)
									);
								})}
						</View>
					</View>
					<View
						style={[
							styles.thinSeparator,
							styles.topMargin10,
							styles.bottomMargin10,
						]}
					/>
					<View style={styles.containerFullWidth}>
						{buttons.length > currentIndex &&
							buttons[currentIndex].targetIndex > -1 && (
								<View>
									{state.items.map(
										(item: Partial<MItem>, index) => (
											<View key={`${index}`}>
												{editMode ? (
													<EditItem
														value={new MItem(item)}
														onChange={(newItem) =>
															updateItem(
																newItem,
																curStateIndex,
																index,
															)
														}
													/>
												) : (
													<ItemView
														value={new MItem(item)}
													/>
												)}
											</View>
										),
									)}
								</View>
							)}
					</View>
					<View style={styles.containerFullWidthHorizontalNoPadding}>
						{/* suspended <MundoButton
						title={editMode ? messages.cancel : messages.edit}
						onPress={() => onChangeEditMode(!editMode)}
						/>*/}
						{order.currentState !== 'rescinded' && (
							<>
								<MundoButton
									dataSet={{ cy: 'invoice.cancellate' }}
									title={messages.cancellate}
									onPress={() =>
										confirm(
											capitalizeFirstWord(
												intl.formatMessage(
													messages.cancellate,
												),
											),
											capitalizeFirstWord(
												intl.formatMessage(
													messages.cancellateDescr,
												),
											),
											async () => {
												await cancelOrder({
													variables: { id },
												});
												history.push(
													'/dash/' + order.pipeline,
												);
											},
											'question',
										)
									}
								/>
								{state &&
									currentIndex !== 0 &&
									state.subState === ESubState.DONE &&
									isAdmin && (
										<MundoButton
											title={messages.resetToHere}
											onPress={() =>
												confirm(
													capitalizeFirstWord(
														intl.formatMessage(
															messages.resetToHere,
														),
													),
													capitalizeFirstWord(
														intl.formatMessage(
															messages.resetDescr,
														),
													),
													async () => {
														if (
															order.tags.includes(
																'requiresCancellation',
															)
														) {
															await cancelOrder({
																variables: {
																	id,
																},
															});
														}
														await resetOrderTo({
															variables: {
																id,
																state:
																	state.title,
															},
														});
													},
													'question',
												)
											}
										/>
									)}
								{isAdmin &&
									!order.tags.find(
										(t) =>
											EUserClassification.TERMINAL === t,
									) && (
										<MundoButton
											title={messages.exposeToTerminal}
											onPress={() =>
												confirm(
													capitalizeFirstWord(
														intl.formatMessage(
															messages.exposeToTerminalQuestion,
														),
													),
													capitalizeFirstWord(
														intl.formatMessage(
															messages.exposeToTerminalInformation,
														),
													),
													async () => {
														await exposeOrderToTerminal(
															{
																variables: {
																	id,
																},
															},
														);
													},
													'question',
												)
											}
										/>
									)}
							</>
						)}
					</View>
				</View>
				<FinishButtonGroup
					saveFunction={() =>
						saveStates({ variables: { id, states } })
					}
					cancelFunction={() => history.goBack()}
				/>
			</ScrollView>
		</View>
	);
};

export default OrderOverview;
