import React, { FC, useContext, useState } from 'react';
import { Dimensions, View } from 'react-native';

import {
	getTranslation,
	MArticle,
	MBundledArticle,
	MTranslation,
	priceFormatter,
} from 'mango-utils-client';
import { useIntl } from 'react-intl';
import { alert } from '../../../utilities/alert';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { useStyle } from '../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import { MundoButton, MundoCheckBox } from '../../elements';
import MundoInput from '../../elements/MundoInput';
import MundoObjectTypeahead from '../../elements/MundoObjectTypeahead';
import MundoText from '../../elements/MundoText';
import FinishButtonGroup from '../../FinishButtonGroup';
import { IBundledArticleEditProps } from '../props';
import messages from './messages';
import { GET_ARTICLES } from './queries';

const BundledArticleEdit: FC<IBundledArticleEditProps> = ({
	onChange,
	bundle,
	changeEditMode,
}) => {
	const styles = useStyle();
	const [bundledArticle, onChangeBundel] = useState(bundle);
	const intl = useIntl();
	const { language } = useContext(LanguageContext);

	const update = (input: Partial<MBundledArticle>) => {
		const next = Object.assign({}, bundledArticle, input);
		onChangeBundel(next);
	};

	const [nextArticle, onChangeNextArticle] = useState<MArticle>();

	const saveButtonHandler = () => {
		onChange(bundledArticle);
		changeEditMode(false);
	};

	return (
		<View style={[styles.elevated, { flex: 1 }]}>
			<MundoText
				message={messages.articles}
				styles={styles.topMargin20}
			/>
			{bundledArticle.articles.map((article) => (
				<View
					style={[styles.horizontalLayout, styles.rightMargin20]}
					key={`${article._id}`}
				>
					<MundoText
						dataSet={{
							cy: `ab.content.${getTranslation(
								article.title,
								language,
							)}`,
						}}
						message={getTranslation(article.title, language)}
					/>
					<MundoButton
						icon={'minus'}
						onPress={() => {
							const articles = [...bundledArticle.articles];
							articles.splice(articles.indexOf(article, 1));
							update({ articles });
						}}
					/>
				</View>
			))}
			<View style={[styles.flexContainerAutoWrap, styles.elevated]}>
				<View style={styles.elevated}>
					<MundoObjectTypeahead
						cyId={'bundle.title.input'}
						value={nextArticle}
						placeholder={messages.article}
						onChange={(next) =>
							next
								? onChangeNextArticle(
										new MArticle({
											...next,
											title: next.title as MTranslation[],
										}),
								  )
								: onChangeNextArticle(undefined)
						}
						QUERY={GET_ARTICLES}
						multilanguage
					/>
				</View>
				<MundoButton
					dataSet={{ cy: 'bundle.addArticle.button' }}
					title={messages.addArticle}
					onPress={() => {
						const articles = [
							...bundledArticle.articles,
							new MArticle(nextArticle),
						];
						update({ articles });
						onChangeNextArticle(undefined);
					}}
					disabled={!nextArticle}
				/>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.verticalCenterAligned,
				]}
			>
				<View
					style={[
						styles.container40Width,
						{ width: Dimensions.get('window').width * 0.35 },
					]}
				>
					<MundoCheckBox
						cyId={'bundle.amount.checkbox'}
						checked={bundledArticle.useAmount || false}
						onCheckedChanged={(checked: boolean) =>
							update({ useAmount: checked })
						}
						title={messages.useFixedAmount}
					/>
				</View>
				<View
					style={[
						styles.containerHalfWidthNoPadding,
						{ width: Dimensions.get('window').width * 0.2 },
					]}
				/>
				<View
					style={[
						styles.containerHalfWidthNoPadding,
						{ width: Dimensions.get('window').width * 0.3 },
					]}
				>
					<MundoInput
						dataSet={{ cy: 'bundle.amount.input' }}
						label={messages.amount}
						onChangeText={(amount: string) =>
							update({ amount: +amount })
						}
						value={`${bundledArticle.amount}`}
						type={'numeric'}
						disabled={!bundledArticle.useAmount}
						length={5}
					/>
				</View>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.verticalCenterAligned,
				]}
			>
				<View
					style={[
						styles.container40Width,
						{ width: Dimensions.get('window').width * 0.35 },
					]}
				>
					<MundoCheckBox
						cyId={'bundle.underflow.checkbox'}
						checked={bundledArticle.useUnderflowPrice || false}
						onCheckedChanged={(checked: boolean) =>
							update({ useUnderflowPrice: checked })
						}
						title={messages.useUnderflow}
					/>
				</View>
				<View
					style={[
						styles.containerHalfWidthNoPadding,
						{ width: Dimensions.get('window').width * 0.2 },
					]}
				>
					<MundoInput
						dataSet={{ cy: 'bundle.lower.input' }}
						label={messages.lowerAmount}
						onChangeText={(lowerAmount: string) =>
							update({ lowerAmount: +lowerAmount })
						}
						value={`${bundledArticle.lowerAmount}`}
						type={'numeric'}
						disabled={bundledArticle.useAmount}
						length={5}
					/>
				</View>
				<View
					style={[
						styles.containerHalfWidthNoPadding,
						{ width: Dimensions.get('window').width * 0.3 },
					]}
				>
					<MundoInput
						dataSet={{ cy: 'bundle.underflow.input' }}
						label={messages.underflowPrice}
						unit={'€'}
						disabled={!bundledArticle.useUnderflowPrice}
						value={`${
							bundledArticle.underflowPrice > 0
								? priceFormatter(
										+bundledArticle.underflowPrice / 1000,
								  )
								: ''
						}`}
						onChangeText={(text: string) => {
							// convert price from readable string to milli Euro (format for saving in database)
							update({
								underflowPrice: +text.replace(/,|\./g, '') * 10,
							});
						}}
						length={6}
					/>
				</View>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.verticalCenterAligned,
				]}
			>
				<View
					style={[
						styles.container40Width,
						{ width: Dimensions.get('window').width * 0.35 },
					]}
				>
					<MundoCheckBox
						cyId={'bundle.overflow.checkbox'}
						checked={bundledArticle.useOverflowPrice || false}
						onCheckedChanged={(checked: boolean) =>
							update({ useOverflowPrice: checked })
						}
						title={messages.useOverflow}
					/>
				</View>
				<View
					style={[
						styles.containerHalfWidthNoPadding,
						{ width: Dimensions.get('window').width * 0.2 },
					]}
				>
					<MundoInput
						dataSet={{ cy: 'bundle.upper.input' }}
						label={messages.upperAmount}
						onChangeText={(upperAmount: string) =>
							update({ upperAmount: +upperAmount })
						}
						value={`${bundledArticle.upperAmount}`}
						type={'numeric'}
						disabled={bundledArticle.useAmount}
						length={5}
					/>
				</View>
				<View
					style={[
						styles.containerHalfWidthNoPadding,
						{ width: Dimensions.get('window').width * 0.3 },
					]}
				>
					<MundoInput
						dataSet={{ cy: 'bundle.overflow.input' }}
						label={messages.overflowPrice}
						unit={'€'}
						disabled={!bundledArticle.useOverflowPrice}
						value={`${
							bundledArticle.overflowPrice > 0
								? priceFormatter(
										+bundledArticle.overflowPrice / 1000,
								  )
								: ''
						}`}
						onChangeText={(text: string) => {
							// convert price from readable string to milli Euro (format for saving in database)
							update({
								overflowPrice: +text.replace(/,|\./g, '') * 10,
							});
						}}
						length={6}
					/>
				</View>
			</View>
			<FinishButtonGroup
				cyId={'bundle'}
				saveFunction={saveButtonHandler}
				saveDisabled={
					!(
						bundledArticle.articles.length &&
						bundledArticle.articles[0]._id
					)
				}
				onPressDisabled={() =>
					alert(
						'error',
						capitalizeFirstWord(
							intl.formatMessage(messages.articlesError),
						),
						'error',
					)
				}
			/>
		</View>
	);
};

export default BundledArticleEdit;
