import gql from 'graphql-tag';

export const DEXPORT_PREVIEW = gql`
	query dExportPreview($from: Float, $to: Float) {
		createDatevExportPreview(from: $from, to: $to) {
			net
			gross
			tax
			count
			date
		}
	}
`;

export const GET_DEXPORTS = gql`
	query getDExport($start: Int!, $count: Int!) {
		datevExports(start: $start, count: $count) {
			net
			gross
			tax
			count
			date
		}
	}
`;
