import gql from 'graphql-tag';

export const GET_ORDER = gql`
	query getOrder($id: String!) {
		order(id: $id) {
			id
			_id
			currentState
			subState
			date
			imported
			customer {
				id
				_id
				title
				address {
					street
					city
					country
					zipCode
					tags
				}
				billingAddress {
					street
					city
					country
					zipCode
					tags
				}
				tags
			}
			tags
		}
	}
`;

export const GET_ORDERS = gql`
	query getOrders(
		$order: OrderInput!
		$start: Int!
		$count: Int!
		$from: Float
		$to: Float
		$orderBy: String
	) {
		orders(
			order: $order
			start: $start
			count: $count
			from: $from
			to: $to
			orderBy: $orderBy
		) {
			_id
			id
			currentState
			subState
			pipeline
			customer {
				title
				defaultPaymentMethod {
					title
					color
				}
			}
			date
			tags
		}
	}
`;
