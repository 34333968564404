import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.tankEntrance';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'tank entrance',
	},
	generalInfo: {
		id: `${scope}.generalInfo`,
		defaultMessage: 'general information',
	},
	orderId: {
		id: `${scope}.orderId`,
		defaultMessage: 'order id',
	},
	license: {
		id: `${scope}.license`,
		defaultMessage: 'trailer license plate',
	},
	observer: {
		id: `${scope}.observer`,
		defaultMessage: 'observer',
	},
	worker: {
		id: `${scope}.worker`,
		defaultMessage: 'worker',
	},
	safety: {
		id: `${scope}.safety`,
		defaultMessage: 'security guard',
	},
	task: {
		id: `${scope}.task`,
		defaultMessage: 'task',
	},
	materials: {
		id: `${scope}.materials`,
		defaultMessage: 'materials',
	},
	equipment: {
		id: `${scope}.equipment`,
		defaultMessage: 'equipment',
	},
	workPrep: {
		id: `${scope}.workPrep`,
		defaultMessage: 'work preparation',
	},
	ignition: {
		id: `${scope}.ignition`,
		defaultMessage: 'check for ignition source',
	},
	grounded: {
		id: `${scope}.grounded`,
		defaultMessage: 'tank is grounded',
	},
	entry: {
		id: `${scope}.entry`,
		defaultMessage: 'entry in chamber',
	},
	preCleaned: {
		id: `${scope}.preCleaned`,
		defaultMessage: 'pre-cleaned',
	},
	airOn: {
		id: `${scope}.airOn`,
		defaultMessage: 'air on',
	},
	suction: {
		id: `${scope}.suction`,
		defaultMessage: 'suction on',
	},
	freeExhib: {
		id: `${scope}.freeExhib`,
		defaultMessage: 'free exhibitions',
	},
	o2: {
		id: `${scope}.o2`,
		defaultMessage: 'oxygen measure',
	},
	exp: {
		id: `${scope}.exp`,
		defaultMessage: 'explosion safety',
	},
	dmg: {
		id: `${scope}.dmg`,
		defaultMessage: 'pollutant concentration',
	},
	agw: {
		id: `${scope}.agw`,
		defaultMessage: 'max. agw',
	},
	personalProtect: {
		id: `${scope}.personalProtect`,
		defaultMessage: 'personal protection equipment',
	},
	safetyEQ: {
		id: `${scope}.safetyEQ`,
		defaultMessage: 'safety equipment',
	},
	safetyRope: {
		id: `${scope}.safetyRope`,
		defaultMessage: 'safety rope',
	},
	pna: {
		id: `${scope}.pna`,
		defaultMessage: 'pna',
	},
	clothing: {
		id: `${scope}.clothing`,
		defaultMessage: 'clothing',
	},
	shoes: {
		id: `${scope}.shoes`,
		defaultMessage: 'shoes',
	},
	breathProtect: {
		id: `${scope}.breathProtect`,
		defaultMessage: 'breathing protection',
	},
	safetyGloves: {
		id: `${scope}.safetyGloves`,
		defaultMessage: 'safety gloves',
	},
	safetyGoggles: {
		id: `${scope}.safetyGoggles`,
		defaultMessage: 'safety goggles',
	},
	multiDevice: {
		id: `${scope}.multiDevice`,
		defaultMessage: 'multi device',
	},
});
