import { ELanguages, MTranslation } from 'mango-utils-client';

export const updateTranslation = (
	text: string,
	lang: ELanguages,
	translations: MTranslation[],
): MTranslation[] => {
	const pivot = translations.find((element) => element.lang === lang);
	if (pivot) {
		pivot.message = text;
	} else {
		translations.push(new MTranslation({ lang, message: text }));
	}

	return translations;
};

export const getTranslation = (
	translations: MTranslation[] | undefined,
	language: ELanguages,
): string => {
	if (translations) {
		const translation = translations.find(
			(trans) => trans.lang === language,
		);
		const text = translation
			? translation.message
			: translations.find((trans) => trans.lang === ELanguages.de)
					?.message;
		return text || '';
	} else {
		return '';
	}
};
