import React, { FC } from 'react';
import { Text, View } from 'react-native';

import { MItem } from 'mango-utils-client';
import { useStyle } from '../../utilities/hooks/styles';
import { ETypes } from '../../utilities/reducer/array.reducer';
import { IRecordPageProps } from './props';

const RecordPage: FC<IRecordPageProps> = (props) => {
	const styles = useStyle();
	const { title, dispatchAction, items } = props;
	return (
		<View style={styles.fullSize}>
			<View style={styles.headerViewNoShadow}>
				<Text style={styles.headerSecondaryText}>{title}</Text>
			</View>
			{items.map((item, index) => (
				<props.itemComponent
					key={item.id}
					value={item}
					onChange={(newItem: MItem) => {
						items.splice(index, 1, newItem);
						dispatchAction({
							type: ETypes.EDIT,
							item: { title, items },
						});
					}}
				/>
			))}
		</View>
	);
};

export default RecordPage;
