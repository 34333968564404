import React, { FC } from 'react';
import { ScrollView, View } from 'react-native';

import { useStyle, useTheme } from '../../utilities/hooks/styles';
import { ETypes } from '../../utilities/reducer/array.reducer';
import { MundoButton } from '../elements';
import Page from '../RecordPage';
import PagesOverview from '../RecordPagesOverview';
import { IRecordingPagesProps } from './props';

const RecordPages: FC<IRecordingPagesProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { pages, dispatchAction, save, saveAndContinue } = props;
	const [siteIndex, onChangeSiteIndex] = React.useState(0);
	return (
		<View>
			<View>
				<ScrollView
					horizontal
					style={[
						styles.buttonGroupScrollviewHigh,
						styles.topMargin10,
					]}
				>
					<View style={styles.buttonGroupPagination}>
						<MundoButton
							icon={'left'}
							onPress={() => onChangeSiteIndex(siteIndex - 1)}
							disabled={siteIndex <= 0}
							styles={{
								backgroundColor: theme.accentSecondaryColor,
								borderColor: theme.accentSecondaryColor,
							}}
						/>
						{pages.map((page, index) => (
							<MundoButton
								dataSet={{
									cy: `record.page.${page.title}.button`,
								}}
								key={`pagenavbutton${page.title}`}
								onPress={() => onChangeSiteIndex(index)}
								title={{ id: page.title }}
								styles={
									index === siteIndex
										? {
												backgroundColor:
													theme.backgroundMainColor,
												borderColor:
													theme.backgroundMainColor,
										  }
										: {
												backgroundColor:
													theme.accentSecondaryColor,
												borderColor:
													theme.accentSecondaryColor,
										  }
								}
							/>
						))}
						<MundoButton
							dataSet={{ cy: 'record.pageOverview.button' }}
							key={`pagenavbuttonoveview`}
							onPress={() => onChangeSiteIndex(pages.length)}
							title={{ id: 'overview' }}
							styles={
								pages.length === siteIndex
									? {
											backgroundColor:
												theme.backgroundMainColor,
											borderColor:
												theme.backgroundMainColor,
									  }
									: {
											backgroundColor:
												theme.accentSecondaryColor,
											borderColor:
												theme.accentSecondaryColor,
									  }
							}
						/>
						<MundoButton
							icon={'right'}
							onPress={() => onChangeSiteIndex(siteIndex + 1)}
							disabled={siteIndex >= pages.length}
							styles={{
								backgroundColor: theme.accentSecondaryColor,
								borderColor: theme.accentSecondaryColor,
							}}
						/>
					</View>
				</ScrollView>
			</View>
			<View style={{ flex: 1 }}>
				{siteIndex < pages.length ? (
					props.pageOverride ? (
						<props.pageOverride
							title={pages[siteIndex].title}
							items={pages[siteIndex].items}
							dispatchAction={(action: {
								type: ETypes;
								location?: number | undefined;
								item?: any;
							}) =>
								dispatchAction({
									...action,
									location: siteIndex,
								})
							}
							itemComponent={props.itemComponent}
						/>
					) : (
						<Page
							title={pages[siteIndex].title}
							items={pages[siteIndex].items}
							dispatchAction={(action) =>
								dispatchAction({
									...action,
									location: siteIndex,
								})
							}
							itemComponent={props.itemComponent}
						/>
					)
				) : props.overviewPageOverride ? (
					<props.overviewPageOverride
						pages={pages}
						save={save}
						saveAndContinue={saveAndContinue}
					/>
				) : (
					<PagesOverview
						pages={pages}
						save={save}
						saveAndContinue={saveAndContinue}
					/>
				)}
			</View>
		</View>
	);
};

export default RecordPages;
