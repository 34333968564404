import { defineMessages } from 'react-intl';

export const scope = 'mango.module.order.filter';

export default defineMessages({
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer name',
	},
	status: {
		id: `${scope}.status`,
		defaultMessage: 'status',
	},
	fromDate: {
		id: `${scope}.from`,
		defaultMessage: 'from',
	},
	toDate: {
		id: `${scope}.to`,
		defaultMessage: 'to',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	selectModule: {
		id: `${scope}.selectModule`,
		defaultMessage: '-Select Module-',
	},
	done: {
		id: `${scope}.done`,
		defaultMessage: 'done',
	},
});
