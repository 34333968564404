import loadable from '@react-loadable/revised';
import { Loader } from './Loader';

export enum EPluign {
	VehicleCleaning = 'VehicleCleaning',
	Statistics = 'Statistics',
	Lynx = 'Lynx',
}

export const getModule = (title: string, plugin = EPluign.VehicleCleaning) => {
	return loadable({
		loader: () =>
			import(
				`./${plugin}/container/${
					title.charAt(0).toUpperCase() + title.slice(1)
				}/Module`
			),
		loading: Loader,
	});
};

export const getContainer = (
	title: string,
	plugin = EPluign.VehicleCleaning,
) => {
	return loadable({
		loader: () => import(`./${plugin}/container/${title}`),
		loading: Loader,
	});
};

export const getComponent = (
	title: string,
	plugin = EPluign.VehicleCleaning,
) => {
	return loadable({
		loader: () => import(`./${plugin}/components/${title}`),
		loading: Loader,
	});
};

export const getExtension = (
	title: string,
	plugin = EPluign.VehicleCleaning,
) => {
	return loadable({
		loader: () => import(`./${plugin}/extensions/${title}`),
		loading: Loader,
	});
};

export const getEnum = (title: string, plugin = EPluign.VehicleCleaning) => {
	return loadable({
		loader: () => import(`./${plugin}/extensions/enum/${title}`),
		loading: Loader,
	});
};
