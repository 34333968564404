import { defineMessages } from 'react-intl';

export const scope = 'mango.container.ArticlePage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'article list',
	},
	create: {
		id: `${scope}.create`,
		defaultMessage: 'create',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	columnTitle: {
		id: `${scope}.columnTitle`,
		defaultMessage: 'title',
	},
	price: {
		id: `${scope}.price`,
		defaultMessage: 'price in €',
	},
	short: {
		id: `${scope}.short`,
		defaultMessage: 'short',
	},
});
