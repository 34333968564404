import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MGroupInvoice } from 'mango-utils-client';
import { GET_GROUPINVOICES } from './queries';

interface IUseGroupInvoicesProps {
	skip: boolean;
	orderBy?: string | undefined;
}

export const useGroupInvoices = (props: IUseGroupInvoicesProps) => {
	const [groupInvoices, updateLocalGroupInvoices] = useState<MGroupInvoice[]>(
		[] as MGroupInvoice[],
	);
	const [filter, onChangeFilter] = useState<MGroupInvoice>();
	const [startDate, onChangeStartDate] = useState<number>(
		Date.now() - 2628000000,
	);
	const [endDate, onChangeEndDate] = useState<number>(Date.now());
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			groupInvoice: { ...filter },
			start: groupInvoices.length,
			count: 20,
			from: startDate,
			to: endDate,
			orderBy: props.orderBy,
		};
	}, [filter, groupInvoices, startDate, endDate, props.orderBy]);

	const [lazyQuery, { loading }] = useLazyQuery(GET_GROUPINVOICES, {
		onCompleted: (data) => {
			if (data) {
				if (
					!data.groupInvoices.length ||
					data.groupInvoices.length % 20 !== 0
				) {
					setLoadMoreActive(false);
				}
				if (reset) {
					updateLocalGroupInvoices(data.groupInvoices);
					onChangeReset(false);
				} else {
					updateLocalGroupInvoices([
						...groupInvoices,
						...data.groupInvoices,
					]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				groupInvoice: { ...filter },
				count: 20,
				start: 0,
				from: startDate,
				to: endDate,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, startDate, endDate, props.orderBy]);

	return {
		groupInvoices,
		loading,
		loadMore,
		onChangeFilter,
		loadMoreActive,
		onChangeStartDate,
		onChangeEndDate,
	};
};
