/**
 * Web platform entry point
 */
import React from 'react';
// @ts-ignore // web specific stuff
import { render } from 'react-native';

import App from './App';

const AppClient = () => {
	return <App />;
};

// @ts-ignore // web specific stuff
render(<AppClient />, document.getElementById('root'));
