import React, { FC, useEffect } from 'react';
import { View } from 'react-native';

import {
	dateFormatter,
	day,
	hour,
	month,
	quartal,
	range,
	week,
	year,
} from 'mango-utils-client';
import DatePicker from '../../../../components/DatePicker';
import {
	MundoButton,
	MundoCheckBox,
	MundoText,
} from '../../../../components/elements';
import MundoPicker from '../../../../components/elements/MundoPicker';
import { useStyle } from '../../../../utilities/hooks/styles';
import { timeMessages } from '../../utilities/times';
import messages from './messages';
import { ITimeConfiguratorProps } from './props';

export const TimeConfigurator: FC<ITimeConfiguratorProps> = ({
	updateFromTo,
	fromTo,
	onChangeInterval,
	interval,
	onChangeEntrys,
	entrys,
	compare,
	onChangeCompare,
	startDate,
	onChangeStartDate,
}) => {
	const styles = useStyle();

	useEffect(() => {
		updateFromTo(
			`${startDate - entrys * interval * (compare ? 2 : 1)}/${startDate}`,
			'fromto',
		);
	}, [entrys, interval, compare, updateFromTo, startDate]);

	return (
		<View style={styles.sideMenu}>
			<View style={styles.paddedView}>
				<MundoText message={messages.from}>
					{' '}
					{dateFormatter(
						new Date(
							+fromTo.split('/')[0] +
								entrys * interval * (compare ? 1 : 0),
						),
					)}
				</MundoText>
				<MundoText message={messages.to} />
				<DatePicker
					value={new Date(startDate).toISOString().substr(0, 10)}
					onChange={(d) => {
						onChangeStartDate(d);
						updateFromTo(
							`${
								startDate -
								entrys * interval * (compare ? 2 : 1)
							}/${startDate}`,
							'fromto',
						);
					}}
				/>
			</View>
			<View style={styles.thinSeparator} />
			<View style={styles.horizontalLayout}>
				<MundoPicker
					label={messages.interval}
					onChange={(v) => onChangeInterval(+v)}
					value={interval}
					values={[
						{ label: timeMessages.hour, value: hour },
						{ label: timeMessages.day, value: day },
						{ label: timeMessages.week, value: week },
						{ label: timeMessages.month, value: month },
						{ label: timeMessages.quartal, value: quartal },
						{ label: timeMessages.year, value: year },
					]}
					style={{}}
				/>
				<MundoPicker
					label={messages.entrys}
					onChange={(v) => onChangeEntrys(+v)}
					value={entrys}
					values={range(100).map((n) => {
						return { label: `${n}`, value: n };
					})}
					style={{}}
				/>
			</View>
			{onChangeCompare && (
				<MundoCheckBox
					checked={!!compare}
					onCheckedChanged={onChangeCompare}
					title={messages.compare}
				/>
			)}
			{compare && (
				<View style={styles.paddedView}>
					<MundoText message={messages.from}>
						{' '}
						{dateFormatter(new Date(+fromTo.split('/')[0]))}{' '}
					</MundoText>
					<MundoText message={messages.to}>
						{' '}
						{dateFormatter(
							new Date(+fromTo.split('/')[0] + entrys * interval),
						)}
					</MundoText>
				</View>
			)}
			<MundoText message={messages.presets} />
			<MundoButton
				title={messages.lastWeek}
				onPress={() => {
					onChangeInterval(day);
					onChangeEntrys(7);
				}}
			/>
			<MundoButton
				title={messages.lastMonth}
				onPress={() => {
					onChangeInterval(day);
					onChangeEntrys(30);
				}}
			/>
			<MundoButton
				title={messages.lastYear}
				onPress={() => {
					onChangeInterval(month);
					onChangeEntrys(12);
				}}
			/>
		</View>
	);
};
