import React, { FC } from 'react';
import { View } from 'react-native';

import { alert } from '../../../utilities/alert';
import { useStyle } from '../../../utilities/hooks/styles';
import { IPipelineProps } from '../props';
import { PipelineEdit, PipelineView } from './';

export const PipelineContext = React.createContext({
	editMode: false,
	setEditMode: (mode: boolean) => {
		alert('set edit mode is undefined', `${mode}`, 'error');
	},
});

export const Pipeline: FC<IPipelineProps> = (props) => {
	const style = useStyle();
	const [editMode, setEditMode] = React.useState(false);

	return (
		<PipelineContext.Provider value={{ editMode, setEditMode }}>
			<View style={style.horizontalLayout}>
				{!editMode ? (
					<PipelineView
						pipeline={props.pipeline}
						index={props.index}
					/>
				) : (
					<PipelineEdit
						pipeline={props.pipeline}
						index={props.index}
					/>
				)}
			</View>
		</PipelineContext.Provider>
	);
};
