import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
	query getCustomer($id: String!) {
		customer(id: $id) {
			_id
			id
			title
			firstName
			lastName
			isPerson
			tags
			address {
				street
				city
				country
				zipCode
				tags
			}
			billingAddress {
				street
				city
				country
				zipCode
				tags
			}
			defaultPaymentMethod {
				_id
				id
				title
			}
			enablePaymentMethodByTime
			paymentMethodByTime {
				paymentMethod {
					_id
					id
					title
				}
				fromh
				toh
				fromm
				tom
				day
			}
			ustID
			allegiance
			taxNr
			externalId
			groupInvoiceTime
			defaultPaymentTime
			mail
			fax
			phone
			discounts
			court
			director
			bank {
				title
				iban
				swift
			}
			commissioning
			legalInformation
		}
	}
`;

export const GET_PAYMENTMETHODS = gql`
	query($any: PaymentMethodInput!, $count: Int!) {
		paymentMethods(paymentMethod: $any, count: $count) {
			_id
			id
			title
		}
	}
`;

export const GET_PAYMENTMETHOD = gql`
	query($id: String!) {
		paymentMethod(id: $id) {
			_id
			title
		}
	}
`;

export const SAVE_CUSTOMER = gql`
	mutation saveCustomer($customer: CustomerInput!) {
		saveCustomer(customer: $customer) {
			_id
		}
	}
`;

export const DELETE_CUSTOMER = gql`
	mutation deleteCustomer($id: String!) {
		deleteCustomer(id: $id) {
			_id
		}
	}
`;

export const ID_IS_TAKEN = gql`
	query idIsTaken($id: Int!, $_id: String!) {
		customerIdIsTaken(id: $id, _id: $_id)
	}
`;
