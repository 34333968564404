import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MundoText } from '../../components/elements';
import FinishButtonGroup from '../../components/FinishButtonGroup';
import LoadingIndicator from '../../components/LoadingIndicator';
import { config } from '../../utilities/config';
import { useArray } from '../../utilities/hooks/array';
import { useStyle } from '../../utilities/hooks/styles';
import { ETypes } from '../../utilities/reducer/array.reducer';
import { useHistory } from '../../utilities/routing';
import messages from './messages';

const ScanPage: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const [selectedFiles, onChangeSelectedFile] = useState<Blob[]>([]);
	const [uploadProgress, dispatchProgress] = useArray<{
		result: string;
		state: string;
		percentage: number;
	}>();

	const onChangeHandler = async (event: any) => {
		const files: Blob[] = Array.from(event.target.files);
		dispatchProgress({
			type: ETypes.RESET_TO,
			item: files.map(() => {
				return {
					result: '-',
					state: 'ready',
					percentage: 0,
				};
			}),
		});
		onChangeSelectedFile(files);
	};

	const onClickHandler = async () => {
		await Promise.all(
			selectedFiles.map(
				(file: any, index: number) =>
					new Promise<{
						result: string;
						state: string;
						percentage: number;
					}>((resolve) => {
						const req = new XMLHttpRequest();
						// add progress to upload
						req.upload.addEventListener('progress', (event) => {
							if (event.lengthComputable) {
								dispatchProgress({
									type: ETypes.EDIT,
									location: index,
									item: {
										result: '',
										state: 'pending',
										percentage:
											(event.loaded / event.total) * 100,
									},
								});
							}
						});
						// finished uploading
						req.upload.addEventListener('load', async () => {
							dispatchProgress({
								type: ETypes.EDIT,
								location: index,
								item: {
									result: '',
									state: 'done',
									percentage: 100,
								},
							});
						});
						// uploading error
						req.upload.addEventListener('error', () => {
							dispatchProgress({
								type: ETypes.EDIT,
								location: index,
								item: {
									result: '',
									state: 'error',
									percentage: 0,
								},
							});
						});
						// get response
						req.onreadystatechange = () => {
							const final = {
								result: req.response,
								state: 'done',
								percentage: 100,
							};
							dispatchProgress({
								type: ETypes.EDIT,
								location: index,
								item: final,
							});
							resolve(final);
						};

						const formData = new FormData();
						formData.append('file', file);

						const [http, url, port] = config.API_URL.split(':');
						const uploadUrl = `${url}:${
							isNaN(+port) ? 'mydefaultport' : +port + 1
						}`;
						req.open('POST', `${http}:${uploadUrl}/quagga/upload`);
						req.send(formData);
					}),
			),
		);
		// onChangenUploadProgress(next);
	};

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<MundoText
					styles={styles.headerText}
					message={messages.title}
				/>
			</View>
			<View style={[styles.spacedContainer, { flexGrow: 1 }]}>
				<View style={styles.bottomMargin20}>
					<input
						type="file"
						name="files"
						onChange={onChangeHandler}
						multiple
					/>
				</View>
				<ScrollView style={styles.fullSize}>
					<View
						style={[
							styles.wideGroupTable,
							styles.wideGroupTableColumnHeader,
						]}
					>
						<View
							style={[
								styles.wideGroupTableColumn10,
								styles.wideGroupTableColumnHeader,
								{ maxWidth: 70 },
							]}
						>
							<MundoText message={messages.progress} />
						</View>
						<View
							style={[
								styles.wideGroupTableColumn10,
								styles.wideGroupTableColumnHeader,
								{ maxWidth: 70 },
							]}
						>
							<MundoText message={messages.state} />
						</View>
						<View
							style={[
								styles.wideGroupTableColumn20,
								styles.wideGroupTableColumnHeader,
							]}
						>
							<MundoText message={messages.result} />
						</View>
					</View>
					{uploadProgress.map((value, index) => {
						return (
							<View
								key={index}
								style={[
									styles.wideGroupTable,
									styles.tableRowContainer,
								]}
							>
								<View
									style={[
										styles.wideGroupTableColumn10,
										styles.extendedRowHeight,
										{ maxWidth: 70 },
									]}
								>
									<MundoText
										message={`${value.percentage}`}
									/>
								</View>
								<View
									style={[
										styles.wideGroupTableColumn10,
										styles.extendedRowHeight,
										{ maxWidth: 70 },
									]}
								>
									{value.state === 'pending' ? (
										<LoadingIndicator />
									) : (
										<MundoText message={value.state} />
									)}
								</View>
								<View
									style={[
										styles.wideGroupTableColumn20,
										styles.extendedRowHeight,
									]}
								>
									{!value.result ? (
										<LoadingIndicator />
									) : (
										<MundoText
											message={`${value.result}`}
										/>
									)}
								</View>
							</View>
						);
					})}
				</ScrollView>
				<FinishButtonGroup
					saveFunction={onClickHandler}
					saveTitle={messages.upload}
					cancelFunction={history.goBack}
				/>
			</View>
		</View>
	);
};

export default ScanPage;
