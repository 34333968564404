import { defineMessages } from 'react-intl';

export const scope = 'mango.container.users';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'users',
	},
	create: {
		id: `${scope}.create`,
		defaultMessage: 'create',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	columnTitle: {
		id: `${scope}.columnTitle`,
		defaultMessage: 'title',
	},
});
