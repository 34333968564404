import { ETOrderListSettings } from 'mango-utils-client';
import { MessageDescriptor } from 'react-intl';

import messages from './messages';

export function pickTableHeaderExtension(
	field: string,
): { identifier: string; header: MessageDescriptor; size: number } {
	switch (field) {
		case ETOrderListSettings.DRIVER:
			return { identifier: 'driver', header: messages.driver, size: 20 };
		case ETOrderListSettings.TRAILER:
			return {
				identifier: 'trailer',
				header: messages.trailer,
				size: 20,
			};
		case ETOrderListSettings.TRUCK:
			return { identifier: 'truck', header: messages.truck, size: 20 };
		default:
			return {
				identifier: 'malformed',
				header: messages.malformed,
				size: 10,
			};
	}
}
