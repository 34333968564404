import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.customerFilterSection';

export default defineMessages({
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	global: {
		id: `${scope}.global`,
		defaultMessage: 'Total Profit',
	},
	filterGlobal: {
		id: `${scope}.filterGlobal`,
		defaultMessage: 'Filter Total Profit by Customers',
	},
	useAllCustomers: {
		id: `${scope}.useAllCustomers`,
		defaultMessage: 'use all',
	},
});
