import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../components/elements/MundoInput';
import {
	getTranslation,
	updateTranslation,
} from '../../../../utilities/translations';
import messages from '../messages';
import { IArticleEditTranslateableFieldProps } from '../props';

export const DataUnitField: FC<IArticleEditTranslateableFieldProps> = ({
	value,
	update,
	lang,
}) => {
	let dUnit = getTranslation(value.dataUnit, lang);
	if (!dUnit) {
		dUnit = '';
	}
	return (
		<View>
			<MundoInput
				dataSet={{ cy: 'article.data.unit.input' }}
				label={messages.dataUnit}
				onChangeText={(text) =>
					update({
						dataUnit: updateTranslation(text, lang, value.dataUnit),
					})
				}
				value={dUnit}
			/>
		</View>
	);
};
