import { useLazyQuery } from '@apollo/client';
import { MPackage } from 'mango-utils-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_PACKAGES } from './queries';

interface IUsePackagesProps {
	skip: boolean;
	disabled?: boolean;
	orderBy?: string | undefined;
}

export const usePackages = (props: IUsePackagesProps) => {
	const [packages, onChangePackages] = useState<MPackage[]>([]);
	const [filter, onChangeFilter] = useState<MPackage>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			package: { ...filter, disabled: props.disabled },
			start: packages.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, packages, props.disabled, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_PACKAGES, {
		onCompleted: (data) => {
			if (data) {
				if (!data.packages.length || data.packages.length % 20 !== 0) {
					setLoadMoreActive(false);
				}
				if (reset) {
					onChangePackages(data.packages);
					onChangeReset(false);
				} else {
					onChangePackages([...packages, ...data.packages]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				package: { ...filter, disabled: props.disabled },
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.disabled, props.orderBy]);

	return { packages, loading, loadMore, onChangeFilter, loadMoreActive };
};
