import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../../../components/elements/MundoInput';
import { useStyle } from '../../../../../../utilities/hooks/styles';
import messages from '../messages';
import { IDriverFilterFieldProps } from '../props';

export const DriverFirstNameFilter: FC<IDriverFilterFieldProps> = ({
	driver,
	update,
}) => {
	const styles = useStyle();

	return (
		<View style={styles.formUnitStandardWidth}>
			<MundoInput
				dataSet={{ cy: 'driver.filter.firstname.input' }}
				label={messages.firstName}
				onChangeText={(firstName) => update({ firstName })}
				value={driver.firstName}
			/>
		</View>
	);
};
