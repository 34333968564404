import {
	MPaymentMethod,
	MPaymentMethodWithTime,
	range,
} from 'mango-utils-client';
import React, { FC, useMemo } from 'react';
import { View } from 'react-native';
import { MundoText } from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import MundoPicker from '../../../../components/elements/MundoPicker';
import { useStyle } from '../../../../utilities/hooks/styles';
import messages from '../messages';
import { GET_PAYMENTMETHODS } from '../queries';
import { IPaymentMethodByTimeProps } from './props';

export const PaymentMethodByTime: FC<IPaymentMethodByTimeProps> = ({
	onChangePaymentMethodByTime,
	paymentMethodByTime,
}) => {
	const styles = useStyle();
	const paymentMethod = useMemo(() => paymentMethodByTime.paymentMethod, [
		PaymentMethodByTime,
	]);

	const update = (next: Partial<MPaymentMethodWithTime>) => {
		onChangePaymentMethodByTime({ ...paymentMethodByTime, ...next });
	};

	return (
		<View>
			<View style={{ zIndex: 10 }}>
				<MundoText message={messages.paymentMethod} />
				<MundoObjectTypeahead
					value={paymentMethod}
					onChange={(value) => {
						if (value) {
							update({
								paymentMethod: value as MPaymentMethod,
							});
						} else {
							update({
								paymentMethod: new MPaymentMethod(),
							});
						}
					}}
					QUERY={GET_PAYMENTMETHODS}
				/>
			</View>
			<View style={styles.horizontalLayout}>
				<View>
					<MundoPicker
						label={'fromh'}
						value={paymentMethodByTime.fromh}
						values={range(24).map((hour) => {
							return { label: `${hour}`, value: hour };
						})}
						onChange={(v) => {
							update({ fromh: +v });
						}}
					/>
					<MundoPicker
						label={'fromm'}
						value={paymentMethodByTime.fromm}
						values={range(60).map((min) => {
							return { label: `${min}`, value: min };
						})}
						onChange={(v) => {
							update({ fromm: +v });
						}}
					/>
				</View>
				<View>
					<MundoPicker
						label={'toh'}
						value={paymentMethodByTime.toh}
						values={range(24).map((hour) => {
							return { label: `${hour}`, value: hour };
						})}
						onChange={(v) => {
							update({ toh: +v });
						}}
					/>
					<MundoPicker
						label={'tom'}
						value={paymentMethodByTime.tom}
						values={range(60).map((min) => {
							return { label: `${min}`, value: min };
						})}
						onChange={(v) => {
							update({ tom: +v });
						}}
					/>
				</View>
			</View>
		</View>
	);
};
