import React, { FC, useState } from 'react';
import { View } from 'react-native';

import { MundoText } from '../../../../../../components/elements';
import MundoObjectTypeahead from '../../../../../../components/elements/MundoObjectTypeahead';
import { useStyle } from '../../../../../../utilities/hooks/styles';
import messages from '../messages';
import { IDriverFilterFieldProps } from '../props';
import { GET_COMPANIES } from '../queries';

export const CompanyFilter: FC<IDriverFilterFieldProps> = ({
	driver,
	update,
}) => {
	const styles = useStyle();

	const [company, onChangeCompany] = useState({ _id: '', title: '' });

	return (
		<View style={[styles.formUnitStandardWidth, styles.elevated]}>
			<MundoText
				message={messages.companyName}
				styles={styles.pickerLabelSpacing}
			/>
			<MundoObjectTypeahead
				value={company}
				placeholder={messages.companyName}
				onChange={(next) => {
					if (next) {
						update({ companyID: next._id });
						onChangeCompany({
							...next,
							title: next.title as string,
						});
					} else if (driver.companyID) {
						update({ companyID: '' });
						onChangeCompany({ _id: '', title: '' });
					}
				}}
				QUERY={GET_COMPANIES}
			/>
		</View>
	);
};
