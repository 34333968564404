import { defineMessages } from 'react-intl';

export const scope = 'mango.components.packageList';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'package list',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	description: {
		id: `${scope}.description`,
		defaultMessage: 'description',
	},
	create: {
		id: `${scope}.create`,
		defaultMessage: 'create',
	},
	price: {
		id: `${scope}.price`,
		defaultMessage: 'price in €',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
});
