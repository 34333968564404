import React, { FC, useContext } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';

import { EUserClassification, MTPreproduct } from 'mango-utils-client';
import icons from '../../../../../assets';
import { MundoButton } from '../../../../../components/elements';
import MundoTable from '../../../../../components/elements/MundoTable';
import MundoText from '../../../../../components/elements/MundoText';
import { useSort } from '../../../../../utilities/hooks/sort';
import { useStyle } from '../../../../../utilities/hooks/styles';
// import { openDocs } from '../../../../../utilities/openDocs';
import { useHistory } from '../../../../../utilities/routing';
import { usePreproducts } from '../../../hooks/preproduct';
// import PreproductFilter from '../filter';
import messages from './messages';
import { IPreproductList } from './props';
import PreproductFilter from '../Filter';
import { AuthContext } from '../../../../../utilities/hooks/auth';

export const PreproductList: FC<IPreproductList> = (props) => {
	const styles = useStyle();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { orderBy, mode, changeSort } = useSort();

	const {
		preproducts: serverPreproducts,
		loading,
		loadMore,
		onChangeFilter,
		loadMoreActive,
	} = usePreproducts({ skip: !!props.data, orderBy });
	const Preproducts: MTPreproduct[] = props.data || serverPreproducts;

	return (
		<View style={!props.sub && styles.containerFullResolution}>
			<View
				style={
					props.sub ? styles.headerViewSecondary : styles.headerView
				}
			>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.preproducts}
						styles={
							props.sub
								? styles.headerSecondaryText
								: styles.headerText
						}
					/>
					{/* <MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/equipmentmanager?id=auflieger',
							)
						}
					/> */}
				</View>
				{!props.cantCreate && (
					<MundoButton
						dataSet={{ cy: 'Preproduct.create' }}
						icon={'plus'}
						onPress={() =>
							history.push(`/edit/Preproduct.vehicleCleaning/new`)
						}
						disabled={
							user?.classification !==
								EUserClassification.ADMIN &&
							user?.classification !== EUserClassification.MEMBER
						}
					/>
				)}
			</View>
			<ScrollView>
				<View style={!props.sub && styles.spacedContainer}>
					<MundoTable
						filter={
							!props.noFilter && (
								<PreproductFilter onChange={onChangeFilter} />
							)
						}
						entrys={[
							{ identifier: 'id', header: messages.id, size: 10 },
							{
								identifier: 'title',
								header: messages.title,
								size: 30,
							},
							{
								identifier: 'chemDesc',
								header: messages.chemicalDescription,
								size: 30,
							},
							{ identifier: 'arrow', size: 4 },
						]}
						rows={Preproducts.map((Preproduct) => {
							return {
								key: Preproduct._id,
								onPress: () =>
									history.push(
										`/edit/preproduct.vehicleCleaning/${Preproduct._id}`,
									),
								values: [
									{
										identifier: 'id',
										content: <Text>{Preproduct.id}</Text>,
									},
									{
										identifier: 'title',
										content: (
											<Text>{Preproduct.title}</Text>
										),
									},
									{
										identifier: 'chemDesc',
										content: (
											<Text>
												{Preproduct.chemicalDescription}
											</Text>
										),
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								dataSet: { cy: Preproduct.title + '.row' },
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};
