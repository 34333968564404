import React, { FC } from 'react';
import { View } from 'react-native';

import { EArticleInput } from 'mango-utils-client';
import { MundoCheckBox } from '../../../../components/elements';
import messages from '../messages';
import { IArticleEditFieldProps } from '../props';

export const DataCheckboxes: FC<IArticleEditFieldProps> = ({
	value,
	update,
}) => {
	const essentialCheckboxHandler = (title: EArticleInput) => {
		const essentials = Object.assign([], value.essentials);
		const index = essentials.findIndex((element) => element === title);

		if (index >= 0) {
			essentials.splice(index, 1);
		} else {
			essentials.push(title);
		}
		update({ essentials });
	};

	return (
		<>
			<View
				style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
			>
				<View>
					<MundoCheckBox
						checked={
							!!value.essentials.find((elem) => elem === 'AMOUNT')
						}
						onCheckedChanged={() =>
							essentialCheckboxHandler(EArticleInput.AMOUNT)
						}
						title={messages.amount}
					/>
				</View>
				<View style={{ marginLeft: 20 }}>
					<MundoCheckBox
						checked={
							!!value.essentials.find((elem) => elem === 'DATA')
						}
						onCheckedChanged={() =>
							essentialCheckboxHandler(EArticleInput.DATA)
						}
						title={messages.data}
					/>
				</View>
				<View style={{ marginLeft: 20 }}>
					<MundoCheckBox
						checked={
							!!value.essentials.find(
								(elem) => elem === 'COMMENT',
							)
						}
						onCheckedChanged={() =>
							essentialCheckboxHandler(EArticleInput.COMMENT)
						}
						title={messages.comment}
					/>
				</View>
				<View style={{ marginLeft: 20 }}>
					<MundoCheckBox
						checked={
							!!value.essentials.find(
								(elem) => elem === 'INTERVALS',
							)
						}
						onCheckedChanged={() =>
							essentialCheckboxHandler(EArticleInput.INTERVALS)
						}
						title={messages.intervals}
					/>
				</View>
			</View>
		</>
	);
};
