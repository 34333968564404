import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { Picker, View } from 'react-native';

import { MundoText } from '../';
import { useStyle } from '../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import { IMundoPickerProps } from './props';

export const MundoPicker: FC<IMundoPickerProps> = memo(
	({
		dataSet,
		value,
		values,
		placeholder,
		label,
		onChange,
		style,
		customValidity,
		tabelarLayout,
		horizontalLayout,
	}) => {
		const styles = useStyle();
		const intl = useIntl();
		const inValid = customValidity ? customValidity(value) : false;
		return (
			<View
				style={
					style || [
						styles.formUnitHeightVertCenter,
						tabelarLayout
							? { width: 180 }
							: styles.formUnitStandardWidth,
						horizontalLayout && styles.containerHorizontal,
					]
				}
			>
				{!!label && horizontalLayout ? (
					<View style={styles.rightMargin10}>
						<MundoText
							styles={styles.pickerLabelSpacing}
							message={label}
						/>
					</View>
				) : (
					<MundoText
						styles={styles.pickerLabelSpacing}
						message={label}
					/>
				)}
				<Picker
					{...{ dataSet }}
					style={styles.picker}
					onValueChange={onChange}
					selectedValue={value}
				>
					{!!placeholder && (
						<Picker.Item
							label={
								typeof placeholder === 'string'
									? placeholder
									: capitalizeFirstWord(
											intl.formatMessage(placeholder),
									  )
							}
							value={''}
						/>
					)}
					{values.map(({ label: l, value: v }, index) => (
						<Picker.Item
							key={`pickerItem${index}`}
							label={
								l &&
								(typeof l === 'string'
									? l
									: capitalizeFirstWord(
											intl.formatMessage(l),
									  ))
							}
							value={v}
						/>
					))}
				</Picker>
				{inValid && (
					<MundoText message={inValid} styles={styles.warning} />
				)}
			</View>
		);
	},
);
