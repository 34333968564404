import { useQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';

import { MGroup } from 'mango-utils-client';
import {
	MundoButton,
	MundoCheckBox,
	MundoText,
} from '../../../components/elements';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { GET_GROUPS } from '../queries';
import messages from './messages';

export const PermissionGrid: FC = () => {
	const styles = useStyle();
	// get Global Settings Context
	const { settings, onChangeSettings } = useContext(SettingsContext);
	/**
	 * Groups
	 */
	const [groups, onChangeGroups] = useState([] as MGroup[]);
	useQuery(GET_GROUPS, {
		onCompleted: (data) => {
			if (data && data.groups) {
				onChangeGroups(data.groups);
			}
		},
	});

	if (!settings) {
		return <LoadingIndicator />;
	}

	return (
		<>
			<View style={styles.headerTitleContainer}>
				<MundoText
					message={messages.permissionGridHeader}
					styles={styles.headerSecondaryText}
				/>
				<MundoButton
					icon={'question'}
					subtype="transparent"
					onPress={() =>
						openDocs(
							'#/content/settings?id=rechtevergabe-berechtigungen',
						)
					}
				/>
			</View>
			<ScrollView
				horizontal
				contentContainerStyle={{
					flexGrow: 1,
				}}
				automaticallyAdjustContentInsets
			>
				<View style={styles.containerFullPercentageWidth}>
					<View style={[styles.wideGroupTable, styles.topMargin20]}>
						<View
							style={[
								styles.wideGroupTableColumn,
								styles.wideGroupTableColumnHeader,
							]}
						>
							<MundoText message={messages.titleHeader} />
						</View>
						{groups.map((g) => (
							<View
								style={[
									styles.wideGroupTableColumn,
									styles.wideGroupTableColumnHeader,
								]}
								key={`${g.id}`}
							>
								<Text>{g.title}</Text>
							</View>
						))}
					</View>

					{settings.pipelines.map((pipe, pi) => (
						<View key={`permissionGridForPipeline${pipe._id}`}>
							{pipe.modules.map((mod, index) => (
								<View
									style={styles.wideGroupTable}
									key={`${mod.title}${
										pipe.id
									}${index}${Math.random()}`}
								>
									<View
										style={[
											styles.wideGroupTableColumn,
											styles.wideGroupTableColumnFooter,
											styles.buttonGroupLeftVertCenter,
										]}
									>
										<MundoText>
											{pipe.short || pi + 1}{' '}
										</MundoText>
										<MundoText
											message={
												mod.title
													? { id: mod.title }
													: {
															id: `module.${mod.identifier
																.split('::')[0]
																.split('.')
																.reverse()
																.join('.')}`,
															defaultMessage:
																mod.identifier,
													  }
											}
										/>
									</View>

									{groups.map((g) => (
										<View
											key={g.id}
											style={[
												styles.wideGroupTableColumn,
												styles.wideGroupTableColumnFooter,
											]}
										>
											<MundoCheckBox
												checked={
													!!mod.groups.find(
														(onmodG) =>
															g.inheritance >
																onmodG.inheritance ||
															g._id ===
																onmodG._id,
													)
												}
												onCheckedChanged={() => {
													const prevEntry = mod.groups.find(
														(onmodG) =>
															g._id ===
															onmodG._id,
													);
													if (prevEntry) {
														mod.groups.splice(
															mod.groups.indexOf(
																prevEntry,
															),
															1,
														);
													} else {
														mod.groups.push(g);
													}
													onChangeSettings(settings);
												}}
												key={`${mod.title}${pipe.id}${g.id}`}
											/>
										</View>
									))}
								</View>
							))}
						</View>
					))}
				</View>
			</ScrollView>
		</>
	);
};
