import { defineMessages } from 'react-intl';

export const scope = 'mango.module.receipt';
export const errorScope = 'error';

export default defineMessages({
	save: {
		id: `${scope}.save`,
		defaultMessage: 'save',
	},
	receipt: {
		id: `${scope}.receipt`,
		defaultMessage: 'receipt',
	},
	noMetaData: {
		id: `${scope}.noMetaData`,
		defaultMessage: 'NO METADATA LOADED',
	},
	selectPackage: {
		id: `${scope}.selectPackage`,
		defaultMessage: '-Select Package-',
	},
	orderCreateError: {
		id: `${errorScope}.orderCreateError`,
		defaultMessage: 'order creation error',
	},
});
