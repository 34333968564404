import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';

import { MCustomer, MModuleDescription, MOrder } from 'mango-utils-client';
import { useIntl } from 'react-intl';
import DatePicker from '../../../components/DatePicker';
import MundoInput from '../../../components/elements/MundoInput';
import MundoPicker from '../../../components/elements/MundoPicker';
import MundoText from '../../../components/elements/MundoText';
import LoadingIndicator from '../../../components/LoadingIndicator';
import useDebounce from '../../../utilities/hooks/debounce';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import messages from './messages';
import { IOrderFilterProps } from './props';

const OrderFilter: FC<IOrderFilterProps> = ({ onChange, children }) => {
	const intl = useIntl();
	const styles = useStyle();
	const { settings } = useContext(SettingsContext);
	const [order, onChangeOrder] = useState<MOrder>(new MOrder());
	const [last, onChangeLast] = useState<MOrder>();
	const [fromTo, onChangeFromTo] = useState<string>(
		`${Date.now() - 60 * 60 * 1000 * 24 * 7}/${Date.now()}`,
	);
	const [lastFromTo, onChangeLastFromTo] = useState<string>();

	const update = (next: Partial<MOrder>) => {
		const nOrder = new MOrder({ ...order, ...next });
		onChangeOrder(nOrder);
	};

	const loadCB = useCallback((next, from, to) => onChange(next, from, to), [
		onChange,
	]);

	const debouncedOrder = useDebounce<MOrder>(order);
	const debouncedFromTo = useDebounce<string>(fromTo);

	useEffect(() => {
		if (debouncedOrder && debouncedOrder !== last) {
			const [from, to] = fromTo.split('/');
			onChangeLast(debouncedOrder);
			loadCB(debouncedOrder, +from, +to);
		}
		if (debouncedFromTo && debouncedFromTo !== lastFromTo) {
			const [from, to] = debouncedFromTo.split('/');
			onChangeLastFromTo(debouncedFromTo);
			loadCB(debouncedOrder, +from, +to);
		}
	}, [debouncedOrder, debouncedFromTo, loadCB, last, lastFromTo, fromTo]);

	if (!settings) {
		return <LoadingIndicator />;
	}

	return (
		<>
			<View style={styles.containerFullWidthHorizontalCenterAligned}>
				<View style={styles.paddedView}>
					<MundoInput
						horizontal
						dataSet={{ cy: 'orders.filter.customer' }}
						label={messages.customer}
						onChangeText={(title) =>
							update({ customer: new MCustomer({ title }) })
						}
						value={order.customer.title}
					/>
				</View>
				<View style={styles.paddedView}>
					<MundoPicker
						label={messages.status}
						horizontalLayout
						onChange={(v) => {
							const [currentState, pipeline] = v.split(':::');
							update({ pipeline, currentState });
						}}
						placeholder={messages.selectModule}
						values={[
							...settings.pipelines.reduce((arr: any[], pipe) => {
								pipe.modules.forEach((module) =>
									arr.push({
										value: `${module.identifier}:::${pipe._id}`,
										label: `${
											pipe.short
										} ${intl.formatMessage(
											transformModuleTitle(
												module ||
													new MModuleDescription(),
											),
										)}`,
									}),
								);
								return arr;
							}, []),
							{ label: messages.done, value: 'done' },
						]}
					/>
				</View>
			</View>
			<View style={styles.containerFullWidthHorizontalCenterAligned}>
				<View style={styles.paddedView}>
					<MundoText message={messages.fromDate} />
				</View>
				<View style={styles.paddedView}>
					<DatePicker
						value={new Date(+fromTo.split('/')[0])
							.toISOString()
							.substr(0, 10)}
						onChange={(date) =>
							onChangeFromTo(
								[date, fromTo.split('/')[1]].join('/'),
							)
						}
					/>
				</View>
				<View style={styles.paddedView}>
					<MundoText message={messages.toDate} />
				</View>
				<View style={styles.paddedView}>
					<DatePicker
						value={new Date(+fromTo.split('/')[1])
							.toISOString()
							.substr(0, 10)}
						onChange={(date) =>
							onChangeFromTo(
								[fromTo.split('/')[0], date].join('/'),
							)
						}
					/>
				</View>
				<View style={styles.paddedView}>
					<MundoInput
						horizontal
						dataSet={{ cy: 'orders.filter.id' }}
						label={messages.id}
						value={`${order.id}`}
						// tslint:disable-next-line: variable-name
						onChangeText={(id) => update({ id: +id })}
					/>
				</View>
				{children}
			</View>
		</>
	);
};

export default OrderFilter;
