import gql from 'graphql-tag';

export const GET_ARTICLE = gql`
	query getArticle($id: String!) {
		article(id: $id) {
			_id
			id
			short
			title {
				lang
				message
			}
			description {
				lang
				message
			}
			price
			tax
			unit
			dataUnit {
				lang
				message
			}
			subArticles {
				id
				_id
				title {
					lang
					message
				}
				short
			}
			tags
			essentials
			mandatory
		}
	}
`;

export const GET_ARTICLES = gql`
	query getArticles($count: Int!) {
		articles(count: $count) {
			_id
			id
			title {
				lang
				message
			}
			short
			description {
				lang
				message
			}
			price
			tax
			unit
			mandatory
		}
	}
`;

export const SAVE_ARTICLE = gql`
	mutation saveArticle($article: ArticleInput!) {
		saveArticle(article: $article) {
			id
		}
	}
`;

export const DELETE_ARTICLE = gql`
	mutation deleteArticle($id: String!) {
		deleteArticle(id: $id) {
			id
		}
	}
`;

export const DELETE_TARTICLE = gql`
	mutation deleteTArticel($id: String!) {
		deleteTArticel(id: $id) {
			id
		}
	}
`;

export const queries = {
	article: GET_ARTICLE,
	articles: GET_ARTICLES,
	save: SAVE_ARTICLE,
	deleteArticle: DELETE_ARTICLE,
	deleteTArticle: DELETE_TARTICLE,
};
