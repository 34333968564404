import { useMutation, useQuery } from '@apollo/client';
import React, {
	createContext,
	FC,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { ScrollView, View } from 'react-native';

import {
	MModuleDescription,
	MPipeline,
	MModuleReceiptSettings,
} from 'mango-utils-client';
import { useHistory, useParams } from '../../../utilities/routing';
import { MundoText } from '../../../components/elements';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { GET_RECEIPT_SETTINGS, SAVE_RECEIPT_SETTINGS } from './queries';
import { useArray } from '../../../utilities/hooks/array';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import OrderListSettings from '../../Order/List/Settings';
import { ReceiptSettingsArticleList } from './components/ArticleList';
import { ReceiptSettingsPackageList } from './components/PackageList';
import { destructData } from '../../../utilities/dataFunctions';

export const ReceiptSettingsContext = createContext({
	pipeline: new MPipeline(),
	onChangeReceiptSettings: (next: Partial<MModuleReceiptSettings>) => {
		/* feed me */
		console.log(next);
	},
});

export const ReceiptSettings: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { pipelineId } = useParams<{ pipelineId: string }>();
	const { settings } = useContext(SettingsContext);
	const [pipeline, onChangePipeline] = useState<MPipeline>();

	useEffect(() => {
		onChangePipeline(settings.pipelines.find((p) => p._id === pipelineId));
	}, [settings, pipelineId]);

	const [receiptSettings, dispatchReceiptSettingsAction] = useReducer(
		(
			state: MModuleReceiptSettings,
			action: { type: string; next: Partial<MModuleReceiptSettings> },
		) => {
			switch (action.type) {
				case 'update':
					return Object.assign(state, action.next);
				default:
					throw new Error();
			}
		},
		new MModuleReceiptSettings(),
	);

	// TO BE REMOVED
	const [dashboardFields, dispatchDashboardFieldsAction] = useArray<string>();

	const { loading } = useQuery(GET_RECEIPT_SETTINGS, {
		skip: !pipelineId,
		variables: {
			pipelineId,
		},
		onCompleted: (data) => {
			if (data) {
				const sets = destructData<MModuleReceiptSettings>(data);
				dispatchDashboardFieldsAction({
					type: ETypes.RESET_TO,
					item: sets.dashboardFields,
				});
			}
		},
	});

	// save settings
	const [saveSettings] = useMutation(SAVE_RECEIPT_SETTINGS);
	const saveButtonHandler = async () => {
		await saveSettings({
			variables: {
				receiptSettings: {
					...receiptSettings,
					articles: receiptSettings.articles.filter(
						(article) => article._id,
					),
					dashboardFields,
					pipelineId,
				},
			},
		});
		history.push('/settings');
	};

	if (!pipeline) {
		return <LoadingIndicator />;
	}

	return (
		<View style={[styles.containerFullResolution]}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={transformModuleTitle(
							pipeline.modules.find(
								(m) => m.identifier === 'receipt',
							) || new MModuleDescription(),
						)}
					/>
					{/* <MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/module/receipt?id=settings',
							)
						}
					/> */}
				</View>
			</View>
			<ScrollView>
				<ReceiptSettingsContext.Provider
					value={{
						onChangeReceiptSettings: (next) =>
							dispatchReceiptSettingsAction({
								type: 'update',
								next,
							}),
						pipeline,
					}}
				>
					<View style={[styles.spacedContainer, styles.elevated]}>
						<View style={[styles.wideGroup, styles.elevated]}>
							<ReceiptSettingsPackageList />
							<ReceiptSettingsArticleList />
						</View>
					</View>
					<View style={styles.spacedContainer}>
						<View style={{ zIndex: -1 }}>
							{loading ? (
								<LoadingIndicator />
							) : (
								<OrderListSettings
									onChange={(next) => {
										dispatchDashboardFieldsAction({
											type: ETypes.RESET_TO,
											item: next,
										});
									}}
									dashboardFields={dashboardFields}
									noSave
								/>
							)}
						</View>
					</View>
				</ReceiptSettingsContext.Provider>
			</ScrollView>
			{/* TODO: add input fields according to settings model */}
			<FinishButtonGroup
				cyId={'receipt.vehicleCleaning'}
				saveFunction={saveButtonHandler}
				cancelFunction={history.goBack}
			/>
		</View>
	);
};
