import gql from 'graphql-tag';

export const GET_ARTICLES = gql`
	query getArticles($any: ArticleInput!, $count: Int!) {
		articles(article: $any, count: $count, isTypeahead: true) {
			_id
			id
			title {
				lang
				message
			}
			price
			tax
			unit
		}
	}
`;
