import { useQuery, useMutation } from '@apollo/client';
import {
	MPipeline,
	MModuleDescription,
	MModuleRecordSettings,
	MPage,
	MArticle,
} from 'mango-utils-client';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ScrollView, View } from 'react-native';

import { useHistory, useParams } from '../../../utilities/routing';
import { MundoText, MundoButton } from '../../../components/elements';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { EditableArticleList } from './components/EditableArticleList';
import { destructData } from '../../../utilities/dataFunctions';
import { useArray } from '../../../utilities/hooks/array';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { openDocs } from '../../../utilities/openDocs';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import OrderListSettings from '../../Order/List/Settings';
import messages from './messages';
import { alert } from '../../../utilities/alert';
import { PagesEdit } from './components/PagesEdit';
import { GET_RECORD_SETTINGS, SAVE_RECORD_SETTINGS } from './queries';

export const RecordSettings: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const intl = useIntl();
	const { pipelineId } = useParams<{ pipelineId: string }>();
	const { settings } = useContext(SettingsContext);
	const [pipeline, onChangePipeline] = useState<MPipeline>();

	useEffect(() => {
		onChangePipeline(settings.pipelines.find((p) => p._id === pipelineId));
	}, [settings, pipelineId]);

	const [recordSettings, onChangeSettings] = useState(
		new MModuleRecordSettings(),
	);

	const [pages, dispatchPageAction] = useArray<MPage>();

	const { loading: loadingRecordSettings } = useQuery(GET_RECORD_SETTINGS, {
		skip: !pipelineId,
		variables: {
			pipelineId,
		},
		onCompleted: (data) => {
			if (data) {
				const s = new MModuleRecordSettings(destructData(data));
				onChangeSettings(s);
				dispatchPageAction({
					type: ETypes.RESET_TO,
					item: s.pages,
				});
			}
		},
	});

	const update = (input: Partial<MModuleRecordSettings>) => {
		const next = Object.assign({}, recordSettings, input);
		onChangeSettings(next);
	};
	// save recordSettings
	const [saveSettings] = useMutation(SAVE_RECORD_SETTINGS);
	const saveButtonHandler = async () => {
		// remove empty articles
		dispatchPageAction({
			type: ETypes.RESET_TO,
			item: pages.map((page) => {
				page.articles = page.articles.filter((article) => article._id);
				return page;
			}),
		});

		await saveSettings({
			variables: {
				settings: {
					...recordSettings,
					pages,
					pipelineId,
				},
			},
		});
		history.goBack();
	};

	const checkDuplicates = useCallback(() => {
		const used: MArticle[] = [];
		const badBoy = pages.find((page) => {
			const internalBadBoy = page.articles.find((art) => {
				if (!used.find((u) => u._id === art._id)) {
					used.push(art);
					return false;
				} else {
					return art;
				}
			});
			if (internalBadBoy) {
				return internalBadBoy;
			}
			return false;
		});
		if (badBoy) {
			return badBoy;
		}
	}, [pages]);

	const saveCB = useCallback(
		(cb: () => void) => {
			if (checkDuplicates()) {
				alert(
					capitalizeFirstWord(intl.formatMessage(messages.duplicate)),
					'',
					'error',
				);
			} else {
				cb();
			}
		},
		[intl, checkDuplicates],
	);

	// LOADING
	const loading = loadingRecordSettings;
	if (loading || !pipeline) {
		return <LoadingIndicator />;
	}
	return (
		<ScrollView>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={transformModuleTitle(
							pipeline.modules.find(
								(m) =>
									m.identifier === 'record.vehicleCleaning',
							) || new MModuleDescription(),
						)}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/record?id=recordSettings',
							)
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={[styles.spacedContainer]}>
					{/* pages creation */}
					<PagesEdit
						pages={pages}
						dispatchPageAction={dispatchPageAction}
					/>
				</View>
				<View style={styles.spacedContainer}>
					<View style={[styles.horizontalLayout, { zIndex: 11 }]}>
						<EditableArticleList
							title={messages.essentialArticles}
							onChange={(essentialArticles) =>
								update({ essentialArticles })
							}
							articles={recordSettings.essentialArticles}
						/>
						<EditableArticleList
							title={messages.standardArticles}
							onChange={(standardArticles) =>
								update({ standardArticles })
							}
							articles={recordSettings.standardArticles}
						/>
						<EditableArticleList
							title={messages.recommendedArticles}
							onChange={(recommendedArticles) =>
								update({ recommendedArticles })
							}
							articles={recordSettings.recommendedArticles}
						/>
					</View>
				</View>
				<OrderListSettings
					update={update}
					dashboardFields={recordSettings.dashboardFields}
					noSave
				/>
			</ScrollView>
			{/* TODO: add input fields according to recordSettings model */}
			<FinishButtonGroup
				cyId={'record.vehicleCleaning'}
				saveFunction={() => saveCB(saveButtonHandler)}
				cancelFunction={history.goBack}
			/>
		</ScrollView>
	);
};
