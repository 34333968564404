import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useHistory } from '../../utilities/routing';

import { ELanguages, MPipeline } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../components/elements';
import MundoPicker from '../../components/elements/MundoPicker';
import { LanguageContext } from '../../utilities/contexts/Language';
import { AuthContext } from '../../utilities/hooks/auth';
import { useOrders } from '../../utilities/hooks/order';
import { SettingsContext } from '../../utilities/hooks/settings';
import { useStyle } from '../../utilities/hooks/styles';
import messages from './messages';

export const DashboardTerminal: FC = () => {
	const styles = useStyle();

	const { logOut } = useContext(AuthContext);
	const history = useHistory();
	const { settings } = useContext(SettingsContext);
	const { onChangeLanguage } = useContext(LanguageContext);
	const [selectedPipeline, onChangeSelectedPipeline] = useState<MPipeline>(
		new MPipeline(),
	);
	const { orders } = useOrders({
		initialFilter: { currentState: 'sign', pipeline: selectedPipeline._id },
	});

	useEffect(() => {
		if (settings.pipelines[0]) {
			onChangeSelectedPipeline(settings.pipelines[0]);
		}
	}, [settings]);

	const start = () => {
		history.push(
			`/module/${selectedPipeline._id}/${selectedPipeline.modules[0].identifier}/new`,
		);
	};
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.dashboardTerminalHeader}
					/>
				</View>
				<MundoButton
					dataSet={{ cy: 'logout' }}
					subtype="transparent"
					onPress={() => logOut()}
					title={messages.logOut}
					icon={'out'}
					fontStyles={styles.primaryTextColor}
				/>
			</View>
			<View
				style={[
					{ width: 100 },
					styles.topMargin20,
					styles.leftMargin20,
				]}
			>
				<MundoPicker
					style={[styles.formUnitStandardWidth]}
					value={selectedPipeline._id}
					values={settings.pipelines.map((pipe, index) => {
						return {
							value: pipe._id,
							label: pipe.title || `${index}`,
						};
					})}
					onChange={(next) => {
						onChangeSelectedPipeline(
							settings.pipelines.find(
								(pipe) => pipe._id === next,
							) || new MPipeline(),
						);
					}}
				/>
			</View>
			<View style={[styles.spacedContainer]}>
				<MundoText
					styles={[styles.primaryTextColor, styles.boldText]}
					message={messages.selectLanguage}
				/>
				<View
					style={[
						styles.flexContainerAutoWrap,
						{ justifyContent: 'center' },
					]}
				>
					<MundoButton
						onPress={() => {
							onChangeLanguage(ELanguages.de);
							start();
						}}
						title={'DE'}
						icon={'de'}
						styles={styles.terminalLanguageButton}
						fontStyles={[styles.primaryTextColor]}
						vertical
						coloredIcon
					/>
					{/* <MundoButton
						onPress={() => {
							start();
						}}
						title={'PL'}
						icon={'pl'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/>
					<MundoButton
						onPress={() => {
							start();
						}}
						title={'RU'}
						icon={'ru'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/>
					<MundoButton
						onPress={() => {
							start();
						}}
						title={'RO'}
						icon={'ro'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/>

					<MundoButton
						onPress={() => {
							start();
						}}
						title={'SK'}
						icon={'sk'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/> */}
					<MundoButton
						onPress={() => {
							onChangeLanguage(ELanguages.en);
							start();
						}}
						title={'EN'}
						icon={'gb'}
						styles={styles.terminalLanguageButton}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
					/>
					{/* <MundoButton
						onPress={() => {
							start();
						}}
						title={'FR'}
						icon={'fr'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/>
					<MundoButton
						onPress={() => {
							start();
						}}
						title={'NL'}
						icon={'nl'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/>
					<MundoButton
						onPress={() => {
							start();
						}}
						title={'IT'}
						icon={'it'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/>
					<MundoButton
						onPress={() => {
							start();
						}}
						title={'ES'}
						icon={'es'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/>
					<MundoButton
						onPress={() => {
							start();
						}}
						title={'BG'}
						icon={'bg'}
						styles={[
							{
								width: 300,
								height: 100,
								backgroundColor: theme.accentMainColor,
								borderColor: theme.backgroundTertiaryColor,
							},
						]}
						fontStyles={styles.primaryTextColor}
						vertical
						coloredIcon
						disabled
					/> */}
				</View>
			</View>
			<View style={styles.spacedContainer}>
				<MundoText
					styles={[styles.primaryTextColor, styles.boldText]}
					message={messages.signButton}
				/>
				<View
					style={[
						styles.containerFullWidthHorizontalCentered,
						styles.topMargin10,
					]}
				>
					<MundoButton
						dataSet={{ cy: 'sign' }}
						onPress={() =>
							history.push(`/dash/${selectedPipeline._id}/sign`)
						}
						disabled={!orders.length}
						title={messages.sign}
						icon={'sign'}
						styles={[
							styles.formButtonStandardWidth,
							{ height: 80 },
						]}
						fontStyles={styles.primaryTextColor}
						vertical
					/>
				</View>
			</View>
		</View>
	);
};
