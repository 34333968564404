import { defineMessages } from 'react-intl';

export const scope = 'mango.components.package.components';

export default defineMessages({
	delete: {
		id: `${scope}.delete`,
		defaultMessage: 'delete',
	},
	editCalculationBundle: {
		id: `${scope}.editCalculationBundle`,
		defaultMessage: 'calculation article bundle edit',
	},
	calculationBundle: {
		id: `${scope}.calculationBundle`,
		defaultMessage: 'calculation article bundle',
	},
	article: {
		id: `${scope}.article`,
		defaultMessage: 'article',
	},
	amount: {
		id: `${scope}.amount`,
		defaultMessage: 'amount',
	},
	lowerAmount: {
		id: `${scope}.lowerAmount`,
		defaultMessage: 'lower amount',
	},
	useFixedAmount: {
		id: `${scope}.useFixedAmount`,
		defaultMessage: 'use fixed amount',
	},
	usedAmount: {
		id: `${scope}.usedAmount`,
		defaultMessage: 'used amount',
	},
	upperAmount: {
		id: `${scope}.upperAmount`,
		defaultMessage: 'upper amount',
	},
	underflowPrice: {
		id: `${scope}.underflowPrice`,
		defaultMessage: 'underflow price',
	},
	overflowPrice: {
		id: `${scope}.overflowPrice`,
		defaultMessage: 'overflow price',
	},
	usedUnderflowPrice: {
		id: `${scope}.usedUnderflowPrice`,
		defaultMessage: 'used underflow price',
	},
	usedOverflowPrice: {
		id: `${scope}.usedOverflowPrice`,
		defaultMessage: 'used overflow price',
	},
	close: {
		id: `${scope}.close`,
		defaultMessage: 'close',
	},
});
