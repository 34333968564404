import React, { FC, useContext, useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import { EOrderListSettings } from 'mango-utils-client';
import { useHistory } from 'react-router-dom';
import { MundoButton, MundoText } from '../../../../components/elements';
import FinishButtonGroup from '../../../../components/FinishButtonGroup';
import { SettingsContext } from '../../../../utilities/hooks/settings';
import { useStyle } from '../../../../utilities/hooks/styles';
import messages from './messages';
import { IOrderListSettingsProps } from './props';

enum ESwapDirection {
	LEFT,
	RIGHT,
}

const OrderListSettings: FC<IOrderListSettingsProps> = (props) => {
	const styles = useStyle();
	const history = useHistory();

	const { settings, onChangeSettings } = useContext(SettingsContext);
	const [orderFields, onChangeOrderFields] = useState<string[]>([]);
	useEffect(() => {
		if (props.dashboardFields) {
			onChangeOrderFields(props.dashboardFields);
		} else {
			onChangeOrderFields(settings.defaultOrderFields);
		}
	}, [settings, props]);
	const save = () => {
		const next = Object.assign({}, settings, {
			defaultOrderFields: orderFields,
		});
		onChangeSettings(next);
		history.goBack();
	};

	const onCheckHandler = (checked: boolean, field: string) => {
		const fields = Object.assign([], orderFields);
		if (checked) {
			if (!orderFields.includes(field)) {
				fields.push(field);
			}
		} else {
			if (orderFields.includes(field)) {
				const index = fields.indexOf(field);
				fields.splice(index, 1);
			}
		}
		onChangeOrderFields(fields);

		if (props.update) {
			props.update({ dashboardFields: fields });
		} else if (props.onChange) {
			props.onChange(fields);
		}
	};

	const swap = (index: number, direction: ESwapDirection) => {
		const neighbour =
			direction === ESwapDirection.RIGHT ? index + 1 : index - 1;
		if (neighbour >= 0 && neighbour < orderFields.length) {
			// Swap
			orderFields[index] = orderFields.splice(
				neighbour,
				1,
				orderFields[index],
			)[0];
		}
		// Reapply
		onChangeOrderFields([...orderFields]);

		if (props.additionalTableConfig && props.update) {
			props.update({ dashboardFields: [...orderFields] });
		}
	};

	const selectDescription = (field: string) => {
		switch (field) {
			case EOrderListSettings.ID:
				return messages.idDescription;
			case EOrderListSettings.CUSTOMER:
				return messages.customerDescription;
			case EOrderListSettings.PIPELINE:
				return messages.pipelineDescription;
			case EOrderListSettings.STATE:
				return messages.stateDescription;
			case EOrderListSettings.SUBSTATE:
				return messages.substateDescription;
			case EOrderListSettings.USE:
				return messages.useDescription;
			case EOrderListSettings.OVERVIEW:
				return messages.overviewDescription;
			case EOrderListSettings.PDF:
				return messages.pdfDescription;
			case EOrderListSettings.PDF_SENDER:
				return messages.pdfSenderDesc;
			case EOrderListSettings.DATE:
				return messages.dateDescription;
			default:
				if (props.additionalSelectTitle) {
					return props.additionalSelectTitle(field);
				} else {
					return { id: field, defaultMessage: field };
				}
		}
	};

	const selectMessage = (field: string) => {
		switch (field) {
			case EOrderListSettings.ID:
				return messages.id;
			case EOrderListSettings.CUSTOMER:
				return messages.customer;
			case EOrderListSettings.DATE:
				return messages.date;
			case EOrderListSettings.OVERVIEW:
				return messages.overview;
			case EOrderListSettings.PDF:
				return messages.pdf;
			case EOrderListSettings.PDF_SENDER:
				return messages.pdfSender;
			case EOrderListSettings.PIPELINE:
				return messages.pipeline;
			case EOrderListSettings.STATE:
				return messages.state;
			case EOrderListSettings.SUBSTATE:
				return messages.substate;
			case EOrderListSettings.USE:
				return messages.use;
			default:
				if (props.additionalSelectTitle) {
					return props.additionalSelectTitle(field);
				} else {
					return { id: field, defaultMessage: field };
				}
		}
	};

	return (
		<View style={!props.dashboardFields && styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.title}
						styles={styles.headerText}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View style={styles.headerViewSecondary}>
						<View style={styles.headerTitleContainer}>
							<MundoText
								styles={styles.headerSecondaryText}
								message={messages.possibleColums}
							/>
						</View>
					</View>
					<View style={styles.flexContainerAutoWrap}>
						{[
							...Object.keys(EOrderListSettings),
							...(props.additionalTableConfig || []),
						].map((c) => {
							const checked = orderFields.includes(c);
							return (
								<TouchableOpacity
									key={c}
									style={
										checked
											? styles.cardNoFlexSelected
											: styles.cardNoFlex
									}
									onPress={() => onCheckHandler(!checked, c)}
								>
									<MundoText
										message={selectMessage(c)}
										styles={styles.headerSecondaryText}
									/>
									<MundoText
										message={selectDescription(c)}
										styles={styles.topMargin10}
									/>
								</TouchableOpacity>
							);
						})}
					</View>
					{orderFields.length > 0 && (
						<View style={styles.headerViewSecondary}>
							<View style={styles.headerTitleContainer}>
								<MundoText
									styles={styles.headerSecondaryText}
									message={messages.sort}
								/>
							</View>
						</View>
					)}
					{orderFields.length > 8 && (
						<MundoText
							styles={styles.warning}
							message={messages.warning}
						/>
					)}
					<ScrollView horizontal>
						{orderFields.map((field, index) => (
							<View
								key={`selectedField${index}`}
								style={[
									styles.cardNoFlex,
									styles.verticalCenterAligned,
								]}
							>
								<MundoText
									styles={styles.headerSecondaryText}
									message={selectMessage(field)}
								/>
								<View style={styles.horizontalLayout}>
									<MundoButton
										dataSet={{ cy: 'field.left.button' }}
										onPress={() =>
											swap(index, ESwapDirection.LEFT)
										}
										styles={styles.transparentButton}
										icon={'left'}
									/>
									<MundoButton
										dataSet={{ cy: 'field.remove' }}
										onPress={() =>
											onCheckHandler(false, field)
										}
										styles={styles.transparentButton}
										icon={'minus'}
									/>
									<MundoButton
										dataSet={{ cy: 'field.right.button' }}
										onPress={() =>
											swap(index, ESwapDirection.RIGHT)
										}
										styles={styles.transparentButton}
										icon={'right'}
									/>
								</View>
							</View>
						))}
					</ScrollView>
				</View>
			</ScrollView>
			{!props.noSave && (
				<FinishButtonGroup
					cyId={'orders.settings.save'}
					saveFunction={save}
					cancelFunction={() => history.goBack()}
				/>
			)}
		</View>
	);
};

export default OrderListSettings;
