import { defineMessages } from 'react-intl';

export const scope = 'mango.components.elements.MundoInput';

export default defineMessages({
	defaultError: {
		id: `${scope}.defaultError`,
		defaultMessage: 'the field input is not a string',
	},
});
