import { useLazyQuery } from '@apollo/client';
import React, { FC, useCallback } from 'react';
import { View } from 'react-native';

import { MCustomer } from 'mango-utils-client';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import MundoText from '../../../components/elements/MundoText';
import CustomerCard from '../../../container/Customer/card';
import CustomerEdit from '../../../container/Customer/Edit';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';
import { ICustomerPickerProps } from './props';
import { GET_COMPANIES, GET_COMPANY } from './queries';

export const CustomerPicker: FC<ICustomerPickerProps> = ({
	title,
	additionalFilters,
	cyId,
	customer,
	onChange,
}) => {
	const styles = useStyle();
	/**
	 * load company handler
	 */
	const [loadCustomer] = useLazyQuery(GET_COMPANY, {
		onCompleted: (data) => {
			if (data && data.customer) {
				onChange(new MCustomer(data.customer));
			}
		},
	});
	/**
	 * update company
	 * @param c next company _id
	 */
	const updateCustomer = useCallback(
		(c?: { _id: string }) => {
			if (c) {
				loadCustomer({
					variables: { id: c._id },
				});
			} else {
				onChange(c);
			}
		},
		[loadCustomer, onChange],
	);

	const checkCustomer = () => {
		if (!customer) {
			return messages.customerError;
		}
		return;
	};
	/**
	 * render
	 */
	return (
		<View>
			<View style={styles.elevated}>
				<MundoText message={title || messages.selectCustomer} />
				<MundoObjectTypeahead
					cyId={cyId}
					value={customer}
					placeholder={messages.customerName}
					onChange={updateCustomer}
					createComponent={CustomerEdit}
					QUERY={GET_COMPANIES}
					customValidity={checkCustomer}
					additionalFilters={additionalFilters}
				/>
			</View>
			<View style={styles.wideGroupLeftAlignedNoPadding}>
				{customer && <CustomerCard customer={customer} />}
			</View>
		</View>
	);
};
