import {
	MModuleInvoiceSettings,
	MPackage,
	MPaymentMethod,
} from 'mango-utils-client';
import { ETypes } from '../../../../utilities/reducer/array.reducer';

/**
 * Passing the loaded invoice settings to the states of the invoice modules
 * @param response response from db
 * @param dispatchAvailablePackageAction dispatch package method
 * @param onChangeSettingsMethod onChangeSettings method
 */
export function handleInvoiceSettings(
	response: { moduleInvoiceSettings: any },
	dispatchAvailablePackageAction: React.Dispatch<{
		type: ETypes;
		location?: number | undefined;
		item?: MPackage | MPackage[];
	}>,
	onChangeSettingsMethod: React.Dispatch<
		React.SetStateAction<MModuleInvoiceSettings>
	>,
	onChangePaymentMethods: React.Dispatch<
		React.SetStateAction<MPaymentMethod[]>
	>,
) {
	if (response && response.moduleInvoiceSettings) {
		// passing settings to invoice module settings state
		const settingsResponse = new MModuleInvoiceSettings(
			response.moduleInvoiceSettings,
		);
		onChangeSettingsMethod(settingsResponse);

		dispatchAvailablePackageAction({
			type: ETypes.RESET_TO,
			item: settingsResponse.packages,
		});

		onChangePaymentMethods(settingsResponse.paymentMethods);
	}
}
