import gql from 'graphql-tag';

export const GET_TRAILERS = gql`
	query($any: TTrailerInput!, $count: Int!) {
		tTrailers(trailer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
			trailerType
		}
	}
`;

export const GET_TRAILER = gql`
	query getTrailer($id: String!) {
		tTrailer(id: $id) {
			id
			title
			trailerType
			type
			companyID
			chambers
			trailerType
			registrationDate
			huTest
			spTest
			uvvTest
			atpTest
			outerBoilerTest
			innerBoilerTest
			hardnessTest
		}
	}
`;
