import React, { FC, useCallback, useContext, useEffect } from 'react';
import { View } from 'react-native';

import { MSettings } from 'mango-utils-client';
import { MundoCheckBox, MundoText } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import { useDState } from '../../../utilities/hooks/debounce/useDState';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';

export const InteractionConfig: FC = () => {
	const { settings, onChangeSettings } = useContext(SettingsContext);
	const styles = useStyle();
	const handleChange = useCallback(
		(change: Partial<MSettings>) => {
			const next = new MSettings({ ...settings, ...change });
			onChangeSettings(next);
		},
		[settings, onChangeSettings],
	);

	const [doxisMail, debouncedDoxisMail, onChangeDoxisMail] = useDState({
		initialValue: settings.doxisMail,
	});

	useEffect(() => {
		if (debouncedDoxisMail && settings.doxisMail !== debouncedDoxisMail) {
			handleChange({ doxisMail: debouncedDoxisMail });
		}
	}, [settings, debouncedDoxisMail, handleChange]);

	useEffect(() => {
		onChangeDoxisMail(settings.doxisMail);
	}, [settings, onChangeDoxisMail]);

	return (
		<View>
			<View style={styles.headerTitleContainer}>
				<MundoText
					message={messages.integrationConfigHeader}
					styles={styles.headerSecondaryText}
				/>
			</View>
			<View style={[styles.horizontalLayout, styles.topMargin20]}>
				<View>
					<MundoText message={messages.doxis} />
				</View>
				<View style={styles.leftMargin20}>
					<MundoCheckBox
						title={messages.enabled}
						checked={settings.doxis}
						onCheckedChanged={(doxis) => handleChange({ doxis })}
					/>
				</View>
				<View style={styles.leftMargin20}>
					<MundoInput
						value={doxisMail || ''}
						onChangeText={onChangeDoxisMail}
						placeholder={messages.doxisMail}
						length={30}
					/>
				</View>
			</View>
		</View>
	);
};
