import React, { FC } from 'react';
import { View } from 'react-native';

import { MundoButton } from '../../components/elements';
import PDFViewer from '../../components/PDFViewer';
import { config } from '../../utilities/config';
import { useStyle } from '../../utilities/hooks/styles';
import { useHistory, useParams } from '../../utilities/routing';
import { IPDFProps } from './props';

export const PDF: FC<IPDFProps> = (props) => {
	const styles = useStyle();
	const history = useHistory();
	let { title, id } = useParams<{ title: string; id: string }>();
	if (props.id && props.title) {
		title = props.title;
		id = props.id;
	}
	return (
		<View
			{...{ dataSet: { cy: 'PackageList' } }}
			style={styles.containerFullResolution}
		>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoButton
						onPress={
							props.callback ? props.callback : history.goBack
						}
						icon={'left'}
						subtype="transparent"
					/>
				</View>
			</View>
			<View style={styles.spacedContainer}>
				<PDFViewer url={`${config.API_URL}/pdf/${title}/${id}`} />
			</View>
		</View>
	);
};
