import { useMutation } from '@apollo/client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MundoCheckBox, MundoText } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';
import { INITIATE_UPDATE } from './queries';

export const MailEdit: FC = () => {
	const styles = useStyle();
	const { user } = useContext(AuthContext);

	const [loading, onChangeLoading] = useState(false);

	const history = useHistory();
	const [mail, onChangeMail] = useState('');
	const [mailDisplay, onChangeMailDisplay] = useState('');
	const [mailUsername, onChangeMailUsername] = useState('');
	const [mailPassword, onChangeMailPassword] = useState('');
	const [mailPasswordVisibility, onChangeMailPasswordVisibility] = useState(
		false,
	);
	const [mailHost, onChangeMailHost] = useState('');
	const [mailPort, onChangeMailPort] = useState(587);
	const [validationMail, onChangeValidationMail] = useState('');

	useEffect(() => {
		onChangeValidationMail(user.mail);
	}, [user]);

	const [initiateUpdate] = useMutation(INITIATE_UPDATE);
	const saveButtonHandler = async () => {
		onChangeLoading(true);
		await initiateUpdate({
			variables: {
				mail,
				mailDisplay,
				mailUsername,
				mailPassword,
				mailHost,
				validationMail,
				mailPort,
			},
		});

		history.push('/settings');
	};

	if (loading) {
		return <LoadingIndicator />;
	}

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.header}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoInput
						value={mail}
						onChangeText={onChangeMail}
						label={messages.mail}
					/>
					<MundoInput
						value={mailDisplay}
						onChangeText={onChangeMailDisplay}
						label={messages.mailDisplay}
					/>
					<MundoInput
						value={mailHost}
						onChangeText={onChangeMailHost}
						label={messages.mailHost}
					/>
					<MundoInput
						value={`${mailPort}`}
						onChangeText={(text) => {
							if (!isNaN(+text)) {
								onChangeMailPort(+text);
							}
						}}
						label={messages.mailPort}
					/>
					<MundoInput
						value={mailUsername}
						onChangeText={onChangeMailUsername}
						label={messages.mailUsername}
					/>
					<MundoInput
						value={mailPassword}
						onChangeText={onChangeMailPassword}
						label={messages.mailPassword}
						secure={!mailPasswordVisibility}
					/>
					<MundoCheckBox
						title={messages.showPassword}
						checked={mailPasswordVisibility}
						onCheckedChanged={onChangeMailPasswordVisibility}
					/>
					<MundoInput
						value={validationMail}
						onChangeText={onChangeValidationMail}
						label={messages.validationMail}
					/>
				</View>
				<View style={styles.spacedContainer}>
					<MundoText message={messages.hint} />
				</View>
				<FinishButtonGroup
					cancelFunction={history.goBack}
					saveFunction={saveButtonHandler}
				/>
			</ScrollView>
		</View>
	);
};
