import { StyleProp, ViewStyle } from 'react-native';

export const makeColumnStyle = (size: number) => {
	return {
		flexBasis: `${size}%`,
		height: 45,
		justifyContent: 'center',
		paddingLeft: 5,
	} as StyleProp<ViewStyle>;
};
