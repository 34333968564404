import React, { FC, useContext } from 'react';

import PopUpContainer from './components/PopupContainer';
import Sidebar from './components/Sidebar';
import { AuthContext } from './utilities/hooks/auth';
import Routing, { Redirect, Router, Switch } from './utilities/routing/index';

// container
import Archiv from './container/Archiv/List';
import ArchivOverview from './container/Archiv/Overview';
import ArticleList from './container/Article/List';
import Credit from './container/Credit/Edit';
import Credits from './container/Credit/List';
import CustomerList from './container/Customer/list';
import Dashboard from './container/Dashboard';
import DatevExportList from './container/DatevExport';
import GroupList from './container/Group/List';
import GroupInvoiceList from './container/GroupInvoice/List';
import HomePage from './container/HomePage/';
import { InvoiceProposals } from './container/Invoice/Proposals';
import { StandaloneOrderList } from './container/Order/List/StandaloneOrderList';
import OrderOverview from './container/Order/Overview';
import PackageList from './container/Package/List';
import ParentEdit from './container/ParentEdit';
import ParentModule from './container/ParentModule/';
import PaymentMethods from './container/PaymentMethod/List';
import PDF from './container/PDF';
import PDFSender from './container/PDFSender';
import ScanPage from './container/Scan';
import SettingsScreen from './container/Settings';
import UserList from './container/User/List';
import PasswordChange from './container/User/PasswordChange';
import UserProfile from './container/User/Profile';
// TODO: GET FROM PLUGINS
import StatisticsDashboard from './Plugins/Statistics/container/Dashboard';
import DriverList from './Plugins/VehicleCleaning/container/Driver/list';
import NavisionExportList from './Plugins/VehicleCleaning/container/NavExport';
import QCD from './Plugins/VehicleCleaning/container/QCD';
import TankEntrance from './Plugins/VehicleCleaning/container/TankEntrance';
import TrailerList from './Plugins/VehicleCleaning/container/Trailer/list';
import TruckList from './Plugins/VehicleCleaning/container/Truck/list';
import Overview from './container/Overview';
import { SettingsProvider } from './utilities/hooks/settings';
import PreproductList from './Plugins/VehicleCleaning/container/Preproduct/List';
import Language from './container/Language';

const Route = Routing.Route;

export const AppRouter: FC = () => {
	const { isLoggedIn, user, isAdmin, firstLoadComplete } = useContext(
		AuthContext,
	);

	return (
		<Router>
			<PopUpContainer>
				{isLoggedIn ? (
					<SettingsProvider>
						<Switch>
							<Sidebar>
								{/* open routes */}
								<Route path={'/'} exact component={Overview} />
								<Route
									path={'/scan'}
									exact
									component={ScanPage}
								/>
								<Route
									path={'/profile'}
									exact
									component={UserProfile}
								/>
								{/* dashboard / dashboard for module */}
								<Route
									path={'/dash/:pipelineId'}
									exact
									component={Dashboard}
								/>
								<Route
									path={'/dash/:pipelineId/:title'}
									exact
									component={Dashboard}
								/>
								{/* modules */}
								<Route
									path={'/module/:pipelineId/:title/:id'}
									exact
									component={ParentModule}
								/>
								{/* order overview */}
								<Route
									path={'/order/:id'}
									exact
									component={OrderOverview}
								/>
								{/* parentedit can work without ID */}
								<Route
									path={'/edit/:title'}
									exact
									component={ParentEdit}
								/>
								<Route
									path={'/edit/:title/:id'}
									exact
									component={ParentEdit}
								/>
								<Route
									path={'/edit/:pipelineId/:title/:id'}
									exact
									component={ParentEdit}
								/>
								{/* change password of logged in user */}
								<Route
									path={'/passwordChange/:id'}
									exact
									component={PasswordChange}
								/>
								{/* data lists */}
								<Route
									path={'/orders'}
									exact
									component={StandaloneOrderList}
								/>
								<Route
									path={'/groupInvoices'}
									exact
									component={GroupInvoiceList}
								/>
								<Route
									path={'/credits'}
									exact
									component={Credits}
								/>
								<Route
									path={'/pdf/:title/:id'}
									exact
									component={PDF}
								/>
								<Route
									path={'/customers'}
									esact
									component={CustomerList}
								/>
								{/* Statistics Routes */}
								<Route
									path={'/statistics'}
									exact
									component={StatisticsDashboard}
								/>
								{/* VehicleCleaning Routes */}
								<Route
									path={'/tankEntrance'}
									exact
									component={TankEntrance}
								/>
								<Route
									path={'/qcd/:id'}
									exact
									component={QCD}
								/>
								<Route
									path={'/trailers'}
									esact
									component={TrailerList}
								/>
								<Route
									path={'/trucks'}
									esact
									component={TruckList}
								/>
								<Route
									path={'/drivers'}
									esact
									component={DriverList}
								/>
								<Route
									path={'/preproducts'}
									esact
									component={PreproductList}
								/>
								{/* settings protected (for admins only)*/}
								{isAdmin && (
									<>
										<Route
											path={'/archiv'}
											exact
											component={Archiv}
										/>
										<Route
											path={'/archiv/:id'}
											exact
											component={ArchivOverview}
										/>
										<Route
											path={'/credit'}
											exact
											component={Credit}
										/>
										<Route
											path={'/settings'}
											exact
											component={SettingsScreen}
										/>
										<Route
											path={'/articles'}
											exact
											component={ArticleList}
										/>
										<Route
											path={'/paymentMethods'}
											exact
											component={PaymentMethods}
										/>
										<Route
											path={'/users'}
											exact
											component={UserList}
										/>
										<Route
											path={'/proposals'}
											exact
											component={InvoiceProposals}
										/>
										<Route
											path={'/groups'}
											exact
											component={GroupList}
										/>
										<Route
											path={'/packages'}
											exact
											component={PackageList}
										/>
										<Route
											path={'/navexport'}
											exact
											component={NavisionExportList}
										/>
										<Route
											path={'/datevexport'}
											exact
											component={DatevExportList}
										/>
										<Route
											path={'/sendpdf/:id'}
											exact
											component={PDFSender}
										/>
									</>
								)}
								<Route
									path={'/language'}
									exact
									component={Language}
								/>
								{!user && firstLoadComplete && (
									<Redirect to={'/'} />
								)}
							</Sidebar>
						</Switch>
					</SettingsProvider>
				) : (
					<Switch>
						<Route path={'/'} exact component={HomePage} />
						<Route path={'/qcd/:id'} exact component={QCD} />
						<Route path={'/language'} exact component={Language} />
						<Redirect to={'/'} />
					</Switch>
				)}
			</PopUpContainer>
		</Router>
	);
};
