import { defineMessages } from 'react-intl';

export const scope = 'mango.module.record.page.overview';

export default defineMessages({
	missingBookedItems: {
		id: `${scope}.missingBookedItems`,
		defaultMessage: "some items got booked but aren't checked",
	},
});
