import { gql } from '@apollo/client';

export const SIGN = gql`
	mutation(
		$signatures: [String!]!
		$orderId: String!
		$pdf: String!
		$positions: [Int!]!
	) {
		sign(
			signatures: $signatures
			orderId: $orderId
			pdf: $pdf
			positions: $positions
		)
	}
`;

export const SIGN_MODULE_OUT = gql`
	mutation signOut(
		$state: StateInput!
		$pdfs: [String!]!
		$orderId: String!
		$pipelineId: String!
	) {
		moduleSignOut(
			sign: {
				output: {
					state: $state
					orderId: $orderId
					pipelineId: $pipelineId
					pdfs: $pdfs
				}
			}
		)
	}
`;

export const SIGN_MODULE_IN = gql`
	query($id: String!, $pipelineId: String!) {
		moduleSignIn(id: $id, pipelineId: $pipelineId) {
			id
			orderId
			state {
				title
				tags
			}
		}
	}
`;

export const GET_USER_SIGNATURE = gql`
	query getUser($id: String!) {
		user(id: $id) {
			signature
		}
	}
`;
