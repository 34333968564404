import { useQuery } from '@apollo/client';
import React, { FC, useState } from 'react';
import { View, Text } from 'react-native';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { useStyle } from '../../../utilities/hooks/styles';
import { GET_STATUS } from '../queries';

export const Status: FC = () => {
	const [graphQLServerStatus, onChangeStatus] = useState('polling');
	const pjson = require('../../../../package.json');

	const styles = useStyle();

	useQuery(GET_STATUS, {
		skip: false,
		onCompleted: (data) => onChangeStatus(data.version),
		onError: () => onChangeStatus('failed'),
		pollInterval: 15000,
	});

	return (
		<View
			style={[styles.verticalPadding10, styles.alignCenterHorizontally]}
		>
			<Text>
				{/* improve display show loading while loading, show connected if response, else disconnected */}
				Client Version:{` ${pjson.version}`}
			</Text>
			<Text>
				{/* improve display show loading while loading, show connected if response, else disconnected */}
				Server Version:{' '}
				{graphQLServerStatus === 'polling' ? (
					<LoadingIndicator />
				) : (
					graphQLServerStatus
				)}
			</Text>
		</View>
	);
};
