import { defineMessages } from 'react-intl';

export const scope = 'mango.components.BundledArticleEdit';

export default defineMessages({
	articles: {
		id: `${scope}.articles`,
		defaultMessage: 'articles',
	},
	article: {
		id: `${scope}.article`,
		defaultMessage: 'article',
	},
	addArticle: {
		id: `${scope}.addArticle`,
		defaultMessage: 'add article',
	},
	lowerAmount: {
		id: `${scope}.lowerAmount`,
		defaultMessage: 'lower amount',
	},
	useFixedAmount: {
		id: `${scope}.useFixedAmount`,
		defaultMessage: 'use fixed amount',
	},
	useOverflow: {
		id: `${scope}.useOverflow`,
		defaultMessage: 'use overflow price',
	},
	useUnderflow: {
		id: `${scope}.useUnderflow`,
		defaultMessage: 'use undeflow price',
	},
	amount: {
		id: `${scope}.amount`,
		defaultMessage: 'amount',
	},
	upperAmount: {
		id: `${scope}.upperAmount`,
		defaultMessage: 'upper amount',
	},
	underflowPrice: {
		id: `${scope}.underflowPrice`,
		defaultMessage: 'underflow price',
	},
	overflowPrice: {
		id: `${scope}.overflowPrice`,
		defaultMessage: 'overflow price',
	},
	close: {
		id: `${scope}.close`,
		defaultMessage: 'close',
	},
	articlesError: {
		id: `${scope}.articlesError`,
		defaultMessage: 'the bundle has no articles or the article is invalid',
	},
});
