import { defineMessages } from 'react-intl';

export const scope = 'mango.module.receipt.dashboard';

export default defineMessages({
	createNewOrder: {
		id: `${scope}.createNewOrder`,
		defaultMessage: 'create new order',
	},
});
