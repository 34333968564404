import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import {
	EInvoice,
	EItemSource,
	figureOutPackageApplication,
	IChange,
	MGroupInvoice,
	millisecondsToDays,
	MInvoice,
	MItem,
	MModuleInvoiceSettings,
	MPackage,
	MPaymentMethod,
	MState,
	priceFormatter,
	roundToCents,
} from 'mango-utils-client';
import DatePicker from '../../../components/DatePicker';
import { MundoButton, MundoCheckBox } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import MundoPicker from '../../../components/elements/MundoPicker';
import MundoText from '../../../components/elements/MundoText';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import EditItem from '../../../components/Item/Edit';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { useArray } from '../../../utilities/hooks/array';
import { AuthContext } from '../../../utilities/hooks/auth';
import { timeoutForDebounce } from '../../../utilities/hooks/debounce';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { useHistory, useParams } from '../../../utilities/routing';
import { getTranslation } from '../../../utilities/translations';
import GroupInvoice from './components/groupInvoice';
import { PossiblePackage } from './components/PossiblePackage';
import {
	checkTax,
	getGroupInvoiceEndDate,
	handleChangeConsumption,
	handleInvoiceSettings,
	handlePaymentMethodChange,
	handleRecordState,
} from './functions';
import messages from './messages';
import {
	INVOICE_IN,
	INVOICE_OUT,
	INVOICE_SETTINGS,
	SAVE_INVOICE_SETTINGS,
	SENSE_GROUP_INVOICE,
} from './queries';
import { numericDateOptions } from '../../../utilities/constants';
import { PopUpContext } from '../../../utilities/contexts/PopUp';
import PaymentMethodEdit from '../../PaymentMethod/Edit';
/**
 * invoice module.
 * gets previouse state (module.plugin/done)
 * works with items
 * checks for packages based on booked and/or price levels.
 */
const InvoiceModule: FC = () => {
	const { id, pipelineId } = useParams<{ id: string; pipelineId: string }>();
	const { onChangePopUp, open: openPop, close: closePop } = useContext(
		PopUpContext,
	);
	const history = useHistory();
	const styles = useStyle();
	const { user } = useContext(AuthContext);
	const { language } = useContext(LanguageContext);
	const isTablet = useMediaQuery({ maxDeviceWidth: 1024 });

	// settings
	const [settings, onChangeSettingsMethod] = useState<MModuleInvoiceSettings>(
		new MModuleInvoiceSettings(),
	);
	const [paymentMethods, onChangePaymentMethods] = useState<MPaymentMethod[]>(
		[],
	);
	// state from previous component
	const [recordState, onChangeRecordState] = useState<MState>();
	// displayedItems
	const [items, dispatchItemAction] = useArray<MItem>();
	// group invoice id:
	const [groupInvoice, onChangeGroupInvoice] = useState<MGroupInvoice>();
	// packages
	const [
		availablePackagesFromSettings,
		dispatchAvailablePackageFromSettingsAction,
	] = useArray<MPackage>();
	const [
		availablePackagesFromState,
		dispatchAvailablePackageFromStateAction,
	] = useArray<MPackage>();
	const [
		availablePackages,
		dispatchAvailablePackageAction,
	] = useArray<MPackage>();
	const [packageHistory, dispatchHistoryAction] = useArray<IChange>();
	// paymentmethod selection index
	const [selectedPaymentMethod, onChangePaymentMethod] = useState(0);
	// discount
	const [discountField, onChangeDiscountField] = useState<number>();
	const [discount, onChangeDiscount] = useState(0);
	// issue & due date states
	const [issueDate, onIssueDateChange] = React.useState(Date.now());
	const [dueDate, onDueDateChange] = React.useState(Date.now());
	const [numId, onChangeNumId] = useState<number>();
	const [tax, onChangeTax] = useState<boolean>(true);
	const [proposal, onChangeProposal] = useState<boolean>(false);
	const [prev, onChangePrev] = useState<MInvoice>();
	// loading input
	const { loading: loadingRecordState } = useQuery(INVOICE_IN, {
		variables: { id },
		onCompleted: (response) => {
			handleRecordState(
				response,
				dispatchAvailablePackageFromStateAction,
				dispatchItemAction,
				onChangeRecordState,
				onChangeNumId,
				onChangePrev,
			);
		},
	});
	/**
	 * initial payment method
	 */
	useEffect(() => {
		if (recordState) {
			let wp = recordState.company.defaultPaymentMethod;
			const pbt = recordState.company.paymentMethodByTime;
			if (pbt.length) {
				const now = new Date();
				const day = now.getDay();
				const hour = now.getHours();
				const min = now.getMinutes();
				const current = hour * 100 + min;
				pbt.forEach((pbte) => {
					if (
						pbte.day.length &&
						!(pbte.day as number[]).includes(day)
					) {
						return;
					}
					// make hhmm
					const from = pbte.fromh * 100 + pbte.fromm;
					const to = pbte.toh * 100 + pbte.tom;

					if (from > to) {
						if (current >= from || to >= hour) {
							wp = pbte.paymentMethod;
						}
					} else if (to >= from) {
						if (current >= from && to >= current) {
							wp = pbte.paymentMethod;
						}
					}
				});
			}
			const pmi = paymentMethods.findIndex((pm) => pm._id === wp._id);
			if (pmi > -1) {
				onChangePaymentMethod(pmi);
			}
		}
	}, [recordState, settings, paymentMethods]);
	/**
	 * combine packages
	 */
	useEffect(() => {
		dispatchAvailablePackageAction({
			type: ETypes.RESET_TO,
			item: [
				...availablePackagesFromState,
				...availablePackagesFromSettings,
			],
		});
	}, [
		availablePackagesFromState,
		availablePackagesFromSettings,
		dispatchAvailablePackageAction,
	]);
	/**
	 * data handler after payment method change
	 */
	useEffect(() => {
		handlePaymentMethodChange(
			paymentMethods[selectedPaymentMethod],
			issueDate,
			groupInvoice,
			recordState,
			onDueDateChange,
			onChangeGroupInvoice,
		);
	}, [
		issueDate,
		groupInvoice,
		recordState,
		selectedPaymentMethod,
		paymentMethods,
	]);
	// loading config
	const [saveSettings] = useMutation(SAVE_INVOICE_SETTINGS);
	const { loading: loadingSettings } = useQuery(INVOICE_SETTINGS, {
		variables: {
			pipelineId,
		},
		onCompleted: (response: { moduleInvoiceSettings: any }) => {
			handleInvoiceSettings(
				response,
				dispatchAvailablePackageFromSettingsAction,
				onChangeSettingsMethod,
				onChangePaymentMethods,
			);
		},
	});
	useEffect(() => {
		if (settings.useProposal && !prev) {
			onChangeProposal(true);
		} else {
			onChangeProposal(false);
		}
		const match = paymentMethods.findIndex(
			(pm) => pm._id === prev?.paymentMethod._id,
		);
		if (match >= 0) {
			onChangePaymentMethod(match);
		}
	}, [prev, settings, paymentMethods]);
	const [groupInvoiceQuery, { loading: groupInvoiceLoading }] = useLazyQuery(
		SENSE_GROUP_INVOICE,
		{
			onCompleted: (data) => {
				if (data.senseGroupInvoice.id) {
					onChangeGroupInvoice(
						new MGroupInvoice(data.senseGroupInvoice),
					);
				} else {
					const endDate = getGroupInvoiceEndDate(
						new MState(recordState).company,
					);
					const daysToMsFactor = 1000 * 60 * 60 * 24;
					onChangeGroupInvoice(
						new MGroupInvoice({
							_id: 'new',
							startDate: Date.now(),
							endDate,
							dueDate:
								endDate +
								daysToMsFactor *
									new MState(recordState).company
										.defaultPaymentTime,
						}),
					);
				}
			},
		},
	);
	/**
	 * mom to handle available packages
	 * TODO: figure out a way to lessen this effects impact on performance
	 */
	const possiblePackages = useMemo(() => {
		if (recordState && items.length) {
			const result = figureOutPackageApplication(
				availablePackages,
				items,
				recordState,
			);
			return result;
		}
		return [];
	}, [recordState, items, availablePackages]);
	/**
	 * effect to handle tax
	 */
	useEffect(() => {
		if (recordState) {
			onChangeTax(
				checkTax(
					recordState.company,
					paymentMethods[selectedPaymentMethod],
				),
			);
		}
	}, [recordState, paymentMethods, selectedPaymentMethod]);
	/**
	 * memoized sums
	 */
	const { netSum, taxSum } = useMemo(() => {
		if (recordState && items.length && settings) {
			const surcharge =
				1 +
				(paymentMethods[selectedPaymentMethod]
					? paymentMethods[selectedPaymentMethod].surcharge / 10000
					: 0);
			let ts = 0;
			let ns = 0;

			items
				.filter((item: MItem) => item.checked)
				.forEach((item) => {
					const priceWithFee =
						Math.round(item.price * surcharge) / 1000;
					const priceWIthDiscount =
						priceWithFee * (1 - item.discount / 10000);
					const endprice = roundToCents(
						priceWIthDiscount * item.amount,
					);

					ns += endprice * 1000;
					// * corona tax
					let taxVal =
						(item.tax === 19 ? 16 : item.tax === 7 ? 5 : item.tax) /
						100;
					if (taxVal === 0) {
						taxVal = 0.16;
					}
					ts += endprice * 1000 * taxVal;
				});
			return { netSum: ns, taxSum: tax ? ts : 0 };
		}
		return { netSum: 0, taxSum: 0 };
	}, [
		items,
		paymentMethods,
		recordState,
		selectedPaymentMethod,
		settings,
		tax,
	]);
	const handleNewPaymentMethod = useCallback(
		(pm?: MPaymentMethod) => {
			if (pm) {
				const next = [...paymentMethods, pm];
				onChangePaymentMethods(next);
				saveSettings({
					variables: {
						invoiceSettings: {
							...settings,
							paymentMethods: next,
						},
					},
				});
			}
			closePop();
		},
		[settings, paymentMethods],
	);
	/**
	 * function to apply / consume a change (no need for deletion since effect runs)
	 * @param change change to consume
	 */
	const consumeChange = useCallback(
		(change: IChange, log = true) =>
			handleChangeConsumption(
				change,
				log,
				dispatchHistoryAction,
				dispatchItemAction,
				items,
			),
		[dispatchHistoryAction, dispatchItemAction, items],
	);
	// save invoice
	const [outQuery] = useMutation<{ moduleInvoiceOut: string }>(INVOICE_OUT);
	const save = async () => {
		await timeoutForDebounce();
		const inputState = new MState(recordState);
		const groupInvoiceId = groupInvoice ? groupInvoice._id : null;
		const paymentMethod = paymentMethods[selectedPaymentMethod];
		const surcharge =
			1 + (paymentMethod ? paymentMethod.surcharge / 10000 : 0);
		let type = EInvoice.INVOICE;
		if (proposal) {
			type = EInvoice.PROPOSAL;
		}
		const invoice = new MInvoice({
			_id: prev?._id || undefined,
			id: prev?.id || 0,
			orderId: id,
			paymentMethod,
			issueDate,
			dueDate,
			positions: items
				.filter((item) => item.checked)
				.map(
					(item) =>
						new MItem({
							...item,
							price: Math.round(item.price * surcharge),
							discount: Math.round(item.discount),
						}),
				),
			customer: inputState.company,
			netSum: Math.round(netSum),
			taxSum: Math.round(taxSum),
			grossSum: Math.round(netSum + taxSum),
			groupInvoiceId,
			billingAddress: paymentMethod.customer
				? paymentMethod.customer.billingAddress
				: inputState.company.billingAddress,
			type,
		});
		inputState.user = user;
		inputState.tags = ['pdf:invoice'];
		console.log(inputState, id, invoice, groupInvoice, pipelineId);
		outQuery({
			variables: {
				state: inputState,
				orderId: id,
				invoice,
				groupInvoice,
				pipelineId,
			},
		})
			.then(() => history.push('/dash/' + pipelineId + '/default'))
			.catch((e) => console.error(e));
	};
	// LOADING
	const loading = loadingRecordState || loadingSettings;
	if (loading) {
		return <LoadingIndicator />;
	}
	/**
	 * render
	 */
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/module/invoice?id=rechnungserstellung',
							)
						}
					/>
				</View>
				{!!numId && (
					<MundoText styles={styles.orderIdHeader}>{numId}</MundoText>
				)}
			</View>
			<ScrollView
				contentContainerStyle={
					styles.horizontalScrollViewContainerNoPadding
				}
			>
				<View style={styles.spacedContainer}>
					{/* PAYMENT METHODS */}
					<View
						style={[styles.buttonGroupLeft, styles.bottomMargin20]}
					>
						<View>
							<MundoText
								message={messages.customerAllegiance}
								styles={{ fontWeight: 'bold' }}
							/>
							<MundoText
								message={
									recordState?.company.billingAddress
										? recordState.company.billingAddress
												.country
										: recordState?.company.address.country
								}
								styles={[styles.bottomMargin10]}
							/>
							<MundoText
								message={messages.paymentMethod}
								styles={{ fontWeight: 'bold' }}
							/>
							{groupInvoice && groupInvoice._id !== 'new' ? (
								<View style={styles.topMargin10}>
									<MundoText message={messages.inherited} />
								</View>
							) : (
								<View style={styles.fullSize}>
									<View style={styles.horizontalLayout}>
										<MundoPicker
											values={paymentMethods.map(
												(method, index) => {
													return {
														value: index,
														label: method.title,
													};
												},
											)}
											onChange={(index) => {
												onChangePaymentMethod(index);
											}}
											value={selectedPaymentMethod}
											style={{ flex: 1 }}
										/>
										<MundoButton
											icon={'plus'}
											onPress={() => {
												onChangePopUp(
													<PaymentMethodEdit
														id={'new'}
														callback={
															handleNewPaymentMethod
														}
														stay
													/>,
												);
												openPop();
											}}
										/>
									</View>
								</View>
							)}
							<View style={styles.containerHorizontal}>
								<View>
									<MundoText
										message={messages.issueDate}
										styles={[
											styles.topMargin10,
											styles.bottomMargin10,
											{ fontWeight: 'bold' },
										]}
									/>
									<DatePicker
										style={styles.formUnitStandardWidth}
										value={new Date(issueDate)
											.toISOString()
											.substr(0, 10)}
										onChange={(date) => {
											onIssueDateChange(date);
										}}
									/>
								</View>
								<View style={styles.leftMargin10}>
									<MundoText
										message={messages.dueDate}
										styles={[
											styles.topMargin10,
											styles.bottomMargin10,
											{ fontWeight: 'bold' },
										]}
									/>
									{groupInvoice ? (
										<MundoText>
											{new Date(
												groupInvoice.dueDate,
											).toLocaleDateString(
												'default',
												numericDateOptions,
											)}
										</MundoText>
									) : (
										<DatePicker
											value={new Date(dueDate)
												.toISOString()
												.substr(0, 10)}
											onChange={(date) => {
												onDueDateChange(date);
											}}
										/>
									)}
								</View>
							</View>
							<MundoText
								message={messages.paymentTime}
								styles={[
									styles.topMargin10,
									{ fontWeight: 'bold' },
								]}
							/>
							<MundoText
								dataSet={{ cy: 'invoice.paymentGoal' }}
								message={
									issueDate >= dueDate
										? messages.now
										: undefined
								}
							>
								{issueDate < dueDate &&
									millisecondsToDays(dueDate - issueDate)}
							</MundoText>
							<MundoText
								message={messages.netSum}
								styles={[
									styles.topMargin10,
									{ fontWeight: 'bold' },
								]}
							/>
							<MundoText
								dataSet={{
									cy: 'invoice.netSum',
								}}
							>{`${priceFormatter(netSum / 1000)} €`}</MundoText>
							<View style={styles.horizontalLayout}>
								<View>
									<MundoText
										message={messages.taxSum}
										styles={[
											styles.topMargin10,
											{ fontWeight: 'bold' },
										]}
									/>
									<MundoText
										dataSet={{ cy: 'invoice.taxSum' }}
									>
										{`${priceFormatter(taxSum / 1000)} €`}{' '}
									</MundoText>
								</View>
								<View
									style={[
										styles.leftMargin20,
										styles.topMargin10,
									]}
								>
									<MundoCheckBox
										checked={tax}
										onCheckedChanged={onChangeTax}
									/>
								</View>
							</View>
							<MundoText
								message={messages.grossSum}
								styles={[
									styles.topMargin10,
									{ fontWeight: 'bold' },
								]}
							/>
							<MundoText
								dataSet={{
									cy: 'invoice.grossSum',
								}}
							>{`${priceFormatter(
								(taxSum + netSum) / 1000,
							)} €`}</MundoText>
							<MundoCheckBox
								title={messages.useProposal}
								checked={proposal}
								onCheckedChanged={(c) => onChangeProposal(c)}
							/>
							<View style={[styles.bottomMargin10]}>
								<MundoCheckBox
									cyId={'invoice.groupInvoice.button'}
									title={
										isTablet
											? messages.useGroupInvoiceSmall
											: messages.useGroupInvoice
									}
									checked={!!groupInvoice}
									onCheckedChanged={() => {
										if (groupInvoice) {
											return onChangeGroupInvoice(
												undefined,
											);
										}
										const inputState = new MState(
											recordState,
										);
										groupInvoiceQuery({
											variables: {
												customer: inputState.company,
												date: issueDate,
											},
										});
									}}
								/>
							</View>
						</View>
						{/* INVOICE STATE */}
						<View style={[styles.leftMargin20]}></View>
						<View style={[styles.leftMargin20]}>
							{!!groupInvoice && (
								<GroupInvoice
									groupInvoice={groupInvoice}
									onChange={onChangeGroupInvoice}
								/>
							)}
							{groupInvoiceLoading && <LoadingIndicator />}
						</View>
					</View>
					{recordState && (
						<View>
							<MundoText message={messages.discount} />
							<View style={styles.wideGroupLeftAlignedNoPadding}>
								{!!recordState.company.discounts.length && (
									<MundoPicker
										onChange={(disc: number) => {
											onChangeDiscountField(disc);
										}}
										values={recordState.company.discounts.map(
											(disc) => {
												return {
													value: disc,
													label: `${disc / 100} %`,
												};
											},
										)}
										placeholder={
											messages.discountPlaceholder
										}
									/>
								)}
								<View style={styles.formUnitHeightVertCenter}>
									<MundoInput
										dataSet={{ cy: 'invoice.discount' }}
										value={`${
											discountField && discountField > 0
												? priceFormatter(
														discountField / 100,
												  )
												: ''
										}`}
										onChangeText={(text: string) =>
											onChangeDiscountField(
												+text.replace(/,|\./g, ''),
											)
										}
										placeholder={messages.discount}
										unit={'%'}
										length={6}
									/>
								</View>
								<View
									style={[
										styles.formUnitHeightVertCenter,
										styles.leftMargin20,
									]}
								>
									<MundoButton
										dataSet={{
											cy: 'invoice.discount.apply',
										}}
										onPress={() => {
											items.forEach(
												(item) =>
													(item.discount =
														discountField || 0),
											);
											dispatchItemAction({
												type: ETypes.NOTIFY,
											});
											onChangeDiscount(
												discountField || 0,
											);
										}}
										title={messages.apply}
									/>
								</View>
							</View>
						</View>
					)}
					{/* APPLICABLE PACKAGES */}
					{possiblePackages.length > 0 && (
						<View style={styles.bottomMargin20}>
							<MundoText
								message={messages.packages}
								styles={[
									styles.topMargin10,
									styles.bottomMargin10,
								]}
							/>
							{possiblePackages.map((pack: IChange) => (
								<PossiblePackage
									cyId={getTranslation(pack.title, language)}
									key={getTranslation(pack.title, language)}
									pack={pack}
									consumeChange={consumeChange}
								/>
							))}
						</View>
					)}
					{packageHistory.length !== 0 && (
						<View
							style={[
								styles.formContainer,
								{ justifyContent: 'space-between' },
							]}
						>
							<View style={styles.formUnitHeightVertCenter}>
								<MundoText message={messages.history} />
							</View>
							<View style={styles.formUnitHeightVertCenter}>
								<MundoButton
									icon={'minus'}
									onPress={() => {
										const changeToDeconsume: IChange =
											packageHistory[
												packageHistory.length - 1
											];
										const change: IChange = {
											title: changeToDeconsume.title,
											description:
												changeToDeconsume.description,
											current: changeToDeconsume.changed,
											changed: changeToDeconsume.current,
										};
										consumeChange(change, false);
										dispatchHistoryAction({
											type: ETypes.REMOVE,
											location: packageHistory.length - 1,
										});
									}}
								/>
							</View>
						</View>
					)}
					{packageHistory.map((change, index) => (
						<Text key={index}>
							{getTranslation(change.title, language)}
						</Text>
					))}
					{/* ITEMS */}
					{items.map((item: MItem, index: number) => (
						<View
							style={{ zIndex: 10 + items.length - index }}
							key={item.articleId || index}
						>
							<EditItem
								value={item}
								onChange={(next) =>
									dispatchItemAction({
										type: ETypes.EDIT,
										item: new MItem(next),
										location: index,
									})
								}
							>
								<View style={styles.wideGroupTableColumn8}>
									<Text>
										{' '}
										={' '}
										{priceFormatter(
											((item.amount * item.price) /
												1000) *
												(1 -
													(item.discount || 0) /
														10000),
										)}{' '}
										€
									</Text>
								</View>
							</EditItem>
						</View>
					))}
					<View
						style={[
							styles.horizontalLayout,
							styles.centeredContent,
						]}
					>
						<MundoButton
							dataSet={{ cy: 'invoice.addItem.button' }}
							icon={'plus'}
							styles={{ width: 100 }}
							onPress={() => {
								dispatchItemAction({
									type: ETypes.ADD,
									item: new MItem({
										source: EItemSource.CUSTOM,
										tax: 16, // 19 corona,
										discount,
									}),
								});
							}}
						/>
					</View>
				</View>
			</ScrollView>
			{/* FINISHBUTTONS */}
			<FinishButtonGroup
				cyId={'invoice'}
				saveFunction={save}
				cancelFunction={history.goBack}
			/>
		</View>
	);
};

export default InvoiceModule;
