import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import { MCustomer, MGroupInvoice } from 'mango-utils-client';
import DatePicker from '../../../components/DatePicker';
import { MundoText } from '../../../components/elements';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import useDebounce from '../../../utilities/hooks/debounce';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';
import { IGroupInvoiceFilter } from './props';
import { GET_CUSTOMERS } from './queries';
import { groupInvoiceStyle } from './styles';

const GroupInvoiceFilter: FC<IGroupInvoiceFilter> = (props) => {
	const styles = useStyle();
	const [groupInvoice, onChangeGroupInvoice] = useState<MGroupInvoice>(
		new MGroupInvoice(),
	);
	const [last, onChangeLast] = useState<MGroupInvoice>();
	const [startDate, startDateChange] = useState(Date.now() - 2628000000); // a month ago
	const [lastFrom, lastFromChange] = useState<number>();
	const [endDate, endDateChange] = useState(Date.now());
	const [lastTo, lastToChange] = useState<number>();

	const update = (next: Partial<MGroupInvoice>) => {
		const nGroupInvoice = new MGroupInvoice({ ...groupInvoice, ...next });
		onChangeGroupInvoice(nGroupInvoice);
	};

	const debouncedSearchTerm = useDebounce(groupInvoice);
	const debouncedFrom = useDebounce(startDate);
	const debouncedTo = useDebounce(endDate);

	const loadCB = useCallback((next) => props.onChangeFilter(next), [props]);
	const loadFromCB = useCallback((next) => props.onChangeStartDate(next), [
		props,
	]);
	const loadToCB = useCallback((next) => props.onChangeEndDate(next), [
		props,
	]);

	useEffect(() => {
		if (debouncedSearchTerm && debouncedSearchTerm !== last) {
			onChangeLast(debouncedSearchTerm);
			loadCB(debouncedSearchTerm);
		}
		if (debouncedFrom && debouncedFrom !== lastFrom) {
			lastFromChange(debouncedFrom);
			loadFromCB(debouncedFrom);
		}
		if (debouncedFrom && debouncedFrom !== lastTo) {
			lastToChange(debouncedTo);
			loadToCB(debouncedTo);
		}
	}, [
		debouncedSearchTerm,
		loadCB,
		last,
		lastFrom,
		lastTo,
		debouncedFrom,
		debouncedTo,
		loadFromCB,
		loadToCB,
	]);

	return (
		<View style={groupInvoiceStyle.filterContainer}>
			<View style={styles.containerFullWidthHorizontalCenterAligned}>
				<View
					style={[
						styles.formContainer,
						styles.verticalCenterAligned,
						styles.elevated,
					]}
				>
					<MundoText
						message={messages.customer}
						styles={styles.itemHorizontalMarginSmall}
					/>
					<View>
						<MundoObjectTypeahead
							cyId={'groupInvoice.customer.filter'}
							value={groupInvoice.customer}
							placeholder={messages.customer}
							onChange={(selectedCustomer) =>
								selectedCustomer &&
								update({
									customer: new MCustomer({
										...selectedCustomer,
										title: selectedCustomer.title as string,
									}),
								})
							}
							QUERY={GET_CUSTOMERS}
						/>
					</View>
				</View>
				<View
					style={[styles.formContainer, styles.verticalCenterAligned]}
				>
					<MundoText
						message={messages.from}
						styles={styles.itemHorizontalMarginSmall}
					/>
					<DatePicker
						style={styles.datePicker}
						cyId={'exports.from.date'}
						value={new Date(startDate).toISOString().substr(0, 10)}
						onChange={(date) => {
							if (date <= endDate) {
								startDateChange(date);
								props.onChangeStartDate(date);
							}
						}}
					/>
				</View>
				<MundoText
					message={messages.to}
					styles={styles.itemHorizontalMarginSmall}
				/>
				<DatePicker
					cyId={'exports.to.date'}
					value={new Date(endDate).toISOString().substr(0, 10)}
					onChange={(date) => {
						if (date >= startDate) {
							endDateChange(date);
							props.onChangeEndDate(date);
						}
					}}
				/>
			</View>
		</View>
	);
};

export default GroupInvoiceFilter;
