import { encode } from 'base-64';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { TextInput, View } from 'react-native';

import { validateEmail } from 'mango-utils-client';
import { alert } from '../../../../utilities/alert';
import { config } from '../../../../utilities/config';
import { useStyle } from '../../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import { MundoText } from '../../../../components/elements';
import MundoButton from '../../../../components/elements/MundoButton';
import messages from './messages';

const Login: FC = () => {
	const styles = useStyle();
	const [username, onChangeUsername] = React.useState('');
	const [company, onChangeCompany] = React.useState('');
	const [password, onChangePassword] = React.useState('');
	const [confirmPassword, onChangeConfirmPassword] = React.useState('');
	const [mail, onChangeMail] = React.useState('');
	const intl = useIntl();

	const userRegister = async () => {
		if (password !== confirmPassword || !validateEmail(mail)) {
			alert(
				capitalizeFirstWord(
					intl.formatMessage(messages.incompleteCred),
				),
				capitalizeFirstWord(intl.formatMessage(messages.plsFill)),
				'error',
			);
			return;
		}
		const postOptions = {
			headers: {
				Authorization: 'Basic ' + encode(username + ':' + password),
			},
			body: JSON.stringify({
				title: username,
				company,
				mail,
			}),
			method: 'POST',
		};
		try {
			const fetchResult = await fetch(
				`${config.API_URL}/register`,
				postOptions,
			);
			if (fetchResult.status >= 500) {
				return alert(
					`${fetchResult.status}`,
					capitalizeFirstWord(intl.formatMessage(messages.credTaken)),
					'error',
				);
			}
			const response = await fetchResult.json();
			alert(
				response.message,
				capitalizeFirstWord(intl.formatMessage(messages.loginEnabled)),
				'success',
			);
		} catch (e) {
			alert(
				capitalizeFirstWord(intl.formatMessage(messages.error)),
				e,
				'error',
			);
		}
	};

	return (
		<View
			style={[
				styles.verticalPadding10,
				styles.buttonGroupVertical,
				styles.fullWidth,
				styles.alignCenterHorizontally,
			]}
		>
			<TextInput
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.user),
				)}
				style={[styles.input, styles.fullWidth]}
				onChangeText={onChangeUsername}
				value={username}
			/>
			<TextInput
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.company),
				)}
				style={[styles.input, styles.fullWidth]}
				onChangeText={(text) => onChangeCompany(text)}
				value={company}
			/>
			<TextInput
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.password),
				)}
				style={[styles.input, styles.fullWidth]}
				secureTextEntry={true}
				onChangeText={(text) => onChangePassword(text)}
				value={password}
			/>
			{password !== confirmPassword && (
				<MundoText
					styles={styles.warning}
					message={messages.passwordMissmatch}
				/>
			)}
			<TextInput
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.passwordConfirm),
				)}
				style={[styles.input, styles.fullWidth]}
				secureTextEntry={true}
				onChangeText={(text) => onChangeConfirmPassword(text)}
				value={confirmPassword}
			/>
			{mail !== '' && !validateEmail(mail) && (
				<MundoText
					styles={styles.warning}
					message={messages.mailFormat}
				/>
			)}
			<TextInput
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.mail),
				)}
				style={[styles.input, styles.fullWidth]}
				onChangeText={(text) => onChangeMail(text)}
				value={mail}
			/>
			<MundoButton
				title={messages.register}
				onPress={() => userRegister()}
				styles={styles.fullWidth}
			/>
		</View>
	);
};

export default Login;
