import gql from 'graphql-tag';

export const GET_PACKAGES = gql`
	query getPacks(
		$package: PackageInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		packages(
			package: $package
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			_id
			id
			title {
				lang
				message
			}
		}
	}
`;
