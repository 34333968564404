import { useQuery } from '@apollo/client';
import { EUserClassification } from 'mango-utils-client';
import React, { FC, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

// import  GraphQL query from file
import { MundoButton, MundoText } from '../../../components/elements';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory, useParams } from '../../../utilities/routing';
import { IDashboardProps } from '../../Dashboard/props';
import OrderList from '../../Order/List';
import messages from './messages';
import { GET_SIGN_SETTINGS } from './queries';

const SignDashboard: FC<IDashboardProps> = () => {
	const styles = useStyle();
	const { pipelineId } = useParams<{ pipelineId: string }>();
	const { user } = useContext(AuthContext);
	const [dashboardFields, onChangeDashboardFields] = useState<string[]>([]);
	const history = useHistory();
	// load settings
	const { loading } = useQuery(GET_SIGN_SETTINGS, {
		variables: {
			pipelineId,
		},
		onCompleted: (data) => {
			if (data.moduleSignSettings) {
				onChangeDashboardFields(
					data.moduleSignSettings.dashboardFields,
				);
			}
		},
	});

	if (loading) {
		return <LoadingIndicator />;
	}

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<MundoText
					message={messages.title}
					styles={styles.headerText}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList
						state={'sign'}
						pipeline={pipelineId}
						tableConfig={
							dashboardFields.length ? dashboardFields : undefined
						}
						noFilter
						noHeader
					/>
				</View>
			</ScrollView>
			{user.classification === EUserClassification.TERMINAL && (
				<View style={styles.horizontalLayout}>
					<MundoButton icon={'left'} onPress={history.goBack} />
				</View>
			)}
		</View>
	);
};

export default SignDashboard;
