import React, { FC, useContext } from 'react';
import { TextInput, View } from 'react-native';

import { EArticleInput, MItem } from 'mango-utils-client';
import unitMessages from '../../messages';
import { useStyle } from '../../utilities/hooks/styles';
import MundoCheckBox from '../elements/MundoCheckBox';
import MundoText from '../elements/MundoText';
import { IItemEditProps } from './props';
import { LanguageContext } from '../../utilities/contexts/Language';
import { getTranslation } from '../../utilities/translations';

const Item: FC<IItemEditProps> = ({ value, onChange, children }) => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);

	const update = (next: Partial<MItem>) => {
		if (!onChange) {
			return;
		}
		onChange(new MItem({ ...value, ...next }));
	};

	return (
		<View style={[styles.articleItem, styles.articleItemRow]}>
			<View>
				<MundoCheckBox
					cyId={`receipt.${value.title}.check`}
					checked={value.checked}
					onCheckedChanged={(checked: boolean) => update({ checked })}
				/>
			</View>
			<MundoText>
				{value.short + ' ' + getTranslation(value.title, language)}
			</MundoText>
			{value.checked && value.essentials.includes(EArticleInput.AMOUNT) && (
				<>
					<TextInput
						{...{
							dataSet: {
								cy: `receipt.${value.title}.input`,
							},
						}}
						placeholder={value.unit}
						value={`${value.amount}`}
						onChangeText={(text) => {
							if (!isNaN(+text)) {
								update({ amount: +text });
							}
						}}
					/>
					<MundoText message={unitMessages[value.unit]} />
				</>
			)}
			{children}
		</View>
	);
};

export default Item;
