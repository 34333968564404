import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.TruckFilter';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID',
	},
	companyName: {
		id: `${scope}.companyName`,
		defaultMessage: 'company name',
	},
	licensePlate: {
		id: `${scope}.licensePlate`,
		defaultMessage: 'license plate',
	},
});
