import { useLazyQuery } from '@apollo/client';
import { MUser } from 'mango-utils-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_USERS } from './queries';

interface IUseUsersProps {
	skip: boolean;
	orderBy?: string | undefined;
}

export const useUsers = (props: IUseUsersProps) => {
	const [users, onChangeUsers] = useState<MUser[]>([]);
	const [filter, onChangeFilter] = useState<MUser>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			user: filter,
			start: users.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, users, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_USERS, {
		onCompleted: (data) => {
			if (data) {
				if (!data.users.length || data.users.length % 20 !== 0) {
					setLoadMoreActive(false);
				}
				if (reset) {
					onChangeUsers(data.users);
					onChangeReset(false);
				} else {
					onChangeUsers([...users, ...data.users]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				user: filter,
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.orderBy]);

	return { users, loading, loadMore, onChangeFilter, loadMoreActive };
};
