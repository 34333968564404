import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../utilities/constants';

const PopUpContainerStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		floater: {
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			alignContent: 'center',
			width: '100%',
			height: '100%',
			zIndex: 25,
		},
		content: {
			borderStyle: 'solid',
			borderColor: 'black',
			backgroundColor: theme.backgroundSecondaryColor,
			maxWidth: 1980,
			minWidth: 750,
			width: '90%',
			maxHeight: 1080,
			borderWidth: 1,
			margin: 'auto',
		},
		closer: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			opacity: 0.25,
			backgroundColor: 'black',
		},
	});

export default PopUpContainerStyles;
