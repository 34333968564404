import React, { FC } from 'react';
import { Image, ScrollView, View } from 'react-native';

import { useMutation } from '@apollo/client';
import { dateFormatter } from 'mango-utils-client';
import DatePicker from '../../../components/DatePicker';
import { MundoButton, MundoText } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import { config } from '../../../utilities/config';
import { useArchivs } from '../../../utilities/hooks/archiv/useArchivs';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';
import { queries } from './queries';

const Archiv: FC = () => {
	const styles = useStyle();

	const history = useHistory();
	const arrow = require('../../../assets/icons/arrow.svg');

	const { orderBy, mode, changeSort } = useSort();
	const {
		onChangeOrderBy,
		archivs,
		loading,
		loadMore,
		onChangeFrom,
		onChangeTo,
		fromDate,
		toDate,
		loadMoreActive,
		refetch,
	} = useArchivs({ sortMode: mode });

	const [restoreInvocie] = useMutation(queries.restore);
	const [restoreGroupInvoice] = useMutation(queries.restoreGroupInvoice);
	/**
	 * restore function
	 */
	const restoreButtonHandler = async (
		id: string,
		isGroupInvoice: boolean,
	) => {
		if (isGroupInvoice) {
			await restoreGroupInvoice({ variables: { id } });
		} else {
			await restoreInvocie({ variables: { id } });
		}
		if (refetch) {
			await refetch();
		}
	};

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.header}
					/>

					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => console.log('TODO')}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoTable
						filter={
							<View
								style={[
									styles.containerFullWidthHorizontalNoPadding,
									styles.bottomMargin20,
									{ alignItems: 'center' },
								]}
							>
								<View>
									<MundoText
										message={messages.from}
										styles={styles.pickerLabelSpacing}
									/>
									<DatePicker
										cyId={'archiv.from.date'}
										value={new Date(fromDate)
											.toISOString()
											.substr(0, 10)}
										onChange={(date) => {
											if (date <= toDate) {
												onChangeFrom(date);
											}
										}}
									/>
								</View>
								<View style={styles.leftMargin20}>
									<MundoText
										message={messages.to}
										styles={styles.pickerLabelSpacing}
									/>
									<DatePicker
										cyId={'archiv.to.date'}
										value={new Date(toDate)
											.toISOString()
											.substr(0, 10)}
										onChange={(date) => {
											if (date >= fromDate) {
												onChangeTo(date);
											}
										}}
									/>
								</View>
							</View>
						}
						entrys={[
							{
								header: messages.orderId,
								size: 15,
								identifier: 'orderId',
							},
							{
								header: messages.invoiceId,
								size: 15,
								identifier: 'invoiceId',
							},
							{
								header: messages.date,
								size: 15,
								identifier: 'date',
							},
							{ size: 15, identifier: 'pdfButton' },
							{ size: 25, identifier: 'restore' },
							{ size: 4, identifier: 'arrow' },
						]}
						rows={archivs.map((a, i) => {
							return {
								onPress: () =>
									history.push(`/archiv/${a.invoiceId}`),
								key: `${a._id}.${i}`,
								values: [
									{
										identifier: 'orderId',
										content: (
											<MundoText>{a.orderId}</MundoText>
										),
									},
									{
										identifier: 'invoiceId',
										content: (
											<MundoText>{a.invoiceId}</MundoText>
										),
									},
									{
										identifier: 'date',
										content: (
											<MundoText>
												{dateFormatter(
													new Date(a.date),
												)}
											</MundoText>
										),
									},
									{
										identifier: 'pdfButton',
										content: (
											<View>
												{a.PDFexists && (
													<MundoButton
														subtype="small"
														fontStyles={
															styles.smallButtonFontProps
														}
														styles={[
															styles.smallButtonLayoutProps,
															styles.buttonBorderProps,
														]}
														title={messages.pdf}
														onPress={() =>
															// TODO: find generic react native solution
															window.open(
																`${config.API_URL}/pdfSummary/${a.invoiceId}`,
															)
														}
													/>
												)}
											</View>
										),
									},
									{
										identifier: 'restore',
										content: (
											<MundoButton
												subtype="small"
												fontStyles={[
													styles.smallButtonFontProps,
												]}
												styles={[
													styles.smallButtonLayoutProps,
													styles.buttonBorderProps,
												]}
												title={messages.restore}
												onPress={() =>
													restoreButtonHandler(
														a._id,
														a.isGroupInvoice,
													)
												}
											/>
										),
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={arrow}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
							};
						})}
						onChangeSort={(id) => {
							onChangeOrderBy(id);
							changeSort(id);
						}}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default Archiv;
