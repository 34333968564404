import gql from 'graphql-tag';

export const RESTORE_INVOICE = gql`
	mutation restore($id: String!) {
		restore(id: $id)
	}
`;

export const RESTORE_GROUPINVOICE = gql`
	mutation restoreGroupInvoice($id: String!) {
		restoreGroupInvoice(id: $id)
	}
`;

export const queries = {
	restore: RESTORE_INVOICE,
	restoreGroupInvoice: RESTORE_GROUPINVOICE,
};
