import gql from 'graphql-tag';

export const GET_ACHIVED_INVOICE = gql`
	query getArchivedInvoice($id: String!) {
		getArchivedInvoice(id: $id) {
			id
			_id
			orderId
			positions {
				amount
				title {
					lang
					message
				}
				short
				description {
					lang
					message
				}
				price
				tax
				unit
				dataUnit {
					lang
					message
				}
				subArticles {
					title {
						lang
						message
					}
					price
					tax
					dataUnit {
						lang
						message
					}
					unit
					articleType
					articleId
					comment
				}
				tags
				essentials
				mandatory
				articleType
				articleId
				source
				amount
				discount
				checked
				highlighted
				data
				comment
			}
			issueDate
			dueDate
			netSum
			taxSum
			grossSum
			discountSum
			paymentMethod {
				title
			}
			customer {
				title
				subTitle
				firstName
				lastName
				phone
				fax
				mail
				address {
					street
					city
					country
					zipCode
				}
			}
			billingAddress {
				street
				city
				country
				zipCode
			}
		}
	}
`;
