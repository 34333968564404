import { defineMessages } from 'react-intl';

export const scope = 'mango.utility.useId';

export default defineMessages({
	idError: {
		id: `${scope}.idError`,
		defaultMessage: 'id is not available or invalid',
	},
	verify: {
		id: `${scope}.verify`,
		defaultMessage: 'verifying...',
	},
});
