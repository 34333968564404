import { defineMessages } from 'react-intl';

export const scope = 'mango.components.Overview';

export default defineMessages({
	createNewOrder: {
		id: `${scope}.createNewOrder`,
		defaultMessage: 'create new order',
	},
	title: {
		id: `${scope}.overviePageTitle`,
		defaultMessage: 'Overview',
	},
});
