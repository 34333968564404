import React, { FC, useContext } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';

import { EUserClassification, MTTruck } from 'mango-utils-client';
import icons from '../../../../../assets';
import { MundoButton } from '../../../../../components/elements';
import MundoTable from '../../../../../components/elements/MundoTable';
import MundoText from '../../../../../components/elements/MundoText';
import { useSort } from '../../../../../utilities/hooks/sort';
import { useStyle } from '../../../../../utilities/hooks/styles';
import { openDocs } from '../../../../../utilities/openDocs';
import { useHistory } from '../../../../../utilities/routing';
import { useTrucks } from '../../../hooks/truck';
import TruckFilter from '../filter';
import messages from './messages';
import { ITruckList } from './props';
import { AuthContext } from '../../../../../utilities/hooks/auth';

const TruckList: FC<ITruckList> = (props) => {
	const styles = useStyle();
	const history = useHistory();

	const { orderBy, mode, changeSort } = useSort();
	const { user } = useContext(AuthContext);
	const {
		trucks: serverTrucks,
		loading,
		loadMore,
		onChangeFilter,
		loadMoreActive,
	} = useTrucks({ skip: !!props.data, orderBy });
	const trucks: MTTruck[] = props.data || serverTrucks;

	return (
		<View style={!props.sub && styles.containerFullResolution}>
			<View
				style={
					props.sub ? styles.headerViewSecondary : styles.headerView
				}
			>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.trucks}
						styles={
							props.sub
								? styles.headerSecondaryText
								: styles.headerText
						}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/equipmentmanager?id=zugmaschinen',
							)
						}
					/>
				</View>
				{!props.cantCreate && (
					<MundoButton
						dataSet={{ cy: 'truck.create' }}
						icon={'plus'}
						onPress={() =>
							history.push(`/edit/truck.vehicleCleaning/new`)
						}
						disabled={
							user?.classification !==
								EUserClassification.ADMIN &&
							user?.classification !== EUserClassification.MEMBER
						}
					/>
				)}
			</View>
			<ScrollView>
				<View style={!props.sub && styles.spacedContainer}>
					<MundoTable
						filter={
							!props.noFilter && (
								<TruckFilter onChange={onChangeFilter} />
							)
						}
						entrys={[
							{ identifier: 'id', header: messages.id, size: 10 },
							{
								identifier: 'title',
								header: messages.title,
								size: 15,
							},
							{ identifier: 'warn', size: 6 },
							{ identifier: 'arrow', size: 4 },
						]}
						rows={trucks.map((truck) => {
							return {
								key: truck._id,
								onPress: () =>
									history.push(
										`/edit/truck.vehicleCleaning/${truck._id}`,
									),
								values: [
									{
										identifier: 'id',
										content: <Text>{truck.id}</Text>,
									},
									{
										identifier: 'title',
										content: <Text>{truck.title}</Text>,
									},
									{
										identifier: 'warn',
										content:
											!truck.companyID || !truck.title ? (
												<View
													style={
														styles.wideGroupTableColumn6
													}
												>
													<MundoText
														styles={[
															{
																color:
																	'#ff8000',
															},
														]}
														message={
															messages.missingValue
														}
													/>
												</View>
											) : (
												<View
													style={
														styles.wideGroupTableColumn6
													}
												/>
											),
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								dataSet: { cy: truck.title + '.row' },
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default TruckList;
