import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';

// import  GraphQL query from file
import { useQuery } from '@apollo/client';
import { MModuleDescription, MTReceiptSettings } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../../../components/elements';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import { IDashboardProps } from '../../../../../container/Dashboard/props';
import OrderList from '../../../../../container/Order/List';
import { useStyle } from '../../../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../../../utilities/moduleTransformers';
import { openDocs } from '../../../../../utilities/openDocs';
import { useParams } from '../../../../../utilities/routing';
import { pickTableContentExtension } from '../../../extensions/TableContent';
import { pickTableHeaderExtension } from '../../../extensions/TableHeader';
import { GET_TRECEIPT_SETTINGS } from './queries';

const TReceiptDashboard: FC<IDashboardProps> = (props) => {
	const styles = useStyle();
	const { pipelineId } = useParams<{ pipelineId: string }>();

	// fetch and return typed data by using a GraphQL query
	const [tReceiptSettings, onChangeReceiptSettings] = useState(
		new MTReceiptSettings(),
	);
	// load settings
	const { loading: loadingSettings } = useQuery(GET_TRECEIPT_SETTINGS, {
		variables: {
			pipelineId,
		},
		onCompleted: (response: { tReceiptSettings: MTReceiptSettings }) => {
			if (response && response.tReceiptSettings) {
				const receiptSettings = response.tReceiptSettings;

				onChangeReceiptSettings(receiptSettings);
			}
		},
	});

	if (loadingSettings) {
		return <LoadingIndicator />;
	}

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					{props.pipeline && (
						<MundoText
							message={transformModuleTitle(
								props.pipeline.modules.find(
									(m) =>
										m.identifier ===
										'receipt.vehicleCleaning',
								) || new MModuleDescription(),
								true,
							)}
							styles={styles.headerText}
						/>
					)}
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/receipt?id=dashboard',
							)
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList
						tableConfig={tReceiptSettings.dashboardFields}
						additionalTableContent={pickTableContentExtension}
						additionalTableHeader={pickTableHeaderExtension}
						state={'receipt.vehicleCleaning'}
						pipeline={pipelineId}
						noFilter
						noHeader
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default TReceiptDashboard;
