import gql from 'graphql-tag';

export const SAVE_INVOICE_SETTINGS = gql`
	mutation saveInvoiceSettings(
		$invoiceSettings: MModuleInvoiceSettingsInput!
	) {
		saveInvoiceSettings(invoiceSettings: $invoiceSettings) {
			_id
		}
	}
`;

export const GET_PACKAGES = gql`
	query getPacks($any: PackageInput!, $count: Int!) {
		packages(package: $any, count: $count) {
			id
			_id
			title {
				lang
				message
			}
		}
	}
`;

export const GET_PAYMENT_METHODS = gql`
	query getPaymentMethods($any: PaymentMethodInput!, $count: Int!) {
		paymentMethods(paymentMethod: $any, count: $count) {
			id
			_id
			title
		}
	}
`;

export const GET_INVOICE_SETTINGS = gql`
	query getInvoiceSettings($pipelineId: String!) {
		moduleInvoiceSettings(pipelineId: $pipelineId) {
			id
			_id
			title
			packages {
				id
				_id
				title {
					lang
					message
				}
			}
			paymentMethods {
				id
				_id
				title
			}
			dashboardFields
			useProposal
		}
	}
`;
