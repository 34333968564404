import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../utilities/constants';

export const moduleTimelineStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		container: {
			display: 'flex',
			flexDirection: 'row',
		},
		pipeline: {
			marginVertical: 10,
			flexWrap: 'wrap',
			flexDirection: 'row',
		},
		shiftBttns: {
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'center',
			margin: 10,
			flexDirection: 'row',
		},
		module: {
			display: 'flex',
			flexDirection: 'column',
			alignContent: 'center',
			justifyContent: 'space-around',
			backgroundColor: theme.accentTextColor,
			borderColor: theme.borderTertiaryColor,
			borderRadius: 4,
			borderWidth: 1,
			marginRight: 20,
			padding: 10,
		},
	});

export const pipelineViewStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		item: {
			flex: 1,
			justifyContent: 'flex-start',
			alignItems: 'center',
			borderRadius: 0,
			padding: 5,
			flexDirection: 'row',
			flexBasis: '90%',
		},
		buttons: {
			flex: 1,
		},
		pipelineListElement: {
			flex: 1,
			flexDirection: 'row',
			margin: 5,
			borderRadius: 5,
			borderColor: theme.textMainColor,
		},
		pipelineListContainer: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			padding: 0,
			margin: 0,
		},
		pipelineListElementDescription: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		pipelineListModuleDescription: {
			justifyContent: 'center',
			alignItems: 'center',
			margin: 5,
			paddingHorizontal: 20,
			paddingVertical: 10,
			borderRadius: 5,
			borderWidth: 1,
			borderColor: theme.textMainColor,
		},
		pipelineTitle: {
			width: 100,
			height: 50,
			padding: 5,
		},
	});

export const pipelineEditStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		pipelineEditSaveButton: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
		},
		pipelineEditContainer: {
			flex: 1,
			flexDirection: 'column',
			marginTop: 20,
			backgroundColor: theme.backgroundSecondaryColor, // edit pipelinen second box
			borderRadius: 5,
			padding: 20,
		},
		pipelineEditContainerFields: {
			flexDirection: 'row',
		},
		titleInput: {
			width: 500,
		},
	});

export const settingStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		container: {
			flex: 1,
		},
		previewImg: {
			width: 120,
			height: 120,
			borderWidth: 1,
			borderRadius: 60,
			borderColor: theme.borderTertiaryColor,
		},
		logoImgBorder: {
			borderWidth: 1,
			borderColor: theme.borderTertiaryColor,
		},
		card: {
			margin: 10,
			borderColor: 'transparent',
			backgroundColor: 'transparent',
			alignItems: 'center',
		},
		addButton: {
			width: 40,
			backgroundColor: theme.successColor,
		},
		pipelineListContainer: {
			flex: 1,
		},
		button: {
			justifyContent: 'center',
			alignItems: 'center',
			minWidth: 90,
			maxHeight: 30,
			backgroundColor: theme.backgroundMainColor,
			padding: 10,
			margin: 5,
			borderRadius: 5,
		},
	});

export default settingStyles;
