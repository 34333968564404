import { decode } from 'base-64';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { EUserClassification, MUser } from 'mango-utils-client';
import { checkToken, removeTokens } from '../../auth';
import { load } from '../../localStorage';
import { AuthContext } from './context/authContext';
import { AuthProvider } from './provider/authProvider';

export default function useAuth(): {
	isLoggedIn: boolean;
	user: MUser | undefined;
	loading: boolean;
	isHost: boolean;
	isAdmin: boolean;
	level: number;
	checkLogin: (first?: boolean | undefined) => Promise<string>;
	logOut: () => void;
	firstLoadComplete: boolean;
} {
	const [isLoggedIn, onChangeisLoggedIn] = useState(false);
	const [loading, onChangeLoading] = useState(true);
	const [user, onChangeUser] = useState<MUser>();
	const [isHost, onChangeIsHost] = useState<boolean>(false);
	const [firstLoadComplete, onChangeFirstLoad] = useState<boolean>(false);

	const checkLogin = useCallback(
		(first?: boolean) => {
			return new Promise<string>((resolve) => {
				checkToken()
					.then(({ success }) => {
						if (success) {
							load('access_token')
								.then((token) => {
									// check if user needs update
									const payload = JSON.parse(
										decode(
											token
												.split('.')[1]
												.replace('_', '/')
												.replace('-', '+'),
										),
									);
									const parsedUser = new MUser(payload.user);
									const uDiff =
										JSON.stringify(parsedUser) !==
										JSON.stringify(user);

									if (first || uDiff) {
										onChangeUser(parsedUser);
										onChangeIsHost(payload.isHost);
									}
									if (!isLoggedIn) {
										onChangeisLoggedIn(true);
									}
									if (loading) {
										onChangeLoading(false);
									}
									resolve(token);
								})
								.catch((e) => {
									console.error(e);
									onChangeLoading(false);
									resolve('');
								});
						} else {
							onChangeisLoggedIn(false);
							onChangeLoading(false);
							resolve('');
						}
					})
					.catch((e) => {
						console.error(e);
						onChangeLoading(false);
						resolve('');
					});
			});
		},
		[isLoggedIn, loading, user],
	);
	/**
	 * handle init
	 */
	useEffect(() => {
		checkLogin()
			.catch((e) => console.error(e))
			.then(() => {
				onChangeFirstLoad(true);
			});
	}, [checkLogin]);
	/**
	 * handle retrigger
	 */
	const logOut = useCallback(() => {
		removeTokens();
		checkLogin().catch((e) => console.error(e));
	}, [checkLogin]);
	/**
	 * level
	 */
	const level = useMemo(() => {
		let level = 0;
		if (user) {
			user.groups.forEach((g) => {
				if (g.inheritance > level) {
					level = g.inheritance;
				}
			});
		}
		return level;
	}, [user]);

	return {
		isLoggedIn,
		user,
		loading,
		isHost,
		isAdmin: !!user && user.classification === EUserClassification.ADMIN,
		level,
		checkLogin,
		logOut,
		firstLoadComplete,
	};
}

export { AuthProvider };
export { AuthContext };
