import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { TouchableOpacity } from 'react-native';

import { useTheme } from '../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import MundoText from '../MundoText';
import { IMundoCheckLabelProps } from './props';
import checkLabelStyles from './styles';

const MundoCheckLabel: FC<IMundoCheckLabelProps> = (props) => {
	const { theme } = useTheme();
	const intl = useIntl();
	const localStyles = checkLabelStyles;
	return (
		<TouchableOpacity
			{...{ dataSet: props.cyId }}
			onPress={() => {
				if (props.onCheckedChanged) {
					props.onCheckedChanged(!props.checked);
				}
			}}
			style={[
				localStyles(theme).label,
				props.checked
					? localStyles(theme).check
					: localStyles(theme).unchecked,
			]}
		>
			<MundoText
				styles={
					props.checked
						? localStyles(theme).labelChecked
						: localStyles(theme).labelUnchecked
				}
			>
				{capitalizeFirstWord(intl.formatMessage(props.title))}
			</MundoText>
		</TouchableOpacity>
	);
};

export default MundoCheckLabel;
