import React, { FC, memo, useEffect, useState } from 'react';
import { View } from 'react-native';

import { EItemSource, MItem } from 'mango-utils-client';
import useDebounce from '../../../utilities/hooks/debounce';
import { useStyle } from '../../../utilities/hooks/styles';
import MundoCheckBox from '../../elements/MundoCheckBox';
import { IItemEditProps } from './../props';
import { CustomArticle } from './components/CustomItem';
import { StandardArticle } from './components/StandardItem';
import { EPluign, getExtension } from '../../../Plugins';

const EditItem: FC<IItemEditProps> = memo(({ value, onChange }) => {
	const styles = useStyle();
	const [item, onChangeItem] = useState<MItem>(value);
	const [last, onChangeLast] = useState<MItem>();
	const { checked } = item;

	const next = useDebounce<MItem>(item, 250);

	useEffect(() => {
		if (next) {
			if (JSON.stringify(next) !== JSON.stringify(last)) {
				onChangeLast(next);
				if (onChange) {
					onChange(next);
				}
			}
		}
	}, [onChange, last, next]);

	useEffect(() => {
		onChangeItem(value);
	}, [value]);

	return (
		<View
			style={[styles.articleItem, styles.elevated, styles.articleItemRow]}
		>
			<View>
				<MundoCheckBox
					cyId={'invoice.item.checkbox'}
					checked={checked}
					onCheckedChanged={(ch: boolean) =>
						onChangeItem({ ...item, checked: ch })
					}
				/>
			</View>
			{item.source === EItemSource.CUSTOM ? (
				<CustomArticle onChange={onChangeItem} value={item} />
			) : (
				<StandardArticle onChange={onChangeItem} value={item} />
			)}

			<View style={{ position: 'absolute', right: 10 }}>
				{Object.keys(EPluign).map((plugin) => {
					const Extension = getExtension(
						'EditItem',
						plugin as EPluign,
					);

					if (Extension) {
						return (
							<Extension
								key={plugin}
								{...{
									item,
									onChange: (newItem: MItem) => {
										if (onChange) {
											onChange(newItem);
										}
									},
								}}
							/>
						);
					}
				})}
			</View>
		</View>
	);
});

export default EditItem;
