import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../components/elements/MundoInput';
import {
	getTranslation,
	updateTranslation,
} from '../../../../utilities/translations';
import messages from '../messages';
import { IArticleEditTranslateableFieldProps } from '../props';

export const TitleField: FC<IArticleEditTranslateableFieldProps> = ({
	value,
	update,
	lang,
}) => {
	let title = getTranslation(value.title, lang);
	if (!title) {
		title = '';
	}
	return (
		<View>
			<MundoInput
				dataSet={{ cy: 'article.title.input' }}
				label={messages.title}
				onChangeText={(text) =>
					update({
						title: updateTranslation(text, lang, value.title),
					})
				}
				value={title}
			/>
		</View>
	);
};
