import gql from 'graphql-tag';

export const GET_COMPANIES = gql`
	query($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;

export const GET_COMPANY = gql`
	query getCustomer($id: String!) {
		customer(id: $id) {
			id
			_id
			title
			firstName
			lastName
			isPerson
			externalId
			address {
				street
				city
				country
				zipCode
				tags
			}
			billingAddress {
				street
				city
				country
				zipCode
				tags
			}
			taxNr
			tags
			ustID
			allegiance
			defaultPaymentTime
			defaultPaymentMethod {
				_id
				id
				title
				warning
				warningMessage
				color
			}
			enablePaymentMethodByTime
			paymentMethodByTime {
				paymentMethod {
					_id
					id
					title
					warning
					warningMessage
					color
				}
				fromh
				toh
				fromm
				tom
				day
			}
			disabled
			discounts
		}
	}
`;
