import { defineMessages } from 'react-intl';

export const scope = 'mango.container.Archive';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'archive',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	orderId: {
		id: `${scope}.orderId`,
		defaultMessage: 'order ID',
	},
	invoiceId: {
		id: `${scope}.invoiceId`,
		defaultMessage: 'invoice ID',
	},
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	pdf: {
		id: `${scope}.pdf`,
		defaultMessage: 'PDF',
	},
	from: {
		id: `${scope}.from`,
		defaultMessage: 'from',
	},
	to: {
		id: `${scope}.to`,
		defaultMessage: 'to',
	},
	restore: {
		id: `${scope}.restore`,
		defaultMessage: 'restore',
	},
	view: {
		id: `${scope}.view`,
		defaultMessage: 'view',
	},
});
