import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MCustomer, MSettings } from 'mango-utils-client';
import { useIntl } from 'react-intl';
import { MundoCheckBox, MundoText } from '../../components/elements';
import MundoInput from '../../components/elements/MundoInput';
import FinishButtonGroup from '../../components/FinishButtonGroup';
import { alert } from '../../utilities/alert';
import { useArray } from '../../utilities/hooks/array';
import { SettingsContext } from '../../utilities/hooks/settings';
import { useStyle } from '../../utilities/hooks/styles';
import { ETypes } from '../../utilities/reducer/array.reducer';
import { useHistory, useParams } from '../../utilities/routing';
import { capitalizeFirstWord } from '../../utilities/stringFunctions';
import CustomerCard from '../Customer/card';
import messages from './messages';
import { GET_ORDER, PDF_SEND, PDF_SENSE } from './queries';

export const PDFSender: FC = () => {
	const styles = useStyle();
	const { settings } = useContext(SettingsContext);
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const intl = useIntl();
	const [checks, dispatchCheckAction] = useArray<boolean>();
	const [email, onChangeEmail] = useState('');
	const [subject, onChangeSubject] = useState('');
	const [text, onChangeText] = useState('');
	const [customer, onChangeCustomer] = useState<MCustomer>();

	const { data } = useQuery(PDF_SENSE, {
		variables: { id },
		onCompleted: ({ pdfs }) => {
			dispatchCheckAction({
				type: ETypes.RESET_TO,
				item: pdfs.map(() => false),
			});
		},
	});

	useQuery(GET_ORDER, {
		variables: { id },
		onCompleted: ({ order }) => {
			onChangeCustomer(order.customer);
			onChangeEmail(order.customer.mail || '');
			onChangeSubject(
				`${
					(settings as MSettings).host.title
				} System Mail. For Order: ${order.id}`,
			);
			onChangeText(`Containing documents for Order: ${order.id}`);
		},
	});

	const [send] = useMutation(PDF_SEND);

	const selectPdfTitle = (pdf: string) => {
		switch (pdf) {
			case 'ecd':
				return messages.ecd;
			case 'qcd':
				return messages.qcd;
			case 'tankEntrance':
				return messages.tankEntrance;
			case 'deliveryNote':
				return messages.deliveryNote;
			case 'order':
				return messages.order;
			default:
				return pdf;
		}
	};

	return (
		<View
			{...{ dataSet: { cy: 'PDFSender' } }}
			style={styles.containerFullResolution}
		>
			<View style={styles.headerView}>
				<MundoText
					message={messages.header}
					styles={styles.headerText}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainerHorizontal}>
					{data &&
						data.pdfs.map((pdf: string, index: number) => (
							<View key={pdf} style={styles.rightMargin20}>
								<MundoCheckBox
									// pdf should be unique
									checked={checks[index]}
									onCheckedChanged={() => {
										dispatchCheckAction({
											type: ETypes.EDIT,
											item: !checks[index],
											location: index,
										});
									}}
									title={selectPdfTitle(pdf)}
								/>
							</View>
						))}
				</View>
				<View style={styles.spacedContainer}>
					{customer && <CustomerCard customer={customer} />}
					<MundoInput
						label={messages.email}
						onChangeText={onChangeEmail}
						value={email}
						length={30}
					/>
				</View>
				<View style={styles.spacedContainer}>
					<MundoInput
						label={messages.subject}
						onChangeText={onChangeSubject}
						value={subject}
						length={60}
						style={styles.fullWidth}
					/>
					<MundoInput
						label={messages.text}
						onChangeText={onChangeText}
						value={text}
						multiline={6}
						length={60}
						style={styles.fullWidth}
					/>
				</View>

				<FinishButtonGroup
					cancelFunction={() => history.goBack()}
					saveFunction={async () => {
						const pdfs = data.pdfs.filter(
							(pdf: string, index: number) => checks[index],
						);
						if (!pdfs.length) {
							alert(
								capitalizeFirstWord(
									intl.formatMessage(messages.noSelPdfError),
								),
								capitalizeFirstWord(
									intl.formatMessage(
										messages.noSelPdfErrorDescr,
									),
								),
								'error',
							);
							return;
						}
						if (!email) {
							alert(
								capitalizeFirstWord(
									intl.formatMessage(
										messages.noEmailEnteredError,
									),
								),
								capitalizeFirstWord(
									intl.formatMessage(
										messages.noEmailEnteredErrorDescr,
									),
								),
								'error',
							);
							return;
						}
						await send({
							variables: {
								id,
								pdfs,
								email,
								subject,
								text,
							},
						});
						history.goBack();
					}}
				/>
			</ScrollView>
		</View>
	);
};
