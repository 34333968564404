import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { Alpha } from 'mango-utils-client';
import useDebounce from '../debounce';
import messages from './messages';
/**
 * hook to use id auto increment validation
 * @param alpha base object to work with
 * @param QUERY query for collection
 * @param id url id (to determin if new or not)
 */
export function useId(alpha: Alpha, QUERY: any) {
	const [currentID, onChangeCurrentID] = useState(0);
	const [idIsValid, onChangeIdisValid] = useState(false);
	const [unverified, onChangeUnverified] = useState(true);

	const debouncedSearchTerm = useDebounce(currentID);

	const checkIDInput = () => {
		if (unverified) {
			return messages.verify;
		} else if (idIsValid) {
			return;
		}

		return messages.idError;
	};

	const updateID = (nextID: number) => {
		onChangeUnverified(true);
		onChangeCurrentID(nextID);
	};

	const [checkID] = useLazyQuery(QUERY, {
		onCompleted: (data: any) => {
			if (data) {
				onChangeIdisValid(!data.driverIdIsTaken);
				onChangeUnverified(false);
			} else {
				onChangeIdisValid(false);
				onChangeUnverified(true);
			}
		},
	});

	useEffect(() => {
		if (debouncedSearchTerm === alpha.id) {
			onChangeIdisValid(true);
			onChangeUnverified(false);
			return;
		} else if (debouncedSearchTerm === 0 && alpha._id !== '') {
			onChangeIdisValid(false);
			onChangeUnverified(false);
		} else if (debouncedSearchTerm) {
			checkID({
				variables: {
					id: debouncedSearchTerm,
					_id: alpha._id || 'new',
				},
			});
		} else if (debouncedSearchTerm === 0) {
			onChangeIdisValid(true);
			onChangeUnverified(false);
		}
	}, [debouncedSearchTerm, checkID, alpha]);

	return {
		currentID,
		idIsValid,
		unverified,
		checkIDInput,
		updateID,
		onChangeCurrentID,
	};
}
