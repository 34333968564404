import { defineMessages } from 'react-intl';

export const scope = 'mango.customer.filter';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID',
	},
	companyName: {
		id: `${scope}.companyName`,
		defaultMessage: 'company name',
	},
	firstName: {
		id: `${scope}.firstName`,
		defaultMessage: 'first name',
	},
	lastName: {
		id: `${scope}.lastName`,
		defaultMessage: 'last name',
	},
	phoneNumber: {
		id: `${scope}.phoneNumber`,
		defaultMessage: 'phone number',
	},
	email: {
		id: `${scope}.email`,
		defaultMessage: 'e-mail address',
	},
	street: {
		id: `${scope}.street`,
		defaultMessage: 'street',
	},
	city: {
		id: `${scope}.city`,
		defaultMessage: 'city',
	},
	zipCode: {
		id: `${scope}.zipCode`,
		defaultMessage: 'zip code',
	},
	country: {
		id: `${scope}.country`,
		defaultMessage: 'country',
	},
	ustIdNumber: {
		id: `${scope}.ustIdNumber`,
		defaultMessage: 'VAT ID',
	},
	externalId: {
		id: `${scope}.externalId`,
		defaultMessage: 'external id',
	},
	region: {
		id: `${scope}.region`,
		defaultMessage: 'region',
	},
	mailError: {
		id: `${scope}.mailError`,
		defaultMessage: 'the e-mail address is in the wrong format!',
	},
	phoneError: {
		id: `${scope}.phoneError`,
		defaultMessage: 'the phone number is in the wrong format!',
	},
	more: {
		id: `${scope}.more`,
		defaultMessage: 'more',
	},
	less: {
		id: `${scope}.less`,
		defaultMessage: 'less',
	},
});
