import { useQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { View } from 'react-native';

import { MOrder } from 'mango-utils-client';
import { useHistory } from '../../../utilities/routing';
import { MundoButton, MundoText } from '../../../components/elements';
import MundoPadButton from '../../../components/elements/MundoPadButton';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from '../messages';
import { IPipelineDisplayProps } from './props';
import { ORDERS_BY_PIPELINE } from './queries';
import { destructData } from '../../../utilities/dataFunctions';
import { AuthContext } from '../../../utilities/hooks/auth';
import { SidebarContext } from '../../../utilities/contexts/Sidebar';

export const PipelineDisplay: FC<IPipelineDisplayProps> = ({ pipeline }) => {
	const history = useHistory();
	const styles = useStyle();
	const [orderCount, onChangeOrderCount] = useState(0);
	const { onChangeCurrentPipeline } = useContext(SidebarContext);
	const { user, isAdmin, level } = useContext(AuthContext);
	useQuery(ORDERS_BY_PIPELINE, {
		variables: {
			order: new MOrder({
				pipeline: pipeline._id,
				currentState: '^((?!done|canceled).)*$',
			}),
			from: 0,
			to: Date.now,
		},
		onCompleted: (data) => {
			onChangeOrderCount(destructData<MOrder[]>(data).length);
		},
		pollInterval: 10000,
	});
	return (
		<View>
			{orderCount > 0 && (
				<View style={styles.topRightNotification}>
					<MundoText message={`${orderCount}`} />
				</View>
			)}
			<MundoPadButton
				cyId={pipeline._id}
				onPress={() => {
					onChangeCurrentPipeline(pipeline._id);
					history.push('/dash/' + pipeline._id + '/default');
				}}
				title={pipeline.title}
				short={pipeline.short}
			/>
			{(isAdmin ||
				!!pipeline.modules[0].groups.find(
					(onmodG) =>
						level > onmodG.inheritance ||
						user.groups.find((g) => g.title === onmodG.title),
				)) && (
				<MundoButton
					dataSet={{
						cy: 'create.button.' + pipeline._id,
					}}
					styles={styles.dashboardAuftragButton}
					onPress={() => {
						onChangeCurrentPipeline(pipeline._id);
						history.push(
							`/module/${pipeline._id}/${pipeline.modules[0].identifier}/new`,
						);
					}}
					icon={'plus'}
					title={messages.createNewOrder}
				/>
			)}
		</View>
	);
};
