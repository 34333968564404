import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const mundoTextStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		text: {
			fontFamily: 'Roboto',
			fontWeight: 'normal',
			color: theme.textMainColor,
		},
		boldText: {
			fontFamily: 'Roboto',
			fontWeight: '600',
			color: theme.textMainColor,
		},
	});

export default mundoTextStyles;
