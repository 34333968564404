import { defineMessages } from 'react-intl';

export const scope = 'mango.container.SignModule';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Sign',
	},
	noAuth: {
		id: `${scope}.noAuth`,
		defaultMessage:
			'you do not have permission to sign as the remaining participants',
	},
	noSign: {
		id: `${scope}.noSign`,
		defaultMessage: 'you can not sign this document',
	},
	applySavedSignature: {
		id: `${scope}.applySavedSignature`,
		defaultMessage: 'apply your saved signature',
	},
	clear: {
		id: `${scope}.clear`,
		defaultMessage: 'clear',
	},
	signSucess: {
		id: `${scope}signSucess`,
		defaultMessage: 'signing completed',
	},
	signProceed: {
		id: `${scope}.signProceed`,
		defaultMessage: 'you can proceed now',
	},
});
