import { createContext } from 'react';

import { ELanguages } from 'mango-utils-client';
import { alert } from '../../alert';

export const LanguageContext = createContext({
	onChangeLanguage: (language: ELanguages) => {
		alert('language is undefined', language, 'warning');
	},
	language: ELanguages.en,
});
