import React, { FC, useContext } from 'react';
import { View } from 'react-native';

import { MSettings } from 'mango-utils-client';
import { MundoText } from '../../../components/elements';
import MundoPicker from '../../../components/elements/MundoPicker';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';

const second = 1000;
const min = second * 60;
const hour = min * 60;
const day = hour * 24;
const week = day * 7;
const month = week * 4;
const quartal = month * 3;
const year = day * 365;

export const ArchivConfig: FC = () => {
	const { settings, onChangeSettings } = useContext(SettingsContext);
	const styles = useStyle();

	const handleChange = (change: Partial<MSettings>) => {
		const next = new MSettings({ ...settings, ...change });
		onChangeSettings(next);
	};

	return (
		<View>
			<View style={styles.headerTitleContainer}>
				<MundoText
					message={messages.archivConfigHeader}
					styles={styles.headerSecondaryText}
				/>
			</View>
			<View style={styles.formContainer}>
				<View style={styles.topMargin20}>
					<MundoPicker
						style={styles.generalFieldStandardWidth}
						label={messages.interval}
						value={settings.archivInterval}
						values={[
							{ value: min, label: messages.min },
							{ value: hour, label: messages.hour },
							{ value: day, label: messages.day },
							{ value: week, label: messages.week },
							{ value: month, label: messages.month },
							{ value: quartal, label: messages.quartal },
							{ value: year, label: messages.year },
						]}
						onChange={(num) =>
							handleChange({ archivInterval: +num })
						}
					/>
				</View>
				<View style={[styles.topMargin20, styles.leftMargin20]}>
					<MundoPicker
						style={styles.generalFieldStandardWidth}
						label={messages.duration}
						value={settings.archivDuration}
						values={[
							{ value: min, label: messages.min },
							{ value: hour, label: messages.hour },
							{ value: day, label: messages.day },
							{ value: week, label: messages.week },
							{ value: month, label: messages.month },
							{ value: quartal, label: messages.quartal },
							{ value: year, label: messages.year },
						]}
						onChange={(num) =>
							handleChange({ archivDuration: +num })
						}
					/>
				</View>
			</View>
		</View>
	);
};
