import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.utilities.times';

export default defineMessages({
	day: {
		id: `${scope}.day`,
		defaultMessage: 'day',
	},
	hour: {
		id: `${scope}.hour`,
		defaultMessage: 'hour',
	},
	week: {
		id: `${scope}.week`,
		defaultMessage: 'week',
	},
	month: {
		id: `${scope}.month`,
		defaultMessage: 'month',
	},
	quartal: {
		id: `${scope}.quartal`,
		defaultMessage: 'quartal',
	},
	year: {
		id: `${scope}.year`,
		defaultMessage: 'year',
	},
	sunday: {
		id: `${scope}.sunday`,
		defaultMessage: 'sunday',
	},
	monday: {
		id: `${scope}.monday`,
		defaultMessage: 'monday',
	},
	tuesday: {
		id: `${scope}.tuesday`,
		defaultMessage: 'tuesday',
	},
	wednesday: {
		id: `${scope}.wednesday`,
		defaultMessage: 'wednesday',
	},
	thursday: {
		id: `${scope}.thursday`,
		defaultMessage: 'thursday',
	},
	friday: {
		id: `${scope}.friday`,
		defaultMessage: 'friday',
	},
	saturday: {
		id: `${scope}.saturday`,
		defaultMessage: 'saturday',
	},
	january: {
		id: `${scope}.january`,
		defaultMessage: 'january',
	},
	february: {
		id: `${scope}.february`,
		defaultMessage: 'february',
	},
	march: {
		id: `${scope}.march`,
		defaultMessage: 'march',
	},
	april: {
		id: `${scope}.april`,
		defaultMessage: 'april',
	},
	may: {
		id: `${scope}.may`,
		defaultMessage: 'may',
	},
	june: {
		id: `${scope}.june`,
		defaultMessage: 'june',
	},
	juli: {
		id: `${scope}.juli`,
		defaultMessage: 'juli',
	},
	august: {
		id: `${scope}.august`,
		defaultMessage: 'august',
	},
	september: {
		id: `${scope}.september`,
		defaultMessage: 'september',
	},
	oktober: {
		id: `${scope}.oktober`,
		defaultMessage: 'oktober',
	},
	november: {
		id: `${scope}.november`,
		defaultMessage: 'november',
	},
	december: {
		id: `${scope}.december`,
		defaultMessage: 'december',
	},
});
