import React, { FC, useState } from 'react';
import { Dimensions, Image, ScrollView, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

import { useMutation, useQuery } from '@apollo/client';
import { MUser } from 'mango-utils-client';
import { useHistory, useParams } from 'react-router-dom';
import icons from '../../assets';
import { MundoButton } from '../../components/elements';
import MundoInput from '../../components/elements/MundoInput';
import MundoText from '../../components/elements/MundoText';
import FinishButtonGroup from '../../components/FinishButtonGroup';
import ImageSelector from '../../components/ImageSelector/';
import SignaturePad from '../../components/SignaturePad';
import { useStyle } from '../../utilities/hooks/styles';
import { openDocs } from '../../utilities/openDocs';
import messages from './messages';
import { GET_USER, SAVE_USER } from './queries';
import { profileEditStyles } from './styles';

const ProfileEdit: FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const styles = useStyle();

	const [img, onChangeImg] = useState<string>(icons.profile);
	const [user, onChangeUser] = useState<MUser>(new MUser());
	const isTablet = useMediaQuery({ maxDeviceWidth: 1024 });
	const { width } = Dimensions.get('screen');

	useQuery(GET_USER, {
		variables: { id },
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			onChangeUser(new MUser(data.user));
		},
	});

	const update = (input: Partial<MUser>) => {
		const next = Object.assign({}, user, input);
		onChangeUser(next);
	};

	const saveButtonHandler = async () => {
		await saveUser({ variables: { user } });
		history.goBack();
	};

	const [saveUser] = useMutation(SAVE_USER);

	const checkEmailInput = (input: any) => {
		const regex = new RegExp(
			// eslint-disable-next-line
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^$/,
		);

		if (!input.match(regex)) {
			return messages.mailError;
		}
		return;
	};

	const checkPhoneInput = (input: any) => {
		// eslint-disable-next-line
		const regex = new RegExp(/^[0-9]*$/);

		if (!input.match(regex)) {
			return messages.phoneError;
		}
		return;
	};

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.header}
						styles={styles.headerText}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/profile?id=benutzererstellung')
						}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View>
						<Image
							source={{ uri: img }}
							style={profileEditStyles().previewImg}
						/>
					</View>
					<View>
						<ImageSelector
							img={img}
							onChangeImg={(d) => onChangeImg(d)}
						/>
						<MundoInput
							dataSet={{ cy: 'profile.mail.input' }}
							label={messages.mail}
							onChangeText={(mail) => update({ mail })}
							value={user.mail}
							customCheckInvalidity={checkEmailInput}
						/>
						<MundoInput
							dataSet={{ cy: 'profile.phone.input' }}
							label={messages.telephone}
							onChangeText={(telephone) => update({ telephone })}
							value={user.telephone}
							customCheckInvalidity={checkPhoneInput}
						/>
					</View>
					<View style={[styles.horizontalLayout, styles.topMargin10]}>
						<SignaturePad
							value={user.signature}
							onChange={(signature) => update({ signature })}
							height={200}
							width={isTablet ? width / 2 - 80 : 400}
						/>
						<Image
							source={{ uri: user.signature }}
							style={{
								width: isTablet ? width / 2 - 80 : 400,
								height: 200,
							}}
						/>
					</View>
					<View style={styles.buttonGroupLeft}>
						<MundoButton
							title={messages.clear}
							onPress={() => update({ signature: '' })}
						/>
					</View>
				</View>

				<FinishButtonGroup
					cyId={'profile'}
					cancelFunction={history.goBack}
					saveFunction={saveButtonHandler}
				/>
			</ScrollView>
		</View>
	);
};

export default ProfileEdit;
