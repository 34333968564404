import { gql } from '@apollo/client';

export const GET_DRIVERS = gql`
	query getDrivers(
		$driver: TDriver
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		tDrivers(
			driver: $driver
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			_id
			id
			title
			type
			companyID
			phone
			mail
			firstName
			lastName
		}
	}
`;
