import { ELanguages, flattenObject } from 'mango-utils-client';
import { createIntl, createIntlCache } from 'react-intl';
import lang from '../../translations';
import { confirm } from '../alert';
import { config } from '../config';
import { load, save } from '../localStorage';
import { capitalizeFirstWord } from '../stringFunctions';
import messages from './messages';
import { removeTokens } from './removeTokens';

export const refreshToken = async () => {
	// const [locale, setLocale] = useState('de');
	const cache = createIntlCache();

	const language = ((await load('language')) as ELanguages) || ELanguages.en;
	// TODO: get language
	const intl = createIntl(
		{
			locale: language,
			messages: flattenObject(lang[language]),
		},
		cache,
	);

	return new Promise<boolean>((resolve) => {
		load('refresh_token')
			.then(async (rtoken: string) => {
				const postOptions = {
					headers: { Authorization: `Bearer ${rtoken}` },
					method: 'POST',
				};
				try {
					const fetchResult = await fetch(
						`${config.API_URL}/refresh`,
						postOptions,
					);
					if (fetchResult.status === 201) {
						const {
							access_token,
							refresh_token,
						} = await fetchResult.json();
						if (access_token && refresh_token) {
							// TODO: access_token should be used for all actions where a user has to be logged in.
							save('access_token', access_token).catch((e: any) =>
								console.error(e),
							);
							save(
								'refresh_token',
								refresh_token,
							).catch((e: any) => console.error(e));
							return resolve(true);
						}
					} else {
						confirm(
							capitalizeFirstWord(
								intl.formatMessage(messages.timeout),
							),
							capitalizeFirstWord(
								intl.formatMessage(messages.logout),
							),
							() => {
								// @ts-ignore
								window.location.reload();
							},
						);
					}
					removeTokens();
					return resolve(false);
				} catch (e) {
					console.log('encountered error during refresh:', e);
					removeTokens();
					return resolve(false);
				}
			})
			.catch(() => {
				removeTokens();
				return resolve(false);
			});
	});
};
