import React, { FC } from 'react';
import { View } from 'react-native';
import { useParams } from '../../utilities/routing';

import { useStyle } from '../../utilities/hooks/styles';
import InvoiceModule from '../Invoice/Module';
import ReceiptModule from '../Receipt/Module/';
import RecordModule from '../Record/Module';
import SignModule from '../Sign/Module';

// Plugin Imports // TODO: remove from hardcode
import { getModule } from '../../Plugins';

const ParentModule: FC = () => {
	const styles = useStyle();
	const { title } = useParams<{ title: string; pipelineId: string }>();

	// TODO: This has to be more dynamic than switch case and static import
	const pickComponent = () => {
		// TODO: load module better
		switch (title) {
			case 'receipt':
				return <ReceiptModule />;
			case 'record':
				return <RecordModule />;
			case 'invoice':
				return <InvoiceModule />;
			case 'sign':
				return <SignModule />;
			default:
				const Module = getModule(title.split('.')[0]);
				return <Module />;
		}
	};
	const module = pickComponent();
	return <View style={styles.fullSize}>{module}</View>;
};

export default ParentModule;
