import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../../../components/elements/MundoInput';
import { useStyle } from '../../../../../../utilities/hooks/styles';
import messages from '../messages';
import { IDriverFilterFieldProps } from '../props';

export const DriverLastNameFilter: FC<IDriverFilterFieldProps> = ({
	driver,
	update,
}) => {
	const styles = useStyle();

	return (
		<View style={styles.formUnitStandardWidth}>
			<MundoInput
				dataSet={{ cy: 'driver.filter.lastname.input' }}
				label={messages.lastName}
				onChangeText={(lastName) => update({ lastName })}
				value={driver.lastName}
			/>
		</View>
	);
};
