import React, { FC, memo } from 'react';
import { Picker, View } from 'react-native';

import { MundoButton } from '../../../../components/elements';
import MundoText from '../../../../components/elements/MundoText';
import { EPluign } from '../../../../Plugins';
import { useStyle } from '../../../../utilities/hooks/styles';
import { openDocs } from '../../../../utilities/openDocs';
import messages from '../messages';
import { IArticleListHeader } from '../props';

const ArticleListHeader: FC<IArticleListHeader> = memo(
	({ onChange, onPress }) => {
		const styles = useStyle();

		return (
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/settings?id=artikel')
						}
					/>
				</View>
				<View style={styles.horizontalLayout}>
					<Picker
						style={styles.picker}
						{...{ dataSet: { cy: 'articleList.type.picker' } }}
						onValueChange={(itemValue) => onChange(itemValue)}
					>
						{/* TODO: need to map over EPlugin */}
						<Picker.Item label={'Article'} value={'article'} />
						<Picker.Item
							label={'TArticle'}
							value={`article.${EPluign.VehicleCleaning}`}
						/>
					</Picker>
					<MundoButton
						dataSet={{ cy: 'articles.create.button' }}
						title={messages.create}
						onPress={onPress}
					/>
				</View>
			</View>
		);
	},
);

export default ArticleListHeader;
