import { useLazyQuery } from '@apollo/client';
import { MExport } from 'mango-utils-client';
import { useEffect, useMemo, useState } from 'react';
import { destructData } from '../../dataFunctions';
import { DEXPORT_PREVIEW } from './queries';

export const usePreview = () => {
	const [preview, updateLocalPreview] = useState<MExport>();
	const [fromDate, onChangeFrom] = useState<number>(Date.now() - 2628000000);
	const [toDate, onChangeTo] = useState<number>(Date.now());

	const variables = useMemo(() => {
		return {
			from: fromDate,
			to: toDate,
		};
	}, [fromDate, toDate]);

	const [lazyQuery, { loading }] = useLazyQuery(DEXPORT_PREVIEW, {
		onCompleted: (data) => {
			if (data) {
				const response = destructData<MExport>(data);
				updateLocalPreview(response);
			}
		},
		variables,
	});

	useEffect(() => {
		lazyQuery({
			variables: {
				from: fromDate,
				to: toDate,
			},
		});
	}, [lazyQuery, fromDate, toDate]);

	return { onChangeFrom, onChangeTo, loading, fromDate, toDate, preview };
};
