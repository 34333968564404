import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.Settings.Receipt';

export default defineMessages({
	companyName: {
		id: `${scope}.companyName`,
		defaultMessage: 'company name',
	},
	equipmentType: {
		id: `${scope}.equipmentType`,
		defaultMessage: 'equipment type',
	},
	containerNr: {
		id: `${scope}.containerNr`,
		defaultMessage: 'container nr.',
	},
	chambers: {
		id: `${scope}.chamber`,
		defaultMessage: 'chamber',
	},
	lidsForChamber: {
		id: `${scope}.lidsForChamber`,
		defaultMessage: 'lids for chamber',
	},
	classifications: {
		id: `${scope}.classifications`,
		defaultMessage: 'classifications',
	},
	freeFormFields: {
		id: `${scope}.freeFormFields`,
		defaultMessage: 'free form fields',
	},
	packages: {
		id: `${scope}.packages`,
		defaultMessage: 'packages',
	},
	addPackage: {
		id: `${scope}.addPackage`,
		defaultMessage: 'add package',
	},
	articles: {
		id: `${scope}.articles`,
		defaultMessage: 'articles',
	},
	addArticle: {
		id: `${scope}.addArticle`,
		defaultMessage: 'add article',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	header: {
		id: `${scope}.header`,
		defaultMessage: 'vehicle cleaning receipt settings',
	},
	denied: {
		id: `${scope}.denied`,
		defaultMessage: 'operation denied',
	},
	duplicate: {
		id: `${scope}.duplicate`,
		defaultMessage: 'You tried to add an already existing entry',
	},
});
