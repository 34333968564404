import React, { FC } from 'react';
import { View } from 'react-native';

import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import MundoButton from '../MundoButton';
import MundoText from '../MundoText';
import { IMundoCheckBoxProps } from './props';
import checkboxStyles from './styles';

const MundoCheckBox: FC<IMundoCheckBoxProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();

	return (
		<View style={styles.buttonGroupLeft}>
			<MundoButton
				dataSet={{ cy: props.cyId }}
				onPress={() => {
					if (props.onCheckedChanged) {
						props.onCheckedChanged(!props.checked);
					}
				}}
				icon={props.checked ? 'check' : undefined}
				subtype="checkbox"
				styles={checkboxStyles(theme).check}
			/>
			{!!props.title && (
				<View style={checkboxStyles(theme).checkboxLabel}>
					<MundoText message={props.title} />
				</View>
			)}
		</View>
	);
};

export default MundoCheckBox;
