import { defineMessages } from 'react-intl';

export const scope = 'mango.container.Settings';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'settings',
	},
	colorPrimary: {
		id: `${scope}.color.primary`,
		defaultMessage: 'primary color',
	},
	colorSecondary: {
		id: `${scope}.color.secondary`,
		defaultMessage: 'secondary color',
	},
	save: {
		id: `${scope}.save`,
		defaultMessage: 'save',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit',
	},
	clear: {
		id: `${scope}.clear`,
		defaultMessage: 'clear',
	},
	left: {
		id: `${scope}.left`,
		defaultMessage: 'left',
	},
	right: {
		id: `${scope}.right`,
		defaultMessage: 'right',
	},
	users: {
		id: `${scope}.users`,
		defaultMessage: 'users',
	},
	articles: {
		id: `${scope}.articles`,
		defaultMessage: 'articles',
	},
	groups: {
		id: `${scope}.groups`,
		defaultMessage: 'groups',
	},
	packages: {
		id: `${scope}.packages`,
		defaultMessage: 'packages',
	},
	paymentMethods: {
		id: `${scope}.paymentMethods`,
		defaultMessage: 'payment methods',
	},
	pipelines: {
		id: `${scope}.pipelines`,
		defaultMessage: 'pipelines',
	},
	interactions: {
		id: `${scope}.interactions`,
		defaultMessage: 'interactions',
	},
	generalSettings: {
		id: `${scope}.general`,
		defaultMessage: 'general settings',
	},
	changePhoto: {
		id: `${scope}.changePhoto`,
		defaultMessage: 'change company logo',
	},
	moduleTitle: {
		id: `${scope}.moduleTitle`,
		defaultMessage: 'module title',
	},
	orders: {
		id: `${scope}.orders`,
		defaultMessage: 'order list',
	},
	host: {
		id: `${scope}.host`,
		defaultMessage: 'administrating company',
	},
	hostMail: {
		id: `${scope}.hostMail`,
		defaultMessage: 'administrating e-mail',
	},
});
