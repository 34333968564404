import React, { FC, useState } from 'react';
import { View } from 'react-native';

import { useStyle } from '../../utilities/hooks/styles';
import BundledArticleEdit from './Edit';
import { IBundledArticleProps } from './props';
import BundledArticleView from './View';

const BundledArticle: FC<IBundledArticleProps> = (props) => {
	const styles = useStyle();
	const [editMode, changeEditMode] = useState(false);

	return (
		<View style={[styles.fullSize]}>
			{!editMode ? (
				<BundledArticleView
					bundle={props.bundle}
					changeEditMode={changeEditMode}
				/>
			) : (
				<BundledArticleEdit
					bundle={props.bundle}
					changeEditMode={changeEditMode}
					onChange={props.onChange || (() => console.log('illegal'))}
				/>
			)}
		</View>
	);
};

export default BundledArticle;
