import React, { FC } from 'react';
import { ScrollView, View } from 'react-native';

import { MModuleDescription } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { useHistory } from '../../../utilities/routing';
import messages from '../messages';
import { IModuleTimeLineProps } from '../props';
import { moduleTimelineStyles } from '../styles';
import { PipelineEditContext } from './PipelineEdit';

enum ESwapDirection {
	LEFT,
	RIGHT,
}

export const ModuleTimeLine: FC<IModuleTimeLineProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { moduleList, setModuleList } = React.useContext(PipelineEditContext);

	const modules = [...moduleList];

	const history = useHistory();

	const swap = (module: MModuleDescription, direction: ESwapDirection) => {
		const index = modules.indexOf(module, 0);
		const neighbour =
			direction === ESwapDirection.RIGHT ? index + 1 : index - 1;

		if (neighbour >= 0 && neighbour < modules.length) {
			// Swap
			modules[index] = modules.splice(neighbour, 1, modules[index])[0];
			modules.forEach((mod, i) => {
				// add new left neighbour title to tags at index of reusable + 1 or set '' if index 0
				const reuseIndex = mod.tags.indexOf('reusable');
				if (reuseIndex > -1) {
					if (i > 0) {
						mod.tags[reuseIndex + 1] = modules[i - 1].identifier;
					} else {
						mod.tags[reuseIndex + 1] = '';
					}
				}
			});
			// Reapply
			setModuleList([...modules]);
		}
	};

	const removeModulePressHandler = (index: number) => {
		if (index >= 0) {
			const newModuleList = modules;
			newModuleList.splice(index, 1);
			setModuleList([...newModuleList]);
		}
	};

	const openSettings = (moduleTitle: string) => {
		history.push(
			`/edit/${props.pipeline._id}/${moduleTitle}/${
				props.pipeline.id || 'new'
			}`,
		);
	};

	return (
		<View style={moduleTimelineStyles(theme).pipeline}>
			<ScrollView horizontal>
				<View style={moduleTimelineStyles(theme).container}>
					{modules.map((module, index) => (
						// Use module as key since it acts as an identifier for now, later use module.identifier or similar
						<View
							style={moduleTimelineStyles(theme).module}
							key={module.identifier + index}
						>
							<MundoInput
								placeholder={messages.moduleTitle}
								value={module.title}
								style={styles.generalFullWidth}
								onChangeText={(text) => {
									module.title = text;
									setModuleList([...modules]);
								}}
							/>
							<View style={[styles.card, styles.wideGroupTable]}>
								<View style={styles.centeredContent}>
									<MundoText
										isButtonText
										message={transformModuleTitle(module)}
										styles={styles.primaryTextColor}
									/>
								</View>
								<MundoButton
									dataSet={{
										cy: `${module.identifier}.settings`,
									}}
									styles={styles.transparentButton}
									onPress={() =>
										openSettings(module.identifier)
									}
									icon={'settings'}
								/>
							</View>
							<View
								style={moduleTimelineStyles(theme).shiftBttns}
							>
								{index !== 0 && (
									<MundoButton
										dataSet={{ cy: 'module.left.button' }}
										styles={styles.neutralButton}
										onPress={() =>
											swap(module, ESwapDirection.LEFT)
										}
										icon={'left'}
									/>
								)}
								<MundoButton
									dataSet={{
										cy: `module.${module.identifier}.remove.button`,
									}}
									icon={'minus'}
									styles={styles.deleteButton}
									onPress={() =>
										removeModulePressHandler(index)
									}
								/>
								{index !== modules.length - 1 && (
									<MundoButton
										dataSet={{ cy: 'module.right.button' }}
										styles={styles.neutralButton}
										onPress={() =>
											swap(module, ESwapDirection.RIGHT)
										}
										icon={'right'}
									/>
								)}
							</View>
						</View>
					))}
				</View>
			</ScrollView>
		</View>
	);
};
