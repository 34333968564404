import { defineMessages } from 'react-intl';

export const scope = 'mango.components.PasswordChange';
export const errorScope = 'error';

export default defineMessages({
	oldPassword: {
		id: `${scope}.oldPassword`,
		defaultMessage: 'old password',
	},
	newPassword: {
		id: `${scope}.newPassword`,
		defaultMessage: 'new password',
	},
	confirmPassword: {
		id: `${scope}.confirmPassword`,
		defaultMessage: 'confirm new password',
	},
	passwordMissmatch: {
		id: `${scope}.user.password.missmatch`,
		defaultMessage: "passwords don't match",
	},
	passwordChange: {
		id: `${scope}.user.password.change`,
		defaultMessage: 'change password',
	},
	header: {
		id: `${scope}.header`,
		defaultMessage: 'change password',
	},
	cancel: {
		id: `${scope}.cancel`,
		defaultMessage: 'cancel',
	},
	passwordChanged: {
		id: `${scope}.passwordChanged`,
		defaultMessage: 'your password was changed successfully',
	},
	wrongPasswordError: {
		id: `${errorScope}.wrongPasswordError`,
		defaultMessage: 'wrong password',
	},
	error: {
		id: `${errorScope}.error`,
		defaultMessage: 'error',
	},
});
