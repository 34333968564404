import { EInvoice, priceFormatter } from 'mango-utils-client';
import React, { FC, useMemo } from 'react';
import { ScrollView, Text, View } from 'react-native';

import MundoTable from '../../../components/elements/MundoTable';
import MundoText from '../../../components/elements/MundoText';
import { useInvoices } from '../../../utilities/hooks/invoice';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';

export const InvoiceProposals: FC = () => {
	const styles = useStyle();
	const history = useHistory();

	const { orderBy, mode, changeSort } = useSort();
	const {
		invoices,
		loading,
		loadMore,
		// onChangeFilter,
		// onChangeFrom,
		// onChangeTo,
		loadMoreActive,
	} = useInvoices(
		useMemo(() => {
			return { orderBy, filter: { type: EInvoice.PROPOSAL } };
		}, [orderBy]),
	);

	return (
		<View style={[styles.containerFullResolution]}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.header}
					/>
					{/* <MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/proposals?id=liste')
						}
					/> */}
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoTable
						entrys={[
							{ identifier: 'id', header: messages.id, size: 10 },
							{
								identifier: 'orderId',
								header: messages.order,
								size: 10,
							},
							{
								identifier: 'customer',
								header: messages.customer,
								size: 20,
							},
							{
								identifier: 'netSum',
								header: messages.netSum,
								size: 20,
							},
							{
								identifier: 'taxSum',
								header: messages.taxSum,
								size: 20,
							},
							{
								identifier: 'grossSum',
								header: messages.grossSum,
								size: 20,
							},
						]}
						rows={invoices.map((invoice) => {
							return {
								key: invoice._id,
								values: [
									{
										identifier: 'id',
										content: <Text>{invoice.id}</Text>,
									},
									{
										identifier: 'orderId',
										content: (
											<Text>
												{
													(
														invoice.tags.find((t) =>
															t.includes(
																'order-numeric',
															),
														) || ''
													).split('::')[1]
												}
											</Text>
										),
									},
									{
										identifier: 'customer',
										content: (
											<Text>
												{invoice.customer.title}
											</Text>
										),
									},
									{
										identifier: 'netSum',
										content: (
											<Text>
												{priceFormatter(
													invoice.netSum / 1000,
												)}{' '}
												€
											</Text>
										),
									},
									{
										identifier: 'taxSum',
										content: (
											<Text>
												{priceFormatter(
													invoice.taxSum / 1000,
												)}{' '}
												€
											</Text>
										),
									},
									{
										identifier: 'grossSum',
										content: (
											<Text>
												{priceFormatter(
													invoice.grossSum / 1000,
												)}{' '}
												€
											</Text>
										),
									},
								],
								onPress: () => {
									const pId = invoice.tags
										.find((t) => t.includes('pipeline::'))
										?.split('::')[1];
									history.push(
										`/module/${pId}/invoice/${invoice.orderId}`,
									);
								},
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};
