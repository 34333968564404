import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
	query($any: ArticleInput!, $count: Int!) {
		articles(article: $any, count: $count, isTypeahead: true) {
			_id
			id
			title {
				lang
				message
			}
			short
		}
	}
`;

export const SAVE_RECORD_SETTINGS = gql`
	mutation saveRecordSettings($settings: MModuleRecordSettingsInput!) {
		saveRecordSettings(recordSettings: $settings) {
			id
		}
	}
`;

export const GET_RECORD_SETTINGS = gql`
	query getRecordSettings($pipelineId: String!) {
		recordSettings(pipelineId: $pipelineId) {
			id
			_id
			title
			pages {
				id
				_id
				title
				articles {
					id
					_id
					title {
						lang
						message
					}
					short
				}
			}
			dashboardFields
			essentialArticles {
				id
				_id
				title {
					lang
					message
				}
				short
			}
			standardArticles {
				id
				_id
				title {
					lang
					message
				}
				short
			}
			recommendedArticles {
				id
				_id
				title {
					lang
					message
				}
				short
			}
		}
	}
`;
