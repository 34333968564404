import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const checkLabelStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		check: {
			backgroundColor: theme.errorColor,
		},
		unchecked: {
			backgroundColor: theme.backgroundTertiaryColor,
		},
		label: {
			borderColor: theme.backgroundSecondaryColor,
			borderStyle: 'solid',
			borderWidth: 1,
			borderRadius: 5,
			marginRight: 10,
			padding: 10,
		},
		labelChecked: {
			color: theme.accentTextColor,
		},
		labelUnchecked: {
			color: theme.textMainColor,
		},
	});

export default checkLabelStyles;
