import React, { FC, useContext, useEffect } from 'react';
import useSettings, { SettingsContext } from '..';
import { alert } from '../../../alert';
import { AuthContext } from '../../auth';

export const SettingsProvider: FC = (props) => {
	const { settings, onChangeSettings, refetch } = useSettings();
	const { user } = useContext(AuthContext);
	/**
	 * effect to refetch after changing user (login in for example)
	 */
	useEffect(() => {
		refetch().catch((e) => alert('error', e, 'error'));
	}, [refetch, user]);

	return (
		<SettingsContext.Provider value={{ settings, onChangeSettings }}>
			{props.children}
		</SettingsContext.Provider>
	);
};
