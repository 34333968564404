import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../components/elements/MundoInput';
import messages from '../messages';
import { IArticleEditFieldProps } from '../props';

export const ShortCodeField: FC<IArticleEditFieldProps> = ({
	value,
	update,
}) => {
	return (
		<View>
			<MundoInput
				dataSet={{ cy: 'article.short.input' }}
				label={messages.short}
				onChangeText={(short) => update({ short })}
				value={value.short}
			/>
		</View>
	);
};
