import React, { FC } from 'react';
import { TouchableOpacity, View } from 'react-native';

interface IProps {
	onPress?: () => void;
	dataSet?: any;
}
export const ConditionalOnPressWrap: FC<IProps> = ({
	onPress,
	children,
	dataSet,
}) => {
	if (onPress) {
		return (
			<TouchableOpacity
				{...{ dataSet }}
				onPress={
					onPress ||
					(() => {
						/* do nothing */
					})
				}
			>
				{children}
			</TouchableOpacity>
		);
	} else {
		return <View>{children}</View>;
	}
};
