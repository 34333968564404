import { StyleSheet } from 'react-native';
import { IThemeConstants } from './constants';

const styles = (theme: IThemeConstants) =>
	StyleSheet.create({
		buttonGroupScrollview: {
			height: 50,
		},
		buttonGroupScrollviewHigh: {
			minHeight: 60,
		},
		standardZIndex: {
			zIndex: 0,
		},
		buttonGroup: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginHorizontal: 14,
			zIndex: 0,
			marginBottom: 20,
		},
		buttonGroupNoMargin: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginHorizontal: 14,
			zIndex: 0,
			marginBottom: 0,
		},
		buttonGroupPagination: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			zIndex: 0,
			marginBottom: 0,
		},
		filterGroup: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			marginHorizontal: 0,
			zIndex: 2,
			marginVertical: 20,
		},
		filterGroupVertical: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-start',
			marginHorizontal: 0,
			zIndex: 2,
			marginVertical: 20,
		},
		buttonGroupNoPadding: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginHorizontal: 0,
			zIndex: 0,
		},
		buttonGroupLeft: {
			flexDirection: 'row',
			justifyContent: 'flex-start',
		},
		buttonGroupLeftVertCenter: {
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center',
		},
		buttonGroupRight: {},
		buttonGroupVertical: {
			display: 'flex',
			padding: 10,
			flexDirection: 'column',
		},
		wideGroup: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
			padding: 10,
			zIndex: 0,
		},
		wideGroupLeftAligned: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			padding: 10,
			zIndex: 1,
		},
		wideGroupLeftAlignedNoPadding: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			padding: 0,
			zIndex: 1,
		},
		wideGroupSubview: {
			marginRight: 30,
		},
		wideGroupTable: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			width: '100%',
		},
		wideGroupTableColumn: {
			flexBasis: '25%',
			flexGrow: 1,
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumnHeader: {
			backgroundColor: theme.backgroundSecondaryColor,
			height: 45,
			paddingLeft: 5,
		},
		wideGroupTableColumnFooter: {
			borderBottomColor: theme.backgroundSecondaryColor,
			borderBottomWidth: 1,
		},
		wideGroupTableColumn8: {
			flexBasis: '8%',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn2: {
			flexBasis: '2%',
			// flexWrap: 'wrap',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn4: {
			flexBasis: '4%',
			// flexWrap: 'wrap',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn6: {
			flexBasis: '6%',
			// flexWrap: 'wrap',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn10: {
			flexBasis: '10%',
			flexGrow: 1,
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn10NoGrow: {
			flexBasis: '10%',
			flexGrow: 0,
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumnButton: {
			width: 100,
			maxWidth: 120,
			height: 45,
			justifyContent: 'center',
			paddingHorizontal: 5,
			flexGrow: 1,
		},
		wideGroupTableColumnButtonSmall: {
			width: 50,
			maxWidth: 60,
			height: 45,
			justifyContent: 'center',
			paddingHorizontal: 5,
		},
		wideGroupTableColumnNoFlex: {
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn15NoGrow: {
			flexBasis: '15%',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn15NoGrowNoHeight: {
			flexBasis: '15%',
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn15: {
			flexBasis: '15%',
			// flexWrap: 'wrap',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
			flexGrow: 1,
		},

		wideGroupTableColumn20: {
			flexBasis: '20%',
			flexGrow: 1,
			// flexWrap: 'wrap',
			height: 45,
			paddingHorizontal: 5,
			justifyContent: 'center',
		},
		wideGroupTableColumn20NoHeight: {
			flexBasis: '20%',
			flexGrow: 1,
			paddingHorizontal: 5,
			justifyContent: 'center',
		},

		wideGroupTableColumn20NoGrow: {
			flexBasis: '20%',
			// flexWrap: 'wrap',
			height: 45,
			paddingHorizontal: 5,
			justifyContent: 'center',
		},
		wideGroupTableColumn25: {
			flexBasis: '25%',
			// flexWrap: 'wrap',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn25NoGrow: {
			flexBasis: '25%',
			// flexWrap: 'wrap',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		generalCellStyle: {
			justifyContent: 'center',
			paddingLeft: 5,
			height: 45,
		},
		wideGroupTableColumn30: {
			flexBasis: '30%',
			flexGrow: 1,
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		wideGroupTableColumn30NoGrow: {
			flexBasis: '30%',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
		},
		extendedRowHeight: {
			height: 50,
		},
		wideGroupTableColumn60: {
			flexBasis: '60%',
			// flexWrap: 'wrap',
			height: 45,
			flexGrow: 1,
			justifyContent: 'center',
			paddingLeft: 5,
		},

		wideGroupTableColumn90: {
			flexBasis: '90%',
			// flexWrap: 'wrap',
			height: 45,
			justifyContent: 'center',
			paddingLeft: 5,
			flexGrow: 1,
		},

		centeredContent: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignContent: 'center',
		},
		input: {
			padding: 10,
			marginVertical: 5,
			borderColor: theme.borderTertiaryColor,
			borderStyle: 'solid',
			backgroundColor: theme.boderMainColor,
			borderRadius: 0,
			borderWidth: 1,
			minHeight: 40,
			width: 200,
		},
		inputStandardHeight: {
			height: 40,
		},
		card: {
			borderWidth: 1,
			borderColor: theme.borderSecondaryColor,
			backgroundColor: theme.backgroundTertiaryColor,
			margin: 6,
			padding: 20,
			minWidth: 300,
			borderRadius: 10,
			flexDirection: 'column',
			justifyContent: 'space-around',
			minHeight: 60,
			shadowColor: '#e5e5e5',
			shadowOffset: { width: 0, height: 6 },
			shadowOpacity: 0.4,
			shadowRadius: 10,
		},
		cardNoFlex: {
			margin: 6,
			padding: 10,
			backgroundColor: theme.backgroundSecondaryColor,
			borderRadius: 5,
		},
		cardNoFlexSelected: {
			margin: 6,
			padding: 10,
			backgroundColor: theme.backgroundTertiaryColor,
			borderRadius: 5,
		},
		cardDashboard: {
			margin: 10,
			marginTop: 0,
			backgroundColor: theme.backgroundTertiaryColor,
			borderRadius: 5,
			flexDirection: 'column',
			justifyContent: 'space-around',
			minHeight: 60,
		},
		fullCard: {
			marginTop: 6,
			marginBottom: 6,
			padding: 10,
			backgroundColor: theme.borderTertiaryColor,
			borderRadius: 5,
			flexDirection: 'column',
			justifyContent: 'space-around',
			minHeight: 60,
			flex: 1,
		},
		textBlock: {
			marginTop: 6,
			marginBottom: 6,
			padding: 10,
			flexDirection: 'column',
			justifyContent: 'space-around',
			minHeight: 50,
			minWidth: 200,
		},
		textBlockHorizontal: {
			marginTop: 6,
			marginBottom: 6,
			padding: 10,
			flexDirection: 'row',
			justifyContent: 'space-around',
			minHeight: 50,
			minWidth: 200,
		},
		warningCard: {
			marginTop: 6,
			marginBottom: 6,
			padding: 10,
			backgroundColor: theme.borderTertiaryColor,
			borderRadius: 5,
			flexDirection: 'column',
			justifyContent: 'space-around',
			minHeight: 60,
		},
		deletedCard: {
			borderStyle: 'solid',
			borderColor: theme.warningColor,
			borderWidth: 1,
		},
		list: {
			margin: 10,
			padding: 10,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
		dashboardAuftragButton: {
			width: '93%',
			alignSelf: 'center',
			marginTop: 0,
		},
		dashList: {
			margin: 0,
			padding: 10,
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5,
			borderWidth: 1,
			width: 280,
			backgroundColor: theme.backgroundSecondaryColor,
			borderColor: theme.borderSecondaryColor,
			borderStyle: 'solid',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
		dashListPadded: {
			margin: 10,
			padding: 10,
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5,
			borderWidth: 1,
			width: 280,
			backgroundColor: theme.backgroundSecondaryColor,
			borderColor: theme.borderSecondaryColor,
			borderStyle: 'solid',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
		dashListCollapse: {
			margin: 0,
			padding: 10,
			borderRadius: 5,
			borderWidth: 1,
			backgroundColor: theme.backgroundSecondaryColor,
			borderColor: theme.borderSecondaryColor,
			borderStyle: 'solid',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
		},
		fullSize: {
			flex: 1,
			zIndex: 2,
		},
		highlight: {
			backgroundColor: theme.accentSecondaryColor, // invoice cards
		},
		loading: {
			flex: 1,
			alignContent: 'center',
			justifyContent: 'center',
		},
		disabled: {
			backgroundColor: theme.backgroundTertiaryColor,
			borderColor: theme.borderTertiaryColor,
		},
		padding: {
			padding: 5,
			margin: 5,
		},
		primaryTextColor: {
			color: theme.textMainColor,
		},
		displayCard: {
			borderRadius: 5,
			backgroundColor: theme.borderSecondaryColor,
			borderColor: theme.textMainColor,
			display: 'flex',
			marginVertical: 20,
			marginRight: 20,
			padding: 10,
			flexDirection: 'column',
		},
		reverseRendered: {
			flexDirection: 'column-reverse',
		},
		elevated: {
			zIndex: 10,
			elevation: 10,
		},
		verticalPadding10: {
			paddingVertical: 10,
			marginVertical: 10,
		},
		verticalPadding20: {
			paddingVertical: 20,
			marginVertical: 10,
		},
		alignCenterHorizontally: {
			alignItems: 'center',
		},
		h1: {
			fontSize: 24,
		},
		fullWidth: {
			width: '100%',
		},
		buttonDefaultTextColor: {
			color: theme.accentTextColor,
		},
		thinSeparator: {
			height: StyleSheet.hairlineWidth,
			backgroundColor: theme.borderTertiaryColor,
		},
		thinSeparatorScrolling: {
			width: '150%',
			height: StyleSheet.hairlineWidth,
			backgroundColor: theme.borderTertiaryColor,
		},
		withMargin: {
			marginVertical: 10,
		},
		warning: {
			color: '#FF3366',
		},
		headerView: {
			paddingVertical: 10,
			paddingLeft: 20,
			paddingRight: 10,
			backgroundColor: theme.boderMainColor,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			shadowOffset: {
				width: 1,
				height: 1,
			},
			shadowOpacity: 0.05421875,
			shadowRadius: 6.384375,
			borderBottomColor: '#F4F4F4',
			borderBottomWidth: 1,
		},
		headerViewNoShadow: {
			paddingVertical: 10,
			paddingLeft: 0,
			paddingRight: 10,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottomColor: '#F4F4F4',
			borderBottomWidth: 1,
		},
		headerTitleContainer: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		headerViewSecondary: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingHorizontal: 20,
			paddingVertical: 10,
			backgroundColor: theme.backgroundSecondaryColor,
		},
		headerText: {
			fontWeight: '500',
			fontSize: 20,
		},
		boldText: {
			fontWeight: 'bold',
		},
		headerSecondaryText: {
			fontSize: 16,
			fontWeight: '500',
		},
		tableRow: {
			flexDirection: 'row',
			width: '100%',
			margin: 10,
		},
		tableRowContainer: {
			borderBottomWidth: 1,
			borderBottomColor: theme.backgroundSecondaryColor,
		},
		idColumn: {
			width: 60,
			alignItems: 'center',
		},
		priceColumn: {
			width: 80,
			alignItems: 'flex-end',
		},
		productNameColumn: {
			maxWidth: '70%',
			minWidth: '70%',
		},
		picker: {
			height: 40,
			marginTop: 5,
			marginBottom: 5,
			marginRight: 5,
			paddingHorizontal: 10,
			borderColor: theme.borderTertiaryColor,
			borderStyle: 'solid',
			backgroundColor: theme.boderMainColor,
			borderRadius: 0,
			borderWidth: 1,
		},
		pickerMaxWidth: {
			width: 300,
		},
		pickerLabelSpacing: {
			paddingTop: 4,
			paddingBottom: 5,
		},
		pickerLabelSpacingReducedHeight: {
			marginTop: -15,
			paddingBottom: 5,
		},
		datePicker: {
			height: 40,
			marginTop: 5,
			marginBottom: 5,
			paddingHorizontal: 5,
			borderRadius: 0,
			borderWidth: 0,
			width: '200px',
			maxWidth: '200px',
		},
		formUnitHeight: {
			height: 80,
		},
		formButtonHeight: {
			height: 40,
			width: 40,
		},
		formUnitHeightVertCenter: {
			height: 80,
			justifyContent: 'center',
		},
		formUnitHeightVertCenterNoWidth: {
			minHeight: 80,
			justifyContent: 'center',
		},
		formUnitHeightVertCenterSpaced: {
			height: 80,
			justifyContent: 'center',
			alignItems: 'flex-start',
		},
		formUnitHeightVertCenterHalfHeight: {
			height: 60,
			justifyContent: 'center',
		},
		formUnitStandardWidth: {
			minWidth: 174,
			marginRight: 26,
			zIndex: 1,
		},
		formUnitHalfWidth: {
			minWidth: 100,
			marginRight: 0,
			zIndex: 1,
		},
		formButtonStandardWidth: {
			minWidth: 174,
			maxWidth: 200,
			zIndex: 1,
			padding: 0,
			margin: 0,
		},
		formContainer: {
			flexDirection: 'row',
		},
		verticalCenterAligned: {
			alignItems: 'center',
		},
		containerHalfWidth: {
			width: '30%',
			flexDirection: 'column',
			padding: 20,
			zIndex: 1,
		},
		containerMidWidth: {
			width: '50%',
			flexDirection: 'column',
			padding: 10,
			zIndex: 1,
		},
		container40Width: {
			width: '40%',
			flexDirection: 'column',
			padding: 10,
			zIndex: 1,
		},
		containerFieldWidth: {
			minWidth: 174,
			maxWidth: '48%',
			marginRight: 26,
			flexDirection: 'column',
			padding: 10,
			zIndex: 1,
		},
		containerHalfWidthNoPadding: {
			width: '30%',
			flexDirection: 'column',
			padding: 0,
			zIndex: 1,
		},
		settingsContainerSmall: {
			width: 300,
			flexDirection: 'column',
			padding: 0,
			zIndex: 1,
		},
		containerTwoThirdWidth: {
			width: '60%',
			flexDirection: 'column',
			padding: 20,
			zIndex: 1,
		},
		containerHalfWidthHorizontal: {
			width: '30%',
			flexDirection: 'row',
			padding: 20,
			zIndex: 1,
		},
		containerFifthWidth: {
			width: '20%',
			padding: 20,
			zIndex: 1,
		},
		containerFullWidth: {
			width: '90%',
			padding: 20,
			zIndex: 1,
		},
		containerPadded: {
			padding: 20,
			zIndex: 1,
		},
		containerFullWidthElevated: {
			width: '90%',
			padding: 20,
			zIndex: 9,
		},
		containerFullWidthElevatedNoPadding: {
			width: '90%',
			padding: 0,
			zIndex: 9,
		},
		containerFullPercentageElevatedNoPadding: {
			width: '100%',
			padding: 0,
			zIndex: 9,
		},
		containerFullWidthElevatedSecondRank: {
			width: '90%',
			padding: 20,
			zIndex: 19,
		},
		containerFullWidthNoPadding: {
			width: '90%',
			padding: 0,
			zIndex: 1,
		},
		containerFullPercentageWidth: {
			width: '100%',
			padding: 0,
			zIndex: 1,
		},
		containerFullPercentageWidthLeftPadding: {
			width: '100%',
			paddingLeft: 20,
			zIndex: 1,
		},
		containerFullWidthNoPaddingButLeft: {
			width: '90%',
			marginTop: 0,
			paddingLeft: 20,
			paddingVertical: 0,
			paddingRight: 0,
			zIndex: 1,
		},
		containerFullWidthHorizontal: {
			width: '100%',
			flexDirection: 'row',
			padding: 20,
			zIndex: 1,
		},
		containerFullWidthHorizontalCentered: {
			width: '100%',
			flexDirection: 'row',
			padding: 20,
			zIndex: 1,
			justifyContent: 'center',
		},
		containerFieldHeight: {
			minHeight: 110,
			alignItems: 'center',
		},
		containerFullWidthHorizontalCenterAligned: {
			width: '90%',
			flexDirection: 'row',
			padding: 0,
			alignItems: 'center',
			zIndex: 1,
		},

		containerFullWidthHorizontalCenterAlignedVertically: {
			width: '90%',
			flexDirection: 'row',
			padding: 0,
			justifyContent: 'center',
			zIndex: 1,
		},

		containerFullWidthHorizontalCenterAlignedWithPadding: {
			width: '90%',
			flexDirection: 'row',
			padding: 10,
			alignItems: 'stretch',
			zIndex: 1,
		},
		itemHorizontalMarginSmall: {
			marginHorizontal: 5,
		},
		containerFullWidthHorizontalNoPadding: {
			width: '100%',
			flexDirection: 'row',
			padding: 0,
			zIndex: 1,
		},
		containerFullWidthHorizontalNoPaddingBetween: {
			width: '100%',
			flexDirection: 'row',
			padding: 0,
			zIndex: 1,
			justifyContent: 'space-between',
		},
		containerFullResolution: {
			flex: 1,
			minWidth: '400px', // minwidth for popup. totally removable if that works
			flexDirection: 'column',
			justifyContent: 'flex-start',
			width: '100hh',
			height: '100vh',
			margin: 0,
			padding: 0,
		},
		container90HeightResolution: {
			flex: 1,
			minWidth: '400px', // minwidth for popup. totally removable if that works
			flexDirection: 'column',
			justifyContent: 'flex-start',
			width: '100hh',
			height: '90vh',
			margin: 0,
			padding: 0,
		},
		containerFullResolutionWithPadding: {
			flex: 1,
			flexDirection: 'column',
			justifyContent: 'flex-start',
			width: '100hh',
			height: '100vh',
			margin: 0,
			padding: 20,
		},
		disclosureIcon: {
			width: 15,
			height: 15,
		},
		spacedContainer: {
			padding: 20,
			shadowOffset: {
				width: 1,
				height: 1,
			},
			shadowOpacity: 0.05421875,
			shadowRadius: 6.384375,
			backgroundColor: theme.boderMainColor,
			borderRadius: 5,
			margin: 20,
			zIndex: 1,
		},
		containerHorizontal: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		spacedContainerHorizontal: {
			padding: 20,
			flexDirection: 'row',
			shadowOffset: {
				width: 1,
				height: 1,
			},
			shadowOpacity: 0.05421875,
			shadowRadius: 6.384375,
			backgroundColor: theme.boderMainColor,
			borderRadius: 5,
			margin: 20,
		},
		paddedView: {
			padding: 5,
		},
		paddedViewMinWidth: {
			padding: 5,
			minWidth: 160,
		},
		paddedViewHorizontal: {
			paddingHorizontal: 5,
		},
		paddedViewVertical: {
			paddingVertical: 5,
		},
		paddedViewVertical10: {
			paddingVertical: 10,
		},
		horizontalLayout: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		verticalLayout: {
			flexDirection: 'column',
		},
		horizontalScrollViewContainer: {
			flexDirection: 'column',
			padding: 0,
			flex: 1,
			flexGrow: 1,
		},
		horizontalScrollViewContainerNoPadding: {
			flexDirection: 'column',
			padding: 0,
			flex: 1,
		},
		horizontalScrollViewContainerFlexed: {
			flexDirection: 'column',
			flex: 1,
			padding: 0,
			flexGrow: 1,
		},
		fullSIzeWideGroup: {
			width: '100%',
			flex: 1,
			display: 'flex',
			flexDirection: 'row',
		},
		transparentButton: {
			marginLeft: 5,
			paddingHorizontal: 3,
			backgroundColor: 'transparent',
			borderColor: 'transparent',
			borderWidth: 1,
			borderRadius: 5,
			minHeight: 16,
		},
		alignLeft: {
			justifyContent: 'flex-start',
		},
		alignLeft50: {
			justifyContent: 'flex-start',
			flexBasis: '50%',
		},
		wrap: {
			flexWrap: 'wrap',
		},
		bottomMargin5: {
			marginBottom: 5,
		},
		bottomMargin6: {
			marginBottom: 6,
		},
		bottomMargin10: {
			marginBottom: 10,
		},
		bottomMargin20: {
			marginBottom: 20,
		},
		topMargin10: {
			marginTop: 10,
		},
		topMargin6: {
			marginTop: 6,
		},
		topMargin20: {
			marginTop: 20,
		},
		topMargin30: {
			marginTop: 30,
		},
		leftMargin5: {
			marginLeft: 5,
		},
		leftMargin10: {
			marginLeft: 10,
		},
		leftMargin20: {
			marginLeft: 20,
		},
		leftMargin26: {
			marginLeft: 11,
		},
		leftMargin30: {
			marginLeft: 30,
		},
		leftMargin40: {
			marginLeft: 40,
		},
		leftMargin50: {
			marginLeft: 50,
		},
		rightMargin10: {
			marginRight: 10,
		},
		rightMargin20: {
			marginRight: 20,
		},
		rightMargin30: {
			marginRight: 30,
		},
		margin5: {
			margin: 5,
		},
		noSpacing: {
			margin: 0,
			padding: 0,
		},
		alignRightInHorizontalFlex: {
			marginLeft: 'auto',
		},
		cancelButton: {
			backgroundColor: theme.warningColor,
			borderColor: theme.warningColor,
		},
		successButton: {
			backgroundColor: theme.successColor,
			borderColor: theme.successColor,
		},
		neutralButton: {
			backgroundColor: theme.warningColor,
			borderColor: theme.warningColor,
		},
		deleteButton: {
			backgroundColor: theme.errorColor,
			borderColor: theme.errorColor,
		},
		primaryButton: {
			backgroundColor: theme.textMainColor,
			borderColor: theme.textMainColor,
		},
		generalFullWidth: {
			width: '100%',
		},
		tabs: {
			// margin: 5,
			backgroundColor: theme.backgroundSecondaryColor,
			paddingHorizontal: 10,
			paddingBottom: 14,
			paddingTop: 16,
			// flex: 1,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignContent: 'flex-start',
			// height: 25,
			borderStyle: 'solid',
			borderColor: theme.backgroundTertiaryColor,
			borderWidth: 0,
			width: 150,
			// borderRadius: 5,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderTopWidth: 1,
			borderBottomWidth: 1,
			borderLeftColor: theme.backgroundTertiaryColor,
			borderRightColor: theme.backgroundTertiaryColor,
		},
		tabLite: {
			backgroundColor: theme.backgroundSecondaryColor,
			paddingHorizontal: 10,
			paddingBottom: 14,
			paddingTop: 16,
			borderStyle: 'solid',
			flex: 1,
			borderColor: theme.backgroundTertiaryColor,
			borderWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderTopWidth: 1,
			borderBottomWidth: 1,
			borderLeftColor: theme.backgroundTertiaryColor,
			borderRightColor: theme.backgroundTertiaryColor,
		},
		selectedtab: {
			borderBottomColor: theme.backgroundMainColor,
			borderBottomWidth: 4,
			borderTopColor: theme.backgroundTertiaryColor,
			borderLeftColor: theme.backgroundTertiaryColor,
			borderRightColor: theme.backgroundTertiaryColor,
			borderTopWidth: 1,
			borderLeftWidth: 1,
			borderRightWidth: 1,
		},
		textSelectedTab: {
			fontSize: 14,
			fontWeight: '600',
		},
		textTab: {
			fontSize: 14,
		},
		smallButtonFontProps: {
			fontSize: 12,
		},
		smallButtonLayoutProps: {
			minWidth: 40,
		},
		buttonBorderProps: {
			borderColor: theme.backgroundSecondaryColor,
		},
		wideSpacedGroup: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		dashboardIcon: {
			tintColor: theme.textMainColor,
			marginRight: 5,
			marginVertical: 2,
		},
		orderCard: {
			flex: 1,
		},
		formLeftHorizontal: {
			width: '100%',
			flexDirection: 'row',
		},
		articleItem: {
			display: 'flex',
			flexDirection: 'column',
			borderColor: theme.borderTertiaryColor,
			borderWidth: 1,
			borderBottomWidth: 5,
			marginVertical: 10,
			paddingHorizontal: 10,
			paddingVertical: 5,
			borderRadius: 10,
			alignItems: 'center',
			justifyContent: 'flex-start',
			minHeight: 80,
			width: '100%',
		},
		articleItemRow: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'flex-start',
			minHeight: 80,
		},
		orderIdHeader: {
			fontSize: 22,
		},
		flexContainerAutoWrap: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'row',
			marginVertical: 10,
		},
		articleFormField: {
			minWidth: 200,
		},
		terminalLanguageButton: {
			width: 300,
			height: 100,
			backgroundColor: theme.accentMainColor,
			borderColor: theme.backgroundTertiaryColor,
			alignItems: 'center',
			justifyContent: 'center',
		},
		fieldHalfContainerWidth: {
			width: '50%',
		},
		fieldOneThirdContainerWidth: {
			width: '33%',
		},
		generalFieldStandardWidth: {
			width: 200,
		},
		sideMenu: {
			backgroundColor: theme.backgroundSecondaryColor, // edit pipelinen second box
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			minWidth: 250,
			padding: 10,
			alignContent: 'center',
			justifyContent: 'flex-start',
		},
		topRightNotification: {
			position: 'absolute',
			top: '0',
			right: '0',
			zIndex: 100,
			backgroundColor: theme.warningColor,
			borderRadius: 12.5,
			width: 25,
			height: 25,
			alignItems: 'center',
			justifyContent: 'center',
		},
		error: {
			color: 'red',
		},
	});

export default styles;
