import { MessageDescriptor } from 'react-intl';
import messages from './messages';

export function convertDateToDay(date: number | Date): MessageDescriptor {
	const parseDate = new Date(date);
	const day = parseDate.getDay();
	const days = [
		messages.sunday,
		messages.monday,
		messages.tuesday,
		messages.wednesday,
		messages.thursday,
		messages.friday,
		messages.saturday,
	];
	const dayname = days[day];

	return dayname;
}
