import { useMutation, useQuery } from '@apollo/client';
import {
	ETOrderListSettings,
	MModuleDescription,
	MModuleInvoiceSettings,
	MPackage,
	MPaymentMethod,
	MPipeline,
} from 'mango-utils-client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { MundoButton, MundoCheckBox } from '../../../components/elements';
import MundoText from '../../../components/elements/MundoText';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import additionalSelectTitle from '../../../Plugins/VehicleCleaning/extensions/TableHeader/functions/selectTitle';
import { useArray } from '../../../utilities/hooks/array';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { openDocs } from '../../../utilities/openDocs';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { useHistory, useParams } from '../../../utilities/routing';
import OrderListSettings from '../../Order/List/Settings';
import PackageSelector from './components/PackageSelector';
import PaymentSelector from './components/PaymentSelector';
import SelectedPackagesEntry from './components/SelectedPackagesEntry';
import SelectedPaymentEntry from './components/SelectedPaymentEntry';
import messages from './messages';
import { GET_INVOICE_SETTINGS, SAVE_INVOICE_SETTINGS } from './queries';

export const InvoiceSettings: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { pipelineId } = useParams<{ pipelineId: string }>();
	const { settings } = useContext(SettingsContext);
	const [pipeline, onChangePipeline] = useState<MPipeline>();

	useEffect(() => {
		onChangePipeline(settings.pipelines.find((p) => p._id === pipelineId));
	}, [settings, pipelineId]);

	const [invoiceSettings, onChangeSettings] = useState(
		new MModuleInvoiceSettings(),
	);

	const [packages, dispatchPackageAction] = useArray<MPackage>();
	const [paymentMethods, dispatchPaymentAction] = useArray<MPaymentMethod>();
	const [useProposal, onChangeUseProposal] = useState<boolean>(false);

	const update = (input: Partial<any>) => {
		const next = Object.assign({}, invoiceSettings, input);
		onChangeSettings(next);
	};

	// get invoiceSettings
	const extractData = (data: any) => data.moduleInvoiceSettings;
	useQuery(GET_INVOICE_SETTINGS, {
		skip: !pipelineId,
		variables: {
			pipelineId,
		},
		onCompleted: (data) => {
			if (data) {
				const next = new MModuleInvoiceSettings(extractData(data));
				onChangeSettings(next);
				dispatchPackageAction({
					type: ETypes.RESET_TO,
					item: next.packages,
				});
				dispatchPaymentAction({
					type: ETypes.RESET_TO,
					item: next.paymentMethods,
				});
				onChangeUseProposal(next.useProposal);
			}
		},
	});

	// save invoiceSettings
	const [saveSettings] = useMutation(SAVE_INVOICE_SETTINGS);
	const saveButtonHandler = async () => {
		// remove empty payment methods and packages
		invoiceSettings.paymentMethods = paymentMethods.filter(
			(method) => method._id,
		);
		invoiceSettings.packages = packages.filter((pack) => pack._id);
		invoiceSettings.pipelineId = pipelineId || '';
		invoiceSettings.useProposal = useProposal;
		await saveSettings({ variables: { invoiceSettings } });
		history.goBack();
	};

	if (!pipeline) {
		return <LoadingIndicator />;
	}

	return (
		<ScrollView>
			<View style={styles.headerTitleContainer}>
				<MundoText
					styles={styles.headerText}
					message={transformModuleTitle(
						pipeline.modules.find(
							(m) => m.identifier === 'invoice',
						) || new MModuleDescription(),
					)}
				/>
				<MundoButton
					icon={'question'}
					subtype="transparent"
					onPress={() =>
						openDocs('#/content/module/invoice?id=invoiceSettings')
					}
				/>
			</View>
			{/* packages selection */}
			<View style={[styles.spacedContainer, { zIndex: 10 }]}>
				<MundoText
					styles={styles.headerSecondaryText}
					message={messages.packages}
				/>
				{packages.map((pack, index) => (
					<SelectedPackagesEntry
						key={index + pack._id}
						pack={pack}
						index={index}
						invoiceSettings={invoiceSettings}
						dispatchPackageAction={dispatchPackageAction}
					/>
				))}
				<PackageSelector
					onNewPack={(newPack) => {
						dispatchPackageAction({
							type: ETypes.ADD,
							item: new MPackage(newPack),
						});
					}}
				/>
			</View>
			{/* payment methods selection */}
			<View style={[styles.spacedContainer, styles.elevated]}>
				<MundoText
					styles={styles.headerSecondaryText}
					message={messages.paymentMethods}
				/>
				{paymentMethods.map((payMeth, index) => (
					<SelectedPaymentEntry
						key={index + payMeth._id}
						index={index}
						payMeth={payMeth}
						invoiceSettings={invoiceSettings}
						dispatchPaymentAction={dispatchPaymentAction}
					/>
				))}
				<PaymentSelector
					onNewPayment={(newPaymentMethod) => {
						dispatchPaymentAction({
							type: ETypes.ADD,
							item: new MPaymentMethod(newPaymentMethod),
						});
					}}
				/>
			</View>
			<View style={styles.spacedContainer}>
				<MundoText
					styles={styles.headerSecondaryText}
					message={messages.useProposal}
				/>
				<MundoCheckBox
					checked={useProposal}
					onCheckedChanged={onChangeUseProposal}
				/>
			</View>
			<View style={styles.spacedContainer}>
				<OrderListSettings
					update={update}
					dashboardFields={invoiceSettings.dashboardFields}
					additionalSelectTitle={additionalSelectTitle}
					additionalTableConfig={Object.keys(ETOrderListSettings)}
					noSave
				/>
			</View>
			<FinishButtonGroup
				cyId={'invoice'}
				saveFunction={saveButtonHandler}
				cancelFunction={history.goBack}
			/>
		</ScrollView>
	);
};
