import React, { FC, memo, useContext, useEffect, useState } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';

import { getTranslation } from 'mango-utils-client';
import icons from '../../../assets';
import { MundoButton } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import MundoText from '../../../components/elements/MundoText';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { usePackages } from '../../../utilities/hooks/package';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory } from '../../../utilities/routing';
import PackageFilter from '../Filter';
import messages from './messages';

const PackageList: FC = memo(() => {
	const styles = useStyle();
	const history = useHistory();
	const { language } = useContext(LanguageContext);

	const [filterPackage, onChangeFilterPackage] = useState();

	const { orderBy, mode, changeSort } = useSort();

	const {
		packages,
		loading,
		loadMore,
		loadMoreActive,
		onChangeFilter,
	} = usePackages({ skip: false, disabled: false, orderBy });

	const createArticleButtonHandler = () => {
		history.push(`/edit/package/new`);
	};

	useEffect(() => {
		onChangeFilter(filterPackage);
	}, [onChangeFilter, filterPackage]);

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.header}
						styles={styles.headerText}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => openDocs('#/content/settings?id=pakete')}
					/>
				</View>
				<MundoButton
					dataSet={{ cy: 'package.create.button' }}
					title={messages.create}
					onPress={createArticleButtonHandler}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoTable
						filter={
							<View
								style={[styles.bottomMargin20, styles.elevated]}
							>
								<PackageFilter
									onChangeFilterPackage={
										onChangeFilterPackage
									}
									filterPackage={filterPackage}
								/>
							</View>
						}
						entrys={[
							{ identifier: 'id', header: messages.id, size: 10 },
							{
								identifier: 'title',
								header: messages.title,
								size: 80,
							},
							{ identifier: 'arrow', size: 4 },
						]}
						rows={packages.map((pack) => {
							return {
								key: pack._id,
								onPress: () =>
									history.push(`/edit/package/` + pack._id),
								values: [
									{
										identifier: 'id',
										content: <Text>{pack.id}</Text>,
									},
									{
										identifier: 'title',
										content: (
											<Text>
												{getTranslation(
													pack.title,
													language,
												)}
											</Text>
										),
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								dataSet: {
									cy: `Package.${getTranslation(
										pack.title,
										language,
									)}`,
								},
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
});

export default PackageList;
