import React, { FC } from 'react';
import { View } from 'react-native';

import { MundoText } from '../../../../../components/elements';
import { numericDateOptions } from '../../../../../utilities/constants';
import { useStyle } from '../../../../../utilities/hooks/styles';
import messages from './messages';
import { IGroupInvoiceProps } from './props';

/**
 * TItems selector based on type.
 * @param props TItems props
 */
const GroupInvoiceView: FC<IGroupInvoiceProps> = ({ groupInvoice }) => {
	const styles = useStyle();

	return (
		<View>
			<MundoText
				message={messages.groupInvoiceId}
				styles={[{ fontWeight: 'bold' }, styles.bottomMargin10]}
			>
				{` ${groupInvoice.id}`}
			</MundoText>
			<MundoText
				message={messages.startDate}
				styles={[styles.bottomMargin10, { fontWeight: 'bold' }]}
			/>
			<MundoText>
				{new Date(groupInvoice.startDate).toLocaleDateString(
					'default',
					numericDateOptions,
				)}
			</MundoText>
			<MundoText
				message={messages.endDate}
				styles={[
					styles.bottomMargin10,
					styles.topMargin10,
					{ fontWeight: 'bold' },
				]}
			/>
			<MundoText>
				{new Date(groupInvoice.endDate).toLocaleDateString(
					'default',
					numericDateOptions,
				)}
			</MundoText>
			<MundoText
				message={messages.dueDate}
				styles={[
					styles.bottomMargin10,
					styles.topMargin10,
					{ fontWeight: 'bold' },
				]}
			/>
			<MundoText>
				{new Date(groupInvoice.dueDate).toLocaleDateString(
					'default',
					numericDateOptions,
				)}
			</MundoText>
		</View>
	);
};

export default GroupInvoiceView;
