import React, { FC, useContext, useEffect } from 'react';
import { ScrollView, Text, View } from 'react-native';

import { MDashboardLaneConfig } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../components/elements';
import MundoIcon from '../../components/elements/MundoIcon';
import OrderCardList from '../../components/OrderCardList';
import { alert } from '../../utilities/alert/index';
import { useArray } from '../../utilities/hooks/array';
import { AuthContext } from '../../utilities/hooks/auth';
import { useStyle, useTheme } from '../../utilities/hooks/styles';
import {
	getLocalSettings,
	saveLocalSettings,
} from '../../utilities/localSettings';
import { openDocs } from '../../utilities/openDocs';
import { ETypes } from '../../utilities/reducer/array.reducer';
import messages from './messages';
import { IDashboardProps } from './props';
import LoadingIndicator from '../../components/LoadingIndicator';

export const DefaultDashboard: FC<IDashboardProps> = ({ pipeline }) => {
	const styles = useStyle();
	const { theme } = useTheme();

	const { user, isAdmin, level } = useContext(AuthContext);
	const [
		collapsedModules,
		dispatchCollapsedAction,
	] = useArray<MDashboardLaneConfig>();

	useEffect(() => {
		getLocalSettings()
			.then((data) => {
				dispatchCollapsedAction({
					type: ETypes.RESET_TO,
					item: data.dashboardLaneConfig,
				});
			})
			.catch((e) => alert('error', e, 'error'));
	}, [dispatchCollapsedAction]);

	const handleCollapseAction = (input: MDashboardLaneConfig) => {
		getLocalSettings()
			.then((data) => {
				const prevIndex = data.dashboardLaneConfig.findIndex(
					(entry) =>
						input.moduleIdentifier === entry.moduleIdentifier &&
						input.pipelineId === entry.pipelineId &&
						input.index === entry.index,
				);
				if (prevIndex === -1) {
					const next = [...data.dashboardLaneConfig, input];
					dispatchCollapsedAction({
						type: ETypes.RESET_TO,
						item: next,
					});
					saveLocalSettings({ dashboardLaneConfig: next });
				} else {
					const next = [
						...data.dashboardLaneConfig.filter(
							(dlc) =>
								JSON.stringify(dlc) !== JSON.stringify(input),
						),
					];
					dispatchCollapsedAction({
						type: ETypes.RESET_TO,
						item: next,
					});
					saveLocalSettings({ dashboardLaneConfig: next });
				}
			})
			.catch((e) => alert('error', e, 'error'));
	};
	if (!pipeline) {
		return <LoadingIndicator />;
	}
	return (
		<>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoText
						styles={styles.headerText}
						message={' ' + pipeline.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => openDocs('#/content/dashboard')}
					/>
				</View>
			</View>
			<ScrollView horizontal>
				<View style={styles.spacedContainerHorizontal}>
					{(pipeline ? pipeline.modules : []).map((module, index) => {
						let creator = false;
						if (module.tags.includes('creator')) {
							creator = true;
						}
						return (
							<OrderCardList
								pipeline={pipeline}
								module={module}
								permission={
									isAdmin ||
									!!module.groups.find(
										(onmodG) =>
											level > onmodG.inheritance ||
											user.groups.find(
												(g) => g.title === onmodG.title,
											),
									)
								}
								cardExtension={(props) => {
									// TODO: outsource me to plugins/vehicleCleaning
									const trailertag = props.order.tags.find(
										(tag) => tag.includes('trailer::'),
									);
									const trucktag = props.order.tags.find(
										(tag) => tag.includes('truck::'),
									);
									const drivertag = props.order.tags.find(
										(tag) => tag.includes('driver::'),
									);
									const driverPointstag = props.order.tags.find(
										(tag) => tag.includes('driverPoints::'),
									);
									return (
										<View>
											{trailertag && (
												<View
													style={
														styles.containerHorizontal
													}
												>
													<MundoIcon
														fontSize={14}
														styles={
															styles.dashboardIcon
														}
														icon="cardTrailer"
													/>
													<MundoText>
														{
															trailertag.split(
																'::',
															)[1]
														}
													</MundoText>
												</View>
											)}
											{trucktag && (
												<View
													style={
														styles.containerHorizontal
													}
												>
													<MundoIcon
														fontSize={14}
														styles={
															styles.dashboardIcon
														}
														icon="cardTruck"
													/>
													<MundoText>
														{
															trucktag.split(
																'::',
															)[1]
														}
													</MundoText>
												</View>
											)}
											{drivertag && (
												<View
													style={
														styles.containerHorizontal
													}
												>
													<MundoIcon
														fontSize={14}
														styles={
															styles.dashboardIcon
														}
														icon="cardDriver"
													/>
													<View
														style={{
															maxWidth: 170,
														}}
													>
														<Text
															numberOfLines={3}
															style={{
																fontFamily:
																	'Roboto',
																fontWeight:
																	'normal',
																color:
																	theme.textMainColor,
															}}
															ellipsizeMode="tail"
														>
															{drivertag.split(
																'::',
															)[1] +
																(driverPointstag
																	? ' ' +
																	  driverPointstag.split(
																			'::',
																	  )[1]
																	: '')}
														</Text>
													</View>
												</View>
											)}
										</View>
									);
								}}
								expanded={
									collapsedModules.find(
										(t: MDashboardLaneConfig) =>
											t.index === index &&
											t.moduleIdentifier ===
												module.identifier &&
											t.pipelineId === pipeline._id,
									) === undefined
								}
								onChangeExpanded={() => {
									handleCollapseAction(
										new MDashboardLaneConfig({
											index,
											moduleIdentifier: module.identifier,
											pipelineId: pipeline._id,
										}),
									);
								}}
								key={`${
									module.identifier
								}${module.tags.join()}${index}`}
								creator={creator}
							/>
						);
					})}
				</View>
			</ScrollView>
		</>
	);
};
