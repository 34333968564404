import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.dashboard';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'statistics',
	},
	profit: {
		id: `${scope}.profit`,
		defaultMessage: 'profit',
	},
	export: {
		id: `${scope}.export`,
		defaultMessage: 'export',
	},
	articles: {
		id: `${scope}.articles`,
		defaultMessage: 'articles',
	},
	csv: {
		id: `${scope}.csv`,
		defaultMessage: 'CSV',
	},
	pdf: {
		id: `${scope}.pdf`,
		defaultMessage: 'PDF',
	},
});
