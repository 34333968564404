import { defineMessages } from 'react-intl';

export const errorScope = 'error.';
export const moduleScope = 'module.';
export const unitScope = 'unit.';
export const languageScope = 'language.';
export const pdfScope = 'pdf.';

export default defineMessages({
	receipt: {
		id: `${moduleScope}receipt`,
		defaultMessage: 'receipt',
	},
	record: {
		id: `${moduleScope}record`,
		defaultMessage: 'record',
	},
	invoice: {
		id: `${moduleScope}invoice`,
		defaultMessage: 'invoice',
	},
	receiptDashboard: {
		id: `${moduleScope}receiptDashboard`,
		defaultMessage: 'Receipt Dashboard',
	},
	recordDashboard: {
		id: `${moduleScope}recordDashboard`,
		defaultMessage: 'Record Dashboard',
	},
	invoiceDashboard: {
		id: `${moduleScope}invoiceDashboard`,
		defaultMessage: 'Invoice Dashboard',
	},
	sign: {
		id: `${moduleScope}sign`,
		defaultMessage: 'sign',
	},
	// UNITS
	PIECE: {
		id: `${unitScope}PIECE`,
		defaultMessage: 'pcs',
	},
	LITER: {
		id: `${unitScope}LITER`,
		defaultMessage: 'L',
	},
	MILILITER: {
		id: `${unitScope}MILILITER`,
		defaultMessage: 'ml',
	},
	METER: {
		id: `${unitScope}METER`,
		defaultMessage: 'm',
	},
	INCH: {
		id: `${unitScope}INCH`,
		defaultMessage: 'in',
	},
	CENTIMETER: {
		id: `${unitScope}CENTIMETER`,
		defaultMessage: 'cm',
	},
	FEET: {
		id: `${unitScope}FEET`,
		defaultMessage: 'ft',
	},
	YEAR: {
		id: `${unitScope}YEAR`,
		defaultMessage: 'yr',
	},
	MONTH: {
		id: `${unitScope}MONTH`,
		defaultMessage: 'm’th',
	},
	WEEK: {
		id: `${unitScope}WEEK`,
		defaultMessage: 'w',
	},
	DAY: {
		id: `${unitScope}DAY`,
		defaultMessage: 'd',
	},
	HOUR: {
		id: `${unitScope}HOUR`,
		defaultMessage: 'h',
	},
	MINUTE: {
		id: `${unitScope}MINUTE`,
		defaultMessage: 'min',
	},
	SECOND: {
		id: `${unitScope}SECOND`,
		defaultMessage: 's',
	},
	TON: {
		id: `${unitScope}TON`,
		defaultMessage: 't',
	},
	GRAM: {
		id: `${unitScope}GRAM`,
		defaultMessage: 'g',
	},
	KILOGRAM: {
		id: `${unitScope}KILOGRAM`,
		defaultMessage: 'kg',
	},
	EURO: {
		id: `${unitScope}EURO`,
		defaultMessage: '€',
	},
	DOLLAR: {
		id: `${unitScope}DOLLAR`,
		defaultMessage: '$',
	},
	AW: {
		id: `${unitScope}AW`,
		defaultMessage: 'AW',
	},
	FLASK: {
		id: `${unitScope}FLASK`,
		defaultMessage: 'flask',
	},
	VEHICLE: {
		id: `${unitScope}VEHICLE`,
		defaultMessage: 'vehicle',
	},
	NONE: {
		id: `${unitScope}NONE`,
		defaultMessage: 'none',
	},
	PIECE_FULL: {
		id: `${unitScope}PIECE_FULL`,
		defaultMessage: 'piece(pcs)',
	},
	LITER_FULL: {
		id: `${unitScope}LITER_FULL`,
		defaultMessage: 'liter(L)',
	},
	MILILITER_FULL: {
		id: `${unitScope}MILILITER_FULL`,
		defaultMessage: 'milliliter(ml)',
	},
	METER_FULL: {
		id: `${unitScope}METER_FULL`,
		defaultMessage: 'meter(m)',
	},
	CENTIMETER_FULL: {
		id: `${unitScope}CENTIMETER_FULL`,
		defaultMessage: 'centimeter(cm)',
	},
	FEET_FULL: {
		id: `${unitScope}FEET_FULL`,
		defaultMessage: 'feet(ft)',
	},
	INCH_FULL: {
		id: `${unitScope}INCH_FULL`,
		defaultMessage: 'inch(in)',
	},
	YEAR_FULL: {
		id: `${unitScope}YEAR_FULL`,
		defaultMessage: 'year(yr)',
	},
	MONTH_FULL: {
		id: `${unitScope}MONTH_FULL`,
		defaultMessage: 'month(m’th)',
	},
	WEEK_FULL: {
		id: `${unitScope}WEEK_FULL`,
		defaultMessage: 'week(w)',
	},
	DAY_FULL: {
		id: `${unitScope}DAY_FULL`,
		defaultMessage: 'day(d)',
	},
	HOUR_FULL: {
		id: `${unitScope}HOUR_FULL`,
		defaultMessage: 'hour(h)',
	},
	MINUTE_FULL: {
		id: `${unitScope}MINUTE_FULL`,
		defaultMessage: 'minute(min)',
	},
	SECOND_FULL: {
		id: `${unitScope}SECOND_FULL`,
		defaultMessage: 'second(s)',
	},
	TON_FULL: {
		id: `${unitScope}TON_FULL`,
		defaultMessage: 'ton(t)',
	},
	GRAM_FULL: {
		id: `${unitScope}GRAM_FULL`,
		defaultMessage: 'gram(g)',
	},
	KILOGRAM_FULL: {
		id: `${unitScope}KILOGRAM_FULL`,
		defaultMessage: 'kilogram(kg)',
	},
	EURO_FULL: {
		id: `${unitScope}EURO_FULL`,
		defaultMessage: 'euro(€)',
	},
	DOLLAR_FULL: {
		id: `${unitScope}DOLLAR_FULL`,
		defaultMessage: 'dollar($)',
	},
	AW_FULL: {
		id: `${unitScope}AW_FULL`,
		defaultMessage: 'AW',
	},
	FLASK_FULL: {
		id: `${unitScope}FLASK_FULL`,
		defaultMessage: 'flask(flask)',
	},
	VEHICLE_FULL: {
		id: `${unitScope}VEHICLE_FULL`,
		defaultMessage: 'vehicle(vehicle)',
	},
	NONE_FULL: {
		id: `${unitScope}NONE_FULL`,
		defaultMessage: 'none(none)',
	},
	invoicePDF: {
		id: `${pdfScope}invoice`,
		defaultMessage: 'invoice',
	},
	failedSaveLanguage: {
		id: `${errorScope}failedSaveLanguage`,
		defaultMessage: 'failed to save language',
	},
	error: {
		id: `${errorScope}error`,
		defaultMessage: 'error',
	},
	en: {
		id: `${languageScope}en`,
		defaultMessage: 'English',
	},
	de: {
		id: `${languageScope}de`,
		defaultMessage: 'German',
	},
	fr: {
		id: `${languageScope}fr`,
		defaultMessage: 'French',
	},
	it: {
		id: `${languageScope}it`,
		defaultMessage: 'Italian',
	},
	lv: {
		id: `${languageScope}lv`,
		defaultMessage: 'Latvian',
	},
	pl: {
		id: `${languageScope}pl`,
		defaultMessage: 'Polish',
	},
	ro: {
		id: `${languageScope}ro`,
		defaultMessage: 'Romania',
	},
	ru: {
		id: `${languageScope}ru`,
		defaultMessage: 'Russian',
	},
	sl: {
		id: `${languageScope}sl`,
		defaultMessage: 'Slovenian',
	},
	es: {
		id: `${languageScope}es`,
		defaultMessage: 'Spanish',
	},
	cs: {
		id: `${languageScope}cs`,
		defaultMessage: 'Czech',
	},
	hu: {
		id: `${languageScope}hu`,
		defaultMessage: 'Hungarian',
	},
});
