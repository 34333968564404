import { defineMessages } from 'react-intl';

export const scope = 'mango.container.invoice.proposals';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'invoice proposals',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	order: {
		id: `${scope}.order`,
		defaultMessage: 'order',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	netSum: {
		id: `${scope}.netSum`,
		defaultMessage: 'net sum',
	},
	taxSum: {
		id: `${scope}.taxSum`,
		defaultMessage: 'tax sum',
	},
	grossSum: {
		id: `${scope}.grossSum`,
		defaultMessage: 'gross sum',
	},
});
