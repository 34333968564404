import gql from 'graphql-tag';

export const GET_USERS = gql`
	query getUsers(
		$user: UserInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		users(user: $user, start: $start, count: $count, orderBy: $orderBy) {
			_id
			id
			title
		}
	}
`;
