import { gql } from '@apollo/client';

export const GET_INVOICES = gql`
	query invoices(
		$invoice: InvoiceInput!
		$start: Int!
		$count: Int!
		$from: Float
		$to: Float
	) {
		invoices(
			invoice: $invoice
			start: $start
			count: $count
			from: $from
			to: $to
		) {
			_id
			id
			grossSum
			netSum
			taxSum
			customer {
				_id
				title
			}
			issueDate
			canceled
			type
			orderId
			tags
		}
	}
`;
