import { MGroupInvoice, MPaymentMethod, MState } from 'mango-utils-client';

export function handlePaymentMethodChange(
	pm: MPaymentMethod,
	issueDate: number,
	groupInvoice: MGroupInvoice | undefined,
	recordState: MState | undefined,
	onDueDateChange: React.Dispatch<React.SetStateAction<number>>,
	onChangeGroupInvoice: React.Dispatch<
		React.SetStateAction<MGroupInvoice | undefined>
	>,
) {
	let pmOverride = recordState ? recordState.company.defaultPaymentTime : -1;

	if (recordState?.company.defaultPaymentTime) {
		if (pmOverride === -1) {
			pmOverride = 0;
		}
	}
	const paymentTime = pm
		? (pmOverride >= 0 ? pmOverride : pm.paymentTime) * 24 * 60 * 60 * 1000
		: 0;
	onDueDateChange(issueDate + paymentTime);
	if (
		groupInvoice &&
		groupInvoice.dueDate !== groupInvoice.endDate + paymentTime
	) {
		groupInvoice.dueDate = groupInvoice.endDate + paymentTime;
		onChangeGroupInvoice(new MGroupInvoice(groupInvoice));
	}
}
