import React, { FC, useCallback } from 'react';
import { View } from 'react-native';

import { useIntl } from 'react-intl';
import { confirm } from '../../utilities/alert';
import { useStyle } from '../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../utilities/stringFunctions';
import { MundoButton } from '../elements';
import messages from './messages';
import { IFinishButtonGroupProps } from './props';

const FinishButtonGroup: FC<IFinishButtonGroupProps> = ({
	cyId,
	saveFunction,
	saveDisabled,
	saveTitle,
	saveDisabledReason,
	onPressDisabled,
	deleteFunction,
	deleteDisabled,
	children,
	cancelFunction,
	cancelDisabled,
	saveAndContinueFunction,
	saveAndContinueDisabled,
}) => {
	const styles = useStyle();
	const intl = useIntl();

	const deleteCB = useCallback(
		(cb: () => void) => {
			confirm(
				capitalizeFirstWord(intl.formatMessage(messages.confirmDelete)),
				'',
				() => {
					cb();
				},
				'info',
			);
		},
		[intl],
	);

	return (
		<View style={styles.buttonGroup}>
			<View style={styles.buttonGroupLeft}>
				{children}
				{cancelFunction && ( // CANCEL Button
					<MundoButton
						dataSet={{ cy: `${cyId}.cancel` }}
						title={messages.cancel}
						styles={styles.cancelButton}
						onPress={cancelFunction}
						disableOnClick
						disabled={cancelDisabled}
					/>
				)}
				{deleteFunction && ( // DELTE Button
					<MundoButton
						dataSet={{ cy: `${cyId}.delete` }}
						title={messages.delete}
						styles={styles.deleteButton}
						onPress={() => deleteCB(deleteFunction)}
						disableOnClick
						disabled={deleteDisabled}
					/>
				)}
			</View>
			<View style={styles.buttonGroupRight}>
				{saveFunction && ( // Save Button
					<MundoButton
						dataSet={{ cy: `${cyId}.save` }}
						title={saveTitle || messages.save}
						styles={styles.successButton}
						onPress={saveFunction}
						onPressDisabled={onPressDisabled}
						disabled={saveDisabled}
						disableOnClick
						reason={saveDisabledReason}
					/>
				)}
				{saveAndContinueFunction && ( // Save Button
					<MundoButton
						dataSet={{ cy: `${cyId}.saveandcontinue` }}
						title={messages.saveAndContinue}
						styles={styles.successButton}
						onPress={saveAndContinueFunction}
						onPressDisabled={onPressDisabled}
						disableOnClick
						disabled={saveAndContinueDisabled}
					/>
				)}
			</View>
		</View>
	);
};

export default FinishButtonGroup;
