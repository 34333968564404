import React, { FC, useCallback, useContext, useMemo } from 'react';
import { View } from 'react-native';

import { MModuleDescription } from 'mango-utils-client';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useTheme } from '../../../utilities/hooks/styles';
import { MundoButton } from '../../elements';
import { IPipelineProps } from '../props';
import sidebarStyles from '../styles';
import { Module } from './Module';
import { useHistory } from '../../../utilities/routing';
import { SidebarContext } from '../../../utilities/contexts/Sidebar';

export const Pipeline: FC<IPipelineProps> = ({
	closeSidebar,
	detail,
	pipeline,
}) => {
	const { theme } = useTheme();
	const { currentPipeline, onChangeCurrentPipeline } = useContext(
		SidebarContext,
	);
	const history = useHistory();
	const { user, isAdmin } = useContext(AuthContext);
	const expanded = useMemo(() => {
		return pipeline._id === currentPipeline;
	}, [pipeline, currentPipeline]);
	const groups = user.groups;

	// save selection to localSettings
	const handleChange = useCallback(() => {
		onChangeCurrentPipeline(pipeline._id);
		history.push('/dash/' + pipeline._id + '/default');
	}, [pipeline]);
	// determin level of current user
	const level = useMemo(() => {
		let temp = 0;
		user.groups.forEach((g) => {
			if (g.inheritance > temp) {
				temp = g.inheritance;
			}
		});
		return temp;
	}, [user]);

	return (
		<View style={sidebarStyles(theme).pipelineContainer}>
			{/* <Text>
				{detail ? pipeline.title : pipeline.short}
			</Text> */}
			<MundoButton
				title={detail ? pipeline.title : pipeline.short}
				// icon={expanded ? 'up' : 'down'}
				dataSet={{ cy: `sidebar.pipeline.${pipeline.short}` }}
				onPress={() => handleChange()}
				styles={
					expanded
						? sidebarStyles(theme).pipelineNameBackground
						: sidebarStyles(theme).pipelineName
				}
			/>
			{expanded &&
				pipeline.modules.map((module: MModuleDescription, index) => {
					if (
						isAdmin ||
						!!module.groups.find(
							(onmodG) =>
								level > onmodG.inheritance ||
								groups.find((g) => g.title === onmodG.title),
						)
					) {
						return (
							<Module
								key={`${index}`}
								detail={detail}
								closeSidebar={closeSidebar}
								module={module}
								pipeline={pipeline}
							/>
						);
					} else {
						return null;
					}
				})}
		</View>
	);
};
