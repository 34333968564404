import gql from 'graphql-tag';

export const GET_GROUP = gql`
	query getGroup($id: String!) {
		group(id: $id) {
			_id
			id
			title
			inheritance
		}
	}
`;

export const SAVE_GROUP = gql`
	mutation saveGroup($group: GroupInput!) {
		saveGroup(group: $group) {
			id
		}
	}
`;

export const DELETE_GROUP = gql`
	mutation deleteGroup($id: String!) {
		deleteGroup(id: $id) {
			id
		}
	}
`;
