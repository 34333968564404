import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const mundoButtonStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		button: {
			borderStyle: 'solid',
			borderWidth: 1,
			borderColor: theme.accentSecondaryColor,
			borderRadius: 4,
			padding: 10,
			margin: 5,
			alignItems: 'center',
			minWidth: 150,
			minHeight: 50,
			justifyContent: 'center',
			zIndex: 2,
		},
		buttonContainer: {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		buttonIconContainer: {
			marginBottom: 10,
			width: 40,
			height: 30,
			alignItems: 'center',
			justifyContent: 'center',
		},
	});

export default mundoButtonStyles;
