import gql from 'graphql-tag';

export const GET_ARTICLES = gql`
	query getArticles(
		$article: ArticleInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		articles(
			article: $article
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			_id
			id
			title {
				lang
				message
			}
			description {
				lang
				message
			}
			price
			tax
			unit
			mandatory
			articleType
			short
		}
	}
`;
