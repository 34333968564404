import gql from 'graphql-tag';

export const GET_ARCHIV = gql`
	query archiv(
		$orderBy: String!
		$sortMode: String!
		$from: Float
		$to: Float
		$start: Int!
		$count: Int!
	) {
		getArchiv(
			orderBy: $orderBy
			sortMode: $sortMode
			from: $from
			to: $to
			start: $start
			count: $count
		) {
			_id
			date
			invoiceId
			orderId
			PDFexists
			isGroupInvoice
		}
	}
`;
