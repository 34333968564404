import React, { FC, useContext } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';

import { EUserClassification, MTDriver } from 'mango-utils-client';
import icons from '../../../../../assets';
import { MundoButton } from '../../../../../components/elements';
import MundoTable from '../../../../../components/elements/MundoTable';
import MundoText from '../../../../../components/elements/MundoText';
import { useSort } from '../../../../../utilities/hooks/sort';
import { useStyle } from '../../../../../utilities/hooks/styles';
import { openDocs } from '../../../../../utilities/openDocs';
import { useHistory } from '../../../../../utilities/routing';
import { useDrivers } from '../../../hooks/driver';
import DriverFilter from '../filter';
import messages from './messages';
import { IDriverList } from './props';
import { AuthContext } from '../../../../../utilities/hooks/auth';

const DriverList: FC<IDriverList> = (props) => {
	const styles = useStyle();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { orderBy, mode, changeSort } = useSort();

	const {
		drivers: serverDrivers,
		loading,
		loadMore,
		loadMoreActive,
		onChangeFilter,
	} = useDrivers({ skip: !!props.data, orderBy });
	const drivers: MTDriver[] = props.data || serverDrivers;

	return (
		<View style={!props.sub && styles.containerFullResolution}>
			<View
				style={
					props.sub ? styles.headerViewSecondary : styles.headerView
				}
			>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.drivers}
						styles={
							props.sub
								? styles.headerSecondaryText
								: styles.headerText
						}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/equipmentmanager?id=fahrer',
							)
						}
					/>
				</View>
				{!props.cantCreate && (
					<MundoButton
						dataSet={{ cy: 'driver.create' }}
						icon={'plus'}
						onPress={() =>
							history.push(`/edit/driver.vehicleCleaning/new`)
						}
						disabled={
							user?.classification !==
								EUserClassification.ADMIN &&
							user?.classification !== EUserClassification.MEMBER
						}
					/>
				)}
			</View>
			<ScrollView>
				<View style={!props.sub && styles.spacedContainer}>
					<MundoTable
						filter={
							!props.noFilter && (
								<DriverFilter onChange={onChangeFilter} />
							)
						}
						entrys={[
							{ identifier: 'id', header: messages.id, size: 10 },
							{
								identifier: 'title',
								header: messages.title,
								size: 15,
							},
							{
								identifier: 'email',
								header: messages.email,
								size: 15,
							},
							{
								identifier: 'phone',
								header: messages.phone,
								size: 15,
							},
							{ identifier: 'warn', size: 6 },
							{ identifier: 'arrow', size: 4 },
						]}
						rows={drivers.map((driver) => {
							return {
								key: driver._id,
								onPress: () =>
									history.push(
										`/edit/driver.vehicleCleaning/` +
											driver._id,
									),
								values: [
									{
										identifier: 'id',
										content: <Text>{driver.id}</Text>,
									},
									{
										identifier: 'title',
										content: <Text>{driver.title}</Text>,
									},
									{
										identifier: 'email',
										content: <Text>{driver.mail}</Text>,
									},
									{
										identifier: 'phone',
										content: <Text>{driver.phone}</Text>,
									},
									{
										identifier: 'warn',
										content:
											!driver.companyID ||
											!driver.firstName ||
											!driver.lastName ? (
												<View
													style={
														styles.wideGroupTableColumn6
													}
												>
													<MundoText
														styles={[
															{
																color:
																	'#ff8000',
															},
														]}
														message={
															messages.missingValue
														}
													/>
												</View>
											) : (
												<View
													style={
														styles.wideGroupTableColumn6
													}
												/>
											),
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								dataSet: { cy: `driver.${driver.title}.row` },
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default DriverList;
