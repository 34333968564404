import React, { FC, memo, useContext } from 'react';
import { View } from 'react-native';

import { MundoButton, MundoText } from '../../../../components/elements';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { getTranslation } from '../../../../utilities/translations';
import { ISelectedPackageEntry } from '../props';

const SelectedPackagesEntry: FC<ISelectedPackageEntry> = memo((props) => {
	const { index, pack, dispatchPackageAction } = props;
	const { language } = useContext(LanguageContext);
	const styles = useStyle();

	return (
		<View style={styles.horizontalLayout}>
			<MundoText message={getTranslation(pack.title, language)} />
			<MundoButton
				icon={'minus'}
				onPress={() =>
					dispatchPackageAction({
						type: ETypes.REMOVE,
						location: index,
					})
				}
			/>
		</View>
	);
});

export default SelectedPackagesEntry;
