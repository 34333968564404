import gql from 'graphql-tag';

export const EXPORT_PREVIEW = gql`
	query exportPreview($from: Float, $to: Float) {
		navExportPreview(from: $from, to: $to) {
			title
			net
			gross
			tax
			count
			date
		}
	}
`;

export const GET_EXPORTS = gql`
	query getExport($start: Int!, $count: Int!) {
		nExports(start: $start, count: $count) {
			title
			net
			gross
			tax
			count
			date
			subExport {
				date
				title
				net
				gross
				tax
			}
		}
	}
`;
