import { defineMessages } from 'react-intl';

export const scope = 'mango.module.invoiceSettings';

export default defineMessages({
	companyName: {
		id: `${scope}.companyName`,
		defaultMessage: 'company name',
	},
	equipmentType: {
		id: `${scope}.equipmentType`,
		defaultMessage: 'equipment type',
	},
	containerNr: {
		id: `${scope}.containerNr`,
		defaultMessage: 'container nr.',
	},
	chambers: {
		id: `${scope}.chamber`,
		defaultMessage: 'chamber',
	},
	lidsForChamber: {
		id: `${scope}.lidsForChamber`,
		defaultMessage: 'lids for chamber',
	},
	classifications: {
		id: `${scope}.classifications`,
		defaultMessage: 'classifications',
	},
	freeFormFields: {
		id: `${scope}.freeFormFields`,
		defaultMessage: 'free form fields',
	},
	packages: {
		id: `${scope}.packages`,
		defaultMessage: 'packages',
	},
	addPackage: {
		id: `${scope}.addPackage`,
		defaultMessage: 'add package',
	},
	paymentMethods: {
		id: `${scope}.paymentmethods`,
		defaultMessage: 'payment methods',
	},
	addPaymentMethod: {
		id: `${scope}.addPaymentMethod`,
		defaultMessage: 'add payment method',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	header: {
		id: `${scope}.header`,
		defaultMessage: 'invoice settings',
	},
	useProposal: {
		id: `${scope}.useProposal`,
		defaultMessage: 'use proposal',
	},
});
