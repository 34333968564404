import { IThemeConstants } from '../utilities/constants';

const theme: IThemeConstants = {
	backgroundMainColor: '#28a745',
	backgroundSecondaryColor: '#F6F6F6',
	backgroundTertiaryColor: '#E5E5E5',
	boderMainColor: '#FFF',
	borderSecondaryColor: '#e6e6e6',
	borderTertiaryColor: '#DDD',
	textMainColor: '#3e3c4f',
	textSecondaryColor: '#878787',
	accentMainColor: '#28a745',
	accentSecondaryColor: '#7BD683',
	accentTertiaryColor: '#F5FCFF',
	accentQuaternaryColor: '#8096D6',
	accentTextColor: '#FFF',
	fontMain: 'Roboto',
	fontThin: 'Roboto',
	errorColor: '#ff0d49',
	warningColor: '#fbbc06',
	successColor: '#28a745',
	// accentQuaternaryLightColor: '#F0F8FF',
	// sidebarColor: '#eeeeee',
};

export default theme;
