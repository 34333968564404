import { defineMessages } from 'react-intl';

export const scope = 'mango.module.state';

export default defineMessages({
	tags: {
		id: `${scope}.tags`,
		defaultMessage: 'tags',
	},
	bookedPackages: {
		id: `${scope}.bookedPackages`,
		defaultMessage: 'booked packages',
	},
	user: {
		id: `${scope}.user`,
		defaultMessage: 'user',
	},
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	state: {
		id: `${scope}.state`,
		defaultMessage: 'state',
	},
	module: {
		id: `${scope}.module`,
		defaultMessage: 'module',
	},
});
