import { gql } from '@apollo/client';

export const GET_COMPANIES = gql`
	query getCustomers(
		$customer: CustomerInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		customers(
			customer: $customer
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			_id
			id
			title
			tags
			address {
				street
				city
				country
				zipCode
				tags
			}
			billingAddress {
				street
				city
				country
				zipCode
				tags
			}
			ustID
			phone
			mail
			allegiance
		}
	}
`;
