import { defineMessages } from 'react-intl';

export const scope = 'mango.container.PDFSender';
export const errorScope = 'error';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'send PDF to customer',
	},
	email: {
		id: `${scope}.email`,
		defaultMessage: 'email',
	},
	subject: {
		id: `${scope}.subject`,
		defaultMessage: 'subject',
	},
	text: {
		id: `${scope}.text`,
		defaultMessage: 'text',
	},
	deliveryNote: {
		id: `${scope}.deliveryNote`,
		defaultMessage: 'delivery note',
	},
	ecd: {
		id: `${scope}.ecd`,
		defaultMessage: 'ecd',
	},
	order: {
		id: `${scope}.order`,
		defaultMessage: 'order',
	},
	qcd: {
		id: `${scope}.qcd`,
		defaultMessage: 'qcd',
	},
	tankEntrance: {
		id: `${scope}.tankEntrance`,
		defaultMessage: 'tank entrance',
	},
	noSelPdfError: {
		id: `${errorScope}.noSelPdfError`,
		defaultMessage: 'no PDF selected',
	},
	noSelPdfErrorDescr: {
		id: `${errorScope}.noSelPdfErrorDescr`,
		defaultMessage: 'please select at least one PDF before sending.',
	},
	noEmailEnteredError: {
		id: `${errorScope}.noEmailEnteredError`,
		defaultMessage: 'please enter an e-mail',
	},
	noEmailEnteredErrorDescr: {
		id: `${errorScope}.noEmailEnteredErrorDescr`,
		defaultMessage:
			'please enter at least one e-mail address before sending.',
	},
});
