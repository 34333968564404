import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import MediaQuery from 'react-responsive';

import { MTDriver } from 'mango-utils-client';
import useDebounce from '../../../../../utilities/hooks/debounce';
import { useStyle } from '../../../../../utilities/hooks/styles';
import {
	CompanyFilter,
	DriverFirstNameFilter,
	DriverIdFilter,
	DriverLastNameFilter,
} from './components';
import { IDriverFilterProps } from './props';

const DriverFilter: FC<IDriverFilterProps> = ({ onChange }) => {
	const styles = useStyle();

	const [driver, onChangeDriver] = useState<MTDriver>(new MTDriver());
	const [last, onChangeLast] = useState<MTDriver>();

	const update = (next: Partial<MTDriver>) => {
		return onChangeDriver(new MTDriver({ ...driver, ...next }));
	};

	const loadCB = useCallback((next) => onChange(next), [onChange]);

	const debouncedSearchTerm = useDebounce(driver);

	useEffect(() => {
		if (debouncedSearchTerm && debouncedSearchTerm !== last) {
			onChangeLast(debouncedSearchTerm);
			loadCB(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm, loadCB, last]);

	return (
		<>
			<MediaQuery minWidth={0} maxWidth={1019}>
				<View style={[styles.filterGroupVertical]}>
					<View style={styles.containerFullWidthHorizontalNoPadding}>
						<CompanyFilter driver={driver} update={update} />
						<DriverIdFilter driver={driver} update={update} />
						<DriverFirstNameFilter
							driver={driver}
							update={update}
						/>
					</View>
					<View style={styles.containerFullWidthHorizontalNoPadding}>
						<DriverLastNameFilter driver={driver} update={update} />
					</View>
				</View>
			</MediaQuery>
			<MediaQuery minWidth={1020}>
				<View style={[styles.filterGroup]}>
					<CompanyFilter driver={driver} update={update} />
					<DriverIdFilter driver={driver} update={update} />
					<DriverFirstNameFilter driver={driver} update={update} />
					<DriverLastNameFilter driver={driver} update={update} />
				</View>
			</MediaQuery>
		</>
	);
};

export default DriverFilter;
