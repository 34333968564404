import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.Settings.Record';

export default defineMessages({
	freeFormFields: {
		id: `${scope}.freeFormFields`,
		defaultMessage: 'free form fields',
	},
	packages: {
		id: `${scope}.packages`,
		defaultMessage: 'packages',
	},
	addPackage: {
		id: `${scope}.addPackage`,
		defaultMessage: 'add package',
	},
	articles: {
		id: `${scope}.articles`,
		defaultMessage: 'articles',
	},
	addArticle: {
		id: `${scope}.addArticle`,
		defaultMessage: 'add article',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	pageTitle: {
		id: `${scope}.pageTitle`,
		defaultMessage: 'page title',
	},
	addPage: {
		id: `${scope}.addPage`,
		defaultMessage: 'add page',
	},
	header: {
		id: `${scope}.header`,
		defaultMessage: 'record settings',
	},
	essentialArticles: {
		id: `${scope}.essentialArticles`,
		defaultMessage: 'essential articles',
	},
	recommendedArticles: {
		id: `${scope}.recommendedArticles`,
		defaultMessage: 'recommended articles',
	},
	standardArticles: {
		id: `${scope}.standardArticles`,
		defaultMessage: 'standard articles',
	},
	duplicate: {
		id: `${scope}.duplicate`,
		defaultMessage: 'there are some duplicate articles',
	},
	defaultValues: {
		id: `${scope}.defaultValues`,
		defaultMessage: 'default values',
	},
});
