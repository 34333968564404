import { defineMessages } from 'react-intl';

export const scope = 'mango.container.scan';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'upload scanned ECD',
	},
	upload: {
		id: `${scope}.upload`,
		defaultMessage: 'upload',
	},
	progress: {
		id: `${scope}.progress`,
		defaultMessage: 'progress',
	},
	state: {
		id: `${scope}.state`,
		defaultMessage: 'state',
	},
	result: {
		id: `${scope}.result`,
		defaultMessage: 'result',
	},
});
