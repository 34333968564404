import React, { FC } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { MundoText } from '../../../components/elements';
import { ESortMode } from '../../../utilities/enums/ESortMode';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';
import { IBundledArticleListHeaderProps } from './props';

const BundledArticleListHeader: FC<IBundledArticleListHeaderProps> = (
	props,
) => {
	const styles = useStyle();

	return (
		<View style={[styles.wideGroupTable, styles.topMargin20]}>
			<View style={styles.wideGroupTableColumn15NoGrow}>
				<TouchableOpacity
					onPress={() => {
						switch (props.currentSort) {
							case ESortMode.ASC:
								return props.onChangeSort(
									'article',
									ESortMode.DESC,
								);
							default:
								return props.onChangeSort(
									'article',
									ESortMode.ASC,
								);
						}
					}}
				>
					<MundoText message={messages.article} />
				</TouchableOpacity>
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.usedAmount} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.usedUnderflowPrice} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.usedOverflowPrice} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.lowerAmount} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.amount} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.upperAmount} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.underflowPrice} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow}>
				<MundoText message={messages.overflowPrice} />
			</View>
			<View style={styles.wideGroupTableColumn10NoGrow} />
		</View>
	);
};

export default BundledArticleListHeader;
