import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.articleFilterSection';

export default defineMessages({
	article: {
		id: `${scope}.article`,
		defaultMessage: 'article',
	},
});
