import { EItemSource, MArticle, MItem, MPage } from 'mango-utils-client';

export const handlePages = (
	pages: MPage[],
	recArts?: MArticle[],
	inItems?: MItem[],
	additionalValues?: (item: any, source: any) => void,
): { title: string; items: MItem[] }[] => {
	const p: Array<{ title: string; items: MItem[] }> = pages.map((page) => {
		return {
			title: page.title,
			items: page.articles.map(
				(article) =>
					new MItem({
						...article,
						source: EItemSource.ARTICLE,
						articleId: article._id,
						amount: 1,
						checked: false,
					}),
			),
		};
	});
	if (!inItems && !recArts) {
		return p;
	} else {
		p.forEach((page) =>
			workWithItems(page.items, recArts, inItems || [], additionalValues),
		);
	}
	return p;
};
const workWithItems = (
	items: MItem[],
	recArts?: MArticle[],
	inItems?: MItem[],
	additionalValues?: (item: any, source: any) => void,
) => {
	items.forEach((item) => {
		let bookedItem;
		if (inItems) {
			bookedItem = inItems.find((bItem) => item._id === bItem._id);
		}
		if (!bookedItem && recArts) {
			const foundRecArt = recArts.find((rA) => item._id === rA._id);
			if (foundRecArt) {
				bookedItem = new MItem(foundRecArt);
			}
		}
		if (bookedItem) {
			const next = new MItem(bookedItem);
			item.amount = next.amount;
			item.data = next.data;
			if (additionalValues) {
				additionalValues(item, next);
			}
			item.highlighted = true;
		}
		if (item.subArticles.length > 0) {
			workWithItems(item.subArticles as MItem[], inItems);
		}
	});
};
