import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.truckFilterSection';

export default defineMessages({
	truck: {
		id: `${scope}.truck`,
		defaultMessage: 'truck',
	},
	global: {
		id: `${scope}.global`,
		defaultMessage: 'Total Profit',
	},
	filterGlobal: {
		id: `${scope}.filterGlobal`,
		defaultMessage: 'Filter Total Profit by Trucks',
	},
	useAllTrucks: {
		id: `${scope}.useAllTrucks`,
		defaultMessage: 'use all',
	},
});
