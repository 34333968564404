import { encode } from 'base-64';
import React, { FC, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextInput, View } from 'react-native';

import { alert } from '../../../../utilities/alert';
import { config } from '../../../../utilities/config';
import { AuthContext } from '../../../../utilities/hooks/auth';
import { useStyle } from '../../../../utilities/hooks/styles';
import { save } from '../../../../utilities/localStorage';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import MundoButton from '../../../../components/elements/MundoButton';
import messages from './messages';

const Login: FC = () => {
	const styles = useStyle();
	const [username, onChangeUsername] = useState('');
	const [password, onChangePassword] = useState('');
	const { checkLogin } = useContext(AuthContext);
	const intl = useIntl();
	const SERVER_BASE_URL = config.API_URL;

	const userLogin = async () => {
		const postOptions = {
			headers: {
				Authorization: 'Basic ' + encode(username + ':' + password),
			},
			method: 'POST',
		};
		try {
			const fetchResult = await fetch(
				`${SERVER_BASE_URL}/login`,
				postOptions,
			);
			if (fetchResult.status === 201) {
				const {
					access_token,
					refresh_token,
				} = await fetchResult.json();
				await save('access_token', access_token).catch((e) =>
					alert('error', e, 'error'),
				);
				await save('refresh_token', refresh_token).catch((e) =>
					alert('error', e, 'error'),
				);
				checkLogin().catch((e) => console.error(e));
			} else if (fetchResult.status === 401) {
				alert(
					`${fetchResult.status}`,
					capitalizeFirstWord(
						intl.formatMessage(messages.invalidUserPassword),
					),
					'warning',
				);
			} else if (fetchResult.status === 403) {
				alert(
					`${fetchResult.status}`,
					capitalizeFirstWord(
						intl.formatMessage(messages.disabledAccount),
					),
					'warning',
				);
			} else if (fetchResult.status === 429) {
				alert(
					`${fetchResult.status}`,
					capitalizeFirstWord(
						intl.formatMessage(messages.failedLogins),
					),
					'warning',
				);
			}
		} catch (e) {
			alert('error', e, 'error');
		}
	};
	return (
		<View
			style={[
				styles.verticalPadding10,
				styles.buttonGroupVertical,
				styles.fullWidth,
				styles.alignCenterHorizontally,
			]}
		>
			<TextInput
				{...{ dataSet: { cy: 'login.username' } }}
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.user),
				)}
				style={[styles.input, styles.fullWidth]}
				onChangeText={(text: string) => onChangeUsername(text)}
				value={username}
			/>
			<TextInput
				{...{ dataSet: { cy: 'login.password' } }}
				placeholder={capitalizeFirstWord(
					intl.formatMessage(messages.password),
				)}
				style={[styles.input, styles.fullWidth]}
				secureTextEntry={true}
				onChangeText={(text: string) => onChangePassword(text)}
				onKeyPress={async (key) => {
					if (key.nativeEvent.key === 'Enter') {
						key.preventDefault();
						await userLogin();
					}
				}}
				value={password}
			/>
			<MundoButton
				styles={styles.fullWidth}
				dataSet={{ cy: 'form-login-button' }}
				onPress={async () => {
					await userLogin();
				}}
				title={messages.login}
			/>
		</View>
	);
};

export default Login;
