import { defineMessages } from 'react-intl';

export const scope = 'mango.container.ArchiveOverview';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'archive overview',
	},
	paymentMethod: {
		id: `${scope}.paymentMethod`,
		defaultMessage: 'payment method',
	},
	customerInformation: {
		id: `${scope}.customerInformation`,
		defaultMessage: 'customer information',
	},
	address: {
		id: `${scope}.address`,
		defaultMessage: 'address',
	},
	billingAddress: {
		id: `${scope}.billingAddress`,
		defaultMessage: 'billing address',
	},
	orderId: {
		id: `${scope}.orderId`,
		defaultMessage: 'order id',
	},
	netSum: {
		id: `${scope}.netSum`,
		defaultMessage: 'net sum',
	},
	grossSum: {
		id: `${scope}.grossSum`,
		defaultMessage: 'gross sum',
	},
	taxSum: {
		id: `${scope}.taxSum`,
		defaultMessage: 'tax sum',
	},
	discountSum: {
		id: `${scope}.discountSum`,
		defaultMessage: 'discount sum',
	},
	issueDate: {
		id: `${scope}.issueDate`,
		defaultMessage: 'issue date',
	},
	dueDate: {
		id: `${scope}.dueDate`,
		defaultMessage: 'due date',
	},
	short: {
		id: `${scope}.short`,
		defaultMessage: 'short',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	amount: {
		id: `${scope}.amount`,
		defaultMessage: 'amount',
	},
	unit: {
		id: `${scope}.unit`,
		defaultMessage: 'unit',
	},
	price: {
		id: `${scope}.price`,
		defaultMessage: 'price',
	},
	positions: {
		id: `${scope}.positions`,
		defaultMessage: 'positions',
	},
});
