import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MCustomer } from 'mango-utils-client';
import { GET_COMPANIES } from './queries';

interface IUseCustomersProps {
	skip: boolean;
	orderBy: string | undefined;
}

export const useCustomers = (props: IUseCustomersProps) => {
	const [customers, onChangeCustomers] = useState<MCustomer[]>([]);
	const [filter, onChangeFilter] = useState<MCustomer>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			customer: filter,
			start: customers.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, customers, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_COMPANIES, {
		onCompleted: (data) => {
			if (data) {
				if (
					!data.customers.length ||
					data.customers.length % 20 !== 0
				) {
					setLoadMoreActive(false);
				}
				if (reset) {
					onChangeReset(false);
					onChangeCustomers(data.customers);
				} else {
					onChangeCustomers([...customers, ...data.customers]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);

		lazyQuery({
			variables: {
				customer: filter,
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.orderBy]);

	return { customers, loading, loadMore, onChangeFilter, loadMoreActive };
};
