import { defineMessages } from 'react-intl';

export const scope = 'mango.components.vehicleCleaning.TableContentExtension';

export default defineMessages({
	malformed: {
		id: `${scope}.malformed`,
		defaultMessage: 'malformed key',
	},
});
