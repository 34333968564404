import { remove } from '../localStorage';

export function removeTokens() {
	remove('access_token').catch((e: any) =>
		console.warn(e, 'the access_token was probably empty already'),
	);
	remove('refresh_token').catch((e: any) =>
		console.warn(e, 'the refresh_token was probably empty already'),
	);
	// window.location.reload(); // scetchy web reload workaround
}
