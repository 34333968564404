import { defineMessages } from 'react-intl';

export const scope = 'mango.components.Settings';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'pipeline name',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit',
	},
	delete: {
		id: `${scope}.delete`,
		defaultMessage: 'delete',
	},
	save: {
		id: `${scope}.save`,
		defaultMessage: 'save',
	},
	cancel: {
		id: `${scope}.cancel`,
		defaultMessage: 'cancel',
	},
	clear: {
		id: `${scope}.clear`,
		defaultMessage: 'clear',
	},
	short: {
		id: `${scope}.short`,
		defaultMessage: 'short',
	},
	titleHeader: {
		id: `${scope}.header`,
		defaultMessage: 'title',
	},
	permissionGridHeader: {
		id: `${scope}.permissionGridHeader`,
		defaultMessage: 'permissions',
	},
	min: {
		id: `${scope}.min`,
		defaultMessage: 'min',
	},
	hour: {
		id: `${scope}.hour`,
		defaultMessage: 'hour',
	},
	day: {
		id: `${scope}.day`,
		defaultMessage: 'day',
	},
	week: {
		id: `${scope}.week`,
		defaultMessage: 'week',
	},
	month: {
		id: `${scope}.month`,
		defaultMessage: 'month',
	},
	quartal: {
		id: `${scope}.quartal`,
		defaultMessage: 'quartal',
	},
	year: {
		id: `${scope}.year`,
		defaultMessage: 'year',
	},
	interval: {
		id: `${scope}.interval`,
		defaultMessage: 'interval',
	},
	duration: {
		id: `${scope}.duration`,
		defaultMessage: 'duration',
	},
	archivConfigHeader: {
		id: `${scope}.archivConfigHeader`,
		defaultMessage: 'archive configuration',
	},
	integrationConfigHeader: {
		id: `${scope}.integrationConfigHeader`,
		defaultMessage: 'integration configuration',
	},
	doxis: {
		id: `${scope}.doxis`,
		defaultMessage: 'doxis',
	},
	enabled: {
		id: `${scope}.enabled`,
		defaultMessage: 'enabled',
	},
	doxisMail: {
		id: `${scope}.doxisMail`,
		defaultMessage: 'doxis e-mail',
	},
	couldNotFind: {
		id: `${scope}.couldNotFind`,
		defaultMessage: 'could not find',
	},
	countryConfigHeader: {
		id: `${scope}.contryConfigHeader`,
		defaultMessage: 'configure available countries',
	},
});
