import React, { FC } from 'react';
import { Image, StyleSheet } from 'react-native';

import icons from '../../../assets';
import { IMundoIconProps } from './props';

const MundoIcon: FC<IMundoIconProps> = (props) => {
	const size = props.fontSize || 30;

	const style = StyleSheet.create({
		icon: {
			width: props.width || props.size || size,
			height: props.height || props.size || size,
		},
	});

	return (
		<Image
			// @ts-ignore // ! this is because the string props.icon is not configured over a enum
			source={icons[props.icon]}
			style={[style.icon, props.styles]}
		/>
	);
};

export default MundoIcon;
