import gql from 'graphql-tag';

export const INITIATE_UPDATE = gql`
	mutation(
		$mail: String!
		$mailDisplay: String!
		$mailUsername: String!
		$mailPassword: String!
		$mailHost: String!
		$mailPort: Int!
		$validationMail: String!
	) {
		initiateMailUpdate(
			mail: $mail
			mailDisplay: $mailDisplay
			mailUsername: $mailUsername
			mailPassword: $mailPassword
			mailHost: $mailHost
			mailPort: $mailPort
			validationMail: $validationMail
		)
	}
`;
