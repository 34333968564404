import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../../../utilities/constants';

export const RecordSettingsStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		article: {
			display: 'flex',
			flexDirection: 'row',
		},
		buttons: {
			display: 'flex',
			marginLeft: 10,
			flexDirection: 'row',
		},
		middleAlignment: {
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		laneCard: {
			margin: 10,
			padding: 10,
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5,
			borderWidth: 1,
			minWidth: 280,
			backgroundColor: theme.borderSecondaryColor,
			borderColor: theme.borderSecondaryColor,
		},
	});
