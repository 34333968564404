import { useLazyQuery } from '@apollo/client';
import { MPaymentMethod } from 'mango-utils-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_PAYMENTMETHODS } from './queries';

interface IUsePaymentMethodsProps {
	skip: boolean;
	orderBy?: string | undefined;
}

export const usePaymentMethods = (props: IUsePaymentMethodsProps) => {
	const [paymentMethods, onChangePaymentMethods] = useState<MPaymentMethod[]>(
		[],
	);
	const [filter, onChangeFilter] = useState<MPaymentMethod>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			paymentMethods: filter,
			start: paymentMethods.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, paymentMethods, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_PAYMENTMETHODS, {
		onCompleted: (data) => {
			if (data) {
				if (
					!data.paymentMethods.length ||
					data.paymentMethods.length % 20 !== 0
				) {
					setLoadMoreActive(false);
				}
				if (reset) {
					onChangePaymentMethods(data.paymentMethods);
					onChangeReset(false);
				} else {
					onChangePaymentMethods([
						...paymentMethods,
						...data.paymentMethods,
					]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				paymentMethods: filter,
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.orderBy]);

	return {
		paymentMethods,
		loading,
		loadMore,
		onChangeFilter,
		loadMoreActive,
	};
};
