import React, { FC } from 'react';
import { ScrollView, View } from 'react-native';
// import { useQuery } from '@apollo/client';

// import  GraphQL query from file
import { MModuleDescription } from 'mango-utils-client';
import { MundoText } from '../../../components/elements';
import { useStyle } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import { IDashboardProps } from '../../Dashboard/props';
import OrderList from '../../Order/List';
import { useParams } from '../../../utilities/routing';

export const RecordDashboard: FC<IDashboardProps> = (props) => {
	const styles = useStyle();
	const { pipelineId } = useParams<{ pipelineId: string }>();

	// fetch and return typed data by using a GraphQL query
	// const [recordSettings, onChangeRecordSettings] = useState(
	// 	new MRecordSettings(),
	// );
	// load settings
	// const { loading: loadingSettings } = useQuery(GET_TRECEIPT_SETTINGS, {
	// 	variables: {
	// 		pipelineId,
	// 	},
	// 	onCompleted: (response: { tRecordSettings: MTRecordSettings }) => {
	// 		if (response && response.tRecordSettings) {
	// 			const receiptSettings = response.tRecordSettings;

	// 			onChangeRecordSettings(receiptSettings);
	// 		}
	// 	},
	// });

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					{props.pipeline && (
						<MundoText
							message={transformModuleTitle(
								props.pipeline.modules.find(
									(m) => m.identifier === 'record',
								) || new MModuleDescription(),
								true,
							)}
							styles={styles.headerText}
						/>
					)}
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList
						state={'record'}
						pipeline={pipelineId}
						noFilter
						noHeader
					/>
				</View>
			</ScrollView>
		</View>
	);
};
