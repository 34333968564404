import React, { FC, useContext, useEffect, useState } from 'react';
import {
	Image,
	ImageSourcePropType,
	TouchableOpacity,
	View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

import icons from '../../assets';
import { SidebarContext } from '../../utilities/contexts/Sidebar';
import { SettingsContext } from '../../utilities/hooks/settings';
import { useTheme } from '../../utilities/hooks/styles';
import MundoButton from '../elements/MundoButton';
import { Content } from './components/Content';
import sidebarStyles from './styles';

const DEFAULT_LOGO_HEIGHT = 40;

const Sidebar: FC = (props) => {
	const { theme } = useTheme();
	const { settings } = useContext(SettingsContext);
	const { isExpanded, onChangeExpanded } = useContext(SidebarContext);
	const [logoWidth, setLogoWidth] = useState(40);
	const [logoHeight, setLogoHeight] = useState(40);

	const isTablet = useMediaQuery({ maxDeviceWidth: 1024 });

	useEffect(() => {
		Image.getSize(
			settings.logoURL,
			(width, height) => {
				setLogoHeight(DEFAULT_LOGO_HEIGHT);
				setLogoWidth((width * DEFAULT_LOGO_HEIGHT) / height);
			},
			() => {
				// log error
			},
		);
	}, [settings.logoURL]);

	return (
		<View style={sidebarStyles(theme).container}>
			{!isExpanded ? (
				<View style={sidebarStyles(theme).sidebar}>
					<MundoButton
						onPress={() => onChangeExpanded(true)}
						icon={'menu'}
					/>
					<Content detail={false} />
				</View>
			) : (
				<View
					style={[
						sidebarStyles(theme).sidebar,
						isTablet
							? sidebarStyles(theme).sidebarDetailTablet
							: sidebarStyles(theme).sidebarDetail,
					]}
				>
					<View style={sidebarStyles(theme).headerContainer}>
						<View style={sidebarStyles(theme).logoContainer}>
							<Image
								source={
									(settings.logoURL !== ''
										? { uri: settings.logoURL }
										: icons.logoSmall) as ImageSourcePropType
								}
								resizeMode="contain"
								style={
									settings.logoURL !== ''
										? {
												width: logoWidth,
												height: logoHeight,
										  }
										: sidebarStyles(theme).logoImgDefault
								}
							/>
						</View>
						<MundoButton
							onPress={() => onChangeExpanded(false)}
							icon={isTablet ? 'close' : 'menu'}
							styles={sidebarStyles(theme).sidebarButton}
						/>
					</View>
					<Content detail={true} />
				</View>
			)}
			<View style={sidebarStyles(theme).contentPane}>
				{props.children}
				{isTablet && isExpanded && (
					<TouchableOpacity
						style={sidebarStyles(theme).closer}
						onPress={() => onChangeExpanded(false)}
					/>
				)}
			</View>
		</View>
	);
};

export default Sidebar;
