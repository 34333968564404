import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.profit';

export default defineMessages({
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	netSumYLabel: {
		id: `${scope}.netSumYLabel`,
		defaultMessage: 'net sum (€)',
	},
	availEntry1: {
		id: `${scope}.availEntry1`,
		defaultMessage: 'you loaded ',
	},
	availEntry2: {
		id: `${scope}.availEntry2`,
		defaultMessage: ' data entries. There are more available',
	},
	profitFrom: {
		id: `${scope}.profitFrom`,
		defaultMessage: 'profit from',
	},
	toManyBars: {
		id: `${scope}.toManyBars`,
		defaultMessage: 'too many bars selected',
	},
	share: {
		id: `${scope}.share`,
		defaultMessage: 'share',
	},
});
