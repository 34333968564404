import { defineMessages } from 'react-intl';

export const scope = 'mango.AddressEdit';
export default defineMessages({
	street: {
		id: `${scope}.street`,
		defaultMessage: 'street',
	},
	city: {
		id: `${scope}.city`,
		defaultMessage: 'city',
	},
	zipCode: {
		id: `${scope}.zipCode`,
		defaultMessage: 'zip code',
	},
	zipCodeError: {
		id: `${scope}.zipCodeError`,
		defaultMessage: 'the zip code is in the wrong format!',
	},
	cityError: {
		id: `${scope}.cityError`,
		defaultMessage: 'the city is in the wrong format!',
	},
	streetError: {
		id: `${scope}.streetError`,
		defaultMessage: 'the street is in the wrong format!',
	},
});
