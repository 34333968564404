import { gql } from '@apollo/client';

export const GET_TRAILERS = gql`
	query($any: TTrailerInput!, $count: Int!) {
		tTrailers(trailer: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;
