import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const mundoBasicTableHeaderStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		text: {
			color: theme.textMainColor,
		},
		container: {
			flexDirection: 'row',
			alignItems: 'center',
		},
	});

export default mundoBasicTableHeaderStyles;
