import { useLazyQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MCustomer } from 'mango-utils-client';
import {
	MundoButton,
	MundoCheckBox,
	MundoText,
} from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { useStyle } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { CSS_COLOR_NAMES } from '../../utilities/randomColor';
import { SFilterSectionContext } from '../FilterSection/FilterSection';
import messages from './messages';
import { ICustomerFilterSectionProps } from './props';
import { GET_CUSTOMERS } from './queries';

export const CustomerFilterSection: FC<ICustomerFilterSectionProps> = ({
	dispatchSelectedCustomerAction,
	selectedCustomers,
	customerColors,
	dispatchCustomerColorAction,
	global,
	onChangeGlobal,
	globalFiltered,
	onChangeGlobalFiltered,
}) => {
	const styles = useStyle();

	const filterSectionContext = useContext(SFilterSectionContext);

	const [useAllCustomers, onChangeUseAll] = useState<boolean>(false);

	const [getAllCustomers] = useLazyQuery(GET_CUSTOMERS, {
		variables: {
			any: {},
			count: 500,
		},
		onCompleted: (data) => {
			if (data) {
				Object.keys(data).forEach((key) => {
					if (key.length) {
						const customers = data[key];
						const colors = customers.map(
							() =>
								CSS_COLOR_NAMES[
									Math.floor(
										Math.random() * CSS_COLOR_NAMES.length,
									)
								],
						);
						dispatchSelectedCustomerAction({
							type: ETypes.RESET_TO,
							item: customers,
						});
						dispatchCustomerColorAction({
							type: ETypes.RESET_TO,
							item: colors,
						});
						onChangeUseAll(true);
					}
				});
			}
		},
	});

	return (
		<View style={[{ justifyContent: 'space-between' }]}>
			<View style={[styles.elevated, { maxHeight: 1000 }]}>
				<View style={[styles.elevated, styles.horizontalLayout]}>
					<View style={styles.elevated}>
						<MundoText message={messages.customer} />
						<MundoObjectTypeahead
							QUERY={GET_CUSTOMERS}
							onChange={(customer) => {
								if (
									customer &&
									!selectedCustomers.find(
										(sC) => customer._id === sC._id,
									)
								) {
									dispatchSelectedCustomerAction({
										type: ETypes.ADD,
										item: new MCustomer({
											...customer,
											title: customer.title as string,
										}),
									});
									dispatchCustomerColorAction({
										type: ETypes.ADD,
										item:
											CSS_COLOR_NAMES[
												Math.floor(
													Math.random() *
														CSS_COLOR_NAMES.length,
												)
											],
									});
								}
							}}
						/>
					</View>
					<MundoCheckBox
						checked={useAllCustomers}
						onCheckedChanged={(c) => {
							if (c) {
								getAllCustomers();
							} else {
								dispatchSelectedCustomerAction({
									type: ETypes.RESET_TO,
									item: [],
								});
								dispatchCustomerColorAction({
									type: ETypes.RESET_TO,
									item: [],
								});
								onChangeUseAll(false);
							}
						}}
						title={messages.useAllCustomers}
					/>
				</View>
				<ScrollView>
					{selectedCustomers.map((customer, index) => {
						return (
							<View
								style={
									styles.containerFullWidthHorizontalCenterAligned
								}
								key={customer._id}
							>
								<MundoText message={customer.title} />
								<MundoButton
									onPress={() =>
										dispatchCustomerColorAction({
											type: ETypes.EDIT,
											item:
												CSS_COLOR_NAMES[
													Math.floor(
														Math.random() *
															CSS_COLOR_NAMES.length,
													)
												],
											location: index,
										})
									}
									styles={{
										backgroundColor: customerColors[index],
									}}
								/>
								<MundoButton
									onPress={() => {
										dispatchSelectedCustomerAction({
											type: ETypes.REMOVE,
											location: index,
										});
										dispatchCustomerColorAction({
											type: ETypes.REMOVE,
											location: index,
										});
									}}
									icon={'minus'}
								/>
							</View>
						);
					})}
				</ScrollView>
			</View>
			{filterSectionContext.useGlobal && (
				<View>
					<View
						style={styles.containerFullWidthHorizontalCenterAligned}
					>
						<MundoCheckBox
							checked={
								global !== undefined
									? global
									: filterSectionContext.global
							}
							onCheckedChanged={
								onChangeGlobal ||
								filterSectionContext.onChangeGlobal
							}
							title={messages.global}
						/>
						<MundoButton
							onPress={() => console.log('')}
							styles={{ backgroundColor: 'green' }}
						/>
					</View>
					<MundoCheckBox
						checked={
							globalFiltered !== undefined
								? globalFiltered
								: filterSectionContext.globalFiltered
						}
						onCheckedChanged={
							onChangeGlobalFiltered ||
							filterSectionContext.onChangeGlobalFiltered
						}
						title={messages.filterGlobal}
					/>
				</View>
			)}
		</View>
	);
};
