import gql from 'graphql-tag';

export const GET_GROUPS = gql`
	query getGroups(
		$group: GroupInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		groups(group: $group, start: $start, count: $count, orderBy: $orderBy) {
			_id
			id
			title
			inheritance
		}
	}
`;
