import gql from 'graphql-tag';

export const GET_CUSTOMERS = gql`
	query getCustomers($any: CustomerInput!, $count: Int!) {
		customers(customer: $any, count: $count) {
			id
			title
		}
	}
`;
