import React, { FC } from 'react';
import { View } from 'react-native';

import { MundoText } from '../../../components/elements';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';
import { ICustomerCardProps } from './props';

const CustomerCard: FC<ICustomerCardProps> = ({ customer }) => {
	const styles = useStyle();
	// return fehlte
	return (
		<View
			style={[
				styles.displayCard,
				customer.disabled && styles.deletedCard,
			]}
		>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.bottomMargin5,
				]}
			>
				<MundoText message={messages.title}>
					: {customer.title}
				</MundoText>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.bottomMargin5,
				]}
			>
				<MundoText message={messages.id}>: {customer.id}</MundoText>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.bottomMargin5,
				]}
			>
				<MundoText message={messages.street}>
					: {customer.address.street}
				</MundoText>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.bottomMargin5,
				]}
			>
				<MundoText message={messages.country}>
					: {customer.address.country}
				</MundoText>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.bottomMargin5,
				]}
			>
				<MundoText message={messages.ustId}>
					: {customer.ustID}
				</MundoText>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.bottomMargin5,
				]}
			>
				<MundoText message={messages.mail}>: {customer.mail}</MundoText>
			</View>
			{customer.disabled && (
				<View
					style={[
						styles.containerFullWidthHorizontalNoPadding,
						styles.bottomMargin5,
					]}
				>
					<MundoText message={messages.disabled} />
				</View>
			)}
		</View>
	);
};

export default CustomerCard;
