import { ERegion, MCustomer, MPaymentMethod } from 'mango-utils-client';

export function checkTax(
	company: MCustomer,
	paymentMethod: MPaymentMethod,
	host?: MCustomer,
): boolean {
	if (host) {
	}
	return paymentMethod && paymentMethod.customer && paymentMethod._id
		? paymentMethod.customer.allegiance === ERegion.N
		: company.allegiance === ERegion.N;
}
