import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.articles';

export default defineMessages({
	availEntry1: {
		id: `${scope}.availEntry1`,
		defaultMessage: 'You loaded ',
	},
	availEntry2: {
		id: `${scope}.availEntry2`,
		defaultMessage: ' data entries. There are more available',
	},
	header: {
		id: `${scope}.profitFrom`,
		defaultMessage: 'Article',
	},
	toManyBars: {
		id: `${scope}.toManyBars`,
		defaultMessage: 'to many bars selected',
	},
	noArticleSelected: {
		id: `${scope}.noArticleSelected`,
		defaultMessage: 'no article selected',
	},
	noMatchingUnitType: {
		id: `${scope}.noMatchingUnitType`,
		defaultMessage: 'no matching unit type',
	},
});
