import { useLazyQuery } from '@apollo/client';
import React, { FC, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { MTTruck } from 'mango-utils-client';
import {
	MundoButton,
	MundoCheckBox,
	MundoText,
} from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { useStyle } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { CSS_COLOR_NAMES } from '../../utilities/randomColor';
import { SFilterSectionContext } from '../FilterSection/FilterSection';
import messages from './messages';
import { ITruckFilterSectionProps } from './props';
import { GET_TRUCKS } from './queries';

export const TruckFilterSection: FC<ITruckFilterSectionProps> = ({
	dispatchSelectedTruckAction,
	selectedTrucks,
	truckColors,
	dispatchTruckColorAction,
	selectedCustomers,
}) => {
	const styles = useStyle();

	const {
		global,
		onChangeGlobal,
		globalFiltered,
		onChangeGlobalFiltered,
		useGlobal,
	} = useContext(SFilterSectionContext);

	const [useAllTrucks, onChangeUseAll] = useState<boolean>(false);

	const [getAllTrucks] = useLazyQuery(GET_TRUCKS, {
		variables: {
			any: {
				companyID: `${selectedCustomers.map((c) => c._id).join('|')}`,
			},
			count: 500,
		},
		onCompleted: (data) => {
			if (data) {
				Object.keys(data).forEach((key) => {
					if (key.length) {
						const Trucks = data[key];
						const colors = Trucks.map(
							() =>
								CSS_COLOR_NAMES[
									Math.floor(
										Math.random() * CSS_COLOR_NAMES.length,
									)
								],
						);
						dispatchSelectedTruckAction({
							type: ETypes.RESET_TO,
							item: Trucks,
						});
						dispatchTruckColorAction({
							type: ETypes.RESET_TO,
							item: colors,
						});
						onChangeUseAll(true);
					}
				});
			}
		},
	});

	return (
		<View style={[{ justifyContent: 'space-between' }]}>
			<View style={[styles.elevated, { maxHeight: 1000 }]}>
				<View style={[styles.elevated, styles.horizontalLayout]}>
					<View style={styles.elevated}>
						<MundoText message={messages.truck} />
						<MundoObjectTypeahead
							QUERY={GET_TRUCKS}
							onChange={(truck) => {
								if (
									truck &&
									!selectedTrucks.find(
										(sC) => truck._id === sC._id,
									)
								) {
									dispatchSelectedTruckAction({
										type: ETypes.ADD,
										item: new MTTruck({
											...truck,
											title: truck.title as string,
										}),
									});
									dispatchTruckColorAction({
										type: ETypes.ADD,
										item:
											CSS_COLOR_NAMES[
												Math.floor(
													Math.random() *
														CSS_COLOR_NAMES.length,
												)
											],
									});
								}
							}}
							additionalFilters={{
								companyID: `${selectedCustomers
									.map((c) => c._id)
									.join('|')}`,
							}}
						/>
					</View>
					<MundoCheckBox
						checked={useAllTrucks}
						onCheckedChanged={(c) => {
							if (c) {
								getAllTrucks();
							} else {
								dispatchSelectedTruckAction({
									type: ETypes.RESET_TO,
									item: [],
								});
								dispatchTruckColorAction({
									type: ETypes.RESET_TO,
									item: [],
								});
								onChangeUseAll(false);
							}
						}}
						title={messages.useAllTrucks}
					/>
				</View>
				<ScrollView>
					{selectedTrucks.map((Truck, index) => {
						return (
							<View
								style={
									styles.containerFullWidthHorizontalCenterAligned
								}
								key={Truck._id}
							>
								<MundoText message={Truck.title} />
								<MundoButton
									onPress={() =>
										dispatchTruckColorAction({
											type: ETypes.EDIT,
											item:
												CSS_COLOR_NAMES[
													Math.floor(
														Math.random() *
															CSS_COLOR_NAMES.length,
													)
												],
											location: index,
										})
									}
									styles={{
										backgroundColor: truckColors[index],
									}}
								/>
								<MundoButton
									onPress={() => {
										dispatchSelectedTruckAction({
											type: ETypes.REMOVE,
											location: index,
										});
										dispatchTruckColorAction({
											type: ETypes.REMOVE,
											location: index,
										});
									}}
									icon={'minus'}
								/>
							</View>
						);
					})}
				</ScrollView>
			</View>
			{useGlobal && (
				<View>
					<View
						style={styles.containerFullWidthHorizontalCenterAligned}
					>
						<MundoCheckBox
							checked={global}
							onCheckedChanged={onChangeGlobal}
							title={messages.global}
						/>
						<MundoButton
							onPress={() => console.log('')}
							styles={{ backgroundColor: 'green' }}
						/>
					</View>
					<MundoCheckBox
						checked={globalFiltered}
						onCheckedChanged={onChangeGlobalFiltered}
						title={messages.filterGlobal}
					/>
				</View>
			)}
		</View>
	);
};
