import 'intl';
import 'intl/locale-data/jsonp/cs';
import 'intl/locale-data/jsonp/de';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/es';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/hu';
import 'intl/locale-data/jsonp/it';
import 'intl/locale-data/jsonp/lv';
import 'intl/locale-data/jsonp/pl';
import 'intl/locale-data/jsonp/ro';
import 'intl/locale-data/jsonp/ru';
import 'intl/locale-data/jsonp/sl';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

// utilities
import { ELanguages, flattenObject } from 'mango-utils-client';
import lang from './translations';
import { alert } from './utilities/alert';
import { LanguageContext } from './utilities/contexts/Language';
import { AuthProvider } from './utilities/hooks/auth';
import { StyleProvider } from './utilities/hooks/styles/provider';
import { load, save } from './utilities/localStorage';

// Wrapper
import ErrorBoundary from './components/ErrorBoundary';

import { AppRouter } from './App.router';
import { CustomApolloProvider } from './utilities/apollo/CustomApolloProvider';

// Disable on screen warnings
console.disableYellowBox = true;

const App: FC = () => {
	const [locale, setLocale] = useState<ELanguages>(ELanguages.en);
	const [messages, setMessages] = useState(flattenObject(lang.en));
	// Function to change language
	const onChangeLanguage = useCallback((language: ELanguages) => {
		save('language', language).catch((e) =>
			alert('failed to save language', `${e}`, 'error'),
		);
		setLocale(language);
		setMessages(flattenObject(lang[language]));
	}, []);
	/**
	 * Effect to handle Language loading
	 */
	useEffect(() => {
		load('language')
			.then((language: string) => {
				if (language) {
					onChangeLanguage(language as ELanguages);
				}
			})
			.catch((e) => {
				alert('error', e, 'error');
			});
	}, [onChangeLanguage]);

	return (
		<ErrorBoundary>
			<LanguageContext.Provider
				value={{ onChangeLanguage, language: locale }}
			>
				<IntlProvider
					onError={() => {
						/* supress */
					}}
					defaultLocale={'en'}
					locale={locale}
					messages={messages}
				>
					<StyleProvider>
						<AuthProvider>
							<CustomApolloProvider>
								<AppRouter />
							</CustomApolloProvider>
						</AuthProvider>
					</StyleProvider>
				</IntlProvider>
			</LanguageContext.Provider>
		</ErrorBoundary>
	);
};

export default App;
