import { gql } from '@apollo/client';

export const GET_DRIVERS = gql`
	query($any: TDriver!, $count: Int!) {
		tDrivers(driver: $any, count: $count, isTypeahead: true) {
			_id
			id
			title
		}
	}
`;
