const stringIsEmpty = (input: string): boolean => {
	return !input || input.trim().length === 0;
};

export const capitalizeFirstWord = (input: string): string => {
	if (stringIsEmpty(input)) {
		return '';
	}
	return input.charAt(0).toUpperCase() + input.slice(1);
};

export const capitalizeAllWords = (input: string): string => {
	if (stringIsEmpty(input)) {
		return '';
	}
	return input.split(' ').map(capitalizeFirstWord).join(' ');
};
