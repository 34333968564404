import React, { FC, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';

import { EUserClassification } from 'mango-utils-client';
import { SidebarContext } from '../../utilities/contexts/Sidebar';
import { AuthContext } from '../../utilities/hooks/auth';
import { useTheme } from '../../utilities/hooks/styles';
import Sidebar from './Sidebar';
import sidebarStyles from './styles';
import {
	getLocalSettings,
	saveLocalSettings,
} from '../../utilities/localSettings';
import { alert } from '../../utilities/alert';

const SidebarComponent: FC = (props) => {
	const { theme } = useTheme();
	const [currentPipeline, onChangeCurrentPipeline] = useState<string>('');

	const [isExpanded, onChangeExpanded] = useState(false);
	const { user } = useContext(AuthContext);
	// load selection from local storage
	useEffect(() => {
		getLocalSettings()
			.then((data) => {
				onChangeCurrentPipeline(data.expandedPipeline[0]);
			})
			.catch((e) => alert('error', e, 'error'));
	}, []);
	// save on change current
	useEffect(() => {
		getLocalSettings()
			.then((data) => {
				data.expandedPipeline = [currentPipeline];
				saveLocalSettings({
					expandedPipeline: data.expandedPipeline,
				});
			})
			.catch((e) => alert('error', e, 'error'));
	});

	return (
		<SidebarContext.Provider
			value={{
				isExpanded,
				onChangeExpanded,
				currentPipeline,
				onChangeCurrentPipeline,
			}}
		>
			{user.classification !== EUserClassification.TERMINAL ? (
				<Sidebar {...props} />
			) : (
				<View style={sidebarStyles(theme).contentPane}>
					{props.children}
				</View>
			)}
		</SidebarContext.Provider>
	);
};

export default SidebarComponent;
