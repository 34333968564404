import { defineMessages } from 'react-intl';

export const scope = 'mango.container.DashboardPage';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'dashboard',
	},
	notUnlocked: {
		id: `${scope}.notUnlocked`,
		defaultMessage:
			'your account has not been enabled by an administrator yet.',
	},
	truck: {
		id: `${scope}.truck`,
		defaultMessage: 'truck',
	},
	trailer: {
		id: `${scope}.trailer`,
		defaultMessage: 'trailer',
	},
	driver: {
		id: `${scope}.driver`,
		defaultMessage: 'driver',
	},
	pipeline: {
		id: `${scope}.pipeline`,
		defaultMessage: 'pipeline',
	},
});
