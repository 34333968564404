import { createContext } from 'react';

import { MSettings } from 'mango-utils-client';
import { alert } from '../../../alert';

interface ISettingsContext {
	onChangeSettings: (newSettings: MSettings) => void;
	settings: MSettings;
}

export const SettingsContext = createContext<ISettingsContext>({
	onChangeSettings: (newSettings: MSettings) => {
		alert('settings is undefined', JSON.stringify(newSettings), 'warning');
	},
	settings: new MSettings(),
});
