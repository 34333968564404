import { defineMessages } from 'react-intl';

export const scope = 'mango.component.settings.OrderList';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'order list settings',
	},
	sort: {
		id: `${scope}.sort`,
		defaultMessage: 'sort',
	},
	warning: {
		id: `${scope}.warning`,
		defaultMessage: 'the table might be too big for small displays',
	},
	idDescription: {
		id: `${scope}.idDescription`,
		defaultMessage: 'id of current order',
	},
	customerDescription: {
		id: `${scope}.customerDescription`,
		defaultMessage: 'customer name',
	},
	pipelineDescription: {
		id: `${scope}.pipelineDescription`,
		defaultMessage: 'short identifier for the pipeline',
	},
	stateDescription: {
		id: `${scope}.stateDescription`,
		defaultMessage: 'state of the current order',
	},
	substateDescription: {
		id: `${scope}.substateDescription`,
		defaultMessage: 'substate of the current order',
	},
	useDescription: {
		id: `${scope}.useDescription`,
		defaultMessage: 'button to start module for the current order',
	},
	overviewDescription: {
		id: `${scope}.overviewDescription`,
		defaultMessage: 'overview button for the current order',
	},
	pdfDescription: {
		id: `${scope}.pdfDescription`,
		defaultMessage: 'PDF picker for the current order',
	},
	dateDescription: {
		id: `${scope}.dateDescription`,
		defaultMessage: 'date of the current order',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	overview: {
		id: `${scope}.overview`,
		defaultMessage: 'overview',
	},
	pdf: {
		id: `${scope}.pdf`,
		defaultMessage: 'PDF',
	},
	pdfSender: {
		id: `${scope}.pdfSender`,
		defaultMessage: 'PDF sender',
	},
	pdfSenderDesc: {
		id: `${scope}.pdfSenderDesc`,
		defaultMessage: 'Button to send PDF documents directly to customers',
	},
	pipeline: {
		id: `${scope}.pipeline`,
		defaultMessage: 'pipeline',
	},
	state: {
		id: `${scope}.state`,
		defaultMessage: 'state',
	},
	substate: {
		id: `${scope}.substate`,
		defaultMessage: 'substate',
	},
	use: {
		id: `${scope}.use`,
		defaultMessage: 'use',
	},
	possibleColums: {
		id: `${scope}.possibleColums`,
		defaultMessage: 'possible columns',
	},
});
