import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const mundoCardStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		card: {
			borderWidth: 1,
			borderColor: theme.borderSecondaryColor,
			backgroundColor: theme.boderMainColor,
			borderRadius: 10,
			padding: 20,
			minWidth: 300,
			shadowColor: '#e5e5e5',
			shadowOffset: { width: 0, height: 6 },
			shadowOpacity: 0.4,
			shadowRadius: 10,
		},
	});

export default mundoCardStyles;
