import { defineMessages } from 'react-intl';

export const scope = 'mango.components.UserEdit';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'edit user',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	groups: {
		id: `${scope}.groups`,
		defaultMessage: 'groups',
	},
	mail: {
		id: `${scope}.mail`,
		defaultMessage: 'e-mail address',
	},
	telephone: {
		id: `${scope}.telephone`,
		defaultMessage: 'telephone',
	},
	tags: {
		id: `${scope}.tags`,
		defaultMessage: 'tags',
	},
	addGroup: {
		id: `${scope}.addGroup`,
		defaultMessage: 'add group',
	},
	classification: {
		id: `${scope}.classification`,
		defaultMessage: 'classification',
	},
	mailError: {
		id: `${scope}.mailError`,
		defaultMessage: 'the e-mail address is in the wrong format!',
	},
	phoneError: {
		id: `${scope}.phoneError`,
		defaultMessage: 'the phone number is in the wrong format!',
	},
	companyName: {
		id: `${scope}.companyName`,
		defaultMessage: 'company name',
	},
	defaultClass: {
		id: `${scope}.defaultClass`,
		defaultMessage: 'default',
	},
	adminClass: {
		id: `${scope}.adminClass`,
		defaultMessage: 'admin',
	},
	memberClass: {
		id: `${scope}.memberClass`,
		defaultMessage: 'member',
	},
	terminalClass: {
		id: `${scope}.terminalClass`,
		defaultMessage: 'terminal',
	},
});
