import { defineMessages } from 'react-intl';

export const scope = 'mango.container.vehicleCleaning.Export';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'Navision Export',
	},
	noData: {
		id: `${scope}.noData`,
		defaultMessage: 'no data found',
	},
	from: {
		id: `${scope}.from`,
		defaultMessage: 'from',
	},
	to: {
		id: `${scope}.to`,
		defaultMessage: 'to',
	},
	export: {
		id: `${scope}.export`,
		defaultMessage: 'export',
	},
	protocol: {
		id: `${scope}.protocol`,
		defaultMessage: 'download latest protocol',
	},
	exported: {
		id: `${scope}.exported`,
		defaultMessage: 'exported',
	},
	total: {
		id: `${scope}.total`,
		defaultMessage: 'total',
	},
	net: {
		id: `${scope}.net`,
		defaultMessage: 'net sum',
	},
	gross: {
		id: `${scope}.gross`,
		defaultMessage: 'gross sum',
	},
	tax: {
		id: `${scope}.tax`,
		defaultMessage: 'tax sum',
	},
	group: {
		id: `${scope}.group`,
		defaultMessage: 'group',
	},
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	filter: {
		id: `${scope}.filter`,
		defaultMessage: 'filter',
	},
	preview: {
		id: `${scope}.preview`,
		defaultMessage: 'preview',
	},
	loadMore: {
		id: `${scope}.loadMore`,
		defaultMessage: 'load more',
	},
	exports: {
		id: `${scope}.exports`,
		defaultMessage: 'exports',
	},
});
