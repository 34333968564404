import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

export const articleEditStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		selector: {
			backgroundColor: theme.backgroundSecondaryColor,
			flex: 1,
			marginRight: 10,
			height: 500,
		},
		selectorRight: {
			backgroundColor: theme.backgroundSecondaryColor,
			flex: 1,
			height: 500,
			marginLeft: 10,
		},
		formLeft: {
			width: '40%',
			marginRight: 20,
		},
		formFull: {
			width: '100%',
			marginRight: 20,
		},

		formRight: {
			width: '40%',
			marginLeft: 20,
		},
		formField: {
			minWidth: 200,
		},
		subArticle: {
			padding: 10,
			minHeight: 40,
			borderBottomColor: theme.backgroundSecondaryColor,
			borderBottomWidth: 1,
		},
		disabledSubArticle: {
			backgroundColor: theme.accentSecondaryColor,
		},
		form: {
			flexDirection: 'row',
		},
		checkbox: {
			marginLeft: 20,
			flexDirection: 'column',
		},
	});
