import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.trailerFilterSection';

export default defineMessages({
	trailer: {
		id: `${scope}.trailer`,
		defaultMessage: 'trailer',
	},
	global: {
		id: `${scope}.global`,
		defaultMessage: 'Total Profit',
	},
	filterGlobal: {
		id: `${scope}.filterGlobal`,
		defaultMessage: 'Filter Total Profit by Trailers',
	},
	useAllTrailers: {
		id: `${scope}.useAllTrailers`,
		defaultMessage: 'use all',
	},
});
