import { defineMessages } from 'react-intl';

export const scope = 'mango.components.Forgot';

export default defineMessages({
	mail: {
		id: `${scope}.mail`,
		defaultMessage: 'e-mail address',
	},
	sendMail: {
		id: `${scope}.sendMail`,
		defaultMessage: 'send an e-mail with a reset link',
	},
	reset: {
		id: `${scope}.reset`,
		defaultMessage: 'reset password',
	},
	wentWrong: {
		id: `${scope}.wentWrong`,
		defaultMessage: 'something went wrong',
	},
	cantLogin: {
		id: `${scope}.cantLogin`,
		defaultMessage: "you can't log in right now",
	},
	mailNotFound: {
		id: `${scope}.mailNotFound`,
		defaultMessage: 'e-mail not found',
	},
	plsCheckMail: {
		id: `${scope}.plsCheckMail`,
		defaultMessage:
			"we don't recognize this e-mail address, please check if the provided e-mail is correct",
	},
	mailSended: {
		id: `${scope}.mailSended`,
		defaultMessage: 'mail sent successfully',
	},
	resetLinkSended: {
		id: `${scope}.resetLinkSended`,
		defaultMessage:
			'an e-mail containing a reset link was sent to the address you provided',
	},
});
