import { useQuery } from '@apollo/client';
import React, { FC, useState } from 'react';

import { MundoPicker } from '../../../../components/elements/MundoPicker/MundoPicker';
import vehicleCleaningMessages from '../../../../Plugins/VehicleCleaning/messages';
import { useHistory } from '../../../../utilities/routing';
import orderListMessages from '../messages';
import { IPDFPickerProps } from '../props';
import { PDF_SENSE } from '../queries';

const PDFPicker: FC<IPDFPickerProps> = ({ order }) => {
	const history = useHistory();
	const [pdfs, onChangePDFs] = useState<string[]>([]);
	useQuery(PDF_SENSE, {
		variables: { orderId: order._id },
		onCompleted: (data) => onChangePDFs(data.pdfs),
	});

	const selectPdfLabel = (pdf: string) => {
		switch (pdf) {
			case 'deliveryNote':
				return vehicleCleaningMessages['vehicleCleaning.deliveryNote']; // Lieferschein
			case 'ecd':
				return vehicleCleaningMessages['vehicleCleaning.ecd'];
			case 'order':
				return vehicleCleaningMessages['vehicleCleaning.order'];
			case 'qcd':
				return vehicleCleaningMessages['vehicleCleaning.qcd'];
			case 'tankEntrance':
				return vehicleCleaningMessages['vehicleCleaning.tankEntrance']; // Tankeinstieg
			default:
				return pdf;
		}
	};

	return (
		<MundoPicker
			onChange={(pdf) => history.push(`pdf/${pdf}/${order._id}`)}
			placeholder={orderListMessages.selectPDF}
			values={pdfs.map((pdf) => ({
				value: pdf,
				label: selectPdfLabel(pdf),
			}))}
		/>
	);
};

export default PDFPicker;
