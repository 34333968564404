import { gql } from '@apollo/client';

export const RECORD_OUT = gql`
	mutation recordOut(
		$state: StateInput!
		$orderId: String
		$pipelineId: String!
	) {
		recordOut(
			record: {
				state: $state
				orderId: $orderId
				pipelineId: $pipelineId
			}
		)
	}
`;

export const RECORD_IN = gql`
	query recordIn($id: String!) {
		recordIn(id: $id) {
			id
			orderId
			items {
				articleId
				id
				_id
				title {
					lang
					message
				}
				amount
				data
				dataUnit {
					lang
					message
				}
				unit
			}
		}
	}
`;

export const RECORD_SETTINGS = gql`
	query recordSettings($pipelineId: String!) {
		recordSettings(pipelineId: $pipelineId) {
			title
			pages {
				title
				id
				_id
				articles {
					title {
						lang
						message
					}
					short
					id
					_id
					price
					unit
					dataUnit {
						lang
						message
					}
					essentials
					tax
					subArticles {
						title {
							lang
							message
						}
						short
						id
						_id
						price
						unit
						dataUnit {
							lang
							message
						}
						essentials
						tax
					}
				}
			}
			essentialArticles {
				_id
			}
			recommendedArticles {
				_id
			}
			standardArticles {
				id
				_id
				title {
					lang
					message
				}
				short
				price
				unit
				dataUnit {
					lang
					message
				}
				essentials
				subArticles {
					title {
						lang
						message
					}
					short
					id
					_id
					price
					unit
					dataUnit {
						lang
						message
					}
					essentials
				}
			}
		}
	}
`;
