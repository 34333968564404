import { defineMessages } from 'react-intl';

export const scope = 'mango.container.paymentEdit';
export const errorScope = 'error';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'edit payment method',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	inheritance: {
		id: `${scope}.inheritance`,
		defaultMessage: 'inheritance',
	},
	surcharge: {
		id: `${scope}.surcharge`,
		defaultMessage: 'surcharge',
	},
	paymentTime: {
		id: `${scope}.paymentTime`,
		defaultMessage: 'payment time',
	},
	description: {
		id: `${scope}.description`,
		defaultMessage: 'description',
	},
	cashbackAmount: {
		id: `${scope}.cashbackAmount`,
		defaultMessage: 'cashback amount',
	},
	cashbackActive: {
		id: `${scope}.cashbackActive`,
		defaultMessage: 'cashback active',
	},
	cashbackTime: {
		id: `${scope}.cashbackTime`,
		defaultMessage: 'cashback time',
	},
	tags: {
		id: `${scope}.tags`,
		defaultMessage: 'tags',
	},
	days: {
		id: `${scope}.days`,
		defaultMessage: 'days',
	},
	millipercent: {
		id: `${scope}.millipercent`,
		defaultMessage: 'milipercent',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	noColor: {
		id: `${scope}.noColor`,
		defaultMessage: 'none',
	},
	red: {
		id: `${scope}.red`,
		defaultMessage: 'red',
	},
	color: {
		id: `${scope}.color`,
		defaultMessage: 'color',
	},
	numericError: {
		id: `${errorScope}.numericError`,
		defaultMessage: 'numeric input only',
	},
	numericErrorDescr: {
		id: `${errorScope}.numericErrorDescr`,
		defaultMessage: 'please enter numeric characters only',
	},
});
