import React, { FC } from 'react';
import { View } from 'react-native';

import { alert } from '../../utilities/alert';
import IDatePickerProps from './props';

const DatePicker: FC<IDatePickerProps> = (props) => {
	return (
		<View
			style={{
				borderColor: '#DDD',
				borderWidth: 1,
				minWidth: '100px',
				width: '160px',
			}}
		>
			<input
				style={{
					minWidth: '100px',
					width: '140px',
					height: '38px',
					fontFamily: 'Roboto, Helvetica, Arial',
					paddingLeft: 5,
					paddingRight: 5,
					borderColor: '#DDD',
					borderWidth: 0,
				}}
				{...{ dataSet: { cy: props.cyId } }}
				type="date"
				value={props.value}
				defaultValue={props.defaultValue}
				onChange={(event: any) => {
					if (event.target.value) {
						props.onChange(new Date(event.target.value).getTime());
					} else {
						alert('error', event, 'error');
					}
				}}
			/>
		</View>
	);
};

export default DatePicker;
