import { defineMessages } from 'react-intl';

export const scope = 'mango.container.credits';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	dueDate: {
		id: `${scope}.dueDate`,
		defaultMessage: 'due date',
	},
	issueDate: {
		id: `${scope}.issueDate`,
		defaultMessage: 'issue date',
	},
	netSum: {
		id: `${scope}.netSum`,
		defaultMessage: 'net sum',
	},
	taxSum: {
		id: `${scope}.taxSum`,
		defaultMessage: 'tax sum',
	},
	grossSum: {
		id: `${scope}.grossSum`,
		defaultMessage: 'gross sum',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	noData: {
		id: `${scope}.noData`,
		defaultMessage: 'no data',
	},
	header: {
		id: `${scope}.header`,
		defaultMessage: 'credits',
	},
	create: {
		id: `${scope}.create`,
		defaultMessage: 'create',
	},
});
