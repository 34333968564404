import React, { FC, useContext } from 'react';
import { Text, View } from 'react-native';

import { EUnit, MItem, priceFormatter } from 'mango-utils-client';
import unitMessages from '../../../../messages';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle } from '../../../../utilities/hooks/styles';
import { getTranslation } from '../../../../utilities/translations';
import MundoInput from '../../../elements/MundoInput';
import messages from '../../messages';
import { IItemEditProps } from '../../props';

export const StandardArticle: FC<IItemEditProps> = ({
	value: item,
	onChange,
}) => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);

	const update = (next: Partial<MItem>) => {
		if (!onChange) {
			return;
		}
		onChange(new MItem({ ...item, ...next }));
	};

	const { title, unit, short, price, discount, amount } = item;

	return (
		<View style={{ flexDirection: 'column' }}>
			<View style={[styles.leftMargin10, { maxWidth: 550 }]}>
				<Text numberOfLines={2} ellipsizeMode="tail">
					{short + ' ' + getTranslation(title, language)}
				</Text>
			</View>
			<View style={{ flexDirection: 'row' }}>
				<View style={styles.leftMargin10}>
					<MundoInput
						dataSet={{ cy: 'invoice.itemPrice.input' }}
						placeholder={messages.placeholderPrice}
						value={`${
							price > 0 ? priceFormatter(price / 1000) : ''
						}`}
						onChangeText={(text: string) => {
							// convert price from readable string to milli Euro (format for saving in database)
							update({ price: +text.replace(/,|\./g, '') * 10 });
						}}
						length={6}
						unit={messages.currency}
					/>
				</View>
				<View style={styles.leftMargin10}>
					<MundoInput
						dataSet={{ cy: 'invoice.itemdiscount.input' }}
						placeholder={messages.placeholderDiscount}
						value={`${
							discount > 0 ? priceFormatter(discount / 100) : ''
						}`}
						onChangeText={(text: string) => {
							update({
								discount: +text.replace(/,|\./g, ''),
							});
						}}
						length={6}
						unit={messages.percent}
					/>
				</View>
				<View style={styles.leftMargin10}>
					<MundoInput
						dataSet={{ cy: 'invoice.itemAmount.input' }}
						placeholder={messages.placeholderAmount}
						value={`${amount > 0 ? amount : ''}`}
						onChangeText={(text: string) => {
							if (!isNaN(+text)) {
								update({ amount: +text });
							}
						}}
						unit={unit !== EUnit.NONE ? unitMessages[unit] : ''}
						length={5}
					/>
				</View>
			</View>
		</View>
	);
};
