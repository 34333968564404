import React, { FC, useEffect, useMemo } from 'react';
import { ScrollView, Text, View } from 'react-native';

import { EModuleTypes, MOrder } from 'mango-utils-client';
import { useOrders } from '../../utilities/hooks/order/useOrders';
import { useStyle, useTheme } from '../../utilities/hooks/styles';
import {
	transformModuleIcon,
	transformModuleTitle,
} from '../../utilities/moduleTransformers';
import { useHistory } from '../../utilities/routing';
import { MundoIcon } from '../elements';
import MundoButton from '../elements/MundoButton';
import MundoText from '../elements/MundoText';
import LoadingIndicator from '../LoadingIndicator';
import OrderCard from '../OrderCard/';
import messages from './messages';
import { IOrderCardListProps } from './props';
import orderCardListStyles from './styles';

const OrderCardList: FC<IOrderCardListProps> = ({
	pipeline,
	module,
	creator,
	expanded,
	onChangeExpanded,
	cardExtension,
	permission,
}) => {
	const history = useHistory();
	const {
		orders,
		loadMore,
		onChangeFilter,
		loading,
		loadMoreActive,
	} = useOrders({
		skip: !!module.identifier,
		poll: 10000,
	});

	const styles = useStyle();
	const { theme } = useTheme();

	const ext = { cardExtension };
	const usable = useMemo(() => {
		return !!(module.type !== EModuleTypes.SELF_EXECUTING && permission);
	}, [module, permission]);
	useEffect(() => {
		const i = module.tags.findIndex((tag) => tag === 'reusable');

		onChangeFilter({
			currentState:
				module.identifier + (i >= 0 ? '::' + module.tags[i + 1] : ''),
			pipeline: pipeline._id,
		} as MOrder);
	}, [onChangeFilter, module, pipeline]);

	if (expanded) {
		return (
			<View style={orderCardListStyles(theme).container}>
				<View style={styles.dashList}>
					<View style={styles.buttonGroupNoPadding}>
						<MundoIcon icon={transformModuleIcon(module)} />
						<MundoText
							message={transformModuleTitle(module)}
							styles={orderCardListStyles(theme).headerTitle}
							bold
						/>
						<MundoButton
							dataSet={{
								cy: `dash.collapse.${module.identifier}.button`,
							}}
							onPress={onChangeExpanded}
							icon="minus"
							styles={orderCardListStyles(theme).laneButton}
						/>
					</View>
				</View>
				<ScrollView>
					<View style={styles.topMargin10}>
						{creator && permission && (
							<View>
								<MundoButton
									dataSet={{
										cy: 'dashboard.createOrder.button',
									}}
									styles={styles.dashboardAuftragButton}
									onPress={() =>
										history.push(
											`/module/${pipeline._id}/${module.identifier}/new`,
										)
									}
									icon={'plus'}
									title={messages.createNewOrder}
								/>
							</View>
						)}
						{orders.map((order) => {
							return (
								<OrderCard
									key={order.id}
									order={order}
									module={module}
									usable={usable}
								>
									<ext.cardExtension
										order={order}
										module={module}
										usable={usable}
									/>
								</OrderCard>
							);
						})}
						{!creator && loading && <LoadingIndicator />}
						{!creator && !loading && loadMoreActive && (
							<MundoButton
								title={messages.loadMore}
								onPress={loadMore}
								styles={[
									styles.formButtonStandardWidth,
									styles.topMargin10,
									{ alignSelf: 'center' },
								]}
							/>
						)}
					</View>
				</ScrollView>
			</View>
		);
	} else {
		return (
			<View style={orderCardListStyles(theme).container}>
				<View style={styles.dashListCollapse}>
					<MundoButton
						dataSet={{
							cy: `dash.uncollapse.${module.identifier}.button`,
						}}
						onPress={() => onChangeExpanded()}
						icon={transformModuleIcon(module)}
						styles={orderCardListStyles(theme).laneButton}
					/>
				</View>
				<ScrollView>
					{creator && (
						<View style={styles.topMargin6}>
							<MundoButton
								dataSet={{ cy: 'dashboard.createOrder.button' }}
								onPress={() =>
									history.push(
										`/module/${pipeline._id}/${module.identifier}/new`,
									)
								}
								icon={'plus'}
							/>
						</View>
					)}
					{orders.map((order) => (
						<View key={order._id} style={styles.topMargin6}>
							<MundoButton
								onPress={() => {
									if (usable) {
										history.push(
											`/module/${order.pipeline}/${
												order.currentState.split(
													'::',
												)[0]
											}/${order._id}`,
										);
									}
								}}
								disabled={!usable}
							>
								<Text>{order.id}</Text>
							</MundoButton>
						</View>
					))}
				</ScrollView>
			</View>
		);
	}
};

export default OrderCardList;
