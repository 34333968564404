import { defineMessages } from 'react-intl';

export const scope = 'mango.components.OrderCardList';

export default defineMessages({
	createNewOrder: {
		id: `${scope}.createNewOrder`,
		defaultMessage: 'create new order',
	},
	loadMore: {
		id: `${scope}.loadMore`,
		defaultMessage: 'load more',
	},
});
