import gql from 'graphql-tag';

export const GET_PACKAGES = gql`
	query getPacks {
		packages {
			_id
			id
			title {
				lang
				message
			}
		}
	}
`;
