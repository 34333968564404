import { encode } from 'base-64';
import { MTTankEntrance } from 'mango-utils-client';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import { MundoButton } from '../../../../../components/elements';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import { checkToken } from '../../../../../utilities/auth';
import { config } from '../../../../../utilities/config';

import { ITankEntranceViewProps } from '../props';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const TankEntranceViewer: FC<ITankEntranceViewProps> = ({
	tankentrance,
}) => {
	const [data, onChangeData] = useState<Uint8Array>();
	const [pageNumber /*, setPageNumber*/] = useState(1);
	const [accessToken, onChangeAccessToken] = useState('');

	const reducedTankEntrance = useMemo(() => {
		const emptyTank = new MTTankEntrance();
		const nextTank = new MTTankEntrance(tankentrance);
		Object.keys(nextTank).forEach((key) => {
			// @ts-ignore
			if ((nextTank[key] as any) === (emptyTank[key] as any)) {
				// @ts-ignore
				delete nextTank[key];
			}
		});
		return nextTank;
	}, [tankentrance]);

	useEffect(() => {
		onChangeAccessToken('');
	}, [tankentrance]);

	useEffect(() => {
		let isMounted = true;
		setTimeout(async () => {
			const { accessToken: t } = await checkToken();
			if (isMounted) {
				onChangeAccessToken(t);
			}
		}, 1);
		return () => {
			isMounted = false;
		};
	}, []);

	const loadSuccessHandler = useCallback(async (successEvent) => {
		// tslint:disable-next-line:await-promise
		onChangeData(await successEvent.getData());
	}, []);

	if (!accessToken) {
		return <LoadingIndicator />;
	}

	return (
		<View>
			<Document
				file={`${config.API_URL}/tankEntrancePDF/${encode(
					JSON.stringify(reducedTankEntrance),
				)}`}
				onLoadSuccess={loadSuccessHandler}
				onLoadError={(e: any) => {
					console.error(e);
				}}
				options={{
					httpHeaders: {
						Authorization: 'Bearer ' + accessToken,
					},
				}}
				loading={<LoadingIndicator />}
			>
				<Page pageNumber={pageNumber} />
			</Document>
			{data && (
				<MundoButton
					styles={{ position: 'absolute' }}
					icon={'download'}
					onPress={() => {
						const blob = new Blob([data], {
							type: 'application/pdf',
						});
						window.open(URL.createObjectURL(blob));
					}}
				/>
			)}
		</View>
	);
};
