import React, { FC, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';

import {
	EOrderListSettings,
	ESubState,
	EUserClassification,
	MOrder,
} from 'mango-utils-client';
import { MundoButton } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import MundoText from '../../../components/elements/MundoText';
import { confirm } from '../../../utilities/alert';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useOrders } from '../../../utilities/hooks/order/useOrders';
import useSettings from '../../../utilities/hooks/settings';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory } from '../../../utilities/routing';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import OrderFilter from '../Filter';
import PDFPicker from './components/pdfPicker';
import messages from './messages';
import { IOrderListProps } from './props';
import { numericDateOptions } from '../../../utilities/constants';

const OrderList: FC<IOrderListProps> = (props) => {
	const styles = useStyle();
	const intl = useIntl();
	const { settings } = useSettings();
	const { user, isAdmin } = useContext(AuthContext);
	const history = useHistory();

	const { orderBy, mode, changeSort } = useSort();

	const {
		orders,
		loading,
		loadMore,
		onChangeFilter,
		onChangeFrom,
		onChangeTo,
		loadMoreActive,
	} = useOrders({ orderBy, poll: props.poll });

	const selectSubstateLabel = (order: MOrder) => {
		switch (order.subState) {
			case ESubState.DONE:
				return messages.done;
			case ESubState.ONGOING:
				return messages.ongoing;
			case ESubState.READY:
				return messages.ready;
			default:
				return messages.undefined;
		}
	};

	const selectStateLabel = (order: MOrder) => {
		switch (order.currentState) {
			case 'done':
				return messages.done;
			case 'rescinded':
				return messages.rescinded;
			case 'canceled':
				return messages.canceled;
			default:
				return {
					id: `module.${order.currentState
						.split('::')[0]
						.split('.')
						.reverse()
						.join('.')}`,
					defaultMessage: order.currentState,
				};
		}
	};

	useEffect(() => {
		onChangeFilter({
			currentState: props.state,
			pipeline: props.pipeline,
		} as MOrder);
		if (props.from && props.to) {
			onChangeFrom(props.from);
			onChangeTo(props.to);
		}
	}, [onChangeFilter, props, onChangeFrom, onChangeTo]);

	const applyFilter = (next: MOrder, from: number, to: number) => {
		onChangeFilter(
			props.state
				? {
						...next,
						...{
							currentState: props.state,
							pipeline: props.pipeline as string,
						},
				  }
				: next,
		);
		onChangeFrom(from);
		onChangeTo(to);
	};

	useEffect(() => {
		if (props.calldown) {
			props.calldown(orders);
		}
	}, [props, orders]);

	return (
		<View>
			{!props.noHeader && (
				<View style={styles.headerView}>
					<View style={styles.headerTitleContainer}>
						<MundoText
							styles={styles.headerText}
							message={messages.title}
						/>
						<MundoButton
							icon={'question'}
							subtype="transparent"
							onPress={() =>
								openDocs('#/content/orders?id=liste')
							}
						/>
					</View>
				</View>
			)}
			<MundoTable
				filter={
					!props.noFilter && (
						<OrderFilter
							onChange={(order, fromDate, toDate) =>
								applyFilter(order, fromDate, toDate)
							}
						/>
					)
				}
				entrys={(props.tableConfig && props.tableConfig.length
					? props.tableConfig
					: settings.defaultOrderFields
				).map((key) => {
					switch (key) {
						case EOrderListSettings.ID:
							return {
								header: messages.id,
								size: 10,
								identifier: 'id',
							};
						case EOrderListSettings.STATE:
							return {
								header: messages.state,
								size: 20,
								identifier: 'currentState',
							};
						case EOrderListSettings.SUBSTATE:
							return {
								header: messages.substate,
								size: 15,
								identifier: 'substate',
							};
						case EOrderListSettings.PIPELINE:
							return {
								header: messages.pipeline,
								size: 15,
								identifier: 'pipeline',
							};
						case EOrderListSettings.CUSTOMER:
							return {
								header: messages.customer,
								size: 30,
								identifier: 'customer',
							};
						case EOrderListSettings.USE:
							return { size: 10, identifier: 'use' };
						case EOrderListSettings.OVERVIEW:
							return {
								size: isAdmin ? 4 : 0,
								identifier: 'overview',
							};
						case EOrderListSettings.PDF:
							return {
								header: messages.pdf,
								size: isAdmin ? 20 : 0,
								identifier: 'pdf',
							};
						case EOrderListSettings.DATE:
							return {
								header: messages.date,
								size: 18,
								identifier: 'date',
							};
						case EOrderListSettings.PDF_SENDER:
							return {
								size: isAdmin ? 6 : 0,
								identifier: 'pdfsend',
							};
						default:
							if (props.additionalTableHeader) {
								return props.additionalTableHeader(key);
							} else {
								return { identifier: 'malformed' };
							}
					}
				})}
				customHeaders={props.headers && props.headers}
				rows={orders
					.filter((order) => order.currentState)
					.map((order, index) => {
						return {
							key: order._id,
							values: (
								props.tableConfig || settings.defaultOrderFields
							).map((key) => {
								switch (key) {
									case EOrderListSettings.ID:
										return {
											identifier: 'id',
											content: (
												<MundoText>
													{order.id}
												</MundoText>
											),
										};
									case EOrderListSettings.STATE:
										return {
											identifier: 'currentState',
											content: (
												<MundoText
													message={selectStateLabel(
														order,
													)}
												/>
											),
										};
									case EOrderListSettings.SUBSTATE:
										return {
											identifier: 'substate',
											content: (
												<MundoText
													message={selectSubstateLabel(
														order,
													)}
												/>
											),
										};
									case EOrderListSettings.PIPELINE:
										const pipeForPipe = settings.pipelines.find(
											(p) => p._id === order.pipeline,
										);
										return {
											identifier: 'pipeline',
											content: (
												<MundoText>
													{pipeForPipe
														? pipeForPipe.short
														: ''}
												</MundoText>
											),
										};
									case EOrderListSettings.CUSTOMER:
										return {
											identifier: 'customer',
											content: (
												<MundoText>
													{order.customer.title}
												</MundoText>
											),
										};
									case EOrderListSettings.USE:
										const pipeForUse = settings.pipelines.find(
											(pipe) => {
												if (props.pipeline) {
													return (
														pipe._id ===
														props.pipeline
													);
												} else {
													return !!pipe.modules.find(
														(mod) =>
															mod.identifier ===
															order.currentState,
													);
												}
											},
										);
										const module = pipeForUse?.modules.find(
											(mod) =>
												mod.identifier ===
												order.currentState,
										);
										if (
											!(
												!isAdmin &&
												!(
													user.classification ===
													EUserClassification.TERMINAL
												) &&
												pipeForUse &&
												!module?.groups.find((g) =>
													user.groups.find(
														(ug) =>
															ug._id === g._id,
													),
												)
											) &&
											![
												'done',
												'canceled',
												'rescinded',
											].includes(order.currentState)
										) {
											return {
												identifier: 'use',
												content: (
													<MundoButton
														subtype="small"
														fontStyles={
															styles.smallButtonFontProps
														}
														styles={[
															styles.smallButtonLayoutProps,
														]}
														dataSet={{
															cy:
																'useOrder.' +
																order.customer
																	.title,
														}}
														onPress={async () => {
															if (
																order.subState ===
																ESubState.ONGOING
															) {
																await new Promise<void>(
																	(resolve) =>
																		confirm(
																			capitalizeFirstWord(
																				intl.formatMessage(
																					messages.occupied,
																				),
																			),
																			capitalizeFirstWord(
																				intl.formatMessage(
																					messages.confirm,
																				),
																			),
																			resolve,
																			'question',
																		),
																);
															}
															history.push(
																`/module/${
																	order.pipeline
																}/${
																	order.currentState.split(
																		'::',
																	)[0]
																}/${order._id}`,
															);
														}}
														title={messages.use}
													/>
												),
											};
										} else {
											return {
												identifier: 'use',
												content: undefined,
											};
										}
									case EOrderListSettings.OVERVIEW:
										if (!isAdmin) {
											return {
												identifier: 'overview',
												content: undefined,
											};
										}
										return {
											identifier: 'overview',
											content: (
												<MundoButton
													subtype="small"
													fontStyles={
														styles.smallButtonFontProps
													}
													styles={[
														styles.smallButtonLayoutProps,
														{
															alignSelf: 'center',
														},
													]}
													onPress={() =>
														history.push(
															`/order/${order._id}`,
														)
													}
													icon={'settings'}
												/>
											),
										};
									case EOrderListSettings.PDF:
										return {
											identifier: 'pdf',
											content: (
												<PDFPicker order={order} />
											),
										};
									case EOrderListSettings.DATE:
										return {
											identifier: 'date',
											content: (
												<MundoText>
													{new Date(
														order.date,
													).toLocaleDateString(
														'default',
														numericDateOptions,
													)}
												</MundoText>
											),
										};
									case EOrderListSettings.PDF_SENDER:
										if (!isAdmin) {
											return {
												identifier: 'pdfsend',
												content: undefined,
											};
										}
										return {
											identifier: 'pdfsend',
											content: (
												<MundoButton
													subtype="small"
													fontStyles={
														styles.smallButtonFontProps
													}
													styles={[
														styles.smallButtonLayoutProps,
														{ maxWidth: 40 },
													]}
													onPress={() =>
														history.push(
															'/sendpdf/' +
																order._id,
														)
													}
													icon={'send'}
												/>
											),
										};
									default:
										if (props.additionalTableContent) {
											return props.additionalTableContent(
												order,
												key,
											);
										}
										return {
											identifier: key,
											content: (
												<Text>Error Key not found</Text>
											),
										};
								}
							}),
							customFields: props.fields && (
								<props.fields order={order} index={index} />
							),
						};
					})}
				onChangeSort={changeSort}
				orderBy={orderBy}
				sortMode={mode}
				loadMore={loadMore}
				noLoadMore={props.noLoadMore}
				loading={loading}
				loadMoreActive={loadMoreActive}
			/>
		</View>
	);
};

export default OrderList;
