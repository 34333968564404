import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useTheme } from '../../utilities/hooks/styles';
import { ISignaturePadProps } from './props';
import { signStyles } from './styles';

const SignaturePad: FC<ISignaturePadProps> = ({
	onChange,
	style,
	height,
	width,
	value,
}) => {
	const [signatureRef, onChangeRef] = useState<ReactSignatureCanvas>();
	const { theme } = useTheme();
	// effect to fix height width missbehaviour
	useEffect(() => {
		if (signatureRef) {
			// @ts-ignore // TODO: figure out why this is required
			signatureRef.getCanvas().width = width;
			// @ts-ignore // TODO: figure out why this is required
			signatureRef.getCanvas().height = height;
		}
	}, [signatureRef, height, width]);
	// effect for controlled value
	useEffect(() => {
		if (signatureRef) {
			signatureRef.clear();
			signatureRef.fromDataURL(value || '', { width, height });
		}
	}, [value, signatureRef, height, width]);
	/**
	 * render
	 */
	return (
		<View
			style={[signStyles(theme).signaturePad, style, { height, width }]}
		>
			<ReactSignatureCanvas
				{...{ dataSet: { cy: 'sign.canvas' } }}
				penColor={'black'}
				ref={(ref) => {
					if (!signatureRef && ref) {
						onChangeRef(ref);
					}
				}}
				onEnd={() => {
					if (signatureRef) {
						onChange(signatureRef.toDataURL());
					}
				}}
			/>
		</View>
	);
};

export default SignaturePad;
