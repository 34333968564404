import React, { FC } from 'react';

import { MModuleDescription, MPipeline } from 'mango-utils-client';
import { TextInput, View } from 'react-native';
import { MundoButton, MundoText } from '../../../components/elements';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import { alert } from '../../../utilities/alert';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { IPipelineProps } from '../props';
import settingStyles, { pipelineEditStyles } from '../styles';
import { ModuleSorter, ModuleTimeLine, PipelineContext } from './';
import messages from './messages';

export const PipelineEditContext = React.createContext({
	moduleList: [] as MModuleDescription[],
	setModuleList: (modules: MModuleDescription[]) => {
		console.log(modules);
		alert('error', 'set module list is undefined', 'error');
	},
	setMissing: (missing: string) => {
		console.log(missing);
		alert('error', 'set dependencies is undefined', 'error');
	},
});

export const PipelineEdit: FC<IPipelineProps> = (props) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { settings, onChangeSettings } = React.useContext(SettingsContext);
	const { setEditMode } = React.useContext(PipelineContext);

	const [pipelineName, setPipelineName] = React.useState(
		props.pipeline.title,
	);
	const [short, setShort] = React.useState(props.pipeline.short);
	const [moduleList, setModuleList] = React.useState(
		props.pipeline.modules as MModuleDescription[],
	);
	const [missing, setMissing] = React.useState('');

	const savePressHandler = () => {
		const newPipeline: MPipeline = new MPipeline({
			title: pipelineName,
			modules: moduleList,
			short: `${short}`,
			_id: props.pipeline._id,
		});
		const newList = [...settings.pipelines];
		newList[props.index] = newPipeline;
		onChangeSettings({ ...settings, pipelines: newList });
		setEditMode(false);
	};

	const cancelPressHandler = () => {
		setEditMode(false);
	};

	return (
		<PipelineEditContext.Provider
			value={{ moduleList, setMissing, setModuleList }}
		>
			<View style={pipelineEditStyles(theme).pipelineEditContainer}>
				<View
					style={
						pipelineEditStyles(theme).pipelineEditContainerFields
					}
				>
					<View>
						<MundoText message={messages.title} />
						<TextInput
							{...{ dataSet: { cy: 'pipeline.title.input' } }}
							value={pipelineName}
							style={[
								styles.input,
								pipelineEditStyles(theme).titleInput,
							]}
							onChangeText={(text) => setPipelineName(text)}
						/>
					</View>
					<View style={styles.leftMargin20}>
						<MundoText message={messages.short} />
						<TextInput
							{...{ dataSet: { cy: 'pipeline.short.input' } }}
							value={short}
							style={[
								styles.input,
								pipelineEditStyles(theme).titleInput,
							]}
							onChangeText={(text) => {
								if (text.length <= 3) {
									setShort(text);
								}
							}}
						/>
					</View>
				</View>
				<ModuleTimeLine index={props.index} pipeline={props.pipeline} />
				<ModuleSorter />
				<View style={pipelineEditStyles(theme).pipelineEditSaveButton}>
					<FinishButtonGroup
						cyId={'pipeline'}
						saveFunction={() => savePressHandler()}
						saveDisabled={!!missing || !settings}
						saveDisabledReason={missing}
						cancelFunction={() => cancelPressHandler()}
					/>
					{moduleList.length > 0 && (
						<MundoButton
							styles={[
								settingStyles(theme).button,
								styles.alignRightInHorizontalFlex,
								styles.deleteButton,
							]}
							title={messages.clear}
							onPress={() => setModuleList([])}
						/>
					)}
				</View>
			</View>
		</PipelineEditContext.Provider>
	);
};
