import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { useQuery } from '@apollo/client';
import { dateFormatter, MInvoice } from 'mango-utils-client';
import { useMediaQuery } from 'react-responsive';
import { MundoButton, MundoText } from '../../../components/elements';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { useStyle } from '../../../utilities/hooks/styles';
import { useParams } from '../../../utilities/routing';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';
import { GET_ACHIVED_INVOICE } from './queries';

const ArchivOverview: FC = () => {
	const history = useHistory();
	const styles = useStyle();
	const { id } = useParams<{ id: string }>();

	const [invoice, onChangeInvoice] = useState<MInvoice>(new MInvoice());

	const { loading: loadingInvoice } = useQuery(GET_ACHIVED_INVOICE, {
		variables: { id },
		onCompleted: (response) => {
			if (response && response.getArchivedInvoice) {
				onChangeInvoice(response.getArchivedInvoice);
			}
		},
	});

	const isTablet = useMediaQuery({ maxDeviceWidth: 1024 });

	const getColumnWidth = (numChars: number) => numChars * 10;

	const headerStyle = [
		styles.wideGroupTableColumnHeader,
		{
			paddingTop: 15,
			paddingRight: 10,
		},
	];

	const cellStyle = [
		styles.generalCellStyle,
		styles.extendedRowHeight,
		{
			paddingLeft: 10,
			paddingRight: 10,
			flexGrow: 1,
		},
	];
	if (loadingInvoice) {
		return <LoadingIndicator />;
	}
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.header}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => console.log('TODO')}
					/>
				</View>
				{!!invoice.id && (
					<MundoText styles={styles.orderIdHeader}>
						{invoice.id}
					</MundoText>
				)}
			</View>
			<ScrollView style={styles.fullWidth}>
				<View style={styles.spacedContainer}>
					<View>
						<View
							style={styles.containerFullWidthHorizontalNoPadding}
						>
							<MundoText
								message={messages.orderId}
								styles={styles.boldText}
							/>
							<MundoText>{`: ${invoice.orderId}`}</MundoText>
						</View>
						<View style={styles.topMargin20}>
							<MundoText
								styles={styles.boldText}
								message={messages.customerInformation}
							/>
						</View>
						<View
							style={[
								styles.containerFullWidthHorizontalNoPadding,
								styles.topMargin10,
								styles.bottomMargin20,
							]}
						>
							<View
								style={[
									styles.topMargin6,
									styles.bottomMargin6,
								]}
							>
								<MundoText>{invoice.customer.title}</MundoText>
								<MundoText>
									{invoice.customer.subTitle}
								</MundoText>
								<MundoText>
									{invoice.customer.firstName}
								</MundoText>
								<MundoText>
									{invoice.customer.lastName}
								</MundoText>

								<MundoText>{invoice.customer.phone}</MundoText>
								<MundoText>{invoice.customer.fax}</MundoText>
								<MundoText>{invoice.customer.mail}</MundoText>
								<MundoText>
									{invoice.customer.homepage}
								</MundoText>

								<View
									style={
										styles.containerFullWidthHorizontalNoPadding
									}
								>
									<View>
										<View
											style={[
												styles.containerFullPercentageElevatedNoPadding,
												styles.topMargin20,
											]}
										>
											<MundoText
												styles={styles.boldText}
												message={messages.address}
											/>
										</View>
										<MundoText>
											{invoice.customer.address.street}
										</MundoText>
										<MundoText>
											{invoice.customer.address.city}
										</MundoText>
										<MundoText>
											{invoice.customer.address.country}
										</MundoText>
									</View>
									<View style={styles.leftMargin30}>
										<View
											style={[
												styles.containerFullPercentageElevatedNoPadding,
												styles.topMargin20,
											]}
										>
											<MundoText
												styles={styles.boldText}
												message={
													messages.billingAddress
												}
											/>
										</View>
										<MundoText>
											{invoice.billingAddress.street}
										</MundoText>
										<MundoText>
											{invoice.billingAddress.city}
										</MundoText>
										<MundoText>
											{invoice.billingAddress.country}
										</MundoText>
									</View>
								</View>
							</View>

							<View style={styles.leftMargin50}>
								<View>
									<View
										style={[
											styles.topMargin6,
											styles.bottomMargin6,
										]}
									>
										<MundoText
											message={messages.issueDate}
											styles={styles.boldText}
										/>
										<MundoText>
											{dateFormatter(
												new Date(invoice.issueDate),
											)}
										</MundoText>
									</View>
									<View
										style={[
											styles.topMargin6,
											styles.bottomMargin6,
										]}
									>
										<MundoText
											message={messages.dueDate}
											styles={styles.boldText}
										/>
										<MundoText>
											{dateFormatter(
												new Date(invoice.dueDate),
											)}
										</MundoText>
									</View>
								</View>
								<View
									style={[
										styles.topMargin6,
										styles.bottomMargin6,
									]}
								>
									<MundoText
										message={messages.paymentMethod}
										styles={styles.boldText}
									/>
									<MundoText>
										{invoice.paymentMethod.title}
									</MundoText>
								</View>
							</View>
						</View>

						<View>
							<View style={styles.bottomMargin10}>
								<MundoText
									message={messages.positions}
									styles={styles.boldText}
								/>
							</View>
							<View
								style={[
									styles.wideGroupTable,
									styles.wideGroupTableColumnHeader,
								]}
							>
								<View
									style={
										isTablet
											? [
													styles.wideGroupTableColumn8,
													styles.wideGroupTableColumnHeader,
											  ]
											: [
													headerStyle,
													{
														width: getColumnWidth(
															8,
														),
														flexGrow: 0,
													},
											  ]
									}
								>
									<MundoText message={messages.short} />
								</View>
								<View
									style={
										isTablet
											? [
													styles.wideGroupTableColumn20,
													styles.wideGroupTableColumnHeader,
											  ]
											: [
													headerStyle,
													{
														width: getColumnWidth(
															30,
														),
														flexGrow: 1,
													},
											  ]
									}
								>
									<MundoText message={messages.title} />
								</View>
								<View
									style={
										isTablet
											? [
													styles.wideGroupTableColumn8,
													styles.wideGroupTableColumnHeader,
											  ]
											: [
													headerStyle,
													{
														width: getColumnWidth(
															8,
														),
														flexGrow: 0,
													},
											  ]
									}
								>
									<MundoText message={messages.amount} />
								</View>
								<View
									style={
										isTablet
											? [
													styles.wideGroupTableColumn8,
													styles.wideGroupTableColumnHeader,
											  ]
											: [
													headerStyle,
													{
														width: getColumnWidth(
															8,
														),
														flexGrow: 0,
													},
											  ]
									}
								>
									<MundoText message={messages.unit} />
								</View>
								<View
									style={
										isTablet
											? [
													styles.wideGroupTableColumn8,
													styles.wideGroupTableColumnHeader,
											  ]
											: [
													headerStyle,
													{
														width: getColumnWidth(
															8,
														),
														flexGrow: 0,
													},
											  ]
									}
								>
									<MundoText message={messages.price} />
								</View>
							</View>
							{invoice.positions.length > 0 &&
								invoice.positions.map((item) => (
									<View
										key={`archiv.overview.item.${item._id}`}
										style={[
											styles.wideGroupTable,
											styles.tableRowContainer,
										]}
									>
										<View
											style={
												isTablet
													? [
															styles.wideGroupTableColumn8,
															styles.extendedRowHeight,
													  ]
													: [
															cellStyle,
															{
																width: getColumnWidth(
																	8,
																),
																flexGrow: 0,
															},
													  ]
											}
										>
											<MundoText>{item.short}</MundoText>
										</View>
										<View
											style={
												isTablet
													? [
															styles.wideGroupTableColumn20,
															styles.extendedRowHeight,
													  ]
													: [
															cellStyle,
															{
																width: getColumnWidth(
																	30,
																),
																flexGrow: 1,
															},
													  ]
											}
										>
											<MundoText>{item.title}</MundoText>
										</View>
										<View
											style={
												isTablet
													? [
															styles.wideGroupTableColumn8,
															styles.extendedRowHeight,
													  ]
													: [
															cellStyle,
															{
																width: getColumnWidth(
																	8,
																),
																flexGrow: 0,
															},
													  ]
											}
										>
											<MundoText>{item.amount}</MundoText>
										</View>
										<View
											style={
												isTablet
													? [
															styles.wideGroupTableColumn8,
															styles.extendedRowHeight,
													  ]
													: [
															cellStyle,
															{
																width: getColumnWidth(
																	8,
																),
																flexGrow: 0,
															},
													  ]
											}
										>
											<MundoText>{item.unit}</MundoText>
										</View>
										<View
											style={
												isTablet
													? [
															styles.wideGroupTableColumn8,
															styles.extendedRowHeight,
													  ]
													: [
															cellStyle,
															{
																width: getColumnWidth(
																	8,
																),
																flexGrow: 0,
															},
													  ]
											}
										>
											<MundoText>{item.price}</MundoText>
										</View>
									</View>
								))}
						</View>
						<View
							style={[
								styles.containerFullWidthHorizontalNoPadding,
								styles.topMargin20,
							]}
						>
							<View style={styles.paddedViewMinWidth}>
								<MundoText
									message={messages.netSum}
									styles={styles.boldText}
								/>
								<MundoText>{invoice.netSum}</MundoText>
							</View>
							<View style={styles.paddedViewMinWidth}>
								<MundoText
									message={messages.taxSum}
									styles={styles.boldText}
								/>
								<MundoText>{invoice.taxSum}</MundoText>
							</View>
							<View style={styles.paddedViewMinWidth}>
								<MundoText
									message={messages.discountSum}
									styles={styles.boldText}
								/>
								<MundoText>{invoice.discountSum}</MundoText>
							</View>
							<View style={styles.paddedViewMinWidth}>
								<MundoText
									message={messages.grossSum}
									styles={styles.boldText}
								/>
								<MundoText>{invoice.grossSum}</MundoText>
							</View>
						</View>
					</View>
				</View>
				<FinishButtonGroup cancelFunction={history.goBack} />
			</ScrollView>
		</View>
	);
};

export default ArchivOverview;
