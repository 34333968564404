import React, { FC, useContext } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';

import { EUserClassification, MCustomer } from 'mango-utils-client';
import icons from '../../../assets';
import { MundoButton } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import MundoText from '../../../components/elements/MundoText';
import { useCustomers } from '../../../utilities/hooks/customer';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory } from '../../../utilities/routing';
import CustomerFilter from '../filter';
import messages from './messages';
import { ICustomerListProps } from './props';
import { AuthContext } from '../../../utilities/hooks/auth';

const CustomerList: FC<ICustomerListProps> = (props) => {
	const styles = useStyle();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const { orderBy, mode, changeSort } = useSort();

	const {
		customers: serverData,
		loading,
		loadMore,
		onChangeFilter,
		loadMoreActive,
	} = useCustomers({ skip: !!props.data, orderBy });
	const customers: MCustomer[] = props.data || serverData;

	return (
		<View style={!props.sub && styles.containerFullResolution}>
			<View
				style={
					props.sub ? styles.headerViewSecondary : styles.headerView
				}
			>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.customers}
						styles={
							props.sub
								? styles.headerSecondaryText
								: styles.headerText
						}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/equipmentmanager?id=firmen',
							)
						}
					/>
				</View>
				{!props.cantCreate && (
					<MundoButton
						dataSet={{ cy: 'company.create' }}
						icon={'plus'}
						onPress={() => history.push('/edit/customer/new')}
						disabled={
							user?.classification !==
								EUserClassification.ADMIN &&
							user?.classification !== EUserClassification.MEMBER
						}
					/>
				)}
			</View>
			<ScrollView>
				<View style={!props.sub && styles.spacedContainer}>
					<MundoTable
						filter={
							!props.noFilter && (
								<View
									style={[
										styles.topMargin10,
										styles.bottomMargin10,
									]}
								>
									<CustomerFilter onChange={onChangeFilter} />
								</View>
							)
						}
						entrys={[
							{ identifier: 'id', header: messages.id, size: 30 },
							{
								identifier: 'title',
								header: messages.title,
								size: 30,
							},
							{ identifier: 'placeholder', size: 20 },
							{ identifier: 'warn', size: 16 },
							{ identifier: 'arrow', size: 4 },
						]}
						rows={customers.map((customer) => {
							return {
								key: customer._id,
								onPress: () =>
									history.push(
										'/edit/customer/' + customer._id,
									),
								values: [
									{
										identifier: 'id',
										content: <Text>{customer.id}</Text>,
									},
									{
										identifier: 'title',
										content: <Text>{customer.title}</Text>,
									},
									{
										identifier: 'placeholder',
										content: <View></View>,
									},
									{
										identifier: 'warn',
										content:
											!customer.title ||
											// !customer.phone ||
											// !customer.mail ||
											// !customer.taxNr ||
											!customer.ustID ? (
												<View
													style={
														styles.wideGroupTableColumn6
													}
												>
													<MundoText
														styles={[
															{
																color:
																	'#ff8000',
															},
														]}
														message={
															messages.missingValue
														}
													/>
												</View>
											) : (
												<View
													style={
														styles.wideGroupTableColumn6
													}
												/>
											),
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								dataSet: { cy: customer.title + '.row' },
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
};

export default CustomerList;
