import gql from 'graphql-tag';

export const GET_SETTINGS = gql`
	query getSettings {
		settings {
			pipelines {
				id
				_id
				title
				short
				modules {
					title
					identifier
					dependsOn
					type
					tags
					groups {
						id
						_id
						title
						inheritance
					}
				}
				tags
			}
			tags
			logoURL
			defaultOrderFields
			host {
				title
				id
				_id
			}
			mail
			archivDuration
			archivInterval
			doxis
			doxisMail
			countries
		}
	}
`;

export const SAVE_SETTINGS = gql`
	mutation saveSettings($settings: SettingsInput!) {
		saveSettings(settings: $settings) {
			pipelines {
				_id
				id
				title
				short
				modules {
					title
					identifier
					dependsOn
					type
					tags
					groups {
						id
						_id
						title
						inheritance
					}
				}
				tags
			}
			tags
			logoURL
			defaultOrderFields
			host {
				title
				id
				_id
			}
			mail
			archivDuration
			archivInterval
			doxis
			doxisMail
			countries
		}
	}
`;
