import { useState } from 'react';
import useDebounce from '.';

export function useDState<t>(options?: {
	initialValue?: t;
	delay?: number;
}): [
	t | undefined,
	t | undefined,
	React.Dispatch<React.SetStateAction<t | undefined>>,
] {
	const [state, onChangeState] = useState<t | undefined>(
		options?.initialValue,
	);

	const debouncedState = useDebounce(state, options?.delay || undefined);

	return [state, debouncedState, onChangeState];
}
