import { defineMessages } from 'react-intl';

export const scope = 'mango.utilities.apollo';

export default defineMessages({
	connection: {
		id: `${scope}.connection`,
		defaultMessage: 'no connection to server',
	},
	unnokwn: {
		id: `${scope}.unnokwn`,
		defaultMessage: 'unkown network error occurred',
	},
});
