import React, { FC, useContext, useEffect, useState } from 'react';
import { Image, ImageSourcePropType, ScrollView, View } from 'react-native';

import { SettingsContext } from '../../utilities/hooks/settings';
import OrderList from '../Order/List';
import { useStyle } from '../../utilities/hooks/styles';
import icons from '../../assets';
import sidebarStyles from '../../components/Sidebar/styles';
import theme from '../../theme/light';
import { MundoButton, MundoText } from '../../components/elements';
import messages from './messages';
import { PipelineDisplay } from './components/PipelineDisplay';
import { openDocs } from '../../utilities/openDocs';

const DEFAULT_LOGO_HEIGHT = 64;

export const Overview: FC = () => {
	const { settings } = useContext(SettingsContext);
	const styles = useStyle();
	const [logoWidth, setLogoWidth] = useState(DEFAULT_LOGO_HEIGHT);
	const [logoHeight, setLogoHeight] = useState(DEFAULT_LOGO_HEIGHT);

	useEffect(() => {
		Image.getSize(
			settings.logoURL,
			(width, height) => {
				setLogoHeight(DEFAULT_LOGO_HEIGHT);
				setLogoWidth((width * DEFAULT_LOGO_HEIGHT) / height);
			},
			() => {
				// log error
			},
		);
	}, [settings.logoURL]);

	return (
		<View style={styles.fullSize}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => openDocs()}
					/>
				</View>
			</View>
			<View style={styles.spacedContainer}>
				<View style={[styles.containerFullWidthHorizontalCentered]}>
					<Image
						source={
							(settings.logoURL ||
								icons.logoSmall) as ImageSourcePropType
						}
						resizeMode="contain"
						style={
							settings.logoURL !== ''
								? {
										width: logoWidth,
										height: logoHeight,
								  }
								: sidebarStyles(theme).logoImgDefault
						}
					/>
				</View>
				<View style={[styles.containerFullWidthHorizontalCentered]}>
					{settings.pipelines.map((pipeline) => {
						return (
							<PipelineDisplay
								key={pipeline._id}
								pipeline={pipeline}
							/>
						);
					})}
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList
						noFilter
						noHeader
						noLoadMore
						from={Date.now()}
						to={0}
						poll={10000}
					/>
				</View>
			</ScrollView>
		</View>
	);
};
