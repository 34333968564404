import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.chart';

export default defineMessages({
	tooManyBars: {
		id: `${scope}.tooManyBars`,
		defaultMessage: 'too many bars selected',
	},
	nothingToDisplay: {
		id: `${scope}.nothingToDisplay`,
		defaultMessage: 'nothing to display',
	},
});
