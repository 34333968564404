import gql from 'graphql-tag';

export const GET_ORDER = gql`
	query getOrder($id: String!) {
		order(id: $id) {
			id
			currentState
			tags
			pipeline
			customer {
				title
				address {
					street
					city
					country
					zipCode
				}
				billingAddress {
					street
					city
					country
					zipCode
				}
			}
		}
	}
`;

export const GET_STATES = gql`
	query getStates($id: String!) {
		statesByOrderId(orderId: $id) {
			title
			subState
			date
			items {
				id
				_id
				articleId
				source
				amount
				checked
				data
				comment
				unit
				title {
					message
					lang
				}
			}
			packages {
				id
				_id
				title {
					message
					lang
				}
				description {
					message
					lang
				}
				price
			}
			tags
			user {
				id
				_id
				title
				classification
			}
		}
	}
`;

export const SAVE_STATES = gql`
	mutation saveStates($id: String!, $states: [StateInput!]!) {
		saveStates(orderId: $id, states: $states) {
			id
		}
	}
`;
// TODO: MOVE
export const GET_TSTATES = gql`
	query getStates($id: String!) {
		tstatesByOrderId(orderId: $id) {
			title
			subState
			date
			classification
			items {
				id
				_id
				articleId
				source
				amount
				checked
				highlighted
				data
				comment
				unit
				title {
					message
					lang
				}
				chamber
				lid
				purpose
			}
			packages {
				id
				_id
				title {
					message
					lang
				}
				description {
					message
					lang
				}
				price
			}
			tags
			user {
				id
				_id
				title
				classification
			}
		}
	}
`;
// TODO: MOVE
export const SAVE_TSTATES = gql`
	mutation saveTStates($id: String!, $states: [TStateInput!]!) {
		saveTStates(orderId: $id, states: $states) {
			id
		}
	}
`;

export const RESET_ORDER_TO = gql`
	mutation($id: String!, $state: String!) {
		resetOrderToState(orderId: $id, state: $state) {
			id
		}
	}
`;

export const EXPOSE_ORDER_TO_TERMINAL = gql`
	mutation($id: String!) {
		exposeOrderToTerminal(orderId: $id)
	}
`;

export const CANCEL_ORDER = gql`
	mutation($id: String!) {
		cancelOrder(orderId: $id) {
			id
		}
	}
`;
