import { defineMessages } from 'react-intl';

export const scope = 'mango.container.DasboradTerminal';

export default defineMessages({
	logOut: {
		id: `${scope}.logOut`,
		defaultMessage: 'log out',
	},
	sign: {
		id: `${scope}.sign`,
		defaultMessage: 'sign',
	},
	dashboardTerminalHeader: {
		id: `${scope}.dashboardTerminalHeader`,
		defaultMessage: 'terminal',
	},
	selectLanguage: {
		id: `${scope}.selectLanguage`,
		defaultMessage:
			'please select the language in which you want to input the order.',
	},
	signButton: {
		id: `${scope}.signButton`,
		defaultMessage: 'sign the completed order documents',
	},
});
