import { ESubState } from 'mango-utils-client';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Text, TouchableOpacity, View } from 'react-native';

import { confirm } from '../../utilities/alert';
import { numericDateOptions } from '../../utilities/constants';
import { useStyle, useTheme } from '../../utilities/hooks/styles';
import { useHistory } from '../../utilities/routing';
import { capitalizeFirstWord } from '../../utilities/stringFunctions';
import { MundoButton, MundoIcon, MundoText } from '../elements';
import messages from './messages';
import { IOrderCardProps } from './props';
import orderCardStyles from './styles';

const OrderCard: FC<IOrderCardProps> = ({ order, children, usable }) => {
	const styles = useStyle();
	const { theme } = useTheme();
	const history = useHistory();
	const intl = useIntl();

	return (
		<View
			style={[
				styles.cardDashboard,
				!usable && { backgroundColor: 'gray' },
			]}
		>
			<View
				style={[
					orderCardStyles(theme, order.subState === ESubState.ONGOING)
						.container,
				]}
			>
				<TouchableOpacity
					{...{ dataSet: `dash.${order.currentState}.module` }}
					style={[orderCardStyles(theme).orderData]}
					onPress={async () => {
						if (!usable) {
							return;
						}
						if (order.subState === ESubState.ONGOING) {
							await new Promise<void>((resolve) =>
								confirm(
									capitalizeFirstWord(
										intl.formatMessage(messages.occupied),
									),
									capitalizeFirstWord(
										intl.formatMessage(messages.confirm),
									),
									resolve,
									'question',
								),
							);
						}
						history.push(
							`/module/${order.pipeline}/${
								order.currentState.split('::')[0]
							}/${order._id}`,
						);
					}}
				>
					<MundoText message={messages.id}>: {order.id}</MundoText>
					<MundoText message={messages.date}>
						:{' '}
						{new Date(order.date).toLocaleDateString(
							'default',
							numericDateOptions,
						)}
					</MundoText>
					{/* <MundoText>{order.subState}</MundoText> */}
					<View
						style={[
							styles.thinSeparator,
							styles.topMargin10,
							styles.bottomMargin10,
							{
								backgroundColor: theme.backgroundSecondaryColor,
							},
						]}
					/>
					<View style={styles.orderCard}>
						<View style={styles.containerHorizontal}>
							<MundoIcon
								fontSize={14}
								styles={styles.dashboardIcon}
								icon="cardCustomer"
							/>
							<View style={{ maxWidth: 170 }}>
								<Text
									numberOfLines={3}
									ellipsizeMode="tail"
									style={{
										fontFamily: 'Roboto',
										fontWeight: 'normal',
										color: theme.textMainColor,
									}}
								>
									{order.customer.title}
								</Text>
							</View>
						</View>
						{!!order.customer.defaultPaymentMethod.title && (
							<View style={styles.containerHorizontal}>
								<MundoIcon
									fontSize={14}
									styles={styles.dashboardIcon}
									icon="cardCreditCard"
								/>
								<MundoText
									styles={{
										color:
											order.customer.defaultPaymentMethod
												.color,
									}}
								>
									{order.customer.defaultPaymentMethod.title}
								</MundoText>
							</View>
						)}
						{children}
					</View>
				</TouchableOpacity>
				<View style={orderCardStyles(theme).orderButtonContainer}>
					<MundoButton
						dataSet={{ cy: `dash.${order.currentState}.settings` }}
						styles={orderCardStyles(theme).orderButton}
						icon={'settings'}
						onPress={() => history.push(`/order/${order._id}`)}
					/>
				</View>
			</View>
		</View>
	);
};

export default OrderCard;
