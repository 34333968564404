import { defineMessages } from 'react-intl';

export const scope = 'mango.customer.picker';

export default defineMessages({
	customerName: {
		id: `${scope}.customerName`,
		defaultMessage: 'name',
	},
	selectCustomer: {
		id: `${scope}.selectCustomer`,
		defaultMessage: 'select customer',
	},
	customerError: {
		id: `${scope}.customerError`,
		defaultMessage: 'the customer is not set or is invalid',
	},
});
