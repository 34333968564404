import React, { FC, useContext, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { ECountries, MSettings } from 'mango-utils-client';
import CountryPicker from '../../../components/CountryPicker';
import { MundoButton, MundoText } from '../../../components/elements';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import messages from './messages';

export const CountryConfig: FC = () => {
	const { settings, onChangeSettings } = useContext(SettingsContext);
	const [countries, onChangeCountries] = useState<ECountries[]>(
		settings.countries,
	);
	const styles = useStyle();

	useEffect(() => {
		onChangeCountries(settings.countries);
	}, [settings]);

	const handleChange = (change: Partial<MSettings>) => {
		const next = new MSettings({ ...settings, ...change });
		onChangeSettings(next);
	};

	return (
		<View>
			<View style={styles.headerTitleContainer}>
				<MundoText
					message={messages.countryConfigHeader}
					styles={styles.headerSecondaryText}
				/>
			</View>
			<View style={styles.formContainer}>
				<View style={styles.topMargin20}>
					<CountryPicker
						value={undefined}
						onChange={(next) => {
							const i = countries.indexOf(next);
							if (next && i < 0) {
								onChangeCountries([...countries, next]);
							} else {
								countries.splice(i, 1);
								onChangeCountries([...countries, next]);
							}
						}}
					/>
				</View>
			</View>
			<View style={{ maxHeight: 200 }}>
				<ScrollView>
					{countries.map((c) => (
						<MundoText
							key={c}
							message={{
								id: Object.keys(ECountries).find(
									(country) =>
										// @ts-ignore
										ECountries[country] === c,
								),
							}}
						/>
					))}
				</ScrollView>
			</View>
			<View style={styles.horizontalLayout}>
				<MundoButton
					title={messages.save}
					onPress={() => {
						handleChange({ countries });
					}}
				/>
			</View>
		</View>
	);
};
