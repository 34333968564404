import {
	MInvoice,
	MItem,
	MModuleInvoiceIn,
	MPackage,
	MState,
} from 'mango-utils-client';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
/**
 * Filter items without price
 * @param items input items
 */
function filterItems(items: MItem[]): MItem[] {
	return items.filter((item) => item.price);
}
/**
 * Compress/merge items with same article id to one by adding the amount
 * @param recordingState input state with items
 */
function compressItems(items: MItem[]): MItem[] {
	if (items.length) {
		const groupedItems = items
			.map((item) => new MItem(item))
			.reduce(
				(h: any, obj) =>
					Object.assign(h, {
						[obj.articleId]: (h[obj.articleId] || []).concat(obj),
					}),
				{},
			);
		const mergedItems = Object.entries(groupedItems)
			.map((item) => item[1])
			.map((item: any) =>
				item.reduce(
					(itemA: MItem, itemB: MItem) =>
						new MItem({
							...itemA,
							amount: itemA.amount + itemB.amount,
						}),
				),
			);
		return mergedItems;
	}
	return [];
}
/**
 * Passing the record state to the invoice module states
 * @param response response from the db
 * @param dispatchAvailablePackageAction dispatch method for packages
 * @param dispatchItemAction dispatch method for items
 * @param onChangeRecordState change record state method
 * @param onChangeNumId change num id method
 */
export function handleRecordState(
	response: any,
	dispatchAvailablePackageAction: React.Dispatch<{
		type: ETypes;
		location?: number | undefined;
		item: MPackage | MPackage[];
	}>,
	dispatchItemAction: React.Dispatch<{
		type: ETypes;
		item: MItem[];
	}>,
	onChangeRecordState: React.Dispatch<
		React.SetStateAction<MState | undefined>
	>,
	onChangeNumId: React.Dispatch<React.SetStateAction<number | undefined>>,
	onChangePrev: React.Dispatch<React.SetStateAction<MInvoice | undefined>>,
) {
	if (response && response.moduleInvoiceIn) {
		const input = new MModuleInvoiceIn(response.moduleInvoiceIn);
		// load recording state
		const recordingState = new MState({
			items: input.items,
			packages: input.packages,
			company: input.customer,
			tags: input.tags,
		});
		onChangeRecordState(recordingState);

		// passing the chosen packages from record state to invoice packages
		dispatchAvailablePackageAction({
			type: ETypes.RESET_TO,
			item: recordingState.packages,
		});
		onChangeNumId(input.id);
		// compress record items
		const mergedItems = filterItems(
			compressItems(recordingState.items as MItem[]),
		);
		if (input.previous && input.previous._id) {
			const prev = new MInvoice(input.previous);
			onChangePrev(prev);
			console.log(prev.paymentMethod.surcharge);
			dispatchItemAction({
				type: ETypes.RESET_TO,
				item: prev.positions.map((p) => {
					p.price =
						(p.price / (100 + prev.paymentMethod.surcharge / 100)) *
						100;
					return p;
				}),
			});
		} else if (recordingState.items) {
			dispatchItemAction({
				type: ETypes.RESET_TO,
				item: mergedItems,
			});
		}
	}
}
