import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
	query($any: ArticleInput!, $count: Int!) {
		articles(article: $any, count: $count, isTypeahead: true) {
			_id
			id
			title {
				lang
				message
			}
			short
			unit
		}
	}
`;
