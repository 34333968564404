import React, { FC } from 'react';
import { View } from 'react-native';

import MundoInput from '../../../../components/elements/MundoInput';
import messages from '../messages';
import { IArticleEditFieldProps } from '../props';

export const TaxField: FC<IArticleEditFieldProps> = ({ value, update }) => {
	return (
		<View>
			<MundoInput
				dataSet={{ cy: 'article.tax.input' }}
				label={messages.tax}
				onChangeText={(tax) => update({ tax: +tax })}
				value={`${value.tax}`}
				unit={'%'}
				type={'numeric'}
				length={5}
			/>
		</View>
	);
};
