import { useLazyQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import {
	Alpha,
	dateFormatter,
	day,
	SData,
	SStat,
	week,
} from 'mango-utils-client';
import { MundoText } from '../../../../components/elements';
import { destructData } from '../../../../utilities/dataFunctions';
import { useStyle } from '../../../../utilities/hooks/styles';
import { Chart } from '../../components/Chart/Chart';
import { DataTable } from '../../components/DataTable/DataTable';
import { FilterSection } from '../../components/FilterSection/FilterSection';
import TimeConfigurator from '../../components/TimeConfigurator';
import { formatTimeTick } from '../../utilities/times';
import { IExportableStatistic } from '../Dashboard/props';
import { profitExport } from './functions/export';
import messages from './messages';
import { GET_INVOICE_STATS, GET_INVOICE_STATS2 } from './queries';

export const Profit: FC<IExportableStatistic> = ({ onChangeExport }) => {
	const styles = useStyle();
	const intl = useIntl();
	const [fromTo, onChangeFromTo] = useState<string>(
		`${Date.now() - week}/${Date.now()}`,
	);
	const [compare, onChangeCompare] = useState<boolean>(false);
	const [global, onChangeGlobal] = useState<boolean>(true);
	const [globalFiltered, onChangeGlobalFiltered] = useState<boolean>(false);

	const [selectedTab, onChangeSelectedTab] = useState<string>('customer'); // selected tab (for grouping)

	const [selectedEntities, onChangeSelectedEntities] = useState<Alpha[]>([]);
	const [entityColors, onChangeEntityColors] = useState<string[]>([]);

	const [interval, onChangeInterval] = useState<number>(day);
	const [entrys, onChangeEntrys] = useState<number>(10);
	const [startDate, onChangeStartDate] = useState(Date.now());

	// * memoized substitutions
	const from = useMemo(() => +fromTo.split('/')[0], [fromTo]);
	const to = useMemo(() => +fromTo.split('/')[1], [fromTo]);
	const sEs = useMemo(() => selectedEntities.map((c) => c._id).join(','), [
		selectedEntities,
	]);

	const [data, onChange] = useState<SStat>(new SStat());
	const [query] = useLazyQuery(GET_INVOICE_STATS, {
		onCompleted: (response) => {
			onChange(destructData(response));
		},
	});
	useEffect(() => {
		query({
			variables: {
				from,
				to,
				interval,
				entrys,
				selectedEntities: sEs,
				entityType: selectedTab,
				globalFilter: globalFiltered,
			},
		});
	}, [from, to, interval, entrys, sEs, globalFiltered, query, selectedTab]);
	const [pastData, onChangePast] = useState<SStat>(new SStat());
	const [query2] = useLazyQuery(GET_INVOICE_STATS2, {
		onCompleted: (response) => {
			onChangePast(destructData(response));
		},
	});
	useEffect(() => {
		if (compare) {
			query2({
				variables: {
					from,
					to,
					interval,
					entrys,
					selectedEntities: sEs,
					entityType: selectedTab,
					globalFilter: globalFiltered,
					past: compare,
				},
			});
		}
	}, [
		from,
		to,
		interval,
		entrys,
		sEs,
		globalFiltered,
		compare,
		query2,
		selectedTab,
	]);

	const updateFromTo = useCallback((fromto: string) => {
		onChangeFromTo(fromto);
	}, []);

	const getTick = useCallback(
		(tick: SData | number, asDate = false) => {
			const formattedTimeTick = formatTimeTick(
				tick,
				startDate,
				entrys,
				interval,
				asDate,
			);
			if (typeof formattedTimeTick === 'object') {
				return intl.formatMessage(formattedTimeTick);
			} else {
				return formattedTimeTick;
			}
		},
		[intl, interval, entrys, startDate],
	);

	useEffect(() => {
		onChangeExport(profitExport(data, selectedEntities));
	}, [onChangeExport, data, selectedEntities]);

	return (
		<>
			<View style={[styles.spacedContainer]}>
				<View style={[styles.horizontalLayout]}>
					<View style={styles.sideMenu}>
						<FilterSection
							selectedEntities={selectedEntities}
							onChangeSelectedEntities={onChangeSelectedEntities}
							selectedTab={selectedTab}
							onChangeSelectedTab={onChangeSelectedTab}
							entityColors={entityColors}
							onChangeEntityColors={onChangeEntityColors}
							global={global}
							onChangeGlobal={onChangeGlobal}
							globalFiltered={globalFiltered}
							onChangeGlobalFiltered={onChangeGlobalFiltered}
						/>
					</View>
					<View style={{ flex: 1, justifyContent: 'center' }}>
						<View style={styles.headerView}>
							<MundoText
								styles={styles.headerSecondaryText}
								message={messages.profitFrom}
							>
								{' ' + dateFormatter(new Date())}
							</MundoText>
						</View>
						<Chart
							selectedEntities={selectedEntities}
							entityColors={entityColors}
							compare={compare}
							global={global}
							data={data}
							pastData={pastData}
							entrys={entrys}
							yLabel={messages.netSumYLabel}
							formatTick={getTick}
						/>
					</View>
					<TimeConfigurator
						updateFromTo={updateFromTo}
						fromTo={fromTo}
						onChangeInterval={onChangeInterval}
						interval={interval}
						onChangeEntrys={onChangeEntrys}
						entrys={entrys}
						compare={compare}
						onChangeCompare={onChangeCompare}
						startDate={startDate}
						onChangeStartDate={onChangeStartDate}
					/>
				</View>
			</View>
			<DataTable
				data={data}
				past={pastData}
				selectedEntrys={selectedEntities}
				compare={compare}
				formatTick={getTick}
			/>
		</>
	);
};
