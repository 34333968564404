import Swal from 'sweetalert2';

const alert = (
	title: string,
	message: string,
	icon?: 'success' | 'error' | 'warning' | 'info' | 'question' | undefined,
) => {
	// floating promise because this is THE error handler
	// tslint:disable-next-line: no-floating-promises
	Swal.fire({
		title,
		text: message,
		icon,
		animation: false,
	});
};

const confirm = (
	title: string,
	message: string,
	callback: () => void,
	icon?: 'success' | 'error' | 'warning' | 'info' | 'question' | undefined,
) => {
	// tslint:disable-next-line: no-floating-promises
	Swal.fire({
		title,
		icon,
		text: message,
		showCancelButton: true,
		animation: false,
	}).then((res) => res.value && callback());
};

export { alert, confirm };
