import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';

import { MCustomer, MPackage } from 'mango-utils-client';
import { MundoText } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import { LanguageContext } from '../../../utilities/contexts/Language';
import useDebounce from '../../../utilities/hooks/debounce';
import { useStyle } from '../../../utilities/hooks/styles';
import {
	getTranslation,
	updateTranslation,
} from '../../../utilities/translations';
import messages from './messages';
import { IPackageFilter } from './props';
import { GET_COMPANIES } from './queries';

const PackageFilter: FC<IPackageFilter> = (props) => {
	const styles = useStyle();
	const [last, onChangeLast] = useState<MPackage>();
	const [pack, onChangePackage] = useState<MPackage>(new MPackage());
	const [customer, onChangeCustomer] = useState<MCustomer>();

	const debouncedSearchTerm = useDebounce(pack);

	const update = (next: Partial<MPackage>) => {
		onChangePackage(new MPackage({ ...pack, ...next }));
	};

	const loadCB = useCallback((next) => props.onChangeFilterPackage(next), [
		props,
	]);

	const { language } = useContext(LanguageContext);

	useEffect(() => {
		if (debouncedSearchTerm && debouncedSearchTerm !== last) {
			onChangeLast(debouncedSearchTerm);
			loadCB(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm, loadCB, last]);

	return (
		<View style={[styles.horizontalLayout, styles.elevated]}>
			<MundoInput
				horizontal
				label={messages.id}
				onChangeText={(id: string) =>
					!isNaN(+id) && update({ id: +id })
				}
				value={`${pack.id}`}
			/>
			<MundoInput
				horizontal
				dataSet={{ cy: 'package.filter.title' }}
				label={messages.title}
				onChangeText={(title: string) =>
					update({
						title: updateTranslation(title, language, pack.title),
					})
				}
				value={getTranslation(pack.title, language)}
			/>
			<MundoText message={messages.customer} styles={styles.padding} />
			<MundoObjectTypeahead
				value={customer}
				placeholder={messages.customerPlaceholder}
				onChange={(selectedCustomer) => {
					if (selectedCustomer) {
						update({ companyId: selectedCustomer._id });
						onChangeCustomer(selectedCustomer as MCustomer);
					} else if (pack.companyId) {
						update({ companyId: '' });
						onChangeCustomer({ _id: '', title: '' } as MCustomer);
					}
				}}
				QUERY={GET_COMPANIES}
			/>
		</View>
	);
};

export default PackageFilter;
