import React, { FC, memo, useCallback, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { TextInput, View } from 'react-native';

import { useStyle } from '../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import MundoText from '../MundoText';
import messages from './messages';
import { IMundoInput } from './props';

const MundoInput: FC<IMundoInput> = memo(
	({
		dataSet,
		label,
		disabled,
		style: propStyles,
		type,
		value,
		onChangeText,
		placeholder,
		unit,
		horizontal,
		customCheckInvalidity,
		hint,
		multiline,
		length,
		textfieldRef,
		secure,
	}) => {
		const styles = useStyle();
		const intl = useIntl();
		const [open, onChangeOpen] = useState(false);
		const fieldLength = length ? (length + 2) * 9 : 174;

		const defaultCheck = (input: any) => {
			if (typeof input === 'string') {
				return undefined;
			}
			return messages.defaultError;
		};

		const getValidity = useCallback(
			(input: any) => {
				if (customCheckInvalidity) {
					return customCheckInvalidity(input);
				}
				return defaultCheck(input);
			},
			[customCheckInvalidity],
		);

		const textprops = textfieldRef
			? {}
			: {
					value: value ? value : '',
					onChangeText,
			  };

		return (
			<View
				style={
					horizontal ? styles.horizontalLayout : styles.verticalLayout
				}
			>
				{label && (
					<MundoText
						message={label}
						styles={
							horizontal
								? styles.paddedViewHorizontal
								: styles.paddedViewVertical
						}
					/>
				)}
				<View style={styles.horizontalLayout}>
					<TextInput
						{...{ dataSet }}
						multiline={!!multiline}
						numberOfLines={multiline || 1}
						style={[
							styles.input,
							propStyles,
							disabled && styles.disabled,
							{ width: fieldLength },
						]}
						ref={textfieldRef}
						keyboardType={type}
						editable={!disabled}
						placeholder={
							placeholder &&
							(typeof placeholder === 'string'
								? (placeholder as string)
								: capitalizeFirstWord(
										intl.formatMessage(
											placeholder as MessageDescriptor,
										),
								  ))
						}
						onFocus={() => onChangeOpen(true)}
						onBlur={() => onChangeOpen(false)}
						secureTextEntry={secure}
						{...textprops}
					/>
					{!!unit && (
						<View
							style={[styles.centeredContent, styles.leftMargin5]}
						>
							<MundoText message={unit} />
						</View>
					)}
				</View>
				{getValidity(value) && (
					<MundoText
						styles={styles.error}
						message={getValidity(value)}
					/>
				)}
				{open && <MundoText message={hint} />}
			</View>
		);
	},
);

export default MundoInput;
