import React, { FC, useContext } from 'react';
import { ScrollView, View } from 'react-native';

import { getTranslation, MArticle, MTranslation } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { CSS_COLOR_NAMES } from '../../utilities/randomColor';
import messages from './messages';
import { IArticleFilterSectionProps } from './props';
import { GET_ARTICLES } from './queries';

export const ArticleFilterSection: FC<IArticleFilterSectionProps> = ({
	dispatchSelectedArticleAction,
	selectedArticles,
	articleColors,
	dispatchArticleColorAction,
	dispatchArticleSecondaryColorAction,
}) => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);
	// const [useAllCustomers, onChangeUseAll] = useState<boolean>(false);

	// const [getAllCustomers] = useLazyQuery(GET_ARTICLES, {
	// 	variables: {
	// 		any: {},
	// 		count: 500,
	// 	},
	// 	onCompleted: (data) => {
	// 		if (data) {
	// 			Object.keys(data).forEach((key) => {
	// 				if (key.length) {
	// 					const customers = data[key];
	// 					const colors = customers.map(() =>
	// 						CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)],
	// 					);
	// 					const secondaryColors = customers.map(() =>
	// 						CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)],
	// 					);
	// 					dispatchSelectedArticleAction({type: ETypes.RESET_TO, item: customers});
	// 					dispatchArticleColorAction({type: ETypes.RESET_TO, item: colors});
	// 					dispatchArticleSecondaryColorAction({type: ETypes.RESET_TO, item: secondaryColors});
	// 					onChangeUseAll(true);
	// 				}
	// 			});
	// 		}
	// 	},
	// });

	return (
		<View style={[{ justifyContent: 'space-between' }]}>
			<View style={[styles.elevated, { maxHeight: 1000 }]}>
				<View style={[styles.elevated, styles.horizontalLayout]}>
					<View style={styles.elevated}>
						<MundoText message={messages.article} />
						<MundoObjectTypeahead
							QUERY={GET_ARTICLES}
							onChange={(article) => {
								if (
									article &&
									!selectedArticles.find(
										(sA) => article._id === sA._id,
									)
								) {
									dispatchSelectedArticleAction({
										type: ETypes.ADD,
										item: new MArticle({
											...article,
											title: article.title as MTranslation[],
										}),
									});
									dispatchArticleColorAction({
										type: ETypes.ADD,
										item:
											CSS_COLOR_NAMES[
												Math.floor(
													Math.random() *
														CSS_COLOR_NAMES.length,
												)
											],
									});
									dispatchArticleSecondaryColorAction({
										type: ETypes.ADD,
										item:
											CSS_COLOR_NAMES[
												Math.floor(
													Math.random() *
														CSS_COLOR_NAMES.length,
												)
											],
									});
								}
							}}
							multilanguage
						/>
					</View>
					{/* <MundoCheckBox
					checked={useAllCustomers}
					onCheckedChanged={(c) => {
						if (c) {
							getAllCustomers();
						} else {
							dispatchArticleColorAction({type: ETypes.RESET_TO, item: []});
							dispatchArticleColorAction({type: ETypes.RESET_TO, item: []});
							dispatchArticleSecondaryColorAction({type: ETypes.RESET_TO, item: []});
							onChangeUseAll(false);
						}
					}}
					title={messages.useAllCustomers}
				/> */}
				</View>
				<ScrollView>
					{selectedArticles.map((article, index) => {
						return (
							<View
								style={
									styles.containerFullWidthHorizontalCenterAligned
								}
								key={article._id}
							>
								<MundoText
									message={getTranslation(
										article.title,
										language,
									)}
								/>
								<MundoButton
									onPress={() =>
										dispatchArticleColorAction({
											type: ETypes.EDIT,
											item:
												CSS_COLOR_NAMES[
													Math.floor(
														Math.random() *
															CSS_COLOR_NAMES.length,
													)
												],
											location: index,
										})
									}
									styles={{
										backgroundColor: articleColors[index],
									}}
								/>
								{/* {compare &&
							<MundoButton
								onPress={() => dispatchArticleSecondaryColorAction({
									type: ETypes.EDIT,
									item:
										CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)],
									location: index,
								})}
								styles={{backgroundColor: articleSecondaryColors[index]}}
							/>
						} */}
								<MundoButton
									onPress={() => {
										dispatchSelectedArticleAction({
											type: ETypes.REMOVE,
											location: index,
										});
										dispatchArticleColorAction({
											type: ETypes.REMOVE,
											location: index,
										});
										dispatchArticleSecondaryColorAction({
											type: ETypes.REMOVE,
											location: index,
										});
									}}
									icon={'minus'}
								/>
							</View>
						);
					})}
				</ScrollView>
			</View>
			{/* <View>
			<View
				style={styles.containerFullWidthHorizontalCenterAligned}
			>
				<MundoCheckBox
					checked={global}
					onCheckedChanged={onChangeGlobal}
					title={messages.global}
				/>
				<MundoButton
					onPress={() => console.log('')}
					styles={{backgroundColor: 'green'}}
				/>
				{compare &&
					<MundoButton
						onPress={() => console.log('')}
						styles={{backgroundColor: 'red'}}
					/>
				}
			</View>
			<MundoCheckBox
				checked={globalFiltered}
				onCheckedChanged={onChangeGlobalFiltered}
				title={messages.filterGlobal}
			/>
		</View> */}
		</View>
	);
};
