import React, { FC } from 'react';
import { View } from 'react-native';

import { alert } from '../../utilities/alert';
import messages from './messages';
import IImageSelectorProps from './props';

const ImageUploader: FC<IImageSelectorProps> = (props) => {
	return (
		<View style={props.style || {}}>
			<input
				type="file"
				accept="image/png"
				onChange={(event: any) => {
					if (event.target.files) {
						const files: Blob[] = Array.from(event.target.files);
						const reader = new FileReader();
						reader.onload = () => {
							const res = '' + reader.result;
							props.onChangeImg(res);
						};
						if (files[0]) {
							reader.readAsDataURL(files[0]);
						}
					} else {
						alert('error', messages.error + event, 'error');
					}
				}}
			/>
		</View>
	);
};

export default ImageUploader;
