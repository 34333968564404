import { gql } from '@apollo/client';

export const GET_TRUCKS = gql`
	query getTrucks(
		$truck: TTruckInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		tTrucks(
			truck: $truck
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			_id
			id
			title
			type
			companyID
			registrationDate
			mileage
			tachoTest
			huTest
			spTest
			uvvTest
		}
	}
`;
