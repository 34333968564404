import { useLazyQuery } from '@apollo/client';
import { MGroup } from 'mango-utils-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_GROUPS } from './queries';

interface IUseGroupsProps {
	skip: boolean;
	orderBy?: string | undefined;
}

export const useGroups = (props: IUseGroupsProps) => {
	const [groups, onChangeGroups] = useState<MGroup[]>([]);
	const [filter, onChangeFilter] = useState<MGroup>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			group: filter,
			start: groups.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, groups, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_GROUPS, {
		onCompleted: (data) => {
			if (data) {
				if (!data.groups.length || data.groups.length % 20 !== 0) {
					setLoadMoreActive(false);
				}
				if (reset) {
					onChangeGroups(data.groups);
					onChangeReset(false);
				} else {
					onChangeGroups([...groups, ...data.groups]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				group: filter,
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.orderBy]);

	return { groups, loading, loadMore, onChangeFilter, loadMoreActive };
};
