import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../utilities/constants';

const homepageStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		homeContainer: {
			alignItems: 'center',
			backgroundColor: theme.backgroundSecondaryColor,
			flex: 1,
			minWidth: '400px', // minwidth for popup. totally removable if that works
			flexDirection: 'column',
			justifyContent: 'flex-start',
			width: '100hh',
			height: '100vh',
			margin: 0,
			padding: 0,
		},
		instructions: {
			textAlign: 'center',
			marginBottom: 5,
		},
		logo: {
			margin: 20,
		},
	});

export default homepageStyles;
