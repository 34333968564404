import React, { FC } from 'react';
import { ScrollView, View } from 'react-native';

import { MundoButton, MundoText } from '../../../components/elements';
import OrderList from '../../../container/Order/List';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import messages from './messages';

export const StandaloneOrderList: FC = () => {
	const styles = useStyle();

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						styles={styles.headerText}
						message={messages.title}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() => openDocs('#/content/orders?id=liste')}
					/>
				</View>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<OrderList noHeader />
				</View>
			</ScrollView>
		</View>
	);
};
