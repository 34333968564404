import React, { FC } from 'react';
import { Image, ImageSourcePropType, View } from 'react-native';
import icons from '../../../assets/index';
import { ESortMode } from '../../../utilities/enums/ESortMode';
import { useTheme } from '../../../utilities/hooks/styles';
import MundoButton from '../MundoButton';
import { IMundoTableHeaderProps } from './props';
import mundoTableHeaderStyles from './styles';

const MundoTableHeader: FC<IMundoTableHeaderProps> = ({
	message,
	children,
	identifier,
	activeSorted,
	sortType,
	cyId,
	onChangeSort,
	textStyle,
	...passThrough
}) => {
	const { theme } = useTheme();
	const iconSize = { width: 20, height: 5 };
	const disabledColor = theme.borderTertiaryColor;
	const enabledColor = theme.textMainColor;
	const isCurrentlySorted =
		activeSorted === identifier || activeSorted === `-${identifier}`;
	const isAscSorted = isCurrentlySorted && sortType === ESortMode.ASC;
	const isDescSorted = isCurrentlySorted && sortType === ESortMode.DESC;

	return (
		<View style={mundoTableHeaderStyles(theme).container}>
			<MundoButton
				dataSet={{ cy: cyId }}
				styles={[mundoTableHeaderStyles(theme).button, textStyle]}
				fontStyles={mundoTableHeaderStyles(theme).text}
				onPress={
					onChangeSort
						? () => onChangeSort(identifier || '')
						: () => {
								/* do nothing*/
						  }
				}
				title={message}
				{...passThrough}
			>
				{children}
			</MundoButton>
			{onChangeSort && (
				<View style={mundoTableHeaderStyles(theme).iconContainer}>
					<View style={{ height: iconSize.height + 2 }}>
						<Image
							source={icons.tableSortUp as ImageSourcePropType}
							style={{
								width: iconSize.width,
								height: iconSize.height,
								tintColor: isAscSorted
									? enabledColor
									: disabledColor,
							}}
						/>
					</View>
					<View style={{ height: iconSize.height + 2 }}>
						<Image
							source={icons.tableSortDown as ImageSourcePropType}
							style={{
								width: iconSize.width,
								height: iconSize.height,
								tintColor: isDescSorted
									? enabledColor
									: disabledColor,
							}}
						/>
					</View>
				</View>
			)}
		</View>
	);
};

export default MundoTableHeader;
