import { useContext } from 'react';
import StylesContext from './context';

export const useStyle = () => {
	const { styles } = useContext(StylesContext);

	return styles;
};

export const useTheme = () => {
	const { theme, setTheme } = useContext(StylesContext);

	return { theme, setTheme };
};
