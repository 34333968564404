export enum ThemeType {
	Dark,
	Light,
}

export interface IThemeConstants {
	accentMainColor: string;
	accentSecondaryColor: string;
	accentTertiaryColor: string;
	accentQuaternaryColor: string;
	accentTextColor: string;
	backgroundMainColor: string;
	backgroundSecondaryColor: string;
	backgroundTertiaryColor: string;
	textMainColor: string;
	textSecondaryColor: string;
	fontMain: string;
	fontThin: string;
	borderTertiaryColor: string;
	borderSecondaryColor: string;
	boderMainColor: string;
	errorColor: string;
	warningColor: string;
	successColor: string;
}

export const numeric: 'numeric' | '2-digit' | undefined = 'numeric';
export const digits: 'numeric' | '2-digit' | undefined = '2-digit';

export const numericDateOptions = {
	day: numeric,
	month: numeric,
	year: numeric,
	hour: numeric,
	minute: numeric,
};
