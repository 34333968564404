import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MTTrailer } from 'mango-utils-client';
import { GET_TRAILERS } from './queries';

interface IUseTrailersProps {
	skip: boolean;
	orderBy: string | undefined;
}

export const useTrailers = (props: IUseTrailersProps) => {
	const [trailers, onChangeTrailers] = useState<MTTrailer[]>([]);
	const [filter, onChangeFilter] = useState<MTTrailer>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			trailer: filter,
			start: trailers.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, trailers, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_TRAILERS, {
		onCompleted: (data) => {
			if (data) {
				if (
					!data.tTrailers.length ||
					data.tTrailers.length % 20 !== 0
				) {
					setLoadMoreActive(false);
				} else if (!loadMoreActive) {
					setLoadMoreActive(true);
				}
				if (reset) {
					onChangeTrailers(data.tTrailers);
					onChangeReset(false);
				} else {
					onChangeTrailers([...trailers, ...data.tTrailers]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		onChangeReset(true);
		lazyQuery({
			variables: {
				trailer: filter,
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.orderBy]);

	return { trailers, loading, loadMore, onChangeFilter, loadMoreActive };
};
