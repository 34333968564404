import { defineMessages } from 'react-intl';

export const scope = 'mango.customer.edit';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID (optional)',
	},
	header: {
		id: `${scope}.header`,
		defaultMessage: 'edit company',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	isPerson: {
		id: `${scope}.isPerson`,
		defaultMessage: 'is person',
	},
	firstName: {
		id: `${scope}.firstName`,
		defaultMessage: 'first name',
	},
	lastName: {
		id: `${scope}.lastName`,
		defaultMessage: 'last name',
	},
	allegiance: {
		id: `${scope}.allegiance`,
		defaultMessage: 'base country',
	},
	ustIdNumber: {
		id: `${scope}.ustId`,
		defaultMessage: 'VAT ID',
	},
	taxNumber: {
		id: `${scope}.taxNumber`,
		defaultMessage: 'tax number',
	},
	externalId: {
		id: `${scope}.externalId`,
		defaultMessage: 'external identification',
	},
	address: {
		id: `${scope}.address`,
		defaultMessage: 'address',
	},
	billingAddress: {
		id: `${scope}.billingAddress`,
		defaultMessage: 'billing address',
	},
	taxError: {
		id: `${scope}.taxError`,
		defaultMessage: 'the tax number is in the wrong format!',
	},
	ustError: {
		id: `${scope}.ustError`,
		defaultMessage: 'the VAT-ID is in the wrong format!',
	},
	defaultPaymentMethod: {
		id: `${scope}.defaultPaymentMethod`,
		defaultMessage: 'default payment method',
	},
	groupInvoiceTime: {
		id: `${scope}.groupInvoiceTime`,
		defaultMessage: 'group invoice duration',
	},
	days: {
		id: `${scope}.days`,
		defaultMessage: 'days',
	},
	phone: {
		id: `${scope}.phone`,
		defaultMessage: 'phone',
	},
	mail: {
		id: `${scope}.mail`,
		defaultMessage: 'mail',
	},
	fax: {
		id: `${scope}.fax`,
		defaultMessage: 'fax',
	},
	groupInvoiceTimeHint: {
		id: `${scope}.groupInvoiceTimeHint`,
		defaultMessage:
			'the duration represents the time period from start of the first invoice to the end of the group invoice',
	},
	defaultPaymentTime: {
		id: `${scope}.defaultPaymentTime`,
		defaultMessage: 'default payment time',
	},
	discounts: {
		id: `${scope}.discounts`,
		defaultMessage: 'discounts',
	},
	selectAllegiance: {
		id: `${scope}.selectAllegiance`,
		defaultMessage: '-Select Base Country-',
	},
	hostData: {
		id: `${scope}.hostData`,
		defaultMessage: 'contractor data',
	},
	online: {
		id: `${scope}.online`,
		defaultMessage: 'update customer in database',
	},
	noOnline: {
		id: `${scope}.noOnline`,
		defaultMessage:
			"this customer doesn't seem to be included in the database",
	},
	paymentMethod: {
		id: `${scope}.paymentMethod`,
		defaultMessage: 'payment method',
	},
	enableTimedPaymentMethods: {
		id: `${scope}.enableTimedPaymentMethods`,
		defaultMessage: 'enable timed payment methods',
	},
	addEntry: {
		id: `${scope}.addEntry`,
		defaultMessage: 'add entry',
	},
	removeEntry: {
		id: `${scope}.removeEntry`,
		defaultMessage: 'remove entry',
	},
});
