import { gql } from '@apollo/client';

export const GET_PREPRODUCTS = gql`
	query(
		$preproduct: TPreproductInput
		$start: Int!
		$count: Int!
		$orderBy: String
	) {
		tPreproducts(
			preproduct: $preproduct
			start: $start
			count: $count
			orderBy: $orderBy
		) {
			title
			id
			_id
		}
	}
`;
