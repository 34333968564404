import React, { FC, memo } from 'react';
import {
	Image,
	ImageSourcePropType,
	ScrollView,
	Text,
	View,
} from 'react-native';

import icons from '../../../assets';
import { MundoButton } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import MundoText from '../../../components/elements/MundoText';
import { useGroups } from '../../../utilities/hooks/group';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';

const GroupList: FC = memo(() => {
	const styles = useStyle();
	const history = useHistory();

	const { orderBy, mode, changeSort } = useSort();

	const {
		groups,
		loading,
		loadMore /*, onChangeFilter*/,
		loadMoreActive,
	} = useGroups({ skip: false, orderBy });

	const createGroupButtonHandler = () => {
		history.push(`/edit/group/new`);
	};

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					<MundoText
						message={messages.title}
						styles={styles.headerText}
					/>
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs('#/content/settings?id=gruppen')
						}
					/>
				</View>
				<MundoButton
					dataSet={{ cy: 'groups.create.button' }}
					title={messages.create}
					onPress={createGroupButtonHandler}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoTable
						entrys={[
							{ identifier: 'id', header: messages.id, size: 4 },
							{
								identifier: 'title',
								header: messages.title,
								size: 90,
							},
							{ identifier: 'arrow', size: 4 },
						]}
						rows={groups.map((group) => {
							return {
								key: group._id,
								onPress: () =>
									history.push(`/edit/group/${group._id}`),
								values: [
									{
										identifier: 'id',
										content: <Text>{group.id}</Text>,
									},
									{
										identifier: 'title',
										content: <Text>{group.title}</Text>,
									},
									{
										identifier: 'arrow',
										content: (
											<Image
												source={
													icons.arrow as ImageSourcePropType
												}
												style={styles.disclosureIcon}
											/>
										),
									},
								],
								dataSet: { cy: `Groups.${group.title}` },
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
});

export default GroupList;
