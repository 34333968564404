import React, { FC } from 'react';
import { View } from 'react-native';
import { useTheme } from '../../../utilities/hooks/styles';
import MundoText from '../MundoText';
import { IMundoBasicTableHeaderProps } from './props';
import mundoBasicTableHeaderStyles from './styles';

const MundoBasicTableHeader: FC<IMundoBasicTableHeaderProps> = ({
	message,
	styles,
	children,
	cyId,
	...passThrough
}) => {
	const { theme } = useTheme();

	return (
		<View style={mundoBasicTableHeaderStyles(theme).container}>
			<MundoText
				isButtonText
				dataSet={{ cy: cyId }}
				message={message}
				styles={[mundoBasicTableHeaderStyles(theme).text, styles]}
				{...passThrough}
			>
				{children}
			</MundoText>
		</View>
	);
};

export default MundoBasicTableHeader;
