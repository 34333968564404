import { defineMessages } from 'react-intl';

export const scope = 'mango.auth';

export default defineMessages({
	timeout: {
		id: `${scope}.timeout`,
		defaultMessage: 'Connection timeout',
	},
	logout: {
		id: `${scope}.logout`,
		defaultMessage: "You've been logged out due to inactivity",
	},
});
