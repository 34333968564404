import { dateFormatter, priceFormatter } from 'mango-utils-client';
import React, { FC, memo } from 'react';
import { ScrollView, View } from 'react-native';

import { MundoButton, MundoText } from '../../../components/elements';
import MundoTable from '../../../components/elements/MundoTable';
import { useGroupInvoices } from '../../../utilities/hooks/groupInvoices';
import { useSort } from '../../../utilities/hooks/sort';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import GroupInvoiceFilter from '../Filter';
import messages from './messages';

const GroupInvoiceList: FC = memo(() => {
	const styles = useStyle();
	const history = useHistory();

	const { orderBy, mode, changeSort } = useSort();
	const {
		groupInvoices,
		loading,
		loadMore,
		loadMoreActive,
		onChangeStartDate,
		onChangeEndDate,
		onChangeFilter,
	} = useGroupInvoices({ skip: false, orderBy });

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<MundoText
					styles={styles.headerText}
					message={messages.title}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<MundoTable
						filter={
							<GroupInvoiceFilter
								onChangeStartDate={onChangeStartDate}
								onChangeEndDate={onChangeEndDate}
								onChangeFilter={onChangeFilter}
							/>
						}
						entrys={[
							{
								identifier: 'customer',
								header: messages.customer,
								size: 30,
							},
							{
								identifier: 'length',
								header: messages.length,
								size: 10,
							},
							{
								identifier: 'startDate',
								header: messages.startDate,
								size: 20,
							},
							{
								identifier: 'endDate',
								header: messages.endDate,
								size: 20,
							},
							{
								identifier: 'dueDate',
								header: messages.dueDate,
								size: 20,
							},
							{
								identifier: 'grossSum',
								header: messages.grossSum,
								size: 20,
							},
							{
								identifier: 'taxSum',
								header: messages.taxSum,
								size: 20,
							},
							{
								identifier: 'netSum',
								header: messages.netSum,
								size: 20,
							},
							{
								identifier: 'payment',
								header: messages.payment,
								size: 25,
							},
							{
								identifier: 'address',
								header: messages.address,
								size: 25,
							},
							{ identifier: 'button', size: 15 },
						]}
						rows={groupInvoices.map((groupInvoice) => {
							return {
								key: groupInvoice._id,
								values: [
									{
										identifier: 'customer',
										content: (
											<MundoText>
												{groupInvoice.customer.title}
											</MundoText>
										),
									},
									{
										identifier: 'length',
										content: (
											<MundoText>
												{groupInvoice.invoices.length}
											</MundoText>
										),
									},
									{
										identifier: 'startDate',
										content: (
											<MundoText>
												{dateFormatter(
													new Date(
														groupInvoice.startDate,
													),
												)}
											</MundoText>
										),
									},
									{
										identifier: 'endDate',
										content: (
											<MundoText>
												{dateFormatter(
													new Date(
														groupInvoice.endDate,
													),
												)}
											</MundoText>
										),
									},
									{
										identifier: 'dueDate',
										content: (
											<MundoText>
												{dateFormatter(
													new Date(
														groupInvoice.dueDate,
													),
												)}
											</MundoText>
										),
									},
									{
										identifier: 'grossSum',
										content: (
											<MundoText>
												{priceFormatter(
													groupInvoice.grossSum /
														1000,
												)}
											</MundoText>
										),
									},
									{
										identifier: 'taxSum',
										content: (
											<MundoText>
												{priceFormatter(
													groupInvoice.taxSum / 1000,
												)}
											</MundoText>
										),
									},
									{
										identifier: 'netSum',
										content: (
											<MundoText>
												{priceFormatter(
													groupInvoice.netSum / 1000,
												)}
											</MundoText>
										),
									},
									{
										identifier: 'payment',
										content: (
											<MundoText>
												{
													groupInvoice.paymentMethod
														.title
												}
											</MundoText>
										),
									},
									{
										identifier: 'address',
										content: (
											<MundoText>{`${groupInvoice.billingAddress.street} ${groupInvoice.billingAddress.zipCode} ${groupInvoice.billingAddress.city} ${groupInvoice.billingAddress.country}`}</MundoText>
										),
									},
									{
										identifier: 'button',
										content: (
											<MundoButton
												styles={{ width: 80 }}
												title={messages.pdf}
												onPress={() =>
													history.push(
														`pdf/gInvoice/${groupInvoice._id}`,
													)
												}
											/>
										),
									},
								],
							};
						})}
						onChangeSort={changeSort}
						orderBy={orderBy}
						sortMode={mode}
						loadMore={loadMore}
						loading={loading}
						loadMoreActive={loadMoreActive}
					/>
				</View>
			</ScrollView>
		</View>
	);
});

export default GroupInvoiceList;
