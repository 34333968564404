import React, { FC } from 'react';
import { View } from 'react-native';

import { priceFormatter } from 'mango-utils-client';
import MundoInput from '../../../../components/elements/MundoInput';
import messages from '../messages';
import { IArticleEditFieldProps } from '../props';

export const PriceField: FC<IArticleEditFieldProps> = ({ update, value }) => {
	const convertPriceBackToDB = (price: string) => {
		const formattedPrice = price.replace('.', '').replace(',', '') + '0';
		return +formattedPrice;
	};

	return (
		<View>
			<MundoInput
				dataSet={{ cy: 'article.price.input' }}
				label={messages.price}
				onChangeText={(priceToConvert) => {
					const price = convertPriceBackToDB(priceToConvert);
					update({ price });
				}}
				value={`${priceFormatter(value.price / 1000)}`}
				unit={'€'}
				length={6}
			/>
		</View>
	);
};
