import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const mundoTableHeaderStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		button: {
			backgroundColor: 'none',
			justifyContent: 'flex-start',
			alignContent: 'flex-start',
			flexDirection: 'row',
			borderWidth: 0,
			padding: 0,
			margin: 0,
		},
		text: {
			color: theme.textMainColor,
		},
		container: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		iconContainer: {
			maxHeight: 20,
			alignItems: 'center',
			padding: 0,
			margin: 0,
		},
	});

export default mundoTableHeaderStyles;
