import { defineMessages } from 'react-intl';

export const scope = 'mango.module.invoice';

export default defineMessages({
	apply: {
		id: `${scope}.apply`,
		defaultMessage: 'apply',
	},
	dueDate: {
		id: `${scope}.dueDate`,
		defaultMessage: 'due date',
	},
	issueDate: {
		id: `${scope}.issueDate`,
		defaultMessage: 'issue date',
	},
	netSum: {
		id: `${scope}.netSum`,
		defaultMessage: 'net sum',
	},
	taxSum: {
		id: `${scope}.taxSum`,
		defaultMessage: 'tax sum',
	},
	grossSum: {
		id: `${scope}.grossSum`,
		defaultMessage: 'gross sum',
	},
	fromThis: {
		id: `${scope}.fromThis`,
		defaultMessage: 'from this',
	},
	toThat: {
		id: `${scope}.toThat`,
		defaultMessage: 'to that',
	},
	history: {
		id: `${scope}.history`,
		defaultMessage: 'history',
	},
	paymentMethod: {
		id: `${scope}.paymentMethod`,
		defaultMessage: 'payment method',
	},
	paymentTime: {
		id: `${scope}.paymentTime`,
		defaultMessage: 'payment deadline (days)',
	},
	packages: {
		id: `${scope}.packages`,
		defaultMessage: 'packages',
	},
	title: {
		id: `${scope}.invoice`,
		defaultMessage: 'invoice',
	},
	useGroupInvoice: {
		id: `${scope}.useGroupInvoice`,
		defaultMessage: 'use group invoice',
	},
	useGroupInvoiceSmall: {
		id: `${scope}.useGroupInvoiceSmall`,
		defaultMessage: 'use group\ninvoice',
	},
	inherited: {
		id: `${scope}.inherited`,
		defaultMessage: 'inherited',
	},
	now: {
		id: `${scope}.now`,
		defaultMessage: 'now',
	},
	discount: {
		id: `${scope}.discount`,
		defaultMessage: 'discount',
	},
	discountPlaceholder: {
		id: `${scope}.discountPlaceholder`,
		defaultMessage: '-Select a customer discount-',
	},
	customerAllegiance: {
		id: `${scope}.customerAllegiance`,
		defaultMessage: 'customer base country',
	},
	price: {
		id: `${scope}.price`,
		defaultMessage: 'price',
	},
	amount: {
		id: `${scope}.amount`,
		defaultMessage: 'amount',
	},
	total: {
		id: `${scope}.total`,
		defaultMessage: 'total',
	},
	useProposal: {
		id: `${scope}.useProposal`,
		defaultMessage: 'create as proposal',
	},
});
