import gql from 'graphql-tag';

export const SAVE_RECEIPT_SETTINGS = gql`
	mutation saveReceiptSettings(
		$receiptSettings: MModuleReceiptSettingsInput!
	) {
		saveReceiptSettings(receiptSettings: $receiptSettings) {
			id
		}
	}
`;

export const GET_RECEIPT_SETTINGS_PACKAGES = gql`
	query getReceiptSettings($pipelineId: String!) {
		receiptSettings(pipelineId: $pipelineId) {
			packages {
				id
				_id
				title {
					lang
					message
				}
			}
		}
	}
`;

export const GET_RECEIPT_SETTINGS_ARTICLES = gql`
	query getReceiptSettings($pipelineId: String!) {
		receiptSettings(pipelineId: $pipelineId) {
			articles {
				id
				_id
				title {
					lang
					message
				}
				short
			}
		}
	}
`;

export const GET_RECEIPT_SETTINGS = gql`
	query getReceiptSettings($pipelineId: String!) {
		receiptSettings(pipelineId: $pipelineId) {
			id
			_id
			title
			dashboardFields
		}
	}
`;

export const GET_ARTICLES = gql`
	query($any: ArticleInput!, $count: Int!) {
		articles(article: $any, count: $count) {
			_id
			id
			title {
				lang
				message
			}
			short
		}
	}
`;

export const GET_PACKAGES = gql`
	query getPacks($any: PackageInput!, $count: Int!) {
		packages(package: $any, count: $count) {
			_id
			id
			title {
				lang
				message
			}
			companyId
		}
	}
`;
