import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import { checkToken } from '../../utilities/auth';

import { MundoButton } from '../elements';
import LoadingIndicator from '../LoadingIndicator';
import { IPDFViewerProps } from './props';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFViewer: FC<IPDFViewerProps> = ({ url, noToken }) => {
	const [data, onChangeData] = useState<Uint8Array>();
	const [numPages, setNumPages] = useState<number>(0);
	const [pageNumber /*, setPageNumber*/] = useState(1);
	const [accessToken, onChangeAccessToken] = useState('');

	useEffect(() => {
		onChangeAccessToken('');
	}, [url]);

	useEffect(() => {
		if (!noToken) {
			let isMounted = true;
			setTimeout(async () => {
				const { accessToken: t } = await checkToken();
				if (isMounted) {
					onChangeAccessToken(t);
				}
			}, 1);
			return () => {
				isMounted = false;
			};
		}
	}, [url, noToken]);

	const loadSuccessHandler = useCallback(async (successEvent) => {
		setNumPages(successEvent.numPages);
		// tslint:disable-next-line:await-promise
		onChangeData(await successEvent.getData());
	}, []);

	if (!accessToken && !noToken) {
		return <LoadingIndicator />;
	}

	return (
		<View>
			<Document
				file={url}
				onLoadSuccess={loadSuccessHandler}
				onLoadError={(e: any) => {
					console.error(e);
				}}
				options={
					!noToken && {
						httpHeaders: {
							Authorization: 'Bearer ' + accessToken,
						},
					}
				}
				loading={<LoadingIndicator />}
			>
				<Page pageNumber={pageNumber} />
			</Document>
			{data && (
				<MundoButton
					styles={{ position: 'absolute' }}
					icon={'download'}
					onPress={() => {
						const blob = new Blob([data], {
							type: 'application/pdf',
						});
						window.open(URL.createObjectURL(blob));
					}}
				/>
			)}
			{numPages > 1 && (
				<p>
					Page {pageNumber} of {numPages}
				</p>
			)}
		</View>
	);
};
