import { Alpha, dateFormatter, SStat } from 'mango-utils-client';

export function profitExport(data: SStat, selectedEntrys: Alpha[]): string {
	const headerDelimiter = `${'#'.repeat(10)}\n`;
	let result = '';

	result += headerDelimiter;
	result += 'Profit\n';
	result += headerDelimiter;

	if (data.general.length > 0) {
		const from = data.general[0].interval;
		const to = data.general[data.general.length - 1].interval;

		result += `von; ${from ? dateFormatter(new Date(from)) : ''}; `;
		result += `bis; ${to ? dateFormatter(new Date(to)) : ''};`;
		result += '\n';
	}

	data.general.forEach((genDate) => {
		const date = genDate.interval;
		result += `${date ? dateFormatter(new Date(date)) : ''}; ${
			genDate.y
		}\n`;
	});

	data.byRef.forEach((c) => {
		result += headerDelimiter;
		const entry = selectedEntrys.find((e) => e._id === c.ref._id);
		const parsed = { title: '', ...entry };
		result += `${parsed.title} ${c.ref.id}\n`;
		result += headerDelimiter;

		if (c.data.length > 0) {
			const from = c.data[0].interval;
			const to = c.data[data.general.length - 1].interval;

			result += `von; ${from ? dateFormatter(new Date(from)) : ''}; `;
			result += `bis; ${to ? dateFormatter(new Date(to)) : ''};`;
			result += '\n';
		}

		c.data.forEach((cusDate) => {
			const date = cusDate.interval;
			result += `${date ? dateFormatter(new Date(date)) : ''}; ${
				cusDate.y
			}\n`;
		});
	});

	return result;
}
