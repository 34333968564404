import React, { FC, useEffect, useState } from 'react';
import { Picker, View } from 'react-native';

import { ETTrailerType, MTTrailer } from 'mango-utils-client';
import MundoInput from '../../../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../../../components/elements/MundoObjectTypeahead';
import MundoText from '../../../../../components/elements/MundoText';
import LoadingIndicator from '../../../../../components/LoadingIndicator';
import { useDState } from '../../../../../utilities/hooks/debounce/useDState';
import { useStyle } from '../../../../../utilities/hooks/styles';
import messages from './messages';
import { ITrailerFilterProps } from './props';
import { GET_COMPANIES } from './queries';

const TrailerFilter: FC<ITrailerFilterProps> = ({ onChange }) => {
	const styles = useStyle();

	const [company, onChangeCompany] = useState({ _id: '', title: '' });

	const [trailer, debouncedTrailer, onChangeTrailer] = useDState<MTTrailer>({
		initialValue: new MTTrailer(),
	});

	const update = (next: Partial<MTTrailer>) => {
		return onChangeTrailer(new MTTrailer({ ...trailer, ...next }));
	};

	useEffect(() => {
		if (debouncedTrailer) {
			onChange(debouncedTrailer);
		}
	}, [debouncedTrailer, onChange]);

	if (!trailer) {
		return <LoadingIndicator />;
	}

	return (
		<View style={[styles.filterGroup, styles.wrap]}>
			<View style={[styles.formUnitStandardWidth, styles.elevated]}>
				<MundoText
					message={messages.companyName}
					styles={styles.pickerLabelSpacing}
				/>
				<MundoObjectTypeahead
					value={company}
					placeholder={messages.companyName}
					onChange={(selectedCompany) => {
						if (selectedCompany) {
							update({ companyID: selectedCompany._id });
							onChangeCompany({
								...selectedCompany,
								title: selectedCompany.title as string,
							});
						} else if (trailer.companyID) {
							update({ companyID: '' });
							onChangeCompany({ _id: '', title: '' });
						}
					}}
					QUERY={GET_COMPANIES}
				/>
			</View>
			<View style={styles.formUnitStandardWidth}>
				<MundoInput
					label={messages.id}
					onChangeText={(id) => update({ id: +id })}
					value={`${trailer.id}`}
					type={`numeric`}
					length={5}
				/>
			</View>
			<View style={styles.formUnitStandardWidth}>
				<MundoInput
					dataSet={{ cy: 'trailer.filter.license.input' }}
					label={messages.licensePlate}
					onChangeText={(title) => update({ title })}
					value={trailer.title}
				/>
			</View>
			<View style={styles.formUnitStandardWidth}>
				<MundoText
					message={messages.equipmentType}
					styles={styles.pickerLabelSpacing}
				/>
				<Picker
					style={styles.picker}
					onValueChange={(trailerType) => update({ trailerType })}
				>
					<Picker.Item label={`-Select Type-`} />
					{Object.keys(ETTrailerType).map((value) => (
						<Picker.Item
							label={`${value}`}
							value={value}
							key={`ttype${value}`}
						/>
					))}
				</Picker>
			</View>
			{/* TODO implement filter by chambers
				<MundoText message={messages.chambers} />
				<Picker
					style={styles.picker}
				>
					<Picker.Item label={`-Select Chambers-`} />
					{range(6).map((i) => (
						<Picker.Item
							label={`${i + 1}`}
							value={`${i + 1}`}
							key={`chamberPicker${i}`}
						/>
					))}
				</Picker>
			*/}
		</View>
	);
};

export default TrailerFilter;
