import React, { FC, memo, useContext } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { LanguageContext } from '../../../../utilities/contexts/Language';

import { useTheme } from '../../../../utilities/hooks/styles';
import { getTranslation } from '../../../../utilities/translations';
import { ISubarticleEntryProps } from '../props';
import { articleEditStyles } from '../styles';

const SubarticleEntry: FC<ISubarticleEntryProps> = memo((props) => {
	const { article, subArticle, update, isArticleASubArticle } = props;
	const { theme } = useTheme();
	const { language } = useContext(LanguageContext);

	return (
		<TouchableOpacity
			{...{
				dataSet: {
					cy: `${getTranslation(
						subArticle.title,
						language,
					)}.add.button`,
				},
			}}
			disabled={isArticleASubArticle(subArticle)}
			key={`ArticlesEditSelector${subArticle._id}`}
			style={[
				articleEditStyles(theme).subArticle,
				isArticleASubArticle(subArticle) &&
					articleEditStyles(theme).disabledSubArticle,
			]}
			onPress={() => {
				update({
					subArticles: [...article.subArticles, subArticle],
				});
			}}
		>
			<Text>
				{subArticle.short +
					' ' +
					getTranslation(subArticle.title, language)}
			</Text>
		</TouchableOpacity>
	);
});

export default SubarticleEntry;
