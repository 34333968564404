import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const invoiceStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		container: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-around',
		},
		side: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: 20,
		},
		paymentMethod: {
			margin: 10,
			padding: 10,
			borderStyle: 'solid',
			borderRadius: 5,
			borderColor: theme.backgroundTertiaryColor,
			borderWidth: 1,
		},
		packageCard: {
			borderColor: theme.backgroundTertiaryColor,
			borderWidth: 1,
			borderStyle: 'solid',
			borderRadius: 5,
			padding: 5,
		},
	});

export default invoiceStyles;
