import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MInvoice } from 'mango-utils-client';
import { GET_INVOICES } from './queries';

interface IUseInvoicesProps {
	skip?: boolean;
	QUERY?: any; // figure out le type
	orderBy?: string | undefined;
	count?: number;
	filter?: Partial<MInvoice>;
}

export const useInvoices = (props: IUseInvoicesProps) => {
	const [invoices, updateLocalInvoices] = useState<MInvoice[]>(
		[] as MInvoice[],
	);
	const [filter, onChangeFilter] = useState<MInvoice>();
	const [fromDate, onChangeFrom] = useState<number>();
	const [toDate, onChangeTo] = useState<number>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		const f = { ...filter, ...props.filter };
		return {
			invoice: f,
			start: invoices.length,
			count: props.count || 20,
			from: fromDate,
			to: toDate,
			orderBy: props.orderBy,
		};
	}, [filter, invoices, fromDate, toDate, props]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(props.QUERY || GET_INVOICES, {
		onCompleted: (data) => {
			if (data) {
				if (
					!data.invoices.length ||
					data.invoices.length % (props.count || 20) !== 0
				) {
					setLoadMoreActive(false);
				}
				if (reset) {
					updateLocalInvoices(
						data.invoices.map(
							(invoice: MInvoice) => new MInvoice(invoice),
						),
					);
					onChangeReset(false);
				} else {
					updateLocalInvoices([...invoices, data.invoices]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				invoice: { ...filter, ...props.filter },
				count: props.count || 20,
				start: 0,
				from: fromDate,
				to: toDate,
				orderBy: props.orderBy,
			},
		});
	}, [lazyQuery, filter, fromDate, toDate, props]);

	return {
		invoices,
		loading,
		loadMore,
		onChangeFilter,
		onChangeFrom,
		onChangeTo,
		loadMoreActive,
	};
};
