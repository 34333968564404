import { defineMessages } from 'react-intl';

export const scope = 'mango.components.groupEdit';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'edit group',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	inheritance: {
		id: `${scope}.inheritance`,
		defaultMessage: 'inheritance',
	},
});
