import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.DriverList';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	email: {
		id: `${scope}.email`,
		defaultMessage: 'e-mail address',
	},
	phone: {
		id: `${scope}.phone`,
		defaultMessage: 'phone',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit',
	},
	noData: {
		id: `${scope}.noData`,
		defaultMessage: 'no data found',
	},
	drivers: {
		id: `${scope}.drivers`,
		defaultMessage: 'drivers',
	},
	missingValue: {
		id: `${scope}.missingValue`,
		defaultMessage: 'missing Value',
	},
});
