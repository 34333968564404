import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MTPreproduct } from 'mango-utils-client';
import { GET_PREPRODUCTS } from './queries';

interface IUsePreproductsProps {
	skip: boolean;
	orderBy: string | undefined;
}

export const usePreproducts = (props: IUsePreproductsProps) => {
	const [preproducts, onChangePreproducts] = useState<MTPreproduct[]>([]);
	const [filter, onChangeFilter] = useState<MTPreproduct>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			preproduct: filter,
			start: preproducts.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, preproducts, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_PREPRODUCTS, {
		onCompleted: (data) => {
			if (data) {
				if (
					!data.tPreproducts.length ||
					data.tPreproducts.length % 20 !== 0
				) {
					setLoadMoreActive(false);
				} else if (!loadMoreActive) {
					setLoadMoreActive(true);
				}
				if (reset) {
					onChangePreproducts(data.tPreproducts);
					onChangeReset(false);
				} else {
					onChangePreproducts([...preproducts, ...data.tPreproducts]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		onChangeReset(true);
		lazyQuery({
			variables: {
				preproduct: filter,
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.orderBy]);

	return { preproducts, loading, loadMore, onChangeFilter, loadMoreActive };
};
