import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { createContext, FC, useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';

import {
	ELanguages,
	ETCalculationFunction,
	ETTriggerFunction,
	MBundledArticle,
	MCustomer,
	MPackage,
	priceFormatter,
} from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../components/elements/MundoObjectTypeahead';
import MundoPicker from '../../../components/elements/MundoPicker';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import { alert } from '../../../utilities/alert/index';
import { LanguageContext } from '../../../utilities/contexts/Language';
import { useStyle } from '../../../utilities/hooks/styles';
import { openDocs } from '../../../utilities/openDocs';
import { useHistory, useParams } from '../../../utilities/routing';
import {
	getTranslation,
	updateTranslation,
} from '../../../utilities/translations';
import BundledArticleList from '../components/BundledArticleList';
import CalculationBundle from '../components/CalculationBundle';
import messages from './messages';
import {
	DELETE_PACKAGE,
	GET_CUSTOMER,
	GET_CUSTOMERS,
	GET_PACKAGE,
	SAVE_PACKAGE,
} from './queries';

export const PackageEditContext = createContext({
	pack: new MPackage(),
	update: (input: Partial<MPackage>) => {
		console.log(input);
		alert('error', 'update is undefined', 'error');
	},
});

const PackageEdit: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	// decoding of url
	const { id } = useParams<{ id: string }>();
	// local pack state
	const [pack, onChangePackage] = useState(
		new MPackage({
			triggerFunctionName: ETTriggerFunction.DEFAULT,
			calculationFunctionName: ETCalculationFunction.DEFAULT,
		}),
	);
	const { language: appLanguage } = useContext(LanguageContext);
	const [language, onChangeLanguage] = useState<ELanguages>(appLanguage);

	useQuery(GET_PACKAGE, {
		skip: !id || id === 'new',
		variables: { id },
		onCompleted: (data) => {
			if (data) {
				const parsedPackage = new MPackage(data.package);
				if (!parsedPackage.triggerFunctionName) {
					parsedPackage.triggerFunctionName =
						ETTriggerFunction.DEFAULT;
				}
				if (!parsedPackage.calculationFunctionName) {
					parsedPackage.calculationFunctionName =
						ETCalculationFunction.DEFAULT;
				}
				onChangePackage(parsedPackage);
				// TODO let server do this
				if (parsedPackage.companyId) {
					loadCustomer({
						variables: { id: parsedPackage.companyId },
					});
				}
			}
		},
	});

	const [loadCustomer] = useLazyQuery<
		{ customer: MCustomer },
		{ id?: string }
	>(GET_CUSTOMER, {
		onCompleted: (queryData) => {
			if (queryData && queryData.customer) {
				if (!customer) {
					changeCustomer(queryData.customer);
				}
			}
		},
	});
	const [customer, changeCustomer] = useState<MCustomer>();
	/**
	 * update function to dispatch state change with new identity
	 * @param input package with field to update
	 */
	const update = (input: Partial<MPackage>) => {
		const next = Object.assign({}, pack, input);
		onChangePackage(next);
	};
	// save function
	const [saveQuery] = useMutation(SAVE_PACKAGE);
	const [deletePackage] = useMutation(DELETE_PACKAGE);

	const savePack = async () => {
		await saveQuery({ variables: { package: pack } });
		history.goBack();
	};

	const deleteButtonHandler = async () => {
		await deletePackage({ variables: { id } });
		history.goBack();
	};

	return (
		<PackageEditContext.Provider value={{ pack, update }}>
			<View style={styles.containerFullResolution}>
				<View style={styles.headerView}>
					<View style={styles.headerTitleContainer}>
						<MundoText
							styles={styles.headerText}
							message={messages.header}
						/>
						<MundoButton
							icon={'question'}
							subtype="transparent"
							onPress={() => openDocs('#/content/packages')}
						/>
					</View>
					<MundoPicker
						style={[styles.formUnitHalfWidth]}
						onChange={onChangeLanguage}
						values={Object.keys(ELanguages).map((lang) => {
							return { label: lang, value: lang };
						})}
						value={language}
					/>
				</View>
				<ScrollView>
					<View style={[styles.spacedContainer, styles.elevated]}>
						<View
							style={[
								styles.containerFullWidthHorizontalNoPadding,
								{ zIndex: 11 },
							]}
						>
							<View
								style={[
									styles.containerHalfWidthNoPadding,
									styles.elevated,
								]}
							>
								<MundoInput
									dataSet={{ cy: 'package.title.input' }}
									label={messages.title}
									onChangeText={(title) =>
										update({
											title: updateTranslation(
												title,
												language,
												pack.title,
											),
										})
									}
									value={getTranslation(pack.title, language)}
								/>
								<View style={styles.elevated}>
									<MundoText
										message={messages.company}
										styles={[
											styles.bottomMargin5,
											styles.topMargin6,
										]}
									/>
									<MundoObjectTypeahead
										cyId={'package.company.input'}
										value={customer}
										placeholder={messages.company}
										onChange={(selectedCompany) =>
											update({
												companyId: selectedCompany
													? selectedCompany._id
													: '',
											})
										}
										QUERY={GET_CUSTOMERS}
									/>
								</View>
							</View>
							<View style={styles.containerHalfWidthNoPadding}>
								<MundoInput
									dataSet={{
										cy: 'package.description.input',
									}}
									label={messages.description}
									onChangeText={(description) =>
										update({
											description: updateTranslation(
												description,
												language,
												pack.description,
											),
										})
									}
									value={getTranslation(
										pack.description,
										language,
									)}
								/>
								<MundoInput
									dataSet={{ cy: 'package.price.input' }}
									label={messages.price}
									unit={'€'}
									value={`${
										pack.price > 0
											? priceFormatter(+pack.price / 1000)
											: ''
									}`}
									onChangeText={(text: string) => {
										// convert price from readable string to milli Euro (format for saving in database)
										update({
											price:
												+text.replace(/,|\./g, '') * 10,
										});
									}}
									length={6}
								/>
							</View>
							<View style={styles.containerHalfWidthNoPadding}>
								<MundoInput
									dataSet={{ cy: 'package.tags' }}
									label={messages.tags}
									onChangeText={(tags) =>
										update({ tags: tags.split(',') })
									}
									value={pack.tags.join(',')}
								/>
								<MundoPicker
									style={{ minWidth: 200 }}
									label={messages.calculation}
									{...{
										dataSet: {
											cy: 'package.calc.function',
										},
									}}
									value={pack.calculationFunctionName}
									onChange={(calc) =>
										update({
											calculationFunctionName: calc,
										})
									}
									values={Object.keys(
										ETCalculationFunction,
									).map((cf) => {
										return {
											// @ts-ignore
											label: messages[cf],
											value: cf,
										};
									})}
								/>
							</View>
						</View>
						{/* calculation bundle display if calculation bundle needed */}
						{pack &&
							pack.calculationFunctionName !==
								ETCalculationFunction.DEFAULT && (
								<View style={styles.elevated}>
									<CalculationBundle />
								</View>
							)}
						<MundoPicker
							label={messages.trigger}
							data-cy={'package.trigger.function'}
							value={pack.triggerFunctionName}
							onChange={(trigger) =>
								update({ triggerFunctionName: trigger })
							}
							values={Object.keys(ETTriggerFunction).map((tf) => {
								// @ts-ignore
								return { label: messages[tf], value: tf };
							})}
						/>
						<BundledArticleList />
						<MundoButton
							title={messages.addArticleBundle}
							dataSet={{ cy: 'package.addArticle.button' }}
							icon={'plus'}
							onPress={() => {
								const ba = new MBundledArticle();
								ba.upperAmount = 0;
								const includedArticles = pack.includedArticles;
								includedArticles.push(new MBundledArticle());
								update({ includedArticles });
							}}
							styles={[
								styles.topMargin10,
								{ alignSelf: 'flex-start' },
							]}
						/>
					</View>
					<FinishButtonGroup
						cyId={'package'}
						saveFunction={savePack}
						saveDisabled={
							!!pack.includedArticles.find(
								(bA) =>
									!bA.articles.length || !bA.articles[0]._id,
							)
						}
						saveDisabledReason={messages.bundledArticleError}
						deleteFunction={deleteButtonHandler}
						cancelFunction={() => history.goBack()}
					/>
				</ScrollView>
			</View>
		</PackageEditContext.Provider>
	);
};

export default PackageEdit;
