import { decode, encode } from 'base-64';

import { MLocalSettings } from 'mango-utils-client';
import { alert } from '../alert';
import { load, save } from '../localStorage';

const key = 'local_settings';
/**
 * get local settings
 */
export const getLocalSettings = async () => {
	return await new Promise<MLocalSettings>((resolve) => {
		load(key)
			.then((next) => {
				if (next) {
					resolve(new MLocalSettings(JSON.parse(decode(next))));
				} else {
					resolve(new MLocalSettings());
				}
			})
			.catch((e) => alert('error', e, 'error'));
	});
};
/**
 * takes partial changes and combines them with previous values
 * store result in data state and local storage
 * @param input changes
 */
export const saveLocalSettings = (input: Partial<MLocalSettings>) => {
	getLocalSettings()
		.then(async (prev) => {
			const next = new MLocalSettings({ ...prev, ...input });
			await save(key, encode(JSON.stringify(next)));
		})
		.catch((e) => alert('error', e, 'error'));
};
