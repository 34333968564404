import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import PDFViewer from '../../../../components/PDFViewer';
import { config } from '../../../../utilities/config';

const QCD: FC = () => {
	const { id } = useParams<{ id: string }>();

	return <PDFViewer noToken url={`${config.API_URL}/qcd/${id}`} />;
};

export default QCD;
