import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.TrailerFilter';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID',
	},
	companyName: {
		id: `${scope}.companyName`,
		defaultMessage: 'company name',
	},
	licensePlate: {
		id: `${scope}.licensePlate`,
		defaultMessage: 'license plate',
	},
	equipmentType: {
		id: `${scope}.equipmentType`,
		defaultMessage: 'equipment type',
	},
	chambers: {
		id: `${scope}.chamber`,
		defaultMessage: 'chamber',
	},
});
