import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.TrailerList';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	type: {
		id: `${scope}.type`,
		defaultMessage: 'type',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit',
	},
	noData: {
		id: `${scope}.noData`,
		defaultMessage: 'no data found',
	},
	trailers: {
		id: `${scope}.trailers`,
		defaultMessage: 'trailers',
	},
	missingValue: {
		id: `${scope}.missingValue`,
		defaultMessage: 'missing Value',
	},
});
