import React, { FC } from 'react';

import { ECountries } from 'mango-utils-client';
import MundoPicker from '../elements/MundoPicker';
import messages from './messages';
import { ICountryPickerProps } from './props';

export const CountryPicker: FC<ICountryPickerProps> = ({
	value,
	onChange,
	cyId,
	countries,
}) => (
	<MundoPicker
		dataSet={{ cy: `${cyId}.country` }}
		label={messages.country}
		onChange={onChange}
		value={value}
		values={(countries
			? countries.map(
					(c) =>
						Object.keys(ECountries).find(
							(k) =>
								// @ts-ignore
								ECountries[k] === c,
						) || '',
			  )
			: Object.keys(ECountries)
		).map((c) => {
			// @ts-ignore
			return { label: c, value: ECountries[c] };
		})}
		placeholder={messages.selectCountry}
	/>
);
