import { defineMessages } from 'react-intl';

export const scope = 'mango.components.UserEdit';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	groups: {
		id: `${scope}.groups`,
		defaultMessage: 'groups',
	},
	mail: {
		id: `${scope}.mail`,
		defaultMessage: 'e-mail address',
	},
	telephone: {
		id: `${scope}.telephone`,
		defaultMessage: 'telephone',
	},
	classification: {
		id: `${scope}.classification`,
		defaultMessage: 'classification',
	},
	changePassword: {
		id: `${scope}.changePassword`,
		defaultMessage: 'change password',
	},
	image: {
		id: `${scope}.image`,
		defaultMessage: 'profile image',
	},
	profile: {
		id: `${scope}.profile`,
		defaultMessage: 'profile',
	},
	edit: {
		id: `${scope}.edit`,
		defaultMessage: 'edit profile',
	},
});
