import { StyleSheet } from 'react-native';
import { IThemeConstants } from '../../../utilities/constants';

const MundoObjectTypeaheadStyles = (theme: IThemeConstants) =>
	StyleSheet.create({
		typeAheadGroup: {
			display: 'flex',
			flexDirection: 'row',
		},
		floatingContentBox: {
			position: 'absolute',
			marginTop: 39,
			marginLeft: 5,
			marginRight: 5,
			backgroundColor: theme.backgroundSecondaryColor,
			width: '240px',
			zIndex: 100,
			borderWidth: 0,
		},
		floatingCreateBox: {
			position: 'absolute',
		},
		highlight: {
			backgroundColor: theme.textSecondaryColor,
			color: theme.backgroundTertiaryColor,
		},
		option: {
			borderBottomColor: theme.borderTertiaryColor,
			borderBottomWidth: StyleSheet.hairlineWidth,
			height: 50,
			paddingLeft: 20,
			justifyContent: 'center',
		},
		optionActive: {
			backgroundColor: theme.textSecondaryColor,
		},
	});

export default MundoObjectTypeaheadStyles;
