import React, { FC, memo, useState } from 'react';
import { View } from 'react-native';

import { MPaymentMethod } from 'mango-utils-client';
import { MundoButton } from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { useStyle } from '../../../../utilities/hooks/styles';
import messages from '../messages';
import { IPaymentSelector } from '../props';
import { GET_PAYMENT_METHODS } from '../queries';

const PaymentSelector: FC<IPaymentSelector> = memo((props) => {
	const styles = useStyle();
	const [next, onChangeNext] = useState<MPaymentMethod>();
	return (
		<View style={styles.horizontalLayout}>
			<MundoObjectTypeahead
				cyId={'payment.input'}
				value={next}
				onChange={(newPaymentMethod) => {
					if (newPaymentMethod) {
						onChangeNext(newPaymentMethod as MPaymentMethod);
					}
				}}
				QUERY={GET_PAYMENT_METHODS}
			/>
			<MundoButton
				dataSet={{ cy: 'addPaymentMethod' }}
				title={messages.addPaymentMethod}
				onPress={() => {
					if (next) {
						props.onNewPayment(next);
					}
				}}
				disabled={!next}
			/>
		</View>
	);
});

export default PaymentSelector;
