import { useReducer } from 'react';
import { arrayReducer, ETypes } from '../../reducer/array.reducer';

export const useArray = <t>(
	initial: t[] = [],
): [
	t[],
	React.Dispatch<{
		type: ETypes;
		location?: number | undefined;
		item?: t | t[];
	}>,
] => {
	const [array, dispatchAction] = useReducer(arrayReducer, initial);

	return [array, dispatchAction];
};
