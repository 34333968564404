import React, { FC } from 'react';
import { View } from 'react-native';

import { MGroupInvoice } from 'mango-utils-client';
import { useIntl } from 'react-intl';
import DatePicker from '../../../../../components/DatePicker';
import { MundoText } from '../../../../../components/elements';
import { useStyle } from '../../../../../utilities/hooks/styles';
import { capitalizeFirstWord } from '../../../../../utilities/stringFunctions';
import messages from './messages';
import { IGroupInvoiceProps } from './props';

/**
 * TItems selector based on type.
 * @param props TItems props
 */
const GroupInvoiceEdit: FC<IGroupInvoiceProps> = ({
	groupInvoice,
	onChange,
}) => {
	const styles = useStyle();
	const intl = useIntl();
	const update = (next: Partial<MGroupInvoice>) => {
		if (!onChange) {
			throw Error(
				capitalizeFirstWord(intl.formatMessage(messages.noOnChange)),
			);
		}
		onChange(new MGroupInvoice({ ...groupInvoice, ...next }));
	};

	return (
		<View>
			<MundoText
				message={messages.title}
				styles={[styles.bottomMargin20, { fontWeight: 'bold' }]}
			>
				{'\n\n'}
			</MundoText>
			<MundoText
				message={messages.startDate}
				styles={[styles.bottomMargin10, { fontWeight: 'bold' }]}
			/>
			<DatePicker
				value={new Date(groupInvoice.startDate)
					.toISOString()
					.substr(0, 10)}
				onChange={(startDate) => {
					update({ startDate });
				}}
			/>
			<MundoText
				message={messages.endDate}
				styles={[
					styles.bottomMargin10,
					styles.topMargin10,
					{ fontWeight: 'bold' },
				]}
			/>
			<DatePicker
				value={new Date(groupInvoice.endDate)
					.toISOString()
					.substr(0, 10)}
				onChange={(endDate) => {
					update({ endDate });
				}}
			/>
			<MundoText
				message={messages.dueDate}
				styles={[
					styles.bottomMargin10,
					styles.topMargin10,
					{ fontWeight: 'bold' },
				]}
			/>
			<DatePicker
				value={new Date(groupInvoice.dueDate)
					.toISOString()
					.substr(0, 10)}
				onChange={(dueDate) => {
					update({ dueDate });
				}}
			/>
		</View>
	);
};

export default GroupInvoiceEdit;
