import { defineMessages } from 'react-intl';

export const scope = 'mango.components.Sidebar';

export default defineMessages({
	home: {
		id: `${scope}.home`,
		defaultMessage: 'home',
	},
	open: {
		id: `${scope}.open`,
		defaultMessage: 'open',
	},
	close: {
		id: `${scope}.close`,
		defaultMessage: 'close',
	},
	settings: {
		id: `${scope}.settings`,
		defaultMessage: 'settings',
	},
	logOut: {
		id: `${scope}.logOut`,
		defaultMessage: 'log out',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	orders: {
		id: `${scope}.orders`,
		defaultMessage: 'orders',
	},
	datevExport: {
		id: `${scope}.exports`,
		defaultMessage: 'Datev Export',
	},
	archiv: {
		id: `${scope}.archiv`,
		defaultMessage: 'archive',
	},
	groupInvoices: {
		id: `${scope}.groupInvoices`,
		defaultMessage: 'group invoices',
	},
	profile: {
		id: `${scope}.profile`,
		defaultMessage: 'profile',
	},
	documentation: {
		id: `${scope}.documentation`,
		defaultMessage: 'documentation',
	},
	credits: {
		id: `${scope}.credits`,
		defaultMessage: 'credits',
	},
	uploadECD: {
		id: `${scope}.uploadECD`,
		defaultMessage: 'upload scanned ECD',
	},
	dataManagement: {
		id: `${scope}.dataManagement`,
		defaultMessage: 'data management',
	},
	back: {
		id: `${scope}.back`,
		defaultMessage: 'back',
	},
	customers: {
		id: `${scope}.customers`,
		defaultMessage: 'customers',
	},
	proposals: {
		id: `${scope}.proposals`,
		defaultMessage: 'proposals',
	},
	language: {
		id: `${scope}.language`,
		defaultMessage: 'language',
	},
});
