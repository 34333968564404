import React, { FC, useContext, useState } from 'react';
import { View } from 'react-native';

import { getTranslation, MArticle, MTranslation } from 'mango-utils-client';
import { IEditableArticleListProps } from './props';
import { MundoText, MundoButton } from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useStyle } from '../../../../utilities/hooks/styles';
import { GET_ARTICLES } from '../../../Article/Edit/queries';

export const EditableArticleList: FC<IEditableArticleListProps> = ({
	articles,
	onChange,
	title,
}) => {
	const styles = useStyle();

	const { language } = useContext(LanguageContext);
	const [nextArticle, onChangeNextArticle] = useState<MArticle>();

	return (
		<View style={styles.spacedContainer}>
			<MundoText styles={styles.headerSecondaryText} message={title} />
			<View style={[styles.leftMargin20, { zIndex: 1 }]}>
				{articles.map((article, index) => (
					<View
						key={`${article._id}.${index}`}
						style={[styles.horizontalLayout, styles.elevated]}
					>
						<View style={styles.padding}>
							<MundoText message={article.short} />
						</View>
						<View style={styles.padding}>
							<MundoText
								message={getTranslation(
									article.title,
									language,
								)}
							/>
						</View>
						<MundoButton
							styles={{ zIndex: -1 }}
							icon={'minus'}
							onPress={() => {
								const next = [...articles];
								next.splice(index, 1);
								onChange(next);
							}}
						/>
					</View>
				))}
			</View>
			<View style={styles.horizontalLayout}>
				<MundoObjectTypeahead
					value={nextArticle}
					onChange={(next) => {
						if (next) {
							onChangeNextArticle(
								new MArticle({
									...next,
									title: next.title as MTranslation[],
								}),
							);
						} else {
							onChangeNextArticle(undefined);
						}
					}}
					QUERY={GET_ARTICLES}
					multilanguage
				/>
				<MundoButton
					styles={{ zIndex: -1 }}
					dataSet={{ cy: 'record.addEssentialArticle.button' }}
					onPress={() => {
						articles.push(new MArticle(nextArticle));
						onChange(articles);
						onChangeNextArticle(undefined);
					}}
					icon={'plus'}
					disabled={!nextArticle}
				/>
			</View>
		</View>
	);
};
