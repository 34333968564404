import { MArticle } from 'mango-utils-client';
import React, { FC, memo, useContext } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { LanguageContext } from '../../../../utilities/contexts/Language';

import { useTheme } from '../../../../utilities/hooks/styles';
import { getTranslation } from '../../../../utilities/translations';
import { ISubarticleSelectEntryProps } from '../props';
import { articleEditStyles } from '../styles';

const SubarticleSelectEntry: FC<ISubarticleSelectEntryProps> = memo((props) => {
	const { subArticle, remove } = props;
	const { theme } = useTheme();
	const { language } = useContext(LanguageContext);

	return (
		<TouchableOpacity
			{...{
				dataSet: {
					cy: `${getTranslation(
						subArticle.title,
						language,
					)}.remove.button`,
				},
			}}
			style={articleEditStyles(theme).subArticle}
			onPress={() => remove(subArticle as MArticle)}
		>
			<Text>
				{subArticle.short +
					' ' +
					getTranslation(subArticle.title, language)}
			</Text>
		</TouchableOpacity>
	);
});

export default SubarticleSelectEntry;
