import React, { FC, useContext } from 'react';
import { Text, View } from 'react-native';
import { EPluign, getExtension } from '../../../Plugins';
import { numericDateOptions } from '../../../utilities/constants';
import { LanguageContext } from '../../../utilities/contexts/Language';

import { useStyle } from '../../../utilities/hooks/styles';
import { getTranslation } from '../../../utilities/translations';

import MundoText from '../../elements/MundoText';
import messages from '../messages';
import { IStateViewProps } from './props';

const StateView: FC<IStateViewProps> = ({ state, index }) => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);
	return (
		<View style={[styles.containerFullPercentageWidth]}>
			<View style={styles.containerFullWidthHorizontalNoPadding}>
				<View style={styles.rightMargin30}>
					<MundoText
						message={messages.module}
						bold
						styles={styles.pickerLabelSpacing}
					/>
					<MundoText
						message={{
							id: `module.${state.title
								.split('::')[0]
								.split('.')
								.reverse()
								.join('.')}`,
							defaultMessage: state.title,
						}}
					/>
				</View>
				<View style={styles.rightMargin30}>
					<MundoText
						message={messages.date}
						styles={styles.pickerLabelSpacing}
						bold
					/>
					<Text>
						{new Date(state.date).toLocaleDateString(
							'default',
							numericDateOptions,
						)}
					</Text>
				</View>
				<View style={styles.rightMargin30}>
					<MundoText
						message={messages.state}
						styles={styles.pickerLabelSpacing}
						bold
					/>
					<Text>{state.subState}</Text>
				</View>
				<View>
					<MundoText
						message={messages.user}
						styles={styles.pickerLabelSpacing}
						bold
					/>
					<Text>{state.user.title}</Text>
				</View>
			</View>
			<View
				style={[
					styles.containerFullWidthHorizontalNoPadding,
					styles.topMargin30,
				]}
			>
				<View>
					<MundoText message={messages.tags} />
					<Text>[{state.tags.join(', ')}]</Text>
				</View>
			</View>
			{state.packages && state.packages.length > 0 && (
				<View
					style={[
						styles.containerFullWidthHorizontalNoPadding,
						styles.topMargin30,
						styles.bottomMargin20,
					]}
				>
					<View>
						<MundoText message={messages.bookedPackages} />
						<Text>
							{state.packages
								.map((pack) =>
									getTranslation(pack.title, language),
								)
								.join(', ')}
						</Text>
					</View>
				</View>
			)}

			<View
				style={[
					styles.containerFullPercentageWidth,
					styles.topMargin10,
				]}
			>
				{Object.keys(EPluign).map((plugin) => {
					const Extension = getExtension(
						'StateView',
						plugin as EPluign,
					);

					if (Extension) {
						return (
							<Extension
								key={plugin}
								{...{
									stateIndex: index,
								}}
							/>
						);
					}
				})}
			</View>
		</View>
	);
};

export default StateView;
