import gql from 'graphql-tag';

export const GET_USER = gql`
	query getUser($id: String!) {
		user(id: $id) {
			id
			_id
			title
			classification
			mail
			telephone
			tags
			customer {
				_id
			}
			signature
		}
	}
`;

export const SAVE_USER = gql`
	mutation saveUser($user: UserInput!) {
		updateUser(user: $user) {
			id
			_id
		}
	}
`;
