import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { EModuleTypes, MModuleDescription } from 'mango-utils-client';
import { MundoButton } from '../../../components/elements';
import { useStyle, useTheme } from '../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../utilities/moduleTransformers';
import settingStyles from '../styles';
import messages from './messages';
import { PipelineEditContext } from './PipelineEdit';

export const ModuleSorter: FC = () => {
	const styles = useStyle();
	const { theme } = useTheme();
	const intl = useIntl();
	const hardcodedModules: MModuleDescription[] = [
		new MModuleDescription({
			identifier: 'receipt',
			tags: ['creator'],
		}),
		new MModuleDescription({
			identifier: 'record',
		}),
		new MModuleDescription({
			identifier: 'invoice',
		}),
		new MModuleDescription({
			identifier: 'sign',
			tags: ['reusable', ''],
			type: EModuleTypes.REUSABLE,
		}),
		new MModuleDescription({
			identifier: 'equipmentManager.vehicleCleaning',
			dependsOn: ['equipmentManager.vehicleCleaning'],
			tags: ['creator'],
		}),
		new MModuleDescription({
			identifier: 'metaData.vehicleCleaning',
			dependsOn: ['equipmentManager.vehicleCleaning'],
		}),
		new MModuleDescription({
			identifier: 'receipt.vehicleCleaning',
			dependsOn: [],
		}),
		new MModuleDescription({
			identifier: 'record.vehicleCleaning',
			dependsOn: ['receipt'],
		}),
		new MModuleDescription({
			identifier: 'record.lynx',
			dependsOn: ['receipt.vehicleCleaning'],
			type: EModuleTypes.SELF_EXECUTING,
		}),
		new MModuleDescription({
			identifier: 'invoice.lynx',
			dependsOn: ['invoice.lynx'],
			type: EModuleTypes.SELF_EXECUTING,
		}),
	];

	const { moduleList, setMissing, setModuleList } = React.useContext(
		PipelineEditContext,
	);
	/**
	 * effect to check for module depencies on pipeline sets missing message in context
	 */
	useEffect(() => {
		const seenModules: string[] = [];

		let reason = '';
		moduleList.some((module) => {
			seenModules.push(module.identifier);

			return !module.dependsOn.every((depend) => {
				const exists = seenModules.find((mod) => mod.includes(depend));
				if (!exists) {
					reason = `${module.identifier} ${intl.formatMessage(
						messages.couldNotFind,
					)}: ${depend}`;
				}
				return exists;
			});
		});
		setMissing(reason);
	}, [moduleList, setMissing, intl]);

	const setModulePressHandler = (module: MModuleDescription) => {
		const reusableIndex = module.tags.indexOf('reusable');
		const clone = new MModuleDescription(module);
		if (
			!moduleList.some((m) => m.title === module.identifier) ||
			reusableIndex > -1
		) {
			if (moduleList.length > 0 && reusableIndex > -1) {
				clone.tags[reusableIndex + 1] =
					moduleList[moduleList.length - 1].title;
			}
			setModuleList([...moduleList, clone]);
		}
	};

	const checkIsUsed = (module: MModuleDescription) => {
		const moduleToCheck = moduleList.find(
			(m: MModuleDescription) => m.identifier === module.identifier,
		);
		if (moduleToCheck) {
			return moduleToCheck.type === EModuleTypes.REUSABLE;
		}
		return true;
	};

	return (
		<View style={styles.horizontalLayout}>
			{hardcodedModules.map((module) => (
				<MundoButton
					dataSet={{ cy: `module.${module.identifier}.button` }}
					disabled={!checkIsUsed(module)}
					styles={settingStyles(theme).card}
					fontStyles={styles.primaryTextColor}
					key={module.identifier}
					onPress={() => setModulePressHandler(module)}
					title={transformModuleTitle(module)}
				/>
			))}
		</View>
	);
};
