import { defineMessages } from 'react-intl';

export const scope = 'mango.components.OrderCard';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID',
	},
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	occupied: {
		id: `${scope}.occupied`,
		defaultMessage: 'this order is being used by another logged in user',
	},
	confirm: {
		id: `${scope}.confirm`,
		defaultMessage: 'please confirm that you want to overtake it',
	},
});
