import { useQuery } from '@apollo/client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import {
	getTranslation,
	MModuleReceiptSettings,
	MPackage,
	MTranslation,
} from 'mango-utils-client';
import { ReceiptSettingsContext } from '../ReceiptSettings';
import { MundoText, MundoButton } from '../../../../components/elements';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { GET_RECEIPT_SETTINGS_PACKAGES } from '../queries';
import { receiptSettingsStyles } from '../../../../Plugins/VehicleCleaning/container/Receipt/Edit/styles';
import { LanguageContext } from '../../../../utilities/contexts/Language';
import { useArray } from '../../../../utilities/hooks/array';
import { useStyle, useTheme } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import { GET_PACKAGES } from '../queries';
import messages from '../messages';
import { alert } from '../../../../utilities/alert';
import { destructData } from '../../../../utilities/dataFunctions';

export const ReceiptSettingsPackageList: FC = () => {
	const styles = useStyle();
	const { theme } = useTheme();
	const { language } = useContext(LanguageContext);
	const intl = useIntl();
	const { onChangeReceiptSettings, pipeline } = useContext(
		ReceiptSettingsContext,
	);

	const [packages, dispatchPackageAction] = useArray<MPackage>();
	const [nextPack, onChangeNextPack] = useState(new MPackage());
	// get settings
	const { loading } = useQuery(GET_RECEIPT_SETTINGS_PACKAGES, {
		variables: {
			pipelineId: pipeline._id,
		},
		onCompleted: (data) => {
			if (data) {
				const sets = destructData<MModuleReceiptSettings>(data);
				console.log(sets);
				dispatchPackageAction({
					type: ETypes.RESET_TO,
					item: sets.packages || [],
				});
			}
		},
	});

	useEffect(() => {
		onChangeReceiptSettings({ packages });
	}, [packages, onChangeReceiptSettings]);

	return (
		<View style={styles.fullSize}>
			<MundoText message={messages.packages} />
			{loading ? (
				<LoadingIndicator />
			) : (
				packages.map((pack, index) => (
					<>
						<View
							key={`${pack._id}`}
							style={[
								{
									zIndex: packages.length - index,
								},
								receiptSettingsStyles(theme).list,
							]}
						>
							<MundoText
								styles={{ flex: 1 }}
								message={getTranslation(pack.title, language)}
							/>
							<View style={receiptSettingsStyles(theme).buttons}>
								<MundoButton
									icon={'up'}
									disabled={index === 0}
									onPress={() =>
										dispatchPackageAction({
											type: ETypes.SWAP_LEFT,
											location: index,
										})
									}
								/>
								<MundoButton
									icon={'down'}
									disabled={index === packages.length - 1}
									onPress={() =>
										dispatchPackageAction({
											type: ETypes.SWAP_RIGHT,
											location: index,
										})
									}
								/>
								<MundoButton
									icon={'minus'}
									onPress={() =>
										dispatchPackageAction({
											type: ETypes.REMOVE,
											location: index,
										})
									}
								/>
							</View>
						</View>
						<View style={styles.thinSeparator} />
					</>
				))
			)}
			<View style={[styles.elevated, receiptSettingsStyles(theme).list]}>
				<MundoObjectTypeahead
					cyId={`package.input`}
					value={nextPack}
					onChange={(newPackage) => {
						if (newPackage) {
							onChangeNextPack({
								...newPackage,
								title: newPackage.title as MTranslation[],
							} as MPackage);
						}
					}}
					QUERY={GET_PACKAGES}
					multilanguage
				/>
				<MundoButton
					dataSet={{ cy: 'receipt.pack.button' }}
					onPress={() => {
						if (
							nextPack._id &&
							!packages.find((p) => nextPack._id === p._id)
						) {
							dispatchPackageAction({
								type: ETypes.ADD,
								item: nextPack,
							});
						} else {
							alert(
								capitalizeFirstWord(
									intl.formatMessage(messages.denied),
								),
								capitalizeFirstWord(
									intl.formatMessage(messages.duplicate),
								),
								'error',
							);
						}
					}}
					title={messages.addPackage}
					disabled={!nextPack._id}
				/>
			</View>
		</View>
	);
};
