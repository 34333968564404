import { defineMessages } from 'react-intl';

export const scope = 'mango.components.package.edit';

export default defineMessages({
	header: {
		id: `${scope}.header`,
		defaultMessage: 'edit package',
	},
	title: {
		id: `${scope}.title`,
		defaultMessage: 'title',
	},
	company: {
		id: `${scope}.company`,
		defaultMessage: 'company',
	},
	description: {
		id: `${scope}.description`,
		defaultMessage: 'description',
	},
	price: {
		id: `${scope}.price`,
		defaultMessage: 'price',
	},
	calculation: {
		id: `${scope}.calculation`,
		defaultMessage: 'calculation function',
	},
	trigger: {
		id: `${scope}.trigger`,
		defaultMessage: 'trigger function',
	},
	calculationBundle: {
		id: `${scope}.calculationBundle`,
		defaultMessage: 'calculation article bundle',
	},
	editCalculationBundle: {
		id: `${scope}.editCalculationBundle`,
		defaultMessage: 'edit calculation article bundle',
	},
	bundledArticleError: {
		id: `${scope}.bundledArticleError`,
		defaultMessage: 'no articles in bundled articles',
	},
	DEFAULT: {
		id: `${scope}.DEFAULT`,
		defaultMessage: 'default',
	},
	COMBINE_ITEMS: {
		id: `${scope}.COMBINE_ITEMS`,
		defaultMessage: 'combine items',
	},
	GENERATE_EXTRA_TIME_ITEM: {
		id: `${scope}.GENERATE_EXTRA_TIME_ITEM`,
		defaultMessage: 'generate extra time item',
	},
	REDUCE_AMOUNT_OF_ITEM: {
		id: `${scope}.REDUCE_AMOUNT_OF_ITEM`,
		defaultMessage: 'reduce amount of item',
	},
	WORK_TIME_IN_AW: {
		id: `${scope}.WORK_TIME_IN_AW`,
		defaultMessage: 'record time in AW',
	},
	GENERATE_SILO_TIME: {
		id: `${scope}.GENERATE_SILO_TIME`,
		defaultMessage: 'silo function',
	},
	TRIGGER_PRICE_LEVEL: {
		id: `${scope}.TRIGGER_PRICE_LEVEL`,
		defaultMessage: 'price level',
	},
	TRIGGER_PRICE: {
		id: `${scope}.TRIGGER_PRICE`,
		defaultMessage: 'basic price',
	},
	tags: {
		id: `${scope}.tags`,
		defaultMessage: 'tags',
	},
	addArticleBundle: {
		id: `${scope}.addArticleBundle`,
		defaultMessage: 'add article bundle',
	},
});
