import React, { FC } from 'react';

import GroupInvoiceEdit from './edit';
import { IGroupInvoiceProps } from './props';
import GroupInvoiceView from './view';

/**
 * TItems selector based on type.
 * @param props TItems props
 */
const GroupInvoice: FC<IGroupInvoiceProps> = ({ groupInvoice, onChange }) => {
	switch (groupInvoice._id) {
		case 'new':
			return (
				<GroupInvoiceEdit
					groupInvoice={groupInvoice}
					onChange={onChange}
				/>
			);
		default:
			return (
				<GroupInvoiceView
					groupInvoice={groupInvoice}
					onChange={onChange}
				/>
			);
	}
};

export default GroupInvoice;
