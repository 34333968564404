import { defineMessages } from 'react-intl';

export const scope = 'mango.module.vehicleCleaning.TRecordDashboard';
export const errorScope = 'error';

export default defineMessages({
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID',
	},
	state: {
		id: `${scope}.state`,
		defaultMessage: 'state',
	},
	pipeline: {
		id: `${scope}.pipeline`,
		defaultMessage: 'pipeline',
	},
	substate: {
		id: `${scope}.substate`,
		defaultMessage: 'substate',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	pdf: {
		id: `${scope}.pdf`,
		defaultMessage: 'PDF',
	},
	use: {
		id: `${scope}.use`,
		defaultMessage: 'use',
	},
	lane: {
		id: `${scope}.lane`,
		defaultMessage: 'lane',
	},
	classifications: {
		id: `${scope}.classifications`,
		defaultMessage: 'classifications',
	},
	trailerTypes: {
		id: `${scope}.trailerTypes`,
		defaultMessage: 'trailer types',
	},
	tags: {
		id: `${scope}.tags`,
		defaultMessage: 'tags',
	},
	selectLane: {
		id: `${scope}.selectLane`,
		defaultMessage: '-Select Lane-',
	},
	occupied: {
		id: `${scope}.occupied`,
		defaultMessage: 'this order is being used by another user',
	},
	confirm: {
		id: `${scope}.confirm`,
		defaultMessage: 'please confirm that you want to take it over',
	},
	error: {
		id: `${errorScope}.error`,
		defaultMessage: 'error',
	},
});
