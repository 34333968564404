import { MTranslation, MPage, MArticle } from 'mango-utils-client';
import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { MundoButton } from '../../../../components/elements';
import MundoInput from '../../../../components/elements/MundoInput';
import MundoObjectTypeahead from '../../../../components/elements/MundoObjectTypeahead';
import { RecordSettingsStyles } from '../../../../Plugins/VehicleCleaning/container/Record/Edit/styles';
import { useStyle, useTheme } from '../../../../utilities/hooks/styles';
import { ETypes } from '../../../../utilities/reducer/array.reducer';
import messages from '../messages';
import { GET_ARTICLES } from '../queries';
import { PageArticle } from './PageArticle';
import { IPagesProps } from './props';

export const PagesEdit: FC<IPagesProps> = ({ pages, dispatchPageAction }) => {
	const styles = useStyle();
	const { theme } = useTheme();

	const [nextArticle, onChangeNextArticle] = useState<MArticle>();

	const [siteIndex, onChangeSiteIndex] = useState(0);

	return (
		<View>
			{/* pages creation */}
			<MundoButton
				dataSet={{ cy: 'record.addPage.button' }}
				onPress={() =>
					dispatchPageAction({
						type: ETypes.ADD,
						item: new MPage({ title: 'new' }),
					})
				}
				title={messages.addPage}
			/>
			{pages[siteIndex] && (
				<View style={styles.elevated}>
					<MundoInput
						dataSet={{ cy: 'record.pageTitle.input' }}
						label={messages.pageTitle}
						onChangeText={(input) =>
							dispatchPageAction({
								type: ETypes.EDIT,
								location: siteIndex,
								item: {
									...pages[siteIndex],
									title: input,
								},
							})
						}
						value={pages[siteIndex].title}
					/>
					<View style={styles.elevated}>
						<View style={styles.elevated}>
							{pages[siteIndex].articles.map((article, index) => (
								<PageArticle
									key={`${article._id}.${index}`}
									pages={pages}
									index={index}
									siteIndex={siteIndex}
									article={article}
									dispatchPageAction={dispatchPageAction}
								/>
							))}
							<View style={styles.horizontalLayout}>
								<MundoObjectTypeahead
									cyId={'article.new.input'}
									value={nextArticle}
									onChange={(newArticle) => {
										if (newArticle) {
											onChangeNextArticle(
												new MArticle({
													...newArticle,
													title: newArticle.title as MTranslation[],
												}),
											);
										}
									}}
									QUERY={GET_ARTICLES}
									multilanguage
								/>
								<MundoButton
									dataSet={{ cy: 'add.article.to.page' }}
									icon={'plus'}
									onPress={() => {
										if (nextArticle) {
											const newArticles =
												pages[siteIndex].articles;
											newArticles.push(nextArticle);
											pages[
												siteIndex
											].articles = newArticles;
											dispatchPageAction({
												type: ETypes.NOTIFY,
											});
										}
									}}
								/>
							</View>
						</View>
					</View>
				</View>
			)}
			{/* PAGINATION */}
			<ScrollView horizontal>
				<View style={styles.buttonGroup}>
					<MundoButton
						dataSet={{ cy: 'record.left.button' }}
						icon={'left'}
						onPress={() => onChangeSiteIndex(siteIndex - 1)}
						disabled={siteIndex <= 0}
					/>
					{pages.map((page, index) => (
						<View key={`pagenavbutton${page.title + index}`}>
							<MundoButton
								dataSet={{ cy: 'record.page.button' }}
								onPress={() => onChangeSiteIndex(index)}
								title={{ id: page.title || 'new' }}
								styles={
									index === siteIndex && {
										backgroundColor:
											theme.backgroundSecondaryColor,
									}
								}
							/>
							<View style={RecordSettingsStyles(theme).buttons}>
								<MundoButton
									icon={'left'}
									onPress={() =>
										dispatchPageAction({
											type: ETypes.SWAP_LEFT,
											location: index,
										})
									}
								/>
								<MundoButton
									icon={'minus'}
									onPress={() =>
										dispatchPageAction({
											type: ETypes.REMOVE,
											location: index,
										})
									}
								/>
								<MundoButton
									icon={'right'}
									onPress={() =>
										dispatchPageAction({
											type: ETypes.SWAP_RIGHT,
											location: index,
										})
									}
								/>
							</View>
						</View>
					))}
					<MundoButton
						dataSet={{ cy: 'record.right.button' }}
						icon={'right'}
						onPress={() => onChangeSiteIndex(siteIndex + 1)}
						disabled={siteIndex >= pages.length - 1}
					/>
				</View>
			</ScrollView>
		</View>
	);
};
