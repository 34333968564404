import React, { FC, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import { MTPreproduct } from 'mango-utils-client';
import messages from './messages';
import { IPreproductFilter } from './props';
import MundoInput from '../../../../../components/elements/MundoInput';
import useDebounce from '../../../../../utilities/hooks/debounce';
import { useStyle } from '../../../../../utilities/hooks/styles';

export const PreproductFilter: FC<IPreproductFilter> = (props) => {
	const styles = useStyle();
	const [last, onChangeLast] = useState<MTPreproduct>();
	const [preproduct, onChangePreproduct] = useState<MTPreproduct>(
		new MTPreproduct(),
	);

	const debouncedSearchTerm = useDebounce(preproduct);

	const loadCB = useCallback((next) => props.onChange(next), [props]);

	const update = (next: Partial<MTPreproduct>) => {
		onChangePreproduct(new MTPreproduct({ ...preproduct, ...next }));
	};

	useEffect(() => {
		if (debouncedSearchTerm && debouncedSearchTerm !== last) {
			onChangeLast(debouncedSearchTerm);
			loadCB(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm, loadCB, last]);

	return (
		<View style={styles.verticalLayout}>
			<MundoInput
				dataSet={{ cy: 'preproduct.filter.title' }}
				style={{ marginLeft: 12 }}
				horizontal
				label={messages.title}
				onChangeText={(title: string) =>
					update({
						title,
					})
				}
				value={preproduct.title}
			/>
		</View>
	);
};

export default PreproductFilter;
