import { defineMessages } from 'react-intl';

export const scope = 'mango.components.Login';
export const errorScope = 'error';

export default defineMessages({
	user: {
		id: `${scope}.user.username`,
		defaultMessage: 'username',
	},
	password: {
		id: `${scope}.user.password`,
		defaultMessage: 'password',
	},
	login: {
		id: `${scope}.auth.login`,
		defaultMessage: 'login',
	},
	invalidUserPassword: {
		id: `${errorScope}.invalidUserPassword`,
		defaultMessage: 'wrong username or password',
	},
	disabledAccount: {
		id: `${errorScope}.disabledAccount`,
		defaultMessage: 'this account has been disabled',
	},
	failedLogins: {
		id: `${errorScope}.failedLogins`,
		defaultMessage:
			'too many failed logins: your account has been disabled',
	},
});
