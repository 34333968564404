import gql from 'graphql-tag';

export const GET_ARTICLES = gql`
	query getArticles {
		articles {
			_id
			id
			title {
				lang
				message
			}
			price
		}
	}
`;

export const RECEIPT_OUT = gql`
	mutation receiptOut($state: StateInput!, $pipelineId: String!) {
		receiptOut(receipt: { state: $state, pipelineId: $pipelineId })
	}
`;

export const RECEIPT_SETTINGS = gql`
	query receiptSettings($pipelineId: String!) {
		receiptSettings(pipelineId: $pipelineId) {
			id
			_id
			title
			dashboardFields
			articles {
				title {
					lang
					message
				}
				short
				id
				_id
				price
				unit
				dataUnit {
					lang
					message
				}
				essentials
				tax
				subArticles {
					title {
						lang
						message
					}
					short
					id
					_id
					price
					unit
					dataUnit {
						lang
						message
					}
					essentials
					tax
				}
			}
			packages {
				_id
				id
				title {
					lang
					message
				}
				companyId
				includedArticles {
					articles {
						id
						_id
						title {
							lang
							message
						}
						description {
							lang
							message
						}
					}
					useAmount
					useUnderflowPrice
					useOverflowPrice
					lowerAmount
					amount
					upperAmount
					underflowPrice
					overflowPrice
				}
			}
		}
	}
`;
