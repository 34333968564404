import { getTranslation } from 'mango-utils-client';
import React, { FC, useContext } from 'react';
import { View } from 'react-native';
import { EPluign, getExtension } from '../../../Plugins';
import { LanguageContext } from '../../../utilities/contexts/Language';

import { useStyle } from '../../../utilities/hooks/styles';
import MundoText from '../../elements/MundoText';
import { IItemViewProps } from './../props';

const ItemView: FC<IItemViewProps> = ({ value: item }) => {
	const styles = useStyle();
	const { language } = useContext(LanguageContext);
	return (
		<View style={[styles.wideGroupTable]}>
			<MundoText
				styles={[styles.wideGroupTableColumn10, { maxWidth: 70 }]}
			>
				{item.price}
			</MundoText>
			<MundoText
				styles={[styles.wideGroupTableColumn10, { maxWidth: 70 }]}
			>
				{item.amount}
			</MundoText>
			<MundoText styles={styles.wideGroupTableColumn30}>
				{getTranslation(item.title, language)}
			</MundoText>
			{Object.keys(EPluign).map((plugin) => {
				const Extension = getExtension('ItemView', plugin as EPluign);

				if (Extension) {
					return (
						<Extension
							key={plugin}
							{...{
								item,
							}}
						/>
					);
				}
			})}
		</View>
	);
};

export default ItemView;
