import { defineMessages } from 'react-intl';

export const scope = 'mango.statistics.timeConfigurator';

export default defineMessages({
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	from: {
		id: `${scope}.from`,
		defaultMessage: 'from',
	},
	to: {
		id: `${scope}.to`,
		defaultMessage: 'to',
	},
	interval: {
		id: `${scope}.interval`,
		defaultMessage: 'interval',
	},
	entrys: {
		id: `${scope}.entrys`,
		defaultMessage: 'entrys',
	},
	presets: {
		id: `${scope}.presets`,
		defaultMessage: 'presets',
	},
	lastWeek: {
		id: `${scope}.lastWeek`,
		defaultMessage: 'last week',
	},
	lastMonth: {
		id: `${scope}.lastMonth`,
		defaultMessage: 'last month',
	},
	lastYear: {
		id: `${scope}.lastYear`,
		defaultMessage: 'last year',
	},
	compare: {
		id: `${scope}.compare`,
		defaultMessage: 'compare to previous data',
	},
});
