import React, { FC, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import { MModuleDescription } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../../../components/elements';
import CustomerList from '../../../../../container/Customer/list';
import { IDashboardProps } from '../../../../../container/Dashboard/props';
import { useStyle } from '../../../../../utilities/hooks/styles';
import { transformModuleTitle } from '../../../../../utilities/moduleTransformers';
import { openDocs } from '../../../../../utilities/openDocs';
import { useHistory, useParams } from '../../../../../utilities/routing';
import DriverList from '../../Driver/list';
import TrailerList from '../../Trailer/list';
import TruckList from '../../Truck/list';
import messages from './messages';

const tabs = [
	messages.companies,
	messages.trailer,
	messages.truck,
	messages.driver,
];

/**
 * Dashboard for different equipments
 * TODO: pagination, filtered queries
 */
const EquipmentManagerDashboard: FC<IDashboardProps> = (props) => {
	const styles = useStyle();
	const [filter, setFilter] = useState<string>(tabs[0].id);
	const { pipelineId } = useParams<{ pipelineId: string }>();

	const history = useHistory();
	/**
	 * filter which list to display
	 * @param filterMode filter to work with
	 */
	const selectTable = (filterMode: string) => {
		switch (filterMode) {
			case messages.companies.id:
				return <CustomerList sub />;
			case messages.driver.id:
				return <DriverList sub />;
			case messages.trailer.id:
				return <TrailerList sub />;
			case messages.truck.id:
				return <TruckList sub />;
			default:
				return (
					<View style={styles.headerView}>
						<MundoText message={messages.description} />
					</View>
				);
		}
	};
	// render
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<View style={styles.headerTitleContainer}>
					{props.pipeline && (
						<MundoText
							message={transformModuleTitle(
								props.pipeline.modules.find(
									(m) =>
										m.identifier ===
										'equipmentManager.vehicleCleaning',
								) || new MModuleDescription(),
								true,
							)}
							styles={styles.headerText}
						/>
					)}
					<MundoButton
						icon={'question'}
						subtype="transparent"
						onPress={() =>
							openDocs(
								'#/content/vehicleCleaning/module/equipmentmanager',
							)
						}
					/>
				</View>
				<MundoButton
					dataSet={{ cy: 'equipmentManager.create' }}
					title={messages.createNewOrder}
					icon={'plus'}
					onPress={() =>
						history.push(
							`/module/${pipelineId}/equipmentManager.vehicleCleaning/new`,
						)
					}
				/>
			</View>
			<ScrollView>
				<View style={styles.spacedContainer}>
					<View style={styles.containerFullWidthHorizontalNoPadding}>
						{tabs.map((tab) => {
							return (
								<TouchableOpacity
									{...{
										dataSet: {
											cy: `filter.${tab.defaultMessage}`,
										},
									}}
									key={tab.id}
									activeOpacity={0.8}
									style={[
										styles.tabs,
										tab.id === filter && styles.selectedtab,
									]}
									onPress={() => setFilter(tab.id)}
								>
									<MundoText
										message={tab}
										styles={
											tab.id === filter
												? styles.textSelectedTab
												: styles.textTab
										}
									/>
								</TouchableOpacity>
							);
						})}
					</View>
					{selectTable(filter)}
				</View>
			</ScrollView>
		</View>
	);
};

export default EquipmentManagerDashboard;
