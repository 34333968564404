import { encode } from 'base-64';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { TextInput, View } from 'react-native';

import { MundoButton, MundoText } from '../../../components/elements';
import { alert } from '../../../utilities/alert';
import { config } from '../../../utilities/config';
import { AuthContext } from '../../../utilities/hooks/auth';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import messages from './messages';

const PasswordChange: FC = () => {
	const history = useHistory();
	const styles = useStyle();

	const [oldPassword, onChangeOldPassword] = React.useState('');
	const [newPassword, onChangeNewPassword] = React.useState('');
	const [confirmPassword, onChangeConfirmPassword] = React.useState('');
	const { user } = useContext(AuthContext);
	const intl = useIntl();

	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<MundoText
					message={messages.header}
					styles={styles.headerText}
				/>
			</View>
			<View style={styles.spacedContainer}>
				<View style={styles.containerHalfWidthNoPadding}>
					<TextInput
						placeholder={capitalizeFirstWord(
							intl.formatMessage(messages.oldPassword),
						)}
						style={styles.input}
						secureTextEntry={true}
						onChangeText={(text: string) =>
							onChangeOldPassword(text)
						}
						value={oldPassword}
					/>
					<TextInput
						placeholder={capitalizeFirstWord(
							intl.formatMessage(messages.newPassword),
						)}
						style={styles.input}
						secureTextEntry={true}
						onChangeText={(text: string) =>
							onChangeNewPassword(text)
						}
						value={newPassword}
					/>
					{newPassword !== confirmPassword && (
						<MundoText
							styles={styles.warning}
							message={messages.passwordMissmatch}
						/>
					)}
					<TextInput
						placeholder={capitalizeFirstWord(
							intl.formatMessage(messages.confirmPassword),
						)}
						style={styles.input}
						secureTextEntry={true}
						onChangeText={(text) => onChangeConfirmPassword(text)}
						value={confirmPassword}
					/>
				</View>
				<View style={styles.containerFullWidthHorizontalNoPadding}>
					<MundoButton
						title={messages.cancel}
						styles={styles.neutralButton}
						onPress={() => history.goBack()}
					/>
					<View
						style={{
							position: 'absolute',
							right: 0,
						}}
					>
						<MundoButton
							title={messages.passwordChange}
							styles={styles.successButton}
							onPress={async () => {
								const postOptions = {
									headers: {
										Authorization:
											'Basic ' +
											encode(
												user.title + ':' + newPassword,
											),
									},
									body: JSON.stringify({
										old: encode(oldPassword),
									}),
									method: 'POST',
								};
								try {
									const fetchResult = await fetch(
										`${config.API_URL}/loggedInResetPassword`,
										postOptions,
									);
									if (fetchResult.status > 201) {
										return alert(
											`${fetchResult.status}`,
											capitalizeFirstWord(
												intl.formatMessage(
													messages.wrongPasswordError,
												),
											),
											'error',
										);
									}
									const response = await fetchResult.json();
									alert(
										response.message,
										capitalizeFirstWord(
											intl.formatMessage(
												messages.passwordChanged,
											),
										),
										'success',
									);
								} catch (e) {
									alert(
										capitalizeFirstWord(
											intl.formatMessage(messages.error),
										),
										e,
										'error',
									);
								}
							}}
						/>
					</View>
				</View>
			</View>
		</View>
	);
};

export default PasswordChange;
