import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryStack,
} from 'victory';

import { MundoText } from '../../../../components/elements';
import { capitalizeFirstWord } from '../../../../utilities/stringFunctions';
import { constructComperatorSet } from '../../utilities/constructComperatorSet';
import messages from './messages';
import { IChartProps } from './props';

export const Chart: FC<IChartProps> = ({
	selectedEntities,
	entityColors,
	compare,
	global,
	data,
	pastData,
	entrys,
	formatTick,
	yLabel,
}) => {
	const intl = useIntl();

	const comparatorSet = useMemo(() => {
		if (compare && pastData.general.length === data.general.length) {
			return constructComperatorSet(data.general, pastData.general);
		}
		return [data.general];
	}, [data, pastData, compare]);

	const barCount = selectedEntities.length + (global ? 1 : 0);
	return (
		<View>
			{barCount > 8 ? (
				<MundoText message={messages.tooManyBars} />
			) : barCount <= 0 ? (
				<MundoText message={messages.nothingToDisplay} />
			) : (
				<VictoryChart
					// animate={true}
					domainPadding={20}
				>
					<VictoryGroup offset={10}>
						{global && (
							<VictoryStack>
								<VictoryBar
									data={comparatorSet[0]}
									style={{ data: { fill: 'green' } }}
								/>
								{compare && (
									<VictoryBar
										data={comparatorSet[1]}
										style={{ data: { fill: 'red' } }}
									/>
								)}
							</VictoryStack>
						)}
						{selectedEntities.map((sC, index) => {
							const d =
								data.byRef.find((a) => a.ref._id === sC._id)
									?.data || [];
							const pd = [
								...(pastData.byRef.find(
									(a) => a.ref._id === sC._id,
								)?.data || []),
							];
							const stack =
								compare && d.length === pd.length
									? constructComperatorSet(d, pd)
									: [d];
							return (
								<VictoryStack key={sC._id}>
									{stack.map((v, i) => {
										return (
											<VictoryBar
												key={i}
												data={v}
												style={{
													labels: {
														fontSize: 5,
														zIndex: 100,
													},
													data: {
														fill: i
															? 'red'
															: entityColors[
																	index
															  ],
													},
												}}
											/>
										);
									})}
								</VictoryStack>
							);
						})}
					</VictoryGroup>
					<VictoryAxis
						tickFormat={(x) => formatTick(x)}
						style={{
							tickLabels: {
								angle: 45,
								verticalAnchor: 'middle',
								textAnchor: 'start',
								padding: 5,
								fontSize: 10,
							},
						}}
						tickCount={
							Math.round(isNaN(+entrys) ? 1 : +entrys) || 1
						}
					/>
					<VictoryAxis
						dependentAxis
						label={capitalizeFirstWord(intl.formatMessage(yLabel))}
						style={{
							axisLabel: {
								padding: 40,
								fontSize: 10,
							},
						}}
					/>
				</VictoryChart>
			)}
		</View>
	);
};
