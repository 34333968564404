import { defineMessages } from 'react-intl';

export const scope = 'mango.customer.card';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'company',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'ID',
	},
	street: {
		id: `${scope}.street`,
		defaultMessage: 'street',
	},
	city: {
		id: `${scope}.city`,
		defaultMessage: 'city',
	},
	country: {
		id: `${scope}.country`,
		defaultMessage: 'country',
	},
	ustId: {
		id: `${scope}.ustId`,
		defaultMessage: 'VAT ID',
	},
	mail: {
		id: `${scope}.mail`,
		defaultMessage: 'e-mail',
	},
	disabled: {
		id: `${scope}.disabled`,
		defaultMessage: 'disabled',
	},
});
