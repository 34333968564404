import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useParams } from 'react-router-dom';

import { MGroup } from 'mango-utils-client';
import { MundoText } from '../../../components/elements';
import MundoInput from '../../../components/elements/MundoInput';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import { useStyle } from '../../../utilities/hooks/styles';
import { useHistory } from '../../../utilities/routing';
import messages from './messages';
import { DELETE_GROUP, GET_GROUP, SAVE_GROUP } from './queries';

const GroupEdit: FC = () => {
	const styles = useStyle();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();

	const [group, onChangeGroup] = useState(new MGroup());

	useQuery(GET_GROUP, {
		variables: { id },
		skip: !id || id === 'new',
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			if (data) {
				onChangeGroup(data.group);
			}
		},
	});

	const [saveGroup] = useMutation(SAVE_GROUP);
	const [deleteGroup] = useMutation(DELETE_GROUP);

	const update = (input: Partial<any>) => {
		const next = Object.assign({}, group, input);
		onChangeGroup(next);
	};

	const saveButtonHandler = async () => {
		await saveGroup({ variables: { group } });
		history.goBack();
	};

	const deleteButtonHandler = async () => {
		await deleteGroup({ variables: { id } });
		history.goBack();
	};

	return (
		<View
			{...{ dataSet: { cy: 'GroupEdit' } }}
			style={styles.containerFullResolution}
		>
			<View style={styles.headerView}>
				<MundoText
					message={messages.header}
					styles={styles.headerText}
				/>
			</View>
			<View style={styles.spacedContainer}>
				<ScrollView>
					<View style={styles.containerFullWidthNoPadding}>
						<MundoInput
							dataSet={{ cy: 'group.title.input' }}
							label={messages.title}
							onChangeText={(inputTitle: string) =>
								update({ title: inputTitle })
							}
							value={group.title}
						/>
						<MundoInput
							dataSet={{ cy: 'group.inheritance.input' }}
							label={messages.inheritance}
							onChangeText={(t: string) =>
								update({ inheritance: !isNaN(+t) ? +t : 0 })
							}
							value={`${group.inheritance}`}
							length={5}
						/>
					</View>
				</ScrollView>
			</View>

			<FinishButtonGroup
				cyId={'group'}
				saveFunction={saveButtonHandler}
				deleteFunction={deleteButtonHandler}
				cancelFunction={() => history.goBack()}
			/>
		</View>
	);
};

export default GroupEdit;
