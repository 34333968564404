import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MTDriver } from 'mango-utils-client';
import { GET_DRIVERS } from './queries';

interface IUseDriversProps {
	skip: boolean;
	orderBy: string | undefined;
}

export const useDrivers = (props: IUseDriversProps) => {
	const [drivers, onChangeDrivers] = useState<MTDriver[]>([]);
	const [filter, onChangeFilter] = useState<MTDriver>();
	const [loadMoreActive, setLoadMoreActive] = useState<boolean>(false);
	const [reset, onChangeReset] = useState<boolean>(false);
	const variables = useMemo(() => {
		return {
			driver: filter,
			start: drivers.length,
			count: 20,
			orderBy: props.orderBy,
		};
	}, [filter, drivers, props.orderBy]);
	// HERE ALL INITAL LOADING HAPPENS
	const [lazyQuery, { loading }] = useLazyQuery(GET_DRIVERS, {
		onCompleted: (data) => {
			if (data) {
				if (!data.tDrivers.length || data.tDrivers.length % 20 !== 0) {
					setLoadMoreActive(false);
				}
				if (reset) {
					onChangeDrivers(data.tDrivers);
					onChangeReset(false);
				} else {
					onChangeDrivers([...drivers, ...data.tDrivers]);
				}
			}
		},
		variables,
		...props,
	});

	const loadMore = useCallback(() => {
		lazyQuery({ variables });
	}, [lazyQuery, variables]);

	useEffect(() => {
		setLoadMoreActive(true);
		onChangeReset(true);
		lazyQuery({
			variables: {
				driver: filter,
				count: 20,
				start: 0,
				orderBy: props.orderBy,
			},
		});
	}, [filter, lazyQuery, props.orderBy]);

	return { drivers, loading, loadMore, onChangeFilter, loadMoreActive };
};
