import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Picker, ScrollView, View } from 'react-native';

import { MState, MUser, range } from 'mango-utils-client';
import { MundoButton, MundoText } from '../../../components/elements';
import FinishButtonGroup from '../../../components/FinishButtonGroup';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PDFViewer from '../../../components/PDFViewer';
import SignaturePad from '../../../components/SignaturePad';
import { alert } from '../../../utilities/alert';
import { config } from '../../../utilities/config';
import { useArray } from '../../../utilities/hooks/array';
import { AuthContext } from '../../../utilities/hooks/auth';
import { SettingsContext } from '../../../utilities/hooks/settings';
import { useStyle } from '../../../utilities/hooks/styles';
import { ETypes } from '../../../utilities/reducer/array.reducer';
import { useHistory, useParams } from '../../../utilities/routing';
import { capitalizeFirstWord } from '../../../utilities/stringFunctions';
import messages from './messages';
import {
	GET_USER_SIGNATURE,
	SIGN,
	SIGN_MODULE_IN,
	SIGN_MODULE_OUT,
} from './queries';
/**
 * signature model. used to sign documents
 * TODO: Document Display
 */
const SignModule: FC = () => {
	const styles = useStyle();
	const intl = useIntl();
	const { user, isAdmin } = useContext(AuthContext);
	const { settings } = useContext(SettingsContext);
	const { id, pipelineId } = useParams<{ id: string; pipelineId: string }>();
	const history = useHistory();
	const [signatures, dispatchSignaturesAction] = useArray<string>();
	const [pdfToSign, onChangePdfToSign] = useState<string[]>([]);
	const [prevState, onChangePrevState] = useState<MState>();
	const [signPositions, onChangeSignPositions] = useState<
		Array<{ position: number; title: string; level: number }>
	>([]);
	const [selectedPosition, onChangeSelectedPosition] = useState<number>(0);
	const [finish] = useMutation(SIGN_MODULE_OUT);
	const [sign] = useMutation(SIGN);
	const [selectedPDF, onChangeSelectedPDF] = useState<string>('');
	const [numId, onChangeNumId] = useState<number>();

	const { data } = useQuery<{ user: MUser }>(GET_USER_SIGNATURE, {
		skip: !user._id,
		variables: { id: user._id },
		fetchPolicy: 'cache-and-network',
	});

	// TODO: improve this
	const level = user.customer._id === settings.host._id || isAdmin ? 1 : 0;

	const save = async () => {
		if (prevState) {
			const newSigns = signatures.filter((s) => s);
			const registeredIndex: number[] = [];
			const newPositions = newSigns.map((s) => {
				const index = signatures.findIndex(
					(sig, i) => sig === s && !registeredIndex.includes(i),
				);
				if (index > -1) {
					registeredIndex.push(index);
				}
				return index;
			});
			await Promise.all(
				pdfToSign.map(async (pdf) => {
					await sign({
						variables: {
							pdf,
							orderId: id,
							signatures: newSigns,
							positions: newPositions,
						},
					});
				}),
			);
			await finish({
				variables: {
					state: new MState({ title: `sign::${prevState.title}` }),
					orderId: id,
					pipelineId,
					pdfs: pdfToSign,
				},
			});
			alert(
				capitalizeFirstWord(intl.formatMessage(messages.signSucess)),
				capitalizeFirstWord(intl.formatMessage(messages.signProceed)),
				'success',
			);
			history.push('/dash/' + pipelineId + '/default');
		}
	};
	const { loading } = useQuery(SIGN_MODULE_IN, {
		variables: { id, pipelineId },
		onCompleted: (response) => {
			if (response && response.moduleSignIn) {
				const state = new MState(response.moduleSignIn.state);
				onChangePrevState(state);
				const pdfs = state.tags
					.filter((tag) => tag.split(':')[0] === 'pdf')
					.map((tag) => tag.split(':')[1]);
				onChangePdfToSign(pdfs);
				onChangeSelectedPDF(pdfs[0]);
				const posTags = state.tags
					.filter((tag) => tag.includes('position'))
					.map((tag) => JSON.parse(tag));
				const positionsToSign: Array<{
					title: string;
					position: number;
					level: number;
				}> = posTags;
				let highestPosition = 0;
				// split the tags into tags that still require signing (positionsToSign) and tags that are signed (positionsDone)
				posTags.forEach((tag) => {
					if (tag.position > highestPosition) {
						highestPosition = tag.position;
					}
				});
				const availablePositions = positionsToSign.filter(
					(pos) =>
						(!pos.level && pos.level !== 0) || pos.level <= level,
				);
				if (availablePositions.length === 0) {
					alert(
						capitalizeFirstWord(
							intl.formatMessage(messages.noSign),
						),
						capitalizeFirstWord(
							intl.formatMessage(messages.noAuth),
						),
						'error',
					);
					history.goBack();
					return;
				}
				dispatchSignaturesAction({
					type: ETypes.RESET_TO,
					item: range(highestPosition).map(() => ''),
				});
				onChangeSelectedPosition(availablePositions[0].position);
				onChangeSignPositions(availablePositions);
				onChangeNumId(response.moduleSignIn.id || 0);
			}
		},
	});
	useEffect(() => {
		onChangeSelectedPosition(signPositions[0]?.position);
	}, [signPositions]);
	// LOADING
	if (loading) {
		return <LoadingIndicator />;
	}
	/**
	 * render
	 */
	return (
		<View style={styles.containerFullResolution}>
			<View style={styles.headerView}>
				<MundoText
					styles={styles.headerText}
					message={messages.title}
				/>
				{!!numId && (
					<MundoText styles={styles.orderIdHeader}>{numId}</MundoText>
				)}
			</View>
			<ScrollView style={styles.fullSize}>
				<View style={styles.spacedContainer}>
					<Picker
						{...{ dataSet: { cy: 'sign.picker' } }}
						style={[styles.picker, styles.pickerMaxWidth]}
						onValueChange={(value) =>
							onChangeSelectedPosition(+value)
						}
						selectedValue={selectedPosition}
					>
						{signPositions.map((pos) => (
							<Picker.Item
								key={`sign.position.${pos.position}`}
								label={`${pos.title}`}
								value={`${pos.position}`}
							/>
						))}
					</Picker>
					<View style={styles.horizontalLayout}>
						<SignaturePad
							value={signatures[selectedPosition]}
							onChange={(s) =>
								dispatchSignaturesAction({
									type: ETypes.EDIT,
									item: s,
									location: selectedPosition,
								})
							}
							height={200}
							width={400}
						/>
					</View>
					{data && !!data.user.signature && (
						<View style={styles.buttonGroupLeft}>
							<MundoButton
								title={messages.clear}
								onPress={() =>
									dispatchSignaturesAction({
										type: ETypes.EDIT,
										item: '',
										location: selectedPosition,
									})
								}
							/>
							<MundoButton
								title={messages.applySavedSignature}
								onPress={() =>
									dispatchSignaturesAction({
										type: ETypes.EDIT,
										item: data.user.signature,
										location: selectedPosition,
									})
								}
							/>
						</View>
					)}
					<FinishButtonGroup
						cyId={'Sign'}
						saveDisabled={!signatures.find((s) => s)}
						saveFunction={save}
						cancelFunction={() => history.goBack()}
					/>
				</View>
				<View style={styles.spacedContainer}>
					<View style={styles.horizontalLayout}>
						{pdfToSign.map((pdf) => (
							<MundoButton
								key={pdf} // pdf names should usually be unique
								styles={selectedPDF === pdf && styles.highlight}
								onPress={() => onChangeSelectedPDF(pdf)}
								title={{ id: pdf, defaultMessage: pdf }}
							/>
						))}
					</View>
					{!!selectedPDF && !!id && (
						<PDFViewer
							url={`${config.API_URL}/pdf/${selectedPDF}/${id}`}
						/>
					)}
				</View>
			</ScrollView>
		</View>
	);
};

export default SignModule;
