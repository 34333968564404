import {
	dateFormatter,
	day,
	getMonday,
	hour,
	month,
	SData,
	week,
	year,
} from 'mango-utils-client';
import { MessageDescriptor } from 'react-intl';
import { convertDateToDay, convertDateToMonth } from '.';

export const formatTimeTick = (
	tick: SData | number,
	startDate: number,
	entrys: number,
	interval: number,
	asDate = false,
): string | MessageDescriptor => {
	const getDate = (num: number) => {
		let date = new Date(startDate - (entrys - num) * interval);
		if (interval === week) {
			date = getMonday(date);
		} else if (interval === day) {
			date.setHours(0);
		} else if (interval === month) {
			date.setDate(1);
			date.setHours(0);
		} else if (interval === year) {
			date.setMonth(0);
			date.setDate(1);
			date.setHours(0);
		} else if (interval === hour) {
			date.setMinutes(0);
		}
		return date.getTime();
	};
	const i = (tick as SData).interval || getDate(tick as number);
	if (asDate) {
		return dateFormatter(new Date(i));
	} else if (interval === month) {
		return convertDateToMonth(i);
	} else if (interval === day) {
		return convertDateToDay(i);
	} else if (interval === year) {
		return `${new Date(i).getFullYear()}`;
	} else if (interval === hour) {
		const now = new Date().getHours();
		const display = now - Math.floor((Date.now() - i) / 1000 / 60 / 60);
		return display + ':00';
	} else {
		return `${i}`;
	}
};
