import { defineMessages } from 'react-intl';

export const scope = 'mango.container.order.list';

export default defineMessages({
	title: {
		id: `${scope}.title`,
		defaultMessage: 'orders',
	},
	id: {
		id: `${scope}.id`,
		defaultMessage: 'id',
	},
	state: {
		id: `${scope}.state`,
		defaultMessage: 'state',
	},
	pipeline: {
		id: `${scope}.pipeline`,
		defaultMessage: 'pipeline',
	},
	substate: {
		id: `${scope}.substate`,
		defaultMessage: 'substate',
	},
	customer: {
		id: `${scope}.customer`,
		defaultMessage: 'customer',
	},
	pdf: {
		id: `${scope}.pdf`,
		defaultMessage: 'PDF',
	},
	use: {
		id: `${scope}.use`,
		defaultMessage: 'use',
	},
	date: {
		id: `${scope}.date`,
		defaultMessage: 'date',
	},
	selectPDF: {
		id: `${scope}.selectPDF`,
		defaultMessage: '-select PDF-',
	},
	done: {
		id: `${scope}.done`,
		defaultMessage: 'done',
	},
	rescinded: {
		id: `${scope}.rescinded`,
		defaultMessage: 'rescinded',
	},
	ongoing: {
		id: `${scope}.ongoing`,
		defaultMessage: 'ongoing',
	},
	ready: {
		id: `${scope}.ready`,
		defaultMessage: 'ready',
	},
	undefined: {
		id: `${scope}.undefined`,
		defaultMessage: 'undefined',
	},
	occupied: {
		id: `${scope}.occupied`,
		defaultMessage: 'this order is being used by another user',
	},
	canceled: {
		id: `${scope}.canceled`,
		defaultMessage: 'canceled',
	},
	confirm: {
		id: `${scope}.confirm`,
		defaultMessage: 'please confirm that you want to take it over',
	},
});
