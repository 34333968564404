import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';

import { MArticle } from 'mango-utils-client';
import MundoInput from '../../../components/elements/MundoInput';
import { LanguageContext } from '../../../utilities/contexts/Language';
import useDebounce from '../../../utilities/hooks/debounce';
import { useStyle } from '../../../utilities/hooks/styles';
import {
	getTranslation,
	updateTranslation,
} from '../../../utilities/translations';
import messages from './messages';
import { IArticleFilter } from './props';

const ArticleFilter: FC<IArticleFilter> = (props) => {
	const styles = useStyle();
	const [last, onChangeLast] = useState<MArticle>();
	const [article, onChangeArticle] = useState<MArticle>(new MArticle());
	const { language } = useContext(LanguageContext);

	const debouncedSearchTerm = useDebounce(article);

	const loadCB = useCallback((next) => props.onChangeFilterArticle(next), [
		props,
	]);

	const update = (next: Partial<MArticle>) => {
		onChangeArticle(new MArticle({ ...article, ...next }));
	};

	useEffect(() => {
		if (debouncedSearchTerm && debouncedSearchTerm !== last) {
			onChangeLast(debouncedSearchTerm);
			loadCB(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm, loadCB, last]);

	return (
		<View style={styles.verticalLayout}>
			<MundoInput
				dataSet={{ cy: 'article.filter.title' }}
				style={{ marginLeft: 12 }}
				horizontal
				label={messages.title}
				onChangeText={(title: string) =>
					update({
						title: updateTranslation(
							title,
							language,
							article.title,
						),
					})
				}
				value={getTranslation(article.title, language)}
			/>
			<MundoInput
				horizontal
				dataSet={{ cy: 'article.filter.short' }}
				label={messages.short}
				onChangeText={(short: string) => update({ short })}
				value={article.short}
			/>
		</View>
	);
};

export default ArticleFilter;
